import { ApexOptions } from "apexcharts";
import Chart from "react-apexcharts";

interface dataSet {
  name: string;
  type: string;
  decimal?: boolean;
  data: number[];
}

interface Iprops {
  datasets: dataSet[];
  labels: string[];
  title: string;
  colors: string[];
  subtitle?: string | "";
  exportfilename: string;
}
// simple bar chart
const MultipleYAxisChart = (props: Iprops) => {
  // default options
  const { colors, datasets, labels, title, exportfilename, subtitle } = props;

  const apexBarChartOpts: ApexOptions = {
    chart: {
      height: 380,
      type: "line",
      stacked: false,
      toolbar: {
        show: true,
        export: {
          csv: {
            filename: exportfilename,
          },
          png: {
            filename: exportfilename,
          },
          svg: {
            filename: exportfilename,
          },
        },
      },
    },
    title: {
      text: title,
      align: "center",
    },

    subtitle: {
      text: subtitle,
      align: "left",
      offsetY: 22,
      margin: 14,
    },
    dataLabels: {
      enabled: true,
      // offsetX: -6,
      style: {
        // fontSize: '12px',
        colors: ["#000"],
      },
    },
    stroke: {
      width: [1, 2, 2],
    },
    // stroke: {
    //     show: true,
    //     width: 1,
    //     colors: ['#fff'],
    //     curve: 'smooth'
    // },
    // title: {
    //     text: 'XYZ - Stock Analysis (2009 - 2016)',
    //     align: 'left',
    //     offsetX: 110
    // },
    colors: colors,
    xaxis: {
      categories: labels,
    },
    yaxis: datasets.map((d, i) => {
      return {
        decimalsInFloat: d.decimal === false ? 0 : undefined,
        axisTicks: {
          show: true,
        },
        opposite: i > 0 ? true : false,
        axisBorder: {
          show: true,
          color: colors[i],
        },
        labels: {
          // formatter: (value) => {
          //     return value.toFixed(0)
          // },
          style: {
            // colors: colors[i],
          },
        },
        title: {
          text: d.name,
          style: {
            color: colors[i],
          },
        },
        tooltip: {
          enabled: true,
        },
      };
    }),
    // yaxis: [
    //         {
    //             axisTicks: {
    //                 show: true,
    //             },
    //             axisBorder: {
    //                 show: true,
    //                 color: '#008FFB'
    //             },
    //             labels: {
    //                 style: {
    //                     colors: '#008FFB',
    //                 }
    //             },
    //             title: {
    //                 text: "EastField",
    //                 style: {
    //                     color: '#008FFB',
    //                 }
    //             },
    //             tooltip: {
    //                 enabled: true
    //             }
    //         },
    //         // {
    //         //     seriesName: 'EastField',
    //         //     opposite: true,
    //         //     axisTicks: {
    //         //         show: true,
    //         //     },
    //         //     axisBorder: {
    //         //         show: true,
    //         //         color: '#00E396'
    //         //     },
    //         //     labels: {
    //         //         style: {
    //         //             colors: '#00E396',
    //         //         }
    //         //     },
    //         //     title: {
    //         //         text: "EastField",
    //         //         style: {
    //         //             color: '#00E396',
    //         //         }
    //         //     },
    //         // },
    //         {
    //             seriesName: '% Change',
    //             opposite: true,
    //             axisTicks: {
    //                 show: true,
    //             },
    //             axisBorder: {
    //                 show: true,
    //                 color: '#FEB019'
    //             },
    //             labels: {
    //                 style: {
    //                     colors: '#FEB019',
    //                 },
    //             },
    //             title: {
    //                 text: "% Change",
    //                 style: {
    //                     color: '#FEB019',
    //                 }
    //             }
    //         },
    //     ],
    tooltip: {
      fixed: {
        enabled: true,
        position: "topLeft", // topRight, topLeft, bottomRight, bottomLeft
        offsetY: 30,
        offsetX: 60,
      },
    },
    legend: {
      showForSingleSeries: true,
      horizontalAlign: "center",
      offsetX: 40,
      labels: {
        colors: "#000",
      },
    },
  };

  // chart data
  // const apexBarChartData = [{
  //     name: 'Income',
  //     type: 'column',
  //     data: [1.4, 2, 2.5, 1.5, 2.5, 2.8, 3.8, 4.6]
  // }, {
  //     name: 'Cashflow',
  //     type: 'column',
  //     data: [1.1, 3, 3.1, 4, 4.1, 4.9, 6.5, 8.5]
  // }, {
  //     name: 'Revenue',
  //     type: 'line',
  //     data: [20, 29, 37, 36, 44, 45, 50, 58]
  // }]

  return (
    <>
      {/*<h4 className="header-title mb-3">{title}</h4>*/}
      <Chart
        options={apexBarChartOpts}
        series={datasets}
        type="line"
        className="apex-charts"
        height={400}
      />
    </>
  );
};

export default MultipleYAxisChart;
