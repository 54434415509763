import { ApexOptions } from "apexcharts";
import Chart from "react-apexcharts";

interface Iprops {
  datasets: number[];
  labels: string[];
  title: string;
  colors: string[];
  subtitle: string[];
  exportfilename: string;
}
// simple pie chart
const PieChart = (props: Iprops) => {
  const { colors, datasets, labels, title, exportfilename, subtitle } = props;
  // default options
  const apexDonutOpts: ApexOptions = {
    chart: {
      height: 380,
      type: "pie",
      toolbar: {
        show: true,
        export: {
          csv: {
            filename: exportfilename,
          },
          png: {
            filename: exportfilename,
          },
          svg: {
            filename: exportfilename,
          },
        },
      },
    },
    plotOptions: {
      pie: {
        offsetY: 20,
        dataLabels: {
          offset: -15,
        },
      },
    },
    title: {
      text: title,
      align: "center",
      offsetY: -8,
      margin: 18,
    },
    annotations: {
      texts: [
        {
          x: 30, // X position (center of the chart)
          y: 38, // Y position (20 pixels from the top)
          text: subtitle[0],
          fontSize: "12px",
          borderColor: "transparent",
          paddingTop: 3,
          paddingBottom: 2,
        },
        {
          x: 30, // X position (center of the chart)
          y: 55, // Y position (20 pixels from the top)
          text: subtitle[1],
          fontSize: "12px",
          borderColor: "transparent",
          paddingTop: 2,
          paddingBottom: 30,
        },
      ],
    },
    dataLabels: {
      enabled: true,
      style: {
        fontSize: "16px",
        fontFamily: "Helvetica, Arial, sans-serif",
        fontWeight: "bold",
        colors: ["#000"],
      },
      // style: {
      //     colors: ["#000000"],
      //     fontWeight: 'bold',

      // },
      dropShadow: {
        enabled: false,
      },
    },
    labels: labels,
    colors: colors,
    legend: {
      show: true,
      position: "bottom",
      horizontalAlign: "center",
      floating: false,
      fontSize: "14px",
      offsetX: 0,
      offsetY: -10,
      showForSingleSeries: true,
    },
    responsive: [
      {
        breakpoint: 600,
        options: {
          chart: {
            height: 240,
          },
          legend: {
            show: false,
          },
        },
      },
    ],
  };

  // chart data
  // const apexDonutData = [44, 55, 41, 17, 15];

  return (
    <>
      {/*<h4 className="header-title mb-5">{title}</h4>*/}

      <Chart
        options={apexDonutOpts}
        series={datasets}
        type="pie"
        height={350}
        className="apex-charts"
      />
    </>
  );
};

export default PieChart;
