import React from "react";

/**
 * Renders the preloader
 */
const Loader = () => {
  return (
    <div className="preloader" id="preloader">
      <div className="status" id="status">
        <div className="spinner">
          <div></div>
          <div></div>
          <div></div>
        </div>
        <p className="tooltip">
        <span>Loading insights! </span>
        {/* <span>Your dashboard is on its way.</span> */}
        </p>
      </div>
    </div>
  );
};

export default Loader;
