import jsPDF from "jspdf";
import autoTable from "jspdf-autotable";
import React, { useEffect, useMemo, useState } from "react";
import { Dropdown } from "react-bootstrap";
import FormInput from "./FormInput";
// import LogoDark from '../custom/assets/images/logo-dark.png';
import { faGear } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import classNames from "classnames";
import * as Feather from "react-feather";
import tableIcon from "../assets/images/dashboard/table_icon.svg";
import FileLoader from "./FileLoader";

export type SelectedRowsHandler = (selectedRows: string[]) => void;
export type conditionHandler = (conditionVal: boolean) => void;

export interface Thead {
  id?: React.Key;
  children?: React.ReactNode | React.ReactNode[];
  text?: string;
  style?: React.CSSProperties;
  className?: string;
}

export interface Tbody {
  id?: React.Key;
  theadId?: React.Key;
  children?: React.ReactNode | React.ReactNode[];
  text?: string;
  style?: React.CSSProperties;
  className?: string;
}

interface Props {
  data?: string[][];
  thead?: Thead[];
  tbody?: Tbody[][];
  title: string;
  fileName?: string;
  downloadPDF?: boolean | false;
  downloadCSV?: boolean | false;
  onClickPDF?: () => void;
  onClickCSV?: () => void;
  setOffset?: React.Dispatch<React.SetStateAction<number>>;
  limit?: number;
  range?: number[];
  valueInString?: string;
  onPageNoChange?: () => void;
  handlePag?: () => void;
  fLoader?: boolean;
  onSelectedRowsChange?: SelectedRowsHandler;
  onOffchange?:conditionHandler
}

interface TableMenuItem {
  label: string;
  field: string;
}

const TransactionTable = (props: Props) => {
  const {
    data,
    title,
    fileName,
    downloadCSV,
    downloadPDF,
    fLoader,
    setOffset,
    limit,
    onClickCSV,
    onClickPDF,
    valueInString,
    range,
    onPageNoChange,
    handlePag,
    onSelectedRowsChange,
    onOffchange
  } = props;

  const head = [props.thead?.map((h) => h.text || "") || []];
  const body =
    props.tbody?.map((tr, i) => {
      return (
        props.thead?.map((th, j) => {
          const col = tr.find((r) => r.theadId === th.id);
          return col?.text || "";
        }) || []
      );
    }) || [];

  const csvData = [...head, ...body];

  const [dropdownOpen, setDropdownOpen] = useState<boolean>(false);
  const [mobileView, setMobileView] = useState<boolean>(false);
  const [selected, setSelected] = useState<string[]>([]);
  const [selectedTrRowIndex, setSelectedTrRowIndex] = useState<number>();
  const [screenSize, setscreenSize] = useState<number>(window.innerWidth);

  // let range = [1, 2, 3, 4, 5]
  const lastPageNo = Math.max(...range!);
  const [pageNo, setPageNo] = useState<number>(1);

  const menuItems = useMemo<Array<TableMenuItem>>(() => {
    const arr: Array<TableMenuItem> = [];
    let a = head
      .map((h) => {
        return h;
      })
      .flat(1);

    setSelected(a);

    a.forEach((m) => {
      arr.push({
        label: m,
        field: m,
      });
    });
    return arr;
  }, []);

  const toggleDropdown = () => {
    setDropdownOpen(!dropdownOpen);
  };

  const handleFirstPage = () => {
    setOffset!(0);
    setPageNo(1);
    if(onOffchange){
      onOffchange(true);
    }
  };
  const handleLastPage = () => {
    setOffset!(lastPageNo * limit! - limit! + 1);
    setPageNo(lastPageNo);
  };
  
  const handlePrevious = () => {
    setPageNo((prev) => Math.max(prev - 1, 1));
    if(pageNo===2){
      if(onOffchange){
      onOffchange(true);
    }
      setOffset!(0)
      return
    }
    if (setOffset) {
      setOffset((prevOffset) => Math.max(prevOffset - limit!, 0));
    }
  };

  const handleNext = () => {
    setPageNo((prev) => Math.min(prev + 1, lastPageNo));
    if (setOffset) {
      setOffset((prevOffset) => {
        if(prevOffset === 0){
          return Math.min((prevOffset+1) + limit!, (lastPageNo - 1) * limit!)
        }else{
          return Math.min(prevOffset + limit!, (lastPageNo - 1) * limit!)
        }        
      });
    }
  };
  
  const handlePageNo = (num: number) => {
    if (num === 1) {
      if(onOffchange){
        onOffchange(true);
      }
      setOffset!(0);
      setPageNo(num);
      return
    } else {
      setOffset!(num * limit! - limit! + 1);
    }
    setPageNo(num);
  };

  useEffect(() => {
    if (limit && setOffset && body.length > 0) {
      if (handlePag) {
        handlePag!();
      }
      onPageNoChange !== undefined && onPageNoChange!();
      if (pageNo === 1) {
        setOffset!(0);
      } else setOffset!(pageNo * limit! - limit! + 1);
    }
  }, [pageNo]);

  useEffect(() => {
    setPageNo(range !== undefined ? range[0] : 1);
  }, [range]);

  //look123
  const handleFieldChange = (item: TableMenuItem) => {
    let a = item.label;
    setSelected((pre) => {
      return pre.includes(a?.toString())
        ? pre.filter((ps) => ps?.toString() !== a?.toString())
        : [...pre, a];
    });

    let updatedRow = selected.slice();
    const isExist = updatedRow.includes(a?.toString());
    if (isExist) {
      const labelIndex = updatedRow.findIndex(
        (b) => b?.toString() === a?.toString()
      );
      updatedRow.splice(labelIndex, 1);
    } else {
      updatedRow.push(a);
    }
    if (onSelectedRowsChange) {
      onSelectedRowsChange(updatedRow);
    }
  };
  //look123
  /*useEffect(() => {
    onSelectedRowsChange!(selected);
  }, [selected]);*/

  const handlePlus = (i: number) => {

    setMobileView(!mobileView);
    setSelectedTrRowIndex((prev) => (prev === i ? undefined : i));
  };

  const handleDownloadPDF = () => {
    const doc = new jsPDF("l", "mm", [460, 210]);
    doc.setFontSize(18);
    doc.text(title, 180, 13);
    autoTable(doc, {
      head: head,
      body: body,
      // foot: foot,
      startY: 20,
      didDrawPage: function () {
        // // Header
        var str = doc.getNumberOfPages();
        doc.setFontSize(10);
        // jsPDF 1.4+ uses getWidth, <1.4 uses .width
        // var pageSize = doc.internal.pageSize;
        // var pageHeight = pageSize.height
        //     ? pageSize.height
        //     : pageSize.getHeight();
        doc.text(str?.toString(), 360, 10);
      },
    });
    doc.save(fileName + ".pdf");
  };

  useEffect(() => {
    if (screenSize <= 1000) {
      setSelected((pre) => [pre[0]]);
    } else if (screenSize > 1000) {
      let a = head
        .map((h) => {
          return h;
        })
        .flat(1);

      setSelected(a);
      setSelectedTrRowIndex(undefined);
    }
  }, [screenSize]);

  useEffect(() => {
    // setscreenSize(window.innerWidth)
    window.addEventListener("resize", () => setscreenSize(window.innerWidth));

    return () => {
      window.removeEventListener("resize", () =>
        setscreenSize(window.innerWidth)
      );
    };
  }, []);

  return (
    <>
      <div className="row">
        <div className="col-lg-6 col-sm-12">
          {title !== "" && (
            <h5 className="header-title mb-2 float-start">
              <img src={tableIcon} alt="icon"></img>
              {title}
            </h5>
          )}
          {/* <i className="fe-align-justify me-1 text-black"></i> */}
        </div>
      </div>
      {/* <hr className='hr my-0' /> */}

      <div className="row">
        <div className="col-4 col-sm-6 col-lg-6 float-start my-1">
          {valueInString && (
            <span className="text-secondary">
              {"*Value in " + (valueInString ? valueInString : "")}
            </span>
          )}
        </div>
        <div className="col-8 col-sm-6 col-lg-6 my-1">
          <div className="float-end h-100 d-flex align-items-center ">
            <div className="">
              {/* {downloadPDF && <button type="button" onClick={onClickPDF} className={`me-1 btn btn-xs btn-secondary`}><Feather.DownloadCloud size={14} /> PDF</button>} */}
              {downloadPDF && (
                <button
                  type="button"
                  onClick={onClickPDF}
                  className="btn btn-xs btn-pdf text-white me-1"
                >
                  {/* <!--<i className="fe-download-cloud me-1"></i> --> */}
                  <svg
                    width="17"
                    height="16"
                    viewBox="0 0 19 21"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M12.889 1.66651H2.66659C1.93023 1.66651 1.33325 2.16812 1.33325 2.78684V18.5462C1.33325 19.1649 1.93023 19.6665 2.66659 19.6665H15.9999C16.7363 19.6665 17.3332 19.1649 17.3332 18.5462V5.40106L12.889 1.66651Z"
                      stroke="white"
                      strokeWidth="1.5"
                      strokeMiterlimit="10"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    ></path>
                    <path
                      d="M12.8889 1.66644V5.40088H17.3334L12.8889 1.66644Z"
                      stroke="white"
                      strokeWidth="1.5"
                      strokeMiterlimit="10"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    ></path>
                    <path
                      d="M9.33328 10.4777L7.17981 13.6118H11.4867L9.33328 10.4777Z"
                      stroke="white"
                      strokeWidth="1.5"
                      strokeMiterlimit="10"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    ></path>
                    <path
                      d="M9.33324 10.478L10.3272 9.03153C10.7689 8.38861 10.2168 7.58499 9.33324 7.58499C8.44973 7.58499 7.89755 8.38861 8.33933 9.03153L9.33324 10.478Z"
                      stroke="white"
                      strokeWidth="1.5"
                      strokeMiterlimit="10"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    ></path>
                    <path
                      d="M11.4868 13.6119L12.4807 15.0584C12.9225 15.7012 14.0268 15.7012 14.4685 15.0584C14.9103 14.4155 14.3581 13.6119 13.4747 13.6119H11.4868Z"
                      stroke="white"
                      strokeWidth="1.5"
                      strokeMiterlimit="10"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    ></path>
                    <path
                      d="M7.1798 13.6119L6.18589 15.0584C5.74411 15.7012 4.6398 15.7012 4.19807 15.0584C3.75634 14.4155 4.30851 13.6119 5.19194 13.6119H7.1798Z"
                      stroke="white"
                      strokeWidth="1.5"
                      strokeMiterlimit="10"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    ></path>
                  </svg>
                  {fLoader && body.length > 0 && (
                    <>
                      <FileLoader />
                    </>
                  )}
                  PDF
                </button>
              )}

              {downloadCSV && (
                <button
                  type="button"
                  onClick={onClickCSV}
                  className="btn btn-xs btn-csv text-white me-1"
                >
                  {/* <!--<i className="fe-download-cloud me-1"></i>--> */}
                  <svg
                    width="17"
                    height="16"
                    viewBox="0 0 19 20"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M11.7856 1V5C11.7856 5.26522 11.9061 5.51957 12.1204 5.70711C12.3347 5.89464 12.6254 6 12.9285 6H17.4999"
                      stroke="white"
                      strokeWidth="1.5"
                      strokeMiterlimit="10"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    ></path>
                    <path
                      d="M15.2143 19H3.78571C3.17951 19 2.59812 18.7893 2.16947 18.4142C1.74082 18.0391 1.5 17.5304 1.5 17V3C1.5 2.46957 1.74082 1.96086 2.16947 1.58579C2.59812 1.21071 3.17951 1 3.78571 1H11.7857L17.5 6V17C17.5 17.5304 17.2592 18.0391 16.8305 18.4142C16.4019 18.7893 15.8205 19 15.2143 19Z"
                      stroke="white"
                      strokeWidth="1.5"
                      strokeMiterlimit="10"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    ></path>
                    <path
                      d="M4.92847 9H14.0713V16H4.92847V9Z"
                      stroke="white"
                      strokeWidth="1.5"
                      strokeMiterlimit="10"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    ></path>
                    <path
                      d="M4.92847 13H14.0713"
                      stroke="white"
                      strokeWidth="1.5"
                      strokeMiterlimit="10"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    ></path>
                    <path
                      d="M8.35718 9V16"
                      stroke="white"
                      strokeWidth="1.5"
                      strokeMiterlimit="10"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    ></path>
                  </svg>
                  {fLoader && body.length > 0 && (
                    <>
                      <FileLoader />
                    </>
                  )}
                  CSV
                </button>
              )}

              {/* {downloadCSV && <button type="button" className={`me-1 btn btn-xs btn-secondary`} onClick={onClickCSV}><Feather.DownloadCloud size={14} /> CSV</button>} */}
            </div>
            <Dropdown show={dropdownOpen} onToggle={toggleDropdown}>
              <Dropdown.Toggle
                id="dropdown-profile"
                as="a"
                onClick={toggleDropdown}
                className={classNames(
                  "nav-link nav-user me-0 waves-effect waves-light",
                  { show: dropdownOpen }
                )}
              >
                <span className="pro-user-name ms-1">
                  <i>
                    <FontAwesomeIcon icon={faGear} />
                  </i>
                </span>
              </Dropdown.Toggle>
              <Dropdown.Menu className="dropdown-menu dropdown-menu-end profile-dropdown overflow">
                <div>
                  <div className="dropdown-header noti-title">
                    <h5 className="text-overflow m-0">Fields Settings</h5>
                  </div>
                  <div className="">
                    {(menuItems || []).map((item, i) => {
                      return (
                        <React.Fragment key={item.label}>
                          {i === ["menuItems"].length - 1 && (
                            <div className="dropdown-divider"></div>
                          )}
                          <FormInput
                            type="checkbox"
                            className="form-control"
                            label={item.label}
                            name={item.field}
                            value={selected}
                            onChange={() => handleFieldChange(item)}
                            checked={selected.includes(item.label)}
                          />
                        </React.Fragment>
                      );
                    })}
                  </div>
                </div>
              </Dropdown.Menu>
            </Dropdown>
          </div>
        </div>
        <div className="col-12">
          <div className="table-wrapper">
            <table className="table table-hover table-bordered">
              <thead>
                <tr>
                  {props.thead?.map((item, i) => {
                    let show = selected.includes(item.text?.toString()!);

                    return (
                      <React.Fragment key={i}>
                        {show && (
                          <th
                            className={`text-truncate ${item.className || ""}`}
                            key={item.id || i}
                            style={item.style}
                          >
                            {item.children}
                          </th>
                        )}
                      </React.Fragment>
                    );
                  })}
                </tr>
              </thead>

              <tbody>
                {body.length > 0 ? (
                  props.tbody?.map((tr, ind) => {
                    let selectedtr = selectedTrRowIndex === ind;
                    return (
                      <>
                        <tr
                          key={ind}
                          onClick={
                            screenSize <= 1000
                              ? () => handlePlus(ind)
                              : () => {}
                          }
                        >
                          {props.thead?.map((th, j) => {
                            const col = tr.find((r) => r.theadId === th.id);
                            let show = selected.includes(
                              col?.theadId?.toString()!
                            );
                            return (
                              <React.Fragment key={j}>
                                {show && (
                                  <td
                                    style={col?.style}
                                    className={`${col?.className} text-truncate`}
                                    key={col?.id || j}
                                  >
                                    {screenSize <= 1000 && j === 0 && (
                                      <i
                                        className="page-selected me-1 border border-white border-2"
                                        onClick={() => handlePlus(ind)}
                                      >
                                        {selectedtr ? (
                                          <Feather.Minus
                                            size={12}
                                            color="#fff"
                                            onClick={() => handlePlus(ind)}
                                          />
                                        ) : (
                                          <Feather.Plus
                                            size={12}
                                            color="#fff"
                                            onClick={() => handlePlus(ind)}
                                          />
                                        )}
                                      </i>
                                    )}
                                    {col?.text !== "" && col?.text !== null
                                      ? col?.children
                                      : "NA"}
                                  </td>
                                )}
                              </React.Fragment>
                            );
                          })}
                        </tr>
                        {selectedtr && (
                          <tr className="child">
                            <td className="child">
                              {/* {props?.tbody!?.map((tr, i) => { */}
                              <ul>
                                {props.thead?.map((th, j) => {
                                  const col = props?.tbody![
                                    selectedTrRowIndex!
                                  ]?.find((r) => r.theadId === th.id);
                                  // const value = tr.find(r => r.theadId === th.id);
                                  return (
                                    <li key={j}>
                                      <span className="dtr-title">
                                        {th.text} :{" "}
                                      </span>
                                      <span className="dtr-data">
                                        {" "}
                                        {col?.text !== "" && col?.text !== null
                                          ? col?.children
                                          : "NA"}
                                      </span>
                                    </li>
                                  );
                                })}
                              </ul>
                              {/* })} */}
                            </td>
                          </tr>
                        )}
                      </>
                    );
                  })
                ) : (
                  <>
                    <tr>
                      <td className="no-records-td" colSpan={head[0].length}>
                        <p className="empty-data-text">No Records Found</p>
                      </td>
                    </tr>{" "}
                  </>
                )}
              </tbody>
            </table>
          </div>

          {limit && setOffset && body.length > 0 && (
            <div style={{ position: "sticky" }} className="text-center m-2">
              {/* <tr> */}
              <nav aria-label="Page navigation example">
                <ul className="hstack gap-2 justify-content-end m-0 text-black">
                  <li className="page-item">
                    <button
                      className="page-link"
                      onClick={handleFirstPage}
                      disabled={pageNo === 1}
                    >
                      <i>
                        <Feather.ChevronsLeft size={25} color="#763EEC" />
                      </i>
                    </button>
                  </li>
                  <li className="page-item">
                    <button
                      className="page-link"
                      onClick={handlePrevious}
                      disabled={pageNo === 1}
                    >
                      <i>
                        <Feather.ChevronLeft size={25} color="#763EEC" />
                      </i>
                    </button>
                  </li>
                  {range?.map((r, i) => {
                    if (i <= 10) {
                      return (
                        <li
                          key={i}
                          className={
                            pageNo === r
                              ? "page-selected  page-item paginate_button"
                              : "page-item"
                          }
                          onClick={() => handlePageNo(r)}
                          value={pageNo}
                        >
                          {" "}
                          <button
                            className={
                              pageNo === r
                                ? " page-selected  text-white page-link"
                                : "page-link"
                            }
                            onClick={() => handlePageNo(r)}
                          >
                            {r}
                          </button>{" "}
                        </li>
                      );
                    } else return <React.Fragment key={i}></React.Fragment>;
                  })}
                  {pageNo > 11 ? (
                    <>
                      ...
                      <span className="page-selected  page-item paginate_button">
                        <button className="page-selected  text-white page-link">
                          {pageNo}
                        </button>
                      </span>
                    </>
                  ) : (
                    ""
                  )}
                  <li className="page-item">
                    <button
                      className="page-link"
                      onClick={handleNext}
                      disabled={pageNo === lastPageNo}
                    >
                      <i>
                        <Feather.ChevronRight size={25} color="#763EEC" />
                      </i>
                    </button>
                  </li>
                  <li className="page-item">
                    <button
                      className="page-link"
                      onClick={handleLastPage}
                      disabled={pageNo === lastPageNo}
                    >
                      <i>
                        <Feather.ChevronsRight size={25} color="#763EEC" />
                      </i>
                    </button>
                  </li>
                </ul>
                {pageNo > 0 && (
                  <span className="text-end btn page-link">
                    {pageNo} | {lastPageNo}
                  </span>
                )}
              </nav>
            </div>
          )}
        </div>
      </div>
    </>
  );
};
export default TransactionTable;
