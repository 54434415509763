import { nanoid } from "@reduxjs/toolkit";
import React, { ChangeEventHandler, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Option from "../components/Option";
import chartIcon from "../components/custom/assets/images/dashboard/chart_icon.svg";
import downIcon from "../components/custom/assets/images/dashboard/down-icon.svg";
import tableIcon from "../components/custom/assets/images/dashboard/table_icon.svg";
import upIcon from "../components/custom/assets/images/dashboard/up-icon.svg";
import FormInput2 from "../components/custom/components/FormInput2";
import { MultiSelect } from "../components/custom/components";
import {
  Tbody,
  Thead,
} from "../components/custom/components/TradeCategoryPerformanceTable";
import TransactionTable from "../components/custom/components/TransactionTable";
import SplineAreaChart from "../components/custom/components/charts/apex/SplineAreaChart";
import { COLORS } from "../components/custom/constants";
import UserLayout from "../components/custom/layouts/UserLayout";
import { ToastTypes, useToast } from "../components/toast/ToastProvider";
import { defaultbrand, defaultPortfolio, defaultTradeCategory, defaultTradeSubCategory, DropDownOption1, PAGE_SIZE } from "../config";
import api, { APIResponse, getAxiosRequestConfig } from "../config/api";
import {
  CSVdownload,
  YearOptions,
  handleDownloadPDF,
} from "../config/functions";
import { AuthUserType } from "../redux/actions/authUserActions";
import { BrandType } from "../redux/actions/brandActions";
import { BrandCompanyType } from "../redux/actions/brandCompanyActions";
import { PortfolioType } from "../redux/actions/portfolioActions";
import {
  OptionsTypes,
  addToSelectedOption,
} from "../redux/actions/selectedOptionsActions";
import { SiteType } from "../redux/actions/siteActions";
import { TradeCategoryType } from "../redux/actions/tradeCategoryActions";
import { TradeSubCategoryType } from "../redux/actions/tradeSubCategoryActions";
import { StoreState } from "../redux/store";
import {
  BuildingDataType,
  BuildingType,
  FloorDataType,
  FloorType,
} from "./SalesOverAllSalesReport";
import { CatSubCatCompanyWithBrandRelationDataType } from "./TransactionBillWiseReport";
import Loader from "../components/custom/components/Loader";
import Modal from "../components/Modal";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faWindowMaximize } from "@fortawesome/free-solid-svg-icons";
import { areaType } from "../redux/actions/areaActions";
import { outletType } from "../redux/actions/outletActions";
import { SalesMAT } from "../components/salesReports/salesMAT";
import SingleSelect from "../components/custom/components/SingleSelect";
import SingleSelect1 from "../components/custom/components/SingleSelect1";

export interface DropDownOption {
  value: number;
  label: string;
  imgUrl: string;
}

export interface SalesMATRecord {
  sales: string;
  centerid: number; //newly added in query
  transyear: number;
  transmonth: number;
  momgrowth: number | null;
  mtd: string;
  mtdpercentchange: string | null;
  mtdsalesmat: string | null;
  averagesale: string;
  averagerate: string;
  averageratepercent: string;
   salesmat:string | null,
  matpercentchange:string|null
}

export interface SalesMATReportType {
  portfolio: number;
  record: SalesMATRecord[];
  count?: number;
}

const SalesMovingAnnualTotalReport: React.FC = () => {
  const toast = useToast();
  const dispatch = useDispatch();
  const authUser = useSelector<StoreState, AuthUserType | null>(
    (state) => state?.authUser
  );
  const authUserToken = useSelector<StoreState, AuthUserType | null>(
    (state) => state?.authUser
  )?.["auth-token"]!;
  const config = getAxiosRequestConfig(authUserToken);

  const authUserSiteId = authUser?.siteId;

  const portfolioOption = useSelector<StoreState, PortfolioType[]>(
    (state) => state?.portfolio
  );
  const siteOption = useSelector<StoreState, SiteType[]>((state) => state?.site);
  const brandOption = useSelector<StoreState, BrandType[]>(
    (state) => state?.brand
  );
  const brandCompanyOption = useSelector<StoreState, BrandCompanyType[]>(
    (state) => state?.brandCompany
  );

  const selectedOption = useSelector<StoreState, OptionsTypes>(
    (state) => state?.selectedOption
  );

  const outletList = useSelector<StoreState, outletType[]>(
    (state) => state?.outlet
  );
  
  const localityOption = useSelector<StoreState, areaType[]>(
    (state) => state?.locality
  );
  const monthsArray = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];

  const filteredBrandCompany = brandCompanyOption?.filter(
    (a) => a?.retailid !== null
  );

  const filteredBrand = brandOption?.filter((a) => {
    return brandCompanyOption?.some(
      (b) => b?.id?.toString() === a?.companyid?.toString() && b?.retailid !== null
    );
  });

  const port =
    authUser?.portfolioId !== undefined && authUser?.portfolioId !== null
      ? authUser?.portfolioId?.split(",")
      : [];

  let portfolioData = port
    ?.map((pe) => {
      return portfolioOption?.filter(
        (f) => f?.id?.toString() === pe?.toString()
      );
    })
    ?.flat(1);

  const pname = portfolioData[0]?.name;

  const st =
    authUser?.siteId !== undefined && authUser?.siteId !== null
      ? authUser?.siteId?.split(",")
      : [];

  let siteData = st
    ?.map((pe) => {
      return siteOption?.filter((f) => f?.id?.toString() === pe?.toString());
    })
    ?.flat(1);

  const br =
    authUser?.brandId !== undefined && authUser?.brandId !== null
      ? authUser?.brandId?.split(",")
      : [];

  let brandData = br
    ?.map((b) => {
      return filteredBrand?.filter((f) => f?.id?.toString() === b?.toString());
    })
    ?.flat(1);

  const categoryOption = useSelector<StoreState, TradeCategoryType[]>(
    (state) => state?.tradeCategory
  );
  const subCategoryOption = useSelector<StoreState, TradeSubCategoryType[]>(
    (state) => state?.tradeSubCategory
  );

  const [
    catSubCatCompanyWithBrandRelationData,
    setCatSubCatCompanyWithBrandRelationData,
  ] = useState<CatSubCatCompanyWithBrandRelationDataType[]>([]);


  const [valueIn, setValueIn] = useState<string>("");

  const [portfolios, setPortfolios] = useState<string>("");
  const [sites, setSites] = useState<string>("");
  const [brands, setBrands] = useState<string>("");

  // const [location, setLocation] = useState<string>("");
  const [locations, setLocations] = useState<DropDownOption[]>([]);
  const [categories, setCategories] = useState<string>("");
  const [subCategories, setSubCategories] = useState<string>("");

  //loader
  const [fileLoader, setFileLoader] = useState<boolean>(false);
  const [loader, setLoader] = useState<boolean>(false);
  const [pag, setPag] = useState<boolean>(false);

  const [salesMATData, setSalesMATData] = useState<SalesMATReportType[]>([]);

  const limit = PAGE_SIZE;
  const [offset, setOffset] = useState<number>(0);
  const [range, setRange] = useState<number[]>([]);
  const [length, setLength] = useState<number>(50);
  const [yearDropDown, setYearDropDown] = useState<number[]>(YearOptions());

  const [showModal, setShowModal] = useState<boolean>(false);
  const [isButtonVisible, setButtonVisible] = useState(false);
  const [disableLocality, setDisableLocality] = useState<boolean>(false);

  const [portfoliosOption, setPortfoliosOption] = useState<DropDownOption1>(defaultPortfolio);
  const [categoriesOption, setCategoriesOption] = useState<DropDownOption1>(defaultTradeCategory);
  const [subCategoriesOption, setSubcategoriesOption] = useState<DropDownOption1>(defaultTradeSubCategory);
  const [brandsOption, setBrandsOption] = useState<DropDownOption1>(defaultbrand);

  const [portfolioDrop, setPortfolioDrop] = useState<DropDownOption[]>([]);
  const [catDrop,setCatDrop] = useState<DropDownOption[]>([])
  const [subcatDrop,setSubcatDrop] = useState<DropDownOption[]>([])
  const [brandDrop,setBrandDrop] = useState<DropDownOption[]>([])

  const showExpandModalHandler = () => {
    setShowModal(true)
  };

  const locality = useMemo<string[]>(() => {
    return locations?.map((p) => {
      return p?.label;
    });
  }, [locations]);
  const SiteFilteredByPortfolio = useMemo<SiteType[]>(() => {
    if (portfolios !== "") {
      return siteData?.filter((s) => {
        return s?.pfid?.toString() === portfolios?.toString();
      });
    } else return [];
  }, [portfolios, siteData]);
  
  const subCategoryFilteredByCategory = useMemo<TradeSubCategoryType[]>(() => {
    if (categories !== "") {
      return subCategoryOption?.filter((sc) => {
        return sc?.catid?.toString() === categories?.toString();
      });
    } else return subCategoryOption;
  }, [categories, subCategoryOption]);

  const BrandFilteredByCompany = useMemo<BrandType[]>(() => {
    if (subCategories !== "") {
      let brands: BrandType[] = [];
      catSubCatCompanyWithBrandRelationData?.forEach((s) => {
        let obj = s?.record?.find((r) =>
          r?.tradesubcatids?.split(",")?.includes(subCategories)
        );
        obj?.brandids?.split(",")?.forEach((m) => {
          let bc = brandData?.find((f) => f?.id?.toString() === m);
          brands?.push(bc!);
        });
      });
      return brands;
    } else if (categories !== "") {
      let brands: BrandType[] = [];
      catSubCatCompanyWithBrandRelationData?.forEach((s) => {
        let obj = s?.record?.find((r) => r?.tradecatid?.toString() === categories);
        obj?.brandids?.split(",")?.forEach((m) => {
          let bc = brandData?.find((f) => f?.id?.toString() === m);
          brands?.push(bc!);
        });
      });
      return brands;
    } else return brandData;
  }, [
    brandData,
    catSubCatCompanyWithBrandRelationData,
    categories,
    subCategories,
  ]);

  const handlePortfolioDrop = (selected: any) => {
    if(fileLoader===false){
    if(selected.length>0){
      const selectedPortfolio = selected as DropDownOption[];
      if(selectedPortfolio[0].value!==null&&selectedPortfolio[0].value!==0){
        setPortfolioDrop(selectedPortfolio)
        setPortfolios(selectedPortfolio[0]?.value?.toString())
        const data = { ...selectedOption, portfolio: selectedPortfolio[0]?.value?.toString() };
        dispatch(addToSelectedOption(data));
        setOffset(0);
        setPag(false);
      }
    }
  }
  };

  const handleClearPortfolio = () =>{
    let pid =portfolioData[0] && portfolioData[0]?.id? portfolioData[0].id?.toString(): "";
    setPortfolios(selectedOption.portfolio ? selectedOption.portfolio : pid);
    const b = portfolioOption.filter((a)=>a?.id?.toString()===pid?.toString())
    if (b?.length > 0 && b[0]?.id !== undefined) {
      const val: DropDownOption = {
        value: b[0]?.id, 
        label: b[0]?.name,
        imgUrl: b[0]?.logourl, 
      };
      setPortfolioDrop([val]);

      if(fileLoader===false){
      setLocations([]);
      const data = { ...selectedOption, portfolio: b[0]?.id?.toString(),location:'',locations:[]};
      dispatch(addToSelectedOption(data));
      setOffset(0);
      setPag(false);
    }
  }
  }
 

  const handleLocationsChange = (value: DropDownOption[]) => {
    if (fileLoader === false) {
      setLocations(value);
      const data = {
        ...selectedOption,
        locations: value,
      };
      dispatch(addToSelectedOption(data));
      setOffset(0);
      setPag(false);
    }
  };
  // const handleLocationChange: ChangeEventHandler<HTMLInputElement> = (e) => {
  //   if (fileLoader === false) {
  //     setLocation(e?.target?.value);
  //     const data = { ...selectedOption, location: e?.target?.value };
  //     dispatch(addToSelectedOption(data));
  //     setOffset(0);
  //     setPag(false);
  //   }
  // };

  const handleCatDrop = (selected: any) => {
    if(fileLoader===false){
    if(selected.length>0){
      const selectedCat = selected as DropDownOption[];
      if(selectedCat[0].value!==null&&selectedCat[0].value!==0){
        setCatDrop(selectedCat)
        setCategories(selectedCat[0]?.value?.toString())
        setSubcatDrop([])
        setSubCategories('')
        setBrandDrop([])
        setBrands('')
        const data = {
          ...selectedOption,
          category: selectedCat[0]?.value?.toString(),
          subCategory: "",
          brand: "",
        };
        dispatch(addToSelectedOption(data));
        setOffset(0);
        setPag(false);
      }
    }
  }
  };
  
  const handleClearCat = () =>{
    if(fileLoader===true){
      setCategories(categories ? categories : '');
      const b = categoryOption.filter((a)=>a?.id?.toString()===categories?.toString())
      if (b?.length > 0 && b[0]?.id !== undefined) {
        const val: DropDownOption = {
          value: b[0]?.id, 
          label: b[0]?.name,
          imgUrl:'', 
        };
        setCatDrop([val]);
     }
     else{
      setCatDrop([])
   }
    }
   
     if(fileLoader===false){
      setCatDrop([])
      setCategories('')
      setSubcatDrop([])
        setSubCategories('')
        setBrandDrop([])
        setBrands('')
       const data = { ...selectedOption,
        category: '',
        subCategory: "",
        brand: "",};
       dispatch(addToSelectedOption(data));
       setOffset(0);
       setPag(false);
     }
  }

  const handleSubcatDrop = (selected: any) => {
    if(fileLoader===false){
    if(selected.length>0){
      const selectedSubcat = selected as DropDownOption[];
      if(selectedSubcat[0].value!==null&&selectedSubcat[0].value!==0){
        setSubcatDrop(selectedSubcat)
        setSubCategories(selectedSubcat[0]?.value?.toString())
        setBrandDrop([])
        setBrands('')
        const data = { ...selectedOption,
          subCategory: selectedSubcat[0]?.value?.toString(),
          brand: "",};
         dispatch(addToSelectedOption(data));
         setOffset(0);
         setPag(false);
      }
    }
  }
  };
  
  const handleClearSubcat = () =>{
    if(fileLoader===true){
      setSubCategories(subCategories ? subCategories : '');
      const b = subCategoryOption.filter((a)=>a?.id?.toString()===subCategories?.toString())
      if (b?.length > 0 && b[0]?.id !== undefined) {
        const val: DropDownOption = {
          value: b[0]?.id, 
          label: b[0]?.name,
          imgUrl:'', 
        };
        setSubcatDrop([val]);
     }else{
      setSubcatDrop([])
   }
    }
   
     if(fileLoader===false){
      setSubcatDrop([])
        setSubCategories('')
        setBrandDrop([])
        setBrands('')
       const data = { ...selectedOption,
        subCategory: "",
        brand: "",};
       dispatch(addToSelectedOption(data));
       setOffset(0);
       setPag(false);
     }
  }

  const handleBrandDrop = (selected: any) => {
    if(fileLoader===false){
    if(selected.length>0){
      const selectedBrand = selected as DropDownOption[];
      if(selectedBrand[0].value!==null&&selectedBrand[0].value!==0){
        setBrandDrop(selectedBrand)
        setBrands(selectedBrand[0]?.value?.toString())
       const data = { ...selectedOption,
        brand: selectedBrand[0]?.value?.toString()};
       dispatch(addToSelectedOption(data));
       setOffset(0);
       setPag(false);
      }
    }
  }
  };
  
  const handleClearBrand = () =>{
    if(fileLoader===true){
      setBrands(brands ? brands : '');
      const b = brandOption.filter((a)=>a?.id?.toString()===brands?.toString())
      if (b?.length > 0 && b[0]?.id !== undefined) {
        const val: DropDownOption = {
          value: b[0]?.id, 
          label: b[0]?.name,
          imgUrl: b[0]?.logourl, 
        };
        setBrandDrop([val]);
     }else {
      setBrandDrop([]);
     }
    }
   
     if(fileLoader===false){
        setBrandDrop([])
        setBrands('')
       const data = { ...selectedOption,
        brand: ""};
       dispatch(addToSelectedOption(data));
       setOffset(0);
       setPag(false);
     }
  }

 

  const getSubtitle = useMemo<string>(() => {
    let subtxt = `\u3010Company\u3011: ${pname}`;
    if (locations?.length > 0) {
      let locNameArr: string[] = [];
      locations?.map((s) => {
        locNameArr?.push(s?.label);
      });
      let localities = locNameArr?.join(", ");
      subtxt += ` \u3010Locality\u3011: ${localities}`;
    }
    if (categories?.length > 0 && categories !== undefined) {
      const categoryIndex = categoryOption?.findIndex(
        (a) => a?.id?.toString() === categories?.toString()
      );
      const categoryName =
        categoryOption[categoryIndex]?.name;
      subtxt += ` \n\u3010TradeCategory\u3011: ${categoryName}`;
    }
    if (subCategories?.length > 0) {
      const subcategoryIndex = subCategoryOption?.findIndex(
        (a) =>
          a?.id?.toString() === subCategories?.toString()
      );
      const subcatName =
        subCategoryOption[subcategoryIndex]?.name;
      subtxt += ` \u3010TradeSubcategory\u3011: ${subcatName}`;
    }
    if (brands?.length > 0) {
      const brandIndex = filteredBrand?.findIndex(
        (a) => a?.id?.toString() === brands?.toString()
      );
      const brandname = filteredBrand[brandIndex]?.name;
      subtxt += ` \u3010Brand\u3011: ${brandname}`;
    }
  return subtxt;
}, [pname, locations, categories, subCategories, brands, categoryOption, subCategoryOption, filteredBrand]);

  const fetchDataForDownload = async () => {
    let error = false;
    if (portfolios === "") {
      toast("Select Portfolio", ToastTypes?.ERROR);
      error = true;
    } else if (brands === "") {
      toast("Select Brand", ToastTypes?.ERROR);
      error = true;
    }


    var getData = {
      portfolio: portfolio,
      site: site,
      locality: locality,
      tradeCategory: category,
      tradeSubCategory: subCategory,
      brand: brand,
    };

    if (error === false && getData !== undefined) {
      const response = await api.post<
        APIResponse<{ records: SalesMATReportType[] }>
      >(`kpi/sales/mat`, getData, config);

      if (response?.status === 200 && response?.data?.data) {
        return response?.data?.data?.records;
      }
    }

    return [];
  };

  const getDownloadData = (Data: SalesMATReportType[]) => {
    var tableHead: Thead[] = [
      { id: "Center", children: "Center", text: "Center" },
      { id: "Year", children: "Year", text: "Year" },
    ];
    var tableBody: Tbody[][] = [];

    monthsArray?.forEach((m, i) => {
      tableHead?.push(
        {
          children: m,
          text: m,
          id: m,
        },
        {
          children: " % Change",
          text: " % Change",
          id: m + " % Change",
        }
      );
    });
    const selectedYears = yearDropDown?.map((f) => f)?.sort();

    Data?.forEach((y, i) => {

      siteOption
        ?.filter((ss) => ss?.pfid?.toString() === y?.portfolio?.toString())
        ?.forEach((c) => {
          selectedYears?.forEach((sy) => {
            var tBody: Tbody[] = [];
            tBody?.push(
              {
                id: nanoid(),
                theadId: "Center",
                children: c?.name,
                text: c?.name,
              },
              {
                id: nanoid(),
                theadId: "Year",
                children: sy?.toString(),
                text: sy?.toString(),
              }
            );

            y?.record
              ?.filter((sw) => sw?.centerid?.toString() === c?.id?.toString())
              ?.filter((y) => y?.transyear?.toString() === sy?.toString())
              ?.forEach((r) => {
                tBody?.push(
                  {
                    id: nanoid(),
                    theadId: monthsArray[r?.transmonth - 1],
                    children: r?.salesmat?.toString(),
                    text: r?.salesmat?.toString(),
                  },
                  {
                    id: nanoid(),
                    theadId: monthsArray[r?.transmonth - 1] + " % Change",
                    children:
                      r?.matpercentchange !== undefined &&
                      r?.matpercentchange !== null ? (
                        <div
                          className={
                            r?.matpercentchange !== undefined &&
                            r?.matpercentchange !== null
                              ? parseFloat(r?.matpercentchange) > 0
                                ? "p-mtd text-truncate"
                                : parseFloat(r?.matpercentchange) < 0
                                ? "n-mtd text-truncate"
                                : ""
                              : ""
                          }
                        >
                          {parseFloat(r?.matpercentchange) > 0 ? (
                            <i>
                              <img src={upIcon} alt="icon"></img>{" "}
                              {r?.matpercentchange?.toString() || ""}
                            </i>
                          ) : parseFloat(r?.matpercentchange) < 0 ? (
                            <i>
                              <img src={downIcon} alt="icon"></img>{" "}
                              {r?.matpercentchange?.toString() || ""}
                            </i>
                          ) : (
                            r?.matpercentchange?.toString()
                          )}
                        </div>
                      ) : r?.matpercentchange === null ? (
                        "-"
                      ) : (
                        ""
                      ),
                    text: r?.matpercentchange?.toString(),
                  }
                );
              });
            if (tBody?.length > 2) tableBody?.push(tBody);
          });
        });
    });
    return { tbody: tableBody, thead: tableHead };
  };

  const handlePag = () => {
    setPag(true);
  };

  const handleApply = () => {
    let error = false;
    if (portfolios === "") {
      toast("Select Portfolio", ToastTypes?.ERROR);
      error = true;
    } else if (brands === "") {
      toast("Select Brand", ToastTypes?.ERROR);
      error = true;
    }

    var getData = {
      portfolio: portfolio,
      site: site,
      locality: locality,
      tradeCategory: category,
      tradeSubCategory: subCategory,
      brand: brand,
      limit: limit?.toString(),
      offset: offset?.toString()==='NaN'||offset===undefined?'0':offset?.toString(),
      authUserRole: authUser?.roleId ? authUser?.roleId : "",
    };

    if (
      error === false &&
      getData !== undefined &&
      getData?.limit !== "" &&
      getData?.offset !== ""
    ) {
      if (pag === false) {
        setLoader(true);
      }
      api
        .post<APIResponse<{ records: SalesMATReportType[] }>>(
          `kpi/sales/mat`,
          getData,
          config
        )
        .then((response) => {
          if (response?.status === 200 && response?.data?.data) {
            const result = response?.data?.data?.records;
            setSalesMATData(result);
            setLoader(false);
          }else {
            setSalesMATData([]);
            setLoader(false);
          }
        })
        .catch((error) => {});
    } 
  };

  useEffect(()=>{    
    if(selectedOption?.isOutletUser){
      setDisableLocality(true);
      }else{
      setDisableLocality(false);
    }
  },[outletList,selectedOption]);

  useEffect(() => {
    if (portfolios !== "" && brands !== "" && fileLoader === false)
      handleApply();
  }, []);

  const handleCsvDownload = async () => {
    setFileLoader(true);
    const csvData = await fetchDataForDownload();
    if (
      csvData[0]?.record?.length !== 0 &&
      csvData[0]?.record?.length !== undefined
    ) {
      const d = { ...getDownloadData(csvData) };
      const csvHead = d?.thead;
      const csvBody = d?.tbody;

      const head = [csvHead?.map((h) => h?.text || "") || []];
      const body =
        csvBody?.map((tr, i) => {
          return (
            csvHead?.map((th, j) => {
              const col = tr?.find((r) => r?.theadId === th?.id);
              return col?.text || "";
            }) || []
          );
        }) || [];

      const rows = [...head, ...body];
      const props = {
        rows: rows,
        fileName: "Moving Annual Total Report",
      };
      CSVdownload(props);
      setFileLoader(false);
    } else {
      setFileLoader(false);
    }
    setFileLoader(false);
  };

  const handlePdfDownload = async () => {
    setFileLoader(true);
    const pdfData = await fetchDataForDownload();
    if (
      pdfData[0]?.record?.length !== undefined &&
      pdfData[0]?.record?.length !== 0
    ) {
      const d = { ...getDownloadData(pdfData) };
      const thead = d?.thead;
      const tbody = d?.tbody;

      const head = [thead?.map((h) => h?.text || "") || []];
      const body =
        tbody?.map((tr, i) => {
          return (
            thead?.map((th, j) => {
              const col = tr?.find((r) => r?.theadId === th?.id);
              return col?.text || "";
            }) || []
          );
        }) || [];

      const props = {
        head: head,
        body: body,
        fileName: "Moving Annual Total Report",
        title: "Moving Annual Total Report",
      };
      handleDownloadPDF(props);
      setFileLoader(false);
    } else {
      setFileLoader(false);
    }
    setFileLoader(false);
  };

  const getTableData = () => {
    // var tableHead: Thead[] = tHead
    var tableHead: Thead[] = [
      { id: "Center", children: "Center", text: "Center" },
      { id: "Year", children: "Year", text: "Year" },
    ];
    var tableBody: Tbody[][] = [];

    monthsArray?.forEach((m, i) => {
      tableHead?.push(
        {
          children: m,
          text: m,
          id: m,
        },
        {
          children: m + " % Change",
          text: m + " % Change",
          id: m + " % Change",
        }
      );
    });
    const selectedYears = yearDropDown?.map((f) => f)?.sort();

    salesMATData?.forEach((y, i) => {

      siteOption
        ?.filter((ss) => ss?.pfid?.toString() === y?.portfolio?.toString())
        ?.forEach((c) => {
          selectedYears?.forEach((sy) => {
            var tBody: Tbody[] = [];
            tBody?.push(
              {
                id: nanoid(),
                theadId: "Center",
                children: c?.name,
                text: c?.name,
              },
              {
                id: nanoid(),
                theadId: "Year",
                children: sy?.toString(),
                text: sy?.toString(),
              }
            );

            y?.record
              ?.filter((sw) => sw?.centerid?.toString() === c?.id?.toString())
              ?.filter((y) => y?.transyear?.toString() === sy?.toString())
              ?.forEach((r) => {
                tBody?.push(
                  {
                    id: nanoid(),
                    theadId: monthsArray[r?.transmonth - 1],
                    children: r?.salesmat?.toString(),
                    text: r?.salesmat?.toString(),
                  },
                  {
                    id: nanoid(),
                    theadId: monthsArray[r?.transmonth - 1] + " % Change",
                    children:
                      r?.matpercentchange !== undefined &&
                      r?.matpercentchange !== null ? (
                        <div
                          className={
                            r?.matpercentchange !== undefined &&
                            r?.matpercentchange !== null
                              ? parseFloat(r?.matpercentchange) > 0
                                ? "p-mtd text-truncate"
                                : parseFloat(r?.matpercentchange) < 0
                                ? "n-mtd text-truncate"
                                : ""
                              : ""
                          }
                        >
                          {parseFloat(r?.matpercentchange) > 0 ? (
                            <i>
                              <img src={upIcon} alt="icon"></img>{" "}
                              {r?.matpercentchange?.toString() || ""}
                            </i>
                          ) : parseFloat(r?.matpercentchange) < 0 ? (
                            <i>
                              <img src={downIcon} alt="icon"></img>{" "}
                              {r?.matpercentchange?.toString() || ""}
                            </i>
                          ) : (
                            r?.matpercentchange?.toString()
                          )}
                        </div>
                      ) : r?.matpercentchange === null ? (
                        "-"
                      ) : (
                        ""
                      ),
                    text: r?.matpercentchange?.toString(),
                  }
                );
              });

            if (tBody?.length > 2) tableBody?.push(tBody);
          });
        });
    });
    return { tbody: tableBody, thead: tableHead };
  };

  

  const portfolio = useMemo<number[]>(() => {
    return portfolios !== "" ? [parseInt(portfolios)] : [];
  }, [portfolios]);
  const site = useMemo<string[]>(() => {
    if (authUserSiteId) {
      return authUserSiteId?.split(",")?.map((s) => {
        return s?.toString();
      });
    } else {
      return [];
    }
  }, [authUserSiteId]);

  const brand = useMemo<number[]>(() => {
    return brands !== "" ? [parseInt(brands)] : [];
  }, [brands]);


  const category = useMemo<number[]>(() => {
    return categories !== "" ? [parseInt(categories)] : [];
  }, [categories]);

  const subCategory = useMemo<number[]>(() => {
    return subCategories !== "" ? [parseInt(subCategories)] : [];
  }, [subCategories]);

  
  useEffect(()=>{
    let pid =portfolioData[0] && portfolioData[0]?.id? portfolioData[0].id?.toString(): "";
    setPortfolios(selectedOption.portfolio ? selectedOption.portfolio : pid);
    const b = portfolioOption.filter((a)=>a?.id?.toString()===pid?.toString())
    if (b?.length > 0 && b[0]?.id !== undefined) {
      const val: DropDownOption = {
        value: b[0]?.id, 
        label: b[0]?.name,
        imgUrl: b[0]?.logourl, 
      };
      setPortfolioDrop([val]);
    }
},[portfolioOption,selectedOption])

  useEffect(() => {
    let sid = siteData[0] && siteData[0]?.id ? siteData[0]?.id?.toString() : "";
    setSites(selectedOption?.site ? selectedOption?.site : sid);
  }, [siteData, selectedOption]);

  useEffect(() => {
    var range = [];
    var num = Math?.ceil(length / limit);
    // let i = 1;

    for (let i = 1; i <= num; i++) {
      range?.push(i);
    }
    setRange(range);
  }, [length, limit]);

  useEffect(() => {
    if (sites !== ""&&selectedOption.isOutletUser===true) {
      let siteCurrencyObject = siteOption?.find(
        (s) => s?.id?.toString() === sites?.toString()
      );
      setValueIn(
        siteCurrencyObject?.currencycode +
          " | " +
          siteCurrencyObject?.valuecaption
      );
    } 
    else if(portfolios !== ""&&selectedOption.isOutletUser===false){
      let portfolioCurrencyObject = portfolioOption?.find(
        (s) => s?.id?.toString() === portfolios?.toString()
      );
      setValueIn(
        portfolioCurrencyObject?.reportingcurrency +
          " | " +
          portfolioCurrencyObject?.valuecaption
      );
    }
    else setValueIn("");
  }, [sites, siteOption,selectedOption]);

  useEffect(() => {
    if (salesMATData?.length > 0)
      setLength(salesMATData[0]?.count ? salesMATData[0]?.count : 0);
  }, [salesMATData]);


  useEffect(() => {
    if (selectedOption?.locations) setLocations(selectedOption?.locations);
  }, [selectedOption])

  useEffect(()=>{
    setCategories(selectedOption.category ? selectedOption.category:'');
    const b = categoryOption.filter((a)=>a?.id?.toString()===selectedOption.category?.toString())
    if (b?.length > 0 && b[0]?.id !== undefined) {
      const val: DropDownOption = {
        value: b[0]?.id, 
        label: b[0]?.name,
        imgUrl:'', 
      };
      setCatDrop([val]);
    }
    },[categoryOption,selectedOption])
    
    
    useEffect(()=>{
    setSubCategories(selectedOption.subCategory ? selectedOption.subCategory:'');
    const b = subCategoryOption.filter((a)=>a?.id?.toString()===selectedOption.subCategory?.toString())
    if (b?.length > 0 && b[0]?.id !== undefined) {
      const val: DropDownOption = {
        value: b[0]?.id, 
        label: b[0]?.name,
        imgUrl:'', 
      };
      setSubcatDrop([val]);
    }
    },[subCategoryOption,selectedOption])

    useEffect(()=>{
      setBrands(selectedOption.brand ? selectedOption.brand:'');
      const b = brandOption.filter((a)=>a?.id?.toString()===selectedOption.brand?.toString())
      if (b?.length > 0 && b[0]?.id !== undefined) {
        const val: DropDownOption = {
          value: b[0]?.id, 
          label: b[0]?.name,
          imgUrl: b[0]?.logourl, 
        };
        setBrandDrop([val]);
      }
    },[brandOption,selectedOption])

  
  useEffect(() => {
    var getData = {
      portfolio: portfolio,
    };

    if (portfolio?.length > 0 && getData !== undefined) {
      api
        .post<
          APIResponse<{ records: CatSubCatCompanyWithBrandRelationDataType[] }>
        >(`filter/catSubcatCompanyRelationWithBrand`, getData, config)
        .then((response) => {
          if (response?.status === 200 && response?.data?.data) {
            const result = response?.data?.data?.records;
            setCatSubCatCompanyWithBrandRelationData(result);
          }
        })
        .catch((error) => {});
    } else {
      setCatSubCatCompanyWithBrandRelationData([]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [portfolio]);

  const colors = [
    COLORS?.COLOR1,
    COLORS?.COLOR2,
    COLORS?.COLOR3,
    COLORS?.COLOR4,
    COLORS?.COLOR5,
    COLORS?.COLOR6,
    COLORS?.COLOR7,
    COLORS?.COLOR8,
  ];

  return (
    <UserLayout>
      <div className="card background-transparent p-1">
        <div className="row">
          <div
            className="col-lg-4 wow fadeInLeft ps-0"
          >
            <h4 className="b-title">Moving Annual Total</h4>
          </div>

          <div className="col-lg-8 wow fadeInRight">
            <div className="row">
              <div className="col-sm-6 col-md-4 col-lg-3">
                {/* <FormInput2
                  name="Portfolio"
                  label=""
                  labelClassName="required"
                  value={portfolios}
                  onChange={handlePortfolioChange}
                  placeholder="Select"
                  containerClass="mb-1"
                  type="select"
                >
                  <option value="">Select</option>
                  {portfolioData?.map((p, i) => {
                    return (
                      <Option value={p?.id} key={i}>
                        {p?.name}
                      </Option>
                    );
                  })}
                </FormInput2> */}
                 {/* <SingleSelect
                  options={[
                    defaultPortfolio,
                    ...portfolioData?.sort(function (a, b) {
                      if (a?.name < b?.name) {
                        return -1;
                      }
                      if (a?.name > b?.name) {
                        return 1;
                      }
                      return 0;
                    })?.map((s, i) => {
                      return (
                        {
                          value: s?.id ? s?.id?.toString() : '',
                          label: s?.name,
                          imgUrl: s?.logourl,
                        }
                      );
                    })
                  ]}
                  label=""
                  selectedOptions={portfoliosOption}
                  setSelectedOptions={handlePortfolioChange}
                  // disabled={disableSite}
                  disabled={disableLocality}
                  placeHolder="Select Portfolio"
                  // filterText=""
                /> */}
                 <SingleSelect1
                      options={portfolioData.map((c, i) => {
                        return {
                          value: c?.id?c?.id:0,
                          label: c?.name?c?.name:'',
                          imgUrl: "",
                        };
                      })}
                      selectedOptions={portfolioDrop}
                      setSelectedOptions={handlePortfolioDrop}
                      placeHolder="Select Portfolio"
                      onClear={handleClearPortfolio}
                      disabled = {disableLocality}
                    />
              </div>
              
              <div className="col-sm-6 col-md-4 col-lg-3">
              <MultiSelect
                  options={localityOption?.map((sc) => {
                    return {
                      value: sc?.id ? sc?.id : 0,
                      label: sc?.area?.toString(),
                      imgUrl: "",
                    };
                  })}
                  label=""
                  selectedOptions={locations}
                  setSelectedOptions={handleLocationsChange}
                  disabled={disableLocality}
                  placeHolder="All Locality"
                /> 
              {/* <FormInput2
                  name="Location"
                  label=""
                  labelClassName="required"
                  value={location}
                  onChange={handleLocationChange}
                  placeholder="Select Locality"
                  containerClass="mb-1"
                  disabled={disableLocality}
                  type="select"
                >
                  <option value="">Select Locality</option>
                  {localityOption?.map((s, i) => {
                    return (
                      <Option value={s?.area} key={i}>
                        {s?.area}
                      </Option>
                    );
                  })}
                </FormInput2> */}
              </div>
            </div>

            <div className="row mt-1">
              <div className="col-sm-6 col-md-4 col-lg-3">
                {/* <FormInput2
                  name="Trade Category"
                  label=""
                  labelClassName="required"
                  value={categories}
                  onChange={handleCategoryChange}
                  placeholder="Select Trade Category"
                  containerClass="mb-1"
                  type="select"
                >
                  <option value="">Select Trade Category</option>
                  {categoryOption?.map((s, i) => {
                    return (
                      <Option value={s?.id} key={i}>
                        {s?.name}
                      </Option>
                    );
                  })}
                </FormInput2> */}
                  {/* <SingleSelect
                  options={[
                    defaultTradeCategory,
                    ...categoryOption?.sort(function (a, b) {
                      if (a?.name < b?.name) {
                        return -1;
                      }
                      if (a?.name > b?.name) {
                        return 1;
                      }
                      return 0;
                    })?.map((s, i) => {
                      return (
                        {
                          value: s?.id ? s?.id?.toString() : '',
                          label: s?.name,
                          imgUrl: '',
                        }
                      );
                    })
                  ]}
                  label=""
                  selectedOptions={categoriesOption}
                  setSelectedOptions={handleCategoryChange}
                  // disabled={disableSite}
                  disabled={disableLocality}
                  placeHolder="Select Trade Category"
                  // filterText=""
                /> */}
                  <SingleSelect1
                      options={categoryOption.map((c, i) => {
                        return {
                          value: c?.id?c?.id:0,
                          label: c?.name?c?.name:'',
                          imgUrl: "",
                        };
                      })}
                      selectedOptions={catDrop}
                      setSelectedOptions={handleCatDrop}
                      placeHolder="Select Trade Category"
                      onClear={handleClearCat}
                      disabled = {disableLocality}
                    />
              </div>
              <div className="col-sm-6 col-md-4 col-lg-3">
                {/* <FormInput2
                  name="Trade Sub Category"
                  label=""
                  labelClassName="required"
                  value={subCategories}
                  onChange={handleSubCategoryChange}
                  placeholder="Select Trade Sub Category"
                  containerClass="mb-1"
                  type="select"
                >
                  <option value="">Select Trade Sub Category</option>
                  {subCategoryFilteredByCategory?.map((s, i) => {
                    return (
                      <Option value={s?.id} key={i}>
                        {s?.name}
                      </Option>
                    );
                  })}
                </FormInput2> */}
                 {/* <SingleSelect
                  options={[
                    defaultTradeSubCategory,
                    ...subCategoryFilteredByCategory?.sort(function (a, b) {
                      if (a?.name < b?.name) {
                        return -1;
                      }
                      if (a?.name > b?.name) {
                        return 1;
                      }
                      return 0;
                    })?.map((s, i) => {
                      return (
                        {
                          value: s?.id ? s?.id?.toString() : '',
                          label: s?.name,
                          imgUrl: '',
                        }
                      );
                    })
                  ]}
                  label=""
                  selectedOptions={subCategoriesOption}
                  setSelectedOptions={handleSubCategoryChange}
                  // disabled={disableSite}
                  disabled={disableLocality}
                  placeHolder="Select Trade Sub Category"
                  // filterText=""
                /> */}
                   <SingleSelect1
                      options={subCategoryFilteredByCategory.map((c, i) => {
                        return {
                          value: c?.id?c?.id:0,
                          label: c?.name?c?.name:'',
                          imgUrl: "",
                        };
                      })}
                      selectedOptions={subcatDrop}
                      setSelectedOptions={handleSubcatDrop}
                      placeHolder="Select Trade Sub Category"
                      onClear={handleClearSubcat}
                      disabled = {disableLocality}
                    />
              </div>
              <div className="col-sm-6 col-md-4 col-lg-3">
                {/* <FormInput2
                  name="Brand"
                  label=""
                  labelClassName="required"
                  value={brands}
                  onChange={handleBrandChange}
                  placeholder="Select Brand"
                  containerClass="mb-1"
                  type="select"
                >
                  <option value="">Select Brand</option>
                  {BrandFilteredByCompany?.sort(function (a, b) {
                    if (a?.name < b?.name) {
                      return -1;
                    }
                    if (a?.name > b?.name) {
                      return 1;
                    }
                    return 0;
                  })?.map((s, i) => {
                    return (
                      <Option value={s?.id} key={i}>
                        {s?.name}
                      </Option>
                    );
                  })}
                </FormInput2> */}
                {/* <SingleSelect
                  options={[
                    defaultbrand,
                    ...BrandFilteredByCompany?.sort(function (a, b) {
                      if (a?.name < b?.name) {
                        return -1;
                      }
                      if (a?.name > b?.name) {
                        return 1;
                      }
                      return 0;
                    })?.map((s, i) => {
                      return (
                        {
                          value: s?.id ? s?.id?.toString() : '',
                          label: s?.name,
                          imgUrl: s?.logourl,
                        }
                      );
                    })
                  ]}
                  label=""
                  selectedOptions={brandsOption}
                  setSelectedOptions={handleBrandChange}
                  // disabled={disableSite}
                  disabled={disableLocality}
                  placeHolder="Select Brand"
                  // filterText=""
                /> */}
                   <SingleSelect1
                options={BrandFilteredByCompany.map((c, i) => {
        return {
          value: c?.id?c?.id:0,
          label: c?.name?c?.name:'',
          imgUrl: "",
        };
      })}
      selectedOptions={brandDrop}
      setSelectedOptions={handleBrandDrop}
      placeHolder="Select Brand"
                onClear={handleClearBrand}
                disabled = {disableLocality}
              />
              </div>
            </div>
          </div>
        </div>
      </div>

      {loader ? (
        <Loader />
      ) : (
        <>
          <div className="row">
            <div className="col-12">
              <div className="card mt-3 widget_div" onMouseEnter={() => setButtonVisible(true)} onMouseLeave={() => setButtonVisible(false)}>
                {(<button type="button" className="expandIconBtn" onClick={showExpandModalHandler} ><i><FontAwesomeIcon icon={faWindowMaximize} size="lg" title="Open in Popup"/></i></button>)}
                <div className="card-body">
                  <div className="row">
                    <div className="col-lg-12 col-sm-12">
                      <div className="float-start">
                        <h5 className="header-title">
                          <img src={chartIcon} alt="icon"></img>
                          Moving Annual Total
                        </h5>
                      </div>
                      <div className="float-end">
                        <button
                          type="button"
                          className="btn btn-primary"
                          onClick={handleApply}
                        >
                          Apply
                        </button>
                      </div>
                    </div>
                  </div>
                  <hr className="m-0 my-2 p-0 hr" />
                  {salesMATData[0]?.record?.length !== undefined &&
                    salesMATData[0]?.record?.length > 0 && (
                      <div>{"*Value in " + valueIn}</div>
                    )}

                  {salesMATData[0]?.record?.length !== undefined &&
                  salesMATData[0]?.record?.length > 0 ? (
                    <SalesMAT 
                      salesMATData={salesMATData}
                      yearDropDown={yearDropDown}
                      monthsArray={monthsArray}
                      getSubtitle={getSubtitle}
                    />
                  ) : (
                    <div className="d-flex flex-column justify-content-center align-items-center col-12 emptychart-container  ">
                      <p className="empty-data">No Records Found</p>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-12">
              <div className="card mt-3">
                <div className="card-body">
                  <div className="row mb-1">
                    <div className="col-lg-6 col-sm-12">
                      <div className="float-start">
                        <h5 className="header-title mt-1">
                          <img src={tableIcon} alt="icon"></img>
                          Moving Annual Total Report
                        </h5>
                      </div>
                    </div>
                    <div className="col-lg-6 col-sm-12">
                      <div className="float-end hstack gap-1">
                        
                      </div>
                    </div>
                  </div>

                  <TransactionTable
                    data={[]}
                    {...getTableData()}
                    title=""
                    downloadCSV={true}
                    downloadPDF={true}
                    fileName="Moving Annual Total Report"
                    limit={limit}
                    setOffset={setOffset}
                    onClickCSV={handleCsvDownload}
                    onClickPDF={handlePdfDownload}
                    range={range}
                    valueInString={valueIn}
                    onPageNoChange={handleApply}
                    handlePag={handlePag}
                    fLoader={fileLoader}
                  />
                </div>
              </div>
            </div>
          </div>

          {showModal && (
        <Modal
        headerText={"Moving Annual Total Report"}
        visible={showModal}
        onClose={() => setShowModal(false)}
        size="xl"
        centered
        classname="widget-container-single" > 
		<div className="card-body">
                  

                  {salesMATData[0]?.record?.length !== undefined &&
                  salesMATData[0]?.record?.length > 0 ? (
                    <SalesMAT 
                      salesMATData={salesMATData}
                      yearDropDown={yearDropDown}
                      monthsArray={monthsArray}
                      getSubtitle={getSubtitle}
                    />
                  ) : (
                    <div className="d-flex flex-column justify-content-center align-items-center col-12 emptychart-container  ">
                      <p className="empty-data">No Records Found</p>
                    </div>
                  )}
                </div>
		
		</Modal>)}
        </>
      )}
    </UserLayout>
  );
};
export default SalesMovingAnnualTotalReport;
