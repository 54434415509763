export enum ActiveDropDown {
    Active = "true",
    InActive = "false",
}
export type ActiveTypes = ActiveDropDown.Active | ActiveDropDown.InActive

export interface FloorType {
    id?: number
    portfolioid: string
    centerid: number
    centertype: string
    buildingid: number
    floorname: string
    floorcode: string
    active: ActiveTypes | boolean
    createdby?: string
    createdat?: string
    updatedat?: string
}

export enum FloorActionsList {
    ADD_FLOOR = 'ADD_FLOOR',
    EDIT_FLOOR = 'EDIT_FLOOR',
    FETCH_FLOOR = 'FETCH_FLOOR',
    DELETE_FLOOR = 'DELETE_FLOOR'
}

export interface DeleteFloor {
    id: number
}

export interface AddFloorAction {
    type: FloorActionsList.ADD_FLOOR
    data: FloorType
}

export interface EditFloorAction {
    type: FloorActionsList.EDIT_FLOOR
    data: FloorType
    id?: number
}

export interface FetchFloorAction {
    type: FloorActionsList.FETCH_FLOOR
    data: FloorType[]

}

export interface DeleteFloorAction {
    type: FloorActionsList.DELETE_FLOOR
    data: DeleteFloor
}

export type FloorActions = AddFloorAction | EditFloorAction | FetchFloorAction | DeleteFloorAction

