import { useEffect} from "react";
import { useDispatch, useSelector } from "react-redux";
import { AuthUserType, logoutUser } from "../redux/actions/authUserActions";
import { clearBrand } from "../redux/actions/brandActions";
import { AppDispatch, StoreState } from "../redux/store";
import { addToSelectedOption } from "../redux/actions/selectedOptionsActions";
import { clearPortfolio } from "../redux/actions/portfolioActions";
import { clearSite } from "../redux/actions/siteActions";
import { clearBrandCompany } from "../redux/actions/brandCompanyActions";
import { clearTradeCategory } from "../redux/actions/tradeCategoryActions";
import { clearsubTradeCategory } from "../redux/actions/tradeSubCategoryActions";
import { clearCountry } from "../redux/actions/countryActions";
import { clearRole } from "../redux/actions/roleActions";
import {
  clearCity,
  clearRegion,
  clearState,
} from "../redux/actions/commonActions";
import { clearOutlet } from "../redux/actions/outletActions";
import { clearBrandCompanyMapping } from "../redux/actions/brandCompanyMappingActions";
import { clearOutletAreas } from "../redux/actions/areaActions"
import { UserLog } from "../config/common";

const Logout = () => {
  const dispatch = useDispatch<AppDispatch>();
  const authUser = useSelector<StoreState, AuthUserType | null>(
    (state) => state.authUser
  );

  useEffect(()=>{
     
    if(authUser){
      const logProps1 = {
        action:`Logout`,
        event: 'Logged out'
     };
     UserLog(logProps1)
    }
  
},[])

  useEffect(() => {
    dispatch(logoutUser());
    dispatch(clearPortfolio());
    dispatch(clearSite());
    dispatch(clearBrandCompany());
    dispatch(clearBrand());
    dispatch(clearTradeCategory());
    dispatch(clearsubTradeCategory());
    dispatch(clearRole());
    dispatch(clearCountry());
    dispatch(clearRegion());
    dispatch(clearState());
    dispatch(clearCity());
    dispatch(clearOutlet());
    dispatch(clearBrandCompanyMapping());
    dispatch(clearOutletAreas());
    dispatch(
      addToSelectedOption({
        portfolio: "",
        site: "",
        brand: "",
        company: "",
        location: "",
        category: "",
        portfolios: [],
        sites: [],
        companies: [],
        brands: [],
        subCategory: "",
        buildings: [],
        floors: [],
        categories: [],
        subCategories: [],
        locations: [],
        country: "",
        region: "",
        state: "",
        city: "",
        showFilter: true,
        loader1:true,
        loader2:true,
        loader3:true,
        loader4:true,
        loader5:true,
        loader6:true,
        isOutletUser: false,
        ip:"",
        loginday:"",
        loginevent:"",
        email:"",
        isLogged:false,
      })
    );
  }, []);
  return <></>;
};
export default Logout;
