import { Action } from "../actions/types";
import { SelectedOptionsActionTypes } from "../actions/selectedOptionsActions";

interface DropdownOption {
  imgUrl: string;
  label: string;
  value: number;
}

export interface Options {
  portfolio: string;
  site: string;
  brand: string;
  company: string;
  location: string;
  category: string;
  subCategory: string;
  portfolios: DropdownOption[];
  sites: DropdownOption[];
  companies: DropdownOption[];
  brands: DropdownOption[];
  buildings: DropdownOption[];
  floors: DropdownOption[];
  categories: DropdownOption[];
  subCategories: DropdownOption[];
  locations: DropdownOption[];
  country: string;
  region: string;
  state: string;
  city: string;
  showFilter: boolean;
  loader1:boolean,
  loader2:boolean,
  loader3:boolean,
  loader4:boolean,
  loader5:boolean,
  loader6:boolean,
  isOutletUser: boolean
  ip:string;
  loginday:string,
  loginevent:string,
  email:string,
  isLogged:boolean
}

const initialState: Options = {
  portfolio: "",
  site: "",
  brand: "",
  company: "",
  location: "",
  category: "",
  portfolios: [],
  sites: [],
  companies: [],
  brands: [],
  subCategory: "",
  buildings: [],
  floors: [],
  categories: [],
  subCategories: [],
  locations: [],
  country: "",
  region: "",
  state: "",
  city: "",
  showFilter: true,
  loader1:true,
  loader2:true,
  loader3:true,
  loader4:true,
  loader5:true,
  loader6:true,
  isOutletUser: false,
  ip:"",
  loginday:"",
  loginevent:"",
  email:"",
  isLogged:false,
};

export const SelectedOptionsReducer = (
  state: Options = initialState,
  action: Action
): Options => {
  const newState = {
    ...state,
  };
  switch (action.type) {
    case SelectedOptionsActionTypes.ADD_SELECTED_OPTION: {
      return { ...newState, ...action.data };
    }

    default: {
      return state;
    }
  }
};
