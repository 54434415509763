import React, { useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { PortfolioDashboardList, PortfolioDashboardType } from "../../config";
import api, { APIResponse, getAxiosRequestConfig } from "../../config/api";
import { YearOptions } from "../../config/functions";
import { AuthUserType } from "../../redux/actions/authUserActions";
// import { PortfolioType } from "../../redux/actions/portfolioActions";
import { StoreState } from "../../redux/store";
import { KpiViewList } from "../commonWidget";
import Widget1 from "../custom/components/Widget1";
import { COLORS } from "../custom/constants";
import { BrandType } from "../../redux/actions/brandActions";
import MultipleYAxisChart from "../custom/components/charts/apex/MultipleYAxis";
import { outletType } from "../../redux/actions/outletActions";
import RetailPerformanceTable from "../custom/components/RetailPerformanceTable";
import Modal from "../Modal";

interface RecordsForYear {
  outletid: number;
  brandid: number;
  transyear: number;
  footfall: string;
  sales: number;
  percentchange?: number | null;
  footfallPercent?: number;
}

interface YearlyRecord {
  portfolio: number;
  record: RecordsForYear[];
}

interface Props {
  portfolioId: number;
  brandId: number;
  outletId: string[];
  expandState: React.Dispatch<React.SetStateAction<boolean>>;
  commonApiEnabler?:boolean
}

const BrandDashboardFootfallWidget: React.FC<Props> = (props: Props) => {
  const authUser = useSelector<StoreState, AuthUserType | null>(
    (state) => state?.authUser
  );
  const authToken = authUser?.["auth-token"]!;
  const config = getAxiosRequestConfig(authToken);

  const brandList = useSelector<StoreState, Array<BrandType>>(
    (state) => state?.brand
  );
  // const portfolioOption = useSelector<StoreState, PortfolioType[]>(
  //   (state) => state?.portfolio
  // );

  // const brandOption = brandList?.filter(
  //   (a) => a?.id?.toString() === authUser?.brandId?.toString()
  // );

  const outletList = useSelector<StoreState, outletType[]>(
    (state) => state?.outlet
  );

  const outletIndex = outletList?.findIndex(
    (a) => a?.id?.toString() === props?.outletId?.toString()
  );
  const propOutname = outletList[outletIndex]?.outletcode;

  const [yearlyData, setYearlyData] = useState<YearlyRecord[]>([]);

  const [selection, setSelection] = useState<PortfolioDashboardType>(
    PortfolioDashboardList?.MOM
  );
  const [view, setView] = useState<string>(KpiViewList?.CHART);
  const [showTableModal, setShowTableModal] = useState<boolean>(false);

  const yearList = YearOptions();
  const showExpandHandler = () => {
    props?.expandState((prevState) => !prevState)
  };
  /*const momHandler = () => {
    setSelection(PortfolioDashboardList?.MOM);
  };
  const mtdHandler = () => {
    setSelection(PortfolioDashboardList?.MTD);
  };
  const yoyHandler = () => {
    setSelection(PortfolioDashboardList?.YOY);
  };
  const ytdHandler = () => {
    setSelection(PortfolioDashboardList?.YTD);
  };*/
  const chartViewHandler = () => {
    setView(KpiViewList?.CHART);
  };
  const tableViewHandler = () => {
    setView(KpiViewList?.TABLE);
  };

  const showGridHandler = () => {
    // props?.expandState(true)
    setShowTableModal(true)
    // setView(KpiViewList?.TABLE)
  };

  const YearlyFilteredData = useMemo<YearlyRecord[]>(() => {
    const data: YearlyRecord[] = [];

    yearlyData?.forEach((d) => {
      data?.push({
        portfolio: d?.portfolio,
        record: yearList
          ?.sort()
          ?.map((yr) => {
            return d?.record?.filter((r) => {
              return yr === r?.transyear;
            });
          })
          ?.flat(1),
      });
    });
    return data;
  }, [yearlyData, yearList]);

  useEffect(() => {
    //yearly
    var getData = {
      portfolio: props?.portfolioId ? [props?.portfolioId] : [],
      brand: props?.brandId ? props?.brandId : "",
      site: props?.outletId ? props?.outletId : "",
      authUserRole: authUser?.roleId ? authUser?.roleId : "",
    };

    if (
      getData?.portfolio?.length > 0 &&
      props?.portfolioId &&
      getData?.brand !== undefined &&
      getData !== undefined &&
      getData?.site !== undefined &&
      getData?.brand !== "" && props?.commonApiEnabler
    ) {
      // kpi/portfolio/sales/monthly
      api
        .post<APIResponse<{ records: YearlyRecord[] }>>(
          `kpi/outlet/yearlysalesconversion`,
          getData,
          config
        )
        .then((response) => {
          if (response?.status === 200 && response?.data?.data) {
            const result = response?.data?.data?.records;
            setYearlyData(result);
          }
          else {setYearlyData([]);}
        })
        .catch((error) => {});
    } 
  }, [props?.portfolioId, props?.outletId, props?.brandId,props?.commonApiEnabler]);

  const colors = [
    COLORS?.COLOR1,
    COLORS?.COLOR2,
    COLORS?.COLOR3,
    COLORS?.COLOR4,
    COLORS?.COLOR5,
    COLORS?.COLOR6,
    COLORS?.COLOR7,
    COLORS?.COLOR8,
  ];

  const getYearlyYOYData = () => {
    var monthlyTableData: string[][] = [
      [
        "Year",
        "Footfall",
        'Sales',
        '% Change'
      ],
    ];

    yearList?.map((a)=>{
    yearlyData?.forEach((y, i) => {
          y?.record?.filter((b)=>b?.transyear?.toString()===a?.toString())
            ?.forEach((r) => {
                let sum = []
              sum?.push(r?.transyear?.toString());
              sum?.push(
                r?.footfall !== undefined && r?.footfall !== null ? r?.footfall?.toString() : "-"
              );
              sum?.push(
                r?.sales !== undefined && r?.sales !== null ? r?.sales?.toString() : "-"
              );
              sum?.push(
                r?.percentchange !== undefined && r?.percentchange !== null
                  ? parseFloat(r?.percentchange?.toString()) > 0
                    ? +r?.percentchange?.toString() + "%"
                    : r?.percentchange?.toString() + "%"
                  : r?.percentchange=== null
                  ? "-"
                  : "-"
              );
                monthlyTableData?.push(sum);
        });
    });
  })
    return monthlyTableData;
  };

  return (
    <Widget1
      title={"Footfall vs Sales"}
      tableViewHandler={tableViewHandler}
      chartViewHandler={chartViewHandler}
      selection={selection}
      view={view}
      viewBtns={false}
      showExpandModal={true}
      showExpand={true}
      showExpandHandler={showExpandHandler}
      showGridHandler={showGridHandler}
      showGrid={true}
    >
      <MultipleYAxisChart
        datasets={(() => {
          let ydata: { name: string; type: string; data: number[] }[] = [
            {
              name: "",
              type: "column",
              data: [],
            },
            {
              name: "sales",
              type: "line",
              data: [],
            },
          ];

          YearlyFilteredData?.forEach((y, i) => {
            // const index = brandList?.findIndex(
            //   (a) => a?.id?.toString() === authUser?.brandId?.toString()
            // );
            // const name = brandList[index]?.name;

            let data2: number[] = [];
            let sales: number[] = [];
            let d1 = y?.record;
            yearList
              ?.map((f) => f)
              ?.sort()
              ?.forEach((yr) => {
                var yrs = d1?.map((d) => d?.transyear);
                if (yrs?.includes(yr)) {
                  let obj = d1?.find((f) => f?.transyear === yr);
                  data2?.push(obj?.footfall ? parseFloat(obj?.footfall) : 0);
                  sales?.push(obj?.sales ? obj?.sales : 0);
                } else {
                  data2?.push(0);
                  sales?.push(0);
                }
              });

            if (data2?.length > 0) {
              ydata[0].name = "Footfall";
              ydata[0].data = data2;
              ydata[1].data = sales;
            }
          });
          return ydata;
        })()}
        labels={yearList?.map((y) => y?.toString())?.sort() || []}
        colors={[
          colors[0],
          colors[1],
          colors[2],
          colors[3],
          colors[4],
          colors[5],
          colors[6],
          colors[7],
        ]}
        title={"Footfall vs Sales"}
        subtitle={(() => {
          let subtxt = ``;
          if (propOutname !== undefined) {
            subtxt += `\u3010Outlet\u3011: ${propOutname}\u2003`;
          }
          subtxt += `\u3010Period\u3011: ${yearList[0]} - ${yearList[2]}\u2003`;
          return subtxt;
        })()}
        exportfilename={"FootfallvsSales"}
      />

                  
{showTableModal && (
              <Modal
              size="xl"
              visible = {showTableModal}
              onClose={() => setShowTableModal(false)}
              centered classname="grid-container"
              >
              <RetailPerformanceTable
                    data={getYearlyYOYData()}
                    title={`Footfall `}
                    fileName={"pf_monthly_sales"}
                    downloadCSV={false}
                    downloadPDF={false}
                  />
              </Modal>
        )}
    </Widget1>
  );
};
export default BrandDashboardFootfallWidget;
