import { Dispatch } from "redux"
import api, { APIResponse, getAxiosRequestConfig } from "../../config/api"
import { GetState } from "../store"

export enum ActiveDropDown {
    Active = "true",
    InActive = "false",

}
export type ActiveTypes = ActiveDropDown.Active | ActiveDropDown.InActive | ''

export interface BuildingType {
    id?: number
    portfolioid: string
    centerid: number
    centertype: string
    buildingname: string
    code: string
    active: ActiveTypes | boolean
    createdby?: string
    createdat?: string
    updatedat?: string
}

export enum BuildingActionsList {
    ADD_BUILDING = 'ADD_BUILDING',
    EDIT_BUILDING = 'EDIT_BUILDING',
    FETCH_BUILDING = 'FETCH_BUILDING',
    DELETE_BUILDING = 'DELETE_BUILDING'
}

export interface DeleteBuilding {
    id: number
}

export interface AddBuildingAction {
    type: BuildingActionsList.ADD_BUILDING
    data: BuildingType
}

export interface EditBuildingAction {
    type: BuildingActionsList.EDIT_BUILDING
    data: BuildingType
    id?: number
}

export interface FetchBuildingAction {
    type: BuildingActionsList.FETCH_BUILDING
    data: BuildingType[]

}

export interface DeleteBuildingAction {
    type: BuildingActionsList.DELETE_BUILDING
    data: DeleteBuilding
}

export type BuildingActions = AddBuildingAction | EditBuildingAction | FetchBuildingAction | DeleteBuildingAction

export const fetchBuilding = (portfolioId: string) => {
    return async (dispatch: Dispatch, getState: GetState) => {

        const token = getState().authUser?.['auth-token']!
        const config = getAxiosRequestConfig(token)

        return api.get<APIResponse<BuildingType[]>>(`building/${portfolioId}`, config).then(response => {
            if (response.status === 200 && response.data.data) {
                dispatch<FetchBuildingAction>({
                    type: BuildingActionsList.FETCH_BUILDING,
                    data: response.data.data
                })
                return Promise.resolve(response.data.message
                    ? response.data.message
                    : 'Fetch Successfull')
            } else {
                // throw { response }
                throw new Error("Unable to fetch")
            }
        }).catch(error => {
            return Promise.reject(error.response
                ? error.response.data.message
                    ? error.response.data.message
                    : 'Unable to fetch'
                : 'Unable to fetch')
        })
    }
}