import jsPDF from "jspdf";
import autoTable, { RowInput } from "jspdf-autotable";
import { EMAIL, PHONE_NUMBER, CODE } from ".";

export const testNumber = (number: string) => PHONE_NUMBER.test(number);
export const testEmail = (email: string) => EMAIL.test(email);
export const testCode = (code: string) => CODE.test(code);

export const FormatDateToYear = (date: string) => {
  const dateObj = new Date(date);
  // const day = dateObj.getDate()?.toString().padStart(2, '0')
  // const month = (dateObj.getMonth() + 1)?.toString().padStart(2, '0')
  const year = dateObj.getFullYear();
  return year;
  // return [year, month, day].join('-')//2023-12-02
};

export const today = (): string => {
  const dateObj = new Date();
  const date = dateObj.getDate()?.toString().padStart(2, "0");
  const month = (dateObj.getMonth() + 1)?.toString().padStart(2, "0");
  const year = dateObj.getFullYear();

  return [year, month, date].join("-");
};

export const timestampToDate = (timestamp: string): string => {
  const dateObj = new Date(timestamp);
  const date = dateObj.getDate()?.toString().padStart(2, "0");
  const month = (dateObj.getMonth() + 1)?.toString().padStart(2, "0");
  const year = dateObj.getFullYear();

  return [year, month, date].join("-");
};

export const getYesterday = (): string => {
  const d = new Date();
  d.setDate(d.getDate() - 1);
  const date = d.getDate()?.toString().padStart(2, "0");
  const month = (d.getMonth() + 1)?.toString().padStart(2, "0");
  const year = d.getFullYear();

  return [year, month, date].join("-");
  // return d;
};

interface PDFProps {
  head: RowInput[] | undefined;
  body: RowInput[] | undefined;
  fileName: string;
  title: string;
  revenueShow?: boolean;
}

export const handleDownloadPDF = (props: PDFProps) => {
  const doc = new jsPDF("l", "mm", [460, 210]);
  doc.setFontSize(18);
  doc.text(props.title, 180, 13);
  autoTable(doc, {
    head: props.head,
    body: props.body,
    rowPageBreak: "avoid",
    // foot: foot,
    startY: 20,
    didDrawPage: function () {
      // // Header
      var str = doc.getNumberOfPages();
      doc.setFontSize(10);
      // jsPDF 1.4+ uses getWidth, <1.4 uses .width
      // var pageSize = doc.internal.pageSize;
      // var pageHeight = pageSize.height
      //     ? pageSize.height
      //     : pageSize.getHeight();
      doc.text(str?.toString(), 360, 10);
    },
  });
  doc.save(props.fileName + ".pdf");
};

export const handleDownloadPDFForMissingReport = (props: PDFProps) => {
  const doc = new jsPDF("l", "mm", [460, 210]);
  doc.setFontSize(18);
  doc.text(props.title, 180, 13);
  autoTable(doc, {
    columnStyles: {
      0: { cellWidth: 30 },
      1: { cellWidth: 30 },
      2: { cellWidth: 30 },
      3: { cellWidth: 30 },
      4: { cellWidth: 275 },
      5: { cellWidth: 30 },
    },
    head: props.head,
    body: props.body,
    rowPageBreak: "avoid",
    // foot: foot,
    startY: 20,
    didDrawPage: function () {
      // // Header
      var str = doc.getNumberOfPages();
      doc.setFontSize(10);
      // jsPDF 1.4+ uses getWidth, <1.4 uses .width
      // var pageSize = doc.internal.pageSize;
      // var pageHeight = pageSize.height
      //     ? pageSize.height
      //     : pageSize.getHeight();
      doc.text(str?.toString(), 360, 10);
    },
  });
  doc.save(props.fileName + ".pdf");
};

export const handleDownloadPDFForConsolidatedSalesReport = (
  props: PDFProps
) => {
  const doc = new jsPDF("l", "mm", [460, 210]);
  doc.setFontSize(18);
  doc.text(props.title, 180, 13);
  autoTable(doc, {
    columnStyles: {
      0: { cellWidth: 20 },
      1: { cellWidth: 20 },
      2: { cellWidth: 20 },
      3: { cellWidth: 20 },
      4: { cellWidth: 20 },
      5: { cellWidth: 20 },
      6: { cellWidth: 20 },
      7: { cellWidth: 20 },
      8: { cellWidth: 10 },
      9: { cellWidth: 20 },
      10: { cellWidth: 20 },
      11: { cellWidth: 20 },
      12: { cellWidth: 20 },
      13: { cellWidth: 20 },
      14: (props.revenueShow) ? { cellWidth: 20 } : { cellWidth: 158 },
      15: { cellWidth: 20 },
      16: { cellWidth: 158 },
    },
    head: props.head,
    body: props.body,
    rowPageBreak: "avoid",
    // foot: foot,
    startY: 20,
    didDrawPage: function () {
      // // Header
      var str = doc.getNumberOfPages();
      doc.setFontSize(10);
      // jsPDF 1.4+ uses getWidth, <1.4 uses .width
      // var pageSize = doc.internal.pageSize;
      // var pageHeight = pageSize.height
      //     ? pageSize.height
      //     : pageSize.getHeight();
      doc.text(str?.toString(), 360, 10);
    },
  });
  doc.save(props.fileName + ".pdf");
};

export const handleDownloadPDF1 = (props: PDFProps) => {
  const doc = new jsPDF("l", "mm", [950, 450]);
  doc.setFontSize(18);

  var pageWidth = doc.internal.pageSize.getWidth();
  var headingWidth =
    (doc.getStringUnitWidth(props.title) * 18) / doc.internal.scaleFactor;
  var xPos = (pageWidth - headingWidth) / 2;

  doc.text(props.title, xPos, 13);

  autoTable(doc, {
    head: props.head,
    body: props.body,

    // foot: foot,

    startY: 20,
    didDrawPage: function () {
      // // Header
      var str = doc.getNumberOfPages();
      doc.setFontSize(10);
      // jsPDF 1.4+ uses getWidth, <1.4 uses .width
      // var pageSize = doc.internal.pageSize;
      // var pageHeight = pageSize.height
      //     ? pageSize.height
      //     : pageSize.getHeight();
      doc.text(str?.toString(), xPos + 200, 13);
    },
  });
  doc.save(props.fileName + ".pdf");
};

interface CSVProps {
  rows: string[][];
  fileName: string;
}

export const CSVdownload = (props: CSVProps) => {
  const rows = props.rows;

  let csvContent = "data:text/csv;charset=utf-8,";

  rows.forEach(function (rowArray) {
    let row = rowArray.join(",");
    csvContent += row + "\r\n";
  });

  var encodedUri = encodeURI(csvContent);
  var link = document.createElement("a");
  link.setAttribute("href", encodedUri);
  link.setAttribute("download", props.fileName + ".csv");
  document.body.appendChild(link);

  link.click();

  document.body.removeChild(link);
};
//3 yrs
export const YearOptions = () => {
  const d = new Date();
  const currentYear = d.getFullYear()?.toString();

  let a = [parseInt(currentYear)];

  for (let i = 1; i < 3; i++) {
    let year = parseInt(currentYear) - i;
    a.push(year);
  }

  return a;
};

export const getDayString = (gdate: string): string => {
  let res = gdate.split("-");
  let daystring = res[1] + "-" + res[2];
  return daystring;
};


export const today1 = (): string => {
  const dateObj = new Date();
  const date = dateObj.getDate().toString().padStart(2, "0");
  const month = (dateObj.getMonth() + 1).toString().padStart(2, "0");
  const year = dateObj.getFullYear();

  return [year,month,date].join("/");
};

export const timestamp = (): string => {
  const dateObj = new Date();
  const hours = dateObj.getHours().toString().padStart(2, "0");
  const minutes = dateObj.getMinutes().toString().padStart(2, "0");
  const seconds = dateObj.getSeconds().toString().padStart(2, "0"); 
  const sun = ""; //parseInt(hours) >= 12 ? 'pm' : 'am';

  return [hours, minutes, seconds + sun].join(":");
};

export const checkLeapYear = (year:number): boolean => {
  //three conditions to find out the leap year
  if ((0 == year % 4) && (0 != year % 100) || (0 == year % 400)) {
      return true
  } else {
      return false
  }
}

export const getWeek = (): number => {
  const now = new Date();
  const d = new Date(Date.UTC(now.getFullYear(), now.getMonth(), now.getDate()));
  d.setUTCDate(d.getUTCDate() + 4 - (d.getUTCDay() || 7));  
  const yearStart = new Date(Date.UTC(d.getUTCFullYear(), 0, 1));
  const millisBetweenDates = d.getTime() - yearStart.getTime();
  const daysBetweenDates = millisBetweenDates / 86400000;
  const weekNo = Math.ceil((daysBetweenDates + 1) / 7);
  return weekNo;
};

export const getPrevWeek = (): number => {
  const now = new Date();
  const year = now.getFullYear()-1
  const result = checkLeapYear(year)
  const currentweek = getWeek()
  if(currentweek===1){
    if(result){
      return 51
    }else{
      return 52
    }
  }else{
    return currentweek-1
  }
}

export const getPrevPrevWeek = (): number => {
  const now = new Date();
  const year = now.getFullYear()-1
  const result = checkLeapYear(year)
  const currentweek = getWeek()
  if(currentweek===2){
    if(result){
      return 51
    }else{
      return 52
    }
  }else{
    return currentweek-2
  }
}




// export const convertToInternationalCurrencySystem = (labelValue: string | number) => {

//     // Nine Zeroes for Billions
//     return Math.abs(Number(labelValue)) >= 1.0e+9

//         ? (Math.abs(Number(labelValue)) / 1.0e+9).toFixed(2) + "B"
//         // Six Zeroes for Millions
//         : Math.abs(Number(labelValue)) >= 1.0e+6

//             ? (Math.abs(Number(labelValue)) / 1.0e+6).toFixed(2) + "M"
//             // Three Zeroes for Thousands
//             : Math.abs(Number(labelValue)) >= 1.0e+3

//                 ? (Math.abs(Number(labelValue)) / 1.0e+3).toFixed(2) + "K"

//                 : Math.abs(Number(labelValue));

// }
