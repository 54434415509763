export enum COLORS {
    'COLOR1' = '#763EEC',
    //    'COLOR1'='#286AF3',
    'COLOR2' = '#3CC88A',
    'COLOR3' = '#DC6195',
    'COLOR4' = '#F3AB3B',
    'COLOR5' = '#52CCE5',
    'COLOR6' = '#F2575C',
    'COLOR7' = '#F46FA5',
    'COLOR8' = '#274178',    
    'COLOR9' = '#A6E4EF',
    'COLOR10' = '#38AB62',
    'COLOR11' = '#007FFF',
    'COLOR12' = '#95FF4B',
    'COLOR13' = '#EEA0EC',
    'COLOR14' = '#F0F8FF',
    'COLOR15' = '#FAEBD7',
    'COLOR16' = '#00FFFF',
    'COLOR17' = '#D9B99B',
    'COLOR18' = '#FFE4C4',
    'COLOR19' = '#003F5C',
    'COLOR20' = '#FF7C43',
    'COLOR21' = '#7FFFD4',
    'COLOR22' = '#F01AD3',
    'COLOR23' = '#000000',
    // ''='BlanchedAlmond',
    // ''='Blue',
    // ''='BlueViolet',
    // ''='Brown',
    // ''='BurlyWood',
    // ''='CadetBlue',
    // ''='Chartreuse',
    // ''='Chocolate',
    // ''='Coral',
    // ''='CornflowerBlue',
    // ''='Cornsilk',
    // ''='Crimson',
    // ''='Cyan',
    // ''='DarkBlue',
    // ''='DarkCyan',
    // ''='DarkGoldenRod',
    // ''='DarkGray',
    // ''='DarkGreen',
    // ''='DarkKhaki',
    // ''='DarkMagenta',
    // ''='DarkOliveGreen',
    // ''='DarkOrange',
    // ''='DarkOrchid',
    // ''='DarkRed',
    // ''='DarkSalmon',
    // ''='DarkSeaGreen',
    // ''='DarkSlateBlue',
    // ''='DarkSlateGray',
    // ''='DarkTurquoise',
    // ''='DarkViolet',
    // ''='DeepPink',
    // ''='DeepSkyBlue',
    // ''='DimGray',
    // ''='DodgerBlue',
    // ''='FireBrick',
    // ''='FloralWhite',
    // ''='ForestGreen',
    // ''='Fuchsia',
    // ''='Gainsboro',
    // ''='GhostWhite',
    // ''='Gold',
    // ''='GoldenRod',
    // ''='Gray',
    // ''='Green',
    // ''='GreenYellow',
    // ''='HoneyDew',
    // ''='HotPink',
    // ''='IndianRed',
    // ''='Indigo',
    // ''='Ivory',
    // ''='Khaki',
    // ''='Lavender',
    // ''='LavenderBlush',
    // ''='LawnGreen',
    // ''='LemonChiffon',
    // ''='LightBlue',
    // ''='LightCoral',
    // ''='LightCyan',
    // ''='LightGoldenRodYellow',
    // ''='LightGray',
    // ''='LightGreen',
    // ''='LightPink',
    // ''='LightSalmon',
    // ''='LightSeaGreen',
    // ''='LightSkyBlue',
    // ''='LightSlateGray',
    // ''='LightYellow',
    // ''='Lime',
    // ''='LimeGreen',
    // ''='Linen',
    // ''='Magenta',
    // ''='Maroon',
    // ''='MediumAquaMarine',
    // ''='MediumBlue',
    // ''='MediumOrchid',
    // ''='MediumPurple',
    // ''='MediumSeaGreen',
    // ''='MediumSlateBlue',
    // ''='MediumSpringGreen',
    // ''='MediumTurquoise',
    // ''='MediumVioletRed',
    // ''='MidnightBlue',
    // ''='MintCream',
    // ''='MistyRose',
    // ''='Moccasin',
    // ''='NavajoWhite',
    // ''='Navy',
    // ''='OldLace',
    // ''='Olive',
    // ''='OliveDrab',
    // ''='Orange',
    // ''='OrangeRed',
    // ''='Orchid',
    // ''='PaleGoldenRod',
    // ''='PaleGreen',
    // ''='PaleTurquoise',
    // ''='PaleVioletRed',
    // ''='PapayaWhip',
    // ''='PeachPuff',
    // ''='Peru',
    // ''='Pink',
    // ''='Plum',
    // ''='PowderBlue',
    // ''='Purple',
    // ''='RebeccaPurple',
    // ''='Red',
    // ''='RosyBrown',
    // ''='RoyalBlue',
    // ''='SaddleBrown',
    // ''='Salmon',
    // ''='SandyBrown',
    // ''='SeaGreen',
    // ''='SeaShell',
    // ''='Sienna',
    // ''='Silver',
    // ''='SkyBlue',
    // ''='SlateBlue',
    // ''='SlateGray',
    // ''='Snow',
    // ''='SpringGreen',
    // ''='SteelBlue',
    // ''='Tan',
    // ''='Teal',
    // ''='Thistle',
    // ''='Tomato',
    // ''='Turquoise',
    // ''='Violet',
    // ''='Wheat',
    // ''='White',
    // ''='WhiteSmoke',
    // ''='Yellow',
    // ''='YellowGreen',
}