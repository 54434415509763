import React, { useEffect, useState } from "react";
import BrandDashboardTopBrandsalesWidget from "./BrandDashboardTopBrandSalesWidget"
import BrandDashboardBrandContributionWidget from "./BrandDashboardBrandContributionWidget"
import BrandDashboardCategoryContributionWidget from "./BrandDashboardCategoryContributionWidget"
import BrandDashboardCategorySalesWidget from "./BrandDashboardCategorySalesWidget"
import BrandDashboardCatSalesCompWidget from "./BrandDashboardCatSalesCompWidget"
import BrandDashboardSubcategorySalesWidget from "./BrandDashboardSubcategorySalesWidget"
import BrandDashboardCatSalesTDCompWidget from "./BrandDashboardCatSalesTilldateCompWidget"


interface props {
    portfolioId: number
    siteId: string[]
    tradeCategory?: number[]
    tradeSubCategory?: number[]
    country?: number[]
    region?: number[]
    state?: number[]
    city?: number[]
    widgetLoader1?:React.Dispatch<React.SetStateAction<boolean>>;
    widgetLoader2?:React.Dispatch<React.SetStateAction<boolean>>;
    apiEnabler1?:boolean
    apiEnabler2?:boolean
}
const BrandDashboardIndex = (props: props) => {
  const [isexpand1, setIsexpand1] = useState<boolean>(false);
  const [isexpand2, setIsexpand2] = useState<boolean>(false);
  const [isexpand3, setIsexpand3] = useState<boolean>(false);
  const [isexpand4, setIsexpand4] = useState<boolean>(false);
  const [isexpand5, setIsexpand5] = useState<boolean>(false);
  const [isexpand6, setIsexpand6] = useState<boolean>(false);
  const [isexpand7, setIsexpand7] = useState<boolean>(false);
  const [loader1,setLoader1] = useState<boolean>(true)
  const [loader2,setLoader2] = useState<boolean>(true)
  const [commonApiEnabler,setCommonApiEnabler] = useState<boolean>(false)



  useEffect(()=>{
    if(loader1===false){
      props.widgetLoader1!((prevState)=>!prevState)
    }
  },[loader1])
  
  useEffect(()=>{
    if(loader2===false){
      props.widgetLoader2!((prevState)=>!prevState)
    }
  },[loader2])


  useEffect(()=>{
    if(loader1===false&&loader2===false){
      setCommonApiEnabler(true)
    }
  },[loader1,loader2])
  

    return (
        <>
        <div className="row">
            <div className={isexpand1 ? "col-xl-12 col-lg-12 col-md-12 me-0":"col-xl-6 col-lg-12 col-md-12 me-0"}>
                <BrandDashboardTopBrandsalesWidget
                    portfolioId={props.portfolioId}
                    siteId={props.siteId}
                    tradeCategory={props.tradeCategory}
                    tradeSubCategory={props.tradeSubCategory}
                    country={props.country}
                    region={props.region}
                    state={props.state}
                    city={props.city}
                    expandState={setIsexpand1}
                    widgetLoader1={setLoader1}
                    apiEnabler1 = {props.apiEnabler1}
                    
                />
            </div>
            <div className={isexpand2 ? "col-xl-12 col-lg-12 col-md-12 me-0":"col-xl-6 col-lg-12 col-md-12 me-0"}>
                <BrandDashboardBrandContributionWidget
                    portfolioId={props.portfolioId}
                    siteId={props.siteId}
                    tradeCategory={props.tradeCategory}
                    tradeSubCategory={props.tradeSubCategory}
                    country={props.country}
                    region={props.region}
                    state={props.state}
                    city={props.city}
                    expandState={setIsexpand2}
                    widgetLoader2={setLoader2}
                    apiEnabler2 = {props.apiEnabler2}
                    commonApiEnabler = {commonApiEnabler}
                />
            </div>

            <div className={isexpand3 ? "col-xl-12 col-lg-12 col-md-12 me-0":"col-xl-6 col-lg-12 col-md-12 me-0"}>
                <BrandDashboardCategoryContributionWidget
                    portfolioId={props.portfolioId}
                    siteId={props.siteId}
                    tradeCategory={props.tradeCategory}
                    tradeSubCategory={props.tradeSubCategory}
                    country={props.country}
                    region={props.region}
                    state={props.state}
                    city={props.city}
                    expandState={setIsexpand3}
                    commonApiEnabler = {commonApiEnabler}
                />
            </div>

            <div className={isexpand4 ? "col-xl-12 col-lg-12 col-md-12 me-0":"col-xl-6 col-lg-12 col-md-12 me-0"}>
                <BrandDashboardCategorySalesWidget
                    portfolioId={props.portfolioId}
                    siteId={props.siteId}
                    tradeCategory={props.tradeCategory}
                    tradeSubCategory={props.tradeSubCategory}
                    country={props.country}
                    region={props.region}
                    state={props.state}
                    city={props.city}
                    expandState={setIsexpand4}
                    commonApiEnabler = {commonApiEnabler}
                />
            </div>

            <div className={isexpand5 ? "col-xl-12 col-lg-12 col-md-12 me-0":"col-xl-6 col-lg-12 col-md-12 me-0"}>
                <BrandDashboardCatSalesCompWidget
                    portfolioId={props.portfolioId}
                    siteId={props.siteId}
                    tradeCategory={props.tradeCategory}
                    tradeSubCategory={props.tradeSubCategory}
                    country={props.country}
                    region={props.region}
                    state={props.state}
                    city={props.city}
                    expandState={setIsexpand5}
                    commonApiEnabler = {commonApiEnabler}
                />
            </div>

            <div className={isexpand6 ? "col-xl-12 col-lg-12 col-md-12 me-0":"col-xl-6 col-lg-12 col-md-12 me-0"}>
                <BrandDashboardCatSalesTDCompWidget
                    portfolioId={props.portfolioId}
                    siteId={props.siteId}
                    tradeCategory={props.tradeCategory}
                    tradeSubCategory={props.tradeSubCategory}
                    country={props.country}
                    region={props.region}
                    state={props.state}
                    city={props.city}
                    expandState={setIsexpand6}
                    commonApiEnabler = {commonApiEnabler}
                />
            </div>

            <div className={isexpand7 ? "col-xl-12 col-lg-12 col-md-12 me-0":"col-xl-6 col-lg-12 col-md-12 me-0"}>
                <BrandDashboardSubcategorySalesWidget
                    portfolioId={props.portfolioId}
                    siteId={props.siteId}
                    tradeCategory={props.tradeCategory}
                    tradeSubCategory={props.tradeSubCategory}
                    country={props.country}
                    region={props.region}
                    state={props.state}
                    city={props.city}
                    expandState={setIsexpand7}
                    commonApiEnabler = {commonApiEnabler}
                />
            </div>

            </div>
        </>


    )
}
export default BrandDashboardIndex