/* eslint-disable no-throw-literal */
import { Dispatch } from "redux"
import api, { APIResponse } from "../../config/api"
import { RoleType } from "./roleActions"

export interface LoginDataType {
    loginid: string
    password: string
}

// export interface AuthUserType {
//     id?: number
//     "auth-token"?: string
//     password?: string
//     status?: string
//     username: string
//     name: string
//     mobileNumber: string
//     email: string
//     role: string
// }

export interface AuthUserType {
    uid?: number
    roleId?: number
    "auth-token"?: string
    password?: string
    status?: string
    name: string
    email: string
    loginid: string
    mobileNumber?: string
    role: RoleType[]
    companyId?: string
    portfolioId?: string
    siteId?: string
    brandId?: string
    createdAt?: string
    updatedAt?: string
}

export enum UserActionList {
    LOGIN_USER = 'LOGIN_USER',
    LOGOUT_USER = 'LOGOUT_USER',
    SIGNUP_USER = 'SIGNUP_USER'
}

export interface SignupAction {
    type: UserActionList.SIGNUP_USER
    data: AuthUserType
}

export interface LoginAction {
    type: UserActionList.LOGIN_USER
    data: AuthUserType
}

export interface LogoutAction {
    type: UserActionList.LOGOUT_USER
    data: null
}

export type AuthUserActions = SignupAction | LoginAction | LogoutAction

export const signupUser = (data: AuthUserType) => {
    // api call
}

export const loginUser = (data: LoginDataType) => {
    return async (dispatch: Dispatch) => {
        // const data = { id:1,"auth-token":'dddd',password:'666666',status:'active',username: 'admin', name: 'admin', mobileNumber: '9943312345', email: 'test@test.com', role: 'admin' }

        return api.post<APIResponse<AuthUserType>>('user/login/', data).then(response => {

            if (response.status === 200 && response.data.data) {
                dispatch<LoginAction>({
                    type: UserActionList.LOGIN_USER,
                    data: response.data.data
                })
                // return Promise.resolve('Successfully logged in')
                return Promise.resolve(response.data.message
                    ? response.data.message
                    : 'Successfully logged in')
            } else {
                throw { response }
            }
        }).catch(error => {
            return Promise.reject(error.response
                ? error.response.data.message
                    ? error.response.data.message
                    : 'Unable to login'
                : 'Unable to login')
        })
    }
}

export const logoutUser = () => {
    return (dispatch: Dispatch) => {
        dispatch<LogoutAction>({
            type: UserActionList.LOGOUT_USER,
            data: null
        })
    }
}