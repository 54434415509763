import React, { useEffect, useMemo, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { PortfolioDashboardList, PortfolioDashboardType } from "../../config";
import api, { APIResponse, getAxiosRequestConfig } from "../../config/api";
import { AuthUserType } from "../../redux/actions/authUserActions";
import { StoreState } from "../../redux/store";
import { KpiViewList } from "../commonWidget";
import Widget1 from "../custom/components/Widget1";
import BarChart1 from "../custom/components/charts/apex/Barchart1";
import { COLORS } from "../custom/constants";
import { PortfolioType } from "../../redux/actions/portfolioActions";
import { YearOptions } from "../../config/functions";

import { BrandType } from "../../redux/actions/brandActions";
import {
  RegionType,
  StateType,
  CityType,
} from "../../redux/actions/commonActions";
import { CountryType } from "../../redux/actions/countryActions";
import RetailPerformanceTable from "../custom/components/RetailPerformanceTable";
import Modal from "../Modal";

interface RetailRecordsForMonth {
  sales: string;
  tenantid: number;
  outletcode: string;
  outletlocation: number;
  centerid?: number;
  transyear: number;
  transmonth: number;
}
interface RetailRecordsForYear {
  sales: string;
  tenantid: number;
  outletcode: string;
  outletlocation: number;
  centerid?: number;
  transyear: number;
}
interface MonthlyRecord {
  portfolio: number;
  record: RetailRecordsForMonth[];
}
interface YearlyRecord {
  portfolio: number;
  record: RetailRecordsForYear[];
}

interface Props {
  portfolioId: number;
  siteId: string[];
  brandId: number;
  country?: number[];
  region?: number[];
  state?: number[];
  city?: number[];
  expandState: React.Dispatch<React.SetStateAction<boolean>>;
  commonApiEnabler?:boolean
}

interface ChangedRefType {
  brand?:string
  country?:string
  region?:string
  state?:string
  city?:string
}

const BrandDashboardSalesByOutletLocWidget: React.FC<Props> = (
  props: Props
) => {
  const authUser = useSelector<StoreState, AuthUserType | null>(
    (state) => state?.authUser
  );
  const authToken = authUser?.["auth-token"]!;
  const config = getAxiosRequestConfig(authToken);
  const changedRef = useRef<ChangedRefType>({});

  const [apiCall1,setApiCall1]=  useState<boolean>(true)
  const [apiCall2,setApiCall2]=  useState<boolean>(true)

  const monthsArray = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];
  const OutletLocations = [
    {
      id: 1,
      name: "Sales (Metro City)",
    },
    {
      id: 2,
      name: "Sales (Non-Metro City)",
    },
  ];
  const brandOption = useSelector<StoreState, Array<BrandType>>(
    (state) => state?.brand
  );
  const portfolioOption = useSelector<StoreState, PortfolioType[]>(
    (state) => state?.portfolio
  );

  const propBrandIndex = brandOption?.findIndex(
    (p) => p?.id !== undefined && p?.id?.toString() === props?.brandId?.toString()
  );
  const propBrandName = brandOption[propBrandIndex]?.name;

  const countryList = useSelector<StoreState, CountryType[]>(
    (state) => state?.country
  );

  const regionList = useSelector<StoreState, RegionType[]>(
    (state) => state?.region
  );

  const stateList = useSelector<StoreState, StateType[]>(
    (state) => state?.state
  );

  const cityList = useSelector<StoreState, CityType[]>((state) => state?.city);

  const countryIndex = countryList?.findIndex(
    (a) => a?.id?.toString() === props?.country?.toString()
  );
  const propConame = countryList[countryIndex]?.countryname;

  const regionIndex = regionList?.findIndex(
    (a) => a?.id?.toString() === props?.region?.toString()
  );
  const propRename = regionList[regionIndex]?.regionname;

  const stateIndex = stateList?.findIndex(
    (a) => a?.id?.toString() === props?.state?.toString()
  );
  const propStname = stateList[stateIndex]?.statename;

  const cityIndex = cityList?.findIndex(
    (a) => a?.id?.toString() === props?.city?.toString()
  );
  const propCiname = cityList[cityIndex]?.cityname;

  const [yearlyData, setYearlyData] = useState<YearlyRecord[]>([]);
  const [monthlyData, setMonthlyData] = useState<MonthlyRecord[]>([]);

  const [selection, setSelection] = useState<PortfolioDashboardType>(
    PortfolioDashboardList?.MOM
  );
  const [view, setView] = useState<string>(KpiViewList?.CHART);
  const [valueIn, setValueIn] = useState<string>("");
  const [showTableModal, setShowTableModal] = useState<boolean>(false);

  // const currentYear = new Date()?.getFullYear();
  // const [year, setYear] = useState<string>(currentYear?.toString());

  const yearList = YearOptions();
  const showExpandHandler = () => {
    props?.expandState((prevState) => !prevState)
  };
  const momHandler = () => {
    setSelection(PortfolioDashboardList?.MOM);
  };
  const yoyHandler = () => {
    setSelection(PortfolioDashboardList?.YOY);
  };
  const chartViewHandler = () => {
    setView(KpiViewList?.CHART);
  };
  const tableViewHandler = () => {
    setView(KpiViewList?.TABLE);
  };

  const showGridHandler = () => {
    // props?.expandState(true)
    setShowTableModal(true)
    // setView(KpiViewList?.TABLE)
  };

  const YearlyFilteredData = useMemo<YearlyRecord[]>(() => {
    const data: YearlyRecord[] = [];
    yearlyData?.forEach((d) => {
      data?.push({
        portfolio: d?.portfolio,
        record: yearList
          ?.sort()
          ?.map((yr) => {
            return d?.record?.filter((r) => {
              return yr === r?.transyear;
            });
          })
          ?.flat(1),
      });
    });
    return data;
  }, [yearlyData, yearList]);

  const MonthlyFilteredData = useMemo<MonthlyRecord[]>(() => {
    const data: MonthlyRecord[] = [];
    monthlyData?.forEach((d) => {
      data?.push({
        portfolio: d?.portfolio,
        record: yearList
          ?.map((yr) => {
            return d?.record?.filter((r) => {
              return yr === r?.transyear;
            });
          })
          ?.flat(1),
      });
    });
    return data;
  }, [monthlyData, yearList]);

  useEffect(() => {
    var getData = {
      portfolio: props?.portfolioId ? [props?.portfolioId] : [],
      brand: props?.brandId ? [props?.brandId] : [],
      site: props?.siteId ? props?.siteId : [],
      country: props?.country,
      state: props?.state,
      region: props?.region,
      city: props?.city,
      authUserRole: authUser?.roleId ? authUser?.roleId : "",
    };
    if (
      getData?.portfolio?.length > 0 &&
      props?.portfolioId &&
      getData !== undefined && apiCall1 && props?.commonApiEnabler
    ) {
      api
        .post<APIResponse<{ records: YearlyRecord[] }>>(
          `kpi/brand-retail/outletLocation/yearly`,
          getData,
          config
        )
        .then((response) => {
          if (response?.status === 200 && response?.data?.data) {
            const result = response?.data?.data?.records;
            setYearlyData(result);
            setApiCall1(false)
          }
          else {
            setYearlyData([]);
            setApiCall1(false)
          }
        })
        .catch((error) => {});
    } 
  }, [
    props?.portfolioId,
    props?.siteId,
    apiCall1,
    props?.commonApiEnabler
  ]);

  useEffect(() => {
    var getData = {
      portfolio: props?.portfolioId ? [props?.portfolioId] : [],
      brand: props?.brandId ? [props?.brandId] : [],
      site: props?.siteId ? props?.siteId : [],
      country: props?.country,
      state: props?.state,
      region: props?.region,
      city: props?.city,
      authUserRole: authUser?.roleId ? authUser?.roleId : "",
    };
    if (
      getData?.portfolio?.length > 0 &&
      props?.portfolioId &&
      getData !== undefined && apiCall2 && props?.commonApiEnabler
    ) {
      api
        .post<APIResponse<{ records: MonthlyRecord[] }>>(
          `kpi/brand-retail/outletLocation/monthly`,
          getData,
          config
        )
        .then((response) => {
          if (response?.status === 200 && response?.data?.data) {
            const result = response?.data?.data?.records;
            setMonthlyData(result);
            setApiCall2(false)
          }
          else {
            setMonthlyData([]);
            setApiCall2(false)
          }
        })
        .catch((error) => {});
    } 
  }, [
    props?.portfolioId,
    props?.siteId,
    apiCall2,
    props?.commonApiEnabler
  ]);

  useEffect(() => {
    let pid = props?.portfolioId ? [props?.portfolioId] : [];
    var obj = portfolioOption?.find((p) => p?.id === Number(pid));
    let valuecaption = obj?.valuecaption ? obj?.valuecaption : "in Millions";
    setValueIn(valuecaption);
  }, [props, portfolioOption]);

  const colors = [
    COLORS?.COLOR1,
    COLORS?.COLOR2,
    COLORS?.COLOR3,
    COLORS?.COLOR4,
    COLORS?.COLOR5,
    COLORS?.COLOR6,
    COLORS?.COLOR7,
    COLORS?.COLOR8,
  ];

  const getMonthlyMOMData = () => {
    var monthlyTableData: string[][] = [
      [
        'Location Type',
        "Year-Month",
        "Sales",
      ],
    ];

    yearList?.map((a)=>{
    
    monthlyData?.forEach((y, i) => {
          y?.record?.filter((b)=>b?.transyear?.toString()===a?.toString())
            ?.forEach((r) => {
                let sum = []
              const month = monthsArray[r?.transmonth - 1];
              const out = OutletLocations?.filter((a)=>a?.id?.toString()===r?.outletlocation?.toString())
              sum?.push(out[0]?.name?.toString())
              sum?.push(r?.transyear?.toString() + "-" + month);
              sum?.push(
                r?.sales !== undefined && r?.sales !== null ? r?.sales : "-"
              );
                monthlyTableData?.push(sum);
        });
  })
  })
    return monthlyTableData;
  };

  const getYearlyYOYData = () => {
    var monthlyTableData: string[][] = [
      [
        'Location Type',
        "Year",
        "Sales",
      ],
    ];

    yearList?.map((a)=>{
    yearlyData?.forEach((y, i) => {
          y?.record?.filter((b)=>b?.transyear?.toString()===a?.toString())
            ?.forEach((r) => {
                let sum = []
                const out = OutletLocations?.filter((a)=>a?.id?.toString()===r?.outletlocation?.toString())
                sum?.push(out[0]?.name?.toString())
              sum?.push(r?.transyear?.toString());
              sum?.push(
                r?.sales !== undefined && r?.sales !== null ? r?.sales : "-"
              );
                monthlyTableData?.push(sum);
        });
    });
  })
    return monthlyTableData;
  };

  
  useEffect(() => { 

    let {city,country,region,state,brandId} = props


  if( changedRef?.current?.city!==city?.toString()
  || changedRef?.current?.country!==country?.toString()
  || changedRef?.current?.region!==region?.toString()
  || changedRef?.current?.state!==state?.toString()
  || changedRef?.current?.brand!==brandId?.toString()
  ) {
    changedRef.current = {
    brand:brandId?.toString(),
    city:city?.toString(),
    country:country?.toString(),
    region:region?.toString(),
    state:state?.toString()
    };
    setApiCall1(true)
    setApiCall2(true)
  }
}, [props?.city,props?.country,props?.region,props?.state,props?.brandId]);

  return (
    <Widget1
      title={"Brand Sales By Store Location"}
      momHandler={momHandler}
      yoyHandler={yoyHandler}
      tableViewHandler={tableViewHandler}
      chartViewHandler={chartViewHandler}
      selection={selection}
      view={view}
      showMTD={false}
      showYTD={false}
      valueInString={valueIn}
      showExpandModal={true}
      showExpand={true}
      showExpandHandler={showExpandHandler}
      showGridHandler={showGridHandler}
      showGrid={true}
    >
      {selection === PortfolioDashboardList?.MOM &&
        view === KpiViewList?.CHART && (
          <BarChart1
            datasets={(() => {
              let mdata: { name: string; data: number[] }[] = [];
              MonthlyFilteredData?.forEach((y, i) => {
                const index = portfolioOption?.findIndex(
                  (p) =>
                    p?.id !== undefined &&
                    p?.id?.toString() === y?.portfolio?.toString()
                );
                // const name = portfolioOption[index]?.name;

                OutletLocations?.forEach((o) => {
                  yearList?.forEach((sy) => {
                    let data2: number[] = [];
                    let d1 = y?.record
                      ?.filter(
                        (sw) =>
                          sw?.outletlocation?.toString() === o?.id?.toString()
                      )
                      ?.filter(
                        (y) => y?.transyear?.toString() === sy?.toString()
                      );
                    monthsArray
                      ?.map((f) => f)
                      ?.sort()
                      ?.forEach((m, mi) => {
                        var mns = d1?.map((d) => d?.transmonth);
                        if (mns?.includes(mi + 1)) {
                          let obj = d1?.find((f) => f?.transmonth === mi + 1);
                          data2?.push(obj?.sales ? parseFloat(obj?.sales) : 0);
                        } else data2?.push(0);
                      });
                    let isEmpty: string[] = [];
                    data2?.forEach((d2) => {
                      if (d2 !== 0) isEmpty?.push("true");
                      else isEmpty?.push("false");
                    });

                    if (data2?.length > 0 && isEmpty?.includes("true")) {
                      mdata?.push({
                        name: o?.name + "-" + sy,
                        data: data2,
                      });
                    }
                  });
                });
              });
              return mdata;
            })()}
            labels={monthsArray}
            colors={[
              colors[0],
              colors[1],
              colors[2],
              colors[3],
              colors[4],
              colors[5],
              colors[6],
              colors[7],
            ]}
            title={(() => {
              const txt = `Brand Sales By Store Location - MOM`;
              return txt;
            })()}
            subtitle={(() => {
              let subtxt: string[] = [];
              let subtxt1 = `\u3010Brand\u3011: ${propBrandName} \u3010Period\u3011: ${yearList[0]} - ${yearList[2]}`;
              let subtxt2 = ``;
              if (props?.country !== undefined && props?.country?.length > 0) {
                subtxt1 += `\u3010Country\u3011: ${propConame}`;
              }
              if (props?.region !== undefined && props?.region?.length > 0) {
                subtxt1 += `\u3010Region\u3011: ${propRename}`;
              }
              if (props?.state !== undefined && props?.state?.length > 0) {
                subtxt2 += `\u3010State\u3011: ${propStname}`;
              }
              if (props?.city !== undefined && props?.city?.length > 0) {
                subtxt2 += `\u3010City\u3011: ${propCiname}`;
              }
              subtxt?.push(subtxt1);
              subtxt?.push(subtxt2);
              return subtxt;
            })()}
            exportfilename={"BrandSalesByStoreLocation-MOM"}
          />
        )}

      {selection === PortfolioDashboardList?.YOY &&
        view === KpiViewList?.CHART && (
          <BarChart1
            datasets={(() => {
              let ydata: { name: string; data: number[] }[] = [];
              YearlyFilteredData?.forEach((y, i) => {
                const index = portfolioOption?.findIndex(
                  (p) =>
                    p?.id !== undefined &&
                    p?.id?.toString() === y?.portfolio?.toString()
                );
                // const name = portfolioOption[index]?.name;

                OutletLocations?.forEach((o) => {
                  let data2: number[] = [];
                  let d1 = y?.record?.filter(
                    (sw) => sw?.outletlocation?.toString() === o?.id?.toString()
                  );
                  yearList?.forEach((yr) => {
                    var yrs = d1?.map((d) => d?.transyear);
                    if (yrs?.includes(yr)) {
                      let obj = d1?.find((f) => f?.transyear === yr);
                      data2?.push(obj?.sales ? parseFloat(obj?.sales!) : 0);
                    } else data2?.push(0);
                  });
                  if (data2?.length > 0) {
                    ydata?.push({
                      name: o?.name,
                      data: data2,
                    });
                  }
                });
              });
              return ydata;
            })()}
            labels={yearList?.map((y) => y?.toString())?.sort()}
            colors={[
              colors[0],
              colors[1],
              colors[2],
              colors[3],
              colors[4],
              colors[5],
              colors[6],
              colors[7],
            ]}
            title={(() => {
              const txt = `Brand Sales By Store Location - YOY`;
              return txt;
            })()}
            subtitle={(() => {
              let subtxt: string[] = [];
              let subtxt1 = `\u3010Brand\u3011: ${propBrandName} \u3010Period\u3011: ${yearList[0]} - ${yearList[2]}`;
              let subtxt2 = ``;
              if (props?.country !== undefined && props?.country?.length > 0) {
                subtxt1 += `\u3010Country\u3011: ${propConame}`;
              }
              if (props?.region !== undefined && props?.region?.length > 0) {
                subtxt1 += `\u3010Region\u3011: ${propRename}`;
              }
              if (props?.state !== undefined && props?.state?.length > 0) {
                subtxt2 += `\u3010State\u3011: ${propStname}`;
              }
              if (props?.city !== undefined && props?.city?.length > 0) {
                subtxt2 += `\u3010City\u3011: ${propCiname}`;
              }
              subtxt?.push(subtxt1);
              subtxt?.push(subtxt2);
              return subtxt;
            })()}
            exportfilename={"BrandSalesByStoreLocation-YOY"}
          />
        )}

{showTableModal && selection === PortfolioDashboardList?.MOM && (
              <Modal
              size="xl"
              visible = {showTableModal}
              onClose={() => setShowTableModal(false)}
              centered classname="grid-container"
              >
              <RetailPerformanceTable
                    data={getMonthlyMOMData()}
                    title={`Brand Sales By Store Location (MOM) - ${propBrandName}`}
                    fileName={"pf_monthly_sales"}
                    downloadCSV={false}
                    downloadPDF={false}
                  />
              </Modal>
        )}

{showTableModal && selection === PortfolioDashboardList?.YOY && (
              <Modal
              size="xl"
              visible = {showTableModal}
              onClose={() => setShowTableModal(false)}
              centered classname="grid-container"
              >
              <RetailPerformanceTable
                    data={getYearlyYOYData()}
                    title={`Brand Sales By Store Location (YOY) - ${propBrandName}`}
                    fileName={"pf_monthly_sales"}
                    downloadCSV={false}
                    downloadPDF={false}
                  />
              </Modal>
        )}
    </Widget1>
  );
};
export default BrandDashboardSalesByOutletLocWidget;
