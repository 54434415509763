import React from "react";
import { useSelector } from "react-redux";
import { StoreState } from "../../redux/store";
import SplineAreaChart from "../custom/components/charts/apex/SplineAreaChart";
import { SiteType } from "../../redux/actions/siteActions";
import { COLORS } from "../custom/constants";

interface Props {  
    salesMATData: {
        portfolio: number;
        record: {
            sales: string;
            centerid: number;
            transyear: number;
            transmonth: number;
            momgrowth: number | null;
            mtd: string;
            mtdpercentchange: string | null;
            mtdsalesmat: string | null;
            averagesale: string;
            averagerate: string;
            averageratepercent: string;
            salesmat:string | null,
  matpercentchange:string|null
        }[];
        count?: number;
      }[];
      yearDropDown: number[];
      monthsArray: string[];
      getSubtitle: string;
}
export const SalesMAT: React.FC<Props> = (props: Props) => {
    const { salesMATData, yearDropDown, monthsArray, getSubtitle } = props;
    const siteOption = useSelector<StoreState, SiteType[]>((state) => state?.site);
    const colors = [
        COLORS?.COLOR1,
        COLORS?.COLOR2,
        COLORS?.COLOR3,
        COLORS?.COLOR4,
        COLORS?.COLOR5,
        COLORS?.COLOR6,
        COLORS?.COLOR7,
        COLORS?.COLOR8,
    ];
    return(
        <>
        <SplineAreaChart
            datasets={(() => {
            let mdata: { name: string; data: number[] }[] = [];
            salesMATData?.forEach((y, i) => {
                siteOption?.filter((f) =>f?.pfid?.toString() === y?.portfolio?.toString())
                ?.forEach((s) => {
                    yearDropDown?.forEach((yr) => {
                        let data2: number[] = [];
                        let d1 = y?.record?.filter(
                        (f) =>
                            f?.centerid?.toString() ===
                            s?.id?.toString()
                        )
                        ?.filter(
                        (m) =>
                            m?.transyear?.toString() ===
                            yr?.toString()
                        );
                        monthsArray
                        ?.map((f) => f)
                        ?.sort()
                        ?.forEach((m, mi) => {
                            var mns = d1?.map((d) => d?.transmonth);
                            if (mns?.includes(mi + 1)) {
                                let obj = d1?.find((f) => f?.transmonth === mi + 1);
                                data2?.push(
                                obj?.salesmat
                                    ? parseFloat(obj?.salesmat)
                                    : 0
                                );
                            } else data2?.push(0);
                        });
                        let isEmpty: string[] = [];
                        data2?.forEach((d2) => {
                            if (d2 !== 0) isEmpty?.push("true");
                            else isEmpty?.push("false");
                        });
                        if (
                            data2?.length > 0 &&
                            isEmpty?.includes("true")
                        ) {
                            mdata?.push({
                                name: s?.name + "-" + yr,
                                data: data2,
                            });
                        }
                    });
                });
            });
            return mdata;
        })()}
        labels={monthsArray}
        colors={[
            colors[0],
            colors[1],
            colors[2],
            colors[3],
            colors[4],
            colors[5],
            colors[6],
            colors[7],
        ]}
        title={(() => {
            const txt = `Moving Annual Total`;
            return txt;
        })()}
        subtitle={(() => {
            let subtxt = getSubtitle ? getSubtitle : "";
            subtxt += ` \u3010Period\u3011: ${yearDropDown[2]} - ${yearDropDown[0]}`;
            return subtxt;
        })()}
        exportfilename={"movingAnnualtotal"}
        />
    </>
    )
}