import { today, today1, timestamp } from "./functions"
import { AuthUserType } from "../redux/actions/authUserActions";
import { OptionsTypes } from "../redux/actions/selectedOptionsActions";
import { addLogUser } from "../redux/actions/logUserActions";
import { store } from "../redux/store";



interface Props {
    action: string;
    event: string;
}

export const UserLog = async (props: Props) => {
    const state = store.getState();
    const authUser:AuthUserType = state.authUser;
    const selectedOption:OptionsTypes = state.selectedOption;
    const date = today()
    const date2 = today1()
    const time = timestamp()
    
    const data = {
        name:authUser?.name,
        role:authUser?.role[0]?.role,
        loginid:authUser?.loginid ? authUser?.loginid : "",
        portfolioid:authUser?.portfolioId,
        action: props.action,
        apptype:'Retail-App',
        ipaddr:selectedOption.ip,
        event: props.event,
        eventtime:date2 + " "+time,
        eventdate:date
    }
    await store.dispatch(addLogUser(data))
}

