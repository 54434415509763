import { useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import FootfallDailyFlowReport from "../pages/FootfallDailyFlowReport";
import FootfallHourlyFlowReport from "../pages/FootfallHourlyFlowReport";
import FootfallVsSalesReport from "../pages/FootfallVsSalesReport";
import Home from "../pages/Home";
import Login from "../pages/Login";
import Logout from "../pages/Logout";
import SalesAvgTransactionValueReport from "../pages/SalesAvgTransactionValueReport";
import SalesConversionReport from "../pages/SalesConversionReport";
import SalesGrowthAnalysisReport from "../pages/SalesGrowthAnalysisReport";
import SalesHourlySalesReport from "../pages/SalesHourlySalesReport";
import SalesMovingAnnualTotalReport from "../pages/SalesMATReport";
import SalesOverAllSalesReport from "../pages/SalesOverAllSalesReport";
import SalesRankingReport from "../pages/SalesRankingReport";
import SalesTradingDensityReport from "../pages/SalesTradingDensityReport";
import SalesWeekendAnalysisReport from "../pages/SalesWeekendAnalysisReport";
import TransactionBasketSizeReport from "../pages/TransactionBasketSizeReport";
import TransactionBillWiseReport from "../pages/TransactionBillWiseReport";
import TransactionConsolidatedDaywiseReport from "../pages/TransactionConsolidatedDaywiseReport";
import TransactionConsolidatedSalesReport from "../pages/TransactionConsolidatedSalesReport";
import TransactionDayWiseReport from "../pages/TransactionDayWiseReport";
import TransactionItemWiseCategoryPerformance from "../pages/TransactionItemWiseCategoryPerformance";
import TransactionItemWiseReport from "../pages/TransactionItemWiseReport";
import TransactionMissingDatesReport from "../pages/TransactionMissingDatesReport";
import TransactionMonthWiseReport from "../pages/TransactionMonthWiseReport";
import TransactionMonthWiseReportLocwise from "../pages/TransactionMonthWiseReportLocwise"
import { fetchCountryByRetailId } from "../redux/actions/countryActions";
import {
  fetchRegionByRetailId,
  fetchStateByRetailId,
  fetchCityByRetailId,
  setSession,
} from "../redux/actions/commonActions";
import { AuthUserType } from "../redux/actions/authUserActions";
import { BrandType, fetchBrand,fetchBrandByUserId } from "../redux/actions/brandActions";
import { fetchBrandCompany,fetchBrandCompanyByUserId } from "../redux/actions/brandCompanyActions";
import { fetchBrandCompanyMapping,fetchBrandCompanyMappingByUserId } from "../redux/actions/brandCompanyMappingActions";
import {
  PortfolioType,
  fetchPortfolioByUserId,
  fetchPortfolio,
} from "../redux/actions/portfolioActions";
import { fetchRole,fetchRoleByUserId } from "../redux/actions/roleActions";
import {
  OptionsTypes,
  addToSelectedOption,
} from "../redux/actions/selectedOptionsActions";
import { SiteType, fetchSite, fetchSiteByUserId } from "../redux/actions/siteActions";
import { fetchTradeCategory, fetchTradeCategoryByUserId } from "../redux/actions/tradeCategoryActions";
import { fetchTradeSubCategory, fetchTradeSubCategoryByUserId } from "../redux/actions/tradeSubCategoryActions";
// import { fetchUsers } from "../redux/actions/usersActions";
import { AppDispatch, StoreState } from "../redux/store";
import UserLayout from "./custom/layouts/UserLayout";
import BrandDashboard from "../pages/BrandDashboard";
import OutletDashboard from "../pages/OutletBrandDashboard";
import RetailDashboard from "../pages/RetailBrandDashboard";
import Page404 from "../pages/errors/page404";
import { fetchUserOutlet, fetchCenterOutlet } from "../redux/actions/outletActions";
import { fetchAllOutletAreas } from "../redux/actions/areaActions";
import axios from "axios";
import { UserLog } from "../config/common";
// import Topbar from "./custom/layouts/Topbar";

const Router = () => {
  const dispatch = useDispatch<AppDispatch>();
  const authUser = useSelector<StoreState, AuthUserType | null>(
    (state) => state.authUser
  );
  const authUserRole = authUser?.role;
  const authendicated =
    typeof authUser?.["auth-token"] === "string" &&
    authUser?.["auth-token"] !== undefined &&
    authUser?.["auth-token"] !== "";

  // const authUserPfId = authUser?.portfolioId;

  const selectedOption = useSelector<StoreState, OptionsTypes>(
    (state) => state.selectedOption
  );
  const portfolioOption = useSelector<StoreState, PortfolioType[]>(
    (state) => state.portfolio
  );
  const brandOption = useSelector<StoreState, BrandType[]>(
    (state) => state.brand
  );
  const siteOption = useSelector<StoreState, SiteType[]>((state) => state.site);
  // const authendicated = true;
  //for center dboard

  const BrandDashboardView = useMemo<boolean>(() => {
    let i = authUserRole?.findIndex((a) => a.pageurl === "/brand/dashboard");
    let view = authUserRole?.at(i!)?.view;
    if (view) return view;
    else return false;
  }, [authUserRole]);

  const OutletDashboardView = useMemo<boolean>(() => {
    let i = authUserRole?.findIndex(
      (a) => a.pageurl === "/brand/outletdashboard"
    );
    let view = authUserRole?.at(i!)?.view;
    if (view) return view;
    else return false;
  }, [authUserRole]);

  const RetailCompanyDashboardView = useMemo<boolean>(() => {
    let i = authUserRole?.findIndex(
      (a) => a.pageurl === "/brand/retaildashboard"
    );
    let view = authUserRole?.at(i!)?.view;
    if (view) return view;
    else return false;
  }, [authUserRole]);

  const transactionBillWiseReportView = useMemo<boolean>(() => {
    let i = authUserRole?.findIndex(
      (a) => a.pageurl === "/transactionReport/billWiseReport"
    );
    let view = authUserRole?.at(i!)?.view;
    if (view) return view;
    else return false;
  }, [authUserRole]);

  const transactionDayWiseReportView = useMemo<boolean>(() => {
    let i = authUserRole?.findIndex(
      (a) => a.pageurl === "/transactionReport/billWiseReport"
    );
    let view = authUserRole?.at(i!)?.view;
    if (view) return view;
    else return false;
  }, [authUserRole]);

  const transactionMonthWiseReportView = useMemo<boolean>(() => {
    let i = authUserRole?.findIndex(
      (a) => a.pageurl === "/transactionReport/monthWiseReport"
    );
    let view = authUserRole?.at(i!)?.view;
    if (view) return view;
    else return false;
  }, [authUserRole]);

  const transactionMonthWiseReportLocView = useMemo<boolean>(() => {
    let i = authUserRole?.findIndex(
      (a) => a.pageurl === "/transactionReport/monthWiseReportLocwise"
    );
    let view = authUserRole?.at(i!)?.view;
    if (view) return view;
    else return false;
  }, [authUserRole]);

  const transactionConsolidatedSalesReportView = useMemo<boolean>(() => {
    let i = authUserRole?.findIndex(
      (a) => a.pageurl === "/transactionReport/consolidatedSalesReport"
    );
    let view = authUserRole?.at(i!)?.view;
    if (view) return view;
    else return false;
  }, [authUserRole]);

  const transactionConsolidatedDaywiseReportView = useMemo<boolean>(() => {
    let i = authUserRole?.findIndex(
      (a) => a.pageurl === "/transactionReport/consolidatedDaywiseReport"
    );
    let view = authUserRole?.at(i!)?.view;
    if (view) return view;
    else return false;
  }, [authUserRole]);

  const transactionItemWiseReportView = useMemo<boolean>(() => {
    let i = authUserRole?.findIndex(
      (a) => a.pageurl === "/transactionReport/itemWiseReport"
    );
    let view = authUserRole?.at(i!)?.view;
    if (view) return view;
    else return false;
  }, [authUserRole]);

  const transactionItemWiseCategoryPerformanceView = useMemo<boolean>(() => {
    let i = authUserRole?.findIndex(
      (a) => a.pageurl === "/transactionReport/itemWiseCategoryPerformance"
    );
    let view = authUserRole?.at(i!)?.view;
    if (view) return view;
    else return false;
  }, [authUserRole]);

  const transactionMissingDatesReportView = useMemo<boolean>(() => {
    let i = authUserRole?.findIndex(
      (a) => a.pageurl === "/transactionReport/missingDatesReport"
    );
    let view = authUserRole?.at(i!)?.view;
    if (view) return view;
    else return false;
  }, [authUserRole]);

  const transactionBasketSizeReportView = useMemo<boolean>(() => {
    let i = authUserRole?.findIndex(
      (a) => a.pageurl === "/transactionReport/basketSizeReport"
    );
    let view = authUserRole?.at(i!)?.view;
    if (view) return view;
    else return false;
  }, [authUserRole]);
  //SALES
  const SalesAnalysisOverallSalesView = useMemo<boolean>(() => {
    let i = authUserRole?.findIndex(
      (a) => a.pageurl === "/salesAnalysis/overallSales"
    );
    let view = authUserRole?.at(i!)?.view;
    if (view) return view;
    else return false;
  }, [authUserRole]);

  const SalesAnalysisSalesRankingView = useMemo<boolean>(() => {
    let i = authUserRole?.findIndex(
      (a) => a.pageurl === "/salesAnalysis/salesRanking"
    );
    let view = authUserRole?.at(i!)?.view;
    if (view) return view;
    else return false;
  }, [authUserRole]);

  const SalesAnalysisTradeDensityView = useMemo<boolean>(() => {
    let i = authUserRole?.findIndex(
      (a) => a.pageurl === "/salesAnalysis/tradingDensity"
    );
    let view = authUserRole?.at(i!)?.view;
    if (view) return view;
    else return false;
  }, [authUserRole]);

  const SalesMATView = useMemo<boolean>(() => {
    let i = authUserRole?.findIndex(
      (a) => a.pageurl === "/salesAnalysis/movingAnnualTotal"
    );
    let view = authUserRole?.at(i!)?.view;
    if (view) return view;
    else return false;
  }, [authUserRole]);

  const SalesHourlyView = useMemo<boolean>(() => {
    let i = authUserRole?.findIndex(
      (a) => a.pageurl === "/salesAnalysis/hourlySales"
    );
    let view = authUserRole?.at(i!)?.view;
    if (view) return view;
    else return false;
  }, [authUserRole]);

  const SalesWeekendView = useMemo<boolean>(() => {
    let i = authUserRole?.findIndex(
      (a) => a.pageurl === "/salesAnalysis/weekendAnalysis"
    );
    let view = authUserRole?.at(i!)?.view;
    if (view) return view;
    else return false;
  }, [authUserRole]);

  const SalesGrowthView = useMemo<boolean>(() => {
    let i = authUserRole?.findIndex(
      (a) => a.pageurl === "/salesAnalysis/growthAnalysis"
    );
    let view = authUserRole?.at(i!)?.view;
    if (view) return view;
    else return false;
  }, [authUserRole]);

  const SalesAvgTransactionView = useMemo<boolean>(() => {
    let i = authUserRole?.findIndex(
      (a) => a.pageurl === "/salesAnalysis/avgTransactionValue"
    );
    let view = authUserRole?.at(i!)?.view;
    if (view) return view;
    else return false;
  }, [authUserRole]);
  const SalesConversionView = useMemo<boolean>(() => {
    let i = authUserRole?.findIndex(
      (a) => a.pageurl === "/salesAnalysis/salesConversion"
    );
    let view = authUserRole?.at(i!)?.view;
    if (view) return view;
    else return false;
  }, [authUserRole]);
  //footfall
  const FootfallHourlyFlowView = useMemo<boolean>(() => {
    let i = authUserRole?.findIndex(
      (a) => a.pageurl === "/footfallAnalysis/hourlyFlow"
    );
    let view = authUserRole?.at(i!)?.view;
    if (view) return view;
    else return false;
  }, [authUserRole]);
  const FootfallDailyFlowView = useMemo<boolean>(() => {
    let i = authUserRole?.findIndex(
      (a) => a.pageurl === "/footfallAnalysis/dailyFlow"
    );
    let view = authUserRole?.at(i!)?.view;
    if (view) return view;
    else return false;
  }, [authUserRole]);
  const FootfallVsSalesView = useMemo<boolean>(() => {
    let i = authUserRole?.findIndex(
      (a) => a.pageurl === "/footfallAnalysis/footfallVsSales"
    );
    let view = authUserRole?.at(i!)?.view;
    if (view) return view;
    else return false;
  }, [authUserRole]);

  const handleSetSelection = () => {
    //portfolio

    const port =
      authUser?.portfolioId !== undefined && authUser?.portfolioId !== null
        ? authUser.portfolioId.split(",")
        : [];

    let portfolioData = port
      ?.map((pe) => {
        return portfolioOption.filter(
          (f) => f?.id?.toString() === pe?.toString()
        );
      })
      .flat(1);
    let pid =
      portfolioData[0] && portfolioData[0]?.id
        ? portfolioData[0].id?.toString()
        : "";

    let p = {
      value: portfolioData[0]?.id ? portfolioData[0].id : parseInt(pid),
      label: portfolioData[0]?.name ? portfolioData[0].name : "",
      imgUrl: portfolioData[0]?.logourl ? portfolioData[0].logourl : "",
    };
    //site
    const st =
      authUser?.siteId !== undefined && authUser?.siteId !== null
        ? authUser.siteId.split(",")
        : [];
    let siteData = st
      ?.map((pe) => {
        return siteOption.filter((f) => f?.id?.toString() === pe?.toString());
      })
      .flat(1);

    let sid = siteData[0] && siteData[0]?.id ? siteData[0].id?.toString() : "";

    let s = {
      value: siteData[0]?.id ? siteData[0].id : 0,
      label: siteData[0]?.name ? siteData[0].name : "",
      imgUrl: siteData[0]?.logourl ? siteData[0].logourl : "",
    };

    const brands = authUser?.brandId !== undefined && authUser?.brandId !== null ? authUser.brandId.split(",") : [];
    var brandData = brands
    ?.map((bs) => {
      return brandOption.filter(
        (b) => b?.id?.toString() === bs?.toString()
      );
    }).flat(1);
      
    let bid = brandData[0] && brandData[0]?.id ? brandData[0].id?.toString() : "";
    let b = {
      value: brandData[0]?.id ? brandData[0].id : 0,
      label: brandData[0]?.name ? brandData[0].name : "",
      imgUrl: brandData[0]?.logourl ? brandData[0].logourl : "",
    };
    

    if (pid !== "" && sid !== "" && bid !== "" && (OutletDashboardView || BrandDashboardView)) {
      const data = {
        ...selectedOption,
        portfolios: [p],
        sites: [s],
        portfolio: pid,
        site: sid,
        brand: bid,
        brands: [b],
      };
      dispatch(addToSelectedOption(data));
    }else {
      const data = {
        ...selectedOption,
        portfolios: [p],
        sites: [s],
        portfolio: pid,
        site: sid,
      };
      dispatch(addToSelectedOption(data));
    }
  };
  
  useEffect(() => {
    if (authendicated) { 
      const pfid = authUser?.portfolioId ? authUser?.portfolioId : '';
      const siteid = authUser?.siteId ? authUser?.siteId : '';
      if(authUser.uid !== null && authUser.uid !== undefined){
        dispatch(fetchPortfolioByUserId(authUser.uid));
        dispatch(fetchSiteByUserId(authUser.uid));        
        dispatch(fetchBrandByUserId(authUser.uid));
        dispatch(fetchRoleByUserId(authUser.uid));
        dispatch(fetchBrandCompanyByUserId(authUser.uid));
        dispatch(fetchBrandCompanyMappingByUserId(authUser.uid));
        dispatch(fetchTradeCategoryByUserId(authUser.uid));
        dispatch(fetchTradeSubCategoryByUserId(authUser.uid));
      }else{
        dispatch(fetchPortfolio());
        dispatch(fetchSite());
        dispatch(fetchBrand());
        dispatch(fetchRole());
        dispatch(fetchBrandCompany());
        dispatch(fetchBrandCompanyMapping());        
        dispatch(fetchTradeCategory());
        dispatch(fetchTradeSubCategory());
      }
      dispatch(fetchAllOutletAreas(pfid));
      dispatch(fetchCenterOutlet(pfid,siteid));
      handleSetSelection();
      dispatch(fetchCountryByRetailId());
      dispatch(fetchRegionByRetailId());
      dispatch(fetchStateByRetailId());
      dispatch(fetchCityByRetailId());
      const logProps1 = {
        action:`Login`,
        event: `User LoggedIn`
      };
      UserLog(logProps1)
    }
  }, [authendicated, authUser]);
  useEffect(() => {
    if (authendicated && OutletDashboardView) {
      let bid = authUser?.brandId ? authUser?.brandId : '';
      let sid = authUser?.siteId ? authUser?.siteId : '';
      const data = {
        ...selectedOption,
        showFilter: false,
        isOutletUser: true,
        brand: bid,
        site: sid,
      };      
      dispatch(addToSelectedOption(data));
    }else{
      const data = {
        ...selectedOption,
        isOutletUser: false,
      };
      dispatch(addToSelectedOption(data));    
      
    }
  }, [authendicated, OutletDashboardView]);
  useEffect(() => {
    if (authUser !== null) {
      dispatch(setSession());
      // handleSetSelection();
    }
  }, [authUser,dispatch]);

  // const getData = async () => {
  //   const res = await axios.get("https://api.ipify.org/?format=json");
  //   const location = await axios.get(`https://get.geojs.io/v1/ip/geo/${res.data.ip}.json`);
  //   const data = {
  //     ...selectedOption,ip:res.data.ip
  //   }
  //   dispatch(addToSelectedOption(data))
  // };

  // useEffect(() => {
  //   if(authendicated){
  //   getData();
  //   }
  // }, [authendicated]);
 

  return (
    <BrowserRouter>
      <Routes>
        <Route
          path="/"
          element={authendicated ? <Home /> : <Navigate to="/login" />}
        />
        {BrandDashboardView && (
          <Route
            path="/brand/dashboard"
            element={
              authendicated ? (
                <UserLayout>
                  <BrandDashboard />
                </UserLayout>
              ) : (
                <Navigate to="/login" />
              )
            }
          />
        )}
        {OutletDashboardView && (
          <Route
            path="/brand/outletdashboard"
            element={
              authendicated ? (
                <UserLayout>
                  <OutletDashboard />
                </UserLayout>
              ) : (
                <Navigate to="/login" />
              )
            }
          />
        )}
        {RetailCompanyDashboardView && (
          <Route
            path="/brand/retaildashboard"
            element={
              authendicated ? (
                <UserLayout>
                  <RetailDashboard />
                </UserLayout>
              ) : (
                <Navigate to="/login" />
              )
            }
          />
        )}

        {/* transaction */}
        <Route
          path="/transactionReport/billWiseReport"
          element={
            authendicated ? (
              transactionBillWiseReportView ? (
                <TransactionBillWiseReport />
              ) : (
                <Navigate to="/" />
              )
            ) : (
              <Navigate to="/login" />
            )
          }
        />
        <Route
          path="/transactionReport/dayWiseReport"
          element={
            authendicated ? (
              transactionDayWiseReportView ? (
                <TransactionDayWiseReport />
              ) : (
                <Navigate to="/" />
              )
            ) : (
              <Navigate to="/login" />
            )
          }
        />
        <Route
          path="/transactionReport/monthWiseReport"
          element={
            authendicated ? (
              transactionMonthWiseReportView ? (
                <TransactionMonthWiseReport />
              ) : (
                <Navigate to="/" />
              )
            ) : (
              <Navigate to="/login" />
            )
          }
        />
        <Route
          path="/transactionReport/monthWiseReportLocwise"
          element={
            authendicated ? (
              transactionMonthWiseReportLocView ? (
                <TransactionMonthWiseReportLocwise />
              ) : (
                <Navigate to="/" />
              )
            ) : (
              <Navigate to="/login" />
            )
          }
        />
        <Route
          path="/transactionReport/consolidatedSalesReport"
          element={
            authendicated ? (
              transactionConsolidatedSalesReportView ? (
                <TransactionConsolidatedSalesReport />
              ) : (
                <Navigate to="/" />
              )
            ) : (
              <Navigate to="/login" />
            )
          }
        />
        <Route
          path="/transactionReport/consolidatedDaywiseReport"
          element={
            authendicated ? (
              transactionConsolidatedDaywiseReportView ? (
                <TransactionConsolidatedDaywiseReport />
              ) : (
                <Navigate to="/" />
              )
            ) : (
              <Navigate to="/login" />
            )
          }
        />

        <Route
          path="/transactionReport/itemWiseReport"
          element={
            authendicated ? (
              transactionItemWiseReportView ? (
                <TransactionItemWiseReport />
              ) : (
                <Navigate to="/" />
              )
            ) : (
              <Navigate to="/login" />
            )
          }
        />
        <Route
          path="/transactionReport/itemWiseCategoryPerformance"
          element={
            authendicated ? (
              transactionItemWiseCategoryPerformanceView ? (
                <TransactionItemWiseCategoryPerformance />
              ) : (
                <Navigate to="/" />
              )
            ) : (
              <Navigate to="/login" />
            )
          }
        />
        <Route
          path="/transactionReport/basketSizeReport"
          element={
            authendicated ? (
              transactionBasketSizeReportView ? (
                <TransactionBasketSizeReport />
              ) : (
                <Navigate to="/" />
              )
            ) : (
              <Navigate to="/login" />
            )
          }
        />
        <Route
          path="/transactionReport/missingDatesReport"
          element={
            authendicated ? (
              transactionMissingDatesReportView ? (
                <TransactionMissingDatesReport />
              ) : (
                <Navigate to="/" />
              )
            ) : (
              <Navigate to="/login" />
            )
          }
        />

        {/* //SAles */}
        <Route
          path="/salesAnalysis/overallSales"
          element={
            authendicated ? (
              SalesAnalysisOverallSalesView ? (
                <SalesOverAllSalesReport />
              ) : (
                <Navigate to="/" />
              )
            ) : (
              <Navigate to="/login" />
            )
          }
        />
        <Route
          path="/salesAnalysis/salesRanking"
          element={
            authendicated ? (
              SalesAnalysisSalesRankingView ? (
                <SalesRankingReport />
              ) : (
                <Navigate to="/" />
              )
            ) : (
              <Navigate to="/login" />
            )
          }
        />
        <Route
          path="/salesAnalysis/tradingDensity"
          element={
            authendicated ? (
              SalesAnalysisTradeDensityView ? (
                <SalesTradingDensityReport />
              ) : (
                <Navigate to="/" />
              )
            ) : (
              <Navigate to="/login" />
            )
          }
        />
        <Route
          path="/salesAnalysis/movingAnnualTotal"
          element={
            authendicated ? (
              SalesMATView ? (
                <SalesMovingAnnualTotalReport />
              ) : (
                <Navigate to="/" />
              )
            ) : (
              <Navigate to="/login" />
            )
          }
        />
        <Route
          path="/salesAnalysis/hourlySales"
          element={
            authendicated ? (
              SalesHourlyView ? (
                <SalesHourlySalesReport />
              ) : (
                <Navigate to="/" />
              )
            ) : (
              <Navigate to="/login" />
            )
          }
        />
        <Route
          path="/salesAnalysis/weekendAnalysis"
          element={
            authendicated ? (
              SalesWeekendView ? (
                <SalesWeekendAnalysisReport />
              ) : (
                <Navigate to="/" />
              )
            ) : (
              <Navigate to="/login" />
            )
          }
        />
        <Route
          path="/salesAnalysis/growthAnalysis"
          element={
            authendicated ? (
              SalesGrowthView ? (
                <SalesGrowthAnalysisReport />
              ) : (
                <Navigate to="/" />
              )
            ) : (
              <Navigate to="/login" />
            )
          }
        />
        <Route
          path="/salesAnalysis/avgTransactionValue"
          element={
            authendicated ? (
              SalesAvgTransactionView ? (
                <SalesAvgTransactionValueReport />
              ) : (
                <Navigate to="/" />
              )
            ) : (
              <Navigate to="/login" />
            )
          }
        />
        <Route
          path="/salesAnalysis/salesConversion"
          element={
            authendicated ? (
              SalesConversionView ? (
                <SalesConversionReport />
              ) : (
                <Navigate to="/" />
              )
            ) : (
              <Navigate to="/login" />
            )
          }
        />

        {/* Footfall */}
        <Route
          path="/footfallAnalysis/hourlyFlow"
          element={
            authendicated ? (
              FootfallHourlyFlowView ? (
                <FootfallHourlyFlowReport />
              ) : (
                <Navigate to="/" />
              )
            ) : (
              <Navigate to="/login" />
            )
          }
        />
        <Route
          path="/footfallAnalysis/dailyFlow"
          element={
            authendicated ? (
              FootfallDailyFlowView ? (
                <FootfallDailyFlowReport />
              ) : (
                <Navigate to="/" />
              )
            ) : (
              <Navigate to="/login" />
            )
          }
        />
        <Route
          path="/footfallAnalysis/footfallVsSales"
          element={
            authendicated ? (
              FootfallVsSalesView ? (
                <FootfallVsSalesReport />
              ) : (
                <Navigate to="/" />
              )
            ) : (
              <Navigate to="/login" />
            )
          }
        />

        <Route
          path="/login"
          element={!authendicated ? <Login /> : <Navigate to="/" />}
        />
        <Route
          path="/logout"
          element={authendicated ? <Logout /> : <Navigate to="/" />}
        />
        <Route path="*" element={<Page404 />} />
      </Routes>
    </BrowserRouter>
  );
};

export default Router;
