import React, { useEffect, useMemo, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { PortfolioDashboardList, PortfolioDashboardType } from "../../config";
import api, { APIResponse, getAxiosRequestConfig } from "../../config/api";
import { AuthUserType } from "../../redux/actions/authUserActions";
import { StoreState } from "../../redux/store";
import { KpiViewList, getOnlyUnique } from "../commonWidget";
import Widget1 from "../custom/components/Widget1";
import { COLORS } from "../custom/constants";
import { PortfolioType } from "../../redux/actions/portfolioActions";
import { TradeSubCategoryType } from "../../redux/actions/tradeSubCategoryActions";
import {
  RegionType,
  StateType,
  CityType,
} from "../../redux/actions/commonActions";
import { CountryType } from "../../redux/actions/countryActions";
import { TradeCategoryType } from "../../redux/actions/tradeCategoryActions";
import PieChart1 from "../custom/components/charts/apex/PieChart1";
import Modal from "../Modal";
import RetailPerformanceTable from "../custom/components/RetailPerformanceTable";
import { YearOptions } from "../../config/functions";

interface RetailRecordsForYear {
  sales: string;
  transyear: number;
  centerid?: number;
  tradesubcatid: number;
  tenantid?: number | null;
}
interface YearlyRecord {
  portfolio: number;
  record: RetailRecordsForYear[];
}

interface Props {
  portfolioId: number;
  siteId: string[];
  tradeCategory?: number[];
  tradeSubCategory?: number[];
  country?: number[];
  region?: number[];
  state?: number[];
  city?: number[];
  expandState: React.Dispatch<React.SetStateAction<boolean>>;
  commonApiEnabler?:boolean
}


interface ChangedRefType {
  tradeCat?: string;
  tradeSubCat?:string
  country?:string
  region?:string
  state?:string
  city?:string
}


const BrandDashboardSubcategorySalesWidget: React.FC<Props> = (
  props: Props
) => {
  const changedRef = useRef<ChangedRefType>({});
  const authUser = useSelector<StoreState, AuthUserType | null>(
    (state) => state?.authUser
  );
  const authToken = authUser?.["auth-token"]!;
  const config = getAxiosRequestConfig(authToken);

  const portfolioOption = useSelector<StoreState, PortfolioType[]>(
    (state) => state?.portfolio
  );
  const subCategoryOption = useSelector<StoreState, TradeSubCategoryType[]>(
    (state) => state?.tradeSubCategory
  );

  const prop_index = portfolioOption?.findIndex(
    (p) =>
      p?.id !== undefined && p?.id?.toString() === props?.portfolioId?.toString()
  );
  const propPfname = portfolioOption[prop_index]?.name;

  const countryList = useSelector<StoreState, CountryType[]>(
    (state) => state?.country
  );

  const regionList = useSelector<StoreState, RegionType[]>(
    (state) => state?.region
  );

  const stateList = useSelector<StoreState, StateType[]>(
    (state) => state?.state
  );

  const cityList = useSelector<StoreState, CityType[]>((state) => state?.city);

  const tradeCategoryList = useSelector<StoreState, TradeCategoryType[]>(
    (state) => state?.tradeCategory
  );
  const subCategoryList = useSelector<StoreState, TradeSubCategoryType[]>(
    (state) => state?.tradeSubCategory
  );

  const countryIndex = countryList?.findIndex(
    (a) => a?.id?.toString() === props?.country?.toString()
  );
  const propConame = countryList[countryIndex]?.countryname;

  const regionIndex = regionList?.findIndex(
    (a) => a?.id?.toString() === props?.region?.toString()
  );
  const propRename = regionList[regionIndex]?.regionname;

  const stateIndex = stateList?.findIndex(
    (a) => a?.id?.toString() === props?.state?.toString()
  );
  const propStname = stateList[stateIndex]?.statename;

  const cityIndex = cityList?.findIndex(
    (a) => a?.id?.toString() === props?.city?.toString()
  );
  const propCiname = cityList[cityIndex]?.cityname;

  const catIndex = tradeCategoryList?.findIndex(
    (a) => a?.id?.toString() === props?.tradeCategory?.toString()
  );
  const propCatname = tradeCategoryList[catIndex]?.name;

  const subcatIndex = subCategoryList?.findIndex(
    (a) => a?.id?.toString() === props?.tradeSubCategory?.toString()
  );
  const propsubCatname = subCategoryList[subcatIndex]?.name;

  const yearList = YearOptions()

  const [yearlyData, setYearlyData] = useState<YearlyRecord[]>([]);
  const [selection, setSelection] = useState<PortfolioDashboardType>(
    PortfolioDashboardList?.MOM
  );
  const [view, setView] = useState<string>(KpiViewList?.CHART);
  const [valueIn, setValueIn] = useState<string>("");
  const currentYear = new Date()?.getFullYear();
  const [showTableModal, setShowTableModal] = useState<boolean>(false);
  const [apiCall1,setApiCall1]=  useState<boolean>(true)
  const [apiCall2,setApiCall2]=  useState<boolean>(true)
  const [year, setYear] = useState<string>(currentYear?.toString());
  const showExpandHandler = () => {
    props?.expandState((prevState) => !prevState)
  };
  const momHandler = () => {
    setSelection(PortfolioDashboardList?.MOM);
  };
  const yoyHandler = () => {
    setSelection(PortfolioDashboardList?.YOY);
  };
  const chartViewHandler = () => {
    setView(KpiViewList?.CHART);
  };
  const tableViewHandler = () => {
    setView(KpiViewList?.TABLE);
  };
  const showGridHandler = () => {
    // props?.expandState(true)
    setShowTableModal(true)
    // setView(KpiViewList?.TABLE)
  };
  const YearlyFilteredData = useMemo<YearlyRecord[]>(() => {
    const data: YearlyRecord[] = [];
    yearlyData?.forEach((d) => {
      data?.push({
        portfolio: d?.portfolio,
        record: d?.record?.filter((r) => {
          return year === r?.transyear?.toString();
        }),
      });
    });
    return data;
  }, [yearlyData, year]);

  useEffect(() => {
    let pid = props?.portfolioId ? [props?.portfolioId] : [];
    var obj = portfolioOption?.find((p) => p?.id === Number(pid));
    let valuecaption = obj?.valuecaption ? obj?.valuecaption : "in Millions";
    setValueIn(valuecaption);
  }, [props, portfolioOption]);

  useEffect(() => {
    var getData = {
      portfolio: props?.portfolioId ? [props?.portfolioId] : [],
      site: props?.siteId ? props?.siteId : [],
      tradeCategory: props?.tradeCategory,
      tradeSubCategory: props?.tradeSubCategory,
      country: props?.country,
      state: props?.state,
      region: props?.region,
      city: props?.city,
      authUserRole: authUser?.roleId ? authUser?.roleId : "",
    };

    if (
      getData?.portfolio?.length > 0 &&
      props?.portfolioId &&
      getData !== undefined && apiCall1 && props?.commonApiEnabler
    ) {
      api
        .post<APIResponse<{ records: YearlyRecord[] }>>(
          `kpi/retail/tradesubcategorysales/yearly`,
          getData,
          config
        )
        .then((response) => {
          if (response?.status === 200 && response?.data?.data) {
            const result = response?.data?.data?.records;
            setYearlyData(result);
            setApiCall1(false)
          }
          else {
            setYearlyData([]);
            setApiCall1(false)
          }
        })
        .catch((error) => {});
    } 
  }, [
    props?.portfolioId,
    props?.siteId,
    apiCall1,props?.commonApiEnabler
  ]);

  const colors = [
    COLORS?.COLOR1,
    COLORS?.COLOR2,
    COLORS?.COLOR3,
    COLORS?.COLOR4,
    COLORS?.COLOR5,
    COLORS?.COLOR6,
    COLORS?.COLOR7,
    COLORS?.COLOR8,
  ];


  const getYearlyData = () => {
    var monthlyTableData: string[][] = [
      [
        'Trade Sub Category',
        "Year",
        "Sales",
      ],
    ];

    yearList?.map((a)=>{
      subCategoryList?.map((f)=>{
    yearlyData?.forEach((y, i) => {
          y?.record?.filter((b)=>b?.transyear?.toString()===a?.toString()&&f?.id?.toString()===b?.tradesubcatid?.toString())
            ?.forEach((r) => {
              const subCat = subCategoryList?.findIndex((e)=>e?.id?.toString()===r?.tradesubcatid?.toString())
                let sum = []
                 sum?.push(subCategoryList[subCat]?.name?.toString()??'')
              sum?.push(r?.transyear?.toString());
              sum?.push(
                r?.sales !== undefined && r?.sales !== null ? r?.sales : "-"
              );
                monthlyTableData?.push(sum);
        });
    });
  })
  })
    return monthlyTableData;
  };

  useEffect(() => { 

    let {tradeCategory,tradeSubCategory,city,country,region,state} = props


  if(changedRef?.current?.tradeCat !== tradeCategory?.toString()||changedRef?.current?.tradeSubCat!==tradeSubCategory?.toString()
      || changedRef?.current?.city!==city?.toString()
  || changedRef?.current?.country!==country?.toString()
  || changedRef?.current?.region!==region?.toString()
  || changedRef?.current?.state!==state?.toString()
  ) {
    changedRef.current = {
    tradeCat:tradeCategory?.toString(),
    tradeSubCat:tradeSubCategory?.toString(),
    city:city?.toString(),
    country:country?.toString(),
    region:region?.toString(),
    state:state?.toString()
    };
    setApiCall1(true)
  }
}, [ props?.tradeCategory,props?.tradeSubCategory,props?.city,props?.country,props?.region,props?.state]);


  return (
    <Widget1
      title={"Trade Subcategory Sales"}
      momHandler={momHandler}
      yoyHandler={yoyHandler}
      tableViewHandler={tableViewHandler}
      chartViewHandler={chartViewHandler}
      selection={selection}
      view={view}
      viewBtns={false}
      showYearFilter={true}
      setYear={setYear}
      year={year}
      valueInString={valueIn}
      showExpandModal={true}
      showExpand={true}
      showExpandHandler={showExpandHandler}
      showGridHandler={showGridHandler}
      showGrid={true}
    >
      {view === KpiViewList?.CHART && (
        <PieChart1
          datasets={YearlyFilteredData?.map((y, i) => {
            // const index = portfolioOption?.findIndex(
            //   (p) =>
            //     p?.id !== undefined &&
            //     p?.id?.toString() === y?.portfolio?.toString()
            // );
            // const name = portfolioOption[index]?.name;
            const subcatArray = y?.record
              ?.map((y, j) => y?.tradesubcatid)
              ?.filter(getOnlyUnique);
            return subcatArray
              ?.map((subcat) => {
                const subcategoryIndex = subCategoryOption?.findIndex(
                  (s) =>
                    s?.id !== undefined &&
                    s?.id?.toString() === subcat?.toString()
                );
                // const subcategoryName =
                //   subCategoryOption[subcategoryIndex]?.name;

                let data = y?.record
                  ?.filter(
                    (r) => r?.tradesubcatid?.toString() === subcat?.toString()
                  )
                  ?.map((r) => (r?.sales ? parseFloat(r?.sales) : 0));

                if (data?.length > 0) {
                  return data;
                } else return [];
              })
              ?.flat(1);
          })?.flat(1)}
          labels={(() => {
            const label: string[] = [];
            YearlyFilteredData?.forEach((y, i) => {
              // const index = portfolioOption?.findIndex(
              //   (p) =>
              //     p?.id !== undefined &&
              //     p?.id?.toString() === y?.portfolio?.toString()
              // );
              // const name = portfolioOption[index]?.name;
              const subcatArray = y?.record
                ?.map((y, j) => y?.tradesubcatid)
                ?.filter(getOnlyUnique);

              subcatArray?.forEach((subcat) => {
                const subcategoryIndex = subCategoryOption?.findIndex(
                  (c) =>
                    c?.id !== undefined &&
                    c?.id?.toString() === subcat?.toString()
                );
                if (subcategoryIndex >= 0) {
                  const subcategoryName =
                    subCategoryOption[subcategoryIndex]?.name;
                  let data = y?.record
                    ?.filter(
                      (r) => r?.tradesubcatid?.toString() === subcat?.toString()
                    )
                    ?.map((r) => (r?.sales ? parseFloat(r?.sales) : 0));

                  if (data?.length > 0) {
                    label?.push(
                      // name + '(' + subcategoryName + ') ',
                      subcategoryName
                    );
                  }
                }
              });
            });
            return label;
          })()}
          // datasets={yearlyDataSets[0]?.datasets}
          // labels={yearlyDataSets[0]?.labels}
          // labels={[year]}
          // labels={yearlyData?.at(0)?.record?.map(r => r?.transyear?.toString())?.filter(onlyUnique) || []}
          colors={[
            colors[0],
            colors[1],
            colors[2],
            colors[3],
            colors[4],
            colors[5],
            colors[6],
            colors[7],
          ]}
          title={(() => {
            const txt = `Trade SubCategory Sales`;
            return txt;
          })()}
          subtitle={(() => {
            let subtxt: string[] = [];
            let subtxt1 = `\u3010Portfolio\u3011: ${propPfname} \u3010Year\u3011: ${year}`;
            let subtxt2 = ``;
            if (
              props?.tradeCategory !== undefined &&
              props?.tradeCategory?.length > 0
            ) {
              subtxt1 += `\u3010TradeCategory\u3011: ${propCatname}`;
            }
            if (
              props?.tradeSubCategory !== undefined &&
              props?.tradeSubCategory?.length > 0
            ) {
              subtxt1 += `\u3010TradeSubCategory\u3011: ${propsubCatname}`;
            }
            if (props?.country !== undefined && props?.country?.length > 0) {
              subtxt2 += `\u3010Country\u3011: ${propConame}`;
            }
            if (props?.region !== undefined && props?.region?.length > 0) {
              subtxt2 += `\u3010Region\u3011: ${propRename}`;
            }
            if (props?.state !== undefined && props?.state?.length > 0) {
              subtxt2 += `\u3010State\u3011: ${propStname}`;
            }
            if (props?.city !== undefined && props?.city?.length > 0) {
              subtxt2 += `\u3010City\u3011: ${propCiname}`;
            }
            subtxt?.push(subtxt1);
            subtxt?.push(subtxt2);
            return subtxt;
          })()}
          exportfilename={"TradeSubcategorySales"}
        />
      )}

{showTableModal &&  (
              <Modal
              size="xl"
              visible = {showTableModal}
              onClose={() => setShowTableModal(false)}
              centered classname="grid-container"
              >
              <RetailPerformanceTable
                    data={getYearlyData()}
                    title={`Trade SubCategory Sales - ${propPfname}`}
                    fileName={"pf_monthly_sales"}
                    downloadCSV={false}
                    downloadPDF={false}
                  />
              </Modal>
        )}

    </Widget1>
  );
};
export default BrandDashboardSubcategorySalesWidget;
