import React, { ChangeEventHandler, MouseEventHandler, ReactNode, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faExpandAlt, faWindowMaximize, faTable, faChartColumn } from "@fortawesome/free-solid-svg-icons";
import * as Feather from "react-feather";
import { getPrevPrevWeek, getPrevWeek, getWeek, YearOptions } from "../../../config/functions";
import { SiteType } from "../../../redux/actions/siteActions";
import Option from "../../Option";
import chartIcon from "../assets/images/dashboard/chart_icon.svg";
import FormInput from "./FormInput";
import Modal from "../../Modal"
import { KpiViewList } from "../../commonWidget";

interface Props {
  children: ReactNode;
  title: string;
  selection: string;
  view: string;
  weekHandler?: MouseEventHandler<HTMLButtonElement>;
  momHandler?: MouseEventHandler<HTMLButtonElement>;
  yoyHandler?: MouseEventHandler<HTMLButtonElement>;
  mtdHandler?: MouseEventHandler<HTMLButtonElement>;
  ytdHandler?: MouseEventHandler<HTMLButtonElement>;
  chartViewHandler: MouseEventHandler<HTMLButtonElement>;
  tableViewHandler: MouseEventHandler<HTMLButtonElement>;
  disabled?: boolean;
  viewOption?: boolean;
  viewBtns?: boolean;
  viewDD?: boolean;
  setSites?: React.Dispatch<React.SetStateAction<string>>;
  sites?: string;
  SiteFilteredByPortfolio?: SiteType[];
  showYOY?: boolean;
  showMOM?: boolean;
  showYTD?: boolean;
  showMTD?: boolean;
  showWEEK?: boolean;
  showYearFilter?: boolean;
  viewDDWeek?:boolean;
  year?: string;
  setYear?: React.Dispatch<React.SetStateAction<string>>;
  valueInString?: string;
    showExpand?: boolean;
    showExpandModal?: boolean;
  week?:string,
  setWeek?:React.Dispatch<React.SetStateAction<string>>,
    showExpandHandler?: MouseEventHandler<HTMLButtonElement>;
  showGrid?: boolean;
  showGridHandler?: MouseEventHandler<HTMLButtonElement>;
  showChartHandler?: MouseEventHandler<HTMLButtonElement>;
  weekList?:number[];
  viewDays?: boolean;
  daysList?: string[];
  sday?: string;
  setSday?: React.Dispatch<React.SetStateAction<string>>;
  showWidgetFltr?: boolean;
  setWidgetFltr?: React.Dispatch<React.SetStateAction<boolean>>;
  weektext?:string
}

const Widget1: React.FC<Props> = (props: Props) => {
  const {
    children,
    title,
    weekHandler,
    momHandler,
    yoyHandler,
    ytdHandler,
    mtdHandler,
    tableViewHandler,
    chartViewHandler,
    selection,
    view,
    viewOption = false,
    viewBtns = true,
    viewDD = false,
    viewDDWeek = false,
    sites,
    setSites,
    SiteFilteredByPortfolio,
    showMOM = true,
    showYOY = true,
    showMTD = true,
    showYTD = true,
    showWEEK = false,
    showYearFilter,
    year,
    setYear,
    valueInString,
    showExpand = false,
        showExpandModal = false,
    showExpandHandler,
    showGrid = false,
    showGridHandler,
    showChartHandler,
    week,
    setWeek,
    weekList,
    viewDays = false,
    daysList,
    sday,
    setSday,
    showWidgetFltr = true,
    setWidgetFltr,
    weektext
  } = props;
    const [isButtonVisible, setButtonVisible] = useState(false);
    const [showModal, setShowModal] = useState<boolean>(false);
    const yearList = YearOptions();
    const currentYear = yearList[0];
    const prevYear = yearList[1];
    const prevPrevYear = yearList[2];
    const showExpandModalHandler = () => {
        setShowModal(true)
      };

    const handleSiteChange: ChangeEventHandler<HTMLInputElement> = (e) => {
        setSites!(e.target.value)
    }

    const handleYearChange = (val: string) => {
    setYear!(val);
  };

  const handleWeekChange: ChangeEventHandler<HTMLInputElement> = (e) => {
    setWeek!(e.target.value);
  };
  const handleDaysChange: ChangeEventHandler<HTMLInputElement> = (e) => {
    setSday!(e.target.value);
  };

    return (
        <div><>
        {showModal && (
            <Modal
            headerText={title}
            visible={showModal}
            onClose={() => setShowModal(false)}
            size="xl"
            centered
            classname="widget-container">
            <div className="card-body">
                <div className="box-head">
                    <div className="box-r-filter">
                  {viewBtns && (
                    <div className="hstack d-md-inline-block">
                            <div className="c-btn-grp">
                        {showWEEK && (
                          <button
                            type="button"
                            onClick={weekHandler}
                            className={`m-1 btn btn-xs ${
                              selection === "WEEK"
                                ? "btn-sales btn-week onSelect"
                                : "btn-sales btn-week text-black"
                            }`}
                          >
                            WEEK
                          </button>
                        )}
                        {showMOM && (<button
                          type="button"
                          onClick={momHandler}
                          className={`m-1 btn btn-xs ${
                            selection === "MOM"
                              ? "btn-sales btn-mom onSelect"
                              : "btn-sales btn-mom text-black"
                          }`}
                        >
                          MOM
                        </button>)}
                        {showMTD && (
                          <button
                            type="button"
                            onClick={mtdHandler}
                            className={`m-1 btn btn-xs ${
                              selection === "MTD"
                                ? "btn-sales btn-mtd onSelect"
                                : "btn-sales btn-mtd text-black"
                            }`}
                          >
                            MTD
                          </button>
                        )}
                        {showYOY && (<button
                          type="button"
                          onClick={yoyHandler}
                          className={`m-1 btn btn-xs ${
                            selection === "YOY"
                              ? "btn-sales btn-yoy onSelect"
                              : "btn-sales btn-yoy text-black"
                          }`}
                        >
                          YOY
                        </button>)}
                        {showYTD && (
                          <button
                            type="button"
                            onClick={ytdHandler}
                            className={`m-1 btn btn-xs ${
                              selection === "YTD"
                                ? "btn-sales btn-ytd onSelect"
                                : "btn-sales btn-ytd text-black"
                            }`}
                          >
                            YTD
                          </button>
                        )}
                            </div>
                        </div>
                  )}
                  {viewDD && (
                    <div className="col-sm-12 col-md-12 col-lg-12 d-md-inline-block center-select">
                            <FormInput
                        name="Center"
                        label=""
                                labelClassName="required"
                                value={sites}
                                onChange={handleSiteChange}
                        placeholder="All Center"
                                containerClass=""
                                type="select"
                            >
                                <option value="">All Centers</option>
                                {SiteFilteredByPortfolio?.map((s, i) => {
                          return (
                            <Option value={s.id} key={i}>
                              {s?.name}
                            </Option>
                          );
                                })}
                            </FormInput>
                    </div>
                  )}
                  {showYearFilter && showWidgetFltr && (
                    <div className="col-sm-12 col-md-12 col-lg-12 d-md-inline-block">
                      {" "}
                            <div className="btn-grp">
                        <button
                          type="button"
                          onClick={() => handleYearChange(prevPrevYear?.toString())}
                          className={`btn btn-xs ${
                            year === prevPrevYear?.toString()
                              ? "btn-sales btn-mom onSelect"
                              : "btn-sales btn-mom text-black"
                          }`}
                        >
                          {prevPrevYear}
                        </button>
                        <button
                          type="button"
                          onClick={() => handleYearChange(prevYear?.toString())}
                          className={`btn btn-xs ${
                            year === prevYear?.toString()
                              ? "btn-sales btn-mtd onSelect"
                              : "btn-sales btn-mtd text-black"
                          }`}
                        >
                          {prevYear}
                        </button>
                        <button
                          type="button"
                          onClick={() => handleYearChange(currentYear?.toString())}
                          className={`btn btn-xs ${
                            year === currentYear?.toString()
                              ? "btn-sales btn-yoy onSelect"
                              : "btn-sales btn-yoy text-black"
                          }`}
                        >
                          {currentYear}
                        </button>
                            </div>
                    </div>
                  )}
                  {viewDDWeek && (
                    <div className="col-sm-12 col-md-12 col-lg-12 d-md-inline-block center-select">
                      <FormInput
                        name="Week"
                        label=""
                        labelClassName="required"
                        value={week}
                        onChange={handleWeekChange}
                        placeholder=""
                        containerClass=""
                        type="select"
                      >
                        <option value="">{weektext}</option>
                        {weekList?.map((s, i) => {
                          return (
                            <Option value={s} key={i}>
                              {s}
                            </Option>
                          );
                        })}
                      </FormInput>
                    </div>
                  )}
                  {viewDays && showWidgetFltr && (
              <div className="col-sm-12 col-md-12 col-lg-12 d-md-inline-block center-select">
                <FormInput
                  name="Day"
                  label=""
                  labelClassName="required"
                  value={sday}
                  onChange={handleDaysChange} 
                  placeholder="Days"
                  containerClass=""
                  type="select"
                >
                  <option value="">Days</option>
                  {daysList?.map((d, i) => {
                    return (
                      <Option value={d} key={i}>
                        {d}
                      </Option>
                    );
                  })}
                </FormInput>
              </div>
            )}
                </div>
              </div>
              <div className="row">
                <div className="col">
                  {viewOption && (
                    <div className="float-start  d-md-inline-block">
                      <span
                        onClick={chartViewHandler}
                        className={`m-1 link ${
                          view === "chart" ? "text-primary" : "text-secondary"
                        }`}
                        style={{ cursor: "pointer" }}
                      >
                        <Feather.BarChart2 />
                      </span>
                    </div>
                  )}
                </div>
              </div>

              <div className="col">{children}</div>
            </div>

      </Modal>)}


      <div className="card m-2 widget_div" onMouseEnter={() => setButtonVisible(true)}
    onMouseLeave={() => setButtonVisible(false)}>
            {showExpandModal && (<button type="button" className="expandIconBtn" onClick={showExpandModalHandler} ><i><FontAwesomeIcon icon={faWindowMaximize} size="lg" title="Open in Popup" /></i></button>)}
            {showExpand && (<button type="button" className="expandIconBtn expandBtn" onClick={showExpandHandler} ><i><FontAwesomeIcon icon={faExpandAlt} size="lg" title="Expand"/></i></button>)}
       {showGrid && view === KpiViewList.CHART && (<button type="button" className="expandIconBtn gridBtn" onClick={showGridHandler} ><i><FontAwesomeIcon icon={faTable} size="lg" title="Grid"/></i></button>)}
       {showGrid && view === KpiViewList.TABLE && (<button type="button" className="expandIconBtn gridBtn" onClick={showChartHandler} ><i><FontAwesomeIcon icon={faChartColumn} size="lg" title="Chart"/></i></button>)}

            <div className="card-body">
                <div className="box-head">
                    <div className="box-title">
            <h4 className="header-title2 mt-0 mb-2 label">
              <img src={chartIcon} alt="icon"></img>
              {title}
            </h4>
                    </div>
                    <div className="box-r-filter">
            {viewBtns && (
              <div className="hstack d-md-inline-block">
                            <div className="c-btn-grp">
                  {showWEEK && (
                    <button
                      type="button"
                      onClick={weekHandler}
                      className={`m-1 btn btn-xs ${
                        selection === "WEEK"
                          ? "btn-sales btn-week onSelect"
                          : "btn-sales btn-week text-black"
                      }`}
                    >
                      WEEK
                    </button>
                  )}
                  {showMOM && (<button
                    type="button"
                    onClick={momHandler}
                    className={`m-1 btn btn-xs ${
                      selection === "MOM"
                        ? "btn-sales btn-mom onSelect"
                        : "btn-sales btn-mom text-black"
                    }`}
                  >
                    MOM
                  </button>
                   )}
                  {showMTD && (
                    <button
                      type="button"
                      onClick={mtdHandler}
                      className={`m-1 btn btn-xs ${
                        selection === "MTD"
                          ? "btn-sales btn-mtd onSelect"
                          : "btn-sales btn-mtd text-black"
                      }`}
                    >
                      MTD
                    </button>
                  )}
                  {showYOY && (<button
                    type="button"
                    onClick={yoyHandler}
                    className={`m-1 btn btn-xs ${
                      selection === "YOY"
                        ? "btn-sales btn-yoy onSelect"
                        : "btn-sales btn-yoy text-black"
                    }`}
                  >
                    YOY
                  </button>
                )}
                  {showYTD && (
                    <button
                      type="button"
                      onClick={ytdHandler}
                      className={`m-1 btn btn-xs ${
                        selection === "YTD"
                          ? "btn-sales btn-ytd onSelect"
                          : "btn-sales btn-ytd text-black"
                      }`}
                    >
                      YTD
                    </button>
                  )}
                            </div>
                        </div>
            )}
            {viewDD && (
              <div className="col-sm-12 col-md-12 col-lg-12 d-md-inline-block center-select">
                            <FormInput
                  name="Center"
                  label=""
                                labelClassName="required"
                                value={sites}
                                onChange={handleSiteChange}
                  placeholder="All Center"
                                containerClass=""
                                type="select"
                            >
                                <option value="">All Centers</option>
                                {SiteFilteredByPortfolio?.map((s, i) => {
                    return (
                      <Option value={s.id} key={i}>
                        {s?.name}
                      </Option>
                    );
                                })}
                            </FormInput>
              </div>
            )}
            {showYearFilter && showWidgetFltr && (
              <div className="col-sm-12 col-md-12 col-lg-12 d-md-inline-block">
                {" "}
                            <div className="btn-grp">
                  <button
                    type="button"
                    onClick={() => handleYearChange(prevPrevYear?.toString())}
                    className={`btn btn-xs ${
                      year === prevPrevYear?.toString()
                        ? "btn-sales btn-mom onSelect"
                        : "btn-sales btn-mom text-black"
                    }`}
                  >
                    {prevPrevYear}
                  </button>
                  <button
                    type="button"
                    onClick={() => handleYearChange(prevYear?.toString())}
                    className={`btn btn-xs ${
                      year === prevYear?.toString()
                        ? "btn-sales btn-mtd onSelect"
                        : "btn-sales btn-mtd text-black"
                    }`}
                  >
                    {prevYear}
                  </button>
                  <button
                    type="button"
                    onClick={() => handleYearChange(currentYear?.toString())}
                    className={`btn btn-xs ${
                      year === currentYear?.toString()
                        ? "btn-sales btn-yoy onSelect"
                        : "btn-sales btn-yoy text-black"
                    }`}
                  >
                    {currentYear}
                  </button>
                            </div>
              </div>
            )}
            {viewDDWeek && (
                    <div className="col-sm-12 col-md-12 col-lg-12 d-md-inline-block center-select">
                      <FormInput
                        name="Week"
                        label=""
                        labelClassName="required"
                        value={week}
                        onChange={handleWeekChange}
                        placeholder=""
                        containerClass=""
                        type="select"
                      >
                        <option value="">{weektext}</option>
                        {weekList?.map((s, i) => {
                          return (
                            <Option value={s} key={i}>
                              {s}
                            </Option>
                          );
                        })}
                      </FormInput>
                    </div>
                  )}
            {viewDays && showWidgetFltr && (
              <div className="col-sm-12 col-md-12 col-lg-12 d-md-inline-block center-select">
                <FormInput
                  name="Day"
                  label=""
                  labelClassName="required"
                  value={sday}
                  onChange={handleDaysChange}
                  placeholder="Days"
                  containerClass=""
                  type="select"
                >
                  <option value="">Days</option>
                  {daysList?.map((d, i) => {
                    return (
                      <Option value={d} key={i}>
                        {d}
                      </Option>
                    );
                  })}
                </FormInput>
                </div>
            )}
          </div>
        </div>
        {valueInString && valueInString !== "" ? (
          <p className="font-14 m-0 p-0">{"* Value " + valueInString}</p>
        ) : valueInString !== undefined ? (
          <p className="font-14 m-0 p-0">* Value in millions</p>
        ) : (
          <p className="font-14 m-0 p-0">&nbsp;</p>
        )}
                <div className="row">
                    <div className="col">
            {viewOption && (
              <div className="float-start  d-md-inline-block">
                <span
                  onClick={chartViewHandler}
                  className={`m-1 link ${
                    view === "chart" ? "text-primary" : "text-secondary"
                  }`}
                  style={{ cursor: "pointer" }}
                >
                  <Feather.BarChart2 />
                </span>
                <span
                  onClick={tableViewHandler}
                  className={`m-1 link ${
                    view === "table" ? "text-primary" : "text-secondary"
                  }`}
                  style={{ cursor: "pointer" }}
                >
                  <Feather.Table />
                </span>
              </div>
            )}
                    </div>
                </div>

        <div className="col">{children}</div>
            </div>
        </div>
        </></div>
        
  );
};
export default React.memo(Widget1);
