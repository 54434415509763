import { ApexOptions } from "apexcharts";
import Chart from "react-apexcharts";

interface dataSet {
  name: string;
  data: number[];
}

interface Iprops {
  datasets: dataSet[];
  labels: string[];
  title: string;
  colors: string[];
  subtitle?: string | "";
  exportfilename: string;
}
// simple line chart
const LineChart = (props: Iprops) => {
  // default options
  const { colors, datasets, labels, title, exportfilename, subtitle } = props;
  const apexLineChartWithLables: ApexOptions = {
    chart: {
      height: 380,
      type: "area",
      zoom: {
        enabled: false,
      },
      toolbar: {
        show: true,
        export: {
          csv: {
            filename: exportfilename,
          },
          svg: {
            filename: exportfilename,
          },
          png: {
            filename: exportfilename,
          },
        },
      },
    },
    title: {
      text: title,
      align: "center",
    },
    subtitle: {
      text: subtitle,
      align: "left",
      offsetY: 22,
      margin: 14,
    },
    colors: colors,
    dataLabels: {
      enabled: true,
    },
    stroke: {
      width: [1, 1],
      curve: "straight", //smooth
    },
    /*title: {
            text: '',
            align: 'left',
            style: {
                fontSize: '14px',
            },
        },*/
    grid: {
      row: {
        colors: ["transparent", "transparent"], // takes an array which will be repeated on columns
        opacity: 0.2,
      },
      borderColor: "#f1f3fa",
      padding: {
        left: 0,
        right: 0,
      },
    },
    markers: {
      size: 3,
    },
    xaxis: {
      categories: labels,
      title: {
        text: "",
      },
      // labels: {
      //     style: {
      //         colors: '#000'
      //     },

      // }
    },
    yaxis: {
      title: {
        text: "",
      },
      // labels: {
      //     style: {
      //         colors: '#000'
      //     }
      // }
    },
    legend: {
      showForSingleSeries: true,
      offsetY: 0,
      show: true,
      // labels:
      // {
      //     colors: '#000'
      // }
    },
    responsive: [
      {
        options: {
          chart: {
            toolbar: {
              show: false,
            },
          },
          legend: {
            show: false,
          },
          stroke: {
            curve: "smooth",
          },
        },
      },
    ],
  };

  // chart data
  // const apexLineChartWithLablesData = [
  //     {
  //         name: 'High - 2018',
  //         data: [403540.35, 584287.1, 294573.87, 176220.78, 487164.73, 314826.53, 1995.23],
  //     },
  //     {
  //         name: 'Low - 2018',
  //         data: [584287, 176220, 1995.23, 487164.73, 584287, 314826.53, 1995.23],
  //     },
  // ];
  return (
    <>
      {/* <h4 className="header-title mb-3">{title}</h4> */}
      <Chart
        options={apexLineChartWithLables}
        series={datasets}
        type="line"
        className="apex-charts"
        height={400}
      />
    </>
  );
};

export default LineChart;
