import { nanoid } from "@reduxjs/toolkit";
import React, { ChangeEventHandler, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Option from "../components/Option";
import chartIcon from "../components/custom/assets/images/dashboard/chart_icon.svg";
import downIcon from "../components/custom/assets/images/dashboard/down-icon.svg";
import tableIcon from "../components/custom/assets/images/dashboard/table_icon.svg";
import upIcon from "../components/custom/assets/images/dashboard/up-icon.svg";
import FormInput2 from "../components/custom/components/FormInput2";
import {
  Tbody,
  Thead,
} from "../components/custom/components/TradeCategoryPerformanceTable";
import TransactionTable from "../components/custom/components/TransactionTable";
import BarChart from "../components/custom/components/charts/apex/BarChart";
import { COLORS } from "../components/custom/constants";
import UserLayout from "../components/custom/layouts/UserLayout";
import { ToastTypes, useToast } from "../components/toast/ToastProvider";
import { defaultbrand, defaultLocation, defaultPortfolio, defaultTradeCategory, defaultTradeSubCategory, DropDownOption1, PAGE_SIZE } from "../config";
import api, { APIResponse, getAxiosRequestConfig } from "../config/api";
import {
    CSVdownload,
    YearOptions,
    handleDownloadPDF,
  } from "../config/functions";
import { AuthUserType } from "../redux/actions/authUserActions";
import { BrandType } from "../redux/actions/brandActions";
import { BrandCompanyType } from "../redux/actions/brandCompanyActions";
import { PortfolioType } from "../redux/actions/portfolioActions";
import {
  OptionsTypes,
  addToSelectedOption,
} from "../redux/actions/selectedOptionsActions";
import { SiteType } from "../redux/actions/siteActions";
import { TradeCategoryType } from "../redux/actions/tradeCategoryActions";
import { TradeSubCategoryType } from "../redux/actions/tradeSubCategoryActions";
import { StoreState } from "../redux/store";
import { CatSubCatCompanyWithBrandRelationDataType } from "./TransactionBillWiseReport";
import Loader from "../components/custom/components/Loader";
import Modal from "../components/Modal";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faWindowMaximize } from "@fortawesome/free-solid-svg-icons";
import { areaType } from "../redux/actions/areaActions";
import { outletType } from "../redux/actions/outletActions";
import { useLocation } from "react-router-dom";
import { UserLog } from "../config/common";
import SingleSelect from "../components/custom/components/SingleSelect";
import SingleSelect1 from "../components/custom/components/SingleSelect1";

export interface DropDownOption {
    value: number;
    label: string;
    imgUrl: string;
}

export interface GrowthAnalysisRecord {
    sales: string;
    transyear: number;
    transmonth: number;
    salespercent: string | null;
}

export interface GrowthAnalysisReportType {
    portfolio: number;
    record: GrowthAnalysisRecord[];
    count?: number;
}

const SalesGrowthAnalysisReport: React.FC = () => {

    const toast = useToast();
    const dispatch = useDispatch();
    const pageName = useLocation()
    const authUser = useSelector<StoreState, AuthUserType | null>(
        (state) => state?.authUser
    );
    const authUserToken = useSelector<StoreState, AuthUserType | null>(
        (state) => state?.authUser
    )?.["auth-token"]!;
    const config = getAxiosRequestConfig(authUserToken);
    const authUserSiteId = authUser?.siteId;
    const portfolioOption = useSelector<StoreState, PortfolioType[]>(
    (state) => state?.portfolio
    );
    const siteOption = useSelector<StoreState, SiteType[]>((state) => state?.site);
    const brandOption = useSelector<StoreState, BrandType[]>(
        (state) => state?.brand
    );
    const brandCompanyOption = useSelector<StoreState, BrandCompanyType[]>(
        (state) => state?.brandCompany
    );
    const selectedOption = useSelector<StoreState, OptionsTypes>(
        (state) => state?.selectedOption
    );

    const filteredBrand = brandOption?.filter((a) => {
        return brandCompanyOption?.some(
            (b) => b?.id?.toString() === a?.companyid?.toString() && b?.retailid !== null
        );
    });

    const port =
    authUser?.portfolioId !== undefined && authUser?.portfolioId !== null
    ? authUser?.portfolioId?.split(",")
    : [];
    let portfolioData = port
    ?.map((pe) => {
    return portfolioOption?.filter(
    (f) => f?.id?.toString() === pe?.toString()
    );
    })
    ?.flat(1);
    const pname = portfolioData[0]?.name;

    const st =
    authUser?.siteId !== undefined && authUser?.siteId !== null
    ? authUser?.siteId?.split(",")
    : [];
    let siteData = st
    ?.map((pe) => {
    return siteOption?.filter((f) => f?.id?.toString() === pe?.toString());
    })
    ?.flat(1);

    const br =
    authUser?.brandId !== undefined && authUser?.brandId !== null
    ? authUser?.brandId?.split(",")
    : [];
    let brandData = br
    ?.map((b) => {
    return filteredBrand?.filter((f) => f?.id?.toString() === b?.toString());
    })
    ?.flat(1);
    const outletList = useSelector<StoreState, outletType[]>(
    (state) => state?.outlet
    );
    const localityOption = useSelector<StoreState, areaType[]>(
    (state) => state?.locality
    );
    const categoryOption = useSelector<StoreState, TradeCategoryType[]>(
    (state) => state?.tradeCategory
    );
    const subCategoryOption = useSelector<StoreState, TradeSubCategoryType[]>(
    (state) => state?.tradeSubCategory
    );

    const [valueIn, setValueIn] = useState<string>("");
    const [portfolios, setPortfolios] = useState<string>("");
    const [sites, setSites] = useState<string>("");
    const [brands, setBrands] = useState<string>("");
    const [companies, setCompanies] = useState<string>("");
    const [location, setLocation] = useState<string>("");
    const [categories, setCategories] = useState<string>("");
    const [subCategories, setSubCategories] = useState<string>("");
    //loader
    const [fileLoader, setFileLoader] = useState<boolean>(false);
    const [loader, setLoader] = useState<boolean>(false);
    const [pag, setPag] = useState<boolean>(false);
    const [yearDropDown, setYearDropDown] = useState<number[]>(YearOptions());
    const [GrowthAnalysisData, setGrowthAnalysisData] = useState<GrowthAnalysisReportType[]>([]);
    
    const limit = PAGE_SIZE;
    const [offset, setOffset] = useState<number>(0);
    const [range, setRange] = useState<number[]>([]);
    const [length, setLength] = useState<number>(50);
    const [selectedRows, setSelectedRows] = useState<string[]>([]);
    const [showModal, setShowModal] = useState<boolean>(false);
    const [isButtonVisible, setButtonVisible] = useState(false);
    const [disableLocality, setDisableLocality] = useState<boolean>(false);
    const [condition,setCondition] = useState<boolean>(false)
    const [year, setYear] = useState<Date>(new Date());



  const [portfolioDrop, setPortfolioDrop] = useState<DropDownOption[]>([]);
  const [locationDrop, setLocationDrop] = useState<DropDownOption[]>([]);
  const [catDrop,setCatDrop] = useState<DropDownOption[]>([])
  const [subcatDrop,setSubcatDrop] = useState<DropDownOption[]>([])
  const [brandDrop,setBrandDrop] = useState<DropDownOption[]>([])


    const showExpandModalHandler = () => {
        setShowModal(true)
    };

    const handleSelectedRowsChange = (selectedRows: string[]) => {
        setSelectedRows(selectedRows);
    };

    const handleOff = (conditionVal: boolean) => {
        setCondition(conditionVal);
    };
    const [ catSubCatCompanyWithBrandRelationData, setCatSubCatCompanyWithBrandRelationData] =  useState<CatSubCatCompanyWithBrandRelationDataType[]>([]);

    const portfolio = useMemo<number[]>(() => {
        return portfolios !== "" ? [parseInt(portfolios)] : [];
    }, [portfolios]);

    const site = useMemo<string[]>(() => {
        if (authUserSiteId) {
            return authUserSiteId?.split(",")?.map((s) => {
            return s?.toString();
        });
        } else {
            return [];
        }
    }, [authUserSiteId]);

    const brand = useMemo<number[]>(() => {
        return brands !== "" ? [parseInt(brands)] : [];
    }, [brands]);

    const category = useMemo<number[]>(() => {
        return categories !== "" ? [parseInt(categories)] : [];
    }, [categories]);

    const subCategory = useMemo<number[]>(() => {
        return subCategories !== "" ? [parseInt(subCategories)] : [];
    }, [subCategories]);
    
    const monthsArray = [
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "Jun",
        "Jul",
        "Aug",
        "Sep",
        "Oct",
        "Nov",
        "Dec",
    ];

    /*const getDownloadData = (Data: GrowthAnalysisReportType[]) => {
        var tableHead: Thead[] = [
            { id: "Month", children: "Month", text: "Month" },
        ];
        var tableBody: Tbody[][] = [];
        Data?.forEach((y, pi) => {
            yearDropDown
            ?.map((f) => f)
            ?.sort()
            ?.forEach((yr) => {
                var tBody: Tbody[] = [];
                    tableHead?.push({
                        children: yr,
                        text: yr?.toString(),
                        id: yr,
                    },
                    {
                        children: yr + " % Change",
                        text: yr + " % Change",
                        id: yr + " % Change",
                    });
                    const sites = y?.record?.filter(
                        (r) => r?.transyear?.toString() === yr?.toString()
                    );
                    monthsArray?.forEach((m, i) => {
                        const site = sites?.find((s) => s?.transmonth === i + 1);
                        tableBody[i] = [
                            ...(tableBody[i] || []),
                            {
                                id: nanoid(),
                                theadId: "Month",
                                children: monthsArray[i],
                                text: monthsArray[i],
                            },
                            {
                                id: nanoid(),
                                theadId: site?.transyear,
                                children: site?.sales,
                                text: site?.sales,
                            },
                            {
                            id: nanoid(),
                            theadId: site?.transyear + " % Change",
                            children:
                                site?.salespercent !== undefined &&
                                site?.salespercent !== null ? (
                                <div
                                    className={
                                        site?.salespercent !== undefined &&
                                        site?.salespercent !== null
                                        ? parseFloat(site?.salespercent) > 0
                                        ? "p-mtd text-truncate"
                                        : parseFloat(site?.salespercent) < 0
                                        ? "n-mtd text-truncate"
                                        : ""
                                        : ""
                                    }
                                >
                                {parseFloat(site?.salespercent) > 0 ? (
                                    <i>
                                        <img src={upIcon} alt="icon"></img>{" "}
                                        {site?.salespercent?.toString() || ""}
                                    </i>
                                ) : parseFloat(site?.salespercent) < 0 ? (
                                    <i>
                                        <img src={downIcon} alt="icon"></img>{" "}
                                        {site?.salespercent?.toString() || ""}
                                    </i>
                                ) : (
                                    site?.salespercent?.toString()
                                )}
                                </div>
                            ) : (
                            ""
                            ),
                            text:
                                site?.salespercent !== undefined &&
                                site?.salespercent !== null
                                ? site?.salespercent
                                : "",
                            },
                        ];
                    });
                });
            });
        return { tbody: tableBody, thead: tableHead };
    };*/
    
    const getDownloadData = (Data: GrowthAnalysisReportType[]) => {
        var tableHead: Thead[] = [
            { id: "Month", children: "Month", text: "Month" },
        ];
        var tableBody: Tbody[][] = [];
        let arr = [new Date(year)?.getFullYear()-2, new Date(year)?.getFullYear() - 1, new Date(year)?.getFullYear()];
        arr?.forEach((yr) => {
            tableHead?.push({
              children: yr,
              text: yr?.toString(),
              id: yr,
            },
            {
              children: yr + " % Change",
              text: yr + " % Change",
              id: yr + " % Change",
            });
        });
        Data?.forEach((y, pi) => {
            yearDropDown
            ?.map((f) => f)
            ?.sort()
            ?.forEach((yr) => {
                    const sites = y?.record?.filter(
                        (r) => r?.transyear?.toString() === yr?.toString()
                    ) || [];
                    monthsArray?.forEach((m, i) => {
                        const site = sites?.find((s) => s?.transmonth === i + 1);
                        tableBody[i] = [
                            ...(tableBody[i] || []),
                            {
                                id: nanoid(),
                                theadId: "Month",
                                children: monthsArray[i],
                                text: monthsArray[i],
                            },
                            {
                                id: nanoid(),
                                theadId: site?.transyear,
                                children: site?.sales,
                                text: site?.sales,
                            },
                            {
                            id: nanoid(),
                            theadId: site?.transyear + " % Change",
                            children:
                                site?.salespercent !== undefined &&
                                site?.salespercent !== null ? (
                                <div
                                    className={
                                        site?.salespercent !== undefined &&
                                        site?.salespercent !== null
                                        ? parseFloat(site?.salespercent) > 0
                                        ? "p-mtd text-truncate"
                                        : parseFloat(site?.salespercent) < 0
                                        ? "n-mtd text-truncate"
                                        : ""
                                        : ""
                                    }
                                >
                                {parseFloat(site?.salespercent) > 0 ? (
                                    <i>
                                        <img src={upIcon} alt="icon"></img>{" "}
                                        {site?.salespercent?.toString() || ""}
                                    </i>
                                ) : parseFloat(site?.salespercent) < 0 ? (
                                    <i>
                                        <img src={downIcon} alt="icon"></img>{" "}
                                        {site?.salespercent?.toString() || ""}
                                    </i>
                                ) : (
                                    site?.salespercent?.toString()
                                )}
                                </div>
                            ) : (
                            ""
                            ),
                            text:
                                site?.salespercent !== undefined &&
                                site?.salespercent !== null
                                ? site?.salespercent
                                : "",
                            },
                        ];
                    });
                });
            });
        return { tbody: tableBody, thead: tableHead };
    };

    const fetchDataForDownload = async () => {
        let error = false;
        if (portfolios === "") {
            toast("Select Portfolio", ToastTypes?.ERROR);
            error = true;
        } else if (location === "" && brands === "") {
            toast("Select Locality and Brand", ToastTypes?.ERROR);
            error = true;
        } else if (location === "" && brands !== "") {
            toast("Select Locality", ToastTypes?.ERROR);
            error = true;
        } else if (brands === "" && location !== "") {
            toast("Select Brand", ToastTypes?.ERROR);
            error = true;
        }
        var getData = {
            portfolio: portfolio,
            site: site,
            locality: location===defaultLocation?.label?'':location,
            tradeCategory: category,
            tradeSubCategory: subCategory,
            brand: brand,
        };
        if (error === false && getData !== undefined) {
            const response = await api.post<
            APIResponse<{ records: GrowthAnalysisReportType[] }>
            >(`kpi/sales/growthAnalysis`, getData, config);
            if (response?.status === 200 && response?.data?.data) {
                return response?.data?.data?.records;
            }
        }
        return [];
    };

    const handleCsvDownload = async () => {
        setFileLoader(true);
        // const csvData = await fetchDataForDownload();
        const csvData = GrowthAnalysisData;
        if (csvData[0]?.record?.length !== 0 && csvData[0]?.record?.length !== undefined) {
            const d = { ...getDownloadData(csvData) };
           
        const csvHead = d?.thead;
        const csvBody = d?.tbody;
        const head = [csvHead?.map((h) => h?.text || "") || []];
        const body =
        csvBody?.map((tr, i) => {
            return (
                csvHead?.map((th, j) => {
                    const col = tr?.find((r) => r?.theadId === th?.id);
                    return col?.text || "";
                }) || []
            );
        }) || [];
            const rows = [...head, ...body];
            const props = {
                rows: rows,
                fileName: "Growth Anlaysis Report",
            };
            CSVdownload(props);
            setFileLoader(false);
            if(authUser){
                const logProps1 = {
                    action: 'Csv',
                    event: `${pageName?.pathname?.replace("\\", " ")} page | Filters - ${combinedValues} | Csv Clicked | Export Status: Success | Data Status: Success`
                };
                UserLog(logProps1)
            }
        } else {
            setFileLoader(false);
            if(authUser){
                const logProps1 = {
                    action: 'Csv',
                    event: `${pageName?.pathname?.replace("\\", " ")} page | Filters - ${combinedValues} | Csv Clicked | Export Status: Success | Data Status: Empty`
                };
                UserLog(logProps1)
            }
        }
        setFileLoader(false);
    };

    const handlePdfDownload = async () => {
        setFileLoader(true);
        // const pdfData = await fetchDataForDownload();
        const pdfData = GrowthAnalysisData;
        if (
        pdfData[0]?.record?.length !== 0 &&
        pdfData[0]?.record?.length !== undefined
        ) {
        const d = { ...getDownloadData(pdfData) };
        const thead = d?.thead;
        const tbody = d?.tbody;

        const head = [thead?.map((h) => h?.text || "") || []];
        const body =
        tbody?.map((tr, i) => {
        return (
        thead?.map((th, j) => {
        const col = tr?.find((r) => r?.theadId === th?.id);
        return col?.text || "";
        }) || []
        );
        }) || [];

        const props = {
        head: head,
        body: body,
        fileName: "Growth Anlaysis Report",
        title: "Growth Anlaysis Report",
        };
        handleDownloadPDF(props);
        setFileLoader(false);
        if(authUser){
        const logProps1 = {
        action: 'Pdf',
        event: `${pageName?.pathname?.replace("\\", " ")} page | Filters - ${combinedValues} | Pdf Clicked | Export Status: Success | Data Status: Success`
        };
        UserLog(logProps1)
        }
        } else {
        setFileLoader(false);
        if(authUser){
        const logProps1 = {
        action: 'Pdf',
        event: `${pageName?.pathname?.replace("\\", " ")} page | Filters - ${combinedValues} | Pdf Clicked | Export Status: Success | Data Status: Empty`
        };
        UserLog(logProps1)
        }
        }
        setFileLoader(false);
        };

    const tHead = useMemo<Thead[]>(() => {
        var theadArray: {
            id: string;
            children: string;
            text: string;
        }[] = [{ id: "Category", children: "Category", text: "Category" }];

        yearDropDown?.forEach((yr) => {
            theadArray?.push(
            {
                id: yr?.toString(),
                children: yr?.toString(),
                text: yr?.toString(),
            },
            {
                id: yr + " % Change",
                children: yr + " % Change",
                text: yr + " % Change",
            },
            { id: yr + " YTD", children: yr + " YTD", text: yr + " YTD" }
            );
        });

        return theadArray;
    }, [yearDropDown]);

    useEffect(() => {
        const header = tHead
        ?.map((a) => a?.text)
        ?.filter((id): id is string => typeof id === "string" && id !== undefined);
        setSelectedRows(header);
    }, [tHead]);

    const getTableData = () => {
        var tableHead: Thead[] = [
            { id: "Month", children: "Month", text: "Month" },
        ];
        var tableBody: Tbody[][] = [];
        let arr = [new Date(year)?.getFullYear()-2, new Date(year)?.getFullYear() - 1, new Date(year)?.getFullYear()];
        arr?.forEach((yr) => {
            tableHead?.push({
              children: yr,
              text: yr?.toString(),
              id: yr,
            },
            {
              children: yr + " % Change",
              text: yr + " % Change",
              id: yr + " % Change",
            });
        });
        MonthlyFilteredData?.forEach((y, pi) => {
            yearDropDown
            ?.map((f) => f)
            ?.sort()
            ?.forEach((yr) => {
                const sites = y?.record?.filter(
                    (r) => r?.transyear?.toString() === yr?.toString()
                ) || [];
                monthsArray?.forEach((m, i) => {
                    const site = sites?.find((s) => s?.transmonth === i + 1);
                    tableBody[i] = [ 
                        ...(tableBody[i] || []),
                        {
                            id: nanoid(),
                            theadId: "Month",
                            children: monthsArray[i],
                            text: monthsArray[i],
                        },
                        {
                            id: nanoid(),
                            theadId: yr,
                            children: site?.sales,
                            text: site?.sales,
                        },
                        {
                            id: nanoid(),
                            theadId: yr + " % Change",
                            children:
                            site?.salespercent !== undefined &&
                            site?.salespercent !== null ? (
                                <div
                                    className={
                                        site?.salespercent !== undefined &&
                                        site?.salespercent !== null
                                        ? parseFloat(site?.salespercent) > 0
                                            ? " p-mtd text-truncate"
                                            : parseFloat(site?.salespercent) <= 0
                                            ? " n-mtd text-truncate"
                                            : ""
                                        : ""
                                    }
                                >
                                {parseFloat(site?.salespercent) > 0 ? (
                                    <i>
                                        <img src={upIcon} alt="icon"></img>{" "}
                                        {site?.salespercent?.toString() || ""}
                                    </i>
                                ) : parseFloat(site?.salespercent) <= 0 ? (
                                    <i>
                                        <img src={downIcon} alt="icon"></img>{" "}
                                        {site?.salespercent?.toString() || ""}
                                    </i>
                                ) : (
                                    site?.salespercent?.toString()
                                )}
                                </div>
                            ) : site?.salespercent === null ? (
                              "-"
                            ) : (
                              ""
                            ),
                          text:
                            site?.salespercent !== undefined &&
                            site?.salespercent !== null
                            ? site?.salespercent
                            : "",
                        },
                    ]
                })
            });
        });

        return { tbody: tableBody, thead: tableHead };
    }

    const subCategoryFilteredByCategory = useMemo<TradeSubCategoryType[]>(() => {
        if (categories !== "") {
            return subCategoryOption?.filter((sc) => {
                return sc?.catid?.toString() === categories?.toString();
            });
        } else return subCategoryOption;
    }, [categories, subCategoryOption]);

    const BrandFilteredByCompany = useMemo<BrandType[]>(() => {
        if (companies !== "") {
            return brandData?.filter((s) => {
                return s?.companyid?.toString() === companies?.toString();
            });
        } else if (subCategories !== "") {
            let brands: BrandType[] = [];
            catSubCatCompanyWithBrandRelationData?.forEach((s) => {
                let obj = s?.record?.find((r) =>
                r?.tradesubcatids?.split(",")?.includes(subCategories)
                );
                obj?.brandids?.split(",")?.forEach((m) => {
                    let bc = brandData?.find((f) => f?.id?.toString() === m);
                    brands?.push(bc!);
                });
            });
            return brands;
        } else if (categories !== "") {
            let brands: BrandType[] = [];
            catSubCatCompanyWithBrandRelationData?.forEach((s) => {
                let obj = s?.record?.find((r) => r?.tradecatid?.toString() === categories);
                obj?.brandids?.split(",")?.forEach((m) => {
                    let bc = brandData?.find((f) => f?.id?.toString() === m);
                    brands?.push(bc!);
                });
            });
            return brands;
        } else return brandData;
    }, [
    companies,
    brandData,
    catSubCatCompanyWithBrandRelationData,
    categories,
    subCategories,
    ]);

    const handlePortfolioDrop = (selected: any) => {
        if(fileLoader===false){
        if(selected.length>0){
          const selectedPortfolio = selected as DropDownOption[];
          if(selectedPortfolio[0].value!==null&&selectedPortfolio[0].value!==0){
            setPortfolioDrop(selectedPortfolio)
            setPortfolios(selectedPortfolio[0]?.value?.toString())
            const data = { ...selectedOption, portfolio: selectedPortfolio[0]?.value?.toString() };
            dispatch(addToSelectedOption(data));
            setOffset(0);
            setPag(false);
          }
        }
      }
      };
    
      const handleClearPortfolio = () =>{
        let pid =portfolioData[0] && portfolioData[0]?.id? portfolioData[0].id?.toString(): "";
        setPortfolios(selectedOption.portfolio ? selectedOption.portfolio : pid);
        const b = portfolioOption.filter((a)=>a?.id?.toString()===pid?.toString())
        if (b?.length > 0 && b[0]?.id !== undefined) {
          const val: DropDownOption = {
            value: b[0]?.id, 
            label: b[0]?.name,
            imgUrl: b[0]?.logourl, 
          };
          setPortfolioDrop([val]);
    
          if(fileLoader===false){
          setLocation("");
          setLocationDrop([]);
          const data = { ...selectedOption, portfolio: b[0]?.id?.toString(),location:''};
          dispatch(addToSelectedOption(data));
          setOffset(0);
          setPag(false);
        }
      }
      }
    
      const handleLocationDrop = (selected: any) => {
        if(fileLoader===false){
        if(selected.length>0){
          const selectedLocation = selected as DropDownOption[];
          if(selectedLocation[0].value!==null&&selectedLocation[0].value!==0){
            setLocationDrop(selectedLocation)
            setLocation(selectedLocation[0]?.label?.toString())
           const data = { ...selectedOption,location: selectedLocation[0]?.label?.toString()};
           dispatch(addToSelectedOption(data));
           setOffset(0);
           setPag(false);
          }
        }
      }
      };
      
      const handleClearLocation = () =>{
           if(fileLoader===true){
            setLocation(location ? location : '');
            const b = localityOption.filter((a)=>a?.area?.toString()===location?.toString())
            if (b?.length > 0 && b[0]?.id !== undefined) {
              const val: DropDownOption = {
                value: b[0]?.id, 
                label: b[0]?.area,
                imgUrl:'', 
              };
              setLocationDrop([val]);
           }
           else{
            setLocationDrop([])
         }
          }
         
           if(fileLoader===false){
             setLocationDrop([])
             setLocation('')
           const data = { ...selectedOption,location: ""};
            dispatch(addToSelectedOption(data));
            setOffset(0);
            setPag(false);
           }
      }
    
      const handleCatDrop = (selected: any) => {
        if(fileLoader===false){
        if(selected.length>0){
          const selectedCat = selected as DropDownOption[];
          if(selectedCat[0].value!==null&&selectedCat[0].value!==0){
            setCatDrop(selectedCat)
            setCategories(selectedCat[0]?.value?.toString())
            setSubcatDrop([])
            setSubCategories('')
            setBrandDrop([])
            setBrands('')
            const data = {
              ...selectedOption,
              category: selectedCat[0]?.value?.toString(),
              subCategory: "",
              brand: "",
            };
            dispatch(addToSelectedOption(data));
            setOffset(0);
            setPag(false);
          }
        }
      }
      };
      
      const handleClearCat = () =>{
        if(fileLoader===true){
          setCategories(categories ? categories : '');
          const b = categoryOption.filter((a)=>a?.id?.toString()===categories?.toString())
          if (b?.length > 0 && b[0]?.id !== undefined) {
            const val: DropDownOption = {
              value: b[0]?.id, 
              label: b[0]?.name,
              imgUrl:'', 
            };
            setCatDrop([val]);
         }
         else{
          setCatDrop([])
       }
        }
       
         if(fileLoader===false){
          setCatDrop([])
          setCategories('')
          setSubcatDrop([])
            setSubCategories('')
            setBrandDrop([])
            setBrands('')
           const data = { ...selectedOption,
            category: '',
            subCategory: "",
            brand: "",};
           dispatch(addToSelectedOption(data));
           setOffset(0);
           setPag(false);
         }
      }
    
      const handleSubcatDrop = (selected: any) => {
        if(fileLoader===false){
        if(selected.length>0){
          const selectedSubcat = selected as DropDownOption[];
          if(selectedSubcat[0].value!==null&&selectedSubcat[0].value!==0){
            setSubcatDrop(selectedSubcat)
            setSubCategories(selectedSubcat[0]?.value?.toString())
            setBrandDrop([])
            setBrands('')
            const data = { ...selectedOption,
              subCategory: selectedSubcat[0]?.value?.toString(),
              brand: "",};
             dispatch(addToSelectedOption(data));
             setOffset(0);
             setPag(false);
          }
        }
      }
      };
      
      const handleClearSubcat = () =>{
        if(fileLoader===true){
          setSubCategories(subCategories ? subCategories : '');
          const b = subCategoryOption.filter((a)=>a?.id?.toString()===subCategories?.toString())
          if (b?.length > 0 && b[0]?.id !== undefined) {
            const val: DropDownOption = {
              value: b[0]?.id, 
              label: b[0]?.name,
              imgUrl:'', 
            };
            setSubcatDrop([val]);
         }else{
          setSubcatDrop([])
       }
        }
       
         if(fileLoader===false){
          setSubcatDrop([])
            setSubCategories('')
            setBrandDrop([])
            setBrands('')
           const data = { ...selectedOption,
            subCategory: "",
            brand: "",};
           dispatch(addToSelectedOption(data));
           setOffset(0);
           setPag(false);
         }
      }
    
      const handleBrandDrop = (selected: any) => {
        if(fileLoader===false){
        if(selected.length>0){
          const selectedBrand = selected as DropDownOption[];
          if(selectedBrand[0].value!==null&&selectedBrand[0].value!==0){
            setBrandDrop(selectedBrand)
            setBrands(selectedBrand[0]?.value?.toString())
           const data = { ...selectedOption,
            brand: selectedBrand[0]?.value?.toString()};
           dispatch(addToSelectedOption(data));
           setOffset(0);
           setPag(false);
          }
        }
      }
      };
      
      const handleClearBrand = () =>{
        if(fileLoader===true){
          setBrands(brands ? brands : '');
          const b = brandOption.filter((a)=>a?.id?.toString()===brands?.toString())
          if (b?.length > 0 && b[0]?.id !== undefined) {
            const val: DropDownOption = {
              value: b[0]?.id, 
              label: b[0]?.name,
              imgUrl: b[0]?.logourl, 
            };
            setBrandDrop([val]);
         }else {
          setBrandDrop([]);
         }
        }
       
         if(fileLoader===false){
            setBrandDrop([])
            setBrands('')
           const data = { ...selectedOption,
            brand: ""};
           dispatch(addToSelectedOption(data));
           setOffset(0);
           setPag(false);
         }
      }

    const handlePag = () => {
        setPag(true);
    };

    const handleApply = () => {
        let error = false;
        if (portfolios === "") {
            toast("Select Portfolio", ToastTypes?.ERROR);
            error = true;
        } else if (location === "" && brands === "") {
            toast("Select Locality and Brand", ToastTypes?.ERROR);
            error = true;
        } else if (location === "" && brands !== "") {
            toast("Select Locality", ToastTypes?.ERROR);
            error = true;
        } else if (brands === "" && location !== "") {
            toast("Select Brand", ToastTypes?.ERROR);
            error = true;
        }
        var getData = {
            portfolio: portfolio,
            site: site,
            locality: location===defaultLocation?.label?'':location,
            tradeCategory: category,
            tradeSubCategory: subCategory,
            brand: brand,
            limit: limit?.toString(),
            offset: offset?.toString()==='NaN'||offset===undefined?'0':offset?.toString(),
            authUserRole: authUser?.roleId ? authUser?.roleId : "",
        };

        if (
        error === false &&
        getData !== undefined &&
        getData?.limit !== "" &&
        getData?.offset !== ""
        ) {
        if (pag === false) {
        setLoader(true);
        }
        api
        .post<APIResponse<{ records: GrowthAnalysisReportType[] }>>(
        `kpi/sales/growthAnalysis`,
        getData,
        config
        )
        .then((response) => {
        if (response?.status === 200 && response?.data?.data) {
        const result = response?.data?.data?.records;
        setLoader(false);
        setPag(true);
        setGrowthAnalysisData(result);
        setCondition(false)
        if(authUser){
        const logProps1 = {
        action: 'Apply',
        event: `${pageName?.pathname?.replace("\\", " ")} page | Filters - ${combinedValues} | Apply Clicked | Data Status: ${result?.[0]?.count && result[0]?.count > 0 ? 'Success' : 'Empty'}`
        };
        UserLog(logProps1)
        }
        }
        else {
        setGrowthAnalysisData([]);
        setLoader(false);
        setPag(true);
        setCondition(false)
        }
        })
        .catch((error) => {});
        } 

    };

    useEffect(()=>{    
        if(selectedOption?.isOutletUser){
            setDisableLocality(true);
        }
        else{
            setDisableLocality(false);
        }
    },[authUser, outletList,selectedOption]);

    useEffect(() => {
        if (portfolios !== "" && brands !== "" && fileLoader === false)
            handleApply();
    }, [portfolios]);

    useEffect(()=>{
        let pid =portfolioData[0] && portfolioData[0]?.id? portfolioData[0].id?.toString(): "";
        setPortfolios(selectedOption.portfolio ? selectedOption.portfolio : pid);
        const b = portfolioOption.filter((a)=>a?.id?.toString()===pid?.toString())
        if (b?.length > 0 && b[0]?.id !== undefined) {
          const val: DropDownOption = {
            value: b[0]?.id, 
            label: b[0]?.name,
            imgUrl: b[0]?.logourl, 
          };
          setPortfolioDrop([val]);
        }
    },[portfolioOption,selectedOption])

    useEffect(() => {
        let sid = siteData[0] && siteData[0]?.id ? siteData[0]?.id?.toString() : "";
        setSites(selectedOption?.site ? selectedOption?.site : sid);
    }, [siteData, selectedOption]);

    useEffect(()=>{
        setLocation(selectedOption.location? selectedOption.location:'')
        const b = localityOption.filter((a)=>a?.area?.toString()===selectedOption.location?.toString())
        if (b?.length > 0 && b[0]?.id !== undefined) {
          const val: DropDownOption = {
            value: b[0]?.id, 
            label: b[0]?.area,
            imgUrl:'', 
          };
          setLocationDrop([val]);
        }
        },[localityOption,selectedOption])
      
        useEffect(()=>{
          setCategories(selectedOption.category ? selectedOption.category:'');
          const b = categoryOption.filter((a)=>a?.id?.toString()===selectedOption.category?.toString())
          if (b?.length > 0 && b[0]?.id !== undefined) {
            const val: DropDownOption = {
              value: b[0]?.id, 
              label: b[0]?.name,
              imgUrl:'', 
            };
            setCatDrop([val]);
          }
          },[categoryOption,selectedOption])
          
          
          useEffect(()=>{
          setSubCategories(selectedOption.subCategory ? selectedOption.subCategory:'');
          const b = subCategoryOption.filter((a)=>a?.id?.toString()===selectedOption.subCategory?.toString())
          if (b?.length > 0 && b[0]?.id !== undefined) {
            const val: DropDownOption = {
              value: b[0]?.id, 
              label: b[0]?.name,
              imgUrl:'', 
            };
            setSubcatDrop([val]);
          }
          },[subCategoryOption,selectedOption])
      
          useEffect(()=>{
            setBrands(selectedOption.brand ? selectedOption.brand:'');
            const b = brandOption.filter((a)=>a?.id?.toString()===selectedOption.brand?.toString())
            if (b?.length > 0 && b[0]?.id !== undefined) {
              const val: DropDownOption = {
                value: b[0]?.id, 
                label: b[0]?.name,
                imgUrl: b[0]?.logourl, 
              };
              setBrandDrop([val]);
            }
          },[brandOption,selectedOption])

    useEffect(() => {
        if (sites !== ""&&selectedOption.isOutletUser===true) {
            let siteCurrencyObject = siteOption?.find(
                (s) => s?.id?.toString() === sites?.toString()
            );
            setValueIn(
                siteCurrencyObject?.currencycode +
                " | " +
                siteCurrencyObject?.valuecaption
            );
        } 
        else if(portfolios !== ""&&selectedOption.isOutletUser===false){
            let portfolioCurrencyObject = portfolioOption?.find(
                (s) => s?.id?.toString() === portfolios?.toString()
            );
            setValueIn(
                portfolioCurrencyObject?.reportingcurrency +
                " | " +
                portfolioCurrencyObject?.valuecaption
            );
        }
        else setValueIn("");
    }, [sites, siteOption,selectedOption]);

    useEffect(() => {
        if (GrowthAnalysisData?.length > 0)
        setLength(
            GrowthAnalysisData[0]?.count ? GrowthAnalysisData[0]?.count : 0
        );
    }, [GrowthAnalysisData]);
    
    useEffect(() => {
        var getData = {
            portfolio: portfolio,
        };
        if (portfolio?.length > 0 && getData !== undefined) {
            api
            .post<
                APIResponse<{ records: CatSubCatCompanyWithBrandRelationDataType[] }>
            >(`filter/catSubcatCompanyRelationWithBrand`, getData, config)
            .then((response) => {
                if (response?.status === 200 && response?.data?.data) {
                    const result = response?.data?.data?.records;
                    setCatSubCatCompanyWithBrandRelationData(result);
                }
            })
            .catch((error) => {});
        } else {
            setCatSubCatCompanyWithBrandRelationData([]);
        }
    }, [portfolio]);

    const MonthlyFilteredData = useMemo<GrowthAnalysisReportType[]>(() => {
        const data: GrowthAnalysisReportType[] = [];
        if (GrowthAnalysisData?.length !== 0)
            GrowthAnalysisData?.forEach((d) => {
                data?.push({
                    portfolio: d?.portfolio,
                    record: yearDropDown
                    ?.map((yr) => {
                        return d?.record?.filter((r) => {
                            return yr?.toString() === r?.transyear?.toString();
                        });
                    })
                    ?.flat(1),
                });
            });
        return data;
    }, [GrowthAnalysisData, yearDropDown]);



    const portFilter = portfolioOption?.filter((a)=>a?.id?.toString()===portfolios?.toString())
    const catFilter = categoryOption?.filter((a)=>a?.id?.toString()===categories?.toString())
    const subcatFilter = subCategoryOption?.filter((a)=>a?.id?.toString()===subCategories?.toString())
    const brandFilter = brandOption?.filter((a)=>a?.id?.toString()===brands?.toString())
    const localityFilter = localityOption?.filter((a)=>a?.area?.toString()===location?.toString())
    const combinedValues = [
        ...portFilter?.map(a => `Portfolio: ${a?.name}`),           
        ...catFilter?.map(c=> `Trade Category: ${c?.name}`),
        ...subcatFilter?.map(d=> `Trade Sub Category: ${d?.name}`),
        ...brandFilter?.map(f=> `Brand: ${f?.name}`),  
        ...localityFilter?.map(f=>`Locality: ${f?.area}`),     
        `Period: ${year?.getFullYear()-2} - ${year?.getFullYear()}`,
    ]
    ?.filter(name => name && name?.trim() !== '') 
    ?.join(', ');  

    const colors = [
        COLORS?.COLOR1,
        COLORS?.COLOR2,
        COLORS?.COLOR3,
        COLORS?.COLOR4,
        COLORS?.COLOR5,
        COLORS?.COLOR6,
        COLORS?.COLOR7,
        COLORS?.COLOR8,
    ];

    useEffect(()=>{
        if(authUser){
            const logProps1 = {
                action:`Visit`,
                event: `${pageName?.pathname?.replace("\\", " ")} page | Visited `
            };
            UserLog(logProps1)
        }
    },[])

    return (
        <UserLayout>
            <div className="card background-transparent p-1">
            <div className="row">
                <div className="col-lg-4 wow fadeInLeft ps-0" >
                    <h4 className="b-title">Growth Analysis</h4>
                </div>

            <div className="col-lg-8 wow fadeInRight">
                <div className="row">
                    <div className="col-sm-6 col-md-4 col-lg-3">
                        {/* <SingleSelect
                            options={[
                                defaultPortfolio,
                                ...portfolioData?.sort(function (a, b) {
                                if (a?.name < b?.name) {
                                return -1;
                                }
                                if (a?.name > b?.name) {
                                return 1;
                                }
                                return 0;
                                })?.map((s, i) => {
                                return (
                                {
                                value: s?.id ? s?.id?.toString() : '',
                                label: s?.name,
                                imgUrl: s?.logourl,
                                }
                                );
                                })
                            ]}
                            label=""
                            selectedOptions={portfoliosOption}
                            setSelectedOptions={handlePortfolioChange}
                            disabled={disableLocality}
                            placeHolder="Select Portfolio"
                        /> */}
                         <SingleSelect1
                      options={portfolioData.map((c, i) => {
                        return {
                          value: c?.id?c?.id:0,
                          label: c?.name?c?.name:'',
                          imgUrl: "",
                        };
                      })}
                      selectedOptions={portfolioDrop}
                      setSelectedOptions={handlePortfolioDrop}
                      placeHolder="Select Portfolio"
                      onClear={handleClearPortfolio}
                      disabled = {disableLocality}
                    />
                    </div>
                    <div className="col-sm-6 col-md-4 col-lg-3">                    
                        {/* <SingleSelect
                            options={[
                            defaultLocation,
                            ...localityOption?.sort(function (a, b) {
                            if (a?.area < b?.area) {
                            return -1;
                            }
                            if (a?.area > b?.area) {
                            return 1;
                            }
                            return 0;
                            })?.map((s, i) => {
                            return (
                            {
                            value: s?.id ? s?.id?.toString() : '',
                            label: s?.area,
                            imgUrl: '',
                            }
                            );
                            })
                            ]}
                            label=""
                            selectedOptions={locationOption}
                            setSelectedOptions={handleLocationChange}
                            disabled={disableLocality}
                            placeHolder="Select Portfolio"
                        /> */}
                        <SingleSelect1
                      options={localityOption.map((c, i) => {
                        return {
                          value: c?.id?c?.id:0,
                          label: c?.area?c?.area:'',
                          imgUrl: "",
                        };
                      })}
                      selectedOptions={locationDrop}
                      setSelectedOptions={handleLocationDrop}
                      placeHolder="Select Locality"
                      onClear={handleClearLocation}
                      disabled = {disableLocality}
                    />
                    </div>
                </div>

                <div className="row mt-1">
                    <div className="col-sm-6 col-md-4 col-lg-3">
                        {/* <SingleSelect
                            options={[
                            defaultTradeCategory,
                            ...categoryOption?.sort(function (a, b) {
                            if (a?.name < b?.name) {
                            return -1;
                            }
                            if (a?.name > b?.name) {
                            return 1;
                            }
                            return 0;
                            })?.map((s, i) => {
                            return (
                            {
                            value: s?.id ? s?.id?.toString() : '',
                            label: s?.name,
                            imgUrl: '',
                            }
                            );
                            })
                            ]}
                            label=""
                            selectedOptions={categoriesOption}
                            setSelectedOptions={handleCategoryChange}
                            disabled={disableLocality}
                            placeHolder="Select Trade Category"
                        /> */}
                        <SingleSelect1
                      options={categoryOption.map((c, i) => {
                        return {
                          value: c?.id?c?.id:0,
                          label: c?.name?c?.name:'',
                          imgUrl: "",
                        };
                      })}
                      selectedOptions={catDrop}
                      setSelectedOptions={handleCatDrop}
                      placeHolder="Select Trade Category"
                      onClear={handleClearCat}
                      disabled = {disableLocality}
                    />
                    </div>
                    <div className="col-sm-6 col-md-4 col-lg-3">
                        {/* <SingleSelect
                            options={[
                            defaultTradeSubCategory,
                            ...subCategoryFilteredByCategory?.sort(function (a, b) {
                            if (a?.name < b?.name) {
                            return -1;
                            }
                            if (a?.name > b?.name) {
                            return 1;
                            }
                            return 0;
                            })?.map((s, i) => {
                            return (
                            {
                            value: s?.id ? s?.id?.toString() : '',
                            label: s?.name,
                            imgUrl: '',
                            }
                            );
                            })
                            ]}
                            label=""
                            selectedOptions={subCategoriesOption}
                            setSelectedOptions={handleSubCategoryChange}
                            disabled={disableLocality}
                            placeHolder="Select Trade Sub Category"
                        /> */}
                           <SingleSelect1
                      options={subCategoryFilteredByCategory.map((c, i) => {
                        return {
                          value: c?.id?c?.id:0,
                          label: c?.name?c?.name:'',
                          imgUrl: "",
                        };
                      })}
                      selectedOptions={subcatDrop}
                      setSelectedOptions={handleSubcatDrop}
                      placeHolder="Select Trade Sub Category"
                      onClear={handleClearSubcat}
                      disabled = {disableLocality}
                    />
                    </div>
                    <div className="col-sm-6 col-md-4 col-lg-3">            
                        {/* <SingleSelect
                            options={[
                            defaultbrand,
                            ...BrandFilteredByCompany?.sort(function (a, b) {
                            if (a?.name < b?.name) {
                            return -1;
                            }
                            if (a?.name > b?.name) {
                            return 1;
                            }
                            return 0;
                            })?.map((s, i) => {
                            return (
                            {
                            value: s?.id ? s?.id?.toString() : '',
                            label: s?.name,
                            imgUrl: s?.logourl,
                            }
                            );
                            })
                            ]}
                            label=""
                            selectedOptions={brandsOption}
                            setSelectedOptions={handleBrandChange}
                            disabled={disableLocality}
                            placeHolder="Select Brand"
                        /> */}
                              <SingleSelect1
                options={BrandFilteredByCompany.map((c, i) => {
        return {
          value: c?.id?c?.id:0,
          label: c?.name?c?.name:'',
          imgUrl: "",
        };
      })}
      selectedOptions={brandDrop}
      setSelectedOptions={handleBrandDrop}
      placeHolder="Select Brand"
                onClear={handleClearBrand}
                disabled = {disableLocality}
              />
                    </div>
            </div>
            </div>
            </div>
            </div>

            {loader ? (
                <Loader />
            ) : (
                <>
                    <div className="row">
                    <div className="col-12">
                    <div className="card mt-3 widget_div" onMouseEnter={() => setButtonVisible(true)} onMouseLeave={() => setButtonVisible(false)}>
                    {(<button type="button" className="expandIconBtn" onClick={showExpandModalHandler} ><i><FontAwesomeIcon icon={faWindowMaximize} size="lg" title="Open in Popup"/></i></button>)}
                    <div className="card-body">
                    <div className="row">
                    <div className="col-lg-12 col-sm-12">
                    <div className="float-start">
                    <h5 className="header-title">
                    <img src={chartIcon} alt="icon"></img>
                    Growth Analysis
                    </h5>
                    </div>
                    <div className="float-end">
                    <button
                    type="button"
                    className="btn btn-primary"
                    onClick={handleApply}
                    >
                    Apply
                    </button>
                    </div>
                    </div>
                    </div>
                    <hr className="m-0 my-2 p-0 hr" />
                    <div>{"*Value in " + valueIn}</div>

                    <BarChart
                    datasets={(() => {
                    let mdata: { name: string; data: number[] }[] = [];
                    MonthlyFilteredData?.forEach((y, i) => {
                    const index = portfolioOption?.findIndex(
                    (p) =>
                    p?.id !== undefined &&
                    p?.id?.toString() === y?.portfolio?.toString()
                    );
                    const name = portfolioOption[index]?.name;
                    const selectedYears = yearDropDown?.map((f) => f)?.sort();

                    selectedYears?.forEach((s) => {
                    let data2: number[] = [];
                    let d1 = y?.record?.filter(
                    (y) => y?.transyear?.toString() === s?.toString()
                    );

                    monthsArray
                    ?.map((f) => f)
                    ?.sort()
                    ?.forEach((m, mi) => {
                    var mns = d1?.map((d) => d?.transmonth);
                    if (mns?.includes(mi + 1)) {
                    let obj = d1?.find(
                    (f) => f?.transmonth === mi + 1
                    );
                    data2?.push(
                    obj?.sales ? parseFloat(obj?.sales) : 0
                    );
                    } else data2?.push(0);
                    });
                    let isEmpty: string[] = [];
                    data2?.forEach((d2) => {
                    if (d2 !== 0) isEmpty?.push("true");
                    else isEmpty?.push("false");
                    });

                    if (data2?.length > 0 && isEmpty?.includes("true")) {
                    mdata?.push({
                    name: name + "-" + s,
                    data: data2,
                    });
                    }
                    });
                    });
                    return mdata;
                    })()}
                    labels={monthsArray}

                    colors={[
                    colors[0],
                    colors[1],
                    colors[2],
                    colors[3],
                    colors[4],
                    colors[5],
                    colors[6],
                    colors[7],
                    ]}
                    title={(() => {
                    const txt = `Growth Analysis`;
                    return txt;
                    })()}
                    subtitle={(() => {
                    let subtxt = `\u3010Company\u3011: ${pname}`;
                    if (location) {
                    subtxt += `\u3010Locality\u3011: ${location}`;
                    }
                    if (categories?.length > 0 && categories !== undefined) {
                    const categoryIndex = categoryOption?.findIndex(
                    (a) => a?.id?.toString() === categories?.toString()
                    );
                    const categoryName =
                    categoryOption[categoryIndex]?.name;
                    subtxt += ` \n\u3010TradeCategory\u3011: ${categoryName}`;
                    }
                    if (subCategories?.length > 0) {
                    const subcategoryIndex = subCategoryOption?.findIndex(
                    (a) => a?.id?.toString() === subCategories?.toString()
                    );
                    const subcatName =
                    subCategoryOption[subcategoryIndex]?.name;
                    subtxt += ` \u3010TradeSubcategory\u3011: ${subcatName}`;
                    }
                    if (brands?.length > 0) {
                    const brandIndex = filteredBrand?.findIndex(
                    (a) => a?.id?.toString() === brands?.toString()
                    );
                    const brandname = filteredBrand[brandIndex]?.name;
                    subtxt += ` \u3010Brand\u3011: ${brandname}`;
                    }
                    subtxt += ` \u3010Period\u3011: ${yearDropDown[2]} - ${yearDropDown[0]}`;
                    return subtxt;
                    })()}
                    exportfilename={"GrowthAnalysis"}
                    />
                    </div>
                    </div>
                    </div>
                    </div>
                    <div className="row">
                    <div className="col-12">
                    <div className="card mt-3">
                    <div className="card-body">
                    <div className="row mb-1">
                    <div className="col-lg-6 col-sm-12">
                    <div className="float-start">
                    <h5 className="header-title mt-1">
                    <img src={tableIcon} alt="icon"></img>
                    Growth Analysis
                    </h5>
                    </div>
                    </div>
                    <div className="col-lg-6 col-sm-12">
                    <div className="float-end hstack gap-1">
                    </div>
                    </div>
                    </div>

                    <TransactionTable
                        data={[]}
                        {...getTableData()}
                        title=""
                        downloadCSV={true}
                        downloadPDF={true}
                        fileName="Growth Analysis"
                        limit={limit}
                        setOffset={setOffset}
                        onClickCSV={handleCsvDownload}
                        onClickPDF={handlePdfDownload}
                        range={range}
                        valueInString={valueIn}
                        onPageNoChange={pag && (offset > 0||condition) ? handleApply : undefined}
                        handlePag={pag ? handlePag : undefined}
                        onOffchange={handleOff}
                        fLoader={fileLoader}
                        onSelectedRowsChange={handleSelectedRowsChange}
                    />
                    </div>
                    </div>
                    </div>
                    </div>

                    {showModal && (
                        <Modal
                        headerText={"Growth Analysis"}
                        visible={showModal}
                        onClose={() => setShowModal(false)}
                        size="xl"
                        centered
                        classname="widget-container-single" > 
                        <div className="card-body">

                        <BarChart
                        datasets={(() => {
                        let mdata: { name: string; data: number[] }[] = [];
                        MonthlyFilteredData?.forEach((y, i) => {
                        const index = portfolioOption?.findIndex(
                        (p) =>
                        p?.id !== undefined &&
                        p?.id?.toString() === y?.portfolio?.toString()
                        );
                        const name = portfolioOption[index]?.name;
                        const selectedYears = yearDropDown?.map((f) => f)?.sort();

                        selectedYears?.forEach((s) => {
                        let data2: number[] = [];
                        let d1 = y?.record?.filter(
                        (y) => y?.transyear?.toString() === s?.toString()
                        );

                        monthsArray
                        ?.map((f) => f)
                        ?.sort()
                        ?.forEach((m, mi) => {
                        var mns = d1?.map((d) => d?.transmonth);
                        if (mns?.includes(mi + 1)) {
                        let obj = d1?.find(
                        (f) => f?.transmonth === mi + 1
                        );
                        data2?.push(
                        obj?.sales ? parseFloat(obj?.sales) : 0
                        );
                        } else data2?.push(0);
                        });
                        let isEmpty: string[] = [];
                        data2?.forEach((d2) => {
                        if (d2 !== 0) isEmpty?.push("true");
                        else isEmpty?.push("false");
                        });

                        if (data2?.length > 0 && isEmpty?.includes("true")) {
                        mdata?.push({
                        name: name + "-" + s,
                        data: data2,
                        });
                        }
                        });
                        });
                        return mdata;
                        })()}
                        labels={monthsArray}

                        colors={[
                        colors[0],
                        colors[1],
                        colors[2],
                        colors[3],
                        colors[4],
                        colors[5],
                        colors[6],
                        colors[7],
                        ]}
                        title={(() => {
                        const txt = `Growth Analysis`;
                        return txt;
                        })()}
                        subtitle={(() => {
                        let subtxt = `\u3010Company\u3011: ${pname}`;
                        if (location) {
                        subtxt += `\u3010Locality\u3011: ${location}`;
                        }
                        if (categories?.length > 0 && categories !== undefined) {
                        const categoryIndex = categoryOption?.findIndex(
                        (a) => a?.id?.toString() === categories?.toString()
                        );
                        const categoryName =
                        categoryOption[categoryIndex]?.name;
                        subtxt += ` \n\u3010TradeCategory\u3011: ${categoryName}`;
                        }
                        if (subCategories?.length > 0) {
                        const subcategoryIndex = subCategoryOption?.findIndex(
                        (a) => a?.id?.toString() === subCategories?.toString()
                        );
                        const subcatName =
                        subCategoryOption[subcategoryIndex]?.name;
                        subtxt += ` \u3010TradeSubcategory\u3011: ${subcatName}`;
                        }
                        if (brands?.length > 0) {
                        const brandIndex = filteredBrand?.findIndex(
                        (a) => a?.id?.toString() === brands?.toString()
                        );
                        const brandname = filteredBrand[brandIndex]?.name;
                        subtxt += ` \u3010Brand\u3011: ${brandname}`;
                        }
                        subtxt += ` \u3010Period\u3011: ${yearDropDown[2]} - ${yearDropDown[0]}`;
                        return subtxt;
                        })()}
                        exportfilename={"GrowthAnalysis"}
                        />
                        </div>

                        </Modal>
                    )}
                </>
            )}
        </UserLayout>
    );

};
export default SalesGrowthAnalysisReport;