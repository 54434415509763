import { Dispatch } from "redux"
import api, { APIResponse, getAxiosRequestConfig } from "../../config/api"
import { GetState } from "../store"

export enum ActiveDropDown {
    Active = "active",
    InActive = "inActive",
}
export type ActiveTypes = ActiveDropDown.Active | ActiveDropDown.InActive

export interface UsersType {
    uid?: number
    loginid?: string
    name: string
    password?: string
    status: ActiveTypes | boolean | string
    email: string
    role: string
    companyId: number
    portfolioId?: string
    siteId?: string
    brandId?: string
    // portfolios?: number[]
    // sites?: number[]
    // brands?: number[]
    createdBy?: number | null,
    createdAt?: string,
    updatedAt?: string
    updatedBy?: string
}

export enum UsersActionsList {
    ADD_USERS = 'ADD_USERS',
    EDIT_USERS = 'EDIT_USERS',
    FETCH_USERS = 'FETCH_USERS',
    DELETE_USERS = 'DELETE_USERS'
}

export interface DeleteUsers {
    id: number
}

export interface AddUsersAction {
    type: UsersActionsList.ADD_USERS
    data: UsersType
}

export interface EditUsersAction {
    type: UsersActionsList.EDIT_USERS
    data: UsersType
    id?: number
}

export interface FetchUsersAction {
    type: UsersActionsList.FETCH_USERS
    data: UsersType[]

}

export interface DeleteUsersAction {
    type: UsersActionsList.DELETE_USERS
    data: DeleteUsers
}

export type UsersActions = AddUsersAction | EditUsersAction | FetchUsersAction | DeleteUsersAction



export const fetchUsers = () => {
    return async (dispatch: Dispatch, getState: GetState) => {
        // return dispatch<FetchUsersAction>({
        //     type: UsersActionsList.FETCH_USERS
        // })
        const token = getState().authUser?.['auth-token']!
        const config = getAxiosRequestConfig(token)

        return api.get<APIResponse<UsersType[]>>('user/', config).then(response => {
            if (response.status === 200 && response.data.data) {
                dispatch<FetchUsersAction>({
                    type: UsersActionsList.FETCH_USERS,
                    data: response.data.data
                })
                return Promise.resolve(response.data.message
                    ? response.data.message
                    : 'Fetch Successfull')
            } else {
                // throw { response }
                throw new Error("Unable to fetch")
            }
        }).catch(error => {
            return Promise.reject(error.response
                ? error.response.data.message
                    ? error.response.data.message
                    : 'Unable to fetch'
                : 'Unable to fetch')
        })
    }
}
