import { nanoid } from "@reduxjs/toolkit";
import React, { ChangeEventHandler, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Option from "../components/Option";
import { getOnlyUnique } from "../components/commonWidget";
import tableIcon from "../components/custom/assets/images/dashboard/table_icon.svg";
import { FormInput, MultiSelect } from "../components/custom/components";
import FormInput2 from "../components/custom/components/FormInput2";
import TransactionTable, {
  Tbody,
  Thead,
} from "../components/custom/components/TransactionTable";
import UserLayout from "../components/custom/layouts/UserLayout";
import { ToastTypes, useToast } from "../components/toast/ToastProvider";
import { defaultPortfolio, DropDownOption1, PAGE_SIZE } from "../config";
import api, { APIResponse, getAxiosRequestConfig } from "../config/api";
import {
  CSVdownload,
  getYesterday,
  handleDownloadPDF,
  today,
} from "../config/functions";
import { AuthUserType } from "../redux/actions/authUserActions";
import { BrandType } from "../redux/actions/brandActions";
import { BrandCompanyType } from "../redux/actions/brandCompanyActions";
import { PortfolioType } from "../redux/actions/portfolioActions";
import {
  OptionsTypes,
  addToSelectedOption,
} from "../redux/actions/selectedOptionsActions";
import { SiteType } from "../redux/actions/siteActions";
import { TradeCategoryType } from "../redux/actions/tradeCategoryActions";
import { TradeSubCategoryType } from "../redux/actions/tradeSubCategoryActions";
import { StoreState } from "../redux/store";

import { CatSubCatCompanyWithBrandRelationDataType } from "./TransactionBillWiseReport";
// import Loader from "../components/custom/components/Loader";
import { areaType } from "../redux/actions/areaActions";
import { outletType } from "../redux/actions/outletActions";
import { useLocation } from "react-router-dom";
import { UserLog } from "../config/common";
import SingleSelect from "../components/custom/components/SingleSelect";
import SingleSelect1 from "../components/custom/components/SingleSelect1";


export interface DropDownOption {
  value: number;
  label: string;
  imgUrl: string;
}
export interface BasketSizeReport {
  tradecatid: number;
  companyid: number;
  brandid: number;
  buildingid: number;
  floorid: number;
  billamount: string;
  returns: string;
  itemqty: string;
  netamount: string;
  basketsize: string;
  avgticketsize: string;
  rsvalue?: string;
  rspercent?: string;
}

export interface BasketSizeReportType {
  portfolio: number;
  count?: number;
  record: BasketSizeReport[];
}

const TransactionBasketSizeReport: React.FC = () => {
  const toast = useToast();
  const dispatch = useDispatch();
  const pageName = useLocation()
  const authUser = useSelector<StoreState, AuthUserType | null>(
    (state) => state?.authUser
  );
  const authUserToken = useSelector<StoreState, AuthUserType | null>(
    (state) => state?.authUser
  )?.["auth-token"]!;
  const config = getAxiosRequestConfig(authUserToken);

  const authUserSiteId = authUser?.siteId;

  const portfolioOption = useSelector<StoreState, PortfolioType[]>(
    (state) => state?.portfolio
  );
  const siteOption = useSelector<StoreState, SiteType[]>((state) => state?.site);
  const brandOption = useSelector<StoreState, BrandType[]>(
    (state) => state?.brand
  );
  const brandCompanyOption = useSelector<StoreState, BrandCompanyType[]>(
    (state) => state?.brandCompany
  );

  const selectedOption = useSelector<StoreState, OptionsTypes>(
    (state) => state?.selectedOption
  );
  const outletList = useSelector<StoreState, outletType[]>(
    (state) => state?.outlet
  );
  
  const localityOption = useSelector<StoreState, areaType[]>(
    (state) => state?.locality
  );
  const categoryOption = useSelector<StoreState, TradeCategoryType[]>(
    (state) => state?.tradeCategory
  );
  const subCategoryOption = useSelector<StoreState, TradeSubCategoryType[]>(
    (state) => state?.tradeSubCategory
  );

  const filteredBrandCompany = brandCompanyOption?.filter(
    (a) => a?.retailid !== null
  );

  const filteredBrand = brandOption?.filter((a) => {
    return filteredBrandCompany?.some(
      (b) =>
        b?.id?.toString() === a?.companyid?.toString() && b?.retailid !== null
    );
  });

  const port =
    authUser?.portfolioId !== undefined && authUser?.portfolioId !== null
      ? authUser?.portfolioId?.split(",")
      : [];

  let portfolioData = port
    ?.map((pe) => {
      return portfolioOption?.filter(
        (f) => f?.id?.toString() === pe?.toString()
      );
    })
    ?.flat(1);

  const st =
    authUser?.siteId !== undefined && authUser?.siteId !== null
      ? authUser?.siteId?.split(",")
      : [];

  let siteData = st
    ?.map((pe) => {
      return siteOption?.filter((f) => f?.id?.toString() === pe?.toString());
    })
    ?.flat(1);

  const br =
    authUser?.brandId !== undefined && authUser?.brandId !== null
      ? authUser?.brandId?.split(",")
      : [];

  let brandData = br
    ?.map((b) => {
      return filteredBrand?.filter((f) => f?.id?.toString() === b?.toString());
    })
    ?.flat(1);

  const [maxFromDate, setMaxFromDate] = useState(getYesterday());
  const [fromDate, setFromDate] = useState<string>(getYesterday());
  const [toDate, setToDate] = useState<string>(today());

  const [fileLoader, setFileLoader] = useState<boolean>(false);

  const [portfolios, setPortfolios] = useState<string>("");
  const [sites, setSites] = useState<string>("");
  const [brands, setBrands] = useState<DropDownOption[]>([]);

  const [categories, setCategories] = useState<DropDownOption[]>([]);
  const [subCategories, setSubCategories] = useState<DropDownOption[]>([]);

  const [disableBrand, setDisableBrand] = useState<boolean>(false);
  const [disableFloor, setDisableFloor] = useState<boolean>(false);
  const [disableSubCategory, setDisableSubCategory] = useState<boolean>(false);

  //loader
  const [loader, setLoader] = useState<boolean>(false);
  const [pag, setPag] = useState<boolean>(false);

  const limit = PAGE_SIZE;
  const [offset, setOffset] = useState<number>(0);
  const [range, setRange] = useState<number[]>([]);
  const [length, setLength] = useState<number>(50);
  const [valueIn, setValueIn] = useState<string>("");

  const [
    catSubCatCompanyWithBrandRelationData,
    setCatSubCatCompanyWithBrandRelationData,
  ] = useState<CatSubCatCompanyWithBrandRelationDataType[]>([]);

  const [basketWiseData, setBasketWiseData] = useState<BasketSizeReportType[]>(
    []
  );

  const [selectedRows, setSelectedRows] = useState<string[]>([]);
  const [locations, setLocations] = useState<DropDownOption[]>([]);
  const [disableLocality, setDisableLocality] = useState<boolean>(false);
  const [condition,setCondition] = useState<boolean>(false)
  const [portfoliosOption, setPortfoliosOption] = useState<DropDownOption1>(defaultPortfolio);

  const [portfolioDrop, setPortfolioDrop] = useState<DropDownOption[]>([]);

  
  const handleSelectedRowsChange = (selectedRows: string[]) => {
    setSelectedRows(selectedRows);
  };
  const handleOff = (conditionVal: boolean) => {
    setCondition(conditionVal);
  };

  //hello
  const locality = useMemo<string[]>(() => {
    return locations?.map((p) => {
      return p?.label;
    });
  }, [locations]);
  
  const handleFromDate: ChangeEventHandler<HTMLInputElement> = (e) => {
    setFromDate(e?.target?.value);
    setPag(false);
  };

  const handleToDate: ChangeEventHandler<HTMLInputElement> = (e) => {
    setToDate(e?.target?.value);
    setPag(false);
  };

  const handlePortfolioDrop = (selected: any) => {
    if(fileLoader===false){
    if(selected.length>0){
      const selectedPortfolio = selected as DropDownOption[];
      if(selectedPortfolio[0].value!==null&&selectedPortfolio[0].value!==0){
        setPortfolioDrop(selectedPortfolio)
        setPortfolios(selectedPortfolio[0]?.value?.toString())
        const data = { ...selectedOption, portfolio: selectedPortfolio[0]?.value?.toString() };
        dispatch(addToSelectedOption(data));
        setOffset(0);
        setPag(false);
      }
    }
  }
  };

  const handleClearPortfolio = () =>{
    let pid =portfolioData[0] && portfolioData[0]?.id? portfolioData[0].id?.toString(): "";
    setPortfolios(selectedOption.portfolio ? selectedOption.portfolio : pid);
    const b = portfolioOption.filter((a)=>a?.id?.toString()===pid?.toString())
    if (b?.length > 0 && b[0]?.id !== undefined) {
      const val: DropDownOption = {
        value: b[0]?.id, 
        label: b[0]?.name,
        imgUrl: b[0]?.logourl, 
      };
      setPortfolioDrop([val]);

      if(fileLoader===false){
      setLocations([]);
      const data = { ...selectedOption, portfolio: b[0]?.id?.toString(),location:'',locations:[]};
      dispatch(addToSelectedOption(data));
      setOffset(0);
      setPag(false);
    }
  }
  }
  const handleLocationsChange = (value: DropDownOption[]) => {
    if (fileLoader === false) {
      setLocations(value);
      const data = {
        ...selectedOption,
        locations: value,
      };
      dispatch(addToSelectedOption(data));
      setOffset(0);
      setPag(false);
    }
  };


  const handleCategoriesChange = (value: DropDownOption[]) => {
    if (fileLoader === false) {
      setCategories(value);
      const data = {
        ...selectedOption,
        categories: value,
        subCategories: [],
        companies: [],
        brands: [],
      };
      dispatch(addToSelectedOption(data));
      setOffset(0);
      setPag(false);
    }
  };

  const handleSubCategoriesChange = (value: DropDownOption[]) => {
    if (fileLoader === false) {
      setSubCategories(value);
      setBrands([]);
      const data = {
        ...selectedOption,
        subCategories: value,
        companies: [],
        brands: [],
      };
      dispatch(addToSelectedOption(data));
      setOffset(0);
      setPag(false);
    }
  };

  const handleBrandsChange = (value: DropDownOption[]) => {
    if (fileLoader === false) {
      setBrands(value);
      const data = { ...selectedOption, brands: value };
      dispatch(addToSelectedOption(data));
      setOffset(0);
      setPag(false);
    }
  };

  const portfolio = useMemo<number[]>(() => {
    return portfolios !== "" ? [parseInt(portfolios)] : [];
  }, [portfolios]);

  const site = useMemo<string[]>(() => {
    if (authUserSiteId) {
      return authUserSiteId?.split(",")?.map((s) => {
        return s?.toString();
      });
    } else {
      return [];
    }
  }, [authUserSiteId]);

  const brand = useMemo<number[]>(() => {
    return brands?.map((p) => {
      return p?.value;
    });
  }, [brands]);

  const category = useMemo<number[]>(() => {
    return categories?.map((p) => {
      return p?.value;
    });
  }, [categories]);

  const subCategory = useMemo<number[]>(() => {
    return subCategories?.map((p) => {
      return p?.value;
    });
  }, [subCategories]);

  const brandFilteredByCompany = useMemo<BrandType[]>(() => {
    // setBrands([])
    if (subCategories?.length > 0) {
      let brands: BrandType[] = [];
      catSubCatCompanyWithBrandRelationData?.forEach((s) => {
        subCategories?.forEach((sb) => {
          let obj = s?.record?.find((r) =>
            r?.tradesubcatids?.split(",")?.includes(sb?.value?.toString())
          );
          obj?.brandids?.split(",")?.forEach((m) => {
            let bc = brandData?.find((f) => f?.id?.toString() === m);
            brands?.push(bc!);
          });
        });
      });

      return brands?.filter(getOnlyUnique);
    } else if (categories?.length > 0) {
      let brands: BrandType[] = [];
      catSubCatCompanyWithBrandRelationData?.forEach((s) => {
        categories?.forEach((cat) => {
          let obj = s?.record?.find(
            (r) => r?.tradecatid?.toString() === cat?.value?.toString()
          );
          obj?.brandids?.split(",")?.forEach((m) => {
            let bc = brandData?.find((f) => f?.id?.toString() === m);
            brands?.push(bc!);
          });
        });
      });
      return brands;
    } else return brandData;
  }, [
    brandData,
    catSubCatCompanyWithBrandRelationData,
    categories,
    subCategories,
  ]);

  const subCategoryFilteredByCategory = useMemo<TradeSubCategoryType[]>(() => {
    if (categories?.length === 1) {
      return subCategoryOption?.filter((sc) => {
        return sc?.catid === categories[0]?.value;
      });
    } else return subCategoryOption;
  }, [categories, subCategoryOption]);


  const portFilter = portfolioOption?.filter((a)=>a?.id?.toString()===portfolios?.toString())

 
  let localitiesName = ''
  if(locations?.length>0){
    let locNameArr: string[] = [];
    locations?.map((s) => {
      locNameArr?.push(s?.label);
    });
    localitiesName = locNameArr?.join(", ");
  }

  

  let categoriesName =  '' 
  if (categories?.length > 0) {
    let catNameArr: string[] = [];
    categories?.map((s) => {
      catNameArr?.push(s?.label);
    });
    categoriesName = catNameArr?.join(", ");
  }

  let subcategoriesName =  '' 
  if (subCategories?.length > 0) {
    let subcatNameArr: string[] = [];
    subCategories?.map((s) => {
      subcatNameArr?.push(s?.label);
    });
    subcategoriesName = subcatNameArr?.join(", ");
  }


  let brandsName = ''
  if(brands?.length>0){
    let brandNameArr: string[] = [];
    brands?.map((s) => {
      brandNameArr?.push(s?.label);
    });
    brandsName = brandNameArr?.join(", ");
  }


  const combinedValues = [
    ...portFilter?.map(a => `Portfolio: ${a?.name}`),           
    categoriesName !== '' ? `Trade Category: ${categoriesName}` : '' , 
    subcategoriesName !== '' ? `Trade Sub Category: ${subcategoriesName}` : '' , 
    localitiesName!== '' ? `Locality: ${localitiesName}` : '' , 
    brandsName !== '' ? `Brand: ${brandsName}` : '' ,  
    `Period: ${fromDate?.toString()} - ${toDate?.toString()}`
  ]
  ?.filter(name => name && name?.trim() !== '') 
  ?.join(', ');  

  const tHead: Thead[] = [
    {
      id: "Trade Category",
      children: "Trade Category",
      text: "Trade Category",
    },
    { id: "Brand Company", children: "Brand Company", text: "Brand Company" },
    { id: "Brand", children: "Brand", text: "Brand" },
    { id: "Floor", children: "Floor", text: "Floor" },
    { id: "Bill Amount", children: "Bill Amount", text: "Bill Amount" },
    { id: "Returns", children: "Returns", text: "Returns" },
    { id: "Net Amount", children: "Net Amount", text: "Net Amount" },
    {
      id: "Quantity Of Items",
      children: "Quantity Of Items",
      text: "Quantity Of Items",
    },
    {
      id: "Basket Size(ABS)",
      children: "Basket Size(ABS)",
      text: "Basket Size(ABS)",
    },
    {
      id: "Average Ticket Size(ATS)",
      children: "Average Ticket Size(ATS)",
      text: "Average Ticket Size(ATS)",
    },
  ];
  if(!selectedOption?.isOutletUser){
    tHead?.push(
    { id: "Revenue Share(%)", children: "Revenue Share(%)", text: "Revenue Share(%)" },
    { id: "Revenue", children: "Revenue", text: "Revenue" },
    )
  }

  const fetchDataForDownload = async () => {
    let error = false;

    if (portfolios === "") {
      toast("Select Portfolio", ToastTypes?.ERROR);
      error = true;
    } else if (brands?.length <= 0) {
      toast("Select Brand", ToastTypes?.ERROR);
      error = true;
    } else if (fromDate === "") {
      toast("Select fromDate", ToastTypes?.ERROR);
      error = true;
    } else if (toDate === "") {
      toast("Select toDate", ToastTypes?.ERROR);
      error = true;
    }
    // const [building, floor] = location?.split('-')
    var getData = {
      portfolio: portfolio,
      site: site,
      locality: locality,
      tradeCategory: category,
      tradeSubCategory: subCategory,
      brand: brand,
      fromDate: fromDate,
      toDate: toDate,
      authUserRole: authUser?.roleId ? authUser?.roleId : "",
    };

    if (error === false && getData !== undefined) {
      const response = await api.post<
        APIResponse<{ records: BasketSizeReportType[] }>
      >(`kpi/report/basketSize`, getData, config);

      if (response?.status === 200 && response?.data?.data) {
        return response?.data?.data?.records;
      }
    }

    return [];
  };

  const getDownloadData = (Data: BasketSizeReportType[]) => {
    var tableHead: Thead[] = tHead;
    var tableBody: Tbody[][] = [];

    const tbody = Data;

    tbody?.forEach((item, i) => {
      item?.record?.forEach((item) => {
        const catObj = categoryOption?.find(
          (s) => s?.id?.toString() === item?.tradecatid?.toString()
        );
        const companyObj = filteredBrandCompany?.find(
          (s) => s?.id?.toString() === item?.companyid?.toString()
        );
        const brandObj = filteredBrand?.find(
          (f) => f?.id?.toString() === item?.brandid?.toString()
        );
        var tBody: Tbody[] = [];
        tBody?.push(
          {
            id: nanoid(),
            theadId: "Trade Category",
            children: catObj?.name,
            text: catObj?.name?.toString(),
          },
          {
            id: nanoid(),
            theadId: "Brand Company",
            children: companyObj?.name,
            text: companyObj?.name,
          },
          {
            id: nanoid(),
            theadId: "Brand",
            children: brandObj?.name,
            text: brandObj?.name?.toString(),
          },
          {
            id: nanoid(),
            theadId: "Floor",
            children: "",//floorObj?.floorname + "|" + buildingObj?.buildingname,
            text: ''//floorObj?.floorname + "|" + buildingObj?.buildingname,
          },
          {
            id: nanoid(),
            theadId: "Bill Amount",
            children: item?.billamount,
            text: item?.billamount,
          },
          {
            id: nanoid(),
            theadId: "Returns",
            children: item?.returns,
            text: item?.returns?.toString(),
          },
          {
            id: nanoid(),
            theadId: "Net Amount",
            children: item?.netamount,
            text: item?.netamount?.toString(),
          },
          {
            id: nanoid(),
            theadId: "Quantity Of Items",
            children: item?.itemqty,
            text: item?.itemqty?.toString(),
          },
          {
            id: nanoid(),
            theadId: "Basket Size(ABS)",
            children: item?.basketsize,
            text: item?.basketsize?.toString(),
          },
          {
            id: nanoid(),
            theadId: "Average Ticket Size(ATS)",
            children: item?.avgticketsize,
            text: item?.avgticketsize?.toString(),
          }
        );
        if(!selectedOption?.isOutletUser){
          tBody?.push(
            {
              id: nanoid(),
              theadId: "Revenue Share(%)",
              children: item?.rspercent,
              text: item?.rspercent,
            },
            {
              id: nanoid(),
              theadId: "Revenue",
              children: item?.rsvalue,
              text: item?.rsvalue,
            },
          );
        }
        tableBody?.push(tBody);
      });
    });

    return { tbody: tableBody, thead: tableHead };
  };

  const handleCsvDownload = async () => {
    setFileLoader(true);
    const csvData = await fetchDataForDownload();

    if (
      csvData[0]?.record?.length !== 0 &&
      csvData[0]?.record?.length !== undefined
    ) {
      const d = { ...getDownloadData(csvData) };
      const filteredHead = d?.thead
        ?.map((a) => {
          const foundObject = selectedRows
            ?.map((b) => {
              if (a?.text?.toString() === b?.toString()) {
                return a;
              }
            })
            ?.find((obj) => obj !== undefined);

          if (foundObject !== undefined) {
            return foundObject;
          }
        })
        ?.filter((obj) => obj !== undefined);

      const csvHead = filteredHead;

      const csvBody = d?.tbody;

      const head = [csvHead?.map((h) => h?.text || "") || []];
      const body =
        csvBody?.map((tr, i) => {
          return (
            csvHead?.map((th, j) => {
              const col = tr?.find((r) => r?.theadId === th?.id);
              return col?.text || "";
            }) || []
          );
        }) || [];

      const rows = [...head, ...body];
      const props = {
        rows: rows,
        fileName: "Basket Size Report",
      };
      CSVdownload(props);
      setFileLoader(false);
      if(authUser){
        const logProps1 = {
          action: 'Csv',
          event: `${pageName?.pathname?.replace("\\", " ")} page | Filters - ${combinedValues} | Csv Clicked | Export Status: Success | Data Status: Success`
        };
        UserLog(logProps1)
      }
    } else {
      setFileLoader(false);
      if(authUser){
        const logProps1 = {
          action: 'Csv',
          event: `${pageName?.pathname?.replace("\\", " ")} page | Filters - ${combinedValues} | Csv Clicked | Export Status: Success | Data Status: Empty`
        };
        UserLog(logProps1)
      }
    }
    setFileLoader(false);
  };

  const handlePdfDownload = async () => {
    setFileLoader(true);
    const pdfData = await fetchDataForDownload();
    if (
      pdfData[0]?.record?.length !== 0 &&
      pdfData[0]?.record?.length !== undefined
    ) {
      const d = { ...getDownloadData(pdfData) };
      const filteredHead = d?.thead
        ?.map((a) => {
          const foundObject = selectedRows
            ?.map((b) => {
              if (a?.text?.toString() === b?.toString()) {
                return a;
              }
            })
            ?.find((obj) => obj !== undefined);

          if (foundObject !== undefined) {
            return foundObject;
          }
        })
        ?.filter((obj) => obj !== undefined);
      const thead = filteredHead;
      const tbody = d?.tbody;

      const head = [thead?.map((h) => h?.text || "") || []];
      const body =
        tbody?.map((tr, i) => {
          return (
            thead?.map((th, j) => {
              const col = tr?.find((r) => r?.theadId === th?.id);
              return col?.text || "";
            }) || []
          );
        }) || [];

      const props = {
        head: head,
        body: body,
        fileName: "Basket Size Report",
        title: "Basket Size Report",
      };

      handleDownloadPDF(props);
      setFileLoader(false);
      if(authUser){
        const logProps1 = {
          action: 'Pdf',
          event: `${pageName?.pathname?.replace("\\", " ")} page | Filters - ${combinedValues} | Pdf Clicked | Export Status: Success | Data Status: Success`
        };
        UserLog(logProps1)
      }
    } else {
      setFileLoader(false);
      if(authUser){
        const logProps1 = {
          action: 'Pdf',
          event: `${pageName?.pathname?.replace("\\", " ")} page | Filters - ${combinedValues} | Pdf Clicked | Export Status: Success | Data Status: Empty`
        };
        UserLog(logProps1)
      }
    }
    setFileLoader(false);
  };

  const getTableData = () => {
    var tableHead: Thead[] = tHead;
    var tableBody: Tbody[][] = [];

    const tbody = basketWiseData;

    tbody?.forEach((item, i) => {
      item?.record?.forEach((item) => {
        const catObj = categoryOption?.find(
          (s) => s?.id?.toString() === item?.tradecatid?.toString()
        );
        const companyObj = filteredBrandCompany?.find(
          (s) => s?.id?.toString() === item?.companyid?.toString()
        );
        const brandObj = filteredBrand?.find(
          (f) => f?.id?.toString() === item?.brandid?.toString()
        );
        var tBody: Tbody[] = [];
        tBody?.push(
          {
            id: nanoid(),
            theadId: "Trade Category",
            children: catObj?.name,
            text: catObj?.name?.toString(),
          },
          {
            id: nanoid(),
            theadId: "Brand Company",
            children: companyObj?.name,
            text: companyObj?.name,
          },
          {
            id: nanoid(),
            theadId: "Brand",
            children: brandObj?.name,
            text: brandObj?.name?.toString(),
          },
          {
            id: nanoid(),
            theadId: "Floor",
            children: "",
            text: "",
          },
          {
            id: nanoid(),
            theadId: "Bill Amount",
            children: item?.billamount,
            text: item?.billamount,
          },
          {
            id: nanoid(),
            theadId: "Returns",
            children: item?.returns,
            text: item?.returns?.toString(),
          },
          {
            id: nanoid(),
            theadId: "Net Amount",
            children: item?.netamount,
            text: item?.netamount?.toString(),
          },
          {
            id: nanoid(),
            theadId: "Quantity Of Items",
            children: item?.itemqty,
            text: item?.itemqty?.toString(),
          },
          {
            id: nanoid(),
            theadId: "Basket Size(ABS)",
            children: item?.basketsize,
            text: item?.basketsize?.toString(),
          },
          {
            id: nanoid(),
            theadId: "Average Ticket Size(ATS)",
            children: item?.avgticketsize,
            text: item?.avgticketsize?.toString(),
          }
        );
        if(!selectedOption?.isOutletUser){
          tBody?.push(
            {
              id: nanoid(),
              theadId: "Revenue Share(%)",
              children: item?.rspercent,
              text: item?.rspercent,
            },
            {
              id: nanoid(),
              theadId: "Revenue",
              children: item?.rsvalue,
              text: item?.rsvalue,
            },
          );
        }
        tableBody?.push(tBody);
      });
    });

    return { tbody: tableBody, thead: tableHead };
  };
  useEffect(()=>{    
    if(selectedOption?.isOutletUser){
      setDisableLocality(true);
    }else{
      setDisableLocality(false);
    }
  },[authUser, outletList,selectedOption]);
  useEffect(() => {
    const header = tHead
      ?.map((a) => a?.text)
      ?.filter((id): id is string => typeof id === "string" && id !== undefined);
    setSelectedRows(header);
  }, []);

  const handleApply = () => {
    let error = false;
    if (portfolios === "") {
      toast("Select Portfolio", ToastTypes?.ERROR);
      error = true;
    } else if (brands?.length <= 0) {
      toast("Select Brand", ToastTypes?.ERROR);
      error = true;
    } else if (fromDate === "") {
      toast("Select fromDate", ToastTypes?.ERROR);
      error = true;
    } else if (toDate === "") {
      toast("Select toDate", ToastTypes?.ERROR);
      error = true;
    }
    // const [building, floor] = location?.split('-')
    var getData = {
      portfolio: portfolio,
      site: site,
      locality: locality,
      tradeCategory: category,
      tradeSubCategory: subCategory,
      brand: brand,
      fromDate: fromDate,
      toDate: toDate,
      limit: limit?.toString(),
      offset: offset?.toString(),
      authUserRole: authUser?.roleId ? authUser?.roleId : "",
    };

    if (
      error === false &&
      getData !== undefined &&
      getData?.limit !== "" &&
      getData?.offset !== ""
    ) {
      if (pag === false) {
        setLoader(true);
      }
      api
        .post<APIResponse<{ records: BasketSizeReportType[] }>>(
          `kpi/report/basketSize`,
          getData,
          config
        )
        .then((response) => {
          if (response?.status === 200 && response?.data?.data) {
            const result = response?.data?.data?.records;
            setLoader(false);
            setPag(true);
            setBasketWiseData(result);
            if(authUser){
              const logProps1 = {
                action: 'Apply',
                event: `${pageName?.pathname?.replace("\\", " ")} page | Filters - ${combinedValues} | Apply Clicked | Data Status: ${result?.[0]?.count && result[0]?.count > 0 ? 'Success' : 'Empty'}`
              };
              UserLog(logProps1)
            }
          }
        })
        .catch((error) => {});
    } else {
      setBasketWiseData([]);
      setLoader(false);
      setPag(true);
      setCondition(false)
    }
  };

  const handlePag = () => {
    setPag(true);
  };

  useEffect(() => {
    setOffset(0);
  }, [fromDate, toDate]);

  useEffect(() => {
    if (portfolios !== "" && brands?.length !== 0 && fileLoader === false)
      handleApply();
  }, [portfolios]);

  useEffect(()=>{
    let pid =portfolioData[0] && portfolioData[0]?.id? portfolioData[0].id?.toString(): "";
    setPortfolios(selectedOption.portfolio ? selectedOption.portfolio : pid);
    const b = portfolioOption.filter((a)=>a?.id?.toString()===pid?.toString())
    if (b?.length > 0 && b[0]?.id !== undefined) {
      const val: DropDownOption = {
        value: b[0]?.id, 
        label: b[0]?.name,
        imgUrl: b[0]?.logourl, 
      };
      setPortfolioDrop([val]);
    }
},[portfolioOption,selectedOption])


  useEffect(() => {
    let sid = siteData[0] && siteData[0]?.id ? siteData[0]?.id?.toString() : "";

    setSites(selectedOption?.site ? selectedOption?.site : sid);
  }, [siteData, selectedOption]);

  useEffect(() => {
    if (selectedOption?.categories && selectedOption?.categories?.length !== 0)
      setCategories(selectedOption?.categories);
  }, [selectedOption]);

  useEffect(() => {
    if (selectedOption?.locations && selectedOption?.locations?.length !== 0)
      setLocations(selectedOption?.locations);
  }, [selectedOption]);

  useEffect(() => {
    if (selectedOption?.subCategories)
      setSubCategories(selectedOption?.subCategories);
  }, [selectedOption]);

  useEffect(() => {
    if (selectedOption?.brands) setBrands(selectedOption?.brands);
  }, [selectedOption]);

  useEffect(() => {
    var range = [];
    var num = Math?.ceil(length / limit);
    // let i = 1;
    for (let i = 1; i <= num; i++) {
      range?.push(i);
    }
    setRange(range);
  }, [length, limit]);

  useEffect(() => {
    if (basketWiseData?.length > 0)
      setLength(basketWiseData[0]?.count ? basketWiseData[0]?.count : 0);
  }, [basketWiseData]);

  useEffect(() => {
    const d = new Date(toDate);
    d?.setDate(d?.getDate() - 1);
    const date = d?.getDate()?.toString()?.padStart(2, "0");
    const month = (d?.getMonth() + 1)?.toString()?.padStart(2, "0");
    const year = d?.getFullYear();
    // return [year, month, date]?.join('-')
    setMaxFromDate([year, month, date]?.join("-"));
  }, [toDate]);

  useEffect(() => {
    if (categories?.length > 1) {
      setDisableSubCategory(true);
      setSubCategories([]);
    } else {
      setDisableSubCategory(false);
    }
  }, [categories]);

  useEffect(() => {
    if (sites !== ""&&selectedOption.isOutletUser===true) {
      let siteCurrencyObject = siteOption?.find(
        (s) => s?.id?.toString() === sites?.toString()
      );
      setValueIn(
        siteCurrencyObject?.currencycode +
          " | " +
          siteCurrencyObject?.valuecaption
      );
    } 
    else if(portfolios !== ""&&selectedOption.isOutletUser===false){
      let portfolioCurrencyObject = portfolioOption?.find(
        (s) => s?.id?.toString() === portfolios?.toString()
      );
      setValueIn(
        portfolioCurrencyObject?.reportingcurrency +
          " | " +
          portfolioCurrencyObject?.valuecaption
      );
    }
    else setValueIn("");
  }, [sites, siteOption,selectedOption]);
  
  useEffect(() => {
    var getData = {
      portfolio: portfolio,
    };

    if (portfolio?.length > 0 && getData !== undefined) {
      api
        .post<
          APIResponse<{ records: CatSubCatCompanyWithBrandRelationDataType[] }>
        >(`filter/catSubcatCompanyRelationWithBrand`, getData, config)
        .then((response) => {
          if (response?.status === 200 && response?.data?.data) {
            const result = response?.data?.data?.records;
            setCatSubCatCompanyWithBrandRelationData(result);
          }
        })
        .catch((error) => {});
    } else {
      setCatSubCatCompanyWithBrandRelationData([]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [portfolio]);

  useEffect(()=>{
    if(authUser){
    const logProps1 = {
      action:`Visit`,
      event: `${pageName?.pathname?.replace("\\", " ")} page | Visited `
    };
    UserLog(logProps1)
  }
  },[])

  return (
    <UserLayout>
      <div className="card background-transparent p-1">
        <div className="row">
          <div
            className="col-lg-4 wow fadeInLeft ps-0"
            // style="visibility: visible; animation-name: fadeInLeft;"
          >
            <h4 className="b-title">Basket Size Report</h4>
          </div>

          <div className="col-lg-8 wow fadeInRight">
            <div className="row">
              <div className="col-sm-6 col-lg-3">
                {/* <FormInput2
                  name="Portfolio"
                  label=""
                  labelClassName="required"
                  value={portfolios}
                  onChange={handlePortfolioChange}
                  placeholder="Select"
                  containerClass="mb-1"
                  type="select"
                >
                  <option value="">Select</option>
                  {portfolioData?.map((p, i) => {
                    return (
                      <Option value={p?.id} key={i}>
                        {p?.name}
                      </Option>
                    );
                  })}
                </FormInput2> */}
                 {/* <SingleSelect
                  options={[
                    defaultPortfolio,
                    ...portfolioData?.sort(function (a, b) {
                      if (a?.name < b?.name) {
                        return -1;
                      }
                      if (a?.name > b?.name) {
                        return 1;
                      }
                      return 0;
                    })?.map((s, i) => {
                      return (
                        {
                          value: s?.id ? s?.id?.toString() : '',
                          label: s?.name,
                          imgUrl: s?.logourl,
                        }
                      );
                    })
                  ]}
                  label=""
                  selectedOptions={portfoliosOption}
                  setSelectedOptions={handlePortfolioChange}
                  // disabled={disableSite}
                  disabled={disableLocality}
                  placeHolder="Select Portfolio"
                  // filterText=""
                /> */}
                   <SingleSelect1
                      options={portfolioData.map((c, i) => {
                        return {
                          value: c?.id?c?.id:0,
                          label: c?.name?c?.name:'',
                          imgUrl: "",
                        };
                      })}
                      selectedOptions={portfolioDrop}
                      setSelectedOptions={handlePortfolioDrop}
                      placeHolder="Select Portfolio"
                      onClear={handleClearPortfolio}
                      disabled = {disableLocality}
                    />
              </div>
              

              <div className="col-sm-6 col-lg-3">
              <MultiSelect
                  options={localityOption?.map((sc) => {
                    return {
                      value: sc?.id ? sc?.id : 0,
                      label: sc?.area?.toString(),
                      imgUrl: "",
                    };
                  })}
                  label=""
                  selectedOptions={locations}
                  setSelectedOptions={handleLocationsChange}
                  disabled={disableLocality}
                  placeHolder="All Locality"
                />
              </div>

              
            </div>

            <div className="row mt-1">
              <div className="col-sm-6 col-lg-3">
                <MultiSelect
                  options={categoryOption?.map((c) => {
                    return {
                      value: c?.id ? c?.id : 0,
                      label: c?.name,
                      imgUrl: "",
                    };
                  })}
                  label=""
                  selectedOptions={categories}
                  setSelectedOptions={handleCategoriesChange}
                  disabled={disableLocality}
                  placeHolder="All Trade Categories"
                />
              </div>

              <div className="col-sm-6 col-lg-3">
                <MultiSelect
                  options={subCategoryFilteredByCategory?.map((sc) => {
                    return {
                      value: sc?.id ? sc?.id : 0,
                      label: sc?.name,
                      imgUrl: "",
                    };
                  })}
                  label=""
                  selectedOptions={subCategories}
                  setSelectedOptions={handleSubCategoriesChange}
                  disabled={disableLocality}
                  placeHolder="All Trade Sub Categories"
                />
              </div>


              <div className="col-sm-6 col-lg-3">
                <MultiSelect
                  options={brandFilteredByCompany
                    ?.sort(function (a, b) {
                      if (a?.name < b?.name) {
                        return -1;
                      }
                      if (a?.name > b?.name) {
                        return 1;
                      }
                      return 0;
                    })
                    ?.map((b) => {
                      return {
                        value: b?.id ? b?.id : 0,
                        label: b?.name,
                        imgUrl: b?.logourl,
                      };
                    })}
                  label=""
                  selectedOptions={brands}
                  setSelectedOptions={handleBrandsChange}
                  disabled={disableLocality}
                  placeHolder="All Brands"
                  filterText=""
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="row">
        <div className="col-12">
          <div className="card mt-3">
            <div className="card-body">
              <div className="row mb-1">
                <div className="col-lg-6 col-sm-12">
                  <div className="float-start">
                    <h5 className="header-title mt-1">
                      <img src={tableIcon} alt="icon"></img>Basket Size Report
                    </h5>
                  </div>
                </div>
                <div className="col-lg-6 col-sm-12">
                  <div className="float-end hstack gap-1">
                    <div
                      className="col-sm-6 col-md-6 col-lg-6"
                      style={{ width: "133px" }}
                    >
                      <FormInput
                        name="From Date"
                        label=""
                        labelClassName="required"
                        value={fromDate}
                        max={maxFromDate}
                        onChange={handleFromDate}
                        type="date"
                        placeholder="From Date"
                      />
                    </div>
                    <div
                      className="col-sm-6 col-md-6 col-lg-6"
                      style={{ width: "133px" }}
                    >
                      <FormInput
                        name="To Date"
                        label=""
                        labelClassName="required"
                        value={toDate}
                        max={today()}
                        onChange={handleToDate}
                        type="date"
                        placeholder="To Date"
                      />
                    </div>
                    <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6">
                      <button
                        type="button"
                        className="btn btn-primary"
                        onClick={handleApply}
                      >
                        Apply
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <TransactionTable
                data={[]}
                {...getTableData()}
                title=""
                downloadCSV={true}
                downloadPDF={true}
                fileName="Basket Size Report"
                limit={limit}
                setOffset={setOffset}
                onClickCSV={handleCsvDownload}
                onClickPDF={handlePdfDownload}
                valueInString={valueIn}
                range={range}
                onPageNoChange={pag && (offset > 0||condition) ? handleApply : undefined}
                handlePag={pag ? handlePag : undefined}
                onOffchange={handleOff}
     
                fLoader={fileLoader}
                onSelectedRowsChange={handleSelectedRowsChange}
              />
              
            </div>
          </div>
        </div>
      </div>
    </UserLayout>
  );
};
export default TransactionBasketSizeReport;
