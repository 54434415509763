import React, { ChangeEventHandler, useEffect, useState, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import BrandDashboardIndex from "../components/brandDashboard";
import api, { APIResponse, getAxiosRequestConfig } from "../config/api";
import { AuthUserType } from "../redux/actions/authUserActions";
import { PortfolioType } from "../redux/actions/portfolioActions";
import { CountryType } from "../redux/actions/countryActions";
import {
  RegionType,
  StateType,
  CityType,
} from "../redux/actions/commonActions";
import { AppDispatch, StoreState } from "../redux/store";
import { BrandType } from "../redux/actions/brandActions";
import { OptionsTypes } from "../redux/actions/selectedOptionsActions";
import Dashloader from "../components/custom/components/DashLoader";
import { UserLog } from "../config/common";

interface Props {}
interface RecordsForMonth {
  sales: string;
  transyear: number;
  transmonth: number;
  momgrowth: number | null;
  mtd: string;
  mtdpercentchange: number | null;
  averagesale: string;
  averagerate: number;
  averageratepercent: string;
  pfcurrcode: string | "";
}
interface RecordsForYear {
  sales: string;
  transyear: number;
  yoygrowth: number | null;
  ytd: number;
  ytdpercentchange: number | null;
  averagesale?: number;
  averagerate?: number;
  averageratepercent?: number;
  pfcurrcode: string | "";
}

interface MonthlyRecord {
  portfolio: number;
  record: RecordsForMonth[];
}
interface YearlyRecord {
  portfolio: number;
  record: RecordsForYear[];
}
interface RevenueRecords {
  revenue: string;
  year: number;
  month: number;
  pfcurrsymbol: string;
  pfcurrcode: string;
}
interface RevenueRecordRes {
  portfolio: number;
  record: RevenueRecords[];
}


interface brandCardResponse {
  yearlyRevenue:{
  revenue: string;
  year: number;
  month: number;
  pfcurrsymbol: string;
  pfcurrcode: string;
  }[];
  monthlyRevenue:{
    revenue: string;
    year: number;
    month: number;
    pfcurrsymbol: string;
    pfcurrcode: string;
    }[];
  monthlySales:{
    sales: string;
    transyear: number;
    transmonth: number;
    momgrowth: number | null;
    mtd: string;
    mtdpercentchange: number | null;
    averagesale: string;
    averagerate: number;
    averageratepercent: string;
    pfcurrcode: string | "";
  }[];
  yearlySales:{
    sales: string;
    transyear: number;
    yoygrowth: number | null;
    ytd: number;
    ytdpercentchange: number | null;
    averagesale?: number;
    averagerate?: number;
    averageratepercent?: number;
    pfcurrcode: string | "";
}[]
}

interface brandRecords {
  data: {
    portfolio: number;
    records: brandCardResponse;
  }
}


const BrandDashboard: React.FC<Props> = (props: Props) => {
  const dispatch = useDispatch<AppDispatch>();
  const authUser = useSelector<StoreState, AuthUserType | null>(
    (state) => state?.authUser
  );
  // const brandId = authUser?.["brandId"]!;
  const authToken = authUser?.["auth-token"]!;
  const config = getAxiosRequestConfig(authToken);
  const authUserPfId = authUser?.portfolioId;
  const authUserBrandId = authUser?.brandId;
  const authUserSiteId = authUser?.siteId;

  const selectedOption = useSelector<StoreState, OptionsTypes>(
    (state) => state?.selectedOption
  );
  const countryList = useSelector<StoreState, Array<CountryType>>(
    (state) => state?.country
  );
  const regionList = useSelector<StoreState, Array<RegionType>>(
    (state) => state?.region
  );
  const stateList = useSelector<StoreState, Array<StateType>>(
    (state) => state?.state
  );
  const cityList = useSelector<StoreState, Array<CityType>>(
    (state) => state?.city
  );
  const portfolioOption = useSelector<StoreState, PortfolioType[]>(
    (state) => state?.portfolio
  );
  let portfolioData = portfolioOption?.filter(
    (pf) => pf?.id?.toString() === authUserPfId?.toString()
  );

  const [portfolio, setPortfolio] = useState<string>("");
  const [pfName, setPfName] = useState<string>("");
  const [brand, setBrand] = useState<string>("");
  const [brandName, setBrandName] = useState<string>("");

  const [country, setCountry] = useState<string>("");
  const [region, setRegion] = useState<string>("");
  const [state, setState] = useState<string>("");
  const [city, setCity] = useState<string>("");

  const [yearlyData, setYearlyData] = useState<YearlyRecord[]>([]);
  const [monthlyData, setMonthlyData] = useState<MonthlyRecord[]>([]);
  // const [pfcurrsymbol, setPfcurrsymbol] = useState<string>("");
  const [pfcurrcode, setPfcurrCode] = useState<string>("");
  const [valueCaption, setValueCaption] = useState<string>("");

  const [yearlyRevenueData, setYearlyRevenueData] = useState<
    RevenueRecordRes[]
  >([]);
  const [monthlyRevenueData, setMonthlyRevenueData] = useState<
    RevenueRecordRes[]
  >([]);

  const [salesYTD, setSalesYTD] = useState<string>("");
  const [salesYTDPercent, setSalesYTDPercent] = useState<string>("");
  const [prevYearSalesYTD, setPrevYearSalesYTD] = useState<string>("");

  const [salesMTD, setSalesMTD] = useState<string>("");
  const [salesMTDPercent, setSalesMTDPercent] = useState<string>("");
  const [prevMonthSalesMTD, setPrevMonthSalesMTD] = useState<string>("");

  const [currentRevenue, setCurrentRevenue] = useState<string>("");
  const [revenuePercent, setRevenuePercent] = useState<string>("");
  const [prevYearRevenue, setPrevYearRevenue] = useState<string>("");

  const [currentMonthRevenue, setCurrentMonthRevenue] = useState<string>("");
  const [revenueMonthPercent, setRevenueMonthPercent] = useState<string>("");
  const [prevYearMonthRevenue, setPrevYearMonthRevenue] = useState<string>("");

  
  const [loader1, setLoader1] = useState<boolean>(true);
  
  const [widgetLoader1,setWidgetLoader1] = useState<boolean>(true)
  const [widgetLoader2,setWidgetLoader2] = useState<boolean>(true)

  const [cardDataRes, setCardDataRes] = useState<brandRecords[]>([]);


  const currentYear = new Date()?.getFullYear();
  const currentMonth = new Date()?.getMonth();

  const site = useMemo<string[]>(() => {
    if (authUserSiteId) {
      return authUserSiteId?.split(",")?.map((s) => {
        return s?.toString();
      });
    } else {
      return [];
    }
  }, [authUserSiteId]);

  const brandOption = useSelector<StoreState, BrandType[]>(
    (state) => state?.brand
  );
  let brandData = brandOption?.filter(
    (b) => b?.id?.toString() === authUserBrandId?.toString()
  );

  // const handleCountryChange: ChangeEventHandler<HTMLInputElement> = (e) => {
  //   setCountry(e?.target?.value);
  // };
  // const handleRegionChange: ChangeEventHandler<HTMLInputElement> = (e) => {
  //   setRegion(e?.target?.value);
  // };
  // const handleStateChange: ChangeEventHandler<HTMLInputElement> = (e) => {
  //   setState(e?.target?.value);
  // };
  // const handleCityChange: ChangeEventHandler<HTMLInputElement> = (e) => {
  //   setCity(e?.target?.value);
  // };
  const monthShort = (monthnum: number, type: number): string => {
    var CurrntMonth: string[] = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ];
    var CurrntMon: string[] = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];
    let mon_name =
      type === 1 ? CurrntMon[monthnum] : type === 2 ? CurrntMonth[monthnum] : "";
    return mon_name;
  };
  // const handleBrandChange: ChangeEventHandler<HTMLInputElement> = (e) => {
  //   setBrand(e?.target?.value);
  // };
  useEffect(() => {
    let pid =
      portfolioData[0] && portfolioData[0]?.id
        ? portfolioData[0]?.id?.toString()
        : "";
    let pname =
      portfolioData[0] && portfolioData[0]?.name
        ? portfolioData[0]?.name?.toString()
        : "";
    setPortfolio(pid);
    setPfName(pname);
  }, [portfolioData]);

  useEffect(() => {
    let pid =
      portfolioData[0] && portfolioData[0]?.id
        ? portfolioData[0]?.id?.toString()
        : "";
    let pname =
      portfolioData[0] && portfolioData[0]?.name
        ? portfolioData[0]?.name?.toString()
        : "";
    setPortfolio(pid);
    setPfName(pname);
  }, [portfolioData]);

  useEffect(() => {
    let bid =
      brandData[0] && brandData[0]?.id ? brandData[0]?.id?.toString() : "";
    let bname =
      brandData[0] && brandData[0]?.name ? brandData[0]?.name?.toString() : "";
    setBrand(bid);
    setBrandName(bname);
  }, [brandData]);

  useEffect(() => {
    let couid = countryList?.filter(
      (a) => a?.id?.toString() === selectedOption?.country?.toString()
    );

    if (couid?.length > 0 && couid[0]?.id !== undefined) {
      setCountry(couid[0]?.id?.toString());
    } else {
      setCountry("");
    }
  }, [country,countryList, selectedOption]);

  useEffect(() => {
    let regid = regionList?.filter(
      (a) => a?.id?.toString() === selectedOption?.region?.toString()
    );

    if (regid?.length > 0 && regid[0]?.id !== undefined) {
      setRegion(regid[0]?.id?.toString());
    } else {
      setRegion("");
    }
  }, [region,regionList, selectedOption]);

  useEffect(() => {
    let statid = stateList?.filter(
      (a) => a?.id?.toString() === selectedOption?.state?.toString()
    );

    if (statid?.length > 0 && statid[0]?.id !== undefined) {
      setState(statid[0]?.id?.toString());
    } else {
      setState("");
    }
  }, [state,stateList, selectedOption]);

  useEffect(() => {
    let citid = cityList?.filter(
      (a) => a?.id?.toString() === selectedOption?.city?.toString()
    );

    if (citid?.length > 0 && citid[0]?.id !== undefined) {
      setCity(citid[0]?.id?.toString());
    } else {
      setCity("");
    }
  }, [city,cityList, selectedOption]);

  // useEffect(() => {
  //   var getData = {
  //     portfolio: [parseInt(portfolio)],
  //     brand: parseInt(brand),
  //     site: site ? site : [],
  //     country: selectedOption?.country
  //       ? [parseInt(selectedOption?.country)]
  //       : [],
  //     region: selectedOption?.region ? [parseInt(selectedOption?.region)] : [],
  //     state: selectedOption?.state ? [parseInt(selectedOption?.state)] : [],
  //     city: selectedOption?.city ? [parseInt(selectedOption?.city)] : [],
  //   };
  //   if (getData?.portfolio?.length > 0 && portfolio && getData !== undefined) {
  //     api
  //       .post<APIResponse<{ records: YearlyRecord[] }>>(
  //         `kpi/brand-retail/sales/yearly`,
  //         getData,
  //         config
  //       )
  //       .then((response) => {
  //         if (response?.status === 200 && response?.data?.data) {
  //           const result = response?.data?.data?.records;
  //           setYearlyData(result);
  //           setLoader1(false)
  //         }
  //       })
  //       .catch((error) => {});
  //   } else {setYearlyData([]);
  //     if (getData?.portfolio?.length > 0 && portfolio && getData !== undefined) {
  //       setLoader1(false)
  //     }
  //   }
  // }, [
  //   portfolio,
  //   site,
  //   brand,
  //   selectedOption?.country,
  //   selectedOption?.region,
  //   selectedOption?.city,
  //   selectedOption?.state,
  // ]);

  // useEffect(() => {
  //   if (yearlyData?.length > 0) {
  //     let obj = yearlyData[0]?.record?.find(
  //       (y) => y?.transyear === currentYear
  //     );
  //     let prev_obj = yearlyData[0]?.record?.find(
  //       (y) => y?.transyear === currentYear - 1
  //     );
  //     setSalesYTD(obj?.ytd ? obj?.ytd?.toString() : "0");
  //     setSalesYTDPercent(
  //       obj?.ytdpercentchange !== null && obj?.ytdpercentchange
  //         ? obj?.ytdpercentchange?.toString()
  //         : ""
  //     );

  //     let lastYearObj = yearlyData[0]?.record?.find(
  //       (y) => y?.transyear === currentYear - 1
  //     );
  //     setPrevYearSalesYTD(lastYearObj?.ytd ? lastYearObj?.ytd?.toString() : "");
  //     setPfcurrCode(
  //       obj?.pfcurrcode
  //         ? obj?.pfcurrcode?.toString()
  //         : prev_obj?.pfcurrcode
  //         ? prev_obj?.pfcurrcode?.toString()
  //         : ""
  //     );
  //   }
  // }, [yearlyData, currentYear]);

  // useEffect(() => {
  //   var getData = {
  //     portfolio: [parseInt(portfolio)],
  //     brand: parseInt(brand),
  //     site: site ? site : [],
  //     country: selectedOption?.country
  //       ? [parseInt(selectedOption?.country)]
  //       : [],
  //     region: selectedOption?.region ? [parseInt(selectedOption?.region)] : [],
  //     state: selectedOption?.state ? [parseInt(selectedOption?.state)] : [],
  //     city: selectedOption?.city ? [parseInt(selectedOption?.city)] : [],
  //   };
  //   if (getData?.portfolio?.length > 0 && portfolio && getData !== undefined) {
  //     api
  //       .post<APIResponse<{ records: MonthlyRecord[] }>>(
  //         `kpi/brand-retail/sales/monthly`,
  //         getData,
  //         config
  //       )
  //       .then((response) => {
  //         if (response?.status === 200 && response?.data?.data) {
  //           const result = response?.data?.data?.records;
  //           setMonthlyData(result);
  //           setLoader2(false)
  //         }
  //       })
  //       .catch((error) => {});
  //   } else {
  //     setMonthlyData([]);
  //     if (getData?.portfolio?.length > 0 && portfolio && getData !== undefined) {
  //       setLoader2(false)
  //     }
  //   }
  // }, [
  //   portfolio,
  //   site,
  //   brand,
  //   selectedOption?.country,
  //   selectedOption?.region,
  //   selectedOption?.city,
  //   selectedOption?.state,
  // ]);

  // useEffect(() => {
  //   if (monthlyData?.length > 0) {
  //     let arr = monthlyData[0]?.record?.filter(
  //       (y) => y?.transyear === currentYear
  //     );
  //     let arr2 = monthlyData[0]?.record?.filter(
  //       (y) => y?.transyear === currentYear - 1
  //     );
  //     const obj = arr?.find((a) => a?.transmonth === currentMonth + 1);
  //     setSalesMTD(obj?.mtd ? obj?.mtd?.toString() : "0");
  //     setSalesMTDPercent(
  //       obj?.mtdpercentchange !== null && obj?.mtdpercentchange
  //         ? obj?.mtdpercentchange?.toString()
  //         : ""
  //     );
  //     const lastYearObj = arr2?.find((a) => a?.transmonth === currentMonth + 1);
  //     setPrevMonthSalesMTD(lastYearObj?.mtd ? lastYearObj?.mtd : "");
  //     // setPfcurrCode(obj?.pfcurrcode ? obj?.pfcurrcode?.toString() : "USD");
  //   }
  // }, [monthlyData, currentMonth, currentYear]);

  // useEffect(() => {
  //   //yearly
  //   var getData = {
  //     portfolio: [parseInt(portfolio)],
  //     brand: parseInt(brand),
  //     site: site ? site : [],
  //   };
  //   if (getData?.portfolio?.length > 0 && portfolio && getData !== undefined) {
  //     api
  //       .post<APIResponse<{ records: RevenueRecordRes[] }>>(
  //         `kpi/brand-retail/getRevenue/yearly`,
  //         getData,
  //         config
  //       )
  //       .then((response) => {
  //         if (response?.status === 200 && response?.data?.data) {
  //           const result = response?.data?.data?.records;
  //           setYearlyRevenueData(result);
  //           setLoader3(false)
  //         }
  //       })
  //       .catch((error) => {});
  //   } else {
  //     setYearlyRevenueData([]);
  //     if (getData?.portfolio?.length > 0 && portfolio && getData !== undefined) {
  //       setLoader3(false)
  //     }
  //   }
  // }, [portfolio, brand, site]);

  // useEffect(() => {
  //   if (yearlyRevenueData?.length > 0) {
  //     let obj = yearlyRevenueData[0]?.record?.find(
  //       (y) => y?.year === currentYear
  //     );
  //     let currentYrRevenue = obj?.revenue ? obj?.revenue?.toString() : "0";
  //     setCurrentRevenue(currentYrRevenue);
  //     let prevYrobj = yearlyRevenueData[0]?.record?.find(
  //       (y) => y?.year === currentYear - 1
  //     );
  //     let prevYrRevenue = prevYrobj?.revenue
  //       ? prevYrobj?.revenue?.toString()
  //       : "0";
  //     setPrevYearRevenue(prevYrRevenue);
  //     let per_change = obj?.revenue
  //       ? Number(prevYrRevenue) !== 0
  //         ? (
  //             ((Number(currentYrRevenue) - Number(prevYrRevenue)) /
  //               Number(prevYrRevenue)) *
  //             100
  //           )?.toString()
  //         : ""
  //       : "";
  //     setRevenuePercent(per_change);
  //     // setPfcurrsymbol(obj?.pfcurrsymbol ? obj?.pfcurrsymbol?.toString() : '$')
  //     // setPfcurrCode(obj?.pfcurrcode ? obj?.pfcurrcode?.toString() : 'USD')
  //   }
  // }, [yearlyRevenueData, currentYear]);

  // useEffect(() => {
  //   //monthly
  //   var getData = {
  //     portfolio: [parseInt(portfolio)],
  //     brand: parseInt(brand),
  //     site: site ? site : [],
  //   };
  //   if (getData?.portfolio?.length > 0 && portfolio && getData !== undefined) {
  //     api
  //       .post<APIResponse<{ records: RevenueRecordRes[] }>>(
  //         `kpi/brand-retail/getRevenue/monthly`,
  //         getData,
  //         config
  //       )
  //       .then((response) => {
  //         if (response?.status === 200 && response?.data?.data) {
  //           const result = response?.data?.data?.records;
  //           setMonthlyRevenueData(result);
  //           setLoader4(false)
  //         }
  //       })
  //       .catch((error) => {});
  //   } else {setMonthlyRevenueData([]);
  //     if (getData?.portfolio?.length > 0 && portfolio && getData !== undefined) {
  //       setLoader4(false)
  //     }
  //   }
  // }, [portfolio, brand, site]);

  // useEffect(() => {
  //   if (monthlyRevenueData?.length > 0) {
  //     let arr = monthlyRevenueData[0]?.record?.filter(
  //       (y) => y?.year === currentYear
  //     );
  //     const obj = arr?.find((a) => a?.month === currentMonth + 1);
  //     let currentmonth = obj?.revenue ? obj?.revenue?.toString() : "0";
  //     setCurrentMonthRevenue(currentmonth);

  //     let prev_arr = monthlyRevenueData[0]?.record?.filter(
  //       (y) => y?.year === currentYear - 1
  //     );
  //     const prev_obj = prev_arr?.find((a) => a?.month === currentMonth + 1);
  //     let prevYrmonth = prev_obj?.revenue ? prev_obj?.revenue?.toString() : "0";
  //     setPrevYearMonthRevenue(prevYrmonth);

  //     let per_change = obj?.revenue
  //       ? Number(prevYrmonth) !== 0
  //         ? (
  //             ((Number(currentmonth) - Number(prevYrmonth)) /
  //               Number(prevYrmonth)) *
  //             100
  //           )?.toString()
  //         : ""
  //       : "";
  //     setRevenueMonthPercent(per_change);
  //     // setPfcurrsymbol(obj?.pfcurrsymbol ? obj?.pfcurrsymbol?.toString() : '$')
  //     // setPfcurrCode(obj?.pfcurrcode ? obj?.pfcurrcode?.toString() : 'USD')
  //   }
  // }, [monthlyRevenueData, currentMonth, currentYear]);


  useEffect(()=>{
     
    if(authUser){
      const logProps1 = {
        action:`Visit`,
        event: 'Brand Dashboard page | Visited '
     };
     UserLog(logProps1)
    }
  
},[])


useEffect(() => {
  if (cardDataRes[0]?.data?.records?.yearlySales?.length > 0) {
      let obj = cardDataRes[0]?.data?.records?.yearlySales?.find((y) => y?.transyear?.toString() === currentYear?.toString());
      setSalesYTD(obj?.ytd ? obj?.ytd?.toString() : "0");
      
      let lastYearObj = cardDataRes[0]?.data?.records?.yearlySales?.find(
      (y) => y?.transyear === currentYear - 1
      );
      setPrevYearSalesYTD(lastYearObj?.ytd ? lastYearObj?.ytd?.toString() : "0");
      setSalesYTDPercent(
        obj?.ytdpercentchange !== null && obj?.ytdpercentchange
          ? obj?.ytdpercentchange?.toString()
          : ""
      );
  }else{
    setSalesYTD('0')
    setPrevYearSalesYTD('0')
    setSalesYTDPercent('')
  }

  if (cardDataRes[0]?.data?.records?.monthlySales?.length > 0) {
      let arr = cardDataRes[0]?.data?.records?.monthlySales?.filter(
      (y) => y?.transyear === currentYear
      );
      let arr2 = cardDataRes[0]?.data?.records?.monthlySales?.filter(
      (y) => y?.transyear === currentYear - 1
      );
      const obj = arr?.find((a) => a?.transmonth === currentMonth + 1);
      setSalesMTD(obj?.mtd? obj?.mtd?.toString() : "0");


      const lastYearObj = arr2?.find((a) => a?.transmonth === currentMonth + 1);
      setPrevMonthSalesMTD(lastYearObj?.mtd ? lastYearObj?.mtd : "0");
      setSalesMTDPercent(
        obj?.mtdpercentchange !== null && obj?.mtdpercentchange
          ? obj?.mtdpercentchange?.toString()
          : ""
      );
  }else{
    setSalesMTD('0')
    setPrevMonthSalesMTD('0')
    setSalesMTDPercent('')
  }

  if (cardDataRes[0]?.data?.records?.yearlyRevenue?.length > 0) {
      let obj = cardDataRes[0]?.data?.records?.yearlyRevenue?.find(
      (y) => y?.year === currentYear
      );
      let currentYrRevenue = obj?.revenue ? obj?.revenue?.toString() : "0";
      setCurrentRevenue(currentYrRevenue);
      let prevYrobj = cardDataRes[0]?.data?.records?.yearlyRevenue?.find(
      (y) => y?.year === currentYear - 1
      );
      let prevYrRevenue = prevYrobj?.revenue
      ? prevYrobj?.revenue?.toString()
      : "0";
      setPrevYearRevenue(prevYrRevenue);
      let per_change = obj?.revenue
      ? Number(prevYrRevenue) !== 0
      ? (
      ((Number(currentYrRevenue) - Number(prevYrRevenue)) /
      Number(prevYrRevenue)) *
      100
      )?.toString()
      : ""
      : "";
      setRevenuePercent(per_change);
      // setPfcurrsymbol(obj?.pfcurrsymbol ? obj?.pfcurrsymbol?.toString() : "$");
      // setPfcurrCode(obj?.pfcurrcode ? obj?.pfcurrcode?.toString() : "USD");
  }else{
    setCurrentRevenue('0')
    setPrevYearRevenue('0')
    setRevenuePercent('')
  }

  if (cardDataRes[0]?.data?.records?.monthlyRevenue?.length > 0) {
      let arr = cardDataRes[0]?.data?.records?.monthlyRevenue?.filter(
      (y) => y?.year === currentYear
      );
      const obj = arr?.find((a) => a?.month === currentMonth + 1);
      let currentmonth = obj?.revenue ? obj?.revenue?.toString() : "0";
      setCurrentMonthRevenue(currentmonth);

      let prev_arr = cardDataRes[0]?.data?.records?.monthlyRevenue?.filter(
      (y) => y?.year === currentYear - 1
      );
      const prev_obj = prev_arr?.find((a) => a?.month === currentMonth + 1);
      let prevYrmonth = prev_obj?.revenue ? prev_obj?.revenue?.toString() : "0";
      setPrevYearMonthRevenue(prevYrmonth);

      let per_change = obj?.revenue
      ? Number(prevYrmonth) !== 0
      ? (
      ((Number(currentmonth) - Number(prevYrmonth)) /
      Number(prevYrmonth)) *
      100
      )?.toString()
      : ""
      : "";
      setRevenueMonthPercent(per_change);
      // setPfcurrsymbol(obj?.pfcurrsymbol ? obj?.pfcurrsymbol?.toString() : "$");
      // setPfcurrCode(obj?.pfcurrcode ? obj?.pfcurrcode?.toString() : "USD");
  }else{
    setCurrentMonthRevenue('0')
    setPrevYearMonthRevenue('0')
    setRevenueMonthPercent('')
  }
}, [currentMonth, currentYear,cardDataRes]);


useEffect(() => {
  
  var getData = {
    portfolio: [parseInt(portfolio)],
    brand: parseInt(brand),
    site: site ? site : [],
    country: selectedOption?.country
      ? [parseInt(selectedOption?.country)]
      : [],
    region: selectedOption?.region ? [parseInt(selectedOption?.region)] : [],
    state: selectedOption?.state ? [parseInt(selectedOption?.state)] : [],
    city: selectedOption?.city ? [parseInt(selectedOption?.city)] : [],
    authUserRole: authUser?.roleId ? authUser?.roleId : "",
  };
  if (getData?.portfolio?.length > 0 && portfolio && getData !== undefined && brand!=='') {
    setLoader1(true)
    setWidgetLoader1(true)
    setWidgetLoader2(true)
    api
      .post<APIResponse<{ records: brandRecords[] }>>(
        `kpi/brand/dashboard/cardetails`,
        getData,
        config
      )
      .then((response) => {
        if (response?.status === 200 && response?.data?.data) {
          const result = response?.data?.data?.records;
          setCardDataRes(result);
          setLoader1(false)
        }
        else {
          setCardDataRes([]);
          setLoader1(false)
        }
      })
      .catch((error) => {});
  } 
}, [
  portfolio,
  site,
  brand,
  selectedOption?.country,
  selectedOption?.region,
  selectedOption?.city,
  selectedOption?.state,
]);

useEffect(()=>{
  setPfcurrCode(portfolioData[0]?.reportingcurrency?.toString()??'')
  setValueCaption(portfolioData[0]?.valuecaption?.toString()??'')
},[portfolioOption])


  return (
    <>
      <div className="card background-transparent">
        <div className="row my-1">
          <div className="wow fadeInLeft ps-0 col-lg-6">
            <h4 className="b-title">Brand Dashboard</h4>
          </div>
        </div>
      </div>

      <div className="row bf-dash">
        <div className="col-sm-6 col-lg-3">
          <div
            className="sales-box sales-ytd wow fadeInRight"
            data-wow-duration="2s"
          >
            <div className="sales-head">
              <div>
              <h3>Sales {currentYear} YTD </h3>
              <p>
                {valueCaption ? "(" + valueCaption + ")" : ""}
              </p>
              </div>
              {Number(salesYTDPercent) !== 0 ? (
                <button
                  className={
                    parseFloat(salesYTDPercent) > 0
                      ? "btn btn-positive"
                      : "btn btn-negative"
                  }
                >
                  <i
                    className={
                      parseFloat(salesYTDPercent) > 0
                        ? "fe-arrow-up-right"
                        : "fe-arrow-down-right"
                    }
                  >
                    {salesYTDPercent}%{" "}
                  </i>
                </button>
              ) : (
                <button className={"btn btn-card-empty"}>
                  <i> </i>
                </button>
              )}
            </div>
            <h2>
              {salesYTD}
              <span className="currCodeSpan">
                {pfcurrcode ? " (" + pfcurrcode + ")" : " "}
              </span>
            </h2>
            {Number(prevYearSalesYTD) !== 0 ? (
              <p>
                Last Year Sales:{prevYearSalesYTD}
                <span className="currCodeSpan">
                  {pfcurrcode ? " (" + pfcurrcode + ")" : " "}
                </span>
              </p>
            ) : (
              <p>Last Year Sales:</p>
            )}
          </div>
        </div>
        <div className="col-sm-6 col-lg-3 col-md-6 bfc-dash">
          <div
            className="sales-box sales-mtd wow fadeInRight"
            data-wow-duration="2s"
          >
            <div className="sales-head">
              <div>
              <h3>
                Sales {currentYear} {monthShort(currentMonth, 1)} MTD{" "}
              </h3>
              <p>
                {valueCaption ? "(" + valueCaption + ")" : ""}
              </p>
              </div>
              
              {Number(salesMTDPercent) !== 0 ? (
                <button
                  className={
                    parseFloat(salesMTDPercent) > 0
                      ? "btn btn-positive"
                      : "btn btn-negative"
                  }
                >
                  <i
                    className={
                      parseFloat(salesMTDPercent) > 0
                        ? "fe-arrow-up-right"
                        : "fe-arrow-down-right"
                    }
                  >
                    {salesMTDPercent}%{" "}
                  </i>
                </button>
              ) : (
                <button className={"btn btn-card-empty"}>
                  <i> </i>
                </button>
              )}
            </div>
            <h2>
              {salesMTD}
              <span className="currCodeSpan">
                {pfcurrcode ? " (" + pfcurrcode + ")" : " "}
              </span>
            </h2>
            {Number(prevMonthSalesMTD) !== 0 ? (
              <p>
                Last Year {monthShort(currentMonth, 1)} Sales:
                {prevMonthSalesMTD}
                <span className="currCodeSpan">
                  {pfcurrcode ? " (" + pfcurrcode + ")" : " "}
                </span>
              </p>
            ) : (
              <p>Last Year {monthShort(currentMonth, 1)} Sales:</p>
            )}
          </div>
        </div>
        <div className="col-lg-3 col-sm-6  col-md-6">
          <div
            className="sales-box revenue-ytd wow fadeInRight"
            data-wow-duration="2s"
          >
            {/* style="visibility: visible; animation-duration: 2s; animation-name: fadeInRight;" */}
            <div className="sales-head">
              <div>
              <h3>Revenue {currentYear}</h3>
              <p>
                {valueCaption ? "(" + valueCaption + ")" : ""}
              </p>
              </div>
              
              {Number(revenuePercent) !== 0 ? (
                <button
                  className={
                    parseFloat(revenuePercent) > 0
                      ? "btn btn-positive"
                      : "btn btn-negative"
                  }
                >
                  <i
                    className={
                      parseFloat(revenuePercent) > 0
                        ? "fe-arrow-up-right"
                        : "fe-arrow-down-right"
                    }
                  >
                    {Number(revenuePercent)?.toFixed(2)}%{" "}
                  </i>
                </button>
              ) : (
                <button className={"btn btn-card-empty"}>
                  <i> </i>
                </button>
              )}
            </div>
            <h2>
              {currentRevenue}
              <span className="currCodeSpan">
                {pfcurrcode ? " (" + pfcurrcode + ")" : " "}
              </span>
            </h2>
            {Number(prevYearRevenue) !== 0 ? (
              <p>
                Last year revenue:{prevYearRevenue}
                <span className="currCodeSpan">
                  {pfcurrcode ? " (" + pfcurrcode + ")" : " "}
                </span>
              </p>
            ) : (
              <p>Last year revenue:</p>
            )}
          </div>
        </div>

        <div className="col-lg-3 col-sm-6  col-md-6">
          <div
            className="sales-box revenue-mtd wow fadeInRight"
            data-wow-duration="2s"
          >
            {/* style="visibility: visible; animation-duration: 2s; animation-name: fadeInRight;" */}
            <div className="sales-head">
              <div>
              <h3>
                Revenue {currentYear} {monthShort(currentMonth, 1)}
              </h3>
              <p>
                {valueCaption ? "(" + valueCaption + ")" : ""}
              </p>
              </div>
              
              {Number(revenueMonthPercent) !== 0 ? (
                <button
                  className={
                    Number(revenueMonthPercent) > 0
                      ? "btn btn-positive"
                      : "btn btn-negative"
                  }
                >
                  <i
                    className={
                      Number(revenueMonthPercent) > 0
                        ? "fe-arrow-up-right"
                        : "fe-arrow-down-right"
                    }
                  >
                    {Number(revenueMonthPercent)?.toFixed(2)}%{" "}
                  </i>
                </button>
              ) : (
                <button className={"btn btn-card-empty"}>
                  <i> </i>
                </button>
              )}
            </div>
            <h2>
              {currentMonthRevenue}
              <span className="currCodeSpan">
                {pfcurrcode ? " (" + pfcurrcode + ")" : " "}
              </span>
            </h2>
            {Number(prevYearMonthRevenue) !== 0 ? (
              <p>
                Last year {monthShort(currentMonth, 1)} revenue:
                {prevYearMonthRevenue}
                <span className="currCodeSpan">
                  {pfcurrcode ? " (" + pfcurrcode + ")" : " "}
                </span>
              </p>
            ) : (
              <p>Last year {monthShort(currentMonth, 1)} revenue:</p>
            )}
          </div>
        </div>
        {/* <div className="col-sm-6 col-lg-3">
                <div className="sales-box revenue-ytd wow fadeInRight" data-wow-duration="2s">
                    <div className="sales-head">
                        <h3>Brand Info </h3>
                    </div>     
                    <div className="brand-info">
                        <p >Company: Reliance Retail</p>
                        <p >Outlet #: 260</p>    
                        <p >Active Outlets: 260</p>    
                    </div>           
                </div>
            </div> */}
      </div>
      { (widgetLoader1!==false||widgetLoader2!==false||loader1!==false)&&<Dashloader/>}
      {loader1===false&&<BrandDashboardIndex
        portfolioId={parseInt(portfolio)}
        siteId={site ? site : []}
        brandId={parseInt(brand)}
        country={
          selectedOption?.country ? [parseInt(selectedOption?.country)] : []
        }
        region={selectedOption?.region ? [parseInt(selectedOption?.region)] : []}
        state={selectedOption?.state ? [parseInt(selectedOption?.state)] : []}
        city={selectedOption?.city ? [parseInt(selectedOption?.city)] : []}
        widgetLoader1={setWidgetLoader1}
        widgetLoader2={setWidgetLoader2}
        apiEnabler1 = {loader1}
        apiEnabler2 = {loader1}
      />}
    </>
  );
};
export default BrandDashboard;
