import { faCaretDown, faCaretUp } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { ChangeEventHandler, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Option from "../components/Option";
import { getOnlyUnique } from "../components/commonWidget";
import chartIcon from "../components/custom/assets/images/dashboard/chart_icon.svg";
import tableIcon from "../components/custom/assets/images/dashboard/table_icon.svg";
import { MultiSelect } from "../components/custom/components";
import FormInput2 from "../components/custom/components/FormInput2";
import {
  Tbody,
  Thead,
} from "../components/custom/components/TradeCategoryPerformanceTable";
import TransactionTable from "../components/custom/components/TransactionTable";
import BarChart1 from "../components/custom/components/charts/apex/Barchart1";
import { COLORS } from "../components/custom/constants";
import UserLayout from "../components/custom/layouts/UserLayout";
import { ToastTypes, useToast } from "../components/toast/ToastProvider";
import { defaultLocation, defaultPortfolio, defaultTradeCategory, DropDownOption1, PAGE_SIZE } from "../config";
import api, { APIResponse, getAxiosRequestConfig } from "../config/api";
import {
  CSVdownload,
  YearOptions,
  handleDownloadPDF,
} from "../config/functions";
import { AuthUserType } from "../redux/actions/authUserActions";
import { PortfolioType } from "../redux/actions/portfolioActions";
import {
  OptionsTypes,
  addToSelectedOption,
} from "../redux/actions/selectedOptionsActions";
import { SiteType } from "../redux/actions/siteActions";
import { TradeCategoryType } from "../redux/actions/tradeCategoryActions";
import { TradeSubCategoryType } from "../redux/actions/tradeSubCategoryActions";
import { StoreState } from "../redux/store";
import Loader from "../components/custom/components/Loader";
import Modal from "../components/Modal";
import { faWindowMaximize } from "@fortawesome/free-solid-svg-icons";
import { areaType } from "../redux/actions/areaActions";
import { outletType } from "../redux/actions/outletActions";
import { useLocation } from "react-router-dom";
import { UserLog } from "../config/common";
import SingleSelect from "../components/custom/components/SingleSelect";
import SingleSelect1 from "../components/custom/components/SingleSelect1";


export interface DropDownOption {
  value: number;
  label: string;
  imgUrl: string;
}

export interface OverallSalesRecord {
  subcategory: number;
  sales: string;
  transyear: number;
  yoygrowth: number | null;
  ytd: string;
  ytdpercentchange: number | null;
  averagesale: string;
  averagerate: number;
  averageratepercent: string;
}

export interface OverallSalesReportType {
  portfolio: number;
  record: OverallSalesRecord[];
  count?: number;
}

export interface BuildingType {
  centerid: number;
  buildingid: number;
  buildingname: string;
  buildingcode: string;
}

export interface BuildingDataType {
  portfolio: number;
  record: BuildingType[];
}

export interface FloorType {
  centerid: number;
  buildingid: number;
  floorid: number;
  floorname: string;
  floorcode: string;
}

export interface FloorDataType {
  portfolio: number;
  record: FloorType[];
}

const SalesOverAllSalesReport: React.FC = () => {
  const toast = useToast();
  const dispatch = useDispatch();
  const pageName = useLocation()
  const authUser = useSelector<StoreState, AuthUserType | null>(
    (state) => state?.authUser
  );
  const authUserToken = useSelector<StoreState, AuthUserType | null>(
    (state) => state?.authUser
  )?.["auth-token"]!;
  const config = getAxiosRequestConfig(authUserToken);

  const authUserSiteId = authUser?.siteId;

  const portfolioOption = useSelector<StoreState, PortfolioType[]>(
    (state) => state?.portfolio
  );
  const siteOption = useSelector<StoreState, SiteType[]>((state) => state?.site);

  const selectedOption = useSelector<StoreState, OptionsTypes>(
    (state) => state?.selectedOption
  );

  const port =
    authUser?.portfolioId !== undefined && authUser?.portfolioId !== null
      ? authUser?.portfolioId?.split(",")
      : [];

  let portfolioData = port
    ?.map((pe) => {
      return portfolioOption?.filter(
        (f) => f?.id?.toString() === pe?.toString()
      );
    })
    ?.flat(1);

  const pname = portfolioData[0]?.name;

  const st =
    authUser?.siteId !== undefined && authUser?.siteId !== null
      ? authUser?.siteId?.split(",")
      : [];

  let siteData = st
    ?.map((pe) => {
      return siteOption?.filter((f) => f?.id?.toString() === pe?.toString());
    })
    ?.flat(1);
  const outletList = useSelector<StoreState, outletType[]>(
    (state) => state?.outlet
  );
  const categoryOption = useSelector<StoreState, TradeCategoryType[]>(
    (state) => state?.tradeCategory
  );
  const localityOption = useSelector<StoreState, areaType[]>(
    (state) => state?.locality
  );
  const subCategoryOption = useSelector<StoreState, TradeSubCategoryType[]>(
    (state) => state?.tradeSubCategory
  );
  const [valueIn, setValueIn] = useState<string>("");

  const [portfolios, setPortfolios] = useState<string>("");
  const [sites, setSites] = useState<string>("");

  const [location, setLocation] = useState<string>("");
  const [categories, setCategories] = useState<string>("");
  const [categoryName, setCategoryName] = useState<string>("");
  const [subCategories, setSubCategories] = useState<DropDownOption[]>([]);
  const [disableSubCategory, setDisableSubCategory] = useState<boolean>(false);
  const [year, setYear] = useState<Date>(new Date());

  //loader
  const [fileLoader, setFileLoader] = useState<boolean>(false);
  const [loader, setLoader] = useState<boolean>(false);
  const [pag, setPag] = useState<boolean>(false);
  const [condition,setCondition] = useState<boolean>(false)

  const [portfoliosOption, setPortfoliosOption] = useState<DropDownOption1>(defaultPortfolio);
  const  [locationOption,setLocationOption] = useState<DropDownOption1>(defaultLocation)
  const [categoriesOption, setCategoriesOption] = useState<DropDownOption1>(defaultTradeCategory);

  const [portfolioDrop, setPortfolioDrop] = useState<DropDownOption[]>([]);
  const [locationDrop, setLocationDrop] = useState<DropDownOption[]>([]);
  const [catDrop,setCatDrop] = useState<DropDownOption[]>([])

  useEffect(() => {
    if (categories !== "") {
      const categoryIndex = categoryOption?.findIndex(
        (c) => c?.id !== undefined && c?.id?.toString() === categories?.toString()
      );
      const categoryName = categoryOption[categoryIndex]?.name;
      setCategoryName(categoryName ? categoryName : "Trade Category");
    } else setCategoryName("Trade Category");
  }, [categories, categoryOption]);

  const [overAllSalesData, setOverAllSalesData] = useState<
    OverallSalesReportType[]
  >([]);

  const limit = PAGE_SIZE;
  const [offset, setOffset] = useState<number>(0);
  const [range, setRange] = useState<number[]>([]);
  const [length, setLength] = useState<number>(50);
  const [yearDropDown, setYearDropDown] = useState<number[]>(YearOptions());

  const [selectedRows, setSelectedRows] = useState<string[]>([]);

  const [showModal, setShowModal] = useState<boolean>(false);
  const [isButtonVisible, setButtonVisible] = useState(false);
  const [disableLocality, setDisableLocality] = useState<boolean>(false);

  const showExpandModalHandler = () => {
    setShowModal(true)
  };

  const handleSelectedRowsChange = (selectedRows: string[]) => {
    setSelectedRows(selectedRows);
  };

  const handleOff = (conditionVal: boolean) => {
    setCondition(conditionVal);
  };


  const subCategoryFilteredByCategory = useMemo<TradeSubCategoryType[]>(() => {
    if (categories !== "") {
      return subCategoryOption?.filter((sc) => {
        return sc?.catid?.toString() === categories?.toString();
      });
    } else return subCategoryOption;
  }, [categories, subCategoryOption]); 
  
  const handlePortfolioDrop = (selected: any) => {
    if(fileLoader===false){
    if(selected.length>0){
      const selectedPortfolio = selected as DropDownOption[];
      if(selectedPortfolio[0].value!==null&&selectedPortfolio[0].value!==0){
        setPortfolioDrop(selectedPortfolio)
        setPortfolios(selectedPortfolio[0]?.value?.toString())
        const data = { ...selectedOption, portfolio: selectedPortfolio[0]?.value?.toString() };
        dispatch(addToSelectedOption(data));
        setOffset(0);
        setPag(false);
      }
    }
  }
  };

  const handleClearPortfolio = () =>{
    let pid =portfolioData[0] && portfolioData[0]?.id? portfolioData[0].id?.toString(): "";
    setPortfolios(selectedOption.portfolio ? selectedOption.portfolio : pid);
    const b = portfolioOption.filter((a)=>a?.id?.toString()===pid?.toString())
    if (b?.length > 0 && b[0]?.id !== undefined) {
      const val: DropDownOption = {
        value: b[0]?.id, 
        label: b[0]?.name,
        imgUrl: b[0]?.logourl, 
      };
      setPortfolioDrop([val]);

      if(fileLoader===false){
      setLocation("");
      setLocationDrop([]);
      const data = { ...selectedOption, portfolio: b[0]?.id?.toString(),location:''};
      dispatch(addToSelectedOption(data));
      setOffset(0);
      setPag(false);
    }
  }
  }

  const handleLocationDrop = (selected: any) => {
    if(fileLoader===false){
    if(selected.length>0){
      const selectedLocation = selected as DropDownOption[];
      if(selectedLocation[0].value!==null&&selectedLocation[0].value!==0){
        setLocationDrop(selectedLocation)
        setLocation(selectedLocation[0]?.label?.toString())
       const data = { ...selectedOption,location: selectedLocation[0]?.label?.toString()};
       dispatch(addToSelectedOption(data));
       setOffset(0);
       setPag(false);
      }
    }
  }
  };
  
  const handleClearLocation = () =>{
       if(fileLoader===true){
        setLocation(location ? location : '');
        const b = localityOption.filter((a)=>a?.area?.toString()===location?.toString())
        if (b?.length > 0 && b[0]?.id !== undefined) {
          const val: DropDownOption = {
            value: b[0]?.id, 
            label: b[0]?.area,
            imgUrl:'', 
          };
          setLocationDrop([val]);
       }
       else{
        setLocationDrop([])
     }
      }
     
       if(fileLoader===false){
         setLocationDrop([])
         setLocation('')
       const data = { ...selectedOption,location: ""};
        dispatch(addToSelectedOption(data));
        setOffset(0);
        setPag(false);
       }
  }

  const handleCatDrop = (selected: any) => {
    if(fileLoader===false){
    if(selected.length>0){
      const selectedCat = selected as DropDownOption[];
      if(selectedCat[0].value!==null&&selectedCat[0].value!==0){
        setCatDrop(selectedCat)
        setCategories(selectedCat[0]?.value?.toString())
        setSubCategories([])
        const data = {
          ...selectedOption,
          category: selectedCat[0]?.value?.toString(),
          subCategory: "",
          subCategories:[],
          brand: "",
          brands:[]
        };
        dispatch(addToSelectedOption(data));
        setOffset(0);
        setPag(false);
      }
    }
  }
  };
  
  const handleClearCat = () =>{
    if(fileLoader===true){
      setCategories(categories ? categories : '');
      const b = categoryOption.filter((a)=>a?.id?.toString()===categories?.toString())
      if (b?.length > 0 && b[0]?.id !== undefined) {
        const val: DropDownOption = {
          value: b[0]?.id, 
          label: b[0]?.name,
          imgUrl:'', 
        };
        setCatDrop([val]);
     }
     else{
      setCatDrop([])
   }
    }
   
     if(fileLoader===false){
      setCatDrop([])
      setCategories('')
        setSubCategories([])
       const data = { ...selectedOption,
        category: '',
        subCategory: "",
        subCategories:[],
        brand: "",
      brands:[]
    };
       dispatch(addToSelectedOption(data));
       setOffset(0);
       setPag(false);
     }
  }

  

  
  const portFilter = portfolioOption?.filter((a)=>a?.id?.toString()===portfolios?.toString())
  const catFilter = categoryOption?.filter((a)=>a?.id?.toString()===categories?.toString())
  const subcatFilter = subCategoryOption?.filter((a)=>a?.id?.toString()===subCategories?.toString())

  const localityFilter = localityOption?.filter((a)=>a?.area?.toString()===location?.toString())

  let subcategoriesName =  '' 
  if (subCategories?.length > 0) {
    let subcatNameArr: string[] = [];
    subCategories?.map((s) => {
      subcatNameArr?.push(s?.label);
    });
    subcategoriesName = subcatNameArr?.join(", ");
  }


  const combinedValues = [
    ...portFilter?.map(a => `Portfolio: ${a?.name}`),           
    ...catFilter?.map(c=> `Trade Category: ${c?.name}`),
    ...subcatFilter?.map(d=> `Trade Sub Category: ${d?.name}`),
    ...localityFilter?.map(f=>`Locality: ${f?.area}`),     
    `Period: ${year?.getFullYear()-2} - ${year?.getFullYear()}`,
  ]
  ?.filter(name => name && name?.trim() !== '') 
  ?.join(', ');  
  

  const YearlyFilteredData = useMemo<OverallSalesReportType[]>(() => {
    var data: OverallSalesReportType[] = [];
    overAllSalesData?.forEach((d) => {
      data?.push({
        portfolio: d?.portfolio,
        record: yearDropDown?.sort()
          ?.map((yr) => {
            return d?.record?.filter((r) => {
              return yr?.toString() === r?.transyear?.toString();
            });
          })
          ?.flat(1),
      });
    });
    return data;
  }, [overAllSalesData, yearDropDown]);

  const tHead = useMemo<Thead[]>(() => {
    var theadArray: {
      id: string;
      children: string;
      text: string;
    }[] = [
      { id: "Sub Category", children: "Sub Category", text: "Sub Category" },
    ];

    yearDropDown?.sort()?.forEach((yr) => {
      theadArray?.push(
        {
          id: yr?.toString(),
          children: yr?.toString(),
          text: yr?.toString(),
        },
        { id: yr + " YTD", children: yr + " YTD", text: yr + " YTD" },
        {
          id: yr + " YTD % Change",
          children: yr + " YTD % Change",
          text: yr + " YTD % Change",
        }
      );
    });

    return theadArray;
  }, []);

  const fetchDataForDownload = async () => {
    let error = false;
    if (portfolios === "") {
      toast("Select Portfolio", ToastTypes?.ERROR);
      error = true;
    }

    var getData = {
      portfolio: portfolio,
      site: site,
      locality: location===defaultLocation?.label?'':location,
      tradeCategory: category,
      tradeSubCategory: subCategory,
    };

    if (error === false && getData !== undefined) {
      const response = await api.post<
        APIResponse<{ records: OverallSalesReportType[] }>
      >(`kpi/sales/overallSales`, getData, config);

      if (response?.status === 200 && response?.data?.data) {
        return response?.data?.data?.records;
      }
    }

    return [];
  };

  const getDownloadData = (Data: OverallSalesReportType[]) => {
    var tableHead: Thead[] = tHead;
    var tableBody: Tbody[][] = [];

    const tbody = Data;

    Data?.forEach((y, i) => {
      const subcatArray = y?.record
        ?.map((r, j) => r?.subcategory)
        ?.filter(getOnlyUnique);

      subcatArray?.forEach((cat, j) => {
        var tBody: Tbody[] = [];
        const scategoryIndex = subCategoryOption?.findIndex(
          (c) => c?.id !== undefined && c?.id?.toString() === cat?.toString()
        );
        const scategoryName = subCategoryOption[scategoryIndex]?.name;
        const scategoryId = cat;

        tBody?.push({
          id: "Sub Category" + scategoryId + j,
          theadId: "Sub Category",
          children: scategoryName,
          text: scategoryName,
        });
        y?.record
          ?.filter((r) => r?.subcategory?.toString() === scategoryId?.toString())
          ?.forEach((r, i) => {
            tBody?.push(
              {
                id: r?.transyear?.toString() + i,
                children: r?.sales,
                text: r?.sales,
                theadId: r?.transyear?.toString(),
              },
              {
                id: r?.ytd !== undefined && r?.ytd !== null ? r?.ytd : "",
                children: r?.ytd,
                text: r?.ytd !== undefined && r?.ytd !== null ? r?.ytd : "",
                theadId: r?.transyear + " YTD",
              },
              {
                id: r?.transyear?.toString() + "% Change" + i,
                children:
                  r?.ytdpercentchange !== undefined &&
                  r?.ytdpercentchange !== null ? (
                    <div
                      className={
                        r?.ytdpercentchange !== undefined &&
                        r?.ytdpercentchange !== null
                          ? r?.ytdpercentchange > 0
                            ? "p-mtd text-success text-truncate"
                            : r?.ytdpercentchange < 0
                            ? "n-mtd text-danger text-truncate"
                            : ""
                          : ""
                      }
                    >
                      {r?.ytdpercentchange > 0 ? (
                        <i>
                          {" "}
                          <FontAwesomeIcon icon={faCaretUp} size="xl" />{" "}
                          {r?.ytdpercentchange?.toString() || ""}
                        </i>
                      ) : r?.ytdpercentchange < 0 ? (
                        <i>
                          {" "}
                          <FontAwesomeIcon icon={faCaretDown} size="xl" />{" "}
                          {r?.ytdpercentchange?.toString() || ""}
                        </i>
                      ) : (
                        r?.ytdpercentchange?.toString()
                      )}
                    </div>
                  ) : r?.ytdpercentchange === null ? (
                    "-"
                  ) : (
                    ""
                  ),
                text: r?.ytdpercentchange?.toString(),
                theadId: r?.transyear + " YTD % Change",
              }
            );
          });
        if (tBody?.length > 1) tableBody?.push(tBody);
      });
    });
    return { tbody: tableBody, thead: tableHead };
  };

  const handlePag = () => {
    setPag(true);
  };

  const handleApply = () => {
    let error = false;
    if (portfolios === "") {
      toast("Select Portfolio", ToastTypes?.ERROR);
      error = true;
    }
    var getData = {
      portfolio: portfolio,
      site: site,
      locality: location===defaultLocation?.label?'':location,
      tradeCategory: category,
      tradeSubCategory: subCategory,
      limit: limit?.toString(),
      offset: offset?.toString(),
      authUserRole: authUser?.roleId ? authUser?.roleId : "",
    };

    if (
      error === false &&
      getData !== undefined &&
      getData?.limit !== "" &&
      getData?.offset !== ""
    ) {
      if (pag === false) {
        setLoader(true);
      }
      api
        .post<APIResponse<{ records: OverallSalesReportType[] }>>(
          `kpi/sales/overallSales`,
          getData,
          config
        )
        .then((response) => {
          if (response?.status === 200 && response?.data?.data) {
            const result = response?.data?.data?.records;
            setLoader(false);
            setPag(true);
            setOverAllSalesData(result);
            setCondition(false)
            if(authUser){
              const logProps1 = {
                action: 'Apply',
                event: `${pageName?.pathname?.replace("\\", " ")} page | Filters - ${combinedValues} | Apply Clicked | Data Status: ${result?.[0]?.count && result[0]?.count > 0 ? 'Success' : 'Empty'}`
              };
              UserLog(logProps1)
            }
            // calculateRange(limit)
          }
        })
        .catch((error) => {});
    } else {
      setOverAllSalesData([]);
      setLoader(false);
      setCondition(false)
      setPag(true);
    }
  };
useEffect(()=>{    
    if(selectedOption?.isOutletUser){
      setDisableLocality(true);
    }
    else{
      setDisableLocality(false);
    }
  },[authUser, outletList,selectedOption]);
  
  useEffect(() => {
    if (portfolios !== "" && fileLoader === false) {
      handleApply();
    } 
  }, [portfolios]);

  const handleCsvDownload = async () => {
    setFileLoader(true);
    const csvData = await fetchDataForDownload();
    if (
      csvData[0]?.record?.length !== 0 &&
      csvData[0]?.record?.length !== undefined
    ) {
      const d = { ...getDownloadData(csvData) };
      const filteredHead = d?.thead
        ?.map((a) => {
          const foundObject = selectedRows
            ?.map((b) => {
              if (a?.text?.toString() === b?.toString()) {
                return a;
              }
            })
            ?.find((obj) => obj !== undefined);

          if (foundObject !== undefined) {
            return foundObject;
          }
        })
        ?.filter((obj) => obj !== undefined);
      const csvHead = filteredHead;
      const csvBody = d?.tbody;

      const head = [csvHead?.map((h) => h?.text || "") || []];
      const body =
        csvBody?.map((tr, i) => {
          return (
            csvHead?.map((th, j) => {
              const col = tr?.find((r) => r?.theadId === th?.id);
              return col?.text || "";
            }) || []
          );
        }) || [];

      const rows = [...head, ...body];
      const props = {
        rows: rows,
        fileName: "OverAll Sales Report",
      };
      CSVdownload(props);
      setFileLoader(false);
      if(authUser){
        const logProps1 = {
          action: 'Csv',
          event: `${pageName?.pathname?.replace("\\", " ")} page | Filters - ${combinedValues} | Csv Clicked | Export Status: Success | Data Status: Success`
        };
        UserLog(logProps1)
      }
    } else {
      setFileLoader(false);
      if(authUser){
        const logProps1 = {
          action: 'Csv',
          event: `${pageName?.pathname?.replace("\\", " ")} page | Filters - ${combinedValues} | Csv Clicked | Export Status: Success | Data Status: Empty`
        };
        UserLog(logProps1)
      }
    }
    setFileLoader(false);
  };

  const handlePdfDownload = async () => {
    setFileLoader(true);
    const pdfData = await fetchDataForDownload();
    if (
      pdfData[0]?.record?.length !== 0 &&
      pdfData[0]?.record?.length !== undefined
    ) {
      const d = { ...getDownloadData(pdfData) };
      const filteredHead = d?.thead
        ?.map((a) => {
          const foundObject = selectedRows
            ?.map((b) => {
              if (a?.text?.toString() === b?.toString()) {
                return a;
              }
            })
            ?.find((obj) => obj !== undefined);

          if (foundObject !== undefined) {
            return foundObject;
          }
        })
        ?.filter((obj) => obj !== undefined);
      const thead = filteredHead;
      const tbody = d?.tbody;

      const head = [thead?.map((h) => h?.text || "") || []];
      const body =
        tbody?.map((tr, i) => {
          return (
            thead?.map((th, j) => {
              const col = tr?.find((r) => r?.theadId === th?.id);
              return col?.text || "";
            }) || []
          );
        }) || [];

      const props = {
        head: head,
        body: body,
        fileName: "OverAll Sales Report",
        title: "OverAll Sales Report",
      };
      handleDownloadPDF(props);
      setFileLoader(false);
      if(authUser){
        const logProps1 = {
          action: 'Pdf',
          event: `${pageName?.pathname?.replace("\\", " ")} page | Filters - ${combinedValues} | Pdf Clicked | Export Status: Success | Data Status: Success`
        };
        UserLog(logProps1)
      }
    } else {
      setFileLoader(false);
      if(authUser){
        const logProps1 = {
          action: 'Pdf',
          event: `${pageName?.pathname?.replace("\\", " ")} page | Filters - ${combinedValues} | Pdf Clicked | Export Status: Success | Data Status: Empty`
        };
        UserLog(logProps1)
      }
    }
    setFileLoader(false);
  };

  const getTableData = () => {
    var tableHead: Thead[] = tHead;
    var tableBody: Tbody[][] = [];
    
    var yearData:OverallSalesRecord[] = [];
    YearlyFilteredData?.forEach((y, i) => {

      const subcatArray = y?.record
        ?.map((r, j) => r?.subcategory)
        ?.filter(getOnlyUnique);

      subcatArray?.forEach((cat, j) => {
        var tBody: Tbody[] = [];
        const scategoryIndex = subCategoryOption?.findIndex(
          (c) => c?.id !== undefined && c?.id?.toString() === cat?.toString()
        );
        const scategoryName = subCategoryOption[scategoryIndex]?.name;
        const scategoryId = cat;

        tBody?.push({
          id: "Sub Category" + scategoryId + j,
          theadId: "Sub Category",
          children: scategoryName,
          text: scategoryName,
        });
        yearDropDown?.sort()?.forEach((yr) => {
          let yrs = y?.record?.sort()?.map((r)=> r?.transyear);
          if (yrs?.includes(yr)) {
            let obj = y?.record?.find((f) => f?.transyear === yr);
            if(obj){
              yearData?.push(obj);
            }
          } else{
            yearData?.push({
                subcategory: scategoryId,
                sales: "",
                transyear: yr,
                yoygrowth: null,
                ytd: "",
                ytdpercentchange: null,
                averagesale: "",
                averagerate: 0,
                averageratepercent: ""
            });
          }
        });
        
        // y?.record?.sort()
        yearData?.filter(
            (r) =>
              r?.subcategory?.toString() === scategoryId?.toString() &&
              r !== undefined
          )
          ?.forEach((r, i) => {
            tBody?.push(
              {
                id: r?.transyear?.toString() + i,
                children: r?.sales,
                text: r?.sales,
                theadId: r?.transyear?.toString(),
              },
              {
                id: r?.ytd !== undefined && r?.ytd !== null ? r?.ytd : "",
                children: r?.ytd,
                text: r?.ytd !== undefined && r?.ytd !== null ? r?.ytd : "",
                theadId: r?.transyear + " YTD",
              },
              {
                id: r?.transyear?.toString() + "% Change" + i,
                children:
                  r?.ytdpercentchange !== undefined &&
                  r?.ytdpercentchange !== null ? (
                    <div
                      className={
                        r?.ytdpercentchange !== undefined &&
                        r?.ytdpercentchange !== null
                          ? r?.ytdpercentchange > 0
                            ? "p-mtd text-success text-truncate"
                            : r?.ytdpercentchange < 0
                            ? "n-mtd text-danger text-truncate"
                            : ""
                          : ""
                      }
                    >
                      {r?.ytdpercentchange > 0 ? (
                        <i>
                          {" "}
                          <FontAwesomeIcon icon={faCaretUp} size="xl" />{" "}
                          {r?.ytdpercentchange?.toString() || ""}
                        </i>
                      ) : r?.ytdpercentchange < 0 ? (
                        <i>
                          {" "}
                          <FontAwesomeIcon icon={faCaretDown} size="xl" />{" "}
                          {r?.ytdpercentchange?.toString() || ""}
                        </i>
                      ) : (
                        r?.ytdpercentchange?.toString()
                      )}
                    </div>
                  ) : r?.ytdpercentchange === null ? (
                    "-"
                  ) : (
                    ""
                  ),
                text: r?.ytdpercentchange?.toString(),
                theadId: r?.transyear + " YTD % Change",
              }
            );
          });
        if (tBody?.length > 1) tableBody?.push(tBody);
      });
    });

    return { tbody: tableBody, thead: tableHead };
  };

  useEffect(() => {
    const header = tHead
      ?.map((a) => a?.text)
      ?.filter((id): id is string => typeof id === "string" && id !== undefined);
    setSelectedRows(header);
  }, []);

  const portfolio = useMemo<number[]>(() => {
    return portfolios !== "" ? [parseInt(portfolios)] : [];
  }, [portfolios]);
  const site = useMemo<string[]>(() => {
    if (authUserSiteId) {
      return authUserSiteId?.split(",")?.map((s) => {
        return s?.toString();
      });
    } else {
      return [];
    }
  }, [authUserSiteId]);

  const category = useMemo<number[]>(() => {
    return categories !== "" ? [parseInt(categories)] : [];
  }, [categories]);
  const subCategory = useMemo<number[]>(() => {
    return subCategories?.map((p) => {
      return p?.value;
    });
  }, [subCategories]);
  useEffect(()=>{
    let pid =portfolioData[0] && portfolioData[0]?.id? portfolioData[0].id?.toString(): "";
    setPortfolios(selectedOption.portfolio ? selectedOption.portfolio : pid);
    const b = portfolioOption.filter((a)=>a?.id?.toString()===pid?.toString())
    if (b?.length > 0 && b[0]?.id !== undefined) {
      const val: DropDownOption = {
        value: b[0]?.id, 
        label: b[0]?.name,
        imgUrl: b[0]?.logourl, 
      };
      setPortfolioDrop([val]);
    }
},[portfolioOption,selectedOption])

  useEffect(() => {
    let sid = siteData[0] && siteData[0]?.id ? siteData[0]?.id?.toString() : "";
    setSites(selectedOption?.site ? selectedOption?.site : sid);
  }, [siteData, selectedOption]);

  useEffect(() => {
    var range = [];
    var num = Math?.ceil(length / limit);

    for (let i = 1; i <= num; i++) {
      range?.push(i);
    }
    setRange(range);
  }, [length, limit]);

  useEffect(()=>{
    setLocation(selectedOption.location? selectedOption.location:'')
    const b = localityOption.filter((a)=>a?.area?.toString()===selectedOption.location?.toString())
    if (b?.length > 0 && b[0]?.id !== undefined) {
      const val: DropDownOption = {
        value: b[0]?.id, 
        label: b[0]?.area,
        imgUrl:'', 
      };
      setLocationDrop([val]);
    }
    },[localityOption,selectedOption])

    useEffect(()=>{
      setCategories(selectedOption.category ? selectedOption.category:'');
      const b = categoryOption.filter((a)=>a?.id?.toString()===selectedOption.category?.toString())
      if (b?.length > 0 && b[0]?.id !== undefined) {
        const val: DropDownOption = {
          value: b[0]?.id, 
          label: b[0]?.name,
          imgUrl:'', 
        };
        setCatDrop([val]);
      }
      },[categoryOption,selectedOption])




  useEffect(() => {
    if (
      selectedOption?.subCategories &&
      selectedOption?.subCategories?.length !== 0
    )
      setSubCategories(selectedOption?.subCategories);
  }, [selectedOption]);


  useEffect(() => {
    if (sites !== ""&&selectedOption.isOutletUser===true) {
      let siteCurrencyObject = siteOption?.find(
        (s) => s?.id?.toString() === sites?.toString()
      );
      setValueIn(
        siteCurrencyObject?.currencycode +
          " | " +
          siteCurrencyObject?.valuecaption
      );
    } 
    else if(portfolios !== ""&&selectedOption.isOutletUser===false){
      let portfolioCurrencyObject = portfolioOption?.find(
        (s) => s?.id?.toString() === portfolios?.toString()
      );
      setValueIn(
        portfolioCurrencyObject?.reportingcurrency +
          " | " +
          portfolioCurrencyObject?.valuecaption
      );
    }
    else setValueIn("");
  }, [sites, siteOption,selectedOption]);

  useEffect(() => {
    if (overAllSalesData?.length > 0)
      setLength(overAllSalesData[0]?.count ? overAllSalesData[0]?.count : 0);
  }, [overAllSalesData]);

  const colors = [
    COLORS?.COLOR1,
    COLORS?.COLOR2,
    COLORS?.COLOR3,
    COLORS?.COLOR4,
    COLORS?.COLOR5,
    COLORS?.COLOR6,
    COLORS?.COLOR7,
    COLORS?.COLOR8,
  ];

  useEffect(()=>{
    if(authUser){
    const logProps1 = {
      action:`Visit`,
      event: `${pageName?.pathname?.replace("\\", " ")} page | Visited `
    };
    UserLog(logProps1)
  }
  },[])

  return (
    <UserLayout>
      <div className="card background-transparent p-1">
        <div className="row">
          <div
            className="col-lg-4 wow fadeInLeft ps-0"
          >
            <h4 className="b-title">OverAll Sales By Trade Category</h4>
          </div>

          <div className="col-lg-8 wow fadeInRight">
            <div className="row">
              <div className="col-sm-3 col-md-4 col-lg-3">
                {/* <FormInput2
                  name="Portfolio"
                  label=""
                  labelClassName="required"
                  value={portfolios}
                  onChange={handlePortfolioChange}
                  placeholder="Select"
                  containerClass="mb-1"
                  type="select"
                >
                  <option value="">Select</option>
                  {portfolioData?.map((p, i) => {
                    return (
                      <Option value={p?.id} key={i}>
                        {p?.name}
                      </Option>
                    );
                  })}
                </FormInput2> */}
                 {/* <SingleSelect
                  options={[
                    defaultPortfolio,
                    ...portfolioData?.sort(function (a, b) {
                      if (a?.name < b?.name) {
                        return -1;
                      }
                      if (a?.name > b?.name) {
                        return 1;
                      }
                      return 0;
                    })?.map((s, i) => {
                      return (
                        {
                          value: s?.id ? s?.id?.toString() : '',
                          label: s?.name,
                          imgUrl: s?.logourl,
                        }
                      );
                    })
                  ]}
                  label=""
                  selectedOptions={portfoliosOption}
                  setSelectedOptions={handlePortfolioChange}
                  // disabled={disableSite}
                  disabled={disableLocality}
                  placeHolder="Select Portfolio"
                  // filterText=""
                /> */}
                 <SingleSelect1
                      options={portfolioData.map((c, i) => {
                        return {
                          value: c?.id?c?.id:0,
                          label: c?.name?c?.name:'',
                          imgUrl: "",
                        };
                      })}
                      selectedOptions={portfolioDrop}
                      setSelectedOptions={handlePortfolioDrop}
                      placeHolder="Select Portfolio"
                      onClear={handleClearPortfolio}
                      disabled = {disableLocality}
                    />
              </div>
              
              <div className="col-sm-3 col-md-4 col-lg-3">
                {/* <FormInput2
                  name="Location"
                  label=""
                  labelClassName="required"
                  value={location}
                  onChange={handleLocationChange}
                  placeholder="Select Locality"
                  containerClass="mb-1"
                  disabled={disableLocality}
                  type="select"
                >
                  <option value="">Select Locality</option>
                  {localityOption?.map((s, i) => {
                    return (
                      <Option value={s?.area} key={i}>
                        {s?.area}
                      </Option>
                    );
                  })}
                </FormInput2> */}
                 {/* <SingleSelect
                  options={[
                    defaultLocation,
                    ...localityOption?.sort(function (a, b) {
                      if (a?.area < b?.area) {
                        return -1;
                      }
                      if (a?.area > b?.area) {
                        return 1;
                      }
                      return 0;
                    })?.map((s, i) => {
                      return (
                        {
                          value: s?.id ? s?.id?.toString() : '',
                          label: s?.area,
                          imgUrl: '',
                        }
                      );
                    })
                  ]}
                  label=""
                  selectedOptions={locationOption}
                  setSelectedOptions={handleLocationChange}
                  disabled={disableLocality}
                  placeHolder="Select Portfolio"
                  // filterText=""
                /> */}
                 <SingleSelect1
                      options={localityOption.map((c, i) => {
                        return {
                          value: c?.id?c?.id:0,
                          label: c?.area?c?.area:'',
                          imgUrl: "",
                        };
                      })}
                      selectedOptions={locationDrop}
                      setSelectedOptions={handleLocationDrop}
                      placeHolder="Select Locality"
                      onClear={handleClearLocation}
                      disabled = {disableLocality}
                    />
              </div>
              <div className="col-sm-3 col-md-4 col-lg-3">
                {/* <FormInput2
                  name="Trade Category"
                  label=""
                  labelClassName="required"
                  value={categories}
                  onChange={handleCategoryChange}
                  placeholder="Select Trade Category"
                  containerClass="mb-1"
                  type="select"
                >
                  <option value="">Select Trade Category</option>
                  {categoryOption?.map((s, i) => {
                    return (
                      <Option value={s?.id} key={i}>
                        {s?.name}
                      </Option>
                    );
                  })}
                </FormInput2> */}
                     {/* <SingleSelect
                  options={[
                    defaultTradeCategory,
                    ...categoryOption?.sort(function (a, b) {
                      if (a?.name < b?.name) {
                        return -1;
                      }
                      if (a?.name > b?.name) {
                        return 1;
                      }
                      return 0;
                    })?.map((s, i) => {
                      return (
                        {
                          value: s?.id ? s?.id?.toString() : '',
                          label: s?.name,
                          imgUrl: '',
                        }
                      );
                    })
                  ]}
                  label=""
                  selectedOptions={categoriesOption}
                  setSelectedOptions={handleCategoryChange}
                  // disabled={disableSite}
                  disabled={disableLocality}
                  placeHolder="Select Trade Category"
                  // filterText=""
                /> */}
                 <SingleSelect1
                      options={categoryOption.map((c, i) => {
                        return {
                          value: c?.id?c?.id:0,
                          label: c?.name?c?.name:'',
                          imgUrl: "",
                        };
                      })}
                      selectedOptions={catDrop}
                      setSelectedOptions={handleCatDrop}
                      placeHolder="Select Trade Category"
                      onClear={handleClearCat}
                      disabled = {disableLocality}
                    />
              </div>
              <div className="col-sm-3 col-md-4 col-lg-3">
                <MultiSelect
                  options={subCategoryFilteredByCategory?.map((sc) => {
                    return {
                      value: sc?.id ? sc?.id : 0,
                      label: sc?.name,
                      imgUrl: "",
                    };
                  })}
                  label=""
                  selectedOptions={subCategories}
                  setSelectedOptions={setSubCategories}
                  disabled={disableLocality}
                  placeHolder="All Trade Sub Categories"
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      {loader ? (
        <Loader />
      ) : (
        <>
          <div className="row">
            <div className="col-12">
              <div className="card mt-3 widget_div" onMouseEnter={() => setButtonVisible(true)} onMouseLeave={() => setButtonVisible(false)}>
              {(<button type="button" className="expandIconBtn" onClick={showExpandModalHandler} ><i><FontAwesomeIcon icon={faWindowMaximize} size="lg" title="Open in Popup"/></i></button>)}
                <div className="card-body">
                  <div className="row">
                    <div className="col-lg-12 col-sm-12">
                      <div className="float-start">
                        <h5 className="header-title">
                          <img src={chartIcon} alt="icon"></img>
                          Overall Sales by {categoryName}
                        </h5>
                      </div>
                      <div className="float-end">
                        <button
                          type="button"
                          className="btn btn-primary"
                          onClick={handleApply}
                        >
                          Apply
                        </button>
                      </div>
                    </div>
                  </div>
                  <hr className="m-0 my-2 p-0 hr" />
                  {YearlyFilteredData[0]?.record?.length > 0 &&
                    YearlyFilteredData[0]?.record[0] !== undefined && (
                      <div>{"*Value in " + valueIn}</div>
                    )}

                  {YearlyFilteredData[0]?.record?.length > 0 &&
                  YearlyFilteredData[0]?.record[0] !== undefined ? (
                    <BarChart1
                      datasets={(() => {
                        let ydata: { name: string; data: number[] }[] = [];
                        YearlyFilteredData?.forEach((y, i) => {
                          subCategoryOption?.forEach((s) => {
                            let data2: number[] = [];
                            let d1 = y?.record?.filter(
                              (sw) =>
                                sw?.subcategory?.toString() === s?.id?.toString()
                            );
                            yearDropDown?.sort()?.forEach((yr) => {
                              var yrs = d1?.map((d) => d?.transyear);
                              if (yrs?.includes(yr)) {
                                let obj = d1?.find((f) => f?.transyear === yr);
                                data2?.push(
                                  obj?.sales ? parseFloat(obj?.sales) : 0
                                );
                              } else data2?.push(0);
                            });

                            //isemptyarray check
                            let isEmpty: string[] = [];
                            data2?.forEach((d2) => {
                              if (d2 !== 0) isEmpty?.push("true");
                              else isEmpty?.push("false");
                            });

                            if (data2?.length > 0 && isEmpty?.includes("true")) {
                              ydata?.push({
                                name: s?.name,
                                data: data2,
                              });
                            }
                          });
                        });
                        return ydata;
                      })()}
                      labels={
                        yearDropDown?.map((y) => y?.toString())?.sort() || []
                      }
                      colors={[
                        colors[0],
                        colors[1],
                        colors[2],
                        colors[3],
                        colors[4],
                        colors[5],
                        colors[6],
                        colors[7],
                      ]}
                      title={(() => {
                        const txt = `Overall Sales By Tradecategory`;
                        return txt;
                      })()}
                      subtitle={(() => {
                        let subtxt: string[] = [];
                        let subtxt1 = `\u3010Company\u3011: ${pname}`;
                        let subtxt2 = ``;
                        if (location) {
                          subtxt1 += `\u3010Locality\u3011: ${location}`;
                        }
                        if (categories) {
                          subtxt1 += ` \n\u3010TradeCategory\u3011: ${categoryName}`;
                        }
                        if (subCategories?.length > 0) {
                          let subcatNameArr: string[] = [];
                          subCategories?.map((s) => {
                            subcatNameArr?.push(s?.label);
                          });
                          let subcatName = subcatNameArr?.join(", ");
                          subtxt2 += ` \u3010TradeSubcategory\u3011: ${subcatName}`;
                        }
                        subtxt1 += ` \u3010Period\u3011: ${yearDropDown[0]} - ${yearDropDown[2]}`;
                        subtxt?.push(subtxt1);
                        subtxt?.push(subtxt2);
                        return subtxt;
                      })()}
                      exportfilename={"overallSalesByTradeCategory"}
                    />
                  ) : (
                    <div className="d-flex flex-column justify-content-center align-items-center col-12 emptychart-container  ">
                      <p className="empty-data">No Records Found</p>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-12">
              <div className="card mt-3">
                <div className="card-body">
                  <div className="row mb-1">
                    <div className="col-lg-6 col-sm-12">
                      <div className="float-start">
                        <h5 className="header-title mt-1">
                          <img src={tableIcon} alt="icon"></img>
                          Overall Sales by {categoryName}
                        </h5>
                      </div>
                    </div>
                    <div className="col-lg-6 col-sm-12">
                      <div className="float-end hstack gap-1">
                        
                      </div>
                    </div>
                  </div>

                  <TransactionTable
                    data={[]}
                    {...getTableData()}
                    title=""
                    downloadCSV={true}
                    downloadPDF={true}
                    fileName={"OverAll Sales by " + categoryName}
                    limit={limit}
                    setOffset={setOffset}
                    onClickCSV={handleCsvDownload}
                    onClickPDF={handlePdfDownload}
                    range={range}
                    valueInString={valueIn}
                    fLoader={fileLoader}
                    onSelectedRowsChange={handleSelectedRowsChange}
                    onPageNoChange={pag && (offset > 0||condition) ? handleApply : undefined}
                    handlePag={pag ? handlePag : undefined}
                    onOffchange={handleOff}
                  />
                </div>
              </div>
            </div>
          </div>
          
          {showModal && (
              <Modal
              headerText={`Overall Sales by ${categoryName}`}
              visible={showModal}
              onClose={() => setShowModal(false)}
              size="xl"
              centered
              classname="widget-container-single" > 
                <div className="card-body">
                  

                  {YearlyFilteredData[0]?.record?.length > 0 &&
                  YearlyFilteredData[0]?.record[0] !== undefined ? (
                    <BarChart1
                      datasets={(() => {
                        let ydata: { name: string; data: number[] }[] = [];
                        YearlyFilteredData?.forEach((y, i) => {

                          subCategoryOption?.forEach((s) => {
                            let data2: number[] = [];
                            let d1 = y?.record?.filter(
                              (sw) =>
                                sw?.subcategory?.toString() === s?.id?.toString()
                            );
                            yearDropDown?.sort()?.forEach((yr) => {
                              var yrs = d1?.map((d) => d?.transyear);
                              if (yrs?.includes(yr)) {
                                let obj = d1?.find((f) => f?.transyear === yr);
                                data2?.push(
                                  obj?.sales ? parseFloat(obj?.sales) : 0
                                );
                              } else data2?.push(0);
                            });

                            //isemptyarray check
                            let isEmpty: string[] = [];
                            data2?.forEach((d2) => {
                              if (d2 !== 0) isEmpty?.push("true");
                              else isEmpty?.push("false");
                            });

                            if (data2?.length > 0 && isEmpty?.includes("true")) {
                              ydata?.push({
                                name: s?.name,
                                data: data2,
                              });
                            }
                          });
                        });
                        return ydata;
                      })()}
                      labels={
                        yearDropDown?.map((y) => y?.toString())?.sort() || []
                      }
                      colors={[
                        colors[0],
                        colors[1],
                        colors[2],
                        colors[3],
                        colors[4],
                        colors[5],
                        colors[6],
                        colors[7],
                      ]}
                      title={(() => {
                        const txt = `Overall Sales By Tradecategory`;
                        return txt;
                      })()}
                      subtitle={(() => {
                        let subtxt: string[] = [];
                        let subtxt1 = `\u3010Company\u3011: ${pname}`;
                        let subtxt2 = ``;
                        if (location) {
                          subtxt1 += `\u3010Locality\u3011: ${location}`;
                        }
                        if (categories) {
                          subtxt1 += ` \n\u3010TradeCategory\u3011: ${categoryName}`;
                        }
                        if (subCategories?.length > 0) {
                          let subcatNameArr: string[] = [];
                          subCategories?.map((s) => {
                            subcatNameArr?.push(s?.label);
                          });
                          let subcatName = subcatNameArr?.join(", ");
                          subtxt2 += ` \u3010TradeSubcategory\u3011: ${subcatName}`;
                        }
                        subtxt1 += ` \u3010Period\u3011: ${yearDropDown[0]} - ${yearDropDown[2]}`;
                        subtxt?.push(subtxt1);
                        subtxt?.push(subtxt2);
                        return subtxt;
                      })()}
                      exportfilename={"overallSalesByTradeCategory"}
                    />
                  ) : (
                    <div className="d-flex flex-column justify-content-center align-items-center col-12 emptychart-container  ">
                      <p className="empty-data">No Records Found</p>
                    </div>
                  )}
                </div>

          </Modal>)}

        </>
      )}
    </UserLayout>
  );
};
export default SalesOverAllSalesReport;
