import React from "react";
import { useSelector } from "react-redux";
import { StoreState } from "../../redux/store";
import HorizontalBarChart from "../custom/components/charts/apex/HorizontalBarChart";
import { PortfolioType } from "../../redux/actions/portfolioActions";
import { COLORS } from "../custom/constants";

interface Props {  
    salesRankingData:{
        topBrands: {
            portfolio: number;
            record: {
                brand: number;
                sales: string;
                transyear?: number;
                month?:number
            }[];
            count?: number;
        }[];
        bottomBrands: {
            portfolio: number;
            record: {
                brand: number;
                sales: string;
                transyear?: number;
                month?:number
            }[];
            count?: number;
        }[];
    }[];
    year: Date;
    getSubtitle: string[];
    filteredBrand: {
        id?: number
        name: string
        url: string
        logopath: string
        logourl: string
        companyid: number
    }[]
}
export const SalesTop10Brands: React.FC<Props> = (props: Props) => {
    const { salesRankingData, year, getSubtitle, filteredBrand } = props;
    const portfolioOption = useSelector<StoreState, PortfolioType[]>(
        (state) => state?.portfolio
    );
    const colors = [
        COLORS?.COLOR1,
        COLORS?.COLOR2,
        COLORS?.COLOR3,
        COLORS?.COLOR4,
        COLORS?.COLOR5,
        COLORS?.COLOR6,
        COLORS?.COLOR7,
        COLORS?.COLOR8,
    ];
    return(
        <>
        <HorizontalBarChart
        datasets={(() => {
        let ydata: { name: string; data: number[] }[] =
            [];
        salesRankingData[0]?.topBrands?.forEach(
            (y, i) => {
            const index = portfolioOption?.findIndex(
                (p) =>
                p?.id !== undefined &&
                p?.id?.toString() ===
                    y?.portfolio?.toString()
            );
            const name = portfolioOption[index]?.name;

            y?.record
                ?.sort((a, b) => {
                return (
                    parseFloat(b?.sales)! -
                    parseFloat(a?.sales)!
                );
                })
                ?.forEach((record) => {
                let bname = filteredBrand?.find(
                    (b) =>
                    b?.id?.toString() ===
                    record?.brand?.toString()
                )?.name;

                ydata?.push({
                    name: bname!,
                    data: [
                    record?.sales
                        ? parseFloat(record?.sales)
                        : 0,
                    ],
                });
                });
            }
        );
        return ydata;
        })()}
        labels={(() => {
        let label: string[] = [];
        salesRankingData[0]?.topBrands?.forEach(
            (y, i) => {
            const index = portfolioOption?.findIndex(
                (p) =>
                p?.id !== undefined &&
                p?.id?.toString() ===
                    y?.portfolio?.toString()
            );
            const name = portfolioOption[index]?.name;
            y?.record
                ?.sort((a, b) => {
                return (
                    parseFloat(b?.sales)! -
                    parseFloat(a?.sales)!
                );
                })
                ?.forEach((record) => {
                let bname = filteredBrand?.find(
                    (b) =>
                    b?.id?.toString() ===
                    record?.brand?.toString()
                )?.name;

                label?.push(bname ? bname : "");
                });
            }
        );
        return label;
        })()}
        colors={[
        colors[0],
        colors[1],
        colors[2],
        colors[3],
        colors[4],
        colors[5],
        colors[6],
        colors[7],
        ]}
        title={(() => {
        const txt = `Sales Ranking Top 10 Brands-Yearly`;
        return txt;
        })()}
        subtitle={(() => {
        let subtxt: string[] = [];
        let subtxt1 = getSubtitle?.length>0 ? getSubtitle[0] : "";
        let subtxt2 = getSubtitle?.length>0 ? getSubtitle[1] : "";
        subtxt?.push(subtxt1);
        subtxt?.push(subtxt2);
        return subtxt;
        })()}
        exportfilename={"SalesRanking-Top10Brands-Yearly"}
    />
        </>
    )
}
export const SalesBottom10Brands: React.FC<Props> = (props: Props) => {
    const { salesRankingData, year, getSubtitle, filteredBrand } = props;
    const portfolioOption = useSelector<StoreState, PortfolioType[]>(
        (state) => state?.portfolio
    );
    const colors = [
        COLORS?.COLOR1,
        COLORS?.COLOR2,
        COLORS?.COLOR3,
        COLORS?.COLOR4,
        COLORS?.COLOR5,
        COLORS?.COLOR6,
        COLORS?.COLOR7,
        COLORS?.COLOR8,
    ];
    return(
        <>
        <HorizontalBarChart
        datasets={(() => {
            let ydata: { name: string; data: number[] }[] =
            [];
            salesRankingData[0]?.bottomBrands?.forEach(
            (y, i) => {
                const index = portfolioOption?.findIndex(
                (p) =>
                    p?.id !== undefined &&
                    p?.id?.toString() ===
                    y?.portfolio?.toString()
                );
                const name = portfolioOption[index]?.name;

                y?.record
                ?.sort((a, b) => {
                    return (
                    parseFloat(a?.sales)! -
                    parseFloat(b?.sales)!
                    );
                })
                ?.forEach((record) => {
                    let bname = filteredBrand?.find(
                    (b) =>
                        b?.id?.toString() ===
                        record?.brand?.toString()
                    )?.name;

                    ydata?.push({
                    name: bname!,
                    data: [
                        record?.sales
                        ? parseFloat(record?.sales)
                        : 0,
                    ],
                    });
                });
            }
            );
            return ydata;
        })()}
        labels={(() => {
            let label: string[] = [];
            salesRankingData[0]?.bottomBrands?.forEach(
            (y, i) => {
                const index = portfolioOption?.findIndex(
                (p) =>
                    p?.id !== undefined &&
                    p?.id?.toString() ===
                    y?.portfolio?.toString()
                );
                const name = portfolioOption[index]?.name;
                y?.record
                ?.sort((a, b) => {
                    return (
                    parseFloat(a?.sales)! -
                    parseFloat(b?.sales)!
                    );
                })
                ?.forEach((record) => {
                    let bname = filteredBrand?.find(
                    (b) =>
                        b?.id?.toString() ===
                        record?.brand?.toString()
                    )?.name;

                    label?.push(bname ? bname : "");
                });
            }
            );
            return label;
        })()}
        colors={[
            colors[0],
            colors[1],
            colors[2],
            colors[3],
            colors[4],
            colors[5],
            colors[6],
            colors[7],
        ]}
        title={(() => {
            const txt = `Sales Ranking Bottom 10 Brands - Yearly`;
            return txt;
        })()}
        subtitle={(() => {
            let subtxt: string[] = [];
            let subtxt1 = getSubtitle?.length>0 ? getSubtitle[0] : "";
            let subtxt2 = getSubtitle?.length>0 ? getSubtitle[1] : "";
   
            subtxt?.push(subtxt1);
            subtxt?.push(subtxt2);
            return subtxt;
        })()}
        exportfilename={"SalesRanking-Bottom10Brands-Yearly"}
        />
        </>
    )
}

interface MonthlyProps {  
    salesRankingMonthlyData:{
        topBrands: {
            portfolio: number;
            record: {
                brand: number;
                sales: string;
                transyear?: number;
                month?:number
            }[];
            count?: number;
        }[];
        bottomBrands: {
            portfolio: number;
            record: {
                brand: number;
                sales: string;
                transyear?: number;
                month?:number
            }[];
            count?: number;
        }[];
    }[];
    yearAndMonth: Date;
    getSubtitle: string[];
    filteredBrand: {
        id?: number
        name: string
        url: string
        logopath: string
        logourl: string
        companyid: number
    }[]
}
export const SalesTop10BrandsMonthwise: React.FC<MonthlyProps> = (props: MonthlyProps) => {
    const { salesRankingMonthlyData, yearAndMonth, getSubtitle, filteredBrand } = props;
    const portfolioOption = useSelector<StoreState, PortfolioType[]>(
        (state) => state?.portfolio
    );
    const colors = [
        COLORS?.COLOR1,
        COLORS?.COLOR2,
        COLORS?.COLOR3,
        COLORS?.COLOR4,
        COLORS?.COLOR5,
        COLORS?.COLOR6,
        COLORS?.COLOR7,
        COLORS?.COLOR8,
    ];
    return(
        <>
        <HorizontalBarChart
        datasets={(() => {
        let ydata: { name: string; data: number[] }[] =
            [];
        salesRankingMonthlyData[0]?.topBrands?.forEach(
            (y, i) => {
            const index = portfolioOption?.findIndex(
                (p) =>
                p?.id !== undefined &&
                p?.id?.toString() ===
                    y?.portfolio?.toString()
            );
            const name = portfolioOption[index]?.name;

            y?.record
                ?.sort((a, b) => {
                return (
                    parseFloat(b?.sales)! -
                    parseFloat(a?.sales)!
                );
                })
                ?.forEach((record) => {
                let bname = filteredBrand?.find(
                    (b) =>
                    b?.id?.toString() ===
                    record?.brand?.toString()
                )?.name;

                ydata?.push({
                    name: bname!,
                    data: [
                    record?.sales
                        ? parseFloat(record?.sales)
                        : 0,
                    ],
                });
                });
            }
        );
        return ydata;
        })()}
        labels={(() => {
        let label: string[] = [];
        salesRankingMonthlyData[0]?.topBrands?.forEach(
            (y, i) => {
            const index = portfolioOption?.findIndex(
                (p) =>
                p?.id !== undefined &&
                p?.id?.toString() ===
                    y?.portfolio?.toString()
            );
            const name = portfolioOption[index]?.name;
            y?.record
                ?.sort((a, b) => {
                return (
                    parseFloat(b?.sales)! -
                    parseFloat(a?.sales)!
                );
                })
                ?.forEach((record) => {
                let bname = filteredBrand?.find(
                    (b) =>
                    b?.id?.toString() ===
                    record?.brand?.toString()
                )?.name;

                label?.push(bname ? bname : "");
                });
            }
        );
        return label;
        })()}
        colors={[
        colors[0],
        colors[1],
        colors[2],
        colors[3],
        colors[4],
        colors[5],
        colors[6],
        colors[7],
        ]}
        title={(() => {
        const txt = `Sales Ranking Top 10 Brands-Monthly`;
        return txt;
        })()}
        subtitle={(() => {
            let subtxt: string[] = [];
            let subtxt1 = getSubtitle?.length>0 ? getSubtitle[0] : "";
            let subtxt2 = getSubtitle?.length>0 ? getSubtitle[1] : "";
         
            subtxt1 += ` \u3010Month\u3011: ${yearAndMonth?.getMonth()+1}`;
            subtxt?.push(subtxt1);
            subtxt?.push(subtxt2);
            return subtxt;
        })()}
        exportfilename={"SalesRanking-Top10Brands-Monthly"}
    />
        </>
    )
}

export const SalesBottom10BrandsMonthwise: React.FC<MonthlyProps> = (props: MonthlyProps) => {
    const { salesRankingMonthlyData, yearAndMonth, getSubtitle, filteredBrand } = props;
    const portfolioOption = useSelector<StoreState, PortfolioType[]>(
        (state) => state?.portfolio
    );
    const colors = [
        COLORS?.COLOR1,
        COLORS?.COLOR2,
        COLORS?.COLOR3,
        COLORS?.COLOR4,
        COLORS?.COLOR5,
        COLORS?.COLOR6,
        COLORS?.COLOR7,
        COLORS?.COLOR8,
    ];
    return(
        <>
        <HorizontalBarChart
            datasets={(() => {
            let ydata: { name: string; data: number[] }[] =
                [];
            salesRankingMonthlyData[0]?.bottomBrands?.forEach(
                (y, i) => {
                const index = portfolioOption?.findIndex(
                    (p) =>
                    p?.id !== undefined &&
                    p?.id?.toString() ===
                        y?.portfolio?.toString()
                );
                const name = portfolioOption[index]?.name;

                y?.record
                    ?.sort((a, b) => {
                    return (
                        parseFloat(a?.sales)! -
                        parseFloat(b?.sales)!
                    );
                    })
                    ?.forEach((record) => {
                    let bname = filteredBrand?.find(
                        (b) =>
                        b?.id?.toString() ===
                        record?.brand?.toString()
                    )?.name;

                    ydata?.push({
                        name: bname!,
                        data: [
                        record?.sales
                            ? parseFloat(record?.sales)
                            : 0,
                        ],
                    });
                    });
                }
            );
            return ydata;
            })()}
            labels={(() => {
            let label: string[] = [];
            salesRankingMonthlyData[0]?.bottomBrands?.forEach(
                (y, i) => {
                const index = portfolioOption?.findIndex(
                    (p) =>
                    p?.id !== undefined &&
                    p?.id?.toString() ===
                        y?.portfolio?.toString()
                );
                const name = portfolioOption[index]?.name;
                y?.record
                    ?.sort((a, b) => {
                    return (
                        parseFloat(a?.sales)! -
                        parseFloat(b?.sales)!
                    );
                    })
                    ?.forEach((record) => {
                    let bname = filteredBrand?.find(
                        (b) =>
                        b?.id?.toString() ===
                        record?.brand?.toString()
                    )?.name;

                    label?.push(bname ? bname : "");
                    });
                }
            );
            return label;
            })()}
            colors={[
            colors[0],
            colors[1],
            colors[2],
            colors[3],
            colors[4],
            colors[5],
            colors[6],
            colors[7],
            ]}
            title={(() => {
            const txt = `Sales Ranking Bottom 10 Brands - Monthly`;
            return txt;
            })()}
            subtitle={(() => {
                let subtxt: string[] = [];
                let subtxt1 = getSubtitle?.length>0 ? getSubtitle[0] : "";
                let subtxt2 = getSubtitle?.length>0 ? getSubtitle[1] : "";
         
                subtxt1 += ` \u3010Month\u3011: ${yearAndMonth?.getMonth()+1}`;
                subtxt?.push(subtxt1);
                subtxt?.push(subtxt2);
                return subtxt;
            })()}
            exportfilename={"SalesRanking-Bottom10Brands-Monthly"}
        />
        </>
    )
}