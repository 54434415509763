import React, { useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { PortfolioDashboardList, PortfolioDashboardType } from "../../config";
import api, { APIResponse, getAxiosRequestConfig } from "../../config/api";
import { YearOptions } from "../../config/functions";
import { PortfolioType } from "../../redux/actions/portfolioActions";
import { AuthUserType } from "../../redux/actions/authUserActions";
import { StoreState } from "../../redux/store";
import { KpiViewList, getOnlyUnique } from "../commonWidget";
import Widget1 from "../custom/components/Widget1";
import { COLORS } from "../custom/constants";
// import { BrandType } from "../../redux/actions/brandActions";
import { outletType } from "../../redux/actions/outletActions";
import SplineAreaChart from "../custom/components/charts/apex/SplineAreaChart";
import RetailPerformanceTable from "../custom/components/RetailPerformanceTable";
import Modal from "../Modal";

interface RecordsForWeek {
  sales: string;
  bills: number;
  transyear: number;
  transmonth?: number;
  weeknumber: number;
  centerid: number;
  brandid: number;
}

interface RecordsForMonth {
  sales: string;
  bills: number;
  transyear: number;
  centerid: number;
  brandid: number;
  transmonth: number;
}
interface RetailRecordsForYear {
  sales: string;
  bills: number;
  transyear: number;
  centerid: number;
  brandid: number;
}
interface WeeklyRecord {
  portfolio: number;
  record: RecordsForWeek[];
}
interface MonthlyRecord {
  portfolio: number;
  record: RecordsForMonth[];
}
interface YearlyRecord {
  portfolio: number;
  record: RetailRecordsForYear[];
}

interface Props {
  portfolioId: number;
  brandId: number;
  outletId: string[];
  tradeCategory?: number[];
  tradeSubCategory?: number[];
  country?: number[];
  region?: number[];
  state?: number[];
  city?: number[];
  expandState: React.Dispatch<React.SetStateAction<boolean>>;
  commonApiEnabler?:boolean;
  valueCapStr?: string;
}

const OutletTransaction: React.FC<Props> = (props: Props) => {
  const authUser = useSelector<StoreState, AuthUserType | null>(
    (state) => state?.authUser
  );
  const authToken = authUser?.["auth-token"]!;
  const config = getAxiosRequestConfig(authToken);

  // const brandList = useSelector<StoreState, Array<BrandType>>(
  //   (state) => state?.brand
  // );
  const portfolioOption = useSelector<StoreState, PortfolioType[]>(
    (state) => state?.portfolio
  );

  // const brandOption = brandList?.filter(
  //   (a) => a?.id?.toString() === authUser?.brandId?.toString()
  // );

  const outletList = useSelector<StoreState, Array<any>>(
    (state) => state?.outlet
  );

  const [weeklyData, setWeeklyData] = useState<WeeklyRecord[]>([]);
  const [yearlyData, setYearlyData] = useState<YearlyRecord[]>([]);
  const [monthlyData, setMonthlyData] = useState<MonthlyRecord[]>([]);

  const [selection, setSelection] = useState<PortfolioDashboardType>(
    PortfolioDashboardList?.MOM
  );
  const [view, setView] = useState<string>(KpiViewList?.CHART);
  const [valueIn, setValueIn] = useState<string>("");
  const [showTableModal, setShowTableModal] = useState<boolean>(false);

  const monthsArray = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];
  // const weeekLable = ["1", "2", "3"];

  // const currentYear = new Date()?.getFullYear();
  // const [year, setYear] = useState<string>(currentYear?.toString());

  const yearList = YearOptions();
  const showExpandHandler = () => {
    props?.expandState((prevState) => !prevState)
  };
  const weekHandler = () => {
    setSelection(PortfolioDashboardList?.WEEK);
  };
  const momHandler = () => {
    setSelection(PortfolioDashboardList?.MOM);
  };
  const yoyHandler = () => {
    setSelection(PortfolioDashboardList?.YOY);
  };
  const chartViewHandler = () => {
    setView(KpiViewList?.CHART);
  };
  const tableViewHandler = () => {
    setView(KpiViewList?.TABLE);
  };
  const showGridHandler = () => {
    // props?.expandState(true)
    setShowTableModal(true)
    // setView(KpiViewList?.TABLE)
  };
  
  const outletOption = useMemo<outletType[]>(() => {
    if(outletList[0] !== undefined){
      return outletList[0]?.filter(
        (a:any) => a?.centerid?.toString() === authUser?.siteId?.toString()
      );
    }else return [];
  }, [outletList]);

  const outletIndex = useMemo<number>(() => {
    return outletList?.findIndex(
      (a) => a?.id?.toString() === props?.outletId?.toString()
    );
  }, [outletList]);

  const propOutname = useMemo<string>(() => {
    return outletList[outletIndex]?.outletcode;
  }, [outletList, outletIndex]);

  const WeeklyFilteredData = useMemo<WeeklyRecord[]>(() => {
    const data: WeeklyRecord[] = [];
    const selectedYears = yearList?.map((f) => f)?.sort();
    weeklyData?.forEach((d) => {
      data?.push({
        portfolio: d?.portfolio,
        record: selectedYears
          ?.map((yr) => {
            return d?.record?.filter((r) => {
              return (
                yr?.toString() === r?.transyear?.toString() &&
                authUser?.brandId?.toString() === r?.brandid?.toString()
              );
            });
          })
          ?.flat(1),
      });
    });
    return data;
  }, [weeklyData, yearList]);

  const weekLabel = useMemo<number[]>(() => {
    const weeks: number[] = [];
    WeeklyFilteredData?.forEach((y, i) => {
      y?.record?.forEach((r) => weeks?.push(r?.weeknumber));
    });

    return weeks
      ?.sort(function (a, b) {
        return a - b;
      })
      ?.filter(getOnlyUnique);
  }, [WeeklyFilteredData]);

  const MonthlyFilteredData = useMemo<MonthlyRecord[]>(() => {
    const data: MonthlyRecord[] = [];

    monthlyData?.forEach((d) => {
      data?.push({
        portfolio: d?.portfolio,
        record: yearList
          ?.map((yr) => {
            return d?.record?.filter((r) => {
              return yr === r?.transyear;
            });
          })
          ?.flat(1),
      });
    });
    return data;
  }, [monthlyData, yearList]);

  const YearlyFilteredData = useMemo<YearlyRecord[]>(() => {
    const data: YearlyRecord[] = [];

    // const selectedYears = yearFilter?.map(f => f?.value)?.sort()

    yearlyData?.forEach((d) => {
      data?.push({
        portfolio: d?.portfolio,
        record: yearList
          ?.sort()
          ?.map((yr) => {
            return d?.record?.filter((r) => {
              return yr === r?.transyear;
            });
          })
          ?.flat(1),
      });
    });

    return data;
  }, [yearlyData, yearList]);

  useEffect(() => {
    let pid = props?.portfolioId ? [props?.portfolioId] : [];
    var obj = portfolioOption?.find((p) => p?.id === Number(pid));
    // let valuecaption = obj?.valuecaption ? obj?.valuecaption : "in Millions";
    let valuecaption = props?.valueCapStr ? props?.valueCapStr : "in Millions";
    setValueIn(valuecaption);
  }, [props, portfolioOption]);

  useEffect(() => {
    var getData = {
      portfolio: props?.portfolioId ? [props?.portfolioId] : [],
      brand: props?.brandId ? [props?.brandId] : [],
      site: props?.outletId ? props?.outletId : [],
      tradeCategory: props?.tradeCategory,
      tradeSubCategory: props?.tradeSubCategory,
      country: props?.country,
      state: props?.state,
      region: props?.region,
      city: props?.city,
      authUserRole: authUser?.roleId ? authUser?.roleId : "",
    };
    if (
      getData?.portfolio?.length > 0 &&
      props?.portfolioId &&
      getData !== undefined && props?.commonApiEnabler
    ) {
      api
        .post<APIResponse<{ records: WeeklyRecord[] }>>(
          `kpi/outlet-retail/bills/weekly`,
          getData,
          config
        )
        .then((response) => {
          if (response?.status === 200 && response?.data?.data) {
            const result = response?.data?.data?.records;

            setWeeklyData(result);
          }
          else{ 
          setWeeklyData([]);
          }
        })
        .catch((error) => {});
    } 
  }, [
    props?.portfolioId,
    props?.outletId,
    props?.tradeCategory,
    props?.tradeSubCategory,
    props?.country,
    props?.state,
    props?.region,
    props?.city,
    props?.commonApiEnabler
  ]);

  useEffect(() => {
    var getData = {
      portfolio: props?.portfolioId ? [props?.portfolioId] : [],
      site: props?.outletId ? props?.outletId : [],
      brand: props?.brandId ? [props?.brandId] : [],
      tradeCategory: props?.tradeCategory,
      tradeSubCategory: props?.tradeSubCategory,
      country: props?.country,
      state: props?.state,
      region: props?.region,
      city: props?.city,
      authUserRole: authUser?.roleId ? authUser?.roleId : "",
    };
    if (
      getData?.portfolio?.length > 0 &&
      props?.portfolioId &&
      getData !== undefined && props?.commonApiEnabler
    ) {
      api
        .post<APIResponse<{ records: YearlyRecord[] }>>(
          `kpi/outlet-retail/bills/yearly`,
          getData,
          config
        )
        .then((response) => {
          if (response?.status === 200 && response?.data?.data) {
            const result = response?.data?.data?.records;

            setYearlyData(result);
          }
          else { setYearlyData([]);}
        })
        .catch((error) => {});
    } 
  }, [
    props?.portfolioId,
    props?.outletId,
    props?.tradeCategory,
    props?.tradeSubCategory,
    props?.country,
    props?.state,
    props?.region,
    props?.city,
    props?.brandId,
    props?.commonApiEnabler
  ]);

  useEffect(() => {
    var getData = {
      portfolio: props?.portfolioId ? [props?.portfolioId] : [],
      brand: props?.brandId ? [props?.brandId] : [],
      site: props?.outletId ? props?.outletId : [],
      tradeCategory: props?.tradeCategory,
      tradeSubCategory: props?.tradeSubCategory,
      country: props?.country,
      state: props?.state,
      region: props?.region,
      city: props?.city,
      authUserRole: authUser?.roleId ? authUser?.roleId : "",
    };
    if (
      getData?.portfolio?.length > 0 &&
      props?.portfolioId &&
      getData !== undefined && props?.commonApiEnabler
    ) {
      api
        .post<APIResponse<{ records: MonthlyRecord[] }>>(
          `kpi/outlet-retail/bills/monthly`,
          getData,
          config
        )
        .then((response) => {
          if (response?.status === 200 && response?.data?.data) {
            const result = response?.data?.data?.records;

            setMonthlyData(result);
          }
          else {setMonthlyData([]);}
        })
        .catch((error) => {});
    } 
  }, [
    props?.portfolioId,
    props?.outletId,
    props?.tradeCategory,
    props?.tradeSubCategory,
    props?.country,
    props?.state,
    props?.region,
    props?.city,
    props?.brandId,
    props?.commonApiEnabler
  ]);

  const colors = [
    COLORS?.COLOR1,
    COLORS?.COLOR2,
    COLORS?.COLOR3,
    COLORS?.COLOR4,
    COLORS?.COLOR5,
    COLORS?.COLOR6,
    COLORS?.COLOR7,
    COLORS?.COLOR8,
  ];

  const getWEEKData = () => {
    var monthlyTableData: string[][] = [
      [
        "Year",
        'Week',
        "Sales",
        'Bills'
      ],
    ];

    yearList?.map((a)=>{
      
    
        weekLabel?.map((g:number)=>{
    weeklyData?.forEach((y, i) => {
          y?.record?.filter((b)=>b?.transyear?.toString()===a?.toString()&&b?.weeknumber?.toString()===g?.toString())
            ?.forEach((r) => {
              let sum = []
              sum?.push(r?.transyear?.toString());
              sum?.push(r?.weeknumber?.toString());
              sum?.push(
                r?.sales !== undefined && r?.sales !== null ? r?.sales : "-"
              );
              sum?.push(
                r?.bills !== undefined && r?.bills!== null ? r?.bills?.toString(): "-"
              );
                monthlyTableData?.push(sum);
        });
      })
  
  })
  })
    return monthlyTableData;
  };


  const getMonthlyMOMData = () => {
    var monthlyTableData: string[][] = [
      [
        "Year-Month",
        "Sales",
        'Bills'
      ],
    ];

    yearList?.map((a)=>{
    
    monthlyData?.forEach((y, i) => {
          y?.record?.filter((b)=>b?.transyear?.toString()===a?.toString())
            ?.forEach((r) => {
                let sum = []
              const month = monthsArray[r?.transmonth - 1];
              sum?.push(r?.transyear?.toString() + "-" + month);
              sum?.push(
                r?.sales !== undefined && r?.sales !== null ? r?.sales : "-"
              );
              sum?.push(
                r?.bills !== undefined && r?.bills!== null ? r?.bills?.toString(): "-"
              );
                monthlyTableData?.push(sum);
        });
  })
  })
    return monthlyTableData;
  };

  const getYearlyYOYData = () => {
    var monthlyTableData: string[][] = [
      [
        "Year",
        "Sales",
        'Bills'
      ],
    ];

    yearList?.map((a)=>{
   
    yearlyData?.forEach((y, i) => {
          y?.record?.filter((b)=>b?.transyear?.toString()===a?.toString())
            ?.forEach((r) => {
                let sum = []
              sum?.push(r?.transyear?.toString());
              sum?.push(
                r?.sales !== undefined && r?.sales !== null ? r?.sales : "-"
              );
              sum?.push(
                r?.bills !== undefined && r?.bills!== null ? r?.bills?.toString() : "-"
              );
                monthlyTableData?.push(sum);
        });
    });
  })
    return monthlyTableData;
  };

  return (
    <Widget1
      title={"Store Transactions"}
      weekHandler={weekHandler}
      momHandler={momHandler}
      yoyHandler={yoyHandler}
      tableViewHandler={tableViewHandler}
      chartViewHandler={chartViewHandler}
      selection={selection}
      view={view}
      showMTD={false}
      showYTD={false}
      showWEEK={true}
      valueInString={valueIn}
      showExpandModal={true}
      showExpand={true}
      showExpandHandler={showExpandHandler}
      showGridHandler={showGridHandler}
      showGrid={true}
    >
      {selection === PortfolioDashboardList?.WEEK &&
        view === KpiViewList?.CHART && (
          <SplineAreaChart
            datasets={(() => {
              const wdata: { name: string; data: number[] }[] = [];

              const name = outletOption[0]?.outletcode;
              WeeklyFilteredData?.forEach((y, i) => {
                const selectedYears = yearList?.map((f) => f)?.sort();

                selectedYears?.forEach((s) => {
                  let data2 = y?.record

                    ?.filter((y) => y?.transyear?.toString() === s?.toString())
                    ?.map((r) => (r?.bills ? r?.bills : 0));

                  let isEmpty: string[] = [];
                  data2?.forEach((d2) => {
                    if (d2 !== 0) isEmpty?.push("true");
                    else isEmpty?.push("false");
                  });
                  if (data2?.length > 0 && isEmpty?.includes("true")) {
                    wdata?.push({
                      name: name + "-" + s,
                      data: data2,
                    });
                  }
                });
              });

              return wdata;
            })()}
            labels={weekLabel?.map((w) => w?.toString())}
            colors={[
              colors[0],
              colors[1],
              colors[2],
              colors[3],
              colors[4],
              colors[5],
              colors[6],
              colors[7],
            ]}
            title={"Store Transactions - WEEK"}
            subtitle={(() => {
              let subtxt = ``;
              if (propOutname !== undefined) {
                subtxt += `\u3010Outlet\u3011: ${propOutname}\u2003`;
              }
              subtxt += `\u3010Period\u3011: ${yearList[0]} - ${yearList[2]}\u2003`;
              return subtxt;
            })()}
            exportfilename={"StoreTransaction-WEEK"}
          />
        )}

      {selection === PortfolioDashboardList?.MOM &&
        view === KpiViewList?.CHART && (
          <SplineAreaChart
            datasets={(() => {
              let mdata: { name: string; data: number[] }[] = [];
              MonthlyFilteredData?.forEach((y, i) => {
                // const selectedYears = yearFilter?.map(f => f)?.sort()
                outletOption?.forEach((s:any) => {
                  yearList?.forEach((sy) => {
                    let data2: number[] = [];
                    let d1 = y?.record
                      ?.filter(
                        (sw) => sw?.centerid?.toString() === s?.centerid?.toString()
                      )
                      ?.filter(
                        (y) => y?.transyear?.toString() === sy?.toString()
                      );
                    monthsArray
                      ?.map((f) => f)
                      ?.sort()
                      ?.forEach((m, mi) => {
                        var mns = d1?.map((d) => d?.transmonth);
                        if (mns?.includes(mi + 1)) {
                          let obj = d1?.find((f) => f?.transmonth === mi + 1);
                          data2?.push(obj?.bills ? obj?.bills : 0);
                        } else data2?.push(0);
                      });
                    let isEmpty: string[] = [];
                    data2?.forEach((d2) => {
                      if (d2 !== 0) isEmpty?.push("true");
                      else isEmpty?.push("false");
                    });

                    if (data2?.length > 0 && isEmpty?.includes("true")) {
                      mdata?.push({
                        name: s?.outletcode + "-" + sy,
                        data: data2,
                      });
                    }
                  });
                });
              });
              return mdata;
            })()}
            labels={monthsArray}
            colors={[
              colors[0],
              colors[1],
              colors[2],
              colors[3],
              colors[4],
              colors[5],
              colors[6],
              colors[7],
              colors[8],
              colors[9],
            ]}
            title={"Store Transactions - MOM"}
            subtitle={(() => {
              let subtxt = ``;
              if (propOutname !== undefined) {
                subtxt += `\u3010Outlet\u3011: ${propOutname}\u2003`;
              }
              subtxt += `\u3010Period\u3011: ${yearList[0]} - ${yearList[2]}\u2003`;
              return subtxt;
            })()}
            exportfilename={"StoreTransaction-MOM"}
          />
        )}

      {selection === PortfolioDashboardList?.YOY &&
        view === KpiViewList?.CHART && (
          <SplineAreaChart
            datasets={(() => {
              let ydata: { name: string; data: number[] }[] = [];

              YearlyFilteredData?.forEach((y, i) => {
                const name = outletOption[0]?.outletcode;

                // let data = y?.record?.map(d => d?.sales ? parseFloat(d?.sales) : 0)
                // if (data?.length > 0) {
                //     ydata?.push({
                //         name: name,
                //         data: data
                //     })
                // }
                let data2: number[] = [];
                let d1 = y?.record;
                yearList?.forEach((yr) => {
                  var yrs = d1?.map((d) => d?.transyear);
                  if (yrs?.includes(yr)) {
                    let obj = d1?.find((f) => f?.transyear === yr);
                    data2?.push(obj?.bills ? obj?.bills : 0);
                  } else data2?.push(0);
                });

                if (data2?.length > 0) {
                  ydata?.push({
                    name: name,
                    data: data2,
                  });
                }
              });
              return ydata;
            })()}
            labels={yearList?.map((y) => y?.toString())?.sort()}
            colors={[
              colors[0],
              colors[1],
              colors[2],
              colors[3],
              colors[4],
              colors[5],
              colors[6],
              colors[7],
            ]}
            title={"Store Transactions - YOY"}
            subtitle={(() => {
              let subtxt = ``;
              if (propOutname !== undefined) {
                subtxt += `\u3010Outlet\u3011: ${propOutname}\u2003`;
              }
              subtxt += `\u3010Period\u3011: ${yearList[0]} - ${yearList[2]}\u2003`;
              return subtxt;
            })()}
            exportfilename={"StoreTransaction-YOY"}
          />
        )}

{showTableModal && selection === PortfolioDashboardList?.MOM && (
              <Modal
              size="xl"
              visible = {showTableModal}
              onClose={() => setShowTableModal(false)}
              centered classname="grid-container"
              >
              <RetailPerformanceTable
                    data={getMonthlyMOMData()}
                    title={`Store Transactions (MOM) - ${outletOption[0]?.outletcode}`}
                    fileName={"pf_monthly_sales"}
                    downloadCSV={false}
                    downloadPDF={false}
                    percent = {false}  
                  />
              </Modal>
        )}

{showTableModal && selection === PortfolioDashboardList?.YOY && (
              <Modal
              size="xl"
              visible = {showTableModal}
              onClose={() => setShowTableModal(false)}
              centered classname="grid-container"
              >
              <RetailPerformanceTable
                    data={getYearlyYOYData()}
                    title={`Store Transactions (YOY) - ${outletOption[0]?.outletcode}`}
                    fileName={"pf_monthly_sales"}
                    downloadCSV={false}
                    downloadPDF={false}
                    percent = {false}
                  />
              </Modal>
        )}

{showTableModal && selection === PortfolioDashboardList?.WEEK && (
              <Modal
              size="xl"
              visible = {showTableModal}
              onClose={() => setShowTableModal(false)}
              centered classname="grid-container"
              >
              <RetailPerformanceTable
                    data={getWEEKData()}
                    title={`Store Transactions (WEEK) - ${outletOption[0]?.outletcode}`}
                    fileName={"pf_monthly_sales"}
                    downloadCSV={false}
                    downloadPDF={false}
                    percent = {false}
                  />
              </Modal>
        )}
    </Widget1>
  );
};
export default OutletTransaction;
