import { ChangeEventHandler, useEffect, useState } from 'react'
import { Button } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import { FormInput, VerticalForm } from '../components/custom/components'
import AuthLayout from '../components/custom/pages/AuthLayout'
import { ToastTypes, useToast } from '../components/toast/ToastProvider'
import {  LoginDataType, loginUser } from '../redux/actions/authUserActions'
import { AppDispatch, StoreState } from '../redux/store'
import axios from "axios";
import { timestamp, today, today1 } from '../config/functions'
import { addToSelectedOption, OptionsTypes } from '../redux/actions/selectedOptionsActions'

const Login = () => {
    const toast = useToast()
    const dispatch = useDispatch<AppDispatch>()
    
  const selectedOption = useSelector<StoreState, OptionsTypes>(
    (state) => state.selectedOption
  );
    // const selectedOption = useSelector<StoreState, OptionsTypes>(state => state.selectedOption)
    // const portfolioOption = useSelector<StoreState, PortfolioType[]>(state => state.portfolio)
    // const authUser = useSelector<StoreState, AuthUserType | null>(state => state.authUser)
    // const siteOption = useSelector<StoreState, SiteType[]>(state => state.site)

    const [loading, setLoading] = useState<boolean>(false)
    const [mounted, setMounted] = useState<boolean>(false)

    const [username, setUsername] = useState<string>('')
    const [password, setPassword] = useState<string>('')
    const [passwordError, setPasswordError] = useState<string>('')
    const [usernameError, setUsernameError] = useState<string>('')
    const [ip, setIP] = useState("");

    const getData = async () => {
    const res = await axios.get("https://api.ipify.org/?format=json");
    // const location = await axios.get(`https://get.geojs.io/v1/ip/geo/${res.data.ip}.json`);
    const data = {
      ...selectedOption,ip:res.data.ip
    }
    dispatch(addToSelectedOption(data))
  };


  useEffect(() => {
    getData();
  }, []);
  

    const handleUsername: ChangeEventHandler<HTMLInputElement> = (event) => {
        setUsernameError('')
        setUsername(event.target.value)
    }

    const handlePassword: ChangeEventHandler<HTMLInputElement> = (event) => {
        setPasswordError('')
        setPassword(event.target.value)
    }

    const handleLogin = (event: any) => {
        event.preventDefault()
        const data: LoginDataType = {
            loginid: username,
            password: password
        }

        let error = false

        if (data.loginid === '') {
            setUsernameError('Username required')
            error = true
        }

        if (data.password === '') {
            setPasswordError('Password required')
            error = true
        }

        if (!error) {
            setLoading(true)
            dispatch(loginUser(data)).then(text => {
                const date = today()
                const date2 = today1()
                const time = timestamp()
          const data = {
            ...selectedOption,loginday:date,loginevent:date2 + " "+time,ip:ip,isLogged:true,email:username
          }
          dispatch(addToSelectedOption(data))
                toast(text)
            }).catch(text => {
                toast(text, ToastTypes.ERROR)
            }).finally(() => {
                if (mounted) {
                    setLoading(false)
                }
            })
        }
    }

    useEffect(() => {
        setMounted(true)
        return () => setMounted(false)
    }, [])

    return <AuthLayout
        helpText={'Enter username and password to login.'}
    >
        <VerticalForm onSubmit={handleLogin}>
            <FormInput
                label={'Username'}
                type='text'
                name='username'
                value={username}
                placeholder='Enter your Username'
                onChange={handleUsername}
                errorText={usernameError}
                containerClass='mb-3'
            />
            <FormInput
                label={'Password'}
                type='password'
                name='password'
                value={password}
                placeholder='Enter your password'
                onChange={handlePassword}
                errorText={passwordError}
                containerClass='mb-3'
            />

            <div className='text-center d-grid'>
                {loading
                    ? <Button variant='primary' type='button' disabled className='btn-lg p-2'>
                        Log In
                    </Button>
                    : <Button variant='primary' type='submit' className='btn-lg p-2'>
                        Log In
                    </Button>}
            </div>
            <div className="mt-2">
                <Link to="#" className="text-primary-50">
                    Forgot your password?
                </Link>
            </div>

        </VerticalForm>
    </AuthLayout>
}

export default Login
