import { SessionType, SessionActionList, RegionType, RegionActionsList, StateType, StateActionsList, CityType, CityActionsList } from "../actions/commonActions";
import { Action } from "../actions/types";

const initialSessionState: SessionType = {
    isLoggedIn: false,
};
const initialRegionValue: Array<RegionType> = []
const initialStateValue: Array<StateType> = []
const initialCityValue: Array<CityType> = []

export const SessionReducer = (state=initialSessionState , action: Action) => {

    switch (action.type) {
        case SessionActionList.SET_SESSION: {
            return { ...state, isLoggedIn: true };
        }
        case SessionActionList.CLEAR_SESSION: {
            return { ...state, isLoggedIn: false };
        }
        default: return state;
    }

}
export const RegionReducer = (state: RegionType[] =initialRegionValue , action: Action) => {

    switch (action.type) {

        case RegionActionsList.FETCH_REGION: {

            return action.data

        }
        case RegionActionsList.CLEAR_REGION: {
            return initialRegionValue

        }

        default: return state
    }

}
export const StateReducer = (state: StateType[] =initialStateValue , action: Action) => {

    switch (action.type) {

        case StateActionsList.FETCH_STATE: {

            return action.data

        }
        case StateActionsList.CLEAR_STATE: {
            return initialStateValue

        }

        default: return state
    }

}
export const CityReducer = (state: CityType[] =initialCityValue , action: Action) => {

    switch (action.type) {

        case CityActionsList.FETCH_CITY: {

            return action.data

        }
        case CityActionsList.CLEAR_CITY: {
            return initialCityValue

        }

        default: return state
    }

}