import React, {useState} from "react";
import LineChart from "../custom/components/charts/apex/LineChart";
import { COLORS } from "../custom/constants";
import { getOnlyUnique } from "../commonWidget";

interface Props {  
    AvgTransactionValueData: {
        portfolio: number;
        record: {
            brandid: number;
            total_receipts: number;
            total_sales: number;
            single_sku_percent: number | null;
            multiple_sku_percent: number | null;
            percent_top10: number;
            percent_81_90: number;
            percent_71_80: number;
            percent_61_70: number;
            percent_51_60: number;
            percent_41_50: number;
            percent_31_40: number;
            percent_21_30: number;
            percent_11_20: number;
            percent_least10: number;
        }[];
        count?: number;
      }[];
    yearAndMonth: Date;
    getSubtitle: string;
    filteredBrand: {
        id?: number
        name: string
        url: string
        logopath: string
        logourl: string
        companyid: number
    }[]
}
export const AvtMonthly: React.FC<Props> = (props: Props) => {
    const { AvgTransactionValueData, yearAndMonth, getSubtitle, filteredBrand } = props;
    const colors = [
        COLORS?.COLOR1,
        COLORS?.COLOR2,
        COLORS?.COLOR3,
        COLORS?.COLOR4,
        COLORS?.COLOR5,
        COLORS?.COLOR6,
        COLORS?.COLOR7,
        COLORS?.COLOR8,
      ];
    return (
        <>
        <LineChart
        datasets={(() => {
            let mdata: { name: string; data: number[] }[] = [];
            AvgTransactionValueData?.forEach((y, i) => {
            const brandArray = y?.record
                ?.map((r, j) => r?.brandid)
                ?.filter(getOnlyUnique);

            brandArray?.forEach((b) => {
                y?.record
                ?.filter((f) => f?.brandid === b)
                ?.forEach((r) => {
                    const brand = filteredBrand?.find(
                    (f) => f?.id?.toString() === b?.toString()
                    );

                    let data: number[] = [];
                    data?.push(
                    r?.percent_top10 ? r?.percent_top10 : 0
                    );
                    data?.push(
                    r?.percent_81_90 ? r?.percent_81_90 : 0
                    );
                    data?.push(
                    r?.percent_71_80 ? r?.percent_71_80 : 0
                    );
                    data?.push(
                    r?.percent_61_70 ? r?.percent_61_70 : 0
                    );
                    data?.push(
                    r?.percent_51_60 ? r?.percent_51_60 : 0
                    );
                    data?.push(
                    r?.percent_41_50 ? r?.percent_41_50 : 0
                    );
                    data?.push(
                    r?.percent_31_40 ? r?.percent_31_40 : 0
                    );
                    data?.push(
                    r?.percent_21_30 ? r?.percent_21_30 : 0
                    );
                    data?.push(
                    r?.percent_11_20 ? r?.percent_11_20 : 0
                    );
                    data?.push(
                    r?.percent_least10 ? r?.percent_least10 : 0
                    );
                    if (data?.length > 0) {
                    mdata?.push({
                        name: brand?.name ? brand?.name : "",
                        data: data,
                    });
                    }
                });
            });
            });
            return mdata;
        })()}
        labels={[
            "Top 10%",
            "81-90%",
            "71-80%",
            "61-70%",
            "51-60%",
            "41-50%",
            "31-40%",
            "21-30%",
            "11-20%",
            "Least 10%",
        ]}
        colors={[
            colors[0],
            colors[1],
            colors[2],
            colors[3],
            colors[4],
            colors[5],
            colors[6],
            colors[7],
        ]}
        title={(() => {
            const txt = `Average Transaction Value - Monthly`;
            return txt;
        })()}
        subtitle={(() => {
            let subtxt = getSubtitle ? getSubtitle : "";
            subtxt += ` \u3010Year\u3011: ${yearAndMonth?.getFullYear()}`;
            subtxt += ` \u3010Month\u3011: ${yearAndMonth?.getMonth() + 1}`;
            return subtxt;
        })()}
        exportfilename={"AVGTransactionValue-Monthly"}
        />
    </>
    );
};
interface YearlyProps {
    yearlyAvgTransactionValueData: {
        portfolio: number;
        record: {
            brandid: number;
            total_receipts: number;
            total_sales: number;
            single_sku_percent: number | null;
            multiple_sku_percent: number | null;
            percent_top10: number;
            percent_81_90: number;
            percent_71_80: number;
            percent_61_70: number;
            percent_51_60: number;
            percent_41_50: number;
            percent_31_40: number;
            percent_21_30: number;
            percent_11_20: number;
            percent_least10: number;
        }[];
        count?: number;
    }[];
    year: Date;
    getSubtitle: string;
    filteredBrand: {
        id?: number
        name: string
        url: string
        logopath: string
        logourl: string
        companyid: number
    }[]
}
export const AvtYearly: React.FC<YearlyProps> = (props: YearlyProps) => {    
    const { yearlyAvgTransactionValueData, year, getSubtitle, filteredBrand } = props;
    const colors = [
        COLORS?.COLOR1,
        COLORS?.COLOR2,
        COLORS?.COLOR3,
        COLORS?.COLOR4,
        COLORS?.COLOR5,
        COLORS?.COLOR6,
        COLORS?.COLOR7,
        COLORS?.COLOR8,
    ];
    return (
        <>
        <LineChart
        datasets={(() => {
            let mdata: { name: string; data: number[] }[] = [];
            yearlyAvgTransactionValueData?.forEach((y, i) => {
            const brandArray = y?.record
                ?.map((r, j) => r?.brandid)
                ?.filter(getOnlyUnique);
            brandArray?.forEach((b) => {
                y?.record
                ?.filter((f) => f?.brandid === b)
            ?.forEach((r) => {
                const brand = filteredBrand?.find(
                (f) => f?.id?.toString() === b?.toString()
                );
                let data: number[] = [];
                data?.push(
                r?.percent_top10 ? r?.percent_top10 : 0
                );
                data?.push(
                r?.percent_81_90 ? r?.percent_81_90 : 0
                );
                data?.push(
                r?.percent_71_80 ? r?.percent_71_80 : 0
                );
                data?.push(
                r?.percent_61_70 ? r?.percent_61_70 : 0
                );
                data?.push(
                r?.percent_51_60 ? r?.percent_51_60 : 0
                );
                data?.push(
                r?.percent_41_50 ? r?.percent_41_50 : 0
                );
                data?.push(
                r?.percent_31_40 ? r?.percent_31_40 : 0
                );
                data?.push(
                r?.percent_21_30 ? r?.percent_21_30 : 0
                );
                data?.push(
                r?.percent_11_20 ? r?.percent_11_20 : 0
                );
                data?.push(
                r?.percent_least10 ? r?.percent_least10 : 0
                );
                if (data?.length > 0) {
                mdata?.push({
                    name: brand?.name ? brand?.name : "",
                    data: data,
                });
                }
            });
        });
        });
        return mdata;
        })()}
        labels={[
            "Top 10%",
            "81-90%",
            "71-80%",
            "61-70%",
            "51-60%",
            "41-50%",
            "31-40%",
            "21-30%",
            "11-20%",
            "Least 10%",
        ]}
        colors={[
            colors[0],
            colors[1],
            colors[2],
            colors[3],
            colors[4],
            colors[5],
            colors[6],
            colors[7],
        ]}
        title={(() => {
            const txt = `Average Transaction Value - Yearly`;
            return txt;
        })()}
        subtitle={(() => {
            let subtxt = getSubtitle ? getSubtitle : "";
            subtxt += ` \u3010Year\u3011: ${year?.getFullYear()}`;
            return subtxt;
        })()}
        exportfilename={"AVGTransactionValue-Yearly"}
        />
        </>
    );

};