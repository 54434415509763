import { Dispatch } from "redux";
import api, { APIResponse, getAxiosRequestConfig } from "../../config/api";
import { GetState } from "../store";

export enum ActiveDropDown {
  Active = "true",
  InActive = "false",
}
export type ActiveTypes = ActiveDropDown.Active | ActiveDropDown.InActive;

export interface SiteType {
  id?: number;
  name: string;
  code: string;
  url: string;
  pfid: number;
  centertype: string;
  gla: number;
  uom: string;
  salesby: string;
  street: string;
  city: string;
  state: string;
  country: string;
  pincode: string;
  logopath: string;
  logourl: string;
  active: ActiveTypes | boolean;
  livefrom: string;
  region: string;
  createdBy?: number | null;
  createdAt?: string;
  updatedAt?: string;
  updatedBy?: string;
  currencycode?: string;
  currencysymbol?: string;
  divisionvalue?: number;
  valuecaption?: string;
  pfcurrcode: string;
  pfcurrsymbol: string;
  hccurrcode: string;
  hccurrsymbol: string;
}
export enum SiteActionsList {
  ADD_SITE = "ADD_SITE",
  EDIT_SITE = "EDIT_SITE",
  FETCH_SITE = "FETCH_SITE",
  DELETE_SITE = "DELETE_SITE",
  CLEAR_SITE = "CLEAR_SITE",
}

export interface DeleteSite {
  id: number;
}

export interface AddSiteAction {
  type: SiteActionsList.ADD_SITE;
  data: SiteType;
}

export interface EditSiteAction {
  type: SiteActionsList.EDIT_SITE;
  data: SiteType;
  id?: number;
}

export interface FetchSiteAction {
  type: SiteActionsList.FETCH_SITE;
  data: SiteType[];
}

export interface ClearSiteAction {
  type: SiteActionsList.CLEAR_SITE;
}

export interface DeleteSiteAction {
  type: SiteActionsList.DELETE_SITE;
  data: DeleteSite;
}

export type SiteActions =
  | AddSiteAction
  | EditSiteAction
  | FetchSiteAction
  | DeleteSiteAction
  | ClearSiteAction;

export const clearSite = () => {
  return async (dispatch: Dispatch, getState: GetState) => {
    return dispatch<ClearSiteAction>({
      type: SiteActionsList.CLEAR_SITE,
    });
  };
};

export const fetchSite = () => {
  return async (dispatch: Dispatch, getState: GetState) => {
    // return dispatch<FetchSiteAction>({
    //     type: SiteActionsList.FETCH_SITE
    // })
    const token =
      getState().authUser?.["auth-token"]!;
    const config = getAxiosRequestConfig(token);

    return api
      .get<APIResponse<SiteType[]>>("site/", config)
      .then((response) => {
        if (response.status === 200 && response.data.data) {
          dispatch<FetchSiteAction>({
            type: SiteActionsList.FETCH_SITE,
            data: response.data.data,
          });
          return Promise.resolve(
            response.data.message ? response.data.message : "Fetch Successfull"
          );
        } else {
          // throw { response };
          throw new Error("Unable to fetch")
        }
      })
      .catch((error) => {
        return Promise.reject(
          error.response
            ? error.response.data.message
              ? error.response.data.message
              : "Unable to fetch"
            : "Unable to fetch"
        );
      });
  };
};

export const fetchSiteByUserId = (userid: number) => {
  return async (dispatch: Dispatch, getState: GetState) => {
    const token =
      getState().authUser?.["auth-token"]!;
    const config = getAxiosRequestConfig(token);

    return api
      .get<APIResponse<SiteType[]>>(`siteByUser/${userid}`, config)
      .then((response) => {
        if (response.status === 200 && response.data.data) {
          dispatch<FetchSiteAction>({
            type: SiteActionsList.FETCH_SITE,
            data: response.data.data,
          });
          return Promise.resolve(
            response.data.message ? response.data.message : "Fetch Successfull"
          );
        } else {
          throw new Error("Unable to fetch")
        }
      })
      .catch((error) => {
        return Promise.reject(
          error.response
            ? error.response.data.message
              ? error.response.data.message
              : "Unable to fetch"
            : "Unable to fetch"
        );
      });
  };
};