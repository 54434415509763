import { ApexOptions } from "apexcharts";
import Chart from "react-apexcharts";

interface dataSet {
  name: string;
  data: number[];
}

interface Iprops {
  datasets: dataSet[];
  labels: string[];
  title: string;
  colors: string[];
  subtitle?: string | "";
  exportfilename: string;
}
// simple bar chart
const BarChart = (props: Iprops) => {
  // default options
  const { colors, datasets, labels, title, exportfilename, subtitle } = props;

  const apexBarChartOpts: ApexOptions = {
    chart: {
      height: 380,
      type: "bar",
      toolbar: {
        show: true,
        export: {
          csv: {
            filename: exportfilename,
          },
          png: {
            filename: exportfilename,
          },
          svg: {
            filename: exportfilename,
          },
        },
      },
    },
    subtitle: {
      text: subtitle,
      align: "left",
      offsetY: 22,
      margin: 14,
    },
    title: {
      text: title,
      align: "center",
    },
    plotOptions: {
      bar: {
        horizontal: false,
        borderRadius: 4,
        columnWidth: "30%",
        dataLabels: {
          position: "top",
        },
      },
    },
    dataLabels: {
      enabled: true,
      offsetX: -6,
      style: {
        fontSize: "10px",
        colors: ["#000"],
      },
    },
    colors: colors,
    stroke: {
      show: true,
      width: 1,
      colors: ["#fff"],
      curve: "smooth",
    },

    xaxis: {
      categories: labels,
    },
    legend: {
      showForSingleSeries: true,
      offsetY: -10,
    },
    states: {
      hover: {
        filter: {
          type: "none",
        },
      },
    },
    grid: {
      borderColor: "#f1f3fa",
      padding: {
        left: 0,
        right: 0,
      },
    },
  };

  // chart data
  // const apexBarChartData = [
  //     {
  //         name: 'Series 1',
  //         data: [44, 55, 41, 64, 22, 43, 21],
  //     },
  //     {
  //         name: 'Series 2',
  //         data: [53, 32, 33, 52, 13, 44, 32],
  //     },
  // ];

  return (
    <>
      {/*<h4 className="header-title mb-3">{title}</h4>*/}
      <Chart
        options={apexBarChartOpts}
        series={datasets}
        type="bar"
        className="apex-charts"
        height={400}
      />
    </>
  );
};

export default BarChart;
