import React, { useEffect, useMemo, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { PortfolioDashboardList, PortfolioDashboardType } from "../../config";
import api, { APIResponse, getAxiosRequestConfig } from "../../config/api";
import { AuthUserType } from "../../redux/actions/authUserActions";
import { StoreState } from "../../redux/store";
import { KpiViewList } from "../commonWidget";
import Widget1 from "../custom/components/Widget1";
import { COLORS } from "../custom/constants";
import { YearOptions } from "../../config/functions";
import { PortfolioType } from "../../redux/actions/portfolioActions";
import { BrandType } from "../../redux/actions/brandActions";
import { BrandCompanyType } from "../../redux/actions/brandCompanyActions";
import { CountryType } from "../../redux/actions/countryActions";
import {
  CityType,
  RegionType,
  StateType,
} from "../../redux/actions/commonActions";
import { TradeCategoryType } from "../../redux/actions/tradeCategoryActions";
import { TradeSubCategoryType } from "../../redux/actions/tradeSubCategoryActions";
import StackedBarChart1 from "../custom/components/charts/apex/StackedBarChart1";
import { OptionsTypes, addToSelectedOption } from "../../redux/actions/selectedOptionsActions";
import RetailPerformanceTable from "../custom/components/RetailPerformanceTable";
import Modal from "../Modal";
interface Props {
  portfolioId: number;
  siteId: string[];
  tradeCategory?: number[];
  tradeSubCategory?: number[];
  country?: number[];
  region?: number[];
  state?: number[];
  city?: number[];
  expandState: React.Dispatch<React.SetStateAction<boolean>>;
  widgetLoader2?:React.Dispatch<React.SetStateAction<boolean>>;
  apiEnabler2?:boolean
  commonApiEnabler?:boolean
}

interface RecordsForMonth {
  transyear: number;
  transmonth: number;
  centerid?: number;
  brandid: number;
  sales: string;
}

interface RecordsForYear {
  transyear: number;
  centerid?: number;
  brandid: number;
  sales: string;
}

interface MonthlyRecord {
  portfolio: number;
  record: RecordsForMonth[];
}

interface YearlyRecord {
  portfolio: number;
  record: RecordsForYear[];
}

interface ChangedRefType {
  tradeCat?: string;
  tradeSubCat?:string
  country?:string
  region?:string
  state?:string
  city?:string
}

const BrandDashboardBrandContributionWidget: React.FC<Props> = (
  props: Props
) => {
  const dispatch = useDispatch();
  const changedRef = useRef<ChangedRefType>({});
  const authUser = useSelector<StoreState, AuthUserType | null>(
    (state) => state?.authUser
  );

  const portfolioList = useSelector<StoreState, PortfolioType[]>(
    (state) => state?.portfolio
  );
  const brandCompanyList = useSelector<StoreState, BrandCompanyType[]>(
    (state) => state?.brandCompany
  );

  const bcid = brandCompanyList?.filter(
    (a) => a?.retailid?.toString() === props?.portfolioId?.toString()
  );

  const brandList = useSelector<StoreState, BrandType[]>(
    (state) => state?.brand
  );

  const brandOption = brandList?.filter(
    (a) => a?.companyid?.toString() === bcid[0]?.id?.toString()
  );

  const authToken = authUser?.["auth-token"]!;
  const config = getAxiosRequestConfig(authToken);

  const selectedOption = useSelector<StoreState, OptionsTypes>(
    (state) => state?.selectedOption
  );

  const [loader, setLoader] = useState<boolean>(true);
  const [apiCall1,setApiCall1]=  useState<boolean>(true)
  const [apiCall2,setApiCall2]=  useState<boolean>(true)

  const monthsArray = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];

  const prop_index = portfolioList?.findIndex(
    (p) =>
      p?.id !== undefined && p?.id?.toString() === props?.portfolioId?.toString()
  );
  const propPfname = portfolioList[prop_index]?.name;

  const countryList = useSelector<StoreState, CountryType[]>(
    (state) => state?.country
  );

  const regionList = useSelector<StoreState, RegionType[]>(
    (state) => state?.region
  );

  const stateList = useSelector<StoreState, StateType[]>(
    (state) => state?.state
  );

  const cityList = useSelector<StoreState, CityType[]>((state) => state?.city);

  const tradeCategoryList = useSelector<StoreState, TradeCategoryType[]>(
    (state) => state?.tradeCategory
  );
  const subCategoryList = useSelector<StoreState, TradeSubCategoryType[]>(
    (state) => state?.tradeSubCategory
  );

  const countryIndex = countryList?.findIndex(
    (a) => a?.id?.toString() === props?.country?.toString()
  );
  const propConame = countryList[countryIndex]?.countryname;

  const regionIndex = regionList?.findIndex(
    (a) => a?.id?.toString() === props?.region?.toString()
  );
  const propRename = regionList[regionIndex]?.regionname;

  const stateIndex = stateList?.findIndex(
    (a) => a?.id?.toString() === props?.state?.toString()
  );
  const propStname = stateList[stateIndex]?.statename;

  const cityIndex = cityList?.findIndex(
    (a) => a?.id?.toString() === props?.city?.toString()
  );
  const propCiname = cityList[cityIndex]?.cityname;

  const catIndex = tradeCategoryList?.findIndex(
    (a) => a?.id?.toString() === props?.tradeCategory?.toString()
  );
  const propCatname = tradeCategoryList[catIndex]?.name;

  const subcatIndex = subCategoryList?.findIndex(
    (a) => a?.id?.toString() === props?.tradeSubCategory?.toString()
  );
  const propsubCatname = subCategoryList[subcatIndex]?.name;

  const [selection, setSelection] = useState<PortfolioDashboardType>(
    PortfolioDashboardList?.MOM
  );
  const [monthlyData, setMonthlyData] = useState<MonthlyRecord[]>([]);
  const [yearlyData, setYearlyData] = useState<YearlyRecord[]>([]);
  const [view, setView] = useState<string>(KpiViewList?.CHART);
  const [valueIn, setValueIn] = useState<string>("");
  const [showTableModal, setShowTableModal] = useState<boolean>(false);

  const yearList = YearOptions();
  const showExpandHandler = () => {
    props?.expandState((prevState) => !prevState)
  };
  const momHandler = () => {
    setSelection(PortfolioDashboardList?.MOM);
  };
  const yoyHandler = () => {
    setSelection(PortfolioDashboardList?.YOY);
  };
  const chartViewHandler = () => {
    setView(KpiViewList?.CHART);
  };
  const tableViewHandler = () => {
    setView(KpiViewList?.TABLE);
  };

  const showGridHandler = () => {
    // props?.expandState(true)
    setShowTableModal(true)
    // setView(KpiViewList?.TABLE)
  };


  const MonthlyFilteredData = useMemo<MonthlyRecord[]>(() => {
    const data: MonthlyRecord[] = [];

    monthlyData?.forEach((d) => {
      data?.push({
        portfolio: d?.portfolio,
        record: yearList
          ?.map((yr) => {
            return d?.record?.filter((r) => {
              return yr === r?.transyear;
            });
          })
          ?.flat(1),
      });
    });
    return data;
  }, [monthlyData, yearList]);

  const YearlyFilteredData = useMemo<YearlyRecord[]>(() => {
    const data: YearlyRecord[] = [];

    // const selectedYears = yearFilter?.map(f => f?.value)?.sort()

    yearlyData?.forEach((d) => {
      data?.push({
        portfolio: d?.portfolio,
        record: yearList
          ?.sort()
          ?.map((yr) => {
            return d?.record?.filter((r) => {
              return yr === r?.transyear;
            });
          })
          ?.flat(1),
      });
    });
    return data;
  }, [yearlyData, yearList]);

  useEffect(() => {
    let pid = props?.portfolioId ? [props?.portfolioId] : [];
    var obj = portfolioList?.find((p) => p?.id === Number(pid));
    let valuecaption = obj?.valuecaption ? obj?.valuecaption : "in Millions";
    setValueIn(valuecaption);
  }, [props, portfolioList]);

  useEffect(() => {
    //yearly
    var getData = {
      portfolio: props?.portfolioId ? [props?.portfolioId] : [],
      site: props?.siteId ? props?.siteId : [],
      tradeCategory: props?.tradeCategory,
      tradeSubCategory: props?.tradeSubCategory,
      country: props?.country,
      state: props?.state,
      region: props?.region,
      city: props?.city,
      authUserRole: authUser?.roleId ? authUser?.roleId : "",
    };

    if (
      getData?.portfolio?.length > 0 &&
      props?.portfolioId &&
      getData !== undefined && apiCall1 && props?.commonApiEnabler
    ) {
      // /kpi/center/sales/weekly
      api
        .post<APIResponse<{ records: YearlyRecord[] }>>(
          `kpi/retail/brandsales/yearly`,
          getData,
          config
        )
        .then((response) => {
          if (response?.status === 200 && response?.data?.data) {
            const result = response?.data?.data?.records;
            setYearlyData(result);
            setApiCall1(false)
          }
          else {
            setYearlyData([])
            setApiCall1(false)
          };
        })
        .catch((error) => {});
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    props?.portfolioId,
    props?.siteId,
    apiCall1,
    props?.commonApiEnabler
  ]);

  useEffect(() => {
    //monthly
    var getData = {
      portfolio: props?.portfolioId ? [props?.portfolioId] : [],
      site: props?.siteId ? props?.siteId : [],
      tradeCategory: props?.tradeCategory,
      tradeSubCategory: props?.tradeSubCategory,
      country: props?.country,
      state: props?.state,
      region: props?.region,
      city: props?.city,
      authUserRole: authUser?.roleId ? authUser?.roleId : "",
    };

    if (
      getData?.portfolio?.length > 0 &&
      props?.portfolioId &&
      getData !== undefined && apiCall2 && props?.apiEnabler2===false
    ) {
      api
        .post<APIResponse<{ records: MonthlyRecord[] }>>(
          `kpi/retail/brandsales/monthly`,
          getData,
          config
        )
        .then((response) => {
          if (response?.status === 200 && response?.data?.data) {
            const result = response?.data?.data?.records;
            setMonthlyData(result);
            setLoader(false)
            setApiCall2(false)
            // const data = {
            //   ...selectedOption,
            //   loader2:false
            // };
            // dispatch(addToSelectedOption(data));
          }
          else {
            setMonthlyData([]);
            setLoader(false)
            setApiCall2(false)
            // const data = {
            //   ...selectedOption,
            //   loader2:false
            // };
            // dispatch(addToSelectedOption(data));
          }
        })
        .catch((error) => {});
    } 
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    props?.portfolioId,
    props?.siteId,
    apiCall2,
    props?.apiEnabler2
  ]);

  const colors = [
    COLORS?.COLOR1,
    COLORS?.COLOR2,
    COLORS?.COLOR3,
    COLORS?.COLOR4,
    COLORS?.COLOR5,
    COLORS?.COLOR6,
    COLORS?.COLOR7,
    COLORS?.COLOR8,
    COLORS?.COLOR10,
    COLORS?.COLOR11,
  ];

  // useEffect(()=>{
  //   const data = {
  //     ...selectedOption,loader2:loader
  //   }
  //   dispatch(addToSelectedOption(data))
  // },[loader])

  const getMonthlyMOMData = () => {
    var monthlyTableData: string[][] = [
      [
        'Brand',
        "Year-Month",
        "Sales",
      ],
    ];

    yearList?.map((a)=>{
      brandList?.map((f)=>{
    monthlyData?.forEach((y, i) => {
          y?.record?.filter((b)=>b?.transyear?.toString()===a?.toString()&&f?.id?.toString()===b?.brandid?.toString())
            ?.forEach((r) => {
              const brand = brandList?.findIndex((e)=>e?.id?.toString()===r?.brandid?.toString())
                let sum = []
                 sum?.push(brandList[brand]?.name?.toString()??'')
              const month = monthsArray[r?.transmonth - 1];
              sum?.push(r?.transyear?.toString() + "-" + month);
              sum?.push(
                r?.sales !== undefined && r?.sales !== null ? r?.sales : "-"
              );
                monthlyTableData?.push(sum);
        });
    });
  })
  })
    return monthlyTableData;
  };

  const getYearlyYOYData = () => {
    var monthlyTableData: string[][] = [
      [
        'Brand',
        "Year",
        "Sales",
      ],
    ];

    yearList?.map((a)=>{
      brandList?.map((f)=>{
    yearlyData?.forEach((y, i) => {
          y?.record?.filter((b)=>b?.transyear?.toString()===a?.toString()&&f?.id?.toString()===b?.brandid?.toString())
            ?.forEach((r) => {
              const brand = brandList?.findIndex((e)=>e?.id?.toString()===r?.brandid?.toString())
                let sum = []
                 sum?.push(brandList[brand]?.name?.toString()??'')
              sum?.push(r?.transyear?.toString());
              sum?.push(
                r?.sales !== undefined && r?.sales !== null ? r?.sales : "-"
              );
                monthlyTableData?.push(sum);
        });
    });
  })
  })
    return monthlyTableData;
  };

  useEffect(() => { 

    let {tradeCategory,tradeSubCategory,city,country,region,state} = props


  if(changedRef?.current?.tradeCat !== tradeCategory?.toString()||changedRef?.current?.tradeSubCat!==tradeSubCategory?.toString()
      || changedRef?.current?.city!==city?.toString()
  || changedRef?.current?.country!==country?.toString()
  || changedRef?.current?.region!==region?.toString()
  || changedRef?.current?.state!==state?.toString()
  ) {
    changedRef.current = {
    tradeCat:tradeCategory?.toString(),
    tradeSubCat:tradeSubCategory?.toString(),
    city:city?.toString(),
    country:country?.toString(),
    region:region?.toString(),
    state:state?.toString()
    };
    setApiCall1(true)
    setApiCall2(true)
  }
}, [ props?.tradeCategory,props?.tradeSubCategory,props?.city,props?.country,props?.region,props?.state]);

useEffect(()=>{
  if(loader===false && apiCall2===false){
    props?.widgetLoader2!((prevState)=>!prevState)
  }
},[loader,apiCall2,props?.apiEnabler2])

  return (
    <Widget1
      title={"Brand Contribution"}
      momHandler={momHandler}
      yoyHandler={yoyHandler}
      tableViewHandler={tableViewHandler}
      chartViewHandler={chartViewHandler}
      selection={selection}
      view={view}
      showMTD={false}
      showYTD={false}
      valueInString={valueIn}
      showExpandModal={true}
      showExpand={true}
      showExpandHandler={showExpandHandler}
      showGridHandler={showGridHandler}
      showGrid={true}
    >
      {selection === PortfolioDashboardList?.MOM && view === KpiViewList?.CHART && (
        <StackedBarChart1
          datasets={(() => {
            let mdata: { name: string; data: number[] }[] = [];
            MonthlyFilteredData?.forEach((y, i) => {
              // const selectedYears = yearFilter?.map(f => f)?.sort()

              brandOption?.forEach((s) => {
                yearList?.forEach((sy) => {
                  let data2: number[] = [];
                  let d1 = y?.record
                    ?.filter(
                      (sw) => sw?.brandid?.toString() === s?.id?.toString()
                    )
                    ?.filter((y) => y?.transyear?.toString() === sy?.toString());
                  monthsArray
                    ?.map((f) => f)
                    ?.sort()
                    ?.forEach((m, mi) => {
                      var mns = d1?.map((d) => d?.transmonth);
                      if (mns?.includes(mi + 1)) {
                        let obj = d1?.find((f) => f?.transmonth === mi + 1);
                        data2?.push(obj?.sales ? parseFloat(obj?.sales) : 0);
                      } else data2?.push(0);
                    });
                  let isEmpty: string[] = [];
                  data2?.forEach((d2) => {
                    if (d2 !== 0) isEmpty?.push("true");
                    else isEmpty?.push("false");
                  });

                  if (data2?.length > 0 && isEmpty?.includes("true")) {
                    mdata?.push({
                      name: s?.name + "-" + sy,
                      data: data2,
                    });
                  }
                });
              });
            });
            return mdata;
          })()}
          labels={monthsArray}
          colors={[
            colors[0],
            colors[1],
            colors[2],
            colors[3],
            colors[4],
            colors[5],
            colors[6],
            colors[7],
            colors[8],
            colors[9],
          ]}
          title={(() => {
            const txt = `Brand Contribution - MOM`;
            return txt;
          })()}
          subtitle={(() => {
            let subtxt: string[] = [];
            let subtxt1 = `\u3010Portfolio\u3011: ${propPfname}\u2003 \u3010Period\u3011: ${yearList[0]} - ${yearList[2]}\u2003`;
            let subtxt2 = ``;
            if (
              props?.tradeCategory !== undefined &&
              props?.tradeCategory?.length > 0
            ) {
              subtxt1 += `\u3010TradeCategory\u3011: ${propCatname}\u2003`;
            }
            if (
              props?.tradeSubCategory !== undefined &&
              props?.tradeSubCategory?.length > 0
            ) {
              subtxt1 += `\u3010TradeSubCategory\u3011: ${propsubCatname}\u2003`;
            }
            if (props?.country !== undefined && props?.country?.length > 0) {
              subtxt2 += `\u3010Country\u3011: ${propConame}\u2003`;
            }
            if (props?.region !== undefined && props?.region?.length > 0) {
              subtxt2 += `\u3010Region\u3011: ${propRename}\u2003`;
            }
            if (props?.state !== undefined && props?.state?.length > 0) {
              subtxt2 += `\u3010State\u3011: ${propStname}\u2003`;
            }
            if (props?.city !== undefined && props?.city?.length > 0) {
              subtxt2 += `\u3010City\u3011: ${propCiname}\u2003`;
            }
            subtxt?.push(subtxt1);
            subtxt?.push(subtxt2);
            return subtxt;
          })()}
          exportfilename={"brandContribution-MOM"}
        />
      )}

      {selection === PortfolioDashboardList?.YOY && view === KpiViewList?.CHART && (
        <StackedBarChart1
          datasets={(() => {
            let ydata: { name: string; data: number[] }[] = [];
            YearlyFilteredData?.forEach((y, i) => {
              // yearList?.forEach(yr => {
              brandOption?.forEach((s) => {
                let data2: number[] = [];
                let d1 = y?.record?.filter(
                  (sw) => sw?.brandid?.toString() === s?.id?.toString()
                );
                yearList?.forEach((yr) => {
                  var yrs = d1?.map((d) => d?.transyear);
                  if (yrs?.includes(yr)) {
                    let obj = d1?.find((f) => f?.transyear === yr);
                    data2?.push(obj?.sales ? parseFloat(obj?.sales!) : 0);
                  } else data2?.push(0);
                });

                if (data2?.length > 0) {
                  ydata?.push({
                    name: s?.name,
                    data: data2,
                  });
                }
              });
              // })
            });
            return ydata;
          })()}
          labels={yearList?.map((y) => y?.toString())?.sort()}
          colors={[
            colors[0],
            colors[1],
            colors[2],
            colors[3],
            colors[4],
            colors[5],
            colors[6],
            colors[7],
            colors[8],
            colors[9],
          ]}
          title={(() => {
            const txt = `Brand Contribution - YOY`;
            return txt;
          })()}
          subtitle={(() => {
            let subtxt: string[] = [];
            let subtxt1 = `\u3010Portfolio\u3011: ${propPfname} \u3010Period\u3011: ${yearList[0]} - ${yearList[2]}`;
            let subtxt2 = ``;
            if (
              props?.tradeCategory !== undefined &&
              props?.tradeCategory?.length > 0
            ) {
              subtxt1 += `\u3010TradeCategory\u3011: ${propCatname}`;
            }
            if (
              props?.tradeSubCategory !== undefined &&
              props?.tradeSubCategory?.length > 0
            ) {
              subtxt1 += `\u3010TradeSubCategory\u3011: ${propsubCatname}`;
            }
            if (props?.country !== undefined && props?.country?.length > 0) {
              subtxt2 += `\u3010Country\u3011: ${propConame}`;
            }
            if (props?.region !== undefined && props?.region?.length > 0) {
              subtxt2 += `\u3010Region\u3011: ${propRename}`;
            }
            if (props?.state !== undefined && props?.state?.length > 0) {
              subtxt2 += `\u3010State\u3011: ${propStname}`;
            }
            if (props?.city !== undefined && props?.city?.length > 0) {
              subtxt2 += `\u3010City\u3011: ${propCiname}`;
            }
            subtxt?.push(subtxt1);
            subtxt?.push(subtxt2);
            return subtxt;
          })()}
          exportfilename={"brandContribution-YOY"}
        />
      )}

{showTableModal && selection === PortfolioDashboardList?.MOM && (
              <Modal
              size="xl"
              visible = {showTableModal}
              onClose={() => setShowTableModal(false)}
              centered classname="grid-container"
              >
              <RetailPerformanceTable
                    data={getMonthlyMOMData()}
                    title={`Brand Contribution (MOM) - ${propPfname}`}
                    fileName={"pf_monthly_sales"}
                    downloadCSV={false}
                    downloadPDF={false}
                  />
              </Modal>
        )}

{showTableModal && selection === PortfolioDashboardList?.YOY && (
              <Modal
              size="xl"
              visible = {showTableModal}
              onClose={() => setShowTableModal(false)}
              centered classname="grid-container"
              >
              <RetailPerformanceTable
                    data={getYearlyYOYData()}
                    title={`Brand Contribution (YOY) - ${propPfname}`}
                    fileName={"pf_monthly_sales"}
                    downloadCSV={false}
                    downloadPDF={false}
                  />
              </Modal>
        )}
    </Widget1>
  );
};
export default BrandDashboardBrandContributionWidget;
