import React, { Suspense, useEffect, useRef, useState } from 'react';
import { Container } from 'react-bootstrap';
import { showLeftSideBar } from '../config';
import { LayoutTypes } from '../constants';
import { changeBodyAttribute } from '../utils';
import UserTopNavbar from './UserTopNavbar';

// code splitting and lazy loading
// https://blog.logrocket.com/lazy-loading-components-in-react-16-6-6cea535c0b52
const Topbar = React.lazy(() => import('./Topbar'));
const UserLeftSidebar = React.lazy(() => import('./UserLeftSidebar'));
const Footer = React.lazy(() => import('./Footer'));

const loading = () => <div className=""></div>;

interface UserLayoutProps {
    children?: any;
}

const UserLayout = ({ children }: UserLayoutProps) => {

    const [isMenuOpened, setIsMenuOpened] = useState<boolean>(false);
    const [isContentPageAtTheTop, setIsContentPageAtTheTop] = useState<boolean>(true);

    /*
    layout defaults
    */
    useEffect(() => {
        changeBodyAttribute('data-layout-mode', LayoutTypes.LAYOUT_VERTICAL);
    }, []);

    /**
     * Open the menu when having mobile screen
     */
    const openMenu = () => {
        setIsMenuOpened((prevState) => !prevState);

        if (document.body) {
            if (isMenuOpened) {
                document.body.classList.remove('sidebar-enable');
            } else {
                document.body.classList.add('sidebar-enable');
            }
        }
    };

    const contentPageRef = useRef<HTMLDivElement>(null)

    useEffect(() => {
        const scrollHandler = (e: Event) => {
            const rect = contentPageRef.current?.getBoundingClientRect()

            if (rect) {
                setIsContentPageAtTheTop(rect.top > 25)
            }

        }

        window.addEventListener('scroll', scrollHandler)
        return () => {
            window.removeEventListener('scroll', scrollHandler)
        }
    }, [])

    return (
        <>

            <div id="wrapper" style={{ background: '#ECF1FF' }}>
                <Suspense fallback={null}>
                    <Topbar openLeftMenuCallBack={openMenu} hideLogo={false} />
                </Suspense>
                <Suspense fallback={loading()}>
                    <UserTopNavbar isContentPageAtTheTop={isContentPageAtTheTop} />
                </Suspense>
                {!showLeftSideBar && <Suspense fallback={loading()}>
                    <UserLeftSidebar />
                </Suspense>}
                <div className={`content-page ${!showLeftSideBar ? 'mx-0 container-fluid mx-auto' : ''}`} ref={contentPageRef}>
                    <div className="content mt-5">
                        <Container fluid>
                            <Suspense fallback={loading()}>{children}</Suspense>
                        </Container>
                    </div>

                    <Suspense fallback={loading()}>
                        <Footer />
                    </Suspense>
                </div>
            </div>
        </>
    );
};
export default UserLayout;
