import React, { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { PortfolioDashboardList, PortfolioDashboardType } from "../../config";
import api, { APIResponse, getAxiosRequestConfig } from "../../config/api";
// import { YearOptions } from "../../config/functions";
import { PortfolioType } from "../../redux/actions/portfolioActions";
import { AuthUserType } from "../../redux/actions/authUserActions";
import { StoreState } from "../../redux/store";
import { KpiViewList } from "../commonWidget";
import Widget1 from "../custom/components/Widget1";
import BarChart from "../custom/components/charts/apex/BarChart";
import { COLORS } from "../custom/constants";
// import { BrandType } from "../../redux/actions/brandActions";
import { outletType } from "../../redux/actions/outletActions";
import { addToSelectedOption } from "../../redux/actions/selectedOptionsActions";
import { OptionsTypes } from "../../redux/actions/selectedOptionsActions";
import RetailPerformanceTable from "../custom/components/RetailPerformanceTable";
import Modal from "../Modal";
import { YearOptions } from "../../config/functions";

// interface RecordsForMonth {
//   sales: string;
//   pname: string;
//   pcode: string;
//   tenantid: number;
//   centerid: number;
//   tyear: number;
//   tmonth: number;
// }
interface RetailRecordsForYear {
  sales: string;
  pname: string;
  pcode: string;
  tenantid: number;
  centerid: number;
  tyear: number;
}
// interface MonthlyRecord {
//   portfolio: number;
//   record: RecordsForMonth[];
// }
interface YearlyRecord {
  portfolio: number;
  record: RetailRecordsForYear[];
}
interface ProductRecord {
  pcode: string;
  pname: string;
}

interface Props {
  portfolioId: number;
  brandId: number;
  outletId: string[];
  country?: number[];
  region?: number[];
  state?: number[];
  city?: number[];
  expandState: React.Dispatch<React.SetStateAction<boolean>>;
  widgetLoader2?:React.Dispatch<React.SetStateAction<boolean>>;
  apiEnabler2?:boolean;
  valueCapStr?: string;
}

const Top10ProductsSales: React.FC<Props> = (props: Props) => {
  const dispatch = useDispatch();
  const authUser = useSelector<StoreState, AuthUserType | null>(
    (state) => state?.authUser
  );
  const authToken = authUser?.["auth-token"]!;
  const config = getAxiosRequestConfig(authToken);

  // const brandList = useSelector<StoreState, Array<BrandType>>(
  //   (state) => state?.brand
  // );
  // const outletList = ['outlet 6','outlet 9','outlet 12']
  const portfolioOption = useSelector<StoreState, PortfolioType[]>(
    (state) => state?.portfolio
  );

  const outletList = useSelector<StoreState, outletType[]>(
    (state) => state?.outlet
  );

  const outletIndex = outletList?.findIndex(
    (a) => a?.id?.toString() === props?.outletId?.toString()
  );
  const propOutname = outletList[outletIndex]?.outletcode;

  const [yearlyData, setYearlyData] = useState<YearlyRecord[]>([]);
  // const [outletList, setOutletList] = useState<OutletRecord[]>([]);

  const [selection, setSelection] = useState<PortfolioDashboardType>(
    PortfolioDashboardList?.MOM
  );
  const [view, setView] = useState<string>(KpiViewList?.CHART);
  const [valueIn, setValueIn] = useState<string>("");

  const currentYear = new Date()?.getFullYear();
  const [year, setYear] = useState<string>(currentYear?.toString());
  const [showTableModal, setShowTableModal] = useState<boolean>(false);

  const selectedOption = useSelector<StoreState, OptionsTypes>(
    (state) => state?.selectedOption
  );

  const [loader, setLoader] = useState<boolean>(true);

  // const yearList = YearOptions();
  const showExpandHandler = () => {
    props?.expandState((prevState) => !prevState)
  };
  const momHandler = () => {
    setSelection(PortfolioDashboardList?.MOM);
  };
  const yoyHandler = () => {
    setSelection(PortfolioDashboardList?.YOY);
  };
  const chartViewHandler = () => {
    setView(KpiViewList?.CHART);
  };
  const tableViewHandler = () => {
    setView(KpiViewList?.TABLE);
  };
  const showGridHandler = () => {
    // props?.expandState(true)
    setShowTableModal(true)
    // setView(KpiViewList?.TABLE)
  };
  const yearList = YearOptions()

  const YearlyFilteredData = useMemo<YearlyRecord[]>(() => {
    const data: YearlyRecord[] = [];
    yearlyData?.forEach((d) => {
      data?.push({
        portfolio: d?.portfolio,
        record: d?.record?.filter((r) => {
          return year?.toString() === r?.tyear?.toString();
        }),
      });
    });
    return data;
  }, [yearlyData, year]);

  const YearlyFilteredProducts = useMemo<ProductRecord[]>(() => {
    const data: ProductRecord[] = [];
    yearlyData?.forEach((d) => {
      d?.record?.forEach((s) => {
        data?.push({
          pcode: s?.pcode,
          pname: s?.pname,
        });
      });
    });
    return data;
  }, [yearlyData, year]);

  useEffect(() => {
    let pid = props?.portfolioId ? [props?.portfolioId] : [];
    var obj = portfolioOption?.find((p) => p?.id === Number(pid));
    // let valuecaption = obj?.valuecaption ? obj?.valuecaption : "in Millions";
    let valuecaption = props?.valueCapStr ? props?.valueCapStr : "in Millions";
    setValueIn(valuecaption);
  }, [props, portfolioOption]);

  useEffect(() => {
    var getData = {
      portfolio: props?.portfolioId ? [props?.portfolioId] : [],
      brand: props?.brandId ? [props?.brandId] : [],
      site: props?.outletId ? props?.outletId : [],
      country: props?.country,
      state: props?.state,
      region: props?.region,
      city: props?.city,
      authUserRole: authUser?.roleId ? authUser?.roleId : "",
    };
    if (
      getData?.portfolio?.length > 0 &&
      props?.portfolioId &&
      getData !== undefined && props?.apiEnabler2===false
    ) {
      api
        .post<APIResponse<{ records: YearlyRecord[] }>>(
          `kpi/outlet-retail/top10prodsales/yearly`,
          getData,
          config
        )
        .then((response) => {
          if (response?.status === 200 && response?.data?.data) {
            const result = response?.data?.data?.records;
            setYearlyData(result);
            setLoader(false)
          }
          else {setYearlyData([]);
            
              setLoader(false)
            
          }
        })
        .catch((error) => {});
    } 
    
  }, [
    props?.portfolioId,
    props?.outletId,
    props?.brandId,
    props?.country,
    props?.state,
    props?.region,
    props?.city,
     props?.apiEnabler2
  ]);

  const colors = [
    COLORS?.COLOR1,
    COLORS?.COLOR2,
    COLORS?.COLOR3,
    COLORS?.COLOR4,
    COLORS?.COLOR5,
    COLORS?.COLOR6,
    COLORS?.COLOR7,
    COLORS?.COLOR8,
  ];

  useEffect(()=>{
    const data = {
      ...selectedOption,loader6:loader
    }
    dispatch(addToSelectedOption(data))
  },[loader])

  const getYearlyYOYData = () => {
    var monthlyTableData: string[][] = [
      [
        'Product',
        "Year",
        "Sales",
      ],
    ];

    yearList?.map((a)=>{
    yearlyData?.forEach((y, i) => {
          y?.record?.filter((b)=>b?.tyear?.toString()===a?.toString())
            ?.forEach((r) => {
                let sum = []
                
                sum?.push(r?.pname?.toString())
              sum?.push(r?.tyear?.toString());
              sum?.push(
                r?.sales !== undefined && r?.sales !== null ? r?.sales : "-"
              );
                monthlyTableData?.push(sum);
        });
    });
  })
    return monthlyTableData;
  };

  useEffect(()=>{
    if(loader===false){
      props?.widgetLoader2!((prevState)=>!prevState)
    }
  },[loader,props?.apiEnabler2])

  return (
    <Widget1
      title={"Top 10 Products Sales"}
      momHandler={momHandler}
      yoyHandler={yoyHandler}
      tableViewHandler={tableViewHandler}
      chartViewHandler={chartViewHandler}
      selection={selection}
      view={view}
      viewBtns={false}
      showYearFilter={true}
      setYear={setYear}
      year={year}
      valueInString={valueIn}
      showExpandModal={true}
      showExpand={true}
      showExpandHandler={showExpandHandler}
      showGridHandler={showGridHandler}
      showGrid={true}
    >
      {view === KpiViewList?.CHART && (
        <BarChart
          datasets={(() => {
            let ydata: { name: string; data: number[] }[] = [
              {
                name: "Sales",
                data: [],
              },
            ];
            YearlyFilteredData?.forEach((y, i) => {
              // const index = portfolioOption?.findIndex(
              //   (p) =>
              //     p?.id !== undefined &&
              //     p?.id?.toString() === y?.portfolio?.toString()
              // );
              // const name = portfolioOption[index]?.name;

              YearlyFilteredProducts?.forEach((s) => {
                y?.record
                  ?.filter((r) => r?.pcode?.toString() === s?.pcode?.toString())
                  ?.forEach((r) => {
                    ydata[0]?.data?.push(r?.sales ? parseFloat(r?.sales) : 0);
                  });
              });
            });
            return ydata;
          })()}
          labels={(() => {
            const labels: string[] = [];
            YearlyFilteredData?.forEach((y, i) => {
              YearlyFilteredProducts?.forEach((s) => {
                let data = y?.record
                  ?.filter((r) => r?.pcode?.toString() === s?.pcode?.toString())
                  ?.map((r) => {
                    return r?.sales ? parseFloat(r?.sales) : 0;
                  });
                if (data?.length > 0) {
                  labels?.push(s?.pname);
                }
              });
            });
            return labels;
          })()}
          colors={[
            colors[0],
            colors[1],
            colors[2],
            colors[3],
            colors[4],
            colors[5],
            colors[6],
            colors[7],
          ]}
          title={(() => {
            const txt = `Top 10 Products Sales`;
            return txt;
          })()}
          subtitle={(() => {
            let subtxt = ``;
            if (propOutname !== undefined) {
              subtxt += `\u3010Outlet\u3011: ${propOutname}\u2003`;
            }
            subtxt += `\u3010Year\u3011: ${year}\u2003`;
            return subtxt;
          })()}
          exportfilename={"Top10ProductsSales"}
        />
      )}


{showTableModal &&  (
              <Modal
              size="xl"
              visible = {showTableModal}
              onClose={() => setShowTableModal(false)}
              centered classname="grid-container"
              >
              <RetailPerformanceTable
                    data={getYearlyYOYData()}
                    title={`Top 10 Product Sales`}
                    fileName={"pf_monthly_sales"}
                    downloadCSV={false}
                    downloadPDF={false}
                  />
              </Modal>
        )}
    </Widget1>
  );
};
export default Top10ProductsSales;
