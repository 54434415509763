import React, { useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { PortfolioDashboardList, PortfolioDashboardType } from "../../config";
import api, { APIResponse, getAxiosRequestConfig } from "../../config/api";
import { YearOptions } from "../../config/functions";
import { PortfolioType } from "../../redux/actions/portfolioActions";
import { AuthUserType } from "../../redux/actions/authUserActions";
import { StoreState } from "../../redux/store";
import { KpiViewList } from "../commonWidget";
import Widget1 from "../custom/components/Widget1";
import BarChart from "../custom/components/charts/apex/BarChart";
import { COLORS } from "../custom/constants";
// import { BrandType } from "../../redux/actions/brandActions";
import { outletType } from "../../redux/actions/outletActions";
import RetailPerformanceTable from "../custom/components/RetailPerformanceTable";
import Modal from "../Modal";

interface RetailRecordsForYear {
  sales: string;
  transdate: string;
  transyear: number;
  tenantid: number;
  centerid: number;
  brandid: number;
}
interface YearlyRecord {
  portfolio: number;
  record: RetailRecordsForYear[];
}

interface Props {
  portfolioId: number;
  brandId: number;
  outletId: string[];
  expandState: React.Dispatch<React.SetStateAction<boolean>>;
  commonApiEnabler?:boolean;
  valueCapStr?: string;
}

const OutletSalesOfDayYearWise: React.FC<Props> = (props: Props) => {
  const authUser = useSelector<StoreState, AuthUserType | null>(
    (state) => state?.authUser
  );
  const authToken = authUser?.["auth-token"]!;
  const config = getAxiosRequestConfig(authToken);

  // const brandList = useSelector<StoreState, Array<BrandType>>(
  //   (state) => state?.brand
  // );
  const portfolioOption = useSelector<StoreState, PortfolioType[]>(
    (state) => state?.portfolio
  );

  const outletList = useSelector<StoreState, outletType[]>(
    (state) => state?.outlet
  );

  const outletIndex = outletList?.findIndex(
    (a) => a?.id?.toString() === props?.outletId?.toString()
  );
  const propOutname = outletList[outletIndex]?.outletcode;

  const [yearlyData, setYearlyData] = useState<YearlyRecord[]>([]);

  const [selection, setSelection] = useState<PortfolioDashboardType>(
    PortfolioDashboardList?.MOM
  );
  const [view, setView] = useState<string>(KpiViewList?.CHART);
  const [valueIn, setValueIn] = useState<string>("");

  const currentYear = new Date()?.getFullYear();
  const [year, setYear] = useState<string>(currentYear?.toString());
  const [showTableModal, setShowTableModal] = useState<boolean>(false);

  const yearList = YearOptions();
  const onlyUnique = <V, I>(value: V, index: I, self: V[]) => {
    return self?.indexOf(value) === index;
  };
  const showExpandHandler = () => {
    props?.expandState((prevState) => !prevState)
  };
  const momHandler = () => {
    setSelection(PortfolioDashboardList?.MOM);
  };
  const yoyHandler = () => {
    setSelection(PortfolioDashboardList?.YOY);
  };
  const chartViewHandler = () => {
    setView(KpiViewList?.CHART);
  };
  const tableViewHandler = () => {
    setView(KpiViewList?.TABLE);
  };
  const showGridHandler = () => {
    // props?.expandState(true)
    setShowTableModal(true)
    // setView(KpiViewList?.TABLE)
  };
  const YearlyFilteredData = useMemo<YearlyRecord[]>(() => {
    const data: YearlyRecord[] = [];
    yearlyData?.forEach((d) => {
      data?.push({
        portfolio: d?.portfolio,
        record: yearList
          ?.sort()
          ?.map((yr) => {
            return d?.record?.filter((r) => {
              return yr === r?.transyear;
            });
          })
          ?.flat(1),
      });
    });
    return data;
  }, [yearlyData, yearList]);

  useEffect(() => {
    let pid = props?.portfolioId ? [props?.portfolioId] : [];
    var obj = portfolioOption?.find((p) => p?.id === Number(pid));
    // let valuecaption = obj?.valuecaption ? obj?.valuecaption : "in Millions";
    let valuecaption = props?.valueCapStr ? props?.valueCapStr : "in Millions";
    setValueIn(valuecaption);
  }, [props, portfolioOption]);

  useEffect(() => {
    var getData = {
      portfolio: props?.portfolioId ? [props?.portfolioId] : [],
      site: props?.outletId ? props?.outletId : [],
      brand: props?.brandId ? [props?.brandId] : [],
      authUserRole: authUser?.roleId ? authUser?.roleId : "",
    };
    if (
      getData?.portfolio?.length > 0 &&
      props?.portfolioId &&
      getData !== undefined && props?.commonApiEnabler
    ) {
      api
        .post<APIResponse<{ records: YearlyRecord[] }>>(
          `kpi/outlet-retail/salesofday/yearly`,
          getData,
          config
        )
        .then((response) => {
          if (response?.status === 200 && response?.data?.data) {
            const result = response?.data?.data?.records;
            setYearlyData(result);
          }
          else {setYearlyData([]);}
        })
        .catch((error) => {});
    } 
  }, [props?.portfolioId, props?.outletId,props?.commonApiEnabler]);

  useEffect(() => {
    let pid = props?.portfolioId ? [props?.portfolioId] : [];
    var obj = portfolioOption?.find((p) => p?.id === Number(pid));
    // let valuecaption = obj?.valuecaption ? obj?.valuecaption : "in Millions";
    let valuecaption = props?.valueCapStr ? props?.valueCapStr : "in Millions";
    setValueIn(valuecaption);
  }, [props, portfolioOption]);

  useEffect(() => {
    var getData = {
      portfolio: props?.portfolioId ? [props?.portfolioId] : [],
      site: props?.outletId ? props?.outletId : [],
    };
    if (
      getData?.portfolio?.length > 0 &&
      props?.portfolioId &&
      getData !== undefined && props?.commonApiEnabler
    ) {
      api
        .post<APIResponse<{ records: YearlyRecord[] }>>(
          `kpi/outlet-retail/salesofday/yearly`,
          getData,
          config
        )
        .then((response) => {
          if (response?.status === 200 && response?.data?.data) {
            const result = response?.data?.data?.records;
            setYearlyData(result);
          }
          else {setYearlyData([])}
        })
        .catch((error) => {});
    } 
  }, [props?.portfolioId, props?.outletId,props?.commonApiEnabler]);

  const colors = [
    COLORS?.COLOR1,
    COLORS?.COLOR2,
    COLORS?.COLOR3,
    COLORS?.COLOR4,
    COLORS?.COLOR5,
    COLORS?.COLOR6,
    COLORS?.COLOR7,
    COLORS?.COLOR8,
  ];

  const getYearlyYOYData = () => {
    var monthlyTableData: string[][] = [
      [
        "Year",
        'Date',
        "Sales",
      ],
    ];

    yearList?.map((a)=>{
   
    yearlyData?.forEach((y, i) => {
      const dateArray = y?.record
      ?.map((y, j) => y?.transdate)
      ?.filter(onlyUnique);
      dateArray?.forEach((c) => {
          y?.record?.filter((b)=>b?.transyear?.toString()===a?.toString()&&b?.transdate?.toString()===c?.toString())
            ?.forEach((r) => {
                let sum = []
              sum?.push(r?.transyear?.toString());
              sum?.push(r?.transdate?.toString());
              sum?.push(
                r?.sales !== undefined && r?.sales !== null ? r?.sales : "-"
              );
                monthlyTableData?.push(sum);
            })
        });
    });
  })
    return monthlyTableData;
  };

  return (
    <Widget1
      title={"Store Sales of Day (Yearwise)"}
      momHandler={momHandler}
      yoyHandler={yoyHandler}
      tableViewHandler={tableViewHandler}
      chartViewHandler={chartViewHandler}
      selection={selection}
      view={view}
      viewBtns={false}
      showYearFilter={false}
      setYear={setYear}
      year={year}
      valueInString={valueIn}
      showExpandModal={true}
      showExpand={true}
      showExpandHandler={showExpandHandler}
      showGridHandler={showGridHandler}
      showGrid={true}
    >
      {view === KpiViewList?.CHART && (
        <BarChart
          datasets={(() => {
            let ydata: { name: string; data: number[] }[] = [];
            YearlyFilteredData?.forEach((y, i) => {
              // const index = portfolioOption?.findIndex(
              //   (p) =>
              //     p?.id !== undefined &&
              //     p?.id?.toString() === y?.portfolio?.toString()
              // );
              // const name = portfolioOption[index]?.name;
              const dateArray = y?.record
                ?.map((y, j) => y?.transdate)
                ?.filter(onlyUnique);
              dateArray?.forEach((c) => {
                let data2: number[] = [];
                let d1 = y?.record?.filter(
                  (sw) => sw?.transdate?.toString() === c?.toString()
                );
                yearList?.forEach((yr) => {
                  var yrs = d1?.map((d) => d?.transyear);
                  if (yrs?.includes(yr)) {
                    let obj = d1?.find(
                      (f) => f?.transyear?.toString() === yr?.toString()
                    );
                    data2?.push(obj?.sales ? parseFloat(obj?.sales!) : 0);
                  } else data2?.push(0);
                });
                if (data2?.length > 0) {
                  ydata?.push({
                    name: c?.toString(),
                    data: data2,
                  });
                }
              });
            });
            return ydata;
          })()}
          labels={yearList?.map((y) => y?.toString())?.sort()}
          colors={[
            colors[0],
            colors[1],
            colors[2],
            colors[3],
            colors[4],
            colors[5],
            colors[6],
            colors[7],
          ]}
          title={"Store Sales Of Day - YearWise"}
          subtitle={(() => {
            let subtxt = ``;
            if (propOutname !== undefined) {
              subtxt += `\u3010Outlet\u3011: ${propOutname}\u2003`;
            }
            subtxt += `\u3010Period\u3011: ${yearList[0]} - ${yearList[2]}\u2003`;
            return subtxt;
          })()}
          exportfilename={"SalesOfDay-YearWise"}
        />
      )}

{showTableModal &&  (
              <Modal
              size="xl"
              visible = {showTableModal}
              onClose={() => setShowTableModal(false)}
              centered classname="grid-container"
              >
              <RetailPerformanceTable
                    data={getYearlyYOYData()}
                    title={`Store Sales Of Day (YearWise)`}
                    fileName={"pf_monthly_sales"}
                    downloadCSV={false}
                    downloadPDF={false}
                    percent = {false}
                  />
              </Modal>
        )}
    </Widget1>
  );
};
export default OutletSalesOfDayYearWise;
