import { nanoid } from "@reduxjs/toolkit";
import React, { ChangeEventHandler, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Option from "../components/Option";
import { FormInput } from "../components/custom/components";
import {
  Tbody,
  Thead,
} from "../components/custom/components/TradeCategoryPerformanceTable";
import TransactionTable from "../components/custom/components/TransactionTable";
import UserLayout from "../components/custom/layouts/UserLayout";
import tableIcon from "../components/custom/assets/images/dashboard/table_icon.svg";
import FormInput2 from "../components/custom/components/FormInput2";
import { ToastTypes, useToast } from "../components/toast/ToastProvider";
import { defaultbrand, defaultLocation, defaultPortfolio, defaultTradeCategory, defaultTradeSubCategory, DropDownOption1, PAGE_SIZE } from "../config";
import api, { APIResponse, getAxiosRequestConfig } from "../config/api";
import {
  CSVdownload,
  getYesterday,
  handleDownloadPDF,
  today,
} from "../config/functions";
import { AuthUserType } from "../redux/actions/authUserActions";
import { BrandType } from "../redux/actions/brandActions";
import { BrandCompanyType } from "../redux/actions/brandCompanyActions";
import { PortfolioType } from "../redux/actions/portfolioActions";
import {
  OptionsTypes,
  addToSelectedOption,
} from "../redux/actions/selectedOptionsActions";
import { SiteType } from "../redux/actions/siteActions";
import { TradeCategoryType } from "../redux/actions/tradeCategoryActions";
import { TradeSubCategoryType } from "../redux/actions/tradeSubCategoryActions";
import { StoreState } from "../redux/store";
import Loader from "../components/custom/components/Loader";
import { areaType } from "../redux/actions/areaActions";
import { outletType } from "../redux/actions/outletActions";
import { useLocation } from "react-router-dom";
import { UserLog } from "../config/common";
import SingleSelect from "../components/custom/components/SingleSelect";
import SingleSelect1 from "../components/custom/components/SingleSelect1";

export interface DropDownOption {
  value: number;
  label: string;
  imgUrl: string;
}

export interface BillWiseRecord {
  receiptno: number;
  receiptdate: string;
  receiptamount: string;
  tax: number;
  return: string;
  returntax: string;
  netsale: number;
  paymentmode: string;
  passengername: string;
  gender: string;
  passportno: number;
  flightseatno: string;
  bookingrefno: string;
  dateofdeparture: string;
  destinationororigin: string;
  postterminalid: string;
  // shiftno: number
  centertype?: string;
  rsvalue?: string;
  rspercent?: string;
}

export interface BillWiseReportType {
  portfolio: number;
  record: BillWiseRecord[];
  count?: number;
}

export interface BuildingType {
  centerid: number;
  buildingid: number;
  buildingname: string;
  buildingcode: string;
}

export interface SubCatWithBrandCompanyType {
  tradesubcatid: number;
  brandcompanyids: string;
}

export interface SubCatWithBrandCompanyRelationDataType {
  portfolio: number;
  record: SubCatWithBrandCompanyType[];
}

export interface CatSubCatCompanyWithBrandType {
  tradecatid: number;
  tradesubcatids: string;
  brandcompanyids: string;
  brandids: string;
}

export interface CatSubCatCompanyWithBrandRelationDataType {
  portfolio: number;
  record: CatSubCatCompanyWithBrandType[];
}

export interface BuildingDataType {
  portfolio: number;
  record: BuildingType[];
}

export interface FloorType {
  centerid: number;
  buildingid: number;
  floorid: number;
  floorname: string;
  floorcode: string;
}

export interface FloorDataType {
  portfolio: number;
  record: FloorType[];
}

const TransactionBillWiseReport: React.FC = () => {
  const toast = useToast();
  const dispatch = useDispatch();
  const pageName = useLocation()
  const authUser = useSelector<StoreState, AuthUserType | null>(
    (state) => state?.authUser
  );
  const authUserToken = useSelector<StoreState, AuthUserType | null>(
    (state) => state?.authUser
  )?.["auth-token"]!;
  const config = getAxiosRequestConfig(authUserToken);
  const authUserSiteId = authUser?.siteId;
  const portfolioOption = useSelector<StoreState, PortfolioType[]>(
    (state) => state?.portfolio
  );
  const siteOption = useSelector<StoreState, SiteType[]>((state) => state?.site);
  const brandOption = useSelector<StoreState, BrandType[]>(
    (state) => state?.brand
  );
  const brandCompanyOption = useSelector<StoreState, BrandCompanyType[]>(
    (state) => state?.brandCompany
  );

  const filteredBrandCompany = brandCompanyOption?.filter(
    (a) => a?.retailid !== null
  );

  const filteredBrand = brandOption?.filter((a) => {
    return brandCompanyOption?.some(
      (b) =>
        b?.id?.toString() === a?.companyid?.toString() && b?.retailid !== null
    );
  });

  const selectedOption = useSelector<StoreState, OptionsTypes>(
    (state) => state?.selectedOption
  );
  const outletList = useSelector<StoreState, outletType[]>(
    (state) => state?.outlet
  );
  
  const localityOption = useSelector<StoreState, areaType[]>(
    (state) => state?.locality
  );
  const port =
    authUser?.portfolioId !== undefined && authUser?.portfolioId !== null
      ? authUser?.portfolioId?.split(",")
      : [];

  let portfolioData = port
    ?.map((pe) => {
      return portfolioOption?.filter(
        (f) => f?.id?.toString() === pe?.toString()
      );
    })
    ?.flat(1);

  const st =
    authUser?.siteId !== undefined && authUser?.siteId !== null
      ? authUser?.siteId?.split(",")
      : [];

  let siteData = st
    ?.map((pe) => {
      return siteOption?.filter((f) => f?.id?.toString() === pe?.toString());
    })
    ?.flat(1);

  const br =
    authUser?.brandId !== undefined && authUser?.brandId !== null
      ? authUser?.brandId?.split(",")
      : [];

  let brandData = br
    ?.map((b) => {
      return filteredBrand?.filter((f) => f?.id?.toString() === b?.toString());
    })
    ?.flat(1);

  const categoryOption = useSelector<StoreState, TradeCategoryType[]>(
    (state) => state?.tradeCategory
  );
  const subCategoryOption = useSelector<StoreState, TradeSubCategoryType[]>(
    (state) => state?.tradeSubCategory
  );

  // const [catSubCatCompanyWithBrandRelationData, setCatSubCatCompanyWithBrandRelationData] = useState<CatSubCatCompanyWithBrandRelationDataType[]>([])
  const [
    catSubCatCompanyWithBrandRelationData,
    setCatSubCatCompanyWithBrandRelationData,
  ] = useState<CatSubCatCompanyWithBrandRelationDataType[]>([]);

  const [maxFromDate, setMaxFromDate] = useState(getYesterday());
  const [fromDate, setFromDate] = useState<string>(getYesterday());
  const [toDate, setToDate] = useState<string>(today());

  const [valueIn, setValueIn] = useState<string>("");

  const [portfolios, setPortfolios] = useState<string>("");
  const [sites, setSites] = useState<string>("");
  const [brands, setBrands] = useState<string>("");

  const [location, setLocation] = useState<string>("");
  const [categories, setCategories] = useState<string>("");
  const [subCategories, setSubCategories] = useState<string>("");

  //loader
  const [loader, setLoader] = useState<boolean>(false);
  const [pag, setPag] = useState<boolean>(false);

  const [billWiseData, setBillWiseData] = useState<BillWiseReportType[]>([]);

  const [fileLoader, setFileLoader] = useState<boolean>(false);

  const limit = PAGE_SIZE;
  const [offset, setOffset] = useState<number>(0);
  const [range, setRange] = useState<number[]>([]);
  const [length, setLength] = useState<number>(50);

  const [selectedRows, setSelectedRows] = useState<string[]>([]);
  const [disableLocality, setDisableLocality] = useState<boolean>(false);
  const [condition,setCondition] = useState<boolean>(false)

  const [portfolioDrop, setPortfolioDrop] = useState<DropDownOption[]>([]);
  const [locationDrop, setLocationDrop] = useState<DropDownOption[]>([]);
  const [catDrop,setCatDrop] = useState<DropDownOption[]>([])
  const [subcatDrop,setSubcatDrop] = useState<DropDownOption[]>([])
  const [brandDrop,setBrandDrop] = useState<DropDownOption[]>([])

  const handleSelectedRowsChange = (selectedRows: string[]) => {
    setSelectedRows(selectedRows);
  };

  const handleOff = (conditionVal: boolean) => {
    setCondition(conditionVal);
  };


  const subCategoryFilteredByCategory = useMemo<TradeSubCategoryType[]>(() => {
    if (categories !== "") {
      return subCategoryOption?.filter((sc) => {
        return sc?.catid?.toString() === categories?.toString();
      });
    } else return subCategoryOption;
  }, [categories, subCategoryOption]);

  const BrandFilteredByCompany = useMemo<BrandType[]>(() => {
    if (subCategories !== "") {
      let brands: BrandType[] = [];
      catSubCatCompanyWithBrandRelationData?.forEach((s) => {
        let obj = s?.record?.find((r) =>
          r?.tradesubcatids?.split(",")?.includes(subCategories)
        );
        obj?.brandids?.split(",")?.forEach((m) => {
          let bc = brandData?.find((f) => f?.id?.toString() === m);
          brands?.push(bc!);
        });
      });
      return brands;
    } else if (categories !== "") {
      let brands: BrandType[] = [];
      catSubCatCompanyWithBrandRelationData?.forEach((s) => {
        let obj = s?.record?.find((r) => r?.tradecatid?.toString() === categories);
        obj?.brandids?.split(",")?.forEach((m) => {
          let bc = brandData?.find((f) => f?.id?.toString() === m);
          brands?.push(bc!);
        });
      });
      return brands;
    } else return brandData;
  }, [
    brandData,
    catSubCatCompanyWithBrandRelationData,
    categories,
    subCategories,
  ]);

  const handleFromDate: ChangeEventHandler<HTMLInputElement> = (e) => {
    setFromDate(e?.target?.value);
    setPag(false);
  };

  const handleToDate: ChangeEventHandler<HTMLInputElement> = (e) => {
    setToDate(e?.target?.value);
    setPag(false);
  };

  const handlePortfolioDrop = (selected: any) => {
    if(fileLoader===false){
    if(selected.length>0){
      const selectedPortfolio = selected as DropDownOption[];
      if(selectedPortfolio[0].value!==null&&selectedPortfolio[0].value!==0){
        setPortfolioDrop(selectedPortfolio)
        setPortfolios(selectedPortfolio[0]?.value?.toString())
        const data = { ...selectedOption, portfolio: selectedPortfolio[0]?.value?.toString() };
        dispatch(addToSelectedOption(data));
        setOffset(0);
        setPag(false);
      }
    }
  }
  };

  const handleClearPortfolio = () =>{
    let pid =portfolioData[0] && portfolioData[0]?.id? portfolioData[0].id?.toString(): "";
    setPortfolios(selectedOption.portfolio ? selectedOption.portfolio : pid);
    const b = portfolioOption.filter((a)=>a?.id?.toString()===pid?.toString())
    if (b?.length > 0 && b[0]?.id !== undefined) {
      const val: DropDownOption = {
        value: b[0]?.id, 
        label: b[0]?.name,
        imgUrl: b[0]?.logourl, 
      };
      setPortfolioDrop([val]);

      if(fileLoader===false){
      setLocation("");
      setLocationDrop([]);
      const data = { ...selectedOption, portfolio: b[0]?.id?.toString(),location:''};
      dispatch(addToSelectedOption(data));
      setOffset(0);
      setPag(false);
    }
  }
  }

  const handleLocationDrop = (selected: any) => {
    if(fileLoader===false){
    if(selected.length>0){
      const selectedLocation = selected as DropDownOption[];
      if(selectedLocation[0].value!==null&&selectedLocation[0].value!==0){
        setLocationDrop(selectedLocation)
        setLocation(selectedLocation[0]?.label?.toString())
       const data = { ...selectedOption,location: selectedLocation[0]?.label?.toString()};
       dispatch(addToSelectedOption(data));
       setOffset(0);
       setPag(false);
      }
    }
  }
  };
  
  const handleClearLocation = () =>{
       if(fileLoader===true){
        setLocation(location ? location : '');
        const b = localityOption.filter((a)=>a?.area?.toString()===location?.toString())
        if (b?.length > 0 && b[0]?.id !== undefined) {
          const val: DropDownOption = {
            value: b[0]?.id, 
            label: b[0]?.area,
            imgUrl:'', 
          };
          setLocationDrop([val]);
       }
       else{
        setLocationDrop([])
     }
      }
     
       if(fileLoader===false){
         setLocationDrop([])
         setLocation('')
       const data = { ...selectedOption,location: ""};
        dispatch(addToSelectedOption(data));
        setOffset(0);
        setPag(false);
       }
  }

  const handleCatDrop = (selected: any) => {
    if(fileLoader===false){
    if(selected.length>0){
      const selectedCat = selected as DropDownOption[];
      if(selectedCat[0].value!==null&&selectedCat[0].value!==0){
        setCatDrop(selectedCat)
        setCategories(selectedCat[0]?.value?.toString())
        setSubcatDrop([])
        setSubCategories('')
        setBrandDrop([])
        setBrands('')
        const data = {
          ...selectedOption,
          category: selectedCat[0]?.value?.toString(),
          subCategory: "",
          brand: "",
        };
        dispatch(addToSelectedOption(data));
        setOffset(0);
        setPag(false);
      }
    }
  }
  };
  
  const handleClearCat = () =>{
    if(fileLoader===true){
      setCategories(categories ? categories : '');
      const b = categoryOption.filter((a)=>a?.id?.toString()===categories?.toString())
      if (b?.length > 0 && b[0]?.id !== undefined) {
        const val: DropDownOption = {
          value: b[0]?.id, 
          label: b[0]?.name,
          imgUrl:'', 
        };
        setCatDrop([val]);
     }
     else{
      setCatDrop([])
   }
    }
   
     if(fileLoader===false){
      setCatDrop([])
      setCategories('')
      setSubcatDrop([])
        setSubCategories('')
        setBrandDrop([])
        setBrands('')
       const data = { ...selectedOption,
        category: '',
        subCategory: "",
        brand: "",};
       dispatch(addToSelectedOption(data));
       setOffset(0);
       setPag(false);
     }
  }

  const handleSubcatDrop = (selected: any) => {
    if(fileLoader===false){
    if(selected.length>0){
      const selectedSubcat = selected as DropDownOption[];
      if(selectedSubcat[0].value!==null&&selectedSubcat[0].value!==0){
        setSubcatDrop(selectedSubcat)
        setSubCategories(selectedSubcat[0]?.value?.toString())
        setBrandDrop([])
        setBrands('')
        const data = { ...selectedOption,
          subCategory: selectedSubcat[0]?.value?.toString(),
          brand: "",};
         dispatch(addToSelectedOption(data));
         setOffset(0);
         setPag(false);
      }
    }
  }
  };
  
  const handleClearSubcat = () =>{
    if(fileLoader===true){
      setSubCategories(subCategories ? subCategories : '');
      const b = subCategoryOption.filter((a)=>a?.id?.toString()===subCategories?.toString())
      if (b?.length > 0 && b[0]?.id !== undefined) {
        const val: DropDownOption = {
          value: b[0]?.id, 
          label: b[0]?.name,
          imgUrl:'', 
        };
        setSubcatDrop([val]);
     }else{
      setSubcatDrop([])
   }
    }
   
     if(fileLoader===false){
      setSubcatDrop([])
        setSubCategories('')
        setBrandDrop([])
        setBrands('')
       const data = { ...selectedOption,
        subCategory: "",
        brand: "",};
       dispatch(addToSelectedOption(data));
       setOffset(0);
       setPag(false);
     }
  }

  const handleBrandDrop = (selected: any) => {
    if(fileLoader===false){
    if(selected.length>0){
      const selectedBrand = selected as DropDownOption[];
      if(selectedBrand[0].value!==null&&selectedBrand[0].value!==0){
        setBrandDrop(selectedBrand)
        setBrands(selectedBrand[0]?.value?.toString())
       const data = { ...selectedOption,
        brand: selectedBrand[0]?.value?.toString()};
       dispatch(addToSelectedOption(data));
       setOffset(0);
       setPag(false);
      }
    }
  }
  };
  
  const handleClearBrand = () =>{
    if(fileLoader===true){
      setBrands(brands ? brands : '');
      const b = brandOption.filter((a)=>a?.id?.toString()===brands?.toString())
      if (b?.length > 0 && b[0]?.id !== undefined) {
        const val: DropDownOption = {
          value: b[0]?.id, 
          label: b[0]?.name,
          imgUrl: b[0]?.logourl, 
        };
        setBrandDrop([val]);
     }else {
      setBrandDrop([]);
     }
    }
   
     if(fileLoader===false){
        setBrandDrop([])
        setBrands('')
       const data = { ...selectedOption,
        brand: ""};
       dispatch(addToSelectedOption(data));
       setOffset(0);
       setPag(false);
     }
  }




  const portFilter = portfolioOption?.filter((a)=>a?.id?.toString()===portfolios?.toString())
  const catFilter = categoryOption?.filter((a)=>a?.id?.toString()===categories?.toString())
  const subcatFilter = subCategoryOption?.filter((a)=>a?.id?.toString()===subCategories?.toString())
  const brandFilter = brandOption?.filter((a)=>a?.id?.toString()===brands?.toString())
  const localityFilter = localityOption?.filter((a)=>a?.area?.toString()===location?.toString())


  const combinedValues = [
    ...portFilter?.map(a => `Portfolio: ${a?.name}`),           
    ...catFilter?.map(c=> `Trade Category: ${c?.name}`),
    ...subcatFilter?.map(d=> `Trade Sub Category: ${d?.name}`),
    ...brandFilter?.map(f=> `Brand: ${f?.name}`),  
    ...localityFilter?.map(f=>`Locality: ${f?.area}`),     
    `Period: ${fromDate?.toString()} - ${toDate?.toString()}`,
  ]
  ?.filter(name => name && name?.trim() !== '') 
  ?.join(', ');  
  
 

  const tHead = useMemo<Thead[]>(() => {
    var theadArray = [
      { id: "Receipt No", children: "Receipt No", text: "Receipt No" },
      { id: "Receipt Date", children: "Receipt Date", text: "Receipt Date" },
      {
        id: "Receipt Amount",
        children: "Receipt Amount",
        text: "Receipt Amount",
      },
      { id: "Tax", children: "Tax", text: "Tax" },
      { id: "Return", children: "Return", text: "Return" },
      { id: "Return Tax", children: "Return Tax", text: "Return Tax" },
      { id: "Net Sale", children: "Net Sale", text: "Net Sale" },
      { id: "Payment Mode", children: "Payment Mode", text: "Payment Mode" },
      {
        id: "Passenger Name",
        children: "Passenger Name",
        text: "Passenger Name",
      },
      { id: "Gender", children: "Gender", text: "Gender" },
    ];
    if(!selectedOption?.isOutletUser){
      theadArray?.push(
        { id: "Revenue Share(%)", children: "Revenue Share(%)", text: "Revenue Share(%)" },
        { id: "Revenue", children: "Revenue", text: "Revenue" },
      )
    }
    return theadArray;
  }, []);

  const fetchDataForDownload = async () => {
    let error = false;
    if (portfolios === "") {
      toast("Select Portfolio", ToastTypes?.ERROR);
      error = true;
    } else if (brands === "") {
      toast("Select Brand", ToastTypes?.ERROR);
      error = true;
    } else if (fromDate === "") {
      toast("Select fromDate", ToastTypes?.ERROR);
      error = true;
    } else if (toDate === "") {
      toast("Select toDate", ToastTypes?.ERROR);
      error = true;
    }

    var getData = {
      portfolio: portfolio,
      site: site,
      locality: location===defaultLocation?.label?'':location,
      tradeCategory: category,
      tradeSubCategory: subCategory,
      brand: brand,
      fromDate: fromDate,
      toDate: toDate,
      authUserRole: authUser?.roleId ? authUser?.roleId : "",
    };

    if (error === false && getData !== undefined) {
      const response = await api.post<
        APIResponse<{ records: BillWiseReportType[] }>
      >(`kpi/report/billwise`, getData, config);

      if (response?.status === 200 && response?.data?.data) {
        return response?.data?.data?.records;
      }
    }

    return [];
  };

  const getDownloadData = (Data: BillWiseReportType[]) => {
    var tableHead: Thead[] = tHead;
    var tableBody: Tbody[][] = [];
    if (
      Data !== undefined &&
      Data?.length > 0 &&
      Data[0] !== undefined &&
      Data[0]?.record !== undefined &&
      Data[0]?.record[0] !== undefined &&
      Data[0]?.record[0]?.centertype === "airport"
    ) {
      tableHead?.push(
        { id: "Passport No", children: "Passport No", text: "Passport No" },
        {
          id: "Flight & Seat No",
          children: "Flight & Seat No",
          text: "Flight & Seat No",
        },
        {
          id: "Booking Ref No",
          children: "Booking Ref No",
          text: "Booking Ref No",
        },
        {
          id: "Date Of Departure",
          children: "Date Of Departure",
          text: "Date Of Departure",
        },
        {
          id: "Destination/Origin",
          children: "Destination/Origin",
          text: "Destination/Origin",
        },
        {
          id: "Post Terminal Id",
          children: "Post Terminal Id",
          text: "Post Terminal Id",
        }
        // { id: 'Shift No', children: 'Shift No', text: 'Shift No' }
      );
    }

    const tbody = Data;

    tbody?.forEach((bill, i) => {
      bill?.record?.forEach((item) => {
        //     selectedYears?.forEach((sy) => {
        var tBody: Tbody[] = [];
        tBody?.push(
          {
            id: nanoid(),
            theadId: "Receipt No",
            children: item?.receiptno,
            text: item?.receiptno?.toString(),
          },
          {
            id: nanoid(),
            theadId: "Receipt Date",
            children: item?.receiptdate,
            text: item?.receiptdate,
          },
          {
            id: nanoid(),
            theadId: "Receipt Amount",
            children: item?.receiptamount,
            text: item?.receiptamount,
          },
          {
            id: nanoid(),
            theadId: "Tax",
            children: item?.tax,
            text: item?.tax?.toString(),
          },
          {
            id: nanoid(),
            theadId: "Return",
            children: item?.return,
            text: item?.return,
          },
          {
            id: nanoid(),
            theadId: "Return Tax",
            children: item?.returntax,
            text: item?.returntax,
          },
          {
            id: nanoid(),
            theadId: "Net Sale",
            children: item?.netsale,
            text: item?.netsale?.toString(),
          },
          {
            id: nanoid(),
            theadId: "Payment Mode",
            children: item?.paymentmode,
            text: item?.paymentmode,
          },
          {
            id: nanoid(),
            theadId: "Passenger Name",
            children: item?.passengername,
            text: item?.passengername,
          },
          {
            id: nanoid(),
            theadId: "Gender",
            children: item?.gender,
            text: item?.gender,
          },
          {
            id: nanoid(),
            theadId: "Passport No",
            children: item?.passportno,
            text: item?.passportno?.toString(),
          },
          {
            id: nanoid(),
            theadId: "Flight & Seat No",
            children: item?.flightseatno,
            text: item?.flightseatno,
          },
          {
            id: nanoid(),
            theadId: "Booking Ref No",
            children: item?.bookingrefno,
            text: item?.bookingrefno,
          },
          {
            id: nanoid(),
            theadId: "Date Of Departure",
            children: item?.dateofdeparture,
            text: item?.dateofdeparture,
          },
          {
            id: nanoid(),
            theadId: "Destination/Origin",
            children: item?.destinationororigin,
            text: item?.destinationororigin,
          },
          {
            id: nanoid(),
            theadId: "Post Terminal Id",
            children: item?.postterminalid,
            text: item?.postterminalid,
          }
          //  {
          //     id: nanoid(),
          //     theadId: 'Shift No',
          //     children: item?.shiftno,
          //     text: item?.shiftno?.toString()
          // }
        );
        tableBody?.push(tBody);
      });
    });
    return { tbody: tableBody, thead: tableHead };
  };

  const handlePag = () => {
    setPag(true);
  };

  const handleApply = () => {
    let error = false;
    if (portfolios === "") {
      toast("Select Portfolio", ToastTypes?.ERROR);
      error = true;
    } else if (brands === "") {
      toast("Select Brand", ToastTypes?.ERROR);
      error = true;
    } else if (fromDate === "") {
      toast("Select fromDate", ToastTypes?.ERROR);
      error = true;
    } else if (toDate === "") {
      toast("Select toDate", ToastTypes?.ERROR);
      error = true;
    }
    var getData = {
      portfolio: portfolio,
      site: site,
      locality: location===defaultLocation?.label?'':location,
      tradeCategory: category,
      tradeSubCategory: subCategory,
      brand: brand,
      fromDate: fromDate,
      toDate: toDate,
      limit: limit?.toString(),
      offset: offset?.toString(),
      authUserRole: authUser?.roleId ? authUser?.roleId : "",
    };

    if (
      error === false &&
      getData !== undefined &&
      getData?.limit !== "" &&
      getData?.offset !== ""
    ) {
      if (pag === false) {
        setLoader(true);
      }
      api
        .post<APIResponse<{ records: BillWiseReportType[] }>>(
          `kpi/report/billwise`,
          getData,
          config
        )
        .then((response) => {
          if (response?.status === 200 && response?.data?.data) {
            const result = response?.data?.data?.records;

            setLoader(false);
            setPag(true);
            setBillWiseData(result);
            setCondition(false)
            if(authUser){
              const logProps1 = {
                action: 'Apply',
                event: `${pageName?.pathname?.replace("\\", " ")} page | Filters - ${combinedValues} | Apply Clicked | Data Status: ${result?.[0]?.count && result[0]?.count > 0 ? 'Success' : 'Empty'}`
              };
              UserLog(logProps1)
            }
          }
        })
        .catch((error) => {});
    } else {
      setBillWiseData([]);
      setLoader(false);
      setPag(true);
      setCondition(false)
    }
  };
  useEffect(()=>{    
    if(selectedOption?.isOutletUser){
      setDisableLocality(true);
    }
    else{
      setDisableLocality(false);
    }
  },[authUser, outletList,selectedOption]);


  useEffect(() => {
    if (portfolios !== "" && brands !== "" && fileLoader === false) {
      handleApply();
    }
  }, [
    portfolios,
  ]);

  const handleCsvDownload = async () => {
    setFileLoader(true);
    const csvData = await fetchDataForDownload();

    if (
      csvData[0]?.record?.length !== 0 &&
      csvData[0]?.record?.length !== undefined
    ) {
      const d = { ...getDownloadData(csvData) };
      const filteredHead = d?.thead
        ?.map((a) => {
          const foundObject = selectedRows
            ?.map((b) => {
              if (a?.text?.toString() === b?.toString()) {
                return a;
              }
            })
            ?.find((obj) => obj !== undefined);

          if (foundObject !== undefined) {
            return foundObject;
          }
        })
        ?.filter((obj) => obj !== undefined);
      const csvHead = filteredHead;
      const csvBody = d?.tbody;

      const head = [csvHead?.map((h) => h?.text || "") || []];
      const body =
        csvBody?.map((tr, i) => {
          return (
            csvHead?.map((th, j) => {
              const col = tr?.find((r) => r?.theadId === th?.id);
              return col?.text || "";
            }) || []
          );
        }) || [];

      const rows = [...head, ...body];
      const props = {
        rows: rows,
        fileName: "Billwise Report",
      };
      CSVdownload(props);
      setFileLoader(false);
      if(authUser){
        const logProps1 = {
          action: 'Csv',
          event: `${pageName?.pathname?.replace("\\", " ")} page | Filters - ${combinedValues} | Csv Clicked | Export Status: Success | Data Status: Success`
        };
        UserLog(logProps1)
      }
    } else {
      setFileLoader(false);
      if(authUser){
        const logProps1 = {
          action: 'Csv',
          event: `${pageName?.pathname?.replace("\\", " ")} page | Filters - ${combinedValues} | Csv Clicked | Export Status: Success | Data Status: Empty`
        };
        UserLog(logProps1)
      }
    }
    setFileLoader(false);
  };

  const handlePdfDownload = async () => {
    setFileLoader(true);
    const pdfData = await fetchDataForDownload();
    if (
      pdfData[0]?.record?.length !== 0 &&
      pdfData[0]?.record?.length !== undefined
    ) {
      const d = { ...getDownloadData(pdfData) };

      const filteredHead = d?.thead
        ?.map((a) => {
          const foundObject = selectedRows
            ?.map((b) => {
              if (a?.text?.toString() === b?.toString()) {
                return a;
              }
            })
            ?.find((obj) => obj !== undefined);

          if (foundObject !== undefined) {
            return foundObject;
          }
        })
        ?.filter((obj) => obj !== undefined);

      const thead = filteredHead;
      const tbody = d?.tbody;

      const head = [thead?.map((h) => h?.text || "") || []];
      const body =
        tbody?.map((tr, i) => {
          return (
            thead?.map((th, j) => {
              const col = tr?.find((r) => r?.theadId === th?.id);
              return col?.text || "";
            }) || []
          );
        }) || [];

      const props = {
        head: head,
        body: body,
        fileName: "Billwise Report",
        title: "Billwise Report",
      };
      handleDownloadPDF(props);
      setFileLoader(false);
      if(authUser){
        const logProps1 = {
          action: 'Pdf',
          event: `${pageName?.pathname?.replace("\\", " ")} page | Filters - ${combinedValues} | Pdf Clicked | Export Status: Success | Data Status: Success`
        };
        UserLog(logProps1)
      }
    } else {
      setFileLoader(false);
      if(authUser){
        const logProps1 = {
          action: 'Pdf',
          event: `${pageName?.pathname?.replace("\\", " ")} page | Filters - ${combinedValues} | Pdf Clicked | Export Status: Success | Data Status: Empty`
        };
        UserLog(logProps1)
      }
    }
    setFileLoader(false);
  };

  const getTableData = () => {
    var tableHead: Thead[] = tHead;
    var tableBody: Tbody[][] = [];
    if (
      billWiseData !== undefined &&
      billWiseData?.length > 0 &&
      billWiseData[0] !== undefined &&
      billWiseData[0]?.record !== undefined &&
      billWiseData[0]?.record[0] !== undefined &&
      billWiseData[0]?.record[0]?.centertype === "airport"
    ) {
      tableHead?.push(
        { id: "Passport No", children: "Passport No", text: "Passport No" },
        {
          id: "Flight & Seat No",
          children: "Flight & Seat No",
          text: "Flight & Seat No",
        },
        {
          id: "Booking Ref No",
          children: "Booking Ref No",
          text: "Booking Ref No",
        },
        {
          id: "Date Of Departure",
          children: "Date Of Departure",
          text: "Date Of Departure",
        },
        {
          id: "Destination/Origin",
          children: "Destination/Origin",
          text: "Destination/Origin",
        },
        {
          id: "Post Terminal Id",
          children: "Post Terminal Id",
          text: "Post Terminal Id",
        }
        // { id: 'Shift No', children: 'Shift No', text: 'Shift No' }
      );
    }
    // const thead = tableData?.filter((item, index) => index === 0)
    // const body = tableData?.filter((item, index) => index !== 0)body
    const tbody = billWiseData;

    tbody?.forEach((bill, i) => {
      bill?.record?.forEach((item) => {
        //     selectedYears?.forEach((sy) => {

        var tBody: Tbody[] = [];
        tBody?.push(
          {
            id: nanoid(),
            theadId: "Receipt No",
            children: item?.receiptno,
            text: item?.receiptno?.toString(),
          },
          {
            id: nanoid(),
            theadId: "Receipt Date",
            children: item?.receiptdate,
            text: item?.receiptdate,
          },
          {
            id: nanoid(),
            theadId: "Receipt Amount",
            children: item?.receiptamount,
            text: item?.receiptamount,
          },
          {
            id: nanoid(),
            theadId: "Tax",
            children: item?.tax,
            text: item?.tax?.toString(),
          },
          {
            id: nanoid(),
            theadId: "Return",
            children: item?.return,
            text: item?.return,
          },
          {
            id: nanoid(),
            theadId: "Return Tax",
            children: item?.returntax,
            text: item?.returntax,
          },
          {
            id: nanoid(),
            theadId: "Net Sale",
            children: item?.netsale,
            text: item?.netsale?.toString(),
          },
          {
            id: nanoid(),
            theadId: "Payment Mode",
            children: item?.paymentmode,
            text: item?.paymentmode,
          },
          {
            id: nanoid(),
            theadId: "Passenger Name",
            children: item?.passengername,
            text: item?.passengername,
          },
          {
            id: nanoid(),
            theadId: "Gender",
            children: item?.gender,
            text: item?.gender,
          },
          {
            id: nanoid(),
            theadId: "Passport No",
            children: item?.passportno,
            text: item?.passportno?.toString(),
          },
          {
            id: nanoid(),
            theadId: "Flight & Seat No",
            children: item?.flightseatno,
            text: item?.flightseatno,
          },
          {
            id: nanoid(),
            theadId: "Booking Ref No",
            children: item?.bookingrefno,
            text: item?.bookingrefno,
          },
          {
            id: nanoid(),
            theadId: "Date Of Departure",
            children: item?.dateofdeparture,
            text: item?.dateofdeparture,
          },
          {
            id: nanoid(),
            theadId: "Destination/Origin",
            children: item?.destinationororigin,
            text: item?.destinationororigin,
          },
          {
            id: nanoid(),
            theadId: "Post Terminal Id",
            children: item?.postterminalid,
            text: item?.postterminalid,
          }
          //  {
          //     id: nanoid(),
          //     theadId: 'Shift No',
          //     children: item?.shiftno,
          //     text: item?.shiftno?.toString()
          // }
        );
        if(!selectedOption?.isOutletUser){
          tBody?.push(
            {
              id: nanoid(),
              theadId: "Revenue Share(%)",
              children: item?.rspercent,
              text: item?.rspercent,
            },
            {
              id: nanoid(),
              theadId: "Revenue",
              children: item?.rsvalue,
              text: item?.rsvalue,
          },
        );
        }
        tableBody?.push(tBody);
      });
    });
    return { tbody: tableBody, thead: tableHead };
  };

  useEffect(() => {
    const header = tHead
      ?.map((a) => a?.text)
      ?.filter((id): id is string => typeof id === "string" && id !== undefined);
    setSelectedRows(header);
  }, []);

  const portfolio = useMemo<number[]>(() => {
    return portfolios !== "" ? [parseInt(portfolios)] : [];
  }, [portfolios]);

  const site = useMemo<string[]>(() => {
    if (authUserSiteId) {
      return authUserSiteId?.split(",")?.map((s) => {
        return s?.toString();
      });
    } else {
      return [];
    }
  }, [authUserSiteId]);

  const brand = useMemo<number[]>(() => {
    return brands !== "" ? [parseInt(brands)] : [];
  }, [brands]);

  const category = useMemo<number[]>(() => {
    return categories !== "" ? [parseInt(categories)] : [];
  }, [categories]);

  const subCategory = useMemo<number[]>(() => {
    return subCategories !== "" ? [parseInt(subCategories)] : [];
  }, [subCategories]);

  useEffect(() => {
    setOffset(0);
  }, [fromDate, toDate]);

  useEffect(() => {
    var range = [];
    var num = Math?.ceil(length / limit);
    // let i = 1;

    for (let i = 1; i <= num; i++) {
      range?.push(i);
    }
    setRange(range);
  }, [length, limit]);

  useEffect(()=>{
    let pid =portfolioData[0] && portfolioData[0]?.id? portfolioData[0].id?.toString(): "";
    setPortfolios(selectedOption.portfolio ? selectedOption.portfolio : pid);
    const b = portfolioOption.filter((a)=>a?.id?.toString()===pid?.toString())
    if (b?.length > 0 && b[0]?.id !== undefined) {
      const val: DropDownOption = {
        value: b[0]?.id, 
        label: b[0]?.name,
        imgUrl: b[0]?.logourl, 
      };
      setPortfolioDrop([val]);
    }
},[portfolioOption,selectedOption])

  useEffect(()=>{
    setLocation(selectedOption.location? selectedOption.location:'')
    const b = localityOption.filter((a)=>a?.area?.toString()===selectedOption.location?.toString())
    if (b?.length > 0 && b[0]?.id !== undefined) {
      const val: DropDownOption = {
        value: b[0]?.id, 
        label: b[0]?.area,
        imgUrl:'', 
      };
      setLocationDrop([val]);
    }
    },[localityOption,selectedOption])
  
    useEffect(()=>{
      setCategories(selectedOption.category ? selectedOption.category:'');
      const b = categoryOption.filter((a)=>a?.id?.toString()===selectedOption.category?.toString())
      if (b?.length > 0 && b[0]?.id !== undefined) {
        const val: DropDownOption = {
          value: b[0]?.id, 
          label: b[0]?.name,
          imgUrl:'', 
        };
        setCatDrop([val]);
      }
      },[categoryOption,selectedOption])
      
      
      useEffect(()=>{
      setSubCategories(selectedOption.subCategory ? selectedOption.subCategory:'');
      const b = subCategoryOption.filter((a)=>a?.id?.toString()===selectedOption.subCategory?.toString())
      if (b?.length > 0 && b[0]?.id !== undefined) {
        const val: DropDownOption = {
          value: b[0]?.id, 
          label: b[0]?.name,
          imgUrl:'', 
        };
        setSubcatDrop([val]);
      }
      },[subCategoryOption,selectedOption])
  
      useEffect(()=>{
        setBrands(selectedOption.brand ? selectedOption.brand:'');
        const b = brandOption.filter((a)=>a?.id?.toString()===selectedOption.brand?.toString())
        if (b?.length > 0 && b[0]?.id !== undefined) {
          const val: DropDownOption = {
            value: b[0]?.id, 
            label: b[0]?.name,
            imgUrl: b[0]?.logourl, 
          };
          setBrandDrop([val]);
        }
      },[brandOption,selectedOption])


  useEffect(() => {
    let sid = siteData[0] && siteData[0]?.id ? siteData[0]?.id?.toString() : "";
    setSites(selectedOption?.site ? selectedOption?.site : sid);
  }, [siteData, selectedOption]);


  useEffect(() => {
    if (sites !== ""&&selectedOption.isOutletUser===true) {
      let siteCurrencyObject = siteOption?.find(
        (s) => s?.id?.toString() === sites?.toString()
      );
      setValueIn(
        siteCurrencyObject?.currencycode +
          " | " +
          siteCurrencyObject?.valuecaption
      );
    } 
    else if(portfolios !== ""&&selectedOption.isOutletUser===false){
      let portfolioCurrencyObject = portfolioOption?.find(
        (s) => s?.id?.toString() === portfolios?.toString()
      );
      setValueIn(
        portfolioCurrencyObject?.reportingcurrency +
          " | " +
          portfolioCurrencyObject?.valuecaption
      );
    }
    else setValueIn("");
  }, [sites, siteOption,selectedOption]);

  useEffect(() => {
    const d = new Date(toDate);
    d?.setDate(d?.getDate() - 1);
    const date = d?.getDate()?.toString()?.padStart(2, "0");
    const month = (d?.getMonth() + 1)?.toString()?.padStart(2, "0");
    const year = d?.getFullYear();
    // return [year, month, date]?.join('-')
    setMaxFromDate([year, month, date]?.join("-"));
  }, [toDate]);

  useEffect(() => {
    if (billWiseData?.length > 0)
      setLength(billWiseData[0]?.count ? billWiseData[0]?.count : 0);
  }, [billWiseData]);

  useEffect(() => {
    var getData = {
      portfolio: portfolio,
    };

    if (portfolio?.length > 0 && getData !== undefined) {
      api
        .post<
          APIResponse<{ records: CatSubCatCompanyWithBrandRelationDataType[] }>
        >(`filter/catSubcatCompanyRelationWithBrand`, getData, config)
        .then((response) => {
          if (response?.status === 200 && response?.data?.data) {
            const result = response?.data?.data?.records;
            setCatSubCatCompanyWithBrandRelationData(result);
          }
        })
        .catch((error) => {});
    } else {
      setCatSubCatCompanyWithBrandRelationData([]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [portfolio]);


  useEffect(()=>{
    if(authUser){
    const logProps1 = {
      action:`Visit`,
      event: `${pageName?.pathname?.replace("\\", " ")} page | Visited `
    };
    UserLog(logProps1)
  }
  },[])

  

  return (
    <UserLayout>
      <div className="card background-transparent p-1">
        <div className="row">
          <div
            className="col-lg-4 wow fadeInLeft ps-0"
            // style="visibility: visible; animation-name: fadeInLeft;"
          >
            <h4 className="b-title">Bill-wise Report</h4>
          </div>

          <div className="col-lg-8 wow fadeInRight">
            <div className="row">
              <div className="col-sm-6 col-md-4 col-lg-3">
                {/* <FormInput2
                  name="Portfolio"
                  label=""
                  labelClassName="required"
                  value={portfolios}
                  onChange={handlePortfolioChange}
                  placeholder="Select"
                  containerClass="mb-1"
                  type="select"
                >
                  <option value="">Select</option>
                  {portfolioData?.map((p, i) => {
                    return (
                      <Option value={p?.id} key={i}>
                        {p?.name}
                      </Option>
                    );
                  })}
                </FormInput2> */}
                 {/* <SingleSelect
                  options={[
                    defaultPortfolio,
                    ...portfolioData?.sort(function (a, b) {
                      if (a?.name < b?.name) {
                        return -1;
                      }
                      if (a?.name > b?.name) {
                        return 1;
                      }
                      return 0;
                    })?.map((s, i) => {
                      return (
                        {
                          value: s?.id ? s?.id?.toString() : '',
                          label: s?.name,
                          imgUrl: s?.logourl,
                        }
                      );
                    })
                  ]}
                  label=""
                  selectedOptions={portfoliosOption}
                  setSelectedOptions={handlePortfolioChange}
                  // disabled={disableSite}
                  disabled={disableLocality}
                  placeHolder="Select Portfolio"
                  // filterText=""
                /> */}
                  <SingleSelect1
                      options={portfolioData.map((c, i) => {
                        return {
                          value: c?.id?c?.id:0,
                          label: c?.name?c?.name:'',
                          imgUrl: "",
                        };
                      })}
                      selectedOptions={portfolioDrop}
                      setSelectedOptions={handlePortfolioDrop}
                      placeHolder="Select Portfolio"
                      onClear={handleClearPortfolio}
                      disabled = {disableLocality}
                    />
              </div>
              
              <div className="col-sm-6 col-md-4 col-lg-3">
              {/* <FormInput2
                  name="Location"
                  label=""
                  labelClassName="required"
                  value={location}
                  onChange={handleLocationChange}
                  placeholder="Select Locality"
                  containerClass="mb-1"
                  disabled={disableLocality}
                  type="select"
                >
                  <option value="">Select Locality</option>
                  {localityOption?.map((s, i) => {
                    return (
                      <Option value={s?.area} key={i}>
                        {s?.area}
                      </Option>
                    );
                  })}
                </FormInput2> */}
                {/* <SingleSelect
                  options={[
                    defaultLocation,
                    ...localityOption?.sort(function (a, b) {
                      if (a?.area < b?.area) {
                        return -1;
                      }
                      if (a?.area > b?.area) {
                        return 1;
                      }
                      return 0;
                    })?.map((s, i) => {
                      return (
                        {
                          value: s?.id ? s?.id?.toString() : '',
                          label: s?.area,
                          imgUrl: '',
                        }
                      );
                    })
                  ]}
                  label=""
                  selectedOptions={locationOption}
                  setSelectedOptions={handleLocationChange}
                  disabled={disableLocality}
                  placeHolder="Select Portfolio"
                  // filterText=""
                /> */}
                   <SingleSelect1
                      options={localityOption.map((c, i) => {
                        return {
                          value: c?.id?c?.id:0,
                          label: c?.area?c?.area:'',
                          imgUrl: "",
                        };
                      })}
                      selectedOptions={locationDrop}
                      setSelectedOptions={handleLocationDrop}
                      placeHolder="Select Locality"
                      onClear={handleClearLocation}
                      disabled = {disableLocality}
                    />
              </div>
            </div>

            <div className="row mt-1">
              <div className="col-sm-6 col-md-4 col-lg-3">
                {/* <FormInput2
                  name="Trade Category"
                  label=""
                  labelClassName="required"
                  value={categories}
                  onChange={handleCategoryChange}
                  placeholder="Select Trade Category"
                  containerClass="mb-1"
                  type="select"
                >
                  <option value="">Select Trade Category</option>
                  {categoryOption?.map((s, i) => {
                    return (
                      <Option value={s?.id} key={i}>
                        {s?.name}
                      </Option>
                    );
                  })}
                </FormInput2> */}
                 {/* <SingleSelect
                  options={[
                    defaultTradeCategory,
                    ...categoryOption?.sort(function (a, b) {
                      if (a?.name < b?.name) {
                        return -1;
                      }
                      if (a?.name > b?.name) {
                        return 1;
                      }
                      return 0;
                    })?.map((s, i) => {
                      return (
                        {
                          value: s?.id ? s?.id?.toString() : '',
                          label: s?.name,
                          imgUrl: '',
                        }
                      );
                    })
                  ]}
                  label=""
                  selectedOptions={categoriesOption}
                  setSelectedOptions={handleCategoryChange}
                  // disabled={disableSite}
                  disabled={disableLocality}
                  placeHolder="Select Trade Category"
                  // filterText=""
                /> */}
                  <SingleSelect1
                      options={categoryOption.map((c, i) => {
                        return {
                          value: c?.id?c?.id:0,
                          label: c?.name?c?.name:'',
                          imgUrl: "",
                        };
                      })}
                      selectedOptions={catDrop}
                      setSelectedOptions={handleCatDrop}
                      placeHolder="Select Trade Category"
                      onClear={handleClearCat}
                      disabled = {disableLocality}
                    />
              </div>
              <div className="col-sm-6 col-md-4 col-lg-3">
                {/* <FormInput2
                  name="Trade Sub Category"
                  label=""
                  labelClassName="required"
                  value={subCategories}
                  onChange={handleSubCategoryChange}
                  placeholder="Select Trade Sub Category"
                  containerClass="mb-1"
                  type="select"
                >
                  <option value="">Select Trade Sub Category</option>
                  {subCategoryFilteredByCategory?.map((s, i) => {
                    return (
                      <Option value={s?.id} key={i}>
                        {s?.name}
                      </Option>
                    );
                  })}
                </FormInput2> */}
                 {/* <SingleSelect
                  options={[
                    defaultTradeSubCategory,
                    ...subCategoryFilteredByCategory?.sort(function (a, b) {
                      if (a?.name < b?.name) {
                        return -1;
                      }
                      if (a?.name > b?.name) {
                        return 1;
                      }
                      return 0;
                    })?.map((s, i) => {
                      return (
                        {
                          value: s?.id ? s?.id?.toString() : '',
                          label: s?.name,
                          imgUrl: '',
                        }
                      );
                    })
                  ]}
                  label=""
                  selectedOptions={subCategoriesOption}
                  setSelectedOptions={handleSubCategoryChange}
                  // disabled={disableSite}
                  disabled={disableLocality}
                  placeHolder="Select Trade Sub Category"
                  // filterText=""
                /> */}
                      <SingleSelect1
                      options={subCategoryFilteredByCategory.map((c, i) => {
                        return {
                          value: c?.id?c?.id:0,
                          label: c?.name?c?.name:'',
                          imgUrl: "",
                        };
                      })}
                      selectedOptions={subcatDrop}
                      setSelectedOptions={handleSubcatDrop}
                      placeHolder="Select Trade Sub Category"
                      onClear={handleClearSubcat}
                      disabled = {disableLocality}
                    />
              </div>
              
              <div className="col-sm-6 col-md-4 col-lg-3">
                {/* <FormInput2
                  name="Brand"
                  label=""
                  labelClassName="required"
                  value={brands}
                  onChange={handleBrandChange}
                  placeholder="Select Brand"
                  containerClass="mb-1"
                  type="select"
                >
                  <option value="">Select Brand</option>
                  {BrandFilteredByCompany?.sort(function (a, b) {
                    if (a?.name < b?.name) {
                      return -1;
                    }
                    if (a?.name > b?.name) {
                      return 1;
                    }
                    return 0;
                  })?.map((s, i) => {
                    return (
                      <Option value={s?.id} key={i}>
                        {s.name}
                      </Option>
                    );
                  })}
                </FormInput2> */}
                  {/* <SingleSelect
                  options={[
                    defaultbrand,
                    ...BrandFilteredByCompany?.sort(function (a, b) {
                      if (a?.name < b?.name) {
                        return -1;
                      }
                      if (a?.name > b?.name) {
                        return 1;
                      }
                      return 0;
                    })?.map((s, i) => {
                      return (
                        {
                          value: s?.id ? s?.id?.toString() : '',
                          label: s?.name,
                          imgUrl: s?.logourl,
                        }
                      );
                    })
                  ]}
                  label=""
                  selectedOptions={brandsOption}
                  setSelectedOptions={handleBrandChange}
                  // disabled={disableSite}
                  disabled={disableLocality}
                  placeHolder="Select Brand"
                  // filterText=""
                /> */}
                              <SingleSelect1
                options={BrandFilteredByCompany.map((c, i) => {
        return {
          value: c?.id?c?.id:0,
          label: c?.name?c?.name:'',
          imgUrl: "",
        };
      })}
      selectedOptions={brandDrop}
      setSelectedOptions={handleBrandDrop}
      placeHolder="Select Brand"
                onClear={handleClearBrand}
                disabled = {disableLocality}
              />
              </div>
            </div>
          </div>
        </div>
      </div>

      {loader ? (
        <Loader />
      ) : (
        <div className="row">
          <div className="col-12">
            <div className="card mt-3">
              <div className="card-body">
                <div className="row mb-1">
                  <div className="col-lg-6 col-sm-12">
                    <div className="float-start">
                      <h5 className="header-title mt-1">
                        <img src={tableIcon} alt="icon"></img>Bill-wise Report
                      </h5>
                    </div>
                  </div>
                  <div className="col-lg-6 col-sm-12">
                    <div className="float-end hstack gap-1">
                      {/* <input type='date' className="form-control" /> */}
                      {/* <input type='date' className="form-control" /> */}
                      <div
                        className="col-sm-6 col-md-6 col-lg-6"
                        style={{ width: "133px" }}
                      >
                        <FormInput
                          name="From Date"
                          label=""
                          labelClassName="required"
                          value={fromDate}
                          max={maxFromDate}
                          onChange={handleFromDate}
                          type="date"
                          placeholder="From Date"
                        />
                      </div>
                      <div
                        className="col-sm-6 col-md-6 col-lg-6"
                        style={{ width: "133px" }}
                      >
                        <FormInput
                          name="To Date"
                          label=""
                          labelClassName="required"
                          value={toDate}
                          max={today()}
                          onChange={handleToDate}
                          type="date"
                          placeholder="To Date"
                        />
                      </div>
                      <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6">
                        <button
                          type="button"
                          className="btn btn-primary"
                          onClick={handleApply}
                        >
                          Apply
                        </button>
                      </div>
                    </div>
                  </div>
                </div>

                <TransactionTable
                  data={[]}
                  {...getTableData()}
                  title=""
                  downloadCSV={true}
                  downloadPDF={true}
                  fileName="Billwise Report"
                  limit={limit}
                  setOffset={setOffset}
                  onClickCSV={handleCsvDownload}
                  onClickPDF={handlePdfDownload}
                  range={range}
                  valueInString={valueIn}
                  onPageNoChange={pag && (offset > 0||condition) ? handleApply : undefined}
                  handlePag={pag ? handlePag : undefined}
                  onOffchange={handleOff}
                  fLoader={fileLoader}
                  onSelectedRowsChange={handleSelectedRowsChange}
                />
              </div>
            </div>
          </div>
        </div>
      )}
    </UserLayout>
  );
};
export default TransactionBillWiseReport;
