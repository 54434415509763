import { AuthUserType, UserActionList } from "../actions/authUserActions"
import { Action } from "../actions/types"

type AuthUserReducer = (state: AuthUserType | null, action: Action) => AuthUserType | null

const initialValue: AuthUserType | null = null

const authUserReducer: AuthUserReducer = (state = initialValue, action) => {
    switch (action.type) {

        case UserActionList.SIGNUP_USER:
            return action.data

        case UserActionList.LOGIN_USER:
            return action.data

        case UserActionList.LOGOUT_USER:
            return initialValue

        default:
            return state
    }
}

export default authUserReducer