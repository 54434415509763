import api, { APIResponse, getAxiosRequestConfig } from "../../config/api"
import { Dispatch } from "redux"
import { GetState } from "../store"

export enum ActiveDropDown {
    Active = "true",
    InActive = "false",
}
export type ActiveTypes = ActiveDropDown.Active | ActiveDropDown.InActive

export interface CountryType {
    id?: number
    countrycode:string
    countryname:string
    currencyname:string
    shortcode:string
    noofdecimal:number
    decimalshortname:string
    currencysymbol:string
    divisionvalue:string
    valuecaption:string
    createdBy?: number | null,
    createdAt?: string,
    updatedAt?: string
    updatedBy?: string
}

export enum CountryActionsList {
    ADD_COUNTRY = 'ADD_COUNTRY',
    EDIT_COUNTRY = 'EDIT_COUNTRY',
    FETCH_COUNTRY = 'FETCH_COUNTRY',
    DELETE_COUNTRY = 'DELETE_COUNTRY',
    CLEAR_COUNTRY = 'CLEAR_COUNTRY'
}

export interface DeleteCountry {
    id: number
}

export interface AddCountryAction {
    type: CountryActionsList.ADD_COUNTRY
    data: CountryType
}

export interface EditCountryAction {
    type: CountryActionsList.EDIT_COUNTRY
    data: CountryType
    id?: number
}

export interface FetchCountryAction {
    type: CountryActionsList.FETCH_COUNTRY
    data: CountryType[]

}

export interface ClearCountryAction {
    type: CountryActionsList.CLEAR_COUNTRY

}

export interface DeleteCountryAction {
    type: CountryActionsList.DELETE_COUNTRY
    data: DeleteCountry
}

export type CountryActions = AddCountryAction | EditCountryAction | FetchCountryAction | DeleteCountryAction | ClearCountryAction

export const clearCountry = () => {
    return async (dispatch: Dispatch, getState: GetState) => {
        return dispatch<ClearCountryAction>({
            type: CountryActionsList.CLEAR_COUNTRY

    })
}
}


export const fetchCountry = () => {
    return async (dispatch: Dispatch, getState: GetState) => {
        // return dispatch<FetchCountryAction>({
        //     type: CountryActionsList.FETCH_COUNTRY
        // })
        const token = getState().authUser?.['auth-token']!
        const config = getAxiosRequestConfig(token)

        return api.get<APIResponse<CountryType[]>>('country/', config).then(response => {
            if (response.status === 200 && response.data.data) {
                dispatch<FetchCountryAction>({
                    type: CountryActionsList.FETCH_COUNTRY,
                    data: response.data.data
                })
                return Promise.resolve(response.data.message
                    ? response.data.message
                    : 'Fetch Successfull')
            } else {
                throw new Error("Unable to fetch")
            }
        }).catch(error => {
            return Promise.reject(error.response
                ? error.response.data.message
                    ? error.response.data.message
                    : 'Unable to fetch'
                : 'Unable to fetch')
        })
    }
}

export const fetchCountryByRetailId = () => {
    return async (dispatch: Dispatch, getState: GetState) => {
        // return dispatch<FetchCountryAction>({
        //     type: CountryActionsList.FETCH_COUNTRY
        // })
        const token = getState().authUser?.['auth-token']!
        const config = getAxiosRequestConfig(token)
        const authUserPfId = getState().authUser?.['portfolioId']
        return api.get<APIResponse<CountryType[]>>(`countriesbyretailid/${authUserPfId}`, config).then(response => {
            if (response.status === 200 && response.data.data) {
                dispatch<FetchCountryAction>({
                    type: CountryActionsList.FETCH_COUNTRY,
                    data: response.data.data
                })
                return Promise.resolve(response.data.message
                    ? response.data.message
                    : 'Fetch Successfull')
            } else {
                throw new Error("Unable to fetch")
            }
        }).catch(error => {
            return Promise.reject(error.response
                ? error.response.data.message
                    ? error.response.data.message
                    : 'Unable to fetch'
                : 'Unable to fetch')
        })
    }
}
