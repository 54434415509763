import React, { useEffect, useMemo, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { PortfolioDashboardList, PortfolioDashboardType } from "../../config";
import api, { APIResponse, getAxiosRequestConfig } from "../../config/api";
import { YearOptions } from "../../config/functions";
import { AuthUserType } from "../../redux/actions/authUserActions";
import { PortfolioType } from "../../redux/actions/portfolioActions";
import { StoreState } from "../../redux/store";
import { KpiViewList } from "../commonWidget";
import Widget1 from "../custom/components/Widget1";
import BarChart1 from "../custom/components/charts/apex/Barchart1";
import { COLORS } from "../custom/constants";
import { BrandType } from "../../redux/actions/brandActions";
import {
  RegionType,
  StateType,
  CityType,
} from "../../redux/actions/commonActions";
import { CountryType } from "../../redux/actions/countryActions";
import Modal from "../Modal";
import RetailPerformanceTable from "../custom/components/RetailPerformanceTable";

interface RecordsForYear {
  brandid: number;
  transyear: number;
  footfall: string;
  percentchange?: number | null;
  footfallPercent?: number;
}

interface YearlyRecord {
  portfolio: number;
  record: RecordsForYear[];
}

interface Props {
  portfolioId: number;
  siteId: string[];
  brandId: number;
  country?: number[];
  region?: number[];
  state?: number[];
  city?: number[];
  expandState: React.Dispatch<React.SetStateAction<boolean>>;
  commonApiEnabler?:boolean
}

interface ChangedRefType {
  brand?:string
  country?:string
  region?:string
  state?:string
  city?:string
}

const BrandDashboardFootfallWidget: React.FC<Props> = (props: Props) => {
  const authUser = useSelector<StoreState, AuthUserType | null>(
    (state) => state?.authUser
  );
  const changedRef = useRef<ChangedRefType>({});
  const authToken = authUser?.["auth-token"]!;
  const config = getAxiosRequestConfig(authToken);

  const brandList = useSelector<StoreState, Array<BrandType>>(
    (state) => state?.brand
  );
  // const portfolioOption = useSelector<StoreState, PortfolioType[]>(
  //   (state) => state?.portfolio
  // );

  const brandOption = brandList?.filter(
    (a) => a?.id?.toString() === authUser?.brandId?.toString()
  );

  const propBrandIndex = brandList?.findIndex(
    (p) => p?.id !== undefined && p?.id?.toString() === props?.brandId?.toString()
  );
  const propBrandName = brandList[propBrandIndex]?.name;
  const countryList = useSelector<StoreState, CountryType[]>(
    (state) => state?.country
  );

  const regionList = useSelector<StoreState, RegionType[]>(
    (state) => state?.region
  );

  const stateList = useSelector<StoreState, StateType[]>(
    (state) => state?.state
  );

  const cityList = useSelector<StoreState, CityType[]>((state) => state?.city);

  const countryIndex = countryList?.findIndex(
    (a) => a?.id?.toString() === props?.country?.toString()
  );
  const propConame = countryList[countryIndex]?.countryname;

  const regionIndex = regionList?.findIndex(
    (a) => a?.id?.toString() === props?.region?.toString()
  );
  const propRename = regionList[regionIndex]?.regionname;

  const stateIndex = stateList?.findIndex(
    (a) => a?.id?.toString() === props?.state?.toString()
  );
  const propStname = stateList[stateIndex]?.statename;

  const cityIndex = cityList?.findIndex(
    (a) => a?.id?.toString() === props?.city?.toString()
  );
  const propCiname = cityList[cityIndex]?.cityname;

  const [yearlyData, setYearlyData] = useState<YearlyRecord[]>([]);

  const [selection, setSelection] = useState<PortfolioDashboardType>(
    PortfolioDashboardList?.MOM
  );
  const [view, setView] = useState<string>(KpiViewList?.CHART);
  const [showTableModal, setShowTableModal] = useState<boolean>(false);
  const [apiCall,setApiCall]=  useState<boolean>(true)

  const yearList = YearOptions();
  const showExpandHandler = () => {
    props?.expandState((prevState) => !prevState)
  };
  const chartViewHandler = () => {
    setView(KpiViewList?.CHART);
  };
  const tableViewHandler = () => {
    setView(KpiViewList?.TABLE);
  };

  const showGridHandler = () => {
    // props?.expandState(true)
    setShowTableModal(true)
    // setView(KpiViewList?.TABLE)
  };


  const YearlyFilteredData = useMemo<YearlyRecord[]>(() => {
    const data: YearlyRecord[] = [];

    yearlyData?.forEach((d) => {
      data?.push({
        portfolio: d?.portfolio,
        record: yearList
          ?.sort()
          ?.map((yr) => {
            return d?.record?.filter((r) => {
              return yr === r?.transyear;
            });
          })
          ?.flat(1),
      });
    });
    return data;
  }, [yearlyData, yearList]);

  useEffect(() => {
    //yearly
    var getData = {
      portfolio: props?.portfolioId ? [props?.portfolioId] : [],
      brand: props?.brandId ? [props?.brandId] : [],
      site: props?.siteId ? props?.siteId : [],
      country: props?.country,
      state: props?.state,
      region: props?.region,
      city: props?.city,
      authUserRole: authUser?.roleId ? authUser?.roleId : "",
    };

    if (
      getData?.portfolio?.length > 0 &&
      props?.portfolioId &&
      getData !== undefined && apiCall && props?.commonApiEnabler
    ) {
      // kpi/portfolio/sales/monthly
      api
        .post<APIResponse<{ records: YearlyRecord[] }>>(
          `kpi/brand/peopleCount/yearly`,
          getData,
          config
        )
        .then((response) => {
          if (response?.status === 200 && response?.data?.data) {
            const result = response?.data?.data?.records;
            setYearlyData(result);
          }
          else {
            setYearlyData([]);
            setApiCall(false)
          }
        })
        .catch((error) => {});
    } 
  }, [
    props?.portfolioId,
    props?.siteId,
    apiCall,
    props?.commonApiEnabler
  ]);

  const colors = [
    COLORS?.COLOR1,
    COLORS?.COLOR2,
    COLORS?.COLOR3,
    COLORS?.COLOR4,
    COLORS?.COLOR5,
    COLORS?.COLOR6,
    COLORS?.COLOR7,
    COLORS?.COLOR8,
  ];

  const getYearlyYOYData = () => {
    var monthlyTableData: string[][] = [
      [
        "Year",
        "Footfall",
      ],
    ];

    yearList?.map((a)=>{
    yearlyData?.forEach((y, i) => {
          y?.record?.filter((b)=>b?.transyear?.toString()===a?.toString())
            ?.forEach((r) => {
                let sum = []
              sum?.push(r?.transyear?.toString());
              sum?.push(
                r?.footfall !== undefined && r?.footfall !== null ? r?.footfall?.toString() : "-"
              );
                monthlyTableData?.push(sum);
        });
    });
  })
    return monthlyTableData;
  };

  useEffect(() => { 

    let {city,country,region,state,brandId} = props


  if( changedRef?.current?.city!==city?.toString()
  || changedRef?.current?.country!==country?.toString()
  || changedRef?.current?.region!==region?.toString()
  || changedRef?.current?.state!==state?.toString()
  || changedRef?.current?.brand!==brandId?.toString()
  ) {
    changedRef.current = {
    brand:brandId?.toString(),
    city:city?.toString(),
    country:country?.toString(),
    region:region?.toString(),
    state:state?.toString()
    };
    setApiCall(true)
  }
}, [props?.city,props?.country,props?.region,props?.state,props?.brandId]);


  return (
    <Widget1
      title={"Footfall"}
      tableViewHandler={tableViewHandler}
      chartViewHandler={chartViewHandler}
      selection={selection}
      view={view}
      viewBtns={false}
      showExpandModal={true}
      showExpand={true}
      showExpandHandler={showExpandHandler}
      showGridHandler={showGridHandler}
      showGrid={true}
    >
      <BarChart1
        datasets={(() => {
          let ydata: { name: string; data: number[] }[] = [];

          YearlyFilteredData?.forEach((y, i) => {
            brandOption?.forEach((s) => {
              let data2: number[] = [];
              let d1 = y?.record?.filter(
                (sw) => sw?.brandid?.toString() === s?.id?.toString()
              );
              yearList?.forEach((yr) => {
                var yrs = d1?.map((d) => d?.transyear);
                if (yrs?.includes(yr)) {
                  let obj = d1?.find(
                    (f) => f?.transyear?.toString() === yr?.toString()
                  );
                  data2?.push(obj?.footfall ? parseFloat(obj?.footfall) : 0);
                } else data2?.push(0);
              });

              if (
                data2?.length > 0 &&
                (data2[0] !== 0 || data2[1] !== 0 || data2[2] !== 0)
              ) {
                ydata?.push({
                  name: s?.name,
                  data: data2,
                });
              }
            });
          });
          return ydata;
        })()}
        labels={yearList?.map((y) => y?.toString())?.sort() || []}
        colors={[
          colors[0],
          colors[1],
          colors[2],
          colors[3],
          colors[4],
          colors[5],
          colors[6],
          colors[7],
        ]}
        title={(() => {
          const txt = `Footfall`;
          return txt;
        })()}
        subtitle={(() => {
          let subtxt: string[] = [];
          let subtxt1 = `\u3010Brand\u3011: ${propBrandName} \u3010Period\u3011: ${yearList[0]} - ${yearList[2]}`;
          let subtxt2 = ``;
          if (props?.country !== undefined && props?.country?.length > 0) {
            subtxt1 += `\u3010Country\u3011: ${propConame}`;
          }
          if (props?.region !== undefined && props?.region?.length > 0) {
            subtxt1 += `\u3010Region\u3011: ${propRename}`;
          }
          if (props?.state !== undefined && props?.state?.length > 0) {
            subtxt2 += `\u3010State\u3011: ${propStname}`;
          }
          if (props?.city !== undefined && props?.city?.length > 0) {
            subtxt2 += `\u3010City\u3011: ${propCiname}`;
          }
          subtxt?.push(subtxt1);
          subtxt?.push(subtxt2);
          return subtxt;
        })()}
        exportfilename={"Footfall"}
      />


      
{showTableModal && (
              <Modal
              size="xl"
              visible = {showTableModal}
              onClose={() => setShowTableModal(false)}
              centered classname="grid-container"
              >
              <RetailPerformanceTable
                    data={getYearlyYOYData()}
                    title={`Footfall - ${propBrandName}`}
                    fileName={"pf_monthly_sales"}
                    downloadCSV={false}
                    downloadPDF={false}
                  />
              </Modal>
        )}
    </Widget1>
  );
};
export default BrandDashboardFootfallWidget;
