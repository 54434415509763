import React, { useEffect, useMemo, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { PortfolioDashboardList, PortfolioDashboardType } from "../../config";
import api, { APIResponse, getAxiosRequestConfig } from "../../config/api";
import { AuthUserType } from "../../redux/actions/authUserActions";
import { StoreState } from "../../redux/store";
import { KpiViewList, getOnlyUnique } from "../commonWidget";
import Widget1 from "../custom/components/Widget1";
import { COLORS } from "../custom/constants";
import { PortfolioType } from "../../redux/actions/portfolioActions";
import { YearOptions } from "../../config/functions";
// import BarChart from "../custom/components/charts/apex/BarChart";
import BarChart1 from "../custom/components/charts/apex/Barchart1";
// import LineChart from "../custom/components/charts/apex/LineChart";
// import StackedBarChart from "../custom/components/charts/apex/StackedBarChart";
import { TradeCategoryType } from "../../redux/actions/tradeCategoryActions";
import {
  RegionType,
  StateType,
  CityType,
} from "../../redux/actions/commonActions";
import { CountryType } from "../../redux/actions/countryActions";
import { TradeSubCategoryType } from "../../redux/actions/tradeSubCategoryActions";
import LineChart1 from "../custom/components/charts/apex/LineChart1";
import RetailPerformanceTable from "../custom/components/RetailPerformanceTable";
import Modal from "../Modal";

interface RecordsForWeek {
  sales: string;
  transyear: number;
  transmonth?: number;
  weeknumber: number;
  tradecatid: number;
  centerid: number | null;
  momgrowth: number | null;
  mtd: string;
  mtdpercentchange: number | null;
  averagesale: string;
  averagerate: number;
  averageratepercent: string;
}
interface RecordsForMonth {
  sales: string;
  transyear: number;
  tradecatid: number | null;
  centerid: number | null;
  transmonth: number;
  momgrowth: number | null;
  mtd: string;
  mtdpercentchange: number | null;
  averagesale: string;
  averagerate: number;
  averageratepercent: string;
}
interface RecordsForYear {
  sales: string;
  transyear: number;
  tradecatid: number | null;
  centerid: number | null;
  yoygrowth: number | null;
  ytd: number;
  ytdpercentchange: number | null;
  averagesale: number;
  averagerate: number;
  averageratepercent: number;
}

interface WeeklyRecord {
  portfolio: number;
  record: RecordsForWeek[];
}
interface MonthlyRecord {
  portfolio: number;
  record: RecordsForMonth[];
}
interface YearlyRecord {
  portfolio: number;
  record: RecordsForYear[];
}

interface Props {
  portfolioId: number;
  siteId?: string[];
  tradeCategory?: number[];
  tradeSubCategory?: number[];
  country?: number[];
  region?: number[];
  state?: number[];
  city?: number[];
  expandState: React.Dispatch<React.SetStateAction<boolean>>;
  commonApiEnabler?:boolean
}

interface ChangedRefType {
  tradeCat?: string;
  tradeSubCat?:string
  country?:string
  region?:string
  state?:string
  city?:string
}

const BrandDashboardCategorySalesWidget: React.FC<Props> = (props: Props) => {
  const authUser = useSelector<StoreState, AuthUserType | null>(
    (state) => state?.authUser
  );
  const changedRef = useRef<ChangedRefType>({});
  const authToken = authUser?.["auth-token"]!;
  const config = getAxiosRequestConfig(authToken);

  const monthsArray = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];

  const portfolioOption = useSelector<StoreState, PortfolioType[]>(
    (state) => state?.portfolio
  );
  const tradecategoryOption = useSelector<StoreState, TradeCategoryType[]>(
    (state) => state?.tradeCategory
  );

  const prop_index = portfolioOption?.findIndex(
    (p) =>
      p?.id !== undefined && p?.id?.toString() === props?.portfolioId?.toString()
  );
  const propPfname = portfolioOption[prop_index]?.name;

  const countryList = useSelector<StoreState, CountryType[]>(
    (state) => state?.country
  );

  const regionList = useSelector<StoreState, RegionType[]>(
    (state) => state?.region
  );

  const stateList = useSelector<StoreState, StateType[]>(
    (state) => state?.state
  );

  const cityList = useSelector<StoreState, CityType[]>((state) => state?.city);

  const tradeCategoryList = useSelector<StoreState, TradeCategoryType[]>(
    (state) => state?.tradeCategory
  );
  const subCategoryList = useSelector<StoreState, TradeSubCategoryType[]>(
    (state) => state?.tradeSubCategory
  );

  const countryIndex = countryList?.findIndex(
    (a) => a?.id?.toString() === props?.country?.toString()
  );
  const propConame = countryList[countryIndex]?.countryname;

  const regionIndex = regionList?.findIndex(
    (a) => a?.id?.toString() === props?.region?.toString()
  );
  const propRename = regionList[regionIndex]?.regionname;

  const stateIndex = stateList?.findIndex(
    (a) => a?.id?.toString() === props?.state?.toString()
  );
  const propStname = stateList[stateIndex]?.statename;

  const cityIndex = cityList?.findIndex(
    (a) => a?.id?.toString() === props?.city?.toString()
  );
  const propCiname = cityList[cityIndex]?.cityname;

  const catIndex = tradeCategoryList?.findIndex(
    (a) => a?.id?.toString() === props?.tradeCategory?.toString()
  );
  const propCatname = tradeCategoryList[catIndex]?.name;

  const subcatIndex = subCategoryList?.findIndex(
    (a) => a?.id?.toString() === props?.tradeSubCategory?.toString()
  );
  const propsubCatname = subCategoryList[subcatIndex]?.name;

  const [yearlyData, setYearlyData] = useState<YearlyRecord[]>([]);
  const [monthlyData, setMonthlyData] = useState<MonthlyRecord[]>([]);
  const [weeklyData, setWeeklyData] = useState<WeeklyRecord[]>([]);

  const [selection, setSelection] = useState<PortfolioDashboardType>(
    PortfolioDashboardList?.MOM
  );
  const [view, setView] = useState<string>(KpiViewList?.CHART);
  const [showTableModal, setShowTableModal] = useState<boolean>(false);
  const [valueIn, setValueIn] = useState<string>("");
  const [apiCall1,setApiCall1]=  useState<boolean>(true)
  const [apiCall2,setApiCall2]=  useState<boolean>(true)
  const [apiCall3,setApiCall3]=  useState<boolean>(true)
  const yearList = YearOptions();

  // const currentYear = new Date()?.getFullYear();
  // const [year, setYear] = useState<string>(currentYear?.toString());
  const showExpandHandler = () => {
    props?.expandState((prevState) => !prevState)
  };
  const weekHandler = () => {
    setSelection(PortfolioDashboardList?.WEEK);
  };
  const momHandler = () => {
    setSelection(PortfolioDashboardList?.MOM);
  };
  const yoyHandler = () => {
    setSelection(PortfolioDashboardList?.YOY);
  };
  const chartViewHandler = () => {
    setView(KpiViewList?.CHART);
  };
  const tableViewHandler = () => {
    setView(KpiViewList?.TABLE);
  };
  const showGridHandler = () => {
    // props?.expandState(true)
    setShowTableModal(true)
    // setView(KpiViewList?.TABLE)
  };

  const YearlyFilteredData = useMemo<YearlyRecord[]>(() => {
    const data: YearlyRecord[] = [];
    yearlyData?.forEach((d) => {
      data?.push({
        portfolio: d?.portfolio,
        record: yearList
          ?.sort()
          ?.map((yr) => {
            return d?.record?.filter((r) => {
              return yr === r?.transyear;
            });
          })
          ?.flat(1),
      });
    });
    return data;
  }, [yearlyData, yearList]);

  const MonthlyFilteredData = useMemo<MonthlyRecord[]>(() => {
    const data: MonthlyRecord[] = [];
    monthlyData?.forEach((d) => {
      data?.push({
        portfolio: d?.portfolio,
        record: yearList
          ?.map((yr) => {
            return d?.record?.filter((r) => {
              return yr === r?.transyear;
            });
          })
          ?.flat(1),
      });
    });
    return data;
  }, [monthlyData, yearList]);

  const WeeklyFilteredData = useMemo<WeeklyRecord[]>(() => {
    const data: WeeklyRecord[] = [];
    const selectedYears = yearList?.map((f) => f)?.sort();
    weeklyData?.forEach((d) => {
      data?.push({
        portfolio: d?.portfolio,
        record: selectedYears
          ?.map((yr) => {
            return d?.record?.filter((r) => {
              return yr?.toString() === r?.transyear?.toString();
            });
          })
          ?.flat(1),
      });
    });
    return data;
  }, [weeklyData, yearList]);

  const weeekLable = useMemo<number[]>(() => {
    const weeks: number[] = [];
    WeeklyFilteredData?.forEach((y, i) => {
      y?.record?.forEach((r) => weeks?.push(r?.weeknumber));
    });

    return weeks
      ?.sort(function (a, b) {
        return a - b;
      })
      ?.filter(getOnlyUnique);
  }, [WeeklyFilteredData]);

  useEffect(() => {
    var getData = {
      portfolio: props?.portfolioId ? [props?.portfolioId] : [],
      site: props?.siteId ? props?.siteId : [],
      tradeCategory: props?.tradeCategory,
      tradeSubCategory: props?.tradeSubCategory,
      country: props?.country,
      state: props?.state,
      region: props?.region,
      city: props?.city,
      authUserRole: authUser?.roleId ? authUser?.roleId : "",
    };
    if (
      getData?.portfolio?.length > 0 &&
      props?.portfolioId &&
      getData !== undefined && apiCall1
    ) {
      api
        .post<APIResponse<{ records: YearlyRecord[] }>>(
          `kpi/retail/tradecategorysales/yearly`,
          getData,
          config
        )
        .then((response) => {
          if (response?.status === 200 && response?.data?.data) {
            const result = response?.data?.data?.records;
            setYearlyData(result);
            setApiCall1(false)
          }
          else {
            setYearlyData([]);
            setApiCall1(false)
          }
        })
        .catch((error) => {});
    } 
    }, [
    props?.portfolioId,
    props?.siteId,
    apiCall1
  ]);

  useEffect(() => {
    var getData = {
      portfolio: props?.portfolioId ? [props?.portfolioId] : [],
      site: props?.siteId ? props?.siteId : [],
      tradeCategory: props?.tradeCategory,
      tradeSubCategory: props?.tradeSubCategory,
      country: props?.country,
      state: props?.state,
      region: props?.region,
      city: props?.city,
      authUserRole: authUser?.roleId ? authUser?.roleId : "",
    };
    if (
      getData?.portfolio?.length > 0 &&
      props?.portfolioId &&
      getData !== undefined && apiCall2 && props?.commonApiEnabler
    ) {
      api
        .post<APIResponse<{ records: MonthlyRecord[] }>>(
          `kpi/retail/tradecategorysales/monthly`,
          getData,
          config
        )
        .then((response) => {
          if (response?.status === 200 && response?.data?.data) {
            const result = response?.data?.data?.records;
            setMonthlyData(result);
            setApiCall2(false)
          }
          else {
            setMonthlyData([]);
            setApiCall2(false)
          }
        })
        .catch((error) => {});
    } 
    }, [
    props?.portfolioId,
    props?.siteId,
   apiCall2,
   props?.commonApiEnabler
  ]);

  useEffect(() => {
    let pid = props?.portfolioId ? [props?.portfolioId] : [];
    var obj = portfolioOption?.find((p) => p?.id === Number(pid));
    let valuecaption = obj?.valuecaption ? obj?.valuecaption : "in Millions";
    setValueIn(valuecaption);
  }, [props, portfolioOption]);

  useEffect(() => {
    var getData = {
      portfolio: props?.portfolioId ? [props?.portfolioId] : [],
      site: props?.siteId ? props?.siteId : [],
      tradeCategory: props?.tradeCategory,
      tradeSubCategory: props?.tradeSubCategory,
      country: props?.country,
      state: props?.state,
      region: props?.region,
      city: props?.city,
      authUserRole: authUser?.roleId ? authUser?.roleId : "",
    };
    if (
      getData?.portfolio?.length > 0 &&
      props?.portfolioId &&
      getData !== undefined && apiCall3
    ) {
      api
        .post<APIResponse<{ records: WeeklyRecord[] }>>(
          `kpi/retail/tradecategorysales/weekly`,
          getData,
          config
        )
        .then((response) => {
          if (response?.status === 200 && response?.data?.data) {
            const result = response?.data?.data?.records;
            setWeeklyData(result);
            setApiCall3(false)
          }
          else {
            setWeeklyData([]);
            setApiCall3(false)
          }
        })
        .catch((error) => {});
    } 
  }, [
    props?.portfolioId,
    props?.siteId,
    apiCall3
  ]);

  const colors = [
    COLORS?.COLOR1,
    COLORS?.COLOR2,
    COLORS?.COLOR3,
    COLORS?.COLOR4,
    COLORS?.COLOR5,
    COLORS?.COLOR6,
    COLORS?.COLOR7,
    COLORS?.COLOR8,
  ];

  const getWEEKData = () => {
    var monthlyTableData: string[][] = [
      [
        'Trade Category',
        "Year",
        'Week',
        "Sales",
      ],
    ];

    yearList?.map((a)=>{
      
      tradecategoryOption?.map((f)=>{
        weeekLable?.map((g:number)=>{
    weeklyData?.forEach((y, i) => {
          y?.record?.filter((b)=>b?.transyear?.toString()===a?.toString()&&f?.id?.toString()===b?.tradecatid?.toString()&&b?.weeknumber?.toString()===g?.toString())
            ?.forEach((r) => {
              const tradeCat = tradecategoryOption?.findIndex((e)=>e?.id?.toString()===r?.tradecatid?.toString())
                let sum = []
                 sum?.push(tradecategoryOption[tradeCat]?.name?.toString()??'')
              sum?.push(r?.transyear?.toString());
              sum?.push(r?.weeknumber?.toString());
              sum?.push(
                r?.sales !== undefined && r?.sales !== null ? r?.sales : "-"
              );
                monthlyTableData?.push(sum);
        });
      })
    });
  })
  })
    return monthlyTableData;
  };

  const getMonthlyMOMData = () => {
    var monthlyTableData: string[][] = [
      [
        'Trade Category',
        "Year-Month",
        "Sales",
      ],
    ];

    yearList?.map((a)=>{
      tradecategoryOption?.map((f)=>{
    monthlyData?.forEach((y, i) => {
          y?.record?.filter((b)=>b?.transyear?.toString()===a?.toString()&&f?.id?.toString()===b?.tradecatid?.toString())
            ?.forEach((r) => {
              const tradeCat = tradecategoryOption?.findIndex((e)=>e?.id?.toString()===r?.tradecatid?.toString())
                let sum = []
                 sum?.push(tradecategoryOption[tradeCat]?.name?.toString()??'')
              const month = monthsArray[r?.transmonth - 1];
              sum?.push(r?.transyear?.toString() + "-" + month);
              sum?.push(
                r?.sales !== undefined && r?.sales !== null ? r?.sales : "-"
              );
                monthlyTableData?.push(sum);
        });
    });
  })
  })
    return monthlyTableData;
  };

  const getYearlyYOYData = () => {
    var monthlyTableData: string[][] = [
      [
        'Trade Category',
        "Year",
        "Sales",
      ],
    ];

    yearList?.map((a)=>{
      tradecategoryOption?.map((f)=>{
    yearlyData?.forEach((y, i) => {
          y?.record?.filter((b)=>b?.transyear?.toString()===a?.toString()&&f?.id?.toString()===b?.tradecatid?.toString())
            ?.forEach((r) => {
              const tradeCat = tradecategoryOption?.findIndex((e)=>e?.id?.toString()===r?.tradecatid?.toString())
                let sum = []
                 sum?.push(tradecategoryOption[tradeCat]?.name?.toString()??'')
              sum?.push(r?.transyear?.toString());
              sum?.push(
                r?.sales !== undefined && r?.sales !== null ? r?.sales : "-"
              );
                monthlyTableData?.push(sum);
        });
    });
  })
  })
    return monthlyTableData;
  };

  useEffect(() => { 

    let {tradeCategory,tradeSubCategory,city,country,region,state} = props


  if(changedRef?.current?.tradeCat !== tradeCategory?.toString()||changedRef?.current?.tradeSubCat!==tradeSubCategory?.toString()
      || changedRef?.current?.city!==city?.toString()
  || changedRef?.current?.country!==country?.toString()
  || changedRef?.current?.region!==region?.toString()
  || changedRef?.current?.state!==state?.toString()
  ) {
    changedRef.current = {
    tradeCat:tradeCategory?.toString(),
    tradeSubCat:tradeSubCategory?.toString(),
    city:city?.toString(),
    country:country?.toString(),
    region:region?.toString(),
    state:state?.toString()
    };
    setApiCall1(true)
    setApiCall2(true)
    setApiCall3(true)
  }
}, [ props?.tradeCategory,props?.tradeSubCategory,props?.city,props?.country,props?.region,props?.state]);


  return (
    <Widget1
      title={"Trade Category Sales"}
      weekHandler={weekHandler}
      momHandler={momHandler}
      yoyHandler={yoyHandler}
      tableViewHandler={tableViewHandler}
      chartViewHandler={chartViewHandler}
      selection={selection}
      view={view}
      showMTD={false}
      showYTD={false}
      showWEEK={true}
      valueInString={valueIn}
      showExpandModal={true}
      showExpand={true}
      showExpandHandler={showExpandHandler}
      showGridHandler={showGridHandler}
      showGrid={true}
    >
      {selection === PortfolioDashboardList?.WEEK &&
        view === KpiViewList?.CHART && (
          <LineChart1
            datasets={(() => {
              const wdata: { name: string; data: number[] }[] = [];
              WeeklyFilteredData?.forEach((y, i) => {
                // const index = portfolioOption?.findIndex(
                //   (p) =>
                //     p?.id !== undefined &&
                //     p?.id?.toString() === y?.portfolio?.toString()
                // );
                // const name = portfolioOption[index]?.name;
                tradecategoryOption?.forEach((c) => {
                  const selectedYears = yearList?.map((f) => f)?.sort();
                  selectedYears?.forEach((s) => {
                    let data2 = y?.record
                      ?.filter(
                        (sw) => sw?.tradecatid?.toString() === c?.id?.toString()
                      )
                      ?.filter((y) => y?.transyear?.toString() === s?.toString())
                      ?.map((r) => (r?.sales ? parseFloat(r?.sales) : 0));

                    let isEmpty: string[] = [];
                    data2?.forEach((d2) => {
                      if (d2 !== 0) isEmpty?.push("true");
                      else isEmpty?.push("false");
                    });
                    if (data2?.length > 0 && isEmpty?.includes("true")) {
                      wdata?.push({
                        name: c?.name + "-" + s,
                        data: data2,
                      });
                    }
                  });
                });
              });
              return wdata;
            })()}
            labels={weeekLable?.map((w) => w?.toString())}
            colors={[
              colors[0],
              colors[1],
              colors[2],
              colors[3],
              colors[4],
              colors[5],
              colors[6],
              colors[7],
            ]}
            title={(() => {
              const txt = `Trade Category Sales - WEEK`;
              return txt;
            })()}
            subtitle={(() => {
              let subtxt: string[] = [];
              let subtxt1 = `\u3010Portfolio\u3011: ${propPfname} \u3010Period\u3011: ${yearList[0]} - ${yearList[2]}`;
              let subtxt2 = ``;
              if (
                props?.tradeCategory !== undefined &&
                props?.tradeCategory?.length > 0
              ) {
                subtxt1 += `\u3010TradeCategory\u3011: ${propCatname}`;
              }
              if (
                props?.tradeSubCategory !== undefined &&
                props?.tradeSubCategory?.length > 0
              ) {
                subtxt1 += `\u3010TradeSubCategory\u3011: ${propsubCatname}`;
              }
              if (props?.country !== undefined && props?.country?.length > 0) {
                subtxt2 += `\u3010Country\u3011: ${propConame}`;
              }
              if (props?.region !== undefined && props?.region?.length > 0) {
                subtxt2 += `\u3010Region\u3011: ${propRename}`;
              }
              if (props?.state !== undefined && props?.state?.length > 0) {
                subtxt2 += `\u3010State\u3011: ${propStname}`;
              }
              if (props?.city !== undefined && props?.city?.length > 0) {
                subtxt2 += `\u3010City\u3011: ${propCiname}`;
              }
              subtxt?.push(subtxt1);
              subtxt?.push(subtxt2);
              return subtxt;
            })()}
            exportfilename={"TradeCategorySales-WEEK"}
          />
        )}

      {selection === PortfolioDashboardList?.MOM &&
        view === KpiViewList?.CHART && (
          <BarChart1
            datasets={(() => {
              let mdata: { name: string; data: number[] }[] = [];
              MonthlyFilteredData?.forEach((y, i) => {
                // const index = portfolioOption?.findIndex(
                //   (p) =>
                //     p?.id !== undefined &&
                //     p?.id?.toString() === y?.portfolio?.toString()
                // );
                // const name = portfolioOption[index]?.name;

                tradecategoryOption?.forEach((s) => {
                  yearList?.forEach((sy) => {
                    let data2: number[] = [];
                    let d1 = y?.record
                      ?.filter(
                        (sw) => sw?.tradecatid?.toString() === s?.id?.toString()
                      )
                      ?.filter(
                        (y) => y?.transyear?.toString() === sy?.toString()
                      );
                    monthsArray
                      ?.map((f) => f)
                      ?.sort()
                      ?.forEach((m, mi) => {
                        var mns = d1?.map((d) => d?.transmonth);
                        if (mns?.includes(mi + 1)) {
                          let obj = d1?.find((f) => f?.transmonth === mi + 1);
                          data2?.push(obj?.sales ? parseFloat(obj?.sales) : 0);
                        } else data2?.push(0);
                      });
                    let isEmpty: string[] = [];
                    data2?.forEach((d2) => {
                      if (d2 !== 0) isEmpty?.push("true");
                      else isEmpty?.push("false");
                    });

                    if (data2?.length > 0 && isEmpty?.includes("true")) {
                      mdata?.push({
                        name: s?.name + "-" + sy,
                        data: data2,
                      });
                    }
                  });
                });
              });

              return mdata;
            })()}
            labels={monthsArray}
            colors={[
              colors[0],
              colors[1],
              colors[2],
              colors[3],
              colors[4],
              colors[5],
              colors[6],
              colors[7],
            ]}
            title={(() => {
              const txt = `Trade Category Sales - MOM`;
              return txt;
            })()}
            subtitle={(() => {
              let subtxt: string[] = [];
              let subtxt1 = `\u3010Portfolio\u3011: ${propPfname} \u3010Period\u3011: ${yearList[0]} - ${yearList[2]}`;
              let subtxt2 = ``;
              if (
                props?.tradeCategory !== undefined &&
                props?.tradeCategory?.length > 0
              ) {
                subtxt1 += `\u3010TradeCategory\u3011: ${propCatname}`;
              }
              if (
                props?.tradeSubCategory !== undefined &&
                props?.tradeSubCategory?.length > 0
              ) {
                subtxt1 += `\u3010TradeSubCategory\u3011: ${propsubCatname}`;
              }
              if (props?.country !== undefined && props?.country?.length > 0) {
                subtxt2 += `\u3010Country\u3011: ${propConame}`;
              }
              if (props?.region !== undefined && props?.region?.length > 0) {
                subtxt2 += `\u3010Region\u3011: ${propRename}`;
              }
              if (props?.state !== undefined && props?.state?.length > 0) {
                subtxt2 += `\u3010State\u3011: ${propStname}`;
              }
              if (props?.city !== undefined && props?.city?.length > 0) {
                subtxt2 += `\u3010City\u3011: ${propCiname}`;
              }
              subtxt?.push(subtxt1);
              subtxt?.push(subtxt2);
              return subtxt;
            })()}
            exportfilename={"TradeCategorySales-MOM"}
          />
        )}

      {selection === PortfolioDashboardList?.YOY &&
        view === KpiViewList?.CHART && (
          <BarChart1
            datasets={(() => {
              let ydata: { name: string; data: number[] }[] = [];
              YearlyFilteredData?.forEach((y, i) => {
                // const index = portfolioOption?.findIndex(
                //   (p) =>
                //     p?.id !== undefined &&
                //     p?.id?.toString() === y?.portfolio?.toString()
                // );
                // const name = portfolioOption[index]?.name;

                tradecategoryOption?.forEach((c) => {
                  let data2: number[] = [];
                  let d1 = y?.record?.filter(
                    (sw) => sw?.tradecatid?.toString() === c?.id?.toString()
                  );
                  yearList?.forEach((yr) => {
                    var yrs = d1?.map((d) => d?.transyear);
                    if (yrs?.includes(yr)) {
                      let obj = d1?.find((f) => f?.transyear === yr);
                      data2?.push(obj?.sales ? parseFloat(obj?.sales!) : 0);
                    } else data2?.push(0);
                  });
                  if (data2?.length > 0) {
                    ydata?.push({
                      name: c?.name,
                      data: data2,
                    });
                  }
                });
              });
              return ydata;
            })()}
            labels={yearList?.map((y) => y?.toString())?.sort()}
            colors={[
              colors[0],
              colors[1],
              colors[2],
              colors[3],
              colors[4],
              colors[5],
              colors[6],
              colors[7],
            ]}
            title={(() => {
              const txt = `Trade Category Sales - YOY`;
              return txt;
            })()}
            subtitle={(() => {
              let subtxt: string[] = [];
              let subtxt1 = `\u3010Portfolio\u3011: ${propPfname}\u2003 \u3010Period\u3011: ${yearList[0]} - ${yearList[2]}\u2003`;
              let subtxt2 = ``;
              if (
                props?.tradeCategory !== undefined &&
                props?.tradeCategory?.length > 0
              ) {
                subtxt1 += `\u3010TradeCategory\u3011: ${propCatname}\u2003`;
              }
              if (
                props?.tradeSubCategory !== undefined &&
                props?.tradeSubCategory?.length > 0
              ) {
                subtxt1 += `\u3010TradeSubCategory\u3011: ${propsubCatname}\u2003`;
              }
              if (props?.country !== undefined && props?.country?.length > 0) {
                subtxt2 += `\u3010Country\u3011: ${propConame}\u2003`;
              }
              if (props?.region !== undefined && props?.region?.length > 0) {
                subtxt2 += `\u3010Region\u3011: ${propRename}\u2003`;
              }
              if (props?.state !== undefined && props?.state?.length > 0) {
                subtxt2 += `\u3010State\u3011: ${propStname}\u2003`;
              }
              if (props?.city !== undefined && props?.city?.length > 0) {
                subtxt2 += `\u3010City\u3011: ${propCiname}\u2003`;
              }
              subtxt?.push(subtxt1);
              subtxt?.push(subtxt2);
              return subtxt;
            })()}
            exportfilename={"TradeCategorySales-YOY"}
          />
        )}


{showTableModal && selection === PortfolioDashboardList?.MOM && (
              <Modal
              size="xl"
              visible = {showTableModal}
              onClose={() => setShowTableModal(false)}
              centered classname="grid-container"
              >
              <RetailPerformanceTable
                    data={getMonthlyMOMData()}
                    title={`Trade Category Sales (MOM) - ${propPfname}`}
                    fileName={"pf_monthly_sales"}
                    downloadCSV={false}
                    downloadPDF={false}
                  />
              </Modal>
        )}

{showTableModal && selection === PortfolioDashboardList?.YOY && (
              <Modal
              size="xl"
              visible = {showTableModal}
              onClose={() => setShowTableModal(false)}
              centered classname="grid-container"
              >
              <RetailPerformanceTable
                    data={getYearlyYOYData()}
                    title={`Trade Category Sales (YOY) - ${propPfname}`}
                    fileName={"pf_monthly_sales"}
                    downloadCSV={false}
                    downloadPDF={false}
                  />
              </Modal>
        )}

{showTableModal && selection === PortfolioDashboardList?.WEEK && (
              <Modal
              size="xl"
              visible = {showTableModal}
              onClose={() => setShowTableModal(false)}
              centered classname="grid-container"
              >
              <RetailPerformanceTable
                    data={getWEEKData()}
                    title={`Trade Category Sales (WEEK) - ${propPfname}`}
                    fileName={"pf_monthly_sales"}
                    downloadCSV={false}
                    downloadPDF={false}
                    percent = {false}
                  />
              </Modal>
        )}
    </Widget1>
  );
};
export default BrandDashboardCategorySalesWidget;
