import api, { APIResponse, getAxiosRequestConfig } from "../../config/api"
import { Dispatch } from "redux"
import { GetState } from "../store"

export interface SessionType {
    isLoggedIn: boolean;
}
export interface RegionType {
    id: number
    countryid: number
    regionname: string
}
export interface StateType {
    id: number
    countryid: number
    regionid: number
    statename: string
    statecode: string
}
export interface CityType {
    id: number
    countryid: number
    regionid: number
    stateid: number
    cityname: string
}
export enum SessionActionList {
    SET_SESSION = "SET_SESSION",
    CLEAR_SESSION = "CLEAR_SESSION",
}
export interface SetSessionAction{
    type: SessionActionList.SET_SESSION;
    data: SessionType;
}
export interface ClearSessionAction{
    type: SessionActionList.CLEAR_SESSION;
    data: SessionType;
}
export type SessionActions = SetSessionAction | ClearSessionAction;
export enum RegionActionsList {
    FETCH_REGION = 'FETCH_REGION',
    CLEAR_REGION = 'CLEAR_REGION'
}

export interface FetchRegionAction {
    type: RegionActionsList.FETCH_REGION
    data: RegionType[]

}

export interface ClearRegionAction {
    type: RegionActionsList.CLEAR_REGION

}
export type RegionActions = FetchRegionAction | ClearRegionAction

export enum StateActionsList {
    FETCH_STATE = 'FETCH_STATE',
    CLEAR_STATE = 'CLEAR_STATE'
}

export interface FetchStateAction {
    type: StateActionsList.FETCH_STATE
    data: StateType[]

}

export interface ClearStateAction {
    type: StateActionsList.CLEAR_STATE

}
export type StateActions = FetchStateAction | ClearStateAction

export enum CityActionsList {
    FETCH_CITY = 'FETCH_CITY',
    CLEAR_CITY = 'CLEAR_CITY'
}

export interface FetchCityAction {
    type: CityActionsList.FETCH_CITY
    data: CityType[]

}

export interface ClearCityAction {
    type: CityActionsList.CLEAR_CITY

}
export type CityActions = FetchCityAction | ClearCityAction

export const clearSession = () => {
    return async (dispatch: Dispatch, getState: GetState) => {
        return dispatch<ClearSessionAction>({
            type: SessionActionList.CLEAR_SESSION,
            data: {isLoggedIn: false}
        });
    };
};
export const setSession = () => {
    return async (dispatch: Dispatch, getState: GetState) => {
        return dispatch<SetSessionAction>({
            type: SessionActionList.SET_SESSION,
            data: {isLoggedIn: true}
        });
    };
};
export const clearRegion = () => {
    return async (dispatch: Dispatch, getState: GetState) => {
        return dispatch<ClearRegionAction>({
            type: RegionActionsList.CLEAR_REGION

        })
    }
}

export const fetchRegion = (countryid: string) => {
    return async (dispatch: Dispatch, getState: GetState) => {
        const token = getState().authUser?.['auth-token']!
        const config = getAxiosRequestConfig(token)

        return api.get<APIResponse<RegionType[]>>(`region/${countryid}`, config).then(response => {
            if (response.status === 200 && response.data.data) {
                dispatch<FetchRegionAction>({
                    type: RegionActionsList.FETCH_REGION,
                    data: response.data.data
                })
                return Promise.resolve(response.data.message
                    ? response.data.message
                    : 'Fetch Successfull')
            } else {
                // throw { response }
                throw new Error("Unable to fetch")
            }
        }).catch(error => {
            return Promise.reject(error.response
                ? error.response.data.message
                    ? error.response.data.message
                    : 'Unable to fetch'
                : 'Unable to fetch')
        })
    }
}

export const fetchRegionByRetailId = () => {
    return async (dispatch: Dispatch, getState: GetState) => {
        const token = getState().authUser?.['auth-token']!
        const config = getAxiosRequestConfig(token)
        const authUserPfId = getState().authUser?.['portfolioId']
        return api.get<APIResponse<RegionType[]>>(`regionsbyretailid/${authUserPfId}`, config).then(response => {
            if (response.status === 200 && response.data.data) {
                dispatch<FetchRegionAction>({
                    type: RegionActionsList.FETCH_REGION,
                    data: response.data.data
                })
                return Promise.resolve(response.data.message
                    ? response.data.message
                    : 'Fetch Successfull')
            } else {
                throw new Error("Unable to fetch")
            }
        }).catch(error => {
            return Promise.reject(error.response
                ? error.response.data.message
                    ? error.response.data.message
                    : 'Unable to fetch'
                : 'Unable to fetch')
        })
    }
}
export const clearState = () => {
    return async (dispatch: Dispatch, getState: GetState) => {
        return dispatch<ClearStateAction>({
            type: StateActionsList.CLEAR_STATE

        })
    }
}
export const fetchStateByRetailId = () => {
    return async (dispatch: Dispatch, getState: GetState) => {
        const token = getState().authUser?.['auth-token']!
        const config = getAxiosRequestConfig(token)
        const authUserPfId = getState().authUser?.['portfolioId']
        return api.get<APIResponse<StateType[]>>(`statesbyretailid/${authUserPfId}`, config).then(response => {
            if (response.status === 200 && response.data.data) {
                dispatch<FetchStateAction>({
                    type: StateActionsList.FETCH_STATE,
                    data: response.data.data
                })
                return Promise.resolve(response.data.message
                    ? response.data.message
                    : 'Fetch Successfull')
            } else {
                throw new Error("Unable to fetch")
            }
        }).catch(error => {
            return Promise.reject(error.response
                ? error.response.data.message
                    ? error.response.data.message
                    : 'Unable to fetch'
                : 'Unable to fetch')
        })
    }
}
export const fetchState = (countryid: string, regionid: string) => {
    return async (dispatch: Dispatch, getState: GetState) => {
        const token = getState().authUser?.['auth-token']!
        const config = getAxiosRequestConfig(token)
        const regionId = regionid ? regionid : 0 
        return api.get<APIResponse<StateType[]>>(`state/${countryid}/${regionId}`, config).then(response => {
            if (response.status === 200 && response.data.data) {
                dispatch<FetchStateAction>({
                    type: StateActionsList.FETCH_STATE,
                    data: response.data.data
                })
                return Promise.resolve(response.data.message
                    ? response.data.message
                    : 'Fetch Successfull')
            } else {
                throw new Error("Unable to fetch")
            }
        }).catch(error => {
            return Promise.reject(error.response
                ? error.response.data.message
                    ? error.response.data.message
                    : 'Unable to fetch'
                : 'Unable to fetch')
        })
    }
}
export const clearCity = () => {
    return async (dispatch: Dispatch, getState: GetState) => {
        return dispatch<ClearCityAction>({
            type: CityActionsList.CLEAR_CITY

        })
    }
}

export const fetchCity = (countryid: string, stateid: string) => {
    return async (dispatch: Dispatch, getState: GetState) => {
        const token = getState().authUser?.['auth-token']!
        const config = getAxiosRequestConfig(token)

        return api.get<APIResponse<CityType[]>>(`city/${countryid}/${stateid}`, config).then(response => {
            if (response.status === 200 && response.data.data) {
                dispatch<FetchCityAction>({
                    type: CityActionsList.FETCH_CITY,
                    data: response.data.data
                })
                return Promise.resolve(response.data.message
                    ? response.data.message
                    : 'Fetch Successfull')
            } else {
                throw new Error("Unable to fetch")
            }
        }).catch(error => {
            return Promise.reject(error.response
                ? error.response.data.message
                    ? error.response.data.message
                    : 'Unable to fetch'
                : 'Unable to fetch')
        })
    }
}

export const fetchCityByRetailId = () => {
    return async (dispatch: Dispatch, getState: GetState) => {
        const token = getState().authUser?.['auth-token']!
        const config = getAxiosRequestConfig(token)
        const authUserPfId = getState().authUser?.['portfolioId']
        return api.get<APIResponse<CityType[]>>(`citiesbyretailid/${authUserPfId}`, config).then(response => {
            if (response.status === 200 && response.data.data) {
                dispatch<FetchCityAction>({
                    type: CityActionsList.FETCH_CITY,
                    data: response.data.data
                })
                return Promise.resolve(response.data.message
                    ? response.data.message
                    : 'Fetch Successfull')
            } else {
                throw new Error("Unable to fetch")
            }
        }).catch(error => {
            return Promise.reject(error.response
                ? error.response.data.message
                    ? error.response.data.message
                    : 'Unable to fetch'
                : 'Unable to fetch')
        })
    }
}