import { nanoid } from '@reduxjs/toolkit';
import classNames from 'classnames';
import React from 'react';
import { Link } from 'react-router-dom';
// import logoDark from '../assets/images/logo-grey.png';
// import CreateNew from '../components/CreateNew';
//import LanguageDropdown from '../components/LanguageDropdown';
import { useSelector } from 'react-redux';
import { AuthUserType } from '../../../redux/actions/authUserActions';
import { StoreState } from '../../../redux/store';
import { getUserMenuItems } from '../helpers/menu';

export interface NotificationItem {
    id: number;
    text: string;
    subText: string;
    icon?: string;
    avatar?: string;
    bgColor?: string;
}


interface UserTopNavbarProps {
    hideLogo?: boolean;
    navCssClasses?: string;
    openLeftMenuCallBack?: () => void;
    topbarDark?: boolean;
    isContentPageAtTheTop?: boolean;
}

const UserTopNavbar = ({ hideLogo, navCssClasses, openLeftMenuCallBack, topbarDark, isContentPageAtTheTop }: UserTopNavbarProps) => {
    const authUser = useSelector<StoreState, AuthUserType | null>(state => state.authUser)
    const roles = authUser?.role
    // const [isopen, setIsopen] = useState<boolean>(false);

    // const navbarCssClasses: string = navCssClasses || '';
    // const containerCssClasses: string = !hideLogo ? 'container-fluid' : '';
    const menuItems = getUserMenuItems()
    /**
     * Toggle the leftmenu when having mobile screen
     */
    const currentPath = window.location.pathname;


    return (
        <React.Fragment>
            {/* <div className={`navbar-custom`}>
                <div className={containerCssClasses}> */}

            <div className={classNames("topnav", { scrolled: isContentPageAtTheTop === false })}>
                <div className="container-fluid px-2 pb-2">
                    <nav className="navbar navbar-light navbar-expand-lg topnav-menu">

                        <div className="collapse navbar-collapse" id="topnav-menu-content">
                            <ul className="navbar-nav ms-3">
                                {/* <AppMenu menuItems={getUserMenuItems()} /> */}
                                {menuItems.map((m, i) => {
                                    let viewArr: string[] = []
                                    let currentMenuArr: string[] = []
                                    return <React.Fragment key={i}>

                                        {m.children && m.children?.length > 0 && m.children?.forEach((c, i2) => {
                                            let obj = roles?.find(r => r.pageurl === c.url && r.pagetitle === c.label)
                                            let view = obj?.view
                                            viewArr.push(view !== undefined ? view.toString() : '')

                                            let currentMenuObj = (currentPath === c.url) ? 'true' : 'false'
                                            currentMenuArr.push(currentMenuObj)
                                        })
                                        }

                                        {(i === 0 || viewArr.includes('true')) && < li className="nav-item dropdown" key={nanoid()}>
                                            <Link className={currentMenuArr.includes('true') ? 'nav-link active dropdown-toggle arrow-none p-2' : 'nav-link dropdown-toggle arrow-none p-2'} to={m.url!} id="topnav-apps"
                                                role="button"
                                                data-bs-toggle="dropdown"
                                                aria-haspopup="true"
                                                aria-expanded="false">

                                                {/* {React.createElement(m.Icon!, { width: 15, height: 15 })} {m.label} */}
                                                <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path d={m.svgPath} fill={currentMenuArr.includes('true') ? "white" : 'black'}></path>
                                                </svg>
                                                <span className=''> {m.label}</span>
                                                {m.children && m.children?.length > 0 && <div className="arrow-down"></div>}
                                            </Link>
                                            {m.children && m.children?.length > 0 && < div className="dropdown-menu" aria-labelledby="topnav-apps">
                                                {m.children.map((c, i) => {
                                                   
                                                    let obj = roles?.find(r => r.pageurl === c.url && r.pagetitle === c.label)
                                                    let view = obj?.view
                                                    return <React.Fragment key={i}>
                                                        {view && <Link to={obj?.pageurl!} className="dropdown-item">  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path d={c.svgPath} fill={currentMenuArr.includes('true') ? "#6C757D" : '#6C757D'}></path>
                                                </svg> {obj?.pagetitle}</Link>
                                                        }
                                                    </React.Fragment>
                                                })
                                                    // })
                                                }
                                            </div>}
                                        </li >}
                                    </React.Fragment>
                                })
                                }
                            </ul>
                        </div>
                    </nav>
                </div>
            </div >

            {/* </div>
            </div> */}
        </React.Fragment >
    );
};

export default UserTopNavbar;
