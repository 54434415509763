import { ApexOptions } from "apexcharts";
import Chart from "react-apexcharts";

interface dataSet {
  name: string;
  type: string;
  decimal?: boolean;
  data: number[];
}

interface Iprops {
  datasets: dataSet[];
  labels: string[];
  title: string;
  colors: string[];
  subtitle: string[];
  exportfilename: string;
}
const MultipleYAxisChart1 = (props: Iprops) => {
  const { colors, datasets, labels, title, exportfilename, subtitle } = props;
  let yaxisdata: any[] = [];

  datasets.map((d, i) => {
    if (d.decimal === true) {
      return yaxisdata.push({
        seriesName: d.name,
        axisTicks: {
          // show: i > 1 ? false : true,
          show: false,
          offsetX: 0,
          offsetY: 0,
        },
        opposite: false,
        axisBorder: {
          show: i > 0 ? false : true,
          color: colors[i],
          offsetX: 0,
          offsetY: 0,
        },
        labels: {
          show: i > 1 ? false : true,
          offsetX: -3,
          style: {},
        },
        title: {
          text: i > 0 ? "" : "Sales",
          style: {
            color: colors[i],
          },
        },
        tooltip: {
          enabled: i > 0 ? false : true,
        },
      });
    } else {
      return yaxisdata.push({
        seriesName: d.name,
        axisTicks: {
          // show: i === datasets?.length-1 ? true : false,
          show: false,
          offsetX: 0,
          offsetY: 0,
        },
        opposite: true,
        axisBorder: {
          show: i === datasets?.length - 1 ? true : false,
          color: colors[i],
          offsetX: 0,
          offsetY: 0,
        },
        labels: {
          show: i === datasets?.length - 1 ? true : false,
          offsetX: -20,
          offsetY: 0,
          style: {},
        },
        title: {
          text: i === datasets?.length - 1 ? "%Change" : "",
          style: {
            color: colors[i],
          },
        },
        tooltip: {
          enabled: i > 0 ? false : true,
        },
      });
    }
  });
  const apexBarChartOpts: ApexOptions = {
    chart: {
      height: 350,
      type: "line",
      stacked: false,
      toolbar: {
        show: true,
        export: {
          csv: {
            filename: exportfilename,
          },
          svg: {
            filename: exportfilename,
          },
          png: {
            filename: exportfilename,
          },
        },
      },
    },
    title: {
      text: title,
      align: "center",
      margin: 18,
      offsetY: -7,
    },

    annotations: {
      texts: [
        {
          x: 30, // X position (center of the chart)
          y: 38, // Y position (20 pixels from the top)
          text: subtitle[0],

          fontSize: "12px",
          borderColor: "transparent",
          paddingTop: 3,
          paddingBottom: 2,
        },
        {
          x: 30, // X position (center of the chart)
          y: 55, // Y position (20 pixels from the top)
          text: subtitle[1],

          fontSize: "12px",
          borderColor: "transparent",
          paddingTop: 2,
          paddingBottom: 30,
        },
      ],
    },
    series: datasets,
    dataLabels: {
      enabled: true,
      style: {
        colors: ["#000"],
      },
    },
    stroke: {
      width: [1, 2, 2],
    },
    colors: colors,
    xaxis: {
      categories: labels,
    },
    yaxis: yaxisdata,
    tooltip: {
      fixed: {
        enabled: true,
        position: "topLeft",
        offsetY: 30,
        offsetX: 60,
      },
    },
    legend: {
      horizontalAlign: "center",
      offsetX: 40,
      labels: {
        colors: "#000",
      },
      showForSingleSeries: true,
    },
  };

  return (
    <>
      {/* <h4 className="header-title mb-3">{title}</h4> */}
      <Chart
        options={apexBarChartOpts}
        series={datasets}
        type="line"
        className="apex-charts"
        height={400}
      />
    </>
  );
};

export default MultipleYAxisChart1;
