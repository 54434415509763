import api, { APIResponse, getAxiosRequestConfig } from "../../config/api";
import { Dispatch } from "redux";
import { GetState } from "../store";

export enum ActiveDropDown {
  Active = "true",
  InActive = "false",
}
export type ActiveTypes = ActiveDropDown.Active | ActiveDropDown.InActive;

export interface PortfolioType {
  id?: number;
  companyid: number;
  name: string;
  url: string;
  code: string;
  logopath: string;
  logourl: string;
  businessmodel: number;
  reportingcurrency: string;
  currencysymbol: string;
  valuecaption: string;
  active: ActiveTypes | boolean;

  //PORTFOLIO DB

  pfid?: number;
  uid?: string;
  server?: string;
  pwd?: string;
  dbname?: string;
  createdBy?: number | null;
  createdAt?: string | null;
  updatedAt?: string | null;
  updatedBy?: string | null;
}

export enum PortfolioActionsList {
  ADD_PORTFOLIO = "ADD_PORTFOLIO",
  EDIT_PORTFOLIO = "EDIT_PORTFOLIO",
  FETCH_PORTFOLIO = "FETCH_PORTFOLIO",
  DELETE_PORTFOLIO = "DELETE_PORTFOLIO",
  CLEAR_PORTFOLIO = "CLEAR_PORTFOLIO",
}

export interface DeletePortfolio {
  id: number;
}

export interface AddPortfolioAction {
  type: PortfolioActionsList.ADD_PORTFOLIO;
  data: PortfolioType;
}

export interface EditPortfolioAction {
  type: PortfolioActionsList.EDIT_PORTFOLIO;
  data: PortfolioType;
  id?: number;
}

export interface FetchPortfolioAction {
  type: PortfolioActionsList.FETCH_PORTFOLIO;
  data: PortfolioType[];
}

export interface DeletePortfolioAction {
  type: PortfolioActionsList.DELETE_PORTFOLIO;
  data: DeletePortfolio;
}

export interface ClearPortfolioAction {
  type: PortfolioActionsList.CLEAR_PORTFOLIO;
}

export type PortfolioActions =
  | AddPortfolioAction
  | EditPortfolioAction
  | FetchPortfolioAction
  | DeletePortfolioAction
  | ClearPortfolioAction;


export const clearPortfolio = () => {
  return async (dispatch: Dispatch, getState: GetState) => {
    return dispatch<ClearPortfolioAction>({
      type: PortfolioActionsList.CLEAR_PORTFOLIO,
    });
  };
};


export const fetchPortfolio = () => {
  return async (dispatch: Dispatch, getState: GetState) => {
    // return dispatch<FetchPortfolioAction>({
    //     type: PortfolioActionsList.FETCH_PORTFOLIO
    //     // data: response.data
    // })
    const token =
      getState().authUser?.["auth-token"]!;
    const config = getAxiosRequestConfig(token);

    return api
      .get<APIResponse<PortfolioType[]>>("portfolio/", config)
      .then((response) => {
        if (response.status === 200 && response.data.data) {
          dispatch<FetchPortfolioAction>({
            type: PortfolioActionsList.FETCH_PORTFOLIO,
            data: response.data.data,
          });
          return Promise.resolve(
            response.data.message ? response.data.message : "Fetch Successfull"
          );
        } else {
          // throw { response };
          throw new Error("Unable to fetch")
        }
      })
      .catch((error) => {
        return Promise.reject(
          error.response
            ? error.response.data.message
              ? error.response.data.message
              : "Unable to fetch"
            : "Unable to fetch"
        );
      });
  };
};

export const fetchPortfolioByUserId = (userid: number) => {
  return async (dispatch: Dispatch, getState: GetState) => {
    const token =
      getState().authUser?.["auth-token"]!;
    const config = getAxiosRequestConfig(token);

    return api
      .get<APIResponse<PortfolioType[]>>(`portfolioByUser/${userid}`, config)
      .then((response) => {
        if (response.status === 200 && response.data.data) {
          dispatch<FetchPortfolioAction>({
            type: PortfolioActionsList.FETCH_PORTFOLIO,
            data: response.data.data,
          });
          return Promise.resolve(
            response.data.message ? response.data.message : "Fetch Successfull"
          );
        } else {
          throw new Error("Unable to fetch")
        }
      })
      .catch((error) => {
        return Promise.reject(
          error.response
            ? error.response.data.message
              ? error.response.data.message
              : "Unable to fetch"
            : "Unable to fetch"
        );
      });
  };
};