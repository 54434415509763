import React, {useState} from "react";
import { useDispatch, useSelector } from "react-redux";
import { PortfolioType } from "../../redux/actions/portfolioActions";
import { SiteType } from "../../redux/actions/siteActions";
import { StoreState } from "../../redux/store";
import LineChart from "../custom/components/charts/apex/LineChart";
import BarChart from "../custom/components/charts/apex/BarChart";
import { COLORS } from "../custom/constants";
import { YearOptions } from "../../config/functions";

interface Props {  
    monthlyData: {
        portfolio: number;
        record: {
            center: number;
            transyear: number;
            transmonth: number;
            sales: string;
            nooftransactions: number;
            footfall: number;
            averageticketsize: number;
            conversionrate: number;
        }[];
        count?: number;
      }[];
    yearAndMonth: Date;
    getSubtitle: string;
}
export const SalesConversionMonthly: React.FC<Props> = (props: Props) => {
    const { monthlyData, yearAndMonth, getSubtitle } = props;
    const month = [ "01", "02", "03", "04", "05", "06", "07", "08", "09", "10", "11", "12" ];
    const colors = [
        COLORS?.COLOR1,
        COLORS?.COLOR2,
        COLORS?.COLOR3,
        COLORS?.COLOR4,
        COLORS?.COLOR5,
        COLORS?.COLOR6,
        COLORS?.COLOR7,
        COLORS?.COLOR8,
      ];
    const siteOption = useSelector<StoreState, SiteType[]>((state) => state?.site);
    return (
        <>
        <LineChart
        datasets={(() => {
        let ydata: { name: string; data: number[] }[] = [];
        monthlyData?.forEach((y, i) => {
            const selectedYears = [
                yearAndMonth?.getFullYear(),
                yearAndMonth?.getFullYear() - 1,
            ];
            siteOption
            ?.filter(
                (ss) =>
                ss?.pfid?.toString() ===
                y?.portfolio?.toString()
            )
            ?.forEach((s) => {
                let data2: number[] = [];
                let d1 = y?.record?.filter(
                (sw) =>
                    sw?.center?.toString() === s?.id?.toString()
                );

                if (
                month[yearAndMonth?.getMonth()] !== "01"
                ) {
                selectedYears?.forEach((yr, yi) => {
                    var yrs = d1?.map((d) => d?.transyear);
                    if (yrs?.includes(yr)) {
                    if (yi === 0) {
                        let arr = d1?.filter(
                        (f) => f?.transyear === yr
                        );
                        arr?.forEach((obj) => {
                        data2?.push(
                            obj?.sales
                            ? parseFloat(obj?.sales)
                            : 0
                        );
                        });
                    } else {
                        let obj = d1?.find(
                        (f) => f?.transyear === yr
                        );
                        data2?.push(
                        obj?.sales
                            ? parseFloat(obj?.sales)
                            : 0
                        );
                    }
                    } else {
                    if (yi === 0) {
                        data2?.push(0);
                        data2?.push(0);
                    } else {
                        data2?.push(0);
                    }
                    }
                });
                } else {
                selectedYears?.forEach((yr, yi) => {
                    var yrs = d1?.map((d) => d?.transyear);
                    if (yrs?.includes(yr)) {
                    if (yi === 1) {
                        let arr = d1?.filter(
                        (f) => f?.transyear === yr
                        );
                        arr?.forEach((obj) => {
                        data2?.push(
                            obj?.sales
                            ? parseFloat(obj?.sales)
                            : 0
                        );
                        });
                    } else {
                        let obj = d1?.find(
                        (f) => f?.transyear === yr
                        );
                        data2?.push(
                        obj?.sales
                            ? parseFloat(obj?.sales)
                            : 0
                        );
                    }
                    } else {
                    if (yi === 1) {
                        data2?.push(0);
                        data2?.push(0);
                    } else {
                        data2?.push(0);
                    }
                    }
                });
                }

                let isEmpty: string[] = [];
                data2?.forEach((d2) => {
                if (d2 !== 0) isEmpty?.push("true");
                else isEmpty?.push("false");
                });

                if (
                data2?.length > 0 &&
                isEmpty?.includes("true")
                ) {
                ydata?.push({
                    name: s?.name,
                    data: data2,
                });
                }
            });
        });
        return ydata;
        })()}
        labels={(() => {
        if (month[yearAndMonth?.getMonth()] !== "01") {
            let a = (
            month[yearAndMonth?.getMonth()]?.toString() +
            "/" +
            yearAndMonth?.getFullYear()
            )?.toString();
            let b = (
            month[
                yearAndMonth?.getMonth() - 1
            ]?.toString() +
            "/" +
            yearAndMonth?.getFullYear()
            )?.toString();
            let c = (
            month[yearAndMonth?.getMonth()]?.toString() +
            "/" +
            (yearAndMonth?.getFullYear() - 1)
            )?.toString();
            return [a, b, c];
        } else if (
            month[yearAndMonth?.getMonth()] === "01"
        ) {
            let a = (
            month[yearAndMonth?.getMonth()]?.toString() +
            "/" +
            yearAndMonth?.getFullYear()
            )?.toString();
            let b = (
            month[yearAndMonth?.getMonth()]?.toString() +
            "/" +
            (yearAndMonth?.getFullYear() - 1)
            )?.toString();
            let c = (
            12?.toString() +
            "/" +
            (yearAndMonth?.getFullYear() - 1)
            )?.toString();
            return [a, b, c];
        } else return [];
        })()}
        colors={[
        colors[0],
        colors[1],
        colors[2],
        colors[3],
        colors[4],
        colors[5],
        colors[6],
        colors[7],
        ]}
        title={(() => {
        const txt = `Sales Conversion - Monthly `;
        return txt;
        })()}
        subtitle={(() => {
        let subtxt = getSubtitle ? getSubtitle : "";
        subtxt += ` \u3010Year\u3011: ${yearAndMonth?.getFullYear()}`;
        subtxt += ` \u3010Month\u3011: ${
            yearAndMonth?.getMonth() + 1
        }`;
        return subtxt;
        })()}
        exportfilename={"salesConversion-Monthly"}
    />
    </>
    );
};
interface YearlyProps {
    yearlyData: {
        portfolio: number;
        record: {
            center: number;
            sales: string;
            transyear: number;
            nooftransactions: number;
            footfall: number;
            averageticketsize: number;
            conversionrate: number;
        }[];
        count?: number;
    }[];
    year: Date;
    getSubtitle: string;
}
export const SalesConversionYearly: React.FC<YearlyProps> = (props: YearlyProps) => {    
    const { yearlyData, year, getSubtitle } = props;
    const [yearDropDown, setYearDropDown] = useState<number[]>(YearOptions());
    const colors = [
        COLORS?.COLOR1,
        COLORS?.COLOR2,
        COLORS?.COLOR3,
        COLORS?.COLOR4,
        COLORS?.COLOR5,
        COLORS?.COLOR6,
        COLORS?.COLOR7,
        COLORS?.COLOR8,
    ];
    const portfolioOption = useSelector<StoreState, PortfolioType[]>(
        (state) => state?.portfolio
    );
    const siteOption = useSelector<StoreState, SiteType[]>((state) => state?.site);
    return (
        <>
        <BarChart
        datasets={(() => {
            let ydata: { name: string; data: number[] }[] = [];
            yearlyData?.forEach((y, i) => {
            const index = portfolioOption?.findIndex(
                (p) =>
                p?.id !== undefined &&
                p?.id?.toString() === y?.portfolio?.toString()
            );
            const name = portfolioOption[index]?.name;
            siteOption?.filter(
            (ss) =>
                ss?.pfid?.toString() ===
                y?.portfolio?.toString()
            )?.forEach((s) => {
                let data2: number[] = [];
                let d1 = y?.record?.filter((sw) =>
                    sw?.center?.toString() === s?.id?.toString()
                );
                yearDropDown?.sort()?.forEach((yr) => {
                    var yrs = d1?.map((d) => d?.transyear);
                    if (yrs?.includes(yr)) {
                    let obj = d1?.find(
                        (f) => f?.transyear === yr
                    );
                    data2?.push(
                        obj?.sales ? parseFloat(obj?.sales) : 0
                    );
                    } else data2?.push(0);
                });
                let isEmpty: string[] = [];
                data2?.forEach((d2) => {
                    if (d2 !== 0) isEmpty?.push("true");
                    else isEmpty?.push("false");
                });
                if (
                    data2?.length > 0 &&
                    isEmpty?.includes("true")
                ) {
                    ydata?.push({
                    name: s?.name,
                    data: data2,
                    });
                }
                });
            });
            return ydata;
        })()}
        labels={
            yearDropDown?.map((y) => y?.toString())?.sort() || []
        }
        colors={[
            colors[0],
            colors[1],
            colors[2],
            colors[3],
            colors[4],
            colors[5],
            colors[6],
            colors[7],
        ]}
        title={(() => {
            const txt = `Sales Conversion - Yearly`;
            return txt;
        })()}
        subtitle={(() => {
            let subtxt = getSubtitle ? getSubtitle : "";
            subtxt += ` \u3010Period\u3011: ${year?.getFullYear() - 2} - ${year?.getFullYear()}`;
            return subtxt;
        })()}
        exportfilename={"SalesConversion-Yearly"}
        />
        </>
    );

};
interface WeeklyProps {  
    weeklyData: {
        portfolio: number;
        record: {
            center: number;
            transyear: number;
            transweek: number;
            sales: string;
            nooftransactions: number;
            footfall: number;
            averageticketsize: number;
            conversionrate: number;
        }[];
        count?: number;
      }[];
    yearAndMonth: Date;
    week: string;
    year: Date;
    getSubtitle: string;
}
export const SalesConversionWeekly: React.FC<WeeklyProps> = (props: WeeklyProps) => {
    const { weeklyData, yearAndMonth, week, year, getSubtitle } = props;
    const colors = [
        COLORS?.COLOR1,
        COLORS?.COLOR2,
        COLORS?.COLOR3,
        COLORS?.COLOR4,
        COLORS?.COLOR5,
        COLORS?.COLOR6,
        COLORS?.COLOR7,
        COLORS?.COLOR8,
    ];
    const siteOption = useSelector<StoreState, SiteType[]>((state) => state?.site);
    return (
        <>
       <LineChart
        datasets={(() => {
            let ydata: { name: string; data: number[] }[] = [];
            weeklyData?.forEach((y, i) => {
                const selectedYears = [
                    yearAndMonth?.getFullYear(),
                    yearAndMonth?.getFullYear() - 1,
                ];
                siteOption
                ?.filter(
                (ss) =>
                    ss?.pfid?.toString() ===
                    y?.portfolio?.toString()
                )
                ?.forEach((s) => {
                let data2: number[] = [];
                let d1 = y?.record?.filter(
                    (sw) =>
                    sw?.center?.toString() === s?.id?.toString()
                );

                if (week !== "1") {
                    selectedYears?.forEach((yr, yi) => {
                    var yrs = d1?.map((d) => d?.transyear);
                    if (yrs?.includes(yr)) {
                        if (yi === 0) {
                        let arr = d1?.filter(
                            (f) => f?.transyear === yr
                        );
                        arr?.forEach((obj) => {
                            data2?.push(
                            obj?.sales
                                ? parseFloat(obj?.sales)
                                : 0
                            );
                        });
                        } else {
                        let obj = d1?.find(
                            (f) => f?.transyear === yr
                        );
                        data2?.push(
                            obj?.sales ? parseFloat(obj?.sales) : 0
                        );
                        }
                    } else {
                        if (yi === 0) {
                        data2?.push(0);
                        data2?.push(0);
                        } else {
                        data2?.push(0);
                        }
                    }
                    });
                } else {
                    selectedYears?.forEach((yr, yi) => {
                    var yrs = d1?.map((d) => d?.transyear);
                    if (yrs?.includes(yr)) {
                        if (yi === 1) {
                        let arr = d1?.filter(
                            (f) => f?.transyear === yr
                        );
                        arr?.forEach((obj) => {
                            data2?.push(
                            obj?.sales
                                ? parseFloat(obj?.sales)
                                : 0
                            );
                        });
                        } else {
                        let obj = d1?.find(
                            (f) => f?.transyear === yr
                        );
                        data2?.push(
                            obj?.sales ? parseFloat(obj?.sales) : 0
                        );
                        }
                    } else {
                        if (yi === 1) {
                        data2?.push(0);
                        data2?.push(0);
                        } else {
                        data2?.push(0);
                        }
                    }
                    });
                }

                let isEmpty: string[] = [];
                data2?.forEach((d2) => {
                    if (d2 !== 0) isEmpty?.push("true");
                    else isEmpty?.push("false");
                });

                if (
                    data2?.length > 0 &&
                    isEmpty?.includes("true")
                ) {
                    ydata?.push({
                    name: s?.name,
                    data: data2,
                    });
                }
                });
            });
            return ydata;
        })()}
        labels={(() => {
            if (week !== "1") {
            let a = (
                week?.toString() +
                "-" +
                year?.getFullYear()
            )?.toString();
            let b = (
                (parseInt(week) - 1)?.toString() +
                "-" +
                year?.getFullYear()
            )?.toString();
            let c = (
                week?.toString() +
                "-" +
                (year?.getFullYear() - 1)
            )?.toString();
            return [a, b, c];
            } else if (week === "1") {
            let a = (
                week?.toString() +
                "-" +
                year?.getFullYear()
            )?.toString();
            let b = (
                week?.toString() +
                "-" +
                (year?.getFullYear() - 1)
            )?.toString();
            let c = (
                53?.toString() +
                "-" +
                (year?.getFullYear() - 1)
            )?.toString();
            return [a, b, c];
            } else {
            return [];
            }
        })()}
        colors={[
            colors[0],
            colors[1],
            colors[2],
            colors[3],
            colors[4],
            colors[5],
            colors[6],
            colors[7],
        ]}
        title={(() => {
            const txt = `Sales Conversion - Weekly`;
            return txt;
        })()}
        subtitle={(() => {
            let subtxt = getSubtitle ? getSubtitle : ""
            subtxt += ` \u3010Year\u3011: ${year?.getFullYear()}`;
            subtxt += ` \u3010Week\u3011: ${week}`;
            return subtxt;
        })()}
        exportfilename={"SalesConversion-Weekly"}
        />
    </>
    );

};