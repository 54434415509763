import { ApexOptions } from "apexcharts";
import Chart from "react-apexcharts";

interface dataSet {
  name: string;
  data: number[];
}

interface Iprops {
  datasets: dataSet[];
  labels: string[];
  title: string;
  colors: string[];
  subtitle?: string | "";
  exportfilename: string;
}

export const generateData = (
  xLabel: string,
  yValue: number,
  yrange: { min: number; max: number },
  count: number
) => {
  var i = 0;
  var series = [];
  while (i < count) {
    var x = xLabel;
    var y = yValue;
    var z =
      Math.floor(Math.random() * (yrange.max - yrange.min + 1)) + yrange.min;

    // var x = Math.floor(Math.random() * (750 - 1 + 1)) + 1;
    // var y = Math.floor(Math.random() * (yrange.max - yrange.min + 1)) + yrange.min;
    // var z = Math.floor(Math.random() * (75 - 15 + 1)) + 15;

    series.push([x, y, z]);
    // baseval += 86400000;
    i++;
  }
  return series;
};

const BubbleChart = (props: Iprops) => {
  // default options
  const { colors, datasets, labels, title, exportfilename, subtitle } = props;

  const apexBubbleChartOpts: ApexOptions = {
    chart: {
      height: 380,
      type: "bubble",
      toolbar: {
        show: true,
        export: {
          csv: {
            filename: exportfilename,
          },
          png: {
            filename: exportfilename,
          },
          svg: {
            filename: exportfilename,
          },
        },
      },
    },

    subtitle: {
      text: subtitle,
      align: "left",
      offsetY: 22,
      margin: 14,
    },
    title: {
      text: title,
      align: "center",
    },
    plotOptions: {
      // bar: {
      //     horizontal: false,
      //     borderRadius: 4,
      //     columnWidth: '30%',
      //     dataLabels: {
      //         position: 'top',
      //     },
      // },
      bubble: {
        zScaling: true,
        minBubbleRadius: 0,
        maxBubbleRadius: 60,
      },
    },
    dataLabels: {
      enabled: true,
    },
    fill: {
      opacity: 0.8,
    },
    colors: colors,
    // stroke: {
    //     show: true,
    //     width: 1,
    //     colors: ['#fff'],
    //     curve: 'smooth'
    // },
    xaxis: {
      tickAmount: 12,
      type: "category",
    },
    // xaxis: {
    //     type: 'category',
    //     // categories: labels,
    // },
    legend: {
      showForSingleSeries: true,
      offsetY: -10,
    },
    // states: {
    //     hover: {
    //         filter: {
    //             type: 'none',
    //         },
    //     },
    // },
    grid: {
      borderColor: "#f1f3fa",
    },
  };

  // const apexBubbleChartData = [
  //     {
  //         name: "Fresh & Healthy",
  //         data: [
  //             {
  //                 x: "",
  //                 y: 0,
  //                 z: 0
  //             },
  //             {
  //                 x: "Fresh & Healthy",
  //                 y: 40000,
  //                 z: 40
  //             },
  //             {
  //                 x: "Woodland",
  //                 y: 0,
  //                 z: 0
  //             },
  //             {
  //                 x: "O2Spa",
  //                 y: 0,
  //                 z: 0
  //             },
  //             {
  //                 x: " ",
  //                 y: 0,
  //                 z: 0
  //             },

  //         ]
  //     },
  //     {
  //         name: "Woodland",
  //         data: [
  //             {
  //                 x: "",
  //                 y: 0,
  //                 z: 0
  //             },
  //             {
  //                 x: "Fresh & Healthy",
  //                 y: 0,
  //                 z: 0
  //             },
  //             {
  //                 x: "Woodland",
  //                 y: 55000,
  //                 z: 55
  //             },
  //             {
  //                 x: "O2Spa",
  //                 y: 0,
  //                 z: 0
  //             },
  //             {
  //                 x: " ",
  //                 y: 0,
  //                 z: 0
  //             },
  //         ]
  //     },
  //     {
  //         name: "O2Spa",
  //         data: [
  //             {
  //                 x: "",
  //                 y: 0,
  //                 z: 0
  //             },
  //             {
  //                 x: "Fresh & Healthy",
  //                 y: 0,
  //                 z: 0
  //             },
  //             {
  //                 x: "Woodland",
  //                 y: 0,
  //                 z: 0
  //             },
  //             {
  //                 x: "O2Spa",
  //                 y: 30000,
  //                 z: 30
  //             },
  //             {
  //                 x: " ",
  //                 y: 0,
  //                 z: 0
  //             },
  //         ]
  //     }
  // ]

  return (
    <>
      {/*<h4 className="header-title mb-3">{title}</h4>*/}
      <Chart
        options={apexBubbleChartOpts}
        series={datasets}
        type="bubble"
        className="apex-charts"
        height={400}
      />
    </>
  );
};

export default BubbleChart;
