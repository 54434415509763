import { ApexOptions } from "apexcharts";
import Chart from "react-apexcharts";

interface dataSet {
  name: string;
  data: number[];
}

interface Iprops {
  datasets: dataSet[];
  labels: string[];
  title: string;
  colors: string[];
  subtitle?: string | "";
  exportfilename: string;
}
// stacked bar chart
const StackedBarChart = (props: Iprops) => {
  // default options
  const { colors, datasets, labels, title, exportfilename, subtitle } = props;

  const apexBarChartStackedOpts: ApexOptions = {
    chart: {
      height: 380,
      type: "bar",
      stacked: true,
      toolbar: {
        show: true,
        export: {
          csv: {
            filename: exportfilename,
          },
          png: {
            filename: exportfilename,
          },
          svg: {
            filename: exportfilename,
          },
        },
      },
    },
    title: {
      text: title,
      align: "center",
    },
    subtitle: {
      text: subtitle,
      align: "left",
      offsetY: 22,
      margin: 14,
    },
    plotOptions: {
      bar: {
        horizontal: false,
        borderRadius: 4,
        columnWidth: "80%",
      },
    },
    stroke: {
      show: false,
    },
    xaxis: {
      categories: labels,
      labels: {
        formatter: (val: string) => {
          return val + "";
        },
      },
    },
    yaxis: {
      title: {
        text: undefined,
      },
    },
    colors: colors,
    tooltip: {
      y: {
        formatter: (val: number) => {
          return val + "";
        },
      },
    },
    fill: {
      opacity: 1,
    },
    states: {
      hover: {
        filter: {
          type: "none",
        },
      },
    },
    legend: {
      position: "bottom",
      horizontalAlign: "center",
      showForSingleSeries: true,
    },
    grid: {
      borderColor: "#f1f3fa",
    },
    dataLabels: {
      enabled: true,
    },
  };

  // chart data
  // const apexBarChartStackedData = [
  //     {
  //         name: 'Marine Sprite',
  //         data: [44, 55, 41, 37, 22, 43, 21],
  //     },
  //     {
  //         name: 'Striking Calf',
  //         data: [53, 32, 33, 52, 13, 43, 32],
  //     },
  //     {
  //         name: 'Tank Picture',
  //         data: [12, 17, 11, 9, 15, 11, 20],
  //     },
  //     {
  //         name: 'Bucket Slope',
  //         data: [9, 7, 5, 8, 6, 9, 4],
  //     },
  //     {
  //         name: 'Reborn Kid',
  //         data: [25, 12, 19, 32, 25, 24, 10],
  //     },
  // ];

  return (
    <>
      {/*<h4 className="header-title mb-3">{title}</h4>*/}
      <Chart
        options={apexBarChartStackedOpts}
        series={datasets}
        type="bar"
        className="apex-charts"
        height={400}
      />
    </>
  );
};

export default StackedBarChart;
