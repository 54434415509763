import React, { useEffect, useMemo, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { PortfolioDashboardList, PortfolioDashboardType } from "../../config";
import api, { APIResponse, getAxiosRequestConfig } from "../../config/api";
// import { YearOptions } from "../../config/functions";
import { PortfolioType } from "../../redux/actions/portfolioActions";
import { AuthUserType } from "../../redux/actions/authUserActions";
import { StoreState } from "../../redux/store";
import { KpiViewList } from "../commonWidget";
import Widget1 from "../custom/components/Widget1";
// import BarChart from "../custom/components/charts/apex/BarChart";
// import LineChart from "../custom/components/charts/apex/LineChart";
import BarChart1 from "../custom/components/charts/apex/Barchart1";
import { COLORS } from "../custom/constants";
import { BrandType } from "../../redux/actions/brandActions";
import {
  RegionType,
  StateType,
  CityType,
} from "../../redux/actions/commonActions";
import { CountryType } from "../../redux/actions/countryActions";
import { TradeCategoryType } from "../../redux/actions/tradeCategoryActions";
import { TradeSubCategoryType } from "../../redux/actions/tradeSubCategoryActions";
import { OptionsTypes, addToSelectedOption } from "../../redux/actions/selectedOptionsActions";
import { YearOptions } from "../../config/functions";
import Modal from "../Modal";
import RetailPerformanceTable from "../custom/components/RetailPerformanceTable";

interface RetailRecordsForYear {
  sales: string;
  transyear: number;
  centerid?: number;
  brandid: number;
  tenantid?: number | null;
}
interface YearlyRecord {
  portfolio: number;
  record: RetailRecordsForYear[];
}

interface Props {
  portfolioId: number;
  siteId: string[];
  tradeCategory?: number[];
  tradeSubCategory?: number[];
  country?: number[];
  region?: number[];
  state?: number[];
  city?: number[];
  expandState: React.Dispatch<React.SetStateAction<boolean>>;
  widgetLoader1?:React.Dispatch<React.SetStateAction<boolean>>;
  apiEnabler1?:boolean
}

interface ChangedRefType {
  tradeCat?: string;
  tradeSubCat?:string
  country?:string
  region?:string
  state?:string
  city?:string
}

const BrandDashboardTopBrandsalesWidget: React.FC<Props> = (props: Props) => {
  const dispatch = useDispatch();
  const changedRef = useRef<ChangedRefType>({});
  const authUser = useSelector<StoreState, AuthUserType | null>(
    (state) => state?.authUser
  );
  const authToken = authUser?.["auth-token"]!;
  const config = getAxiosRequestConfig(authToken);

  const brandList = useSelector<StoreState, Array<BrandType>>(
    (state) => state?.brand
  );
  const portfolioOption = useSelector<StoreState, PortfolioType[]>(
    (state) => state?.portfolio
  );

  const [yearlyData, setYearlyData] = useState<YearlyRecord[]>([]);

  const [selection, setSelection] = useState<PortfolioDashboardType>(
    PortfolioDashboardList?.MOM
  );
  const [view, setView] = useState<string>(KpiViewList?.CHART);
  const [valueIn, setValueIn] = useState<string>("");

  const currentYear = new Date()?.getFullYear();
  const [year, setYear] = useState<string>(currentYear?.toString());
  const [showTableModal, setShowTableModal] = useState<boolean>(false);

  const selectedOption = useSelector<StoreState, OptionsTypes>(
    (state) => state?.selectedOption
  );

  const [loader, setLoader] = useState<boolean>(true);
  const [apiCall,setApiCall]=  useState<boolean>(true)

  const prop_index = portfolioOption?.findIndex(
    (p) =>
      p?.id !== undefined && p?.id?.toString() === props?.portfolioId?.toString()
  );
  const propPfname = portfolioOption[prop_index]?.name;

  const countryList = useSelector<StoreState, CountryType[]>(
    (state) => state?.country
  );

  const regionList = useSelector<StoreState, RegionType[]>(
    (state) => state?.region
  );

  const stateList = useSelector<StoreState, StateType[]>(
    (state) => state?.state
  );

  const cityList = useSelector<StoreState, CityType[]>((state) => state?.city);

  const tradeCategoryList = useSelector<StoreState, TradeCategoryType[]>(
    (state) => state?.tradeCategory
  );
  const subCategoryList = useSelector<StoreState, TradeSubCategoryType[]>(
    (state) => state?.tradeSubCategory
  );

  const countryIndex = countryList?.findIndex(
    (a) => a?.id?.toString() === props?.country?.toString()
  );
  const propConame = countryList[countryIndex]?.countryname;

  const regionIndex = regionList?.findIndex(
    (a) => a?.id?.toString() === props?.region?.toString()
  );
  const propRename = regionList[regionIndex]?.regionname;

  const stateIndex = stateList?.findIndex(
    (a) => a?.id?.toString() === props?.state?.toString()
  );
  const propStname = stateList[stateIndex]?.statename;

  const cityIndex = cityList?.findIndex(
    (a) => a?.id?.toString() === props?.city?.toString()
  );
  const propCiname = cityList[cityIndex]?.cityname;

  const catIndex = tradeCategoryList?.findIndex(
    (a) => a?.id?.toString() === props?.tradeCategory?.toString()
  );
  const propCatname = tradeCategoryList[catIndex]?.name;

  const subcatIndex = subCategoryList?.findIndex(
    (a) => a?.id?.toString() === props?.tradeSubCategory?.toString()
  );
  const propsubCatname = subCategoryList[subcatIndex]?.name;

  const yearList = YearOptions();
  const showExpandHandler = () => {
    props?.expandState((prevState) => !prevState)
  };
  const momHandler = () => {
    setSelection(PortfolioDashboardList?.MOM);
  };
  const yoyHandler = () => {
    setSelection(PortfolioDashboardList?.YOY);
  };
  const chartViewHandler = () => {
    setView(KpiViewList?.CHART);
  };
  const tableViewHandler = () => {
    setView(KpiViewList?.TABLE);
  };
  const showGridHandler = () => {
    // props?.expandState(true)
    setShowTableModal(true)
    // setView(KpiViewList?.TABLE)
  };

  const YearlyFilteredData = useMemo<YearlyRecord[]>(() => {
    const data: YearlyRecord[] = [];
    yearlyData?.forEach((d) => {
      data?.push({
        portfolio: d?.portfolio,
        record: d?.record?.filter((r) => year?.toString() === r?.transyear?.toString()) ?? [],
      });
    });
    return data;
  }, [yearlyData, year]);


  const getTableData = () => {
    var tableData: string[][] = [
      [
        'Brand',
        "Year",
        "Sales",
      ],
    ];

    yearList?.map((a)=>{
      yearlyData?.map((b)=>{
        b?.record?.filter((c)=>c?.transyear?.toString()===a?.toString())
        ?.forEach((d)=>{
          const brand = brandList?.findIndex((e)=>e?.id?.toString()===d?.brandid?.toString())
          let sum = []
          sum?.push(brandList[brand]?.name?.toString()??'')
          sum?.push(a?.toString())
          sum?.push(d?.sales?.toString()??'')
          return tableData?.push(sum)
        })
      })
    })

    return tableData
  };

  useEffect(() => {
    let pid = props?.portfolioId ? [props?.portfolioId] : [];
    var obj = portfolioOption?.find((p) => p?.id === Number(pid));
    let valuecaption = obj?.valuecaption ? obj?.valuecaption : "in Millions";
    setValueIn(valuecaption);
  }, [props, portfolioOption]);

  useEffect(() => {
    var getData = {
      portfolio: props?.portfolioId ? [props?.portfolioId] : [],
      site: props?.siteId ? props?.siteId : [],
      tradeCategory: props?.tradeCategory,
      tradeSubCategory: props?.tradeSubCategory,
      country: props?.country,
      state: props?.state,
      region: props?.region,
      city: props?.city,
      authUserRole: authUser?.roleId ? authUser?.roleId : "",
    };
    if (
      getData?.portfolio?.length > 0 &&
      props?.portfolioId &&
      getData !== undefined && apiCall && props?.apiEnabler1===false
    ) {
      api
        .post<APIResponse<{ records: YearlyRecord[] }>>(
          `kpi/retail/topbrandssales/yearly`,
          getData,
          config
        )
        .then((response) => {
          if (response?.status === 200 && response?.data?.data) {
            const result = response?.data?.data?.records;
            setYearlyData(result);
            setLoader(false)
            setApiCall(false)
            // const data = {
            //   ...selectedOption,
            //   loader1:false
            // };
            // dispatch(addToSelectedOption(data));
          }
          else {
            setYearlyData([])
            // const data = {
            //   ...selectedOption,
            //   w1:false
            // };
            // dispatch(addToSelectedOption(data));
            setLoader(false)
            setApiCall(false)
            // const data = {
            //   ...selectedOption,
            //   loader1:false
            // };
            // dispatch(addToSelectedOption(data));
          }
        })
        .catch((error) => {});
    } 
  }, [
    props?.portfolioId,
    props?.siteId,
    apiCall,
    props?.apiEnabler1
  ]);

  const colors = [
    COLORS?.COLOR1,
    COLORS?.COLOR2,
    COLORS?.COLOR3,
    COLORS?.COLOR4,
    COLORS?.COLOR5,
    COLORS?.COLOR6,
    COLORS?.COLOR7,
    COLORS?.COLOR8,
  ];

  

  



useEffect(() => { 

    let {tradeCategory,tradeSubCategory,city,country,region,state} = props


  if(changedRef?.current?.tradeCat !== tradeCategory?.toString()||changedRef?.current?.tradeSubCat!==tradeSubCategory?.toString()
      || changedRef?.current?.city!==city?.toString()
  || changedRef?.current?.country!==country?.toString()
  || changedRef?.current?.region!==region?.toString()
  || changedRef?.current?.state!==state?.toString()
  ) {
    changedRef.current = {
    tradeCat:tradeCategory?.toString(),
    tradeSubCat:tradeSubCategory?.toString(),
    city:city?.toString(),
    country:country?.toString(),
    region:region?.toString(),
    state:state?.toString()
    };
    setApiCall(true)
  }
}, [ props?.tradeCategory,props?.tradeSubCategory,props?.city,props?.country,props?.region,props?.state]);


// const showExpandHandler = () => {
//   props?.expandState((prevState) => !prevState)
// };

useEffect(()=>{
  if(loader===false){
    props?.widgetLoader1!((prevState)=>!prevState)
  }
},[loader])



  return (
    <Widget1
      title={"Top 5 Brands Sales"}
      momHandler={momHandler}
      yoyHandler={yoyHandler}
      tableViewHandler={tableViewHandler}
      chartViewHandler={chartViewHandler}
      selection={selection}
      view={view}
      viewBtns={false}
      showYearFilter={true}
      setYear={setYear}
      year={year}
      valueInString={valueIn}
      showExpandModal={true}
      showExpand={true}
      showExpandHandler={showExpandHandler}
      showGridHandler={showGridHandler}
      showGrid={true}
    >
      {/* <BarChart
            datasets={(() => {
                let ydataRaw: { name: string; data: number[] }[] = [
                    {
                        name: 'Sales',
                        data: [ 150?.67, 122?.2, 96?.51, 72?.21, 69?.08]
                    }
                ]
                return ydataRaw
            })()}
            labels={brandList?.sort()}
            colors={[colors[0], colors[1], colors[2], colors[3], colors[4], colors[5], colors[6], colors[7]]}
            title={''}
        /> */}

      {view === KpiViewList?.CHART && (
        <BarChart1
          datasets={(() => {
            let ydata: { name: string; data: number[] }[] = [
              {
                name: "Sales",
                data: [],
              },
            ];
            YearlyFilteredData?.forEach((y, i) => {
              // const index = portfolioOption?.findIndex(
              //   (p) =>
              //     p?.id !== undefined &&
              //     p?.id?.toString() === y?.portfolio?.toString()
              // );
              // const name = portfolioOption[index]?.name;

              brandList?.forEach((b) => {
                y?.record
                  ?.filter((r) => r?.brandid?.toString() === b?.id?.toString())
                  ?.forEach((r) => {
                    ydata[0]?.data?.push(r?.sales ? parseFloat(r?.sales) : 0);
                  });
              });
            });
            return ydata;
          })()}
          labels={(() => {
            const labels: string[] = [];
            YearlyFilteredData?.forEach((y, i) => {
              brandList?.forEach((b) => {
                let data = y?.record
                  ?.filter((r) => r?.brandid?.toString() === b?.id?.toString())
                  ?.map((r) => {
                    return r?.sales ? parseFloat(r?.sales) : 0;
                  });
                if (data?.length > 0) {
                  labels?.push(b?.name);
                }
              });
            });
            return labels;
          })()}
          colors={[
            colors[0],
            colors[1],
            colors[2],
            colors[3],
            colors[4],
            colors[5],
            colors[6],
            colors[7],
          ]}
          title={(() => {
            const txt = `Top 5 Brand Sales`;
            return txt;
          })()}
          subtitle={(() => {
            let subtxt: string[] = [];
            let subtxt1 = `\u3010Portfolio\u3011: ${propPfname} \u3010Year\u3011: ${year}`;
            let subtxt2 = ``;
            if (
              props?.tradeCategory !== undefined &&
              props?.tradeCategory?.length > 0
            ) {
              subtxt1 += `\u3010TradeCategory\u3011: ${propCatname}`;
            }
            if (
              props?.tradeSubCategory !== undefined &&
              props?.tradeSubCategory?.length > 0
            ) {
              subtxt1 += `\u3010TradeSubCategory\u3011: ${propsubCatname}`;
            }
            if (props?.country !== undefined && props?.country?.length > 0) {
              subtxt2 += `\u3010Country\u3011: ${propConame}`;
            }
            if (props?.region !== undefined && props?.region?.length > 0) {
              subtxt2 += `\u3010Region\u3011: ${propRename}`;
            }
            if (props?.state !== undefined && props?.state?.length > 0) {
              subtxt2 += `\u3010State\u3011: ${propStname}`;
            }
            if (props?.city !== undefined && props?.city?.length > 0) {
              subtxt2 += `\u3010City\u3011: ${propCiname}`;
            }
            subtxt?.push(subtxt1);
            subtxt?.push(subtxt2);
            return subtxt;
          })()}
          exportfilename={"Top5BrandsSales"}
        />
      )}

{showTableModal && (
              <Modal
              size="xl"
              visible = {showTableModal}
              onClose={() => setShowTableModal(false)}
              centered classname="grid-container"
              >
              <RetailPerformanceTable
                    data={getTableData()}
                    title={`Top 5 Brand Sales - ${propPfname}`}
                    fileName={"pf_monthly_sales"}
                    downloadCSV={false}
                    downloadPDF={false}
                  />
              </Modal>
        )}
    </Widget1>
  );
};
export default BrandDashboardTopBrandsalesWidget;
