import React, { useEffect, useState } from "react";
import BrandDashboardSalesPerformance from "./BrandDashboardSalesPerformance";
import BrandDashboardTop10OutletSalesWidget from "./BrandDashboardTop10OutletSalesWidget";
import BrandDashboardBottom10OutletSalesWidget from "./BrandDashboardBottom10OutletSalesWidget";
// import BrandDashboardSalesOfDayMonthWiseWidget from "./BrandDashboardSalesOfDayMonthWiseWidget";
import BrandDashboardSalesOfDayYearWiseWidget from "./BrandDashboardSalesOfDayYearWiseWidget";
import BrandDashboardSalesByOutletTypeWidget from "./BrandDashboardSalesByOutletTypeWidget";
import BrandDashboardSalesByOutletLocWidget from "./BrandDashboardSalesByOutletLocWidget";
import BrandDashboardFootfallWidget from "./BrandDashboardFootfallWidget";

interface props {
  portfolioId: number;
  siteId: string[];
  brandId: number;
  country?: number[];
  region?: number[];
  state?: number[];
  city?: number[];
  widgetLoader1?:React.Dispatch<React.SetStateAction<boolean>>;
  widgetLoader2?:React.Dispatch<React.SetStateAction<boolean>>;
  apiEnabler1?:boolean
  apiEnabler2?:boolean
}
const BrandDashboardIndex = (props: props) => {
  const [isexpand1, setIsexpand1] = useState<boolean>(false);
  const [isexpand2, setIsexpand2] = useState<boolean>(false);
  const [isexpand3, setIsexpand3] = useState<boolean>(false);
  const [isexpand4, setIsexpand4] = useState<boolean>(false);
  const [isexpand5, setIsexpand5] = useState<boolean>(false);
  const [isexpand6, setIsexpand6] = useState<boolean>(false);
  const [isexpand7, setIsexpand7] = useState<boolean>(false);
  const [loader1,setLoader1] = useState<boolean>(true)
  const [loader2,setLoader2] = useState<boolean>(true)
  const [commonApiEnabler,setCommonApiEnabler] = useState<boolean>(false)

  useEffect(()=>{
    if(loader1===false){
      props.widgetLoader1!((prevState)=>!prevState)
    }
  },[loader1])
  
  useEffect(()=>{
    if(loader2===false){
      props.widgetLoader2!((prevState)=>!prevState)
    }
  },[loader2])


  useEffect(()=>{
    if(loader1===false&&loader2===false){
      setCommonApiEnabler(true)
    }
  },[loader1,loader2])

  return (
    <>
      <div className="row">
        <div className={isexpand1 ? "col-xl-12 col-lg-12 col-md-12 me-0":"col-xl-6 col-lg-12 col-md-12 me-0"}>
          <BrandDashboardSalesPerformance
            portfolioId={props.portfolioId}
            siteId={props.siteId}
            brandId={props.brandId}
            country={props.country}
            region={props.region}
            state={props.state}
            city={props.city}
            expandState={setIsexpand1}
            widgetLoader1={setLoader1}
            apiEnabler1 = {props.apiEnabler1}
            commonApiEnabler = {commonApiEnabler}
          />
        </div>
        <div className={isexpand2 ? "col-xl-12 col-lg-12 col-md-12 me-0":"col-xl-6 col-lg-12 col-md-12 me-0"}>
          <BrandDashboardTop10OutletSalesWidget
            portfolioId={props.portfolioId}
            siteId={props.siteId}
            brandId={props.brandId}
            country={props.country}
            region={props.region}
            state={props.state}
            city={props.city}
            expandState={setIsexpand2}
            widgetLoader2={setLoader2}
            apiEnabler2 = {props.apiEnabler2}
          />
        </div>
        <div className={isexpand3 ? "col-xl-12 col-lg-12 col-md-12 me-0":"col-xl-6 col-lg-12 col-md-12 me-0"}>
          <BrandDashboardBottom10OutletSalesWidget
            portfolioId={props.portfolioId}
            siteId={props.siteId}
            brandId={props.brandId}
            country={props.country}
            region={props.region}
            state={props.state}
            city={props.city}
            expandState={setIsexpand3}
            commonApiEnabler = {commonApiEnabler}
          />
        </div>
        {/* <div className="col-xl-6 col-lg-12 col-md-12 me-0">
                <BrandDashboardSalesOfDayMonthWiseWidget
                    portfolioId={props.portfolioId}
                    country={props.country}
                    region={props.region}
                    state={props.state}
                    city={props.city}
                />
            </div> */}
        <div className={isexpand4 ? "col-xl-12 col-lg-12 col-md-12 me-0":"col-xl-6 col-lg-12 col-md-12 me-0"}>
          <BrandDashboardSalesOfDayYearWiseWidget
            portfolioId={props.portfolioId}
            siteId={props.siteId}
            brandId={props.brandId}
            country={props.country}
            region={props.region}
            state={props.state}
            city={props.city}
            expandState={setIsexpand4}
            commonApiEnabler = {commonApiEnabler}
          />
        </div>
        <div className={isexpand5 ? "col-xl-12 col-lg-12 col-md-12 me-0":"col-xl-6 col-lg-12 col-md-12 me-0"}>
          <BrandDashboardSalesByOutletTypeWidget
            portfolioId={props.portfolioId}
            siteId={props.siteId}
            brandId={props.brandId}
            country={props.country}
            region={props.region}
            state={props.state}
            city={props.city}
            expandState={setIsexpand5}
            commonApiEnabler = {commonApiEnabler}
          />
        </div>
        <div className={isexpand6 ? "col-xl-12 col-lg-12 col-md-12 me-0":"col-xl-6 col-lg-12 col-md-12 me-0"}>
          <BrandDashboardSalesByOutletLocWidget
            portfolioId={props.portfolioId}
            siteId={props.siteId}
            brandId={props.brandId}
            country={props.country}
            region={props.region}
            state={props.state}
            city={props.city}
            expandState={setIsexpand6}
            commonApiEnabler = {commonApiEnabler}
          />
        </div>
        <div className={isexpand7 ? "col-xl-12 col-lg-12 col-md-12 me-0":"col-xl-6 col-lg-12 col-md-12 me-0"}>
          <BrandDashboardFootfallWidget
            portfolioId={props.portfolioId}
            siteId={props.siteId}
            brandId={props.brandId}
            country={props.country}
            region={props.region}
            state={props.state}
            city={props.city}
            expandState={setIsexpand7}
            commonApiEnabler = {commonApiEnabler}
          />
        </div>
      </div>
    </>
  );
};
export default BrandDashboardIndex;
