import React, { useEffect, useMemo, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { PortfolioDashboardList, PortfolioDashboardType } from "../../config";
import api, { APIResponse, getAxiosRequestConfig } from "../../config/api";
import { AuthUserType } from "../../redux/actions/authUserActions";
import { StoreState } from "../../redux/store";
import { KpiViewList, getOnlyUnique } from "../commonWidget";
import Widget1 from "../custom/components/Widget1";
// import BarChart from "../custom/components/charts/apex/BarChart";
// import LineChart from "../custom/components/charts/apex/LineChart";
import { COLORS } from "../custom/constants";
import { PortfolioType } from "../../redux/actions/portfolioActions";
import { TradeCategoryType } from "../../redux/actions/tradeCategoryActions";
import { YearOptions } from "../../config/functions";
// import MultipleYAxisChart from "../custom/components/charts/apex/MultipleYAxisNew";
import {
  RegionType,
  StateType,
  CityType,
} from "../../redux/actions/commonActions";
import { CountryType } from "../../redux/actions/countryActions";
import { TradeSubCategoryType } from "../../redux/actions/tradeSubCategoryActions";
import MultipleYAxisChart1 from "../custom/components/charts/apex/MultipleYAxis1";
import RetailPerformanceTable from "../custom/components/RetailPerformanceTable";
import Modal from "../Modal";

interface RecordsForMonth {
  sales: string;
  transyear: number;
  tradecatid: number | null;
  centerid: number | null;
  transmonth: number;
  momgrowth: number | null;
  mtd: string;
  mtdpercentchange: number | null;
  mompercentchange: number | null;
  averagesale: string;
  averagerate: number;
  averageratepercent: string;
}
interface RecordsForYear {
  sales: string;
  transyear: number;
  tradecatid: number | null;
  centerid: number | null;
  yoygrowth: number | null;
  ytd: number;
  ytdpercentchange: number | null;
  yoypercentchange: number | null;
  averagesale: number;
  averagerate: number;
  averageratepercent: number;
}
interface MonthlyRecord {
  portfolio: number;
  record: RecordsForMonth[];
}
interface YearlyRecord {
  portfolio: number;
  record: RecordsForYear[];
}
interface Props {
  portfolioId: number;
  siteId: string[];
  tradeCategory?: number[];
  tradeSubCategory?: number[];
  country?: number[];
  region?: number[];
  state?: number[];
  city?: number[];
  expandState: React.Dispatch<React.SetStateAction<boolean>>;
  commonApiEnabler?:boolean
}


interface ChangedRefType {
  tradeCat?: string;
  tradeSubCat?:string
  country?:string
  region?:string
  state?:string
  city?:string
}

// const TradeCategoryList = ["Retail", "Food & Beverage"];
const BrandDashboardCatSalesTDCompWidget: React.FC<Props> = (props: Props) => {
  const authUser = useSelector<StoreState, AuthUserType | null>(
    (state) => state?.authUser
  );
  const authToken = authUser?.["auth-token"]!;
  const config = getAxiosRequestConfig(authToken);
  const changedRef = useRef<ChangedRefType>({});

  const monthsArray = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];

  const portfolioOption = useSelector<StoreState, PortfolioType[]>(
    (state) => state?.portfolio
  );
  const tradecategoryOption = useSelector<StoreState, TradeCategoryType[]>(
    (state) => state?.tradeCategory
  );

  const prop_index = portfolioOption?.findIndex(
    (p) =>
      p?.id !== undefined && p?.id?.toString() === props?.portfolioId?.toString()
  );
  const propPfname = portfolioOption[prop_index]?.name;

  const countryList = useSelector<StoreState, CountryType[]>(
    (state) => state?.country
  );

  const regionList = useSelector<StoreState, RegionType[]>(
    (state) => state?.region
  );

  const stateList = useSelector<StoreState, StateType[]>(
    (state) => state?.state
  );

  const cityList = useSelector<StoreState, CityType[]>((state) => state?.city);

  const tradeCategoryList = useSelector<StoreState, TradeCategoryType[]>(
    (state) => state?.tradeCategory
  );
  const subCategoryList = useSelector<StoreState, TradeSubCategoryType[]>(
    (state) => state?.tradeSubCategory
  );

  const countryIndex = countryList?.findIndex(
    (a) => a?.id?.toString() === props?.country?.toString()
  );
  const propConame = countryList[countryIndex]?.countryname;

  const regionIndex = regionList?.findIndex(
    (a) => a?.id?.toString() === props?.region?.toString()
  );
  const propRename = regionList[regionIndex]?.regionname;

  const stateIndex = stateList?.findIndex(
    (a) => a?.id?.toString() === props?.state?.toString()
  );
  const propStname = stateList[stateIndex]?.statename;

  const cityIndex = cityList?.findIndex(
    (a) => a?.id?.toString() === props?.city?.toString()
  );
  const propCiname = cityList[cityIndex]?.cityname;

  const catIndex = tradeCategoryList?.findIndex(
    (a) => a?.id?.toString() === props?.tradeCategory?.toString()
  );
  const propCatname = tradeCategoryList[catIndex]?.name;

  const subcatIndex = subCategoryList?.findIndex(
    (a) => a?.id?.toString() === props?.tradeSubCategory?.toString()
  );
  const propsubCatname = subCategoryList[subcatIndex]?.name;

  const [yearlyData, setYearlyData] = useState<YearlyRecord[]>([]);
  const [monthlyData, setMonthlyData] = useState<MonthlyRecord[]>([]);
  const [showTableModal, setShowTableModal] = useState<boolean>(false);
  const [apiCall1,setApiCall1]=  useState<boolean>(true)
  const [apiCall2,setApiCall2]=  useState<boolean>(true)
  const [selection, setSelection] = useState<PortfolioDashboardType>(
    PortfolioDashboardList?.YTD
  );
  const [view, setView] = useState<string>(KpiViewList?.CHART);
  const [valueIn, setValueIn] = useState<string>("");

  const yearList = YearOptions();

  /*const momHandler = () => {
        setSelection(PortfolioDashboardList?.MOM);
    }
    const yoyHandler = () => {
        setSelection(PortfolioDashboardList?.YOY)
    }*/
    const showExpandHandler = () => {
      props?.expandState((prevState) => !prevState)
    };
  const mtdHandler = () => {
    setSelection(PortfolioDashboardList?.MTD);
  };
  const ytdHandler = () => {
    setSelection(PortfolioDashboardList?.YTD);
  };
  const chartViewHandler = () => {
    setView(KpiViewList?.CHART);
  };
  const tableViewHandler = () => {
    setView(KpiViewList?.TABLE);
  };
  const showGridHandler = () => {
    // props?.expandState(true)
    setShowTableModal(true)
    // setView(KpiViewList?.TABLE)
  };
  const YearlyFilteredData = useMemo<YearlyRecord[]>(() => {
    const data: YearlyRecord[] = [];
    yearlyData?.forEach((d) => {
      data?.push({
        portfolio: d?.portfolio,
        record: yearList
          ?.sort()
          ?.map((yr) => {
            return d?.record?.filter((r) => {
              return yr === r?.transyear;
            });
          })
          ?.flat(1),
      });
    });
    return data;
  }, [yearlyData, yearList]);

  const MonthlyFilteredData = useMemo<MonthlyRecord[]>(() => {
    const data: MonthlyRecord[] = [];
    monthlyData?.forEach((d) => {
      data?.push({
        portfolio: d?.portfolio,
        record: yearList
          ?.map((yr) => {
            return d?.record?.filter((r) => {
              return yr === r?.transyear;
            });
          })
          ?.flat(1),
      });
    });
    return data;
  }, [monthlyData, yearList]);

  useEffect(() => {
    var getData = {
      portfolio: props?.portfolioId ? [props?.portfolioId] : [],
      site: props?.siteId ? props?.siteId : [],
      tradeCategory: props?.tradeCategory,
      tradeSubCategory: props?.tradeSubCategory,
      country: props?.country,
      state: props?.state,
      region: props?.region,
      city: props?.city,
      authUserRole: authUser?.roleId ? authUser?.roleId : "",
    };
    if (
      getData?.portfolio?.length > 0 &&
      props?.portfolioId &&
      getData !== undefined && apiCall1 && props?.commonApiEnabler
    ) {
      api
        .post<APIResponse<{ records: YearlyRecord[] }>>(
          `kpi/retail/categorycomparison/yearly`,
          getData,
          config
        )
        .then((response) => {
          if (response?.status === 200 && response?.data?.data) {
            const result = response?.data?.data?.records;
            setYearlyData(result);
            setApiCall1(false)
          }
          else {
            setYearlyData([]);
            setApiCall1(false)
          }
        })
        .catch((error) => {});
    } 
  }, [
    props?.portfolioId,
    props?.siteId,
    apiCall1,
    props?.commonApiEnabler
  ]);

  useEffect(() => {
    var getData = {
      portfolio: props?.portfolioId ? [props?.portfolioId] : [],
      site: props?.siteId ? props?.siteId : [],
      tradeCategory: props?.tradeCategory,
      tradeSubCategory: props?.tradeSubCategory,
      country: props?.country,
      state: props?.state,
      region: props?.region,
      city: props?.city,
      authUserRole: authUser?.roleId ? authUser?.roleId : "",
    };
    if (
      getData?.portfolio?.length > 0 &&
      props?.portfolioId &&
      getData !== undefined && apiCall2
    ) {
      api
        .post<APIResponse<{ records: MonthlyRecord[] }>>(
          `kpi/retail/categorycomparison/monthly`,
          getData,
          config
        )
        .then((response) => {
          if (response?.status === 200 && response?.data?.data) {
            const result = response?.data?.data?.records;
            setMonthlyData(result);
            setApiCall2(false)
          }
          else {
            setMonthlyData([])
            setApiCall2(false)
          };
        })
        .catch((error) => {});
    } 
  }, [
    props?.portfolioId,
    props?.siteId,
    apiCall2
  ]);

  useEffect(() => {
    let pid = props?.portfolioId ? [props?.portfolioId] : [];
    var obj = portfolioOption?.find((p) => p?.id === Number(pid));
    let valuecaption = obj?.valuecaption ? obj?.valuecaption : "in Millions";
    setValueIn(valuecaption);
  }, [props, portfolioOption]);

  const colors = [
    COLORS?.COLOR1,
    COLORS?.COLOR2,
    COLORS?.COLOR3,
    COLORS?.COLOR4,
    COLORS?.COLOR5,
    COLORS?.COLOR6,
    COLORS?.COLOR7,
    COLORS?.COLOR8,
  ];

  const getMonthlyMTDData = () => {
    var monthlyTableData: string[][] = [
      [
        'Trade Category',
        "Year-Month",
        "Sales",
        '% Change'
      ],
    ];

    yearList?.map((a)=>{
      tradecategoryOption?.map((f)=>{
    monthlyData?.forEach((y, i) => {
          y?.record?.filter((b)=>b?.transyear?.toString()===a?.toString()&&f?.id?.toString()===b?.tradecatid?.toString())
            ?.forEach((r) => {
              const tradeCat = tradecategoryOption?.findIndex((e)=>e?.id?.toString()===r?.tradecatid?.toString())
                let sum = []
                 sum?.push(tradecategoryOption[tradeCat]?.name?.toString()??'')
              const month = monthsArray[r?.transmonth - 1];
              sum?.push(r?.transyear?.toString() + "-" + month);
              sum?.push(
                r?.sales !== undefined && r?.sales !== null ? r?.sales : "-"
              );
              sum?.push(
                r?.mtdpercentchange !== undefined && r?.mtdpercentchange !== null
                  ? parseFloat(r?.mtdpercentchange?.toString()) > 0
                    ? +r?.mtdpercentchange?.toString() + "%"
                    : r?.mtdpercentchange?.toString() + "%"
                  : r?.mtdpercentchange=== null
                  ? "-"
                  : "-"
              );
                monthlyTableData?.push(sum);
        });
    });
  })
  })
    return monthlyTableData;
  };

  const getYearlyYTDData = () => {
    var monthlyTableData: string[][] = [
      [
        'Trade Category',
        "Year",
        "Sales",
        '% Change'
      ],
    ];

    yearList?.map((a)=>{
      tradecategoryOption?.map((f)=>{
    yearlyData?.forEach((y, i) => {
          y?.record?.filter((b)=>b?.transyear?.toString()===a?.toString()&&f?.id?.toString()===b?.tradecatid?.toString())
            ?.forEach((r) => {
              const tradeCat = tradecategoryOption?.findIndex((e)=>e?.id?.toString()===r?.tradecatid?.toString())
                let sum = []
                 sum?.push(tradecategoryOption[tradeCat]?.name?.toString()??'')
              sum?.push(r?.transyear?.toString());
              sum?.push(
                r?.sales !== undefined && r?.sales !== null ? r?.sales : "-"
              );
              sum?.push(
                r?.ytdpercentchange !== undefined && r?.ytdpercentchange !== null
                  ? parseFloat(r?.ytdpercentchange?.toString()) > 0
                    ? +r?.ytdpercentchange?.toString() + "%"
                    : r?.ytdpercentchange?.toString() + "%"
                  : r?.ytdpercentchange=== null
                  ? "-"
                  : "-"
              );
                monthlyTableData?.push(sum);
        });
    });
  })
  })
    return monthlyTableData;
  };

  useEffect(() => { 

    let {tradeCategory,tradeSubCategory,city,country,region,state} = props


  if(changedRef?.current?.tradeCat !== tradeCategory?.toString()||changedRef?.current?.tradeSubCat!==tradeSubCategory?.toString()
      || changedRef?.current?.city!==city?.toString()
  || changedRef?.current?.country!==country?.toString()
  || changedRef?.current?.region!==region?.toString()
  || changedRef?.current?.state!==state?.toString()
  ) {
    changedRef.current = {
    tradeCat:tradeCategory?.toString(),
    tradeSubCat:tradeSubCategory?.toString(),
    city:city?.toString(),
    country:country?.toString(),
    region:region?.toString(),
    state:state?.toString()
    };
    setApiCall1(true)
    setApiCall2(true)
  }
}, [ props?.tradeCategory,props?.tradeSubCategory,props?.city,props?.country,props?.region,props?.state]);


  return (
    <Widget1
      title={"Trade Category Performance MTD & YTD"}
      // momHandler={momHandler}
      // yoyHandler={yoyHandler}
      mtdHandler={mtdHandler}
      ytdHandler={ytdHandler}
      showMOM={false}
      showYOY={false}
      tableViewHandler={tableViewHandler}
      chartViewHandler={chartViewHandler}
      selection={selection}
      view={view}
      valueInString={valueIn}

      showExpandModal={true}
      showExpand={true}
      showExpandHandler={showExpandHandler}
      showGridHandler={showGridHandler}
      showGrid={true}
    >
      {/* {selection === PortfolioDashboardList?.MOM && view === KpiViewList?.CHART &&
        <MultipleYAxisChart
            datasets={(() => {
                let mdata: { name: string; type: string, data: number[] }[] = []
                MonthlyFilteredData?.forEach((y, i) => {
                    const index = portfolioOption?.findIndex(p => p?.id !== undefined && p?.id?.toString() === y?.portfolio?.toString())
                    const name = portfolioOption[index]?.name;
                    const selectedYears = yearList?.map(f => f)?.sort()

                    const catArray = y?.record?.map((y, j) => y?.tradecatid)?.filter(getOnlyUnique)
                    catArray?.forEach((cat) => {
                        const categoryIndex = tradecategoryOption?.findIndex(c => c?.id !== undefined && c?.id?.toString() === cat?.toString())
                        const categoryName = tradecategoryOption[categoryIndex]?.name
                        selectedYears?.forEach((s) => {
                            let data2: number[] = []
                            let changePercentdata: number[] = []
                            let d1 = y?.record?.filter(r => r?.tradecatid?.toString() === cat?.toString())?.filter((y) => y??.transyear?.toString() === s?.toString())
                            monthsArray?.map(f => f)?.sort()?.forEach((m, mi) => {
                                var mns = d1?.map(d => d?.transmonth)
                                if (mns?.includes(mi + 1)) {
                                    let obj = d1?.find(f => f?.transmonth === mi + 1)
                                    data2?.push(obj?.sales ? parseFloat(obj?.sales) : 0)
                                    changePercentdata?.push(obj?.mtdpercentchange ? obj?.mtdpercentchange : 0)
                                } else {
                                    data2?.push(0)
                                    changePercentdata?.push(0)
                                }
                            })
                            let isEmpty: string[] = []
                            let isPercentEmpty: string[] = []
                            data2?.forEach(d2 => {
                                if (d2 !== 0) isEmpty?.push('true')
                                else isEmpty?.push('false')
                            })
                            changePercentdata?.forEach(c2 => {
                                if (c2 !== 0) isPercentEmpty?.push('true')
                                else isPercentEmpty?.push('false')
                            })
                            if (data2?.length > 0 && isEmpty?.includes('true')) {
                                mdata?.push({
                                    name: categoryName + ' - ' + s,
                                    type: 'column',
                                    data: data2
                                })
                            }
                            if (changePercentdata?.length > 0 && isEmpty?.includes('true')) {
                                mdata?.push({
                                    name: categoryName + ' - ' + s + ' %Change',
                                    type: 'line',
                                    data: changePercentdata
                                })
                            }
                        })    
                    })
                })
                return mdata
            })()}
            labels={monthsArray}
            colors={[colors[0], colors[1], colors[2], colors[3], colors[4], colors[5], colors[6], colors[7]]}
            title={''}
        />} */}

      {selection === PortfolioDashboardList?.MTD &&
        view === KpiViewList?.CHART && (
          <MultipleYAxisChart1
            datasets={(() => {
              let mdata: {
                name: string;
                type: string;
                decimal: boolean;
                data: number[];
              }[] = [];
              MonthlyFilteredData?.forEach((y, i) => {
                // const index = portfolioOption?.findIndex(
                //   (p) =>
                //     p?.id !== undefined &&
                //     p?.id?.toString() === y?.portfolio?.toString()
                // );
                // const name = portfolioOption[index]?.name;
                const selectedYears = yearList?.map((f) => f)?.sort();

                const catArray = y?.record
                  ?.map((y, j) => y?.tradecatid)
                  ?.filter(getOnlyUnique);
                catArray?.forEach((cat) => {
                  const categoryIndex = tradecategoryOption?.findIndex(
                    (c) =>
                      c?.id !== undefined && c?.id?.toString() === cat?.toString()
                  );
                  const categoryName = tradecategoryOption[categoryIndex]?.name;

                  selectedYears?.forEach((sy) => {
                    let data2: number[] = [];
                    let changePercentdata: number[] = [];
                    let d1 = y?.record
                      ?.filter(
                        (r) => r?.tradecatid?.toString() === cat?.toString()
                      )
                      ?.filter(
                        (y) => y?.transyear?.toString() === sy?.toString()
                      );
                    monthsArray
                      ?.map((f) => f)
                      ?.sort()
                      ?.forEach((m, mi) => {
                        var mns = d1?.map((d) => d?.transmonth);
                        if (mns?.includes(mi + 1)) {
                          let obj = d1?.find((f) => f?.transmonth === mi + 1);
                          data2?.push(obj?.mtd ? parseFloat(obj?.mtd) : 0);
                          changePercentdata?.push(
                            obj?.mtdpercentchange ? obj?.mtdpercentchange : 0
                          );
                        } else {
                          data2?.push(0);
                          changePercentdata?.push(0);
                        }
                      });
                    let isEmpty: string[] = [];
                    let isPercentEmpty: string[] = [];
                    data2?.forEach((d2) => {
                      if (d2 !== 0) isEmpty?.push("true");
                      else isEmpty?.push("false");
                    });
                    changePercentdata?.forEach((c2) => {
                      if (c2 !== 0) isPercentEmpty?.push("true");
                      else isPercentEmpty?.push("false");
                    });
                    if (data2?.length > 0 && isEmpty?.includes("true")) {
                      mdata?.push({
                        name: categoryName + " - " + sy,
                        type: "column",
                        decimal: true,
                        data: data2,
                      });
                    }
                    if (
                      changePercentdata?.length > 0 &&
                      isEmpty?.includes("true")
                    ) {
                      mdata?.push({
                        name: categoryName + " - " + sy + " %Change",
                        type: "line",
                        decimal: false,
                        data: changePercentdata,
                      });
                    }
                  });
                });
              });
              return mdata;
            })()}
            labels={monthsArray}
            colors={[
              colors[0],
              colors[1],
              colors[2],
              colors[3],
              colors[4],
              colors[5],
              colors[6],
              colors[7],
            ]}
            title={(() => {
              const txt = `Trade Category Performance - MTD`;
              return txt;
            })()}
            subtitle={(() => {
              let subtxt: string[] = [];
              let subtxt1 = `\u3010Portfolio\u3011: ${propPfname} \u3010Period\u3011: ${yearList[0]} - ${yearList[2]}`;
              let subtxt2 = ``;
              if (
                props?.tradeCategory !== undefined &&
                props?.tradeCategory?.length > 0
              ) {
                subtxt1 += `\u3010TradeCategory\u3011: ${propCatname}`;
              }
              if (
                props?.tradeSubCategory !== undefined &&
                props?.tradeSubCategory?.length > 0
              ) {
                subtxt1 += `\u3010TradeSubCategory\u3011: ${propsubCatname}`;
              }
              if (props?.country !== undefined && props?.country?.length > 0) {
                subtxt2 += `\u3010Country\u3011: ${propConame}`;
              }
              if (props?.region !== undefined && props?.region?.length > 0) {
                subtxt2 += `\u3010Region\u3011: ${propRename}`;
              }
              if (props?.state !== undefined && props?.state?.length > 0) {
                subtxt2 += `\u3010State\u3011: ${propStname}`;
              }
              if (props?.city !== undefined && props?.city?.length > 0) {
                subtxt2 += `\u3010City\u3011: ${propCiname}`;
              }
              subtxt?.push(subtxt1);
              subtxt?.push(subtxt2);
              return subtxt;
            })()}
            exportfilename={"TradeCategoryPerformance-MTD"}
          />
        )}

      {selection === PortfolioDashboardList?.YTD &&
        view === KpiViewList?.CHART && (
          <MultipleYAxisChart1
            datasets={(() => {
              let ydata: {
                name: string;
                type: string;
                decimal: boolean;
                data: number[];
              }[] = [];
              YearlyFilteredData?.forEach((y, i) => {
                // const index = portfolioOption?.findIndex(
                //   (p) =>
                //     p?.id !== undefined &&
                //     p?.id?.toString() === y?.portfolio?.toString()
                // );
                // const name = portfolioOption[index]?.name;

                const catArray = y?.record
                  ?.map((y, j) => y?.tradecatid)
                  ?.filter(getOnlyUnique);
                catArray?.forEach((cat) => {
                  const categoryIndex = tradecategoryOption?.findIndex(
                    (c) =>
                      c?.id !== undefined && c?.id?.toString() === cat?.toString()
                  );
                  const categoryName = tradecategoryOption[categoryIndex]?.name;

                  let data2: number[] = [];
                  let changePercentdata: number[] = [];
                  let d1 = y?.record?.filter(
                    (sw) => sw?.tradecatid?.toString() === cat?.toString()
                  );
                  yearList
                    ?.map((f) => f)
                    ?.sort()
                    ?.forEach((yr) => {
                      var yrs = d1?.map((d) => d?.transyear);
                      if (yrs?.includes(yr)) {
                        let obj = d1?.find((f) => f?.transyear === yr);
                        data2?.push(
                          obj?.ytd ? parseFloat(obj?.ytd!?.toString()) : 0
                        );
                        changePercentdata?.push(
                          obj?.ytdpercentchange ? obj?.ytdpercentchange : 0
                        );
                      } else {
                        data2?.push(0);
                        changePercentdata?.push(0);
                      }
                    });
                  if (data2?.length > 0) {
                    ydata?.push({
                      name: categoryName,
                      type: "column",
                      decimal: true,
                      data: data2,
                    });
                  }
                  if (changePercentdata?.length > 0) {
                    ydata?.push({
                      name: categoryName + " %Change",
                      type: "line",
                      decimal: false,
                      data: changePercentdata,
                    });
                  }
                });
              });
              return ydata;
            })()}
            labels={yearList?.map((y) => y?.toString())?.sort()}
            colors={[
              colors[0],
              colors[1],
              colors[2],
              colors[3],
              colors[4],
              colors[5],
              colors[6],
              colors[7],
            ]}
            title={(() => {
              const txt = `Trade Category Performance - YTD`;
              return txt;
            })()}
            subtitle={(() => {
              let subtxt: string[] = [];
              let subtxt1 = `\u3010Portfolio\u3011: ${propPfname}" \u3010Period\u3011: ${yearList[0]} - ${yearList[2]}`;
              let subtxt2 = ``;
              if (
                props?.tradeCategory !== undefined &&
                props?.tradeCategory?.length > 0
              ) {
                subtxt1 += `\u3010TradeCategory\u3011: ${propCatname}`;
              }
              if (
                props?.tradeSubCategory !== undefined &&
                props?.tradeSubCategory?.length > 0
              ) {
                subtxt1 += `\u3010TradeSubCategory\u3011: ${propsubCatname}`;
              }
              if (props?.country !== undefined && props?.country?.length > 0) {
                subtxt2 += `\u3010Country\u3011: ${propConame}`;
              }
              if (props?.region !== undefined && props?.region?.length > 0) {
                subtxt2 += `\u3010Region\u3011: ${propRename}`;
              }
              if (props?.state !== undefined && props?.state?.length > 0) {
                subtxt2 += `\u3010State\u3011: ${propStname}`;
              }
              if (props?.city !== undefined && props?.city?.length > 0) {
                subtxt2 += `\u3010City\u3011: ${propCiname}`;
              }
              subtxt?.push(subtxt1);
              subtxt?.push(subtxt2);
              return subtxt;
            })()}
            exportfilename={"TradeCategoryPerformance-YTD"}
          />
        )}


{showTableModal && selection === PortfolioDashboardList?.MTD && (
              <Modal
              size="xl"
              visible = {showTableModal}
              onClose={() => setShowTableModal(false)}
              centered classname="grid-container"
              >
              <RetailPerformanceTable
                    data={getMonthlyMTDData()}
                    title={`Trade Category Performance (MTD) - ${propPfname}`}
                    fileName={"pf_monthly_sales"}
                    downloadCSV={false}
                    downloadPDF={false}
                  />
              </Modal>
        )}

{showTableModal && selection === PortfolioDashboardList?.YTD && (
              <Modal
              size="xl"
              visible = {showTableModal}
              onClose={() => setShowTableModal(false)}
              centered classname="grid-container"
              >
              <RetailPerformanceTable
                    data={getYearlyYTDData()}
                    title={`Trade Category Performance (YTD) - ${propPfname}`}
                    fileName={"pf_monthly_sales"}
                    downloadCSV={false}
                    downloadPDF={false}
                  />
              </Modal>
        )}

      {/* {selection === PortfolioDashboardList?.YOY && view === KpiViewList?.CHART && 
        <MultipleYAxisChart
            datasets={(() => {
                let ydata: { name: string; type: string, data: number[] }[] = []                
                YearlyFilteredData?.forEach((y, i) => {
                    const index = portfolioOption?.findIndex(p => p?.id !== undefined && p?.id?.toString() === y?.portfolio?.toString())
                    const name = portfolioOption[index]?.name                    
                    const catArray = y?.record?.map((y, j) => y?.tradecatid)?.filter(getOnlyUnique)
                    catArray?.forEach(cat => {
                        const categoryIndex = tradecategoryOption?.findIndex(c => c?.id !== undefined && c?.id?.toString() === cat?.toString())
                        const categoryName = tradecategoryOption[categoryIndex]?.name

                        let data2: number[] = []
                        let changePercentdata: number[] = []
                        let d1 = y?.record?.filter(r => r?.tradecatid?.toString() === cat?.toString())
                        yearList?.map(f => f)?.sort()?.forEach(yr => {
                            var yrs = d1?.map(d => d?.transyear)
                            if (yrs?.includes(yr)) {
                                let obj = d1?.find(f => f?.transyear === yr)
                                data2?.push(parseFloat(obj?.sales!?.toString()))
                                changePercentdata?.push(obj?.ytdpercentchange ? obj?.ytdpercentchange : 0)
                            } else {
                                data2?.push(0)
                                changePercentdata?.push(0)
                            }
                        })
                        if (data2?.length > 0) {
                            ydata?.push({name:categoryName, type: 'column', data: data2})
                        } 
                        // if (changePercentdata?.length > 0) {
                            // ydata?.push({name: categoryName +' % Change', type: 'line', data: changePercentdata})
                            ydata?.push({name: '%Change', type: 'line', data: changePercentdata})
                        // } 
                    })
                })
                return ydata
            })()
            }
            labels={yearList?.map(y => y?.toString())?.sort()}
            colors={[colors[0], colors[1], colors[2], colors[3], colors[4], colors[5], colors[6], colors[7]]}
            title={''}
        />} */}
    </Widget1>
  );
};
export default BrandDashboardCatSalesTDCompWidget;
