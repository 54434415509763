const CommonDashboard = () => {
    return <>

        {/* <PageTitle
            title='Portfolio Dashboard : Pheonix '
        /> */}

        <div className="row">
            <h4>Welcome!</h4>
        </div>

    </>

}

export default CommonDashboard
