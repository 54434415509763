/* eslint-disable no-throw-literal */
// import { Dispatch } from "redux";

export enum SelectedOptionsActionTypes {
  ADD_SELECTED_OPTION = "ADD_SELECTED_OPTION",
}

interface DropdownOption {
  imgUrl: string;
  label: string;
  value: number;
}

export interface OptionsTypes {
  portfolio: string;
  site: string;
  brand: string;
  company: string;
  portfolios: DropdownOption[];
  sites: DropdownOption[];
  companies: DropdownOption[];
  brands: DropdownOption[];
  location: string;
  category: string;
  subCategory: string;
  buildings: DropdownOption[];
  floors: DropdownOption[];
  categories: DropdownOption[];
  subCategories: DropdownOption[];
  locations: DropdownOption[];
  country: string;
  region: string;
  state: string;
  city: string;
  showFilter: boolean;
  loader1:boolean,
  loader2:boolean,
  loader3:boolean,
  loader4:boolean,
  loader5:boolean,
  loader6:boolean,
  isOutletUser: boolean,
  ip:string;
  loginday:string,
  loginevent:string,
  email:string,
  isLogged:boolean,
}

export interface AddSelectedOptionAction {
  type: SelectedOptionsActionTypes.ADD_SELECTED_OPTION;
  data: OptionsTypes;
}

export type OptionsActions = AddSelectedOptionAction;

export const addToSelectedOption = (
  item: OptionsTypes
): AddSelectedOptionAction => {
  return {
    type: SelectedOptionsActionTypes.ADD_SELECTED_OPTION,
    data: item,
  };
};
