import { nanoid } from "@reduxjs/toolkit";
import React, { ChangeEventHandler, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Option from "../components/Option";
import chartIcon from "../components/custom/assets/images/dashboard/chart_icon.svg";
import { FormInput } from "../components/custom/components";
import FormInput2 from "../components/custom/components/FormInput2";
import {
  Tbody,
  Thead,
} from "../components/custom/components/TradeCategoryPerformanceTable";
import TransactionTable from "../components/custom/components/TransactionTable";
import LineChart1 from "../components/custom/components/charts/apex/LineChart1";
import { COLORS } from "../components/custom/constants";
import UserLayout from "../components/custom/layouts/UserLayout";
import { ToastTypes, useToast } from "../components/toast/ToastProvider";
import {  defaultbrand, defaultLocation, defaultPortfolio, defaultTradeCategory, defaultTradeSubCategory, DropDownOption1,PAGE_SIZE } from "../config";
import api, { APIResponse, getAxiosRequestConfig } from "../config/api";
import {
  CSVdownload,
  getYesterday,
  handleDownloadPDF1,
} from "../config/functions";
import { AuthUserType } from "../redux/actions/authUserActions";
import { BrandType } from "../redux/actions/brandActions";
import { BrandCompanyType } from "../redux/actions/brandCompanyActions";
import { PortfolioType } from "../redux/actions/portfolioActions";
import {
  OptionsTypes,
  addToSelectedOption,
} from "../redux/actions/selectedOptionsActions";
import { SiteType } from "../redux/actions/siteActions";
import { TradeCategoryType } from "../redux/actions/tradeCategoryActions";
import { TradeSubCategoryType } from "../redux/actions/tradeSubCategoryActions";
import { StoreState } from "../redux/store";
import {
  BuildingDataType,
  BuildingType,
  FloorDataType,
  FloorType,
} from "./SalesOverAllSalesReport";
import { CatSubCatCompanyWithBrandRelationDataType } from "./TransactionBillWiseReport";
import Loader from "../components/custom/components/Loader";
import TransactionTable1 from "../components/custom/components/TransactionTable1";
import Modal from "../components/Modal";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faWindowMaximize } from "@fortawesome/free-solid-svg-icons";
import { areaType } from "../redux/actions/areaActions";
import { outletType } from "../redux/actions/outletActions";
import { useLocation } from "react-router-dom";
import { UserLog } from "../config/common";
import SingleSelect from "../components/custom/components/SingleSelect";
import SingleSelect1 from "../components/custom/components/SingleSelect1";

export interface DropDownOption {
  value: number;
  label: string;
  imgUrl: string;
}

export interface HourlySalesRecord {
  date: string;
  h1: number;
  h2: number;
  h3: number;
  h4: number;
  h5: number;
  h6: number;
  h7: number;
  h8: number;
  h9: number;
  h10: number;
  h11: number;
  h12: number;
  h13: number;
  h14: number;
  h15: number;
  h16: number;
  h17: number;
  h18: number;
  h19: number;
  h20: number;
  h21: number;
  h22: number;
  h23: number;
  h24: number;
}

export interface HourlySalesReportType {
  portfolio: number;
  record: HourlySalesRecord[];
  count?: number;
}

const SalesHourlySalesReport: React.FC = () => {
  const toast = useToast();
  const dispatch = useDispatch();
  const pageName = useLocation()
  const authUser = useSelector<StoreState, AuthUserType | null>(
    (state) => state?.authUser
  );
  const authUserToken = useSelector<StoreState, AuthUserType | null>(
    (state) => state?.authUser
  )?.["auth-token"]!;
  const config = getAxiosRequestConfig(authUserToken);

  const authUserSiteId = authUser?.siteId;
  const [fileLoader, setFileLoader] = useState<boolean>(false);
  const [loader, setLoader] = useState<boolean>(false);

  const portfolioOption = useSelector<StoreState, PortfolioType[]>(
    (state) => state?.portfolio
  );
  const siteOption = useSelector<StoreState, SiteType[]>((state) => state?.site);
  const brandOption = useSelector<StoreState, BrandType[]>(
    (state) => state?.brand
  );
  const brandCompanyOption = useSelector<StoreState, BrandCompanyType[]>(
    (state) => state?.brandCompany
  );

  const selectedOption = useSelector<StoreState, OptionsTypes>(
    (state) => state?.selectedOption
  );

  const noArray = [
    "1",
    "2",
    "3",
    "4",
    "5",
    "6",
    "7",
    "8",
    "9",
    "10",
    "11",
    "12",
    "13",
    "14",
    "15",
    "16",
    "17",
    "18",
    "19",
    "20",
    "21",
    "22",
    "23",
    "24",
  ];

  const filteredBrandCompany = brandCompanyOption?.filter(
    (a) => a?.retailid !== null
  );

  const filteredBrand = brandOption?.filter((a) => {
    return brandCompanyOption?.some(
      (b) => b?.id?.toString() === a?.companyid?.toString() && b?.retailid !== null
    );
  });

  const port =
    authUser?.portfolioId !== undefined && authUser?.portfolioId !== null
      ? authUser?.portfolioId?.split(",")
      : [];

  let portfolioData = port
    ?.map((pe) => {
      return portfolioOption?.filter(
        (f) => f?.id?.toString() === pe?.toString()
      );
    })
    ?.flat(1);
  const pname = portfolioData[0]?.name;
  const st =
    authUser?.siteId !== undefined && authUser?.siteId !== null
      ? authUser?.siteId?.split(",")
      : [];

  let siteData = st
    ?.map((pe) => {
      return siteOption?.filter((f) => f?.id?.toString() === pe?.toString());
    })
    ?.flat(1);

  const br =
    authUser?.brandId !== undefined && authUser?.brandId !== null
      ? authUser?.brandId?.split(",")
      : [];

  let brandData = br
    ?.map((b) => {
      return filteredBrand?.filter((f) => f?.id?.toString() === b?.toString());
    })
    ?.flat(1);
    const outletList = useSelector<StoreState, outletType[]>(
      (state) => state?.outlet
    );
  const localityOption = useSelector<StoreState, areaType[]>(
    (state) => state?.locality
  );
  const categoryOption = useSelector<StoreState, TradeCategoryType[]>(
    (state) => state?.tradeCategory
  );
  const subCategoryOption = useSelector<StoreState, TradeSubCategoryType[]>(
    (state) => state?.tradeSubCategory
  );

  const [
    catSubCatCompanyWithBrandRelationData,
    setCatSubCatCompanyWithBrandRelationData,
  ] = useState<CatSubCatCompanyWithBrandRelationDataType[]>([]);

  const [filterDate, setFilterDate] = useState<string>(getYesterday());

  const [valueIn, setValueIn] = useState<string>("");

  const [portfolios, setPortfolios] = useState<string>("");
  const [sites, setSites] = useState<string>("");
  const [brands, setBrands] = useState<string>("");
  const [companies, setCompanies] = useState<string>("");

  const [location, setLocation] = useState<string>("");
  const [categories, setCategories] = useState<string>("");
  const [subCategories, setSubCategories] = useState<string>("");


  const [hourlySalesData, setHourlySalesData] = useState<
    HourlySalesReportType[]
  >([]);
  const [hourlySalesYearlyData, setHourlySalesYearlyData] = useState<
    HourlySalesReportType[]
  >([]);

  const limit = PAGE_SIZE;
  const [offset, setOffset] = useState<number>(0);
  const [range, setRange] = useState<number[]>([]);
  const [length, setLength] = useState<number>(50);

  const [selectedRows, setSelectedRows] = useState<string[]>([]);
  const [selectedRows1, setSelectedRows1] = useState<string[]>([]);

  const [showModal, setShowModal] = useState<boolean>(false);
  const [isButtonVisible, setButtonVisible] = useState(false);
  const [disableLocality, setDisableLocality] = useState<boolean>(false);

  const [portfolioDrop, setPortfolioDrop] = useState<DropDownOption[]>([]);
  const [locationDrop, setLocationDrop] = useState<DropDownOption[]>([]);
  const [catDrop,setCatDrop] = useState<DropDownOption[]>([])
  const [subcatDrop,setSubcatDrop] = useState<DropDownOption[]>([])
  const [brandDrop,setBrandDrop] = useState<DropDownOption[]>([])

  const showExpandModalHandler = () => {
    setShowModal(true)
  };

  const handleSelectedRowsChange = (selectedRows: string[]) => {
    setSelectedRows(selectedRows);
  };

  const handleSelectedRowsChange1 = (selectedRows1: string[]) => {
    setSelectedRows1(selectedRows1);
  };

  const SiteFilteredByPortfolio = useMemo<SiteType[]>(() => {
    if (portfolios !== "") {
      return siteData?.filter((s) => {
        return s?.pfid?.toString() === portfolios?.toString();
      });
    } else return [];
  }, [portfolios, siteData]);

  const BrandFilteredByCompany = useMemo<BrandType[]>(() => {
    if (companies !== "") {
      return brandData?.filter((s) => {
        return s?.companyid?.toString() === companies?.toString();
      });
    } else if (subCategories !== "") {
      let brands: BrandType[] = [];
      catSubCatCompanyWithBrandRelationData?.forEach((s) => {
        let obj = s?.record?.find((r) =>
          r?.tradesubcatids?.split(",")?.includes(subCategories)
        );
        obj?.brandids?.split(",")?.forEach((m) => {
          let bc = brandData?.find((f) => f?.id?.toString() === m);
          brands?.push(bc!);
        });
      });
      return brands;
    } else if (categories !== "") {
      let brands: BrandType[] = [];
      catSubCatCompanyWithBrandRelationData?.forEach((s) => {
        let obj = s?.record?.find((r) => r?.tradecatid?.toString() === categories);
        obj?.brandids?.split(",")?.forEach((m) => {
          let bc = brandData?.find((f) => f?.id?.toString() === m);
          brands?.push(bc!);
        });
      });
      return brands;
    } else return brandData;
  }, [
    companies,
    brandData,
    catSubCatCompanyWithBrandRelationData,
    categories,
    subCategories,
  ]);

  const subCategoryFilteredByCategory = useMemo<TradeSubCategoryType[]>(() => {
    if (categories !== "") {
      return subCategoryOption?.filter((sc) => {
        return sc?.catid?.toString() === categories?.toString();
      });
    } else return subCategoryOption;
  }, [categories, subCategoryOption]);

  const handleFilterDate: ChangeEventHandler<HTMLInputElement> = (e) => {
    setFilterDate(e?.target?.value);
  };

  const handlePortfolioDrop = (selected: any) => {
    if(fileLoader===false){
    if(selected.length>0){
      const selectedPortfolio = selected as DropDownOption[];
      if(selectedPortfolio[0].value!==null&&selectedPortfolio[0].value!==0){
        setPortfolioDrop(selectedPortfolio)
        setPortfolios(selectedPortfolio[0]?.value?.toString())
        const data = { ...selectedOption, portfolio: selectedPortfolio[0]?.value?.toString() };
        dispatch(addToSelectedOption(data));
        setOffset(0);
      }
    }
  }
  };

  const handleClearPortfolio = () =>{
    let pid =portfolioData[0] && portfolioData[0]?.id? portfolioData[0].id?.toString(): "";
    setPortfolios(selectedOption.portfolio ? selectedOption.portfolio : pid);
    const b = portfolioOption.filter((a)=>a?.id?.toString()===pid?.toString())
    if (b?.length > 0 && b[0]?.id !== undefined) {
      const val: DropDownOption = {
        value: b[0]?.id, 
        label: b[0]?.name,
        imgUrl: b[0]?.logourl, 
      };
      setPortfolioDrop([val]);

      if(fileLoader===false){
      setLocation("");
      setLocationDrop([]);
      const data = { ...selectedOption, portfolio: b[0]?.id?.toString(),location:''};
      dispatch(addToSelectedOption(data));
      setOffset(0);
    }
  }
  }

  const handleLocationDrop = (selected: any) => {
    if(fileLoader===false){
    if(selected.length>0){
      const selectedLocation = selected as DropDownOption[];
      if(selectedLocation[0].value!==null&&selectedLocation[0].value!==0){
        setLocationDrop(selectedLocation)
        setLocation(selectedLocation[0]?.label?.toString())
       const data = { ...selectedOption,location: selectedLocation[0]?.label?.toString()};
       dispatch(addToSelectedOption(data));
       setOffset(0);
      }
    }
  }
  };
  
  const handleClearLocation = () =>{

       if(fileLoader===true){
        setLocation(location ? location : '');
        const b = localityOption.filter((a)=>a?.area?.toString()===location?.toString())
        if (b?.length > 0 && b[0]?.id !== undefined) {
          const val: DropDownOption = {
            value: b[0]?.id, 
            label: b[0]?.area,
            imgUrl:'', 
          };
          setLocationDrop([val]);
       }
       else{
        setLocationDrop([])
     }
      }
     
       if(fileLoader===false){
         setLocationDrop([])
         setLocation('')
       const data = { ...selectedOption,location: ""};
        dispatch(addToSelectedOption(data));
        setOffset(0);
       }
  }

  const handleCatDrop = (selected: any) => {
    if(fileLoader===false){
    if(selected.length>0){
      const selectedCat = selected as DropDownOption[];
      if(selectedCat[0].value!==null&&selectedCat[0].value!==0){
        setCatDrop(selectedCat)
        setCategories(selectedCat[0]?.value?.toString())
        setSubcatDrop([])
        setSubCategories('')
        setBrandDrop([])
        setBrands('')
        const data = {
          ...selectedOption,
          category: selectedCat[0]?.value?.toString(),
          subCategory: "",
          brand: "",
        };
        dispatch(addToSelectedOption(data));
        setOffset(0);
      }
    }
  }
  };
  
  const handleClearCat = () =>{
    if(fileLoader===true){
      setCategories(categories ? categories : '');
      const b = categoryOption.filter((a)=>a?.id?.toString()===categories?.toString())
      if (b?.length > 0 && b[0]?.id !== undefined) {
        const val: DropDownOption = {
          value: b[0]?.id, 
          label: b[0]?.name,
          imgUrl:'', 
        };
        setCatDrop([val]);
     }
     else{
      setCatDrop([])
   }
    }
   
     if(fileLoader===false){
      setCatDrop([])
      setCategories('')
      setSubcatDrop([])
        setSubCategories('')
        setBrandDrop([])
        setBrands('')
       const data = { ...selectedOption,
        category: '',
        subCategory: "",
        brand: "",};
       dispatch(addToSelectedOption(data));
       setOffset(0);
     }
  }

  const handleSubcatDrop = (selected: any) => {
    if(fileLoader===false){
    if(selected.length>0){
      const selectedSubcat = selected as DropDownOption[];
      if(selectedSubcat[0].value!==null&&selectedSubcat[0].value!==0){
        setSubcatDrop(selectedSubcat)
        setSubCategories(selectedSubcat[0]?.value?.toString())
        setBrandDrop([])
        setBrands('')
        const data = { ...selectedOption,
          subCategory: selectedSubcat[0]?.value?.toString(),
          brand: "",};
         dispatch(addToSelectedOption(data));
         setOffset(0);
      }
    }
  }
  };
  
  const handleClearSubcat = () =>{
    if(fileLoader===true){
      setSubCategories(subCategories ? subCategories : '');
      const b = subCategoryOption.filter((a)=>a?.id?.toString()===subCategories?.toString())
      if (b?.length > 0 && b[0]?.id !== undefined) {
        const val: DropDownOption = {
          value: b[0]?.id, 
          label: b[0]?.name,
          imgUrl:'', 
        };
        setSubcatDrop([val]);
     }else{
      setSubcatDrop([])
   }
    }
   
     if(fileLoader===false){
      setSubcatDrop([])
        setSubCategories('')
        setBrandDrop([])
        setBrands('')
       const data = { ...selectedOption,
        subCategory: "",
        brand: "",};
       dispatch(addToSelectedOption(data));
       setOffset(0);
     }
  }

  const handleBrandDrop = (selected: any) => {
    if(fileLoader===false){
    if(selected.length>0){
      const selectedBrand = selected as DropDownOption[];
      if(selectedBrand[0].value!==null&&selectedBrand[0].value!==0){
        setBrandDrop(selectedBrand)
        setBrands(selectedBrand[0]?.value?.toString())
       const data = { ...selectedOption,
        brand: selectedBrand[0]?.value?.toString()};
       dispatch(addToSelectedOption(data));
       setOffset(0);
      }
    }
  }
  };
  
  const handleClearBrand = () =>{
    if(fileLoader===true){
      setBrands(brands ? brands : '');
      const b = brandOption.filter((a)=>a?.id?.toString()===brands?.toString())
      if (b?.length > 0 && b[0]?.id !== undefined) {
        const val: DropDownOption = {
          value: b[0]?.id, 
          label: b[0]?.name,
          imgUrl: b[0]?.logourl, 
        };
        setBrandDrop([val]);
     }else {
      setBrandDrop([]);
     }
    }
   
     if(fileLoader===false){
        setBrandDrop([])
        setBrands('')
       const data = { ...selectedOption,
        brand: ""};
       dispatch(addToSelectedOption(data));
       setOffset(0);
     }
  }

  const portFilter = portfolioOption?.filter((a)=>a?.id?.toString()===portfolios?.toString())
  const catFilter = categoryOption?.filter((a)=>a?.id?.toString()===categories?.toString())
  const subcatFilter = subCategoryOption?.filter((a)=>a?.id?.toString()===subCategories?.toString())
  const brandFilter = brandOption?.filter((a)=>a?.id?.toString()===brands?.toString())
  const localityFilter = localityOption?.filter((a)=>a?.area?.toString()===location?.toString())


  const combinedValues = [
    ...portFilter?.map(a => `Portfolio: ${a?.name}`),           
    ...catFilter?.map(c=> `Trade Category: ${c?.name}`),
    ...subcatFilter?.map(d=> `Trade Sub Category: ${d?.name}`),
    ...brandFilter?.map(f=> `Brand: ${f?.name}`),  
    ...localityFilter?.map(f=>`Locality: ${f?.area}`),     
    `Date: ${filterDate?.toString()}`,
  ]
  ?.filter(name => name && name?.trim() !== '') 
  ?.join(', ');  

  const tHead = useMemo<Thead[]>(() => {
    var tableHead: Thead[] = [{ id: "Date", children: "Date", text: "Date" }];

    noArray?.forEach((n, i) => {
      tableHead?.push({
        children: n,
        text: n,
        id: n,
      });
    });

    return tableHead;
  }, []);

  const tHead1 = useMemo<Thead[]>(() => {
    var tableHead: Thead[] = [{ id: "Date", children: "Date", text: "Date" }];

    noArray?.forEach((n, i) => {
      tableHead?.push({
        children: n,
        text: n,
        id: n,
      });
    });

    return tableHead;
  }, []);

  const getDownloadData = (Data: HourlySalesReportType[]) => {
    var tableHead: Thead[] = tHead;
    var tableBody: Tbody[][] = [];

    Data?.forEach((data, i) => {
      data?.record?.forEach((item) => {
        var tBody: Tbody[] = [];
        tBody?.push(
          {
            id: nanoid(),
            theadId: "Date",
            children: item?.date,
            text: item?.date,
          },
          {
            id: nanoid(),
            theadId: "1",
            children: item?.h1,
            text: item?.h1?.toString(),
          },
          {
            id: nanoid(),
            theadId: "2",
            children: item?.h2,
            text: item?.h2?.toString(),
          },
          {
            id: nanoid(),
            theadId: "3",
            children: item?.h3,
            text: item?.h3?.toString(),
          },
          {
            id: nanoid(),
            theadId: "4",
            children: item?.h4,
            text: item?.h4?.toString(),
          },
          {
            id: nanoid(),
            theadId: "5",
            children: item?.h5,
            text: item?.h5?.toString(),
          },
          {
            id: nanoid(),
            theadId: "6",
            children: item?.h6,
            text: item?.h6?.toString(),
          },
          {
            id: nanoid(),
            theadId: "7",
            children: item?.h7,
            text: item?.h7?.toString(),
          },
          {
            id: nanoid(),
            theadId: "8",
            children: item?.h8,
            text: item?.h8?.toString(),
          },
          {
            id: nanoid(),
            theadId: "9",
            children: item?.h9,
            text: item?.h9?.toString(),
          },
          {
            id: nanoid(),
            theadId: "10",
            children: item?.h10,
            text: item?.h10?.toString(),
          },
          {
            id: nanoid(),
            theadId: "11",
            children: item?.h11,
            text: item?.h11?.toString(),
          },
          {
            id: nanoid(),
            theadId: "12",
            children: item?.h12,
            text: item?.h12?.toString(),
          },
          {
            id: nanoid(),
            theadId: "13",
            children: item?.h13,
            text: item?.h13?.toString(),
          },
          {
            id: nanoid(),
            theadId: "14",
            children: item?.h14,
            text: item?.h14?.toString(),
          },
          {
            id: nanoid(),
            theadId: "15",
            children: item?.h15,
            text: item?.h15?.toString(),
          },
          {
            id: nanoid(),
            theadId: "16",
            children: item?.h16,
            text: item?.h16?.toString(),
          },
          {
            id: nanoid(),
            theadId: "17",
            children: item?.h17,
            text: item?.h17?.toString(),
          },
          {
            id: nanoid(),
            theadId: "18",
            children: item?.h18,
            text: item?.h18?.toString(),
          },
          {
            id: nanoid(),
            theadId: "19",
            children: item?.h19,
            text: item?.h19?.toString(),
          },
          {
            id: nanoid(),
            theadId: "20",
            children: item?.h20,
            text: item?.h20?.toString(),
          },
          {
            id: nanoid(),
            theadId: "21",
            children: item?.h21,
            text: item?.h21?.toString(),
          },
          {
            id: nanoid(),
            theadId: "22",
            children: item?.h22,
            text: item?.h22?.toString(),
          },
          {
            id: nanoid(),
            theadId: "23",
            children: item?.h23,
            text: item?.h23?.toString(),
          },
          {
            id: nanoid(),
            theadId: "24",
            children: item?.h24,
            text: item?.h24?.toString(),
          }
        );
        tableBody?.push(tBody);
      });
    });
    return { tbody: tableBody, thead: tableHead };
  };

  const handleApply = () => {
    let error = false;
    if (portfolios === "") {
      toast("Select Portfolio", ToastTypes?.ERROR);
      error = true;
    } else if (location === "" && brands === "") {
      toast("Select Locality and Brand", ToastTypes?.ERROR);
      error = true;
    } else if (location === "" && brands !== "") {
      toast("Select Locality", ToastTypes?.ERROR);
      error = true;
    } else if (brands === "" && location !== ""  ) {
      toast("Select Brand", ToastTypes?.ERROR);
      error = true;
    } else if (filterDate === "") {
      toast("Select filterDate", ToastTypes?.ERROR);
      error = true;
    }
    const [building, floor] = location?.split("-");
    var getData = {
      portfolio: portfolio,
      site: site,
      locality: location===defaultLocation?.label?'':location,
      tradeCategory: category,
      tradeSubCategory: subCategory,
      brand: brand,
      date: filterDate,
      limit: limit?.toString(),
      offset: offset?.toString()==='NaN'||offset===undefined?'0':offset?.toString(),
      authUserRole: authUser?.roleId ? authUser?.roleId : "",
    };

    if (
      error === false &&
      getData !== undefined &&
      getData?.limit !== "" &&
      getData?.offset !== ""
    ) {
      setLoader(true);
      api
        .post<APIResponse<{ records: HourlySalesReportType[] }>>(
          `kpi/sales/daywiseHourlySales`,
          getData,
          config
        )
        .then((response) => {
          if (response?.status === 200 && response?.data?.data) {
            const result = response?.data?.data?.records;
            setHourlySalesData(result);
            setLoader(false);
            if(authUser){
              const logProps1 = {
                action: 'Apply',
                event: `${pageName?.pathname?.replace("\\", " ")} page | Filters - ${combinedValues} | Apply Clicked | Data Status: ${result?.[0]?.count && result[0]?.count > 0 ? 'Success' : 'Empty'}`
              };
              UserLog(logProps1)
            }
          }
          else {
            setHourlySalesData([]);
            setLoader(false);
          }
          
        })
        .catch((error) => {});

      api
        .post<APIResponse<{ records: HourlySalesReportType[] }>>(
          `kpi/sales/yearlyHourlySales`,
          getData,
          config
        )
        .then((response) => {
          if (response?.status === 200 && response?.data?.data) {
            const result = response?.data?.data?.records;
            setHourlySalesYearlyData(result);
            setLoader(false);
            if(authUser){
              const logProps1 = {
                action: 'Apply',
                event: `${pageName?.pathname?.replace("\\", " ")} page | Filters - ${combinedValues} | Apply Clicked | Data Status: ${result?.[0]?.count && result[0]?.count > 0 ? 'Success' : 'Empty'}`
              };
              UserLog(logProps1)
            }
          }
          else {
            setHourlySalesYearlyData([]);
            setLoader(false);
          }
        })
        .catch((error) => {});

        // setLoader(false);
    } 
  };


  useEffect(()=>{    
    if(selectedOption?.isOutletUser){
      setDisableLocality(true);
    }
    else{
      setDisableLocality(false);
    }
  },[authUser, outletList,selectedOption]);
  
  useEffect(() => {
    if (portfolios !== ""&&fileLoader === false) handleApply();
  
  }, [
    portfolios,
  ]);

  const handleCsvDownload = async (from: string) => {
    setFileLoader(true);
    let Data = from === "Day" ? hourlySalesData : hourlySalesYearlyData;
    if (Data[0]?.record?.length !== undefined && Data[0]?.record?.length !== 0) {
      const d = { ...getDownloadData(Data) };
      const filteredHead = d?.thead
        ?.map((a) => {
          const selectedRowsToMap =
            from === "Day" ? selectedRows : selectedRows1;

          const foundObject = selectedRowsToMap
            ?.map((b) => {
              if (a?.text?.toString() === b?.toString()) {
                return a;
              }
            })
            ?.find((obj) => obj !== undefined);

          if (foundObject !== undefined) {
            return foundObject;
          }
        })
        ?.filter((obj) => obj !== undefined);
      const csvHead = filteredHead;
      const csvBody = d?.tbody;

      const head = [csvHead?.map((h) => h?.text || "") || []];
      const body =
        csvBody?.map((tr, i) => {
          return (
            csvHead?.map((th, j) => {
              const col = tr?.find((r) => r?.theadId === th?.id);
              return col?.text || "";
            }) || []
          );
        }) || [];

      const rows = [...head, ...body];
      const props = {
        rows: rows,
        fileName:
          from === "Day"
            ? "Hourly Sales Daywise Report"
            : "Hourly Sales Yearly Report",
      };
      CSVdownload(props);
      setFileLoader(false);
      if(authUser){
        const logProps1 = {
          action: 'Csv',
          event: `${pageName?.pathname?.replace("\\", " ")} page | Filters - ${combinedValues} | Csv Clicked | Export Status: Success | Data Status: Success`
        };
        UserLog(logProps1)
      }
    } else {
      setFileLoader(false);
      if(authUser){
        const logProps1 = {
          action: 'Csv',
          event: `${pageName?.pathname?.replace("\\", " ")} page | Filters - ${combinedValues} | Csv Clicked | Export Status: Success | Data Status: Empty`
        };
        UserLog(logProps1)
      }
    }
    setFileLoader(false);
  };

  const handlePdfDownload = async (from: string) => {
    setFileLoader(true);
    let Data = from === "Day" ? hourlySalesData : hourlySalesYearlyData;
    if (Data[0]?.record?.length !== undefined && Data[0]?.record?.length !== 0) {
      const d = { ...getDownloadData(Data) };
      const filteredHead = d?.thead
        ?.map((a) => {
          const selectedRowsToMap =
            from === "Day" ? selectedRows : selectedRows1;

          const foundObject = selectedRowsToMap
            ?.map((b) => {
              if (a?.text?.toString() === b?.toString()) {
                return a;
              }
            })
            ?.find((obj) => obj !== undefined);

          if (foundObject !== undefined) {
            return foundObject;
          }
        })
        ?.filter((obj) => obj !== undefined);
      const thead = filteredHead;
      const tbody = d?.tbody;

      const head = [thead?.map((h) => h?.text || "") || []];
      const body =
        tbody?.map((tr, i) => {
          return (
            thead?.map((th, j) => {
              const col = tr?.find((r) => r?.theadId === th?.id);
              return col?.text || "";
            }) || []
          );
        }) || [];

      const props = {
        head: head,
        body: body,
        fileName:
          from === "Day"
            ? "Hourly Sales Daywise Report"
            : "Hourly Sales Yearly Report",
        title:
          from === "Day"
            ? "Hourly Sales Daywise Report"
            : "Hourly Sales Yearly Report",
      };
      handleDownloadPDF1(props);
      setFileLoader(false);
      if(authUser){
        const logProps1 = {
          action: 'Pdf',
          event: `${pageName?.pathname?.replace("\\", " ")} page | Filters - ${combinedValues} | Pdf Clicked | Export Status: Success | Data Status: Success`
        };
        UserLog(logProps1)
      }
    } else {
      setFileLoader(false);
      if(authUser){
        const logProps1 = {
          action: 'Pdf',
          event: `${pageName?.pathname?.replace("\\", " ")} page | Filters - ${combinedValues} | Pdf Clicked | Export Status: Success | Data Status: Empty`
        };
        UserLog(logProps1)
      }
    }
    setFileLoader(false);
  };

  const getTableData = (from: string) => {
    var tableHead: Thead[] = tHead;
    var tableBody: Tbody[][] = [];
    let Data = from === "Day" ? hourlySalesData : hourlySalesYearlyData;

    Data?.forEach((data, i) => {
      data?.record?.forEach((item) => {
        var tBody: Tbody[] = [];
        tBody?.push(
          {
            id: nanoid(),
            theadId: "Date",
            children: item?.date,
            text: item?.date,
          },
          {
            id: nanoid(),
            theadId: "1",
            children: item?.h1,
            text: item?.h1?.toString(),
          },
          {
            id: nanoid(),
            theadId: "2",
            children: item?.h2,
            text: item?.h2?.toString(),
          },
          {
            id: nanoid(),
            theadId: "3",
            children: item?.h3,
            text: item?.h3?.toString(),
          },
          {
            id: nanoid(),
            theadId: "4",
            children: item?.h4,
            text: item?.h4?.toString(),
          },
          {
            id: nanoid(),
            theadId: "5",
            children: item?.h5,
            text: item?.h5?.toString(),
          },
          {
            id: nanoid(),
            theadId: "6",
            children: item?.h6,
            text: item?.h6?.toString(),
          },
          {
            id: nanoid(),
            theadId: "7",
            children: item?.h7,
            text: item?.h7?.toString(),
          },
          {
            id: nanoid(),
            theadId: "8",
            children: item?.h8,
            text: item?.h8?.toString(),
          },
          {
            id: nanoid(),
            theadId: "9",
            children: item?.h9,
            text: item?.h9?.toString(),
          },
          {
            id: nanoid(),
            theadId: "10",
            children: item?.h10,
            text: item?.h10?.toString(),
          },
          {
            id: nanoid(),
            theadId: "11",
            children: item?.h11,
            text: item?.h11?.toString(),
          },
          {
            id: nanoid(),
            theadId: "12",
            children: item?.h12,
            text: item?.h12?.toString(),
          },
          {
            id: nanoid(),
            theadId: "13",
            children: item?.h13,
            text: item?.h13?.toString(),
          },
          {
            id: nanoid(),
            theadId: "14",
            children: item?.h14,
            text: item?.h14?.toString(),
          },
          {
            id: nanoid(),
            theadId: "15",
            children: item?.h15,
            text: item?.h15?.toString(),
          },
          {
            id: nanoid(),
            theadId: "16",
            children: item?.h16,
            text: item?.h16?.toString(),
          },
          {
            id: nanoid(),
            theadId: "17",
            children: item?.h17,
            text: item?.h17?.toString(),
          },
          {
            id: nanoid(),
            theadId: "18",
            children: item?.h18,
            text: item?.h18?.toString(),
          },
          {
            id: nanoid(),
            theadId: "19",
            children: item?.h19,
            text: item?.h19?.toString(),
          },
          {
            id: nanoid(),
            theadId: "20",
            children: item?.h20,
            text: item?.h20?.toString(),
          },
          {
            id: nanoid(),
            theadId: "21",
            children: item?.h21,
            text: item?.h21?.toString(),
          },
          {
            id: nanoid(),
            theadId: "22",
            children: item?.h22,
            text: item?.h22?.toString(),
          },
          {
            id: nanoid(),
            theadId: "23",
            children: item?.h23,
            text: item?.h23?.toString(),
          },
          {
            id: nanoid(),
            theadId: "24",
            children: item?.h24,
            text: item?.h24?.toString(),
          }
        );
        tableBody?.push(tBody);
      });
    });

    return { tbody: tableBody, thead: tableHead };
  };

  useEffect(() => {
    const header = tHead
      ?.map((a) => a?.text)
      ?.filter((id): id is string => typeof id === "string" && id !== undefined);
    setSelectedRows(header);
  }, []);

  useEffect(() => {
    const header = tHead1
      ?.map((a) => a?.text)
      ?.filter((id): id is string => typeof id === "string" && id !== undefined);
    setSelectedRows1(header);
  }, []);

  const portfolio = useMemo<number[]>(() => {
    return portfolios !== "" ? [parseInt(portfolios)] : [];
  }, [portfolios]);

  const site = useMemo<string[]>(() => {
    if (authUserSiteId) {
      return authUserSiteId?.split(",")?.map((s) => {
        return s?.toString();
      });
    } else {
      return [];
    }
  }, [authUserSiteId]);

  const brand = useMemo<number[]>(() => {
    return brands !== "" ? [parseInt(brands)] : [];
  }, [brands]);

  const category = useMemo<number[]>(() => {
    return categories !== "" ? [parseInt(categories)] : [];
  }, [categories]);

  const subCategory = useMemo<number[]>(() => {
    return subCategories !== "" ? [parseInt(subCategories)] : [];
  }, [subCategories]);

  useEffect(()=>{
    let pid =portfolioData[0] && portfolioData[0]?.id? portfolioData[0].id?.toString(): "";
    setPortfolios(selectedOption.portfolio ? selectedOption.portfolio : pid);
    const b = portfolioOption.filter((a)=>a?.id?.toString()===pid?.toString())
    if (b?.length > 0 && b[0]?.id !== undefined) {
      const val: DropDownOption = {
        value: b[0]?.id, 
        label: b[0]?.name,
        imgUrl: b[0]?.logourl, 
      };
      setPortfolioDrop([val]);
    }
},[portfolioOption,selectedOption])

  useEffect(() => {
    let sid = siteData[0] && siteData[0]?.id ? siteData[0]?.id?.toString() : "";
    setSites(selectedOption?.site ? selectedOption?.site : sid);
  }, [siteData, selectedOption]);
  useEffect(() => {
    var range = [];
    var num = Math?.ceil(length / limit);
    // let i = 1;

    for (let i = 1; i <= num; i++) {
      range?.push(i);
    }
    setRange(range);
  }, [length, limit]);

  useEffect(()=>{
    setLocation(selectedOption.location? selectedOption.location:'')
    const b = localityOption.filter((a)=>a?.area?.toString()===selectedOption.location?.toString())
    if (b?.length > 0 && b[0]?.id !== undefined) {
      const val: DropDownOption = {
        value: b[0]?.id, 
        label: b[0]?.area,
        imgUrl:'', 
      };
      setLocationDrop([val]);
    }
    },[localityOption,selectedOption])
  
    useEffect(()=>{
      setCategories(selectedOption.category ? selectedOption.category:'');
      const b = categoryOption.filter((a)=>a?.id?.toString()===selectedOption.category?.toString())
      if (b?.length > 0 && b[0]?.id !== undefined) {
        const val: DropDownOption = {
          value: b[0]?.id, 
          label: b[0]?.name,
          imgUrl:'', 
        };
        setCatDrop([val]);
      }
      },[categoryOption,selectedOption])
      
      
      useEffect(()=>{
      setSubCategories(selectedOption.subCategory ? selectedOption.subCategory:'');
      const b = subCategoryOption.filter((a)=>a?.id?.toString()===selectedOption.subCategory?.toString())
      if (b?.length > 0 && b[0]?.id !== undefined) {
        const val: DropDownOption = {
          value: b[0]?.id, 
          label: b[0]?.name,
          imgUrl:'', 
        };
        setSubcatDrop([val]);
      }
      },[subCategoryOption,selectedOption])
  
      useEffect(()=>{
        setBrands(selectedOption.brand ? selectedOption.brand:'');
        const b = brandOption.filter((a)=>a?.id?.toString()===selectedOption.brand?.toString())
        if (b?.length > 0 && b[0]?.id !== undefined) {
          const val: DropDownOption = {
            value: b[0]?.id, 
            label: b[0]?.name,
            imgUrl: b[0]?.logourl, 
          };
          setBrandDrop([val]);
        }
      },[brandOption,selectedOption])
    

  useEffect(() => {
    if (sites !== ""&&selectedOption.isOutletUser===true) {
      let siteCurrencyObject = siteOption?.find(
        (s) => s?.id?.toString() === sites?.toString()
      );
      setValueIn(
        siteCurrencyObject?.currencycode +
          " | " +
          siteCurrencyObject?.valuecaption
      );
    } 
    else if(portfolios !== ""&&selectedOption.isOutletUser===false){
      let portfolioCurrencyObject = portfolioOption?.find(
        (s) => s?.id?.toString() === portfolios?.toString()
      );
      setValueIn(
        portfolioCurrencyObject?.reportingcurrency +
          " | " +
          portfolioCurrencyObject?.valuecaption
      );
    }
    else setValueIn("");
  }, [sites, siteOption,selectedOption]);

  useEffect(() => {
    if (hourlySalesData?.length > 0)
      setLength(hourlySalesData[0]?.count ? hourlySalesData[0]?.count : 0);
  }, [hourlySalesData]);

  useEffect(() => {
    var getData = {
      portfolio: portfolio,
    };

    if (portfolio?.length > 0 && getData !== undefined) {
      api
        .post<
          APIResponse<{ records: CatSubCatCompanyWithBrandRelationDataType[] }>
        >(`filter/catSubcatCompanyRelationWithBrand`, getData, config)
        .then((response) => {
          if (response?.status === 200 && response?.data?.data) {
            const result = response?.data?.data?.records;
            setCatSubCatCompanyWithBrandRelationData(result);
          }
        })
        .catch((error) => {});
    } else {
      setCatSubCatCompanyWithBrandRelationData([]);
    }
  }, [portfolio]);

  const colors = [
    COLORS?.COLOR1,
    COLORS?.COLOR2,
    COLORS?.COLOR3,
    COLORS?.COLOR4,
    COLORS?.COLOR5,
    COLORS?.COLOR6,
    COLORS?.COLOR7,
    COLORS?.COLOR8,
  ];

  useEffect(()=>{
    if(authUser){
    const logProps1 = {
      action:`Visit`,
      event: `${pageName?.pathname?.replace("\\", " ")} page | Visited `
    };
    UserLog(logProps1)
  }
  },[])

  return (
    <UserLayout>
      <div className="card background-transparent p-1">
        <div className="row">
          <div
            className="col-lg-4 wow fadeInLeft ps-0"
          >
            <h4 className="b-title">Hourly Sales</h4>
          </div>

          <div className="col-lg-8 wow fadeInRight">
          <div className="row">
              <div className="col-sm-6 col-md-4 col-lg-3">
                {/* <FormInput2
                  name="Portfolio"
                  label=""
                  labelClassName="required"
                  value={portfolios}
                  onChange={handlePortfolioChange}
                  placeholder="Select"
                  containerClass="mb-1"
                  type="select"
                >
                  <option value="">Select</option>
                  {portfolioData?.map((p, i) => {
                    return (
                      <Option value={p?.id} key={i}>
                        {p?.name}
                      </Option>
                    );
                  })}
                </FormInput2> */}
                 {/* <SingleSelect
                  options={[
                    defaultPortfolio,
                    ...portfolioData?.sort(function (a, b) {
                      if (a?.name < b?.name) {
                        return -1;
                      }
                      if (a?.name > b?.name) {
                        return 1;
                      }
                      return 0;
                    })?.map((s, i) => {
                      return (
                        {
                          value: s?.id ? s?.id?.toString() : '',
                          label: s?.name,
                          imgUrl: s?.logourl,
                        }
                      );
                    })
                  ]}
                  label=""
                  selectedOptions={portfoliosOption}
                  setSelectedOptions={handlePortfolioChange}
                  // disabled={disableSite}
                  disabled={disableLocality}
                  placeHolder="Select Portfolio"
                  // filterText=""
                /> */}
                  <SingleSelect1
                      options={portfolioData.map((c, i) => {
                        return {
                          value: c?.id?c?.id:0,
                          label: c?.name?c?.name:'',
                          imgUrl: "",
                        };
                      })}
                      selectedOptions={portfolioDrop}
                      setSelectedOptions={handlePortfolioDrop}
                      placeHolder="Select Portfolio"
                      onClear={handleClearPortfolio}
                      disabled = {disableLocality}
                    />
              </div>
              
              <div className="col-sm-6 col-md-4 col-lg-3">
              {/* <FormInput2
                  name="Location"
                  label=""
                  labelClassName="required"
                  value={location}
                  onChange={handleLocationChange}
                  placeholder="Select Locality"
                  containerClass="mb-1"
                  disabled={disableLocality}
                  type="select"
                >
                  <option value="">Select Locality</option>
                  {localityOption?.map((s, i) => {
                    return (
                      <Option value={s?.area} key={i}>
                        {s?.area}
                      </Option>
                    );
                  })}
                </FormInput2> */}
                 {/* <SingleSelect
                  options={[
                    defaultLocation,
                    ...localityOption?.sort(function (a, b) {
                      if (a?.area < b?.area) {
                        return -1;
                      }
                      if (a?.area > b?.area) {
                        return 1;
                      }
                      return 0;
                    })?.map((s, i) => {
                      return (
                        {
                          value: s?.id ? s?.id?.toString() : '',
                          label: s?.area,
                          imgUrl: '',
                        }
                      );
                    })
                  ]}
                  label=""
                  selectedOptions={locationOption}
                  setSelectedOptions={handleLocationChange}
                  disabled={disableLocality}
                  placeHolder="Select Portfolio"
                  // filterText=""
                /> */}
                  <SingleSelect1
                      options={localityOption.map((c, i) => {
                        return {
                          value: c?.id?c?.id:0,
                          label: c?.area?c?.area:'',
                          imgUrl: "",
                        };
                      })}
                      selectedOptions={locationDrop}
                      setSelectedOptions={handleLocationDrop}
                      placeHolder="Select Locality"
                      onClear={handleClearLocation}
                      disabled = {disableLocality}
                    />
              </div>

            </div>

            <div className="row mt-1">
              <div className="col-sm-6 col-md-4 col-lg-3">
                {/* <FormInput2
                  name="Trade Category"
                  label=""
                  labelClassName="required"
                  value={categories}
                  onChange={handleCategoryChange}
                  placeholder="Select Trade Category"
                  containerClass="mb-1"
                  type="select"
                >
                  <option value="">Select Trade Category</option>
                  {categoryOption?.map((s, i) => {
                    return (
                      <Option value={s?.id} key={i}>
                        {s?.name}
                      </Option>
                    );
                  })}
                </FormInput2> */}
                    {/* <SingleSelect
                  options={[
                    defaultTradeCategory,
                    ...categoryOption?.sort(function (a, b) {
                      if (a?.name < b?.name) {
                        return -1;
                      }
                      if (a?.name > b?.name) {
                        return 1;
                      }
                      return 0;
                    })?.map((s, i) => {
                      return (
                        {
                          value: s?.id ? s?.id?.toString() : '',
                          label: s?.name,
                          imgUrl: '',
                        }
                      );
                    })
                  ]}
                  label=""
                  selectedOptions={categoriesOption}
                  setSelectedOptions={handleCategoryChange}
                  // disabled={disableSite}
                  disabled={disableLocality}
                  placeHolder="Select Trade Category"
                  // filterText=""
                /> */}
                  <SingleSelect1
                      options={categoryOption.map((c, i) => {
                        return {
                          value: c?.id?c?.id:0,
                          label: c?.name?c?.name:'',
                          imgUrl: "",
                        };
                      })}
                      selectedOptions={catDrop}
                      setSelectedOptions={handleCatDrop}
                      placeHolder="Select Trade Category"
                      onClear={handleClearCat}
                      disabled = {disableLocality}
                    />
              </div>
              <div className="col-sm-6 col-md-4 col-lg-3">
                {/* <FormInput2
                  name="Trade Sub Category"
                  label=""
                  labelClassName="required"
                  value={subCategories}
                  onChange={handleSubCategoryChange}
                  placeholder="Select Trade Subcategory"
                  containerClass="mb-1"
                  type="select"
                >
                  <option value="">Select Trade Subcategory</option>
                  {subCategoryFilteredByCategory?.map((s, i) => {
                    return (
                      <Option value={s?.id} key={i}>
                        {s?.name}
                      </Option>
                    );
                  })}
                </FormInput2> */}
                   {/* <SingleSelect
                  options={[
                    defaultTradeSubCategory,
                    ...subCategoryFilteredByCategory?.sort(function (a, b) {
                      if (a?.name < b?.name) {
                        return -1;
                      }
                      if (a?.name > b?.name) {
                        return 1;
                      }
                      return 0;
                    })?.map((s, i) => {
                      return (
                        {
                          value: s?.id ? s?.id?.toString() : '',
                          label: s?.name,
                          imgUrl: '',
                        }
                      );
                    })
                  ]}
                  label=""
                  selectedOptions={subCategoriesOption}
                  setSelectedOptions={handleSubCategoryChange}
                  // disabled={disableSite}
                  disabled={disableLocality}
                  placeHolder="Select Trade Sub Category"
                  // filterText=""
                /> */}
                  <SingleSelect1
                      options={subCategoryFilteredByCategory.map((c, i) => {
                        return {
                          value: c?.id?c?.id:0,
                          label: c?.name?c?.name:'',
                          imgUrl: "",
                        };
                      })}
                      selectedOptions={subcatDrop}
                      setSelectedOptions={handleSubcatDrop}
                      placeHolder="Select Trade Sub Category"
                      onClear={handleClearSubcat}
                      disabled = {disableLocality}
                    />
              </div>
              
              <div className="col-sm-6 col-md-4 col-lg-3">
                {/* <FormInput2
                  name="Brand"
                  label=""
                  labelClassName="required"
                  value={brands}
                  onChange={handleBrandChange}
                  placeholder="Select Brand"
                  containerClass="mb-1"
                  type="select"
                >
                  <option value="">Select Brand</option>
                  {BrandFilteredByCompany?.sort(function (a, b) {
                    if (a?.name < b?.name) {
                      return -1;
                    }
                    if (a?.name > b?.name) {
                      return 1;
                    }
                    return 0;
                  })?.map((s, i) => {
                    return (
                      <Option value={s?.id} key={i}>
                        {s?.name}
                      </Option>
                    );
                  })}
                </FormInput2> */}
                {/* <SingleSelect
                  options={[
                    defaultbrand,
                    ...BrandFilteredByCompany?.sort(function (a, b) {
                      if (a?.name < b?.name) {
                        return -1;
                      }
                      if (a?.name > b?.name) {
                        return 1;
                      }
                      return 0;
                    })?.map((s, i) => {
                      return (
                        {
                          value: s?.id ? s?.id?.toString() : '',
                          label: s?.name,
                          imgUrl: s?.logourl,
                        }
                      );
                    })
                  ]}
                  label=""
                  selectedOptions={brandsOption}
                  setSelectedOptions={handleBrandChange}
                  // disabled={disableSite}
                  disabled={disableLocality}
                  placeHolder="Select Brand"
                  // filterText=""
                /> */}
                     <SingleSelect1
                options={BrandFilteredByCompany.map((c, i) => {
        return {
          value: c?.id?c?.id:0,
          label: c?.name?c?.name:'',
          imgUrl: "",
        };
      })}
      selectedOptions={brandDrop}
      setSelectedOptions={handleBrandDrop}
      placeHolder="Select Brand"
                onClear={handleClearBrand}
                disabled = {disableLocality}
              />
              </div>
            </div>

          </div>
        </div>
      </div>

      {loader ? (
        <Loader />
      ) : (
        <>
          <div className="row">
            <div className="col-12">
              <div className="card mt-3 widget_div" onMouseEnter={() => setButtonVisible(true)} onMouseLeave={() => setButtonVisible(false)}>
              {(<button type="button" className="expandIconBtn" onClick={showExpandModalHandler} ><i><FontAwesomeIcon icon={faWindowMaximize} size="lg" title="Open in Popup"/></i></button>)}
                <div className="card-body">
                  <div className="row">
                    <div className="col-lg-12 col-sm-12">
                      <div className="float-start">
                        <h5 className="header-title">
                          <img src={chartIcon} alt="icon"></img>
                          Hourly Sales Report
                        </h5>
                      </div>
                      <div className="col-lg-12 col-sm-12 col-md-6">
                        <div className="float-end hstack gap-2">
                          <div className="col-xs-12 col-sm-12 col-md-8 col-lg-8">
                            <FormInput
                              name="From Date"
                              label=""
                              labelClassName="required"
                              value={filterDate}
                              onChange={handleFilterDate}
                              max={getYesterday()}
                              type="date"
                              placeholder="From Date"
                            />
                          </div>
                          <div className="col-xs-12 col-sm-12 col-md-4 col-lg-4">
                            <button
                              type="button"
                              className="btn btn-primary"
                              onClick={handleApply}
                            >
                              Apply
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <hr className="m-0 my-2 p-0 hr" />
                  <div>{"*Value in " + valueIn}</div>
                  <div className="row">
                    <div className="col-6">
                      <LineChart1
                        datasets={(() => {
                          const hdata: { name: string; data: number[] }[] = [];

                          hourlySalesData?.forEach((y, i) => {
                            const index = portfolioOption?.findIndex(
                              (p) =>
                                p?.id !== undefined &&
                                p?.id?.toString() === y?.portfolio?.toString()
                            );
                            const name = portfolioOption[index]?.name;

                            y?.record?.forEach((r) => {
                              let data: number[] = [];
                              // for (let i = 1; i <= 24; i++) {
                              data?.push(r?.h1 ? r?.h1 : 0);
                              data?.push(r?.h2 ? r?.h2 : 0);
                              data?.push(r?.h3 ? r?.h3 : 0);
                              data?.push(r?.h4 ? r?.h4 : 0);
                              data?.push(r?.h5 ? r?.h5 : 0);
                              data?.push(r?.h6 ? r?.h6 : 0);
                              data?.push(r?.h7 ? r?.h7 : 0);
                              data?.push(r?.h8 ? r?.h8 : 0);
                              data?.push(r?.h9 ? r?.h9 : 0);
                              data?.push(r?.h10 ? r?.h10 : 0);
                              data?.push(r?.h11 ? r?.h11 : 0);
                              data?.push(r?.h12 ? r?.h12 : 0);
                              data?.push(r?.h13 ? r?.h13 : 0);
                              data?.push(r?.h14 ? r?.h14 : 0);
                              data?.push(r?.h15 ? r?.h15 : 0);
                              data?.push(r?.h16 ? r?.h16 : 0);
                              data?.push(r?.h17 ? r?.h17 : 0);
                              data?.push(r?.h18 ? r?.h18 : 0);
                              data?.push(r?.h19 ? r?.h19 : 0);
                              data?.push(r?.h20 ? r?.h20 : 0);
                              data?.push(r?.h21 ? r?.h21 : 0);
                              data?.push(r?.h22 ? r?.h22 : 0);
                              data?.push(r?.h23 ? r?.h23 : 0);
                              data?.push(r?.h24 ? r?.h24 : 0);
                              // }
                              if (data?.length > 0) {
                                hdata?.push({
                                  name: r?.date,
                                  data: data,
                                });
                              }
                            });
                          });
                          return hdata;
                        })()}
                        labels={noArray?.map((w) => w?.toString())}
                        colors={[
                          colors[0],
                          colors[1],
                          colors[2],
                          colors[3],
                          colors[4],
                          colors[5],
                          colors[6],
                          colors[7],
                        ]}
                        title={(() => {
                          const txt = `Hourly Sales Daywise`;

                          return txt;
                        })()}
                        subtitle={(() => {
                          let subtxt: string[] = [];
                          let subtxt1 = `\u3010Company\u3011: ${pname}`;
                          let subtxt2 = ``;
                          if (location) {
                            subtxt1 += `\u3010Locality\u3011: ${location}`;
                          }
                          if (
                            categories?.length > 0 &&
                            categories !== undefined
                          ) {
                            const categoryIndex = categoryOption?.findIndex(
                              (a) => a?.id?.toString() === categories?.toString()
                            );
                            const categoryName =
                              categoryOption[categoryIndex]?.name;
                            subtxt1 += ` \n\u3010TradeCategory\u3011: ${categoryName}`;
                          }
                          if (subCategories?.length > 0) {
                            const subcategoryIndex =
                              subCategoryOption?.findIndex(
                                (a) =>
                                  a?.id?.toString() === subCategories?.toString()
                              );
                            const subcatName =
                              subCategoryOption[subcategoryIndex]?.name;
                            subtxt2 += ` \u3010TradeSubcategory\u3011: ${subcatName}`;
                          }
                          if (brand?.length > 0) {
                            const brandIndex = brandOption?.findIndex(
                              (a) => a?.id?.toString() === brand?.toString()
                            );
                            const brandname = brandOption[brandIndex]?.name;
                            subtxt2 += ` \u3010Brand\u3011: ${brandname}`;
                          }
                          subtxt2 += ` \u3010Date\u3011: ${filterDate?.toString()}`;
                          subtxt?.push(subtxt1);
                          subtxt?.push(subtxt2);
                          return subtxt;
                        })()}
                        exportfilename={"hourlySales-Daywise"}
                      />
                    </div>
                    <div className="col-6">
                      <LineChart1
                        datasets={(() => {
                          const hdata: { name: string; data: number[] }[] = [];

                          hourlySalesYearlyData?.forEach((y, i) => {
                            const index = portfolioOption?.findIndex(
                              (p) =>
                                p?.id !== undefined &&
                                p?.id?.toString() === y?.portfolio?.toString()
                            );
                            const name = portfolioOption[index]?.name;

                            y?.record?.forEach((r) => {
                              let data: number[] = [];
                              // for (let i = 1; i <= 24; i++) {
                              data?.push(r?.h1 ? r?.h1 : 0);
                              data?.push(r?.h2 ? r?.h2 : 0);
                              data?.push(r?.h3 ? r?.h3 : 0);
                              data?.push(r?.h4 ? r?.h4 : 0);
                              data?.push(r?.h5 ? r?.h5 : 0);
                              data?.push(r?.h6 ? r?.h6 : 0);
                              data?.push(r?.h7 ? r?.h7 : 0);
                              data?.push(r?.h8 ? r?.h8 : 0);
                              data?.push(r?.h9 ? r?.h9 : 0);
                              data?.push(r?.h10 ? r?.h10 : 0);
                              data?.push(r?.h11 ? r?.h11 : 0);
                              data?.push(r?.h12 ? r?.h12 : 0);
                              data?.push(r?.h13 ? r?.h13 : 0);
                              data?.push(r?.h14 ? r?.h14 : 0);
                              data?.push(r?.h15 ? r?.h15 : 0);
                              data?.push(r?.h16 ? r?.h16 : 0);
                              data?.push(r?.h17 ? r?.h17 : 0);
                              data?.push(r?.h18 ? r?.h18 : 0);
                              data?.push(r?.h19 ? r?.h19 : 0);
                              data?.push(r?.h20 ? r?.h20 : 0);
                              data?.push(r?.h21 ? r?.h21 : 0);
                              data?.push(r?.h22 ? r?.h22 : 0);
                              data?.push(r?.h23 ? r?.h23 : 0);
                              data?.push(r?.h24 ? r?.h24 : 0);
                              // }
                              if (data?.length > 0) {
                                hdata?.push({
                                  name: r?.date,
                                  data: data,
                                });
                              }
                            });
                          });
                          return hdata;
                        })()}
                        labels={noArray?.map((w) => w?.toString())}
                        colors={[
                          colors[0],
                          colors[1],
                          colors[2],
                          colors[3],
                          colors[4],
                          colors[5],
                          colors[6],
                          colors[7],
                        ]}
                        title={(() => {
                          const txt = `Hourly Sales Yearwise`;

                          return txt;
                        })()}
                        subtitle={(() => {
                          let subtxt: string[] = [];
                          let subtxt1 = `\u3010Company\u3011: ${pname}`;
                          let subtxt2 = ``;
                          if (location) {
                            subtxt1 += `\u3010Locality\u3011: ${location}`;
                          }
                          if (
                            categories?.length > 0 &&
                            categories !== undefined
                          ) {
                            const categoryIndex = categoryOption?.findIndex(
                              (a) => a?.id?.toString() === categories?.toString()
                            );
                            const categoryName =
                              categoryOption[categoryIndex]?.name;
                            subtxt1 += ` \n\u3010TradeCategory\u3011: ${categoryName}`;
                          }
                          if (subCategories?.length > 0) {
                            const subcategoryIndex =
                              subCategoryOption?.findIndex(
                                (a) =>
                                  a?.id?.toString() === subCategories?.toString()
                              );
                            const subcatName =
                              subCategoryOption[subcategoryIndex]?.name;
                            subtxt2 += ` \u3010TradeSubcategory\u3011: ${subcatName}`;
                          }
                          if (brand?.length > 0) {
                            const brandIndex = brandOption?.findIndex(
                              (a) => a?.id?.toString() === brand?.toString()
                            );
                            const brandname = brandOption[brandIndex]?.name;
                            subtxt2 += ` \u3010Brand\u3011: ${brandname}`;
                          }
                          subtxt2 += ` \u3010Date\u3011: ${filterDate?.toString()}`;
                          subtxt?.push(subtxt1);
                          subtxt?.push(subtxt2);
                          return subtxt;
                        })()}
                        exportfilename={"HourlySales-yearly"}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-12">
              <div className="card mt-3">
                <div className="card-body">
                  <div className="row mb-1">
                    <div className="col-lg-6 col-sm-12">
                      <div className="float-end hstack gap-1">
                        
                      </div>
                    </div>
                  </div>

                  <TransactionTable
                    data={[]}
                    {...getTableData("Day")}
                    title="Hourly Sales Daywise Report"
                    downloadCSV={true}
                    downloadPDF={true}
                    fileName="Hourly Sales Daywise Report"
                    // limit={limit}
                    setOffset={setOffset}
                    onClickCSV={() => handleCsvDownload("Day")}
                    onClickPDF={() => handlePdfDownload("Day")}
                    range={range}
                    valueInString={valueIn}
                    onPageNoChange={handleApply}
                    fLoader={fileLoader}
                    onSelectedRowsChange={handleSelectedRowsChange}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-12">
              <div className="card mt-3">
                <div className="card-body">
                  <TransactionTable1
                    data={[]}
                    {...getTableData("Year")}
                    title="Hourly Sales Yearwise Report"
                    downloadCSV={true}
                    downloadPDF={true}
                    fileName="Hourly Sales Yearwise Report"
                    onClickCSV={() => handleCsvDownload("Year")}
                    onClickPDF={() => handlePdfDownload("Year")}
                    range={range}
                    valueInString={valueIn}
                    onPageNoChange={handleApply}
                    fLoader={fileLoader}
                    onSelectedRowsChange1={handleSelectedRowsChange1}
                  />
                </div>
              </div>
            </div>
          </div>

          {showModal && (
        <Modal
        headerText={"Hourly Sales"}
        visible={showModal}
        onClose={() => setShowModal(false)}
        size="xl"
        centered
        classname="widget-container-single" > 
		
		<div className="card-body">
                  
                  <div className="row">
                    <div className="col-6">
                      <LineChart1
                        datasets={(() => {
                          const hdata: { name: string; data: number[] }[] = [];

                          hourlySalesData?.forEach((y, i) => {
                            const index = portfolioOption?.findIndex(
                              (p) =>
                                p?.id !== undefined &&
                                p?.id?.toString() === y?.portfolio?.toString()
                            );
                            const name = portfolioOption[index]?.name;

                            y?.record?.forEach((r) => {
                              let data: number[] = [];
                              data?.push(r?.h1 ? r?.h1 : 0);
                              data?.push(r?.h2 ? r?.h2 : 0);
                              data?.push(r?.h3 ? r?.h3 : 0);
                              data?.push(r?.h4 ? r?.h4 : 0);
                              data?.push(r?.h5 ? r?.h5 : 0);
                              data?.push(r?.h6 ? r?.h6 : 0);
                              data?.push(r?.h7 ? r?.h7 : 0);
                              data?.push(r?.h8 ? r?.h8 : 0);
                              data?.push(r?.h9 ? r?.h9 : 0);
                              data?.push(r?.h10 ? r?.h10 : 0);
                              data?.push(r?.h11 ? r?.h11 : 0);
                              data?.push(r?.h12 ? r?.h12 : 0);
                              data?.push(r?.h13 ? r?.h13 : 0);
                              data?.push(r?.h14 ? r?.h14 : 0);
                              data?.push(r?.h15 ? r?.h15 : 0);
                              data?.push(r?.h16 ? r?.h16 : 0);
                              data?.push(r?.h17 ? r?.h17 : 0);
                              data?.push(r?.h18 ? r?.h18 : 0);
                              data?.push(r?.h19 ? r?.h19 : 0);
                              data?.push(r?.h20 ? r?.h20 : 0);
                              data?.push(r?.h21 ? r?.h21 : 0);
                              data?.push(r?.h22 ? r?.h22 : 0);
                              data?.push(r?.h23 ? r?.h23 : 0);
                              data?.push(r?.h24 ? r?.h24 : 0);
                              if (data?.length > 0) {
                                hdata?.push({
                                  name: r?.date,
                                  data: data,
                                });
                              }
                            });
                          });
                          return hdata;
                        })()}
                        labels={noArray?.map((w) => w?.toString())}
                        colors={[
                          colors[0],
                          colors[1],
                          colors[2],
                          colors[3],
                          colors[4],
                          colors[5],
                          colors[6],
                          colors[7],
                        ]}
                        title={(() => {
                          const txt = `Hourly Sales Daywise`;

                          return txt;
                        })()}
                        subtitle={(() => {
                          let subtxt: string[] = [];
                          let subtxt1 = `\u3010Company\u3011: ${pname}`;
                          let subtxt2 = ``;
                          if (location) {
                            subtxt1 += `\u3010Locality\u3011: ${location}`;
                          }
                          if (
                            categories?.length > 0 &&
                            categories !== undefined
                          ) {
                            const categoryIndex = categoryOption?.findIndex(
                              (a) => a?.id?.toString() === categories?.toString()
                            );
                            const categoryName =
                              categoryOption[categoryIndex]?.name;
                            subtxt1 += ` \n\u3010TradeCategory\u3011: ${categoryName}`;
                          }
                          if (subCategories?.length > 0) {
                            const subcategoryIndex =
                              subCategoryOption?.findIndex(
                                (a) =>
                                  a?.id?.toString() === subCategories?.toString()
                              );
                            const subcatName =
                              subCategoryOption[subcategoryIndex]?.name;
                            subtxt2 += ` \u3010TradeSubcategory\u3011: ${subcatName}`;
                          }
                          if (brand?.length > 0) {
                            const brandIndex = brandOption?.findIndex(
                              (a) => a?.id?.toString() === brand?.toString()
                            );
                            const brandname = brandOption[brandIndex]?.name;
                            subtxt2 += ` \u3010Brand\u3011: ${brandname}`;
                          }
                          subtxt2 += ` \u3010Date\u3011: ${filterDate?.toString()}`;
                          subtxt?.push(subtxt1);
                          subtxt?.push(subtxt2);
                          return subtxt;
                        })()}
                        exportfilename={"hourlySales-Daywise"}
                      />
                    </div>
                    <div className="col-6">
                      <LineChart1
                        datasets={(() => {
                          const hdata: { name: string; data: number[] }[] = [];

                          hourlySalesYearlyData?.forEach((y, i) => {
                            const index = portfolioOption?.findIndex(
                              (p) =>
                                p?.id !== undefined &&
                                p?.id?.toString() === y?.portfolio?.toString()
                            );
                            const name = portfolioOption[index]?.name;

                            y?.record?.forEach((r) => {
                              let data: number[] = [];
                              data?.push(r?.h1 ? r?.h1 : 0);
                              data?.push(r?.h2 ? r?.h2 : 0);
                              data?.push(r?.h3 ? r?.h3 : 0);
                              data?.push(r?.h4 ? r?.h4 : 0);
                              data?.push(r?.h5 ? r?.h5 : 0);
                              data?.push(r?.h6 ? r?.h6 : 0);
                              data?.push(r?.h7 ? r?.h7 : 0);
                              data?.push(r?.h8 ? r?.h8 : 0);
                              data?.push(r?.h9 ? r?.h9 : 0);
                              data?.push(r?.h10 ? r?.h10 : 0);
                              data?.push(r?.h11 ? r?.h11 : 0);
                              data?.push(r?.h12 ? r?.h12 : 0);
                              data?.push(r?.h13 ? r?.h13 : 0);
                              data?.push(r?.h14 ? r?.h14 : 0);
                              data?.push(r?.h15 ? r?.h15 : 0);
                              data?.push(r?.h16 ? r?.h16 : 0);
                              data?.push(r?.h17 ? r?.h17 : 0);
                              data?.push(r?.h18 ? r?.h18 : 0);
                              data?.push(r?.h19 ? r?.h19 : 0);
                              data?.push(r?.h20 ? r?.h20 : 0);
                              data?.push(r?.h21 ? r?.h21 : 0);
                              data?.push(r?.h22 ? r?.h22 : 0);
                              data?.push(r?.h23 ? r?.h23 : 0);
                              data?.push(r?.h24 ? r?.h24 : 0);
                              if (data?.length > 0) {
                                hdata?.push({
                                  name: r?.date,
                                  data: data,
                                });
                              }
                            });
                          });
                          return hdata;
                        })()}
                        labels={noArray?.map((w) => w?.toString())}
                        colors={[
                          colors[0],
                          colors[1],
                          colors[2],
                          colors[3],
                          colors[4],
                          colors[5],
                          colors[6],
                          colors[7],
                        ]}
                        title={(() => {
                          const txt = `Hourly Sales Yearwise`;

                          return txt;
                        })()}
                        subtitle={(() => {
                          let subtxt: string[] = [];
                          let subtxt1 = `\u3010Company\u3011: ${pname}`;
                          let subtxt2 = ``;
                          if (location) {
                            subtxt1 += `\u3010Locality\u3011: ${location}`;
                          }
                          if (
                            categories?.length > 0 &&
                            categories !== undefined
                          ) {
                            const categoryIndex = categoryOption?.findIndex(
                              (a) => a?.id?.toString() === categories?.toString()
                            );
                            const categoryName =
                              categoryOption[categoryIndex]?.name;
                            subtxt1 += ` \n\u3010TradeCategory\u3011: ${categoryName}`;
                          }
                          if (subCategories?.length > 0) {
                            const subcategoryIndex =
                              subCategoryOption?.findIndex(
                                (a) =>
                                  a?.id?.toString() === subCategories?.toString()
                              );
                            const subcatName =
                              subCategoryOption[subcategoryIndex]?.name;
                            subtxt2 += ` \u3010TradeSubcategory\u3011: ${subcatName}`;
                          }
                          if (brand?.length > 0) {
                            const brandIndex = brandOption?.findIndex(
                              (a) => a?.id?.toString() === brand?.toString()
                            );
                            const brandname = brandOption[brandIndex]?.name;
                            subtxt2 += ` \u3010Brand\u3011: ${brandname}`;
                          }
                          subtxt2 += ` \u3010Date\u3011: ${filterDate?.toString()}`;
                          subtxt?.push(subtxt1);
                          subtxt?.push(subtxt2);
                          return subtxt;
                        })()}
                        exportfilename={"HourlySales-yearly"}
                      />
                    </div>
                  </div>
                </div>
		</Modal>)}
        </>
      )}
    </UserLayout>
  );
};
export default SalesHourlySalesReport;
