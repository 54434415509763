import { ApexOptions } from "apexcharts";
import Chart from "react-apexcharts";

interface dataSet {
  name: string;
  data: number[];
}

interface Iprops {
  datasets: dataSet[];
  labels: string[];
  title: string;
  subtitle: string[];
  colors: string[];
  exportfilename: string;
}
// simple line chart
const LineChart1 = (props: Iprops) => {
  // default options
  const { colors, datasets, labels, title, subtitle, exportfilename } = props;
  const apexLineChartWithLables: ApexOptions = {
    chart: {
      height: 380,
      type: "area",
      zoom: {
        enabled: false,
      },
      toolbar: {
        show: true,
        export: {
          csv: {
            filename: exportfilename,
          },
          svg: {
            filename: exportfilename,
          },
          png: {
            filename: exportfilename,
          },
        },
      },
    },
    title: {
      text: title,
      align: "center",
      margin: 18,
      offsetY: -8,
    },
    /*subtitle: {
      text: '',
      align: "center",
      offsetY: 22,
      margin: 18,
    },*/
    annotations: {
      texts: [
        {
          x: 30,
          y: 38,
          text: subtitle[0],
          fontSize: "12px",
          borderColor: "transparent",
          paddingTop: 3,
          paddingBottom: 2,
        },
        {
          x: 30,
          y: 55,
          text: subtitle[1],
          fontSize: "12px",
          borderColor: "transparent",
          paddingTop: 2,
          paddingBottom: 30,
        },
      ],
    },
    colors: colors,
    dataLabels: {
      enabled: true,
    },
    stroke: {
      width: [1, 1],
      curve: "straight", //smooth
    },
    grid: {
      row: {
        colors: ["transparent", "transparent"], // takes an array which will be repeated on columns
        opacity: 0.2,
      },
      borderColor: "#f1f3fa",
      padding: {
        left: 0,
        right: 0,
      },
    },
    markers: {
      size: 3,
    },
    xaxis: {
      categories: labels,
      title: {
        text: "",
      },
    },
    yaxis: {
      title: {
        text: "",
      },
    },
    legend: {
      showForSingleSeries: true,
      offsetY: 0,
      show: true,
    },
    responsive: [
      {
        options: {
          chart: {
            toolbar: {
              show: false,
            },
          },
          legend: {
            show: false,
          },
          stroke: {
            curve: "smooth",
          },
        },
      },
    ],
  };

  return (
    <>
      <Chart
        options={apexLineChartWithLables}
        series={datasets}
        type="line"
        className="apex-charts"
        height={400}
      />
    </>
  );
};

export default LineChart1;
