import api, { APIResponse, getAxiosRequestConfig } from "../../config/api";
import { Dispatch } from "redux";
import { GetState } from "../store";

export interface areaType {
  id: number;
  area: string;
}

export enum AreaActionsList {
  FETCH_ALL_OUTLET_AREAS = "FETCH_ALL_OUTLET_AREAS",
  CLEAR_OUTLET_AREAS = "CLEAR_OUTLET_AREAS",
}
export interface FetchAllAreaAction {
  type: AreaActionsList.FETCH_ALL_OUTLET_AREAS;
  data: areaType[];
}

export interface ClearAreaAction {
  type: AreaActionsList.CLEAR_OUTLET_AREAS;
}

export type OutletAreaActions =
  | ClearAreaAction
  | FetchAllAreaAction;

export const fetchAllOutletAreas = (portfolioId: string) => {
  return async (dispatch: Dispatch, getState: GetState) => {
    const token = getState().authUser?.["auth-token"]!;
    const config = getAxiosRequestConfig(token);
    return api
      .get<APIResponse<areaType[]>>(`outlet-areas/${portfolioId}`, config)
      .then((response) => {
        if (response.status === 200 && response.data.data) {
          dispatch<FetchAllAreaAction>({
            type: AreaActionsList.FETCH_ALL_OUTLET_AREAS,
            data: response.data.data,
          });
          return Promise.resolve(
            response.data.message ? response.data.message : "Fetch Successfull"
          );
        } else {
          // throw { response };
          throw new Error("Unable to fetch")
        }
      })
      .catch((error) => {
        return Promise.reject(
          error.response
            ? error.response.data.message
              ? error.response.data.message
              : "Unable to fetch"
            : "Unable to fetch"
        );
      });
  };
};

export const clearOutletAreas = () => {
  return async (dispatch: Dispatch, getState: GetState) => {
    return dispatch<ClearAreaAction>({
      type: AreaActionsList.CLEAR_OUTLET_AREAS,
    });
  };
};
