import jsPDF from 'jspdf';
import autoTable from 'jspdf-autotable';
import React from "react";
import { CSVLink } from 'react-csv';
// import LogoDark from '../custom/assets/images/logo-dark.png';
import downIcon from '../assets/images/dashboard/down-icon.svg';
import tableIcon from '../assets/images/dashboard/table_icon.svg';
import upIcon from '../assets/images/dashboard/up-icon.svg';

interface Props {
    data: string[][],
    title: string,
    fileName?: string,
    downloadPDF?: boolean | false,
    downloadCSV?: boolean | false
    percent?: boolean
    valueInString?: string
}
const SalesPerformanceTable: React.FC<Props> = (props: Props) => {
    const { data, title, fileName, downloadCSV, downloadPDF, valueInString, percent = true
    } = props;
    const head = data.filter((item, index) => index === 0)

    //const head = [['S.No', 'Date', 'Account Name', 'Particulars', 'Cash In', ' Cash Out', 'Balance']];

    const body = data.filter((item, index) => index !== 0)

    //         [['1', '01/01/2023', 'Test Account1', 'Opening Balance','15000', '13000', '2000'],
    //         ['2', '01/02/2023', 'Test Account2', 'Opening Balance','18000', '14000', '4000'],
    //         ['3', '01/03/2023', 'Test Account2', 'Opening Balance','12000', '8000', '4000'],
    //         ['4', '01/04/2023', 'Test Account1', 'Opening Balance','5000', '3000', '2000'],]

    const handleDownloadPDF = () => {
        const doc = new jsPDF('l', 'mm', [460, 210])
        doc.setFontSize(18)
        doc.text(title, 180, 13)
        autoTable(doc, {
            head: head,
            body: body,
            // foot: foot,
            startY: 20,
            didDrawPage: function () {
                // // Header
                var str = doc.getNumberOfPages();
                doc.setFontSize(10);
                // jsPDF 1.4+ uses getWidth, <1.4 uses .width
                // var pageSize = doc.internal.pageSize;
                // var pageHeight = pageSize.height
                //     ? pageSize.height
                //     : pageSize.getHeight();
                doc.text(str.toString(), 360, 10);
            }
        })
        doc.save(fileName + ".pdf")
    }

    return (
        <>
            <div className="row mb-2 mt-1 p-1">
                <div className="col-lg-6 col-sm-12">
                    {title !== '' && <h4 className="header-title mb-0">
                        <img src={tableIcon} alt="icon"></img>
                        {/* <img src="assets/images/chart-sales-performance.svg" alt="Sales Performance" /> */}
                        {title}
                    </h4>}
                </div>
            </div>

            <div className="row">
                <div className="col-4 col-sm-12 col-lg-6  float-start my-1">
                    {valueInString && <span className='text-secondary'>{'*Value in ' + (valueInString ? valueInString : '')}</span>}
                </div>
                <div className="col-lg-6 col-sm-12 my-1">
                    <div className="float-end">
                        {downloadPDF && <button type="button" onClick={handleDownloadPDF} className="btn btn-xs btn-pdf text-white me-1">
                            {/* <!--<i className="fe-download-cloud me-1"></i> --> */}
                            <svg width="17" height="16" viewBox="0 0 19 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M12.889 1.66651H2.66659C1.93023 1.66651 1.33325 2.16812 1.33325 2.78684V18.5462C1.33325 19.1649 1.93023 19.6665 2.66659 19.6665H15.9999C16.7363 19.6665 17.3332 19.1649 17.3332 18.5462V5.40106L12.889 1.66651Z" stroke="white" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"></path>
                                <path d="M12.8889 1.66644V5.40088H17.3334L12.8889 1.66644Z" stroke="white" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"></path>
                                <path d="M9.33328 10.4777L7.17981 13.6118H11.4867L9.33328 10.4777Z" stroke="white" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"></path>
                                <path d="M9.33324 10.478L10.3272 9.03153C10.7689 8.38861 10.2168 7.58499 9.33324 7.58499C8.44973 7.58499 7.89755 8.38861 8.33933 9.03153L9.33324 10.478Z" stroke="white" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"></path>
                                <path d="M11.4868 13.6119L12.4807 15.0584C12.9225 15.7012 14.0268 15.7012 14.4685 15.0584C14.9103 14.4155 14.3581 13.6119 13.4747 13.6119H11.4868Z" stroke="white" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"></path>
                                <path d="M7.1798 13.6119L6.18589 15.0584C5.74411 15.7012 4.6398 15.7012 4.19807 15.0584C3.75634 14.4155 4.30851 13.6119 5.19194 13.6119H7.1798Z" stroke="white" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"></path>
                            </svg>
                            PDF
                        </button>}

                        {downloadCSV && <CSVLink data={data} filename={fileName + ".csv"}><button type="button" className="btn btn-xs btn-csv text-white me-1">
                            {/* <!--<i className="fe-download-cloud me-1"></i>--> */}
                            <svg width="17" height="16" viewBox="0 0 19 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M11.7856 1V5C11.7856 5.26522 11.9061 5.51957 12.1204 5.70711C12.3347 5.89464 12.6254 6 12.9285 6H17.4999" stroke="white" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"></path>
                                <path d="M15.2143 19H3.78571C3.17951 19 2.59812 18.7893 2.16947 18.4142C1.74082 18.0391 1.5 17.5304 1.5 17V3C1.5 2.46957 1.74082 1.96086 2.16947 1.58579C2.59812 1.21071 3.17951 1 3.78571 1H11.7857L17.5 6V17C17.5 17.5304 17.2592 18.0391 16.8305 18.4142C16.4019 18.7893 15.8205 19 15.2143 19Z" stroke="white" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"></path>
                                <path d="M4.92847 9H14.0713V16H4.92847V9Z" stroke="white" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"></path>
                                <path d="M4.92847 13H14.0713" stroke="white" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"></path>
                                <path d="M8.35718 9V16" stroke="white" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"></path>
                            </svg>
                            CSV
                        </button>
                        </CSVLink>}
                    </div>
                </div>
            </div >
            {/*  */}
            {/* < div className='row' >
                <div className="col-lg-6 col-sm-12">
                    {title !== '' && <h5 className="header-title mb-3 float-start"><i className="fe-align-justify me-1 text-primary"></i>{title}</h5>}
                </div>
                <div className="col-lg-6 col-sm-12 text-end mb-1">
                    {downloadPDF && <button type="button" onClick={handleDownloadPDF} className={`me-1 btn btn-xs btn-secondary`}><Feather.DownloadCloud size={14} /> PDF</button>}
                    {downloadCSV && <CSVLink data={data} filename={fileName + ".csv"}> <button type="button" className={`me-1 btn btn-xs btn-secondary`}><Feather.DownloadCloud size={14} /> CSV</button></CSVLink>}
                </div>
            </div > */}
            {/* <hr className='hr mt-0' /> */}
            <div className='table-wrapper'>
                <table className="table table-hover table-bordered">
                    <thead>
                        <tr>
                            {head.at(0)?.map((col, i) => {
                                return (
                                    <th className='text-truncate' key={i}>{col}</th>
                                )
                            })
                            }
                        </tr>
                    </thead>
                    <tbody>
                        {body.length>0 ? body.map((row, i) => {
                            return (
                                <tr key={i + i}>
                                    {row.map((col, i) => {
                                        return (
                                            <>
                                                {percent && <td
                                                    //  className={i === 5 && parseFloat(col) > 0 ? 'text-success text-truncate' : (i === 5 && parseFloat(col) < 0 ? 'text-danger text-truncate' : '')}
                                                    // <FontAwesomeIcon icon="fa-sharp fa-regular fa-caret-up" />
                                                    // <FontAwesomeIcon icon={regular("caret-up")} />
                                                    // <i class="fa-sharp fa-regular fa-caret-up"></i>
                                                    key={i}>
                                                    <span className={i === 5 && parseFloat(col) > 0 ? ' text-truncate p-mtd' : (i === 5 && parseFloat(col) < 0 ? 'text-truncate n-mtd' : '')}>{i === 5 && parseFloat(col) > 0
                                                        ? <i>
                                                            <img src={upIcon} alt="icon"></img>
                                                            {/* <FontAwesomeIcon icon={faCaretUp} size="lg" /> */}
                                                            {col}
                                                        </i>
                                                        : (i === 5 && parseFloat(col) < 0
                                                            ? <i>
                                                                <img src={downIcon} alt="icon"></img>
                                                                {/* <FontAwesomeIcon icon={faCaretDown} size="lg" />  */}
                                                                {col}
                                                            </i>
                                                            : col)}</span>
                                                </td>}
                                                {percent === false && <td
                                                    className='text-truncate'
                                                    key={i}>
                                                    {col}</td>}
                                            </>
                                        )
                                    })}
                                </tr>
                            )
                        }): <><tr><td className='no-records-td' colSpan={head[0].length}>No Records Found</td></tr> </>
                        }
                    </tbody>
                </table>
            </div>

        </>
    )
}
export default SalesPerformanceTable;