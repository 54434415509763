import React, { useEffect, useState } from "react";
import OutletSalesPerformance from "./OutletSalesPerformanceWidget";
import Top10ProductsSales from "./Top10ProductsSalesWidget";
import Bottom10ProductsSales from "./Bottom10ProductsSalesWidget";
// import OutletDashboardSalesOfDayMonthWise from "./OutletDashboardSalesOfDayMonthWiseWidget";
import OutletSalesOfDayYearWise from "./OutletDashboardSalesOfDayYearWiseWidget";
import OutletFootfall from "./OutletDashboardFootfallWidget";
import FootfallvsSales from "./OutletDashboardFootfallVsSalesWidget";
import OutletATV from "./AverageTransactionValueWidget";
import OutletTransaction from "./NumberOfBillsWidget";


interface props {
    portfolioId: number;
    brandId: number;
    outletId: string[];    
    widgetLoader1?:React.Dispatch<React.SetStateAction<boolean>>;
    widgetLoader2?:React.Dispatch<React.SetStateAction<boolean>>;
    apiEnabler1?:boolean;
    apiEnabler2?:boolean;
    valueCapStr?: string;
}
const OutletBrandDashboardIndex = (props: props) => {
    const [isexpand1, setIsexpand1] = useState<boolean>(false);
    const [isexpand2, setIsexpand2] = useState<boolean>(false);
    const [isexpand3, setIsexpand3] = useState<boolean>(false);
    const [isexpand4, setIsexpand4] = useState<boolean>(false);
    const [isexpand5, setIsexpand5] = useState<boolean>(false);
    const [isexpand6, setIsexpand6] = useState<boolean>(false);
    const [isexpand7, setIsexpand7] = useState<boolean>(false);
    const [isexpand8, setIsexpand8] = useState<boolean>(false);
    const [loader1,setLoader1] = useState<boolean>(true)
    const [loader2,setLoader2] = useState<boolean>(true)
    const [commonApiEnabler,setCommonApiEnabler] = useState<boolean>(false)

    useEffect(()=>{
        if(loader1===false){
          props.widgetLoader1!((prevState)=>!prevState)
        }
      },[loader1])
      
      useEffect(()=>{
        if(loader2===false){
          props.widgetLoader2!((prevState)=>!prevState)
        }
      },[loader2])
    
    
      useEffect(()=>{
        if(loader1===false&&loader2===false){
          setCommonApiEnabler(true)
        }
      },[loader1,loader2])


    return (
        <>
        <div className="row">
            <div className={isexpand1 ? "col-xl-12 col-lg-12 col-md-12 me-0":"col-xl-6 col-lg-12 col-md-12 me-0"}>
                <OutletSalesPerformance
                    portfolioId={props.portfolioId}
                    brandId={props.brandId}
                    outletId={props.outletId}
                    expandState={setIsexpand1}
                    widgetLoader1={setLoader1}
                    apiEnabler1 = {props.apiEnabler1}
                    commonApiEnabler = {commonApiEnabler}
                    valueCapStr = {props.valueCapStr}
                />
            </div>
            <div className={isexpand2 ? "col-xl-12 col-lg-12 col-md-12 me-0":"col-xl-6 col-lg-12 col-md-12 me-0"}>
                <Top10ProductsSales
                    portfolioId={props.portfolioId}
                    brandId={props.brandId}
                    outletId={props.outletId}
                    expandState={setIsexpand2}
                    widgetLoader2={setLoader2}
                    apiEnabler2 = {props.apiEnabler2}
                    valueCapStr = {props.valueCapStr}
                />
            </div>
            <div className={isexpand3 ? "col-xl-12 col-lg-12 col-md-12 me-0": "col-xl-6 col-lg-12 col-md-12 me-0"}>
                <Bottom10ProductsSales
                    portfolioId={props.portfolioId}
                    brandId={props.brandId}
                    outletId={props.outletId}
                    expandState={setIsexpand3}
                    commonApiEnabler = {commonApiEnabler}
                    valueCapStr = {props.valueCapStr}
                />
            </div>
            <div className={isexpand4 ? "col-xl-12 col-lg-12 col-md-12 me-0":"col-xl-6 col-lg-12 col-md-12 me-0"}>
                <OutletATV
                    portfolioId={props.portfolioId}
                    brandId={props.brandId}
                    outletId={props.outletId}
                    expandState={setIsexpand4}
                    commonApiEnabler = {commonApiEnabler}
                    valueCapStr = {props.valueCapStr}
                />
            </div>
            <div className={isexpand5 ? "col-xl-12 col-lg-12 col-md-12 me-0":"col-xl-6 col-lg-12 col-md-12 me-0"}>
                <OutletTransaction
                    portfolioId={props.portfolioId}
                    brandId={props.brandId}
                    outletId={props.outletId}
                    expandState={setIsexpand5}
                    commonApiEnabler = {commonApiEnabler}
                    valueCapStr = {props.valueCapStr}
                />
            </div>
            {/* <div className="col-xl-6 col-lg-12 col-md-12 me-0">
                <OutletDashboardSalesOfDayMonthWise
                    portfolioId={props.portfolioId}
                    brandId={props.brandId}
                    outletId={props.outletId}
                />
            </div> */}
            <div className={isexpand6 ? "col-xl-12 col-lg-12 col-md-12 me-0":"col-xl-6 col-lg-12 col-md-12 me-0"}>
                <OutletSalesOfDayYearWise
                    portfolioId={props.portfolioId}
                    brandId={props.brandId}
                    outletId={props.outletId}
                    expandState={setIsexpand6}
                    commonApiEnabler = {commonApiEnabler}
                    valueCapStr = {props.valueCapStr}
                />
            </div>
            <div className={isexpand7 ? "col-xl-12 col-lg-12 col-md-12 me-0":"col-xl-6 col-lg-12 col-md-12 me-0"}>
                <OutletFootfall
                    portfolioId={props.portfolioId}
                    brandId={props.brandId}
                    outletId={props.outletId}
                    expandState={setIsexpand7}
                    commonApiEnabler = {commonApiEnabler}
                />
            </div>
            <div className={isexpand8 ? "col-xl-12 col-lg-12 col-md-12 me-0":"col-xl-6 col-lg-12 col-md-12 me-0"}>
                <FootfallvsSales
                    portfolioId={props.portfolioId}
                    brandId={props.brandId}
                    outletId={props.outletId}
                    expandState={setIsexpand8}
                    commonApiEnabler = {commonApiEnabler}
                />
            </div>
            </div>
        </>
    )
}
export default OutletBrandDashboardIndex