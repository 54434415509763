import { ApexOptions } from "apexcharts";
import Chart from "react-apexcharts";

interface dataSet {
  name: string;
  data: number[];
}

interface Iprops {
  datasets: dataSet[];
  labels: string[];
  title: string;
  colors: string[];
  subtitle?: string | "";
  exportfilename: string;
}

// SplineAreaChart
const SplineAreaChart = (props: Iprops) => {
  const { colors, datasets, labels, title, exportfilename, subtitle } = props;
  // default options
  const apexAreaChart1Opts: ApexOptions = {
    chart: {
      height: 380,
      type: "area",
      zoom: {
        enabled: false,
      },
      toolbar: {
        show: true,
        export: {
          csv: {
            filename: exportfilename,
          },
          png: {
            filename: exportfilename,
          },
          svg: {
            filename: exportfilename,
          },
        },
      },
    },
    title: {
      text: title,
      align: "center",
    },

    subtitle: {
      text: subtitle,
      align: "left",
      offsetY: 22,
      margin: 14,
    },
    dataLabels: {
      enabled: true,
    },
    stroke: {
      width: 3,
      curve: "smooth",
    },
    colors: colors,
    legend: {
      showForSingleSeries: true,
      offsetY: -10,
    },
    xaxis: {
      categories: labels,
    },
    tooltip: {
      fixed: {
        enabled: false,
        position: "topRight",
      },
    },
    grid: {
      row: {
        colors: ["transparent", "transparent"], // takes an array which will be repeated on columns
        opacity: 0.2,
      },
      borderColor: "#f1f3fa",
    },
  };

  // chart data
  // const apexAreaChart1Data = [
  //     {
  //         name: 'Series 1',
  //         data: [31, 40, 28, 51, 42, 109, 100],
  //     },
  //     {
  //         name: 'Series 2',
  //         data: [11, 32, 45, 32, 34, 52, 41],
  //     },
  // ];

  return (
    <>
      {/*<h4 className="header-title mb-3">{title}</h4>*/}
      <Chart
        options={apexAreaChart1Opts}
        series={datasets}
        type="area"
        className="apex-charts"
        height={400}
      />
      {/* </Card.Body> */}
    </>
  );
};

export default SplineAreaChart;
