import { combineReducers } from "redux";
import authUserReducer from "./authUserReducer";
import { BrandCompanyReducer } from "./brandCompanyReducer";
import { BrandReducer } from "./brandReducer";
import { BuildingReducer } from "./buildingReducer";
import { CountryReducer } from "./countryReducer";
import { FloorReducer } from "./floorReducer";
import { PortfolioReducer } from "./portfolioReducer";
import { RoleReducer } from "./roleReducer";
import { SiteReducer } from "./siteReducer";
import { TradeCategoryReducer } from "./tradeCategoryReducer";
import { TradeSubCategoryReducer } from "./tradeSubCategoryReducer";
import { UsersReducer } from "./usersReducer";
import { SelectedOptionsReducer } from "./selectedOptionsReducer";
import { BrandCompanyMappingReducer } from "./brandCompanyMappingReducer";
import { OutletReducer } from "./outletReducer";
import { SessionReducer, RegionReducer, StateReducer, CityReducer } from "./commonReducer";
import { AreaReducer } from "./areaReducer";
import { LogUserReducer } from "./logUserReducer";

const reducers = combineReducers({
  authUser: authUserReducer,
  portfolio: PortfolioReducer,
  site: SiteReducer,
  brand: BrandReducer,
  brandCompany: BrandCompanyReducer,
  brandCompanyMapping: BrandCompanyMappingReducer,
  tradeCategory: TradeCategoryReducer,
  tradeSubCategory: TradeSubCategoryReducer,
  outlet: OutletReducer,
  users: UsersReducer,
  country: CountryReducer,
  role: RoleReducer,
  building: BuildingReducer,
  floor: FloorReducer,
  selectedOption: SelectedOptionsReducer,
  region: RegionReducer,
  state: StateReducer,
  city: CityReducer,
  session: SessionReducer,
  locality: AreaReducer,
  LogUserReducer: LogUserReducer,
});

export default reducers;
