import React, { ChangeEventHandler, useEffect, useState, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import Option from "../components/Option";
import FormInput2 from "../components/custom/components/FormInput2";
import BrandDashboardIndex from "../components/retailBrandDashboard";
import api, { APIResponse, getAxiosRequestConfig } from "../config/api";
import { AuthUserType } from "../redux/actions/authUserActions";
import { PortfolioType } from "../redux/actions/portfolioActions";
import { CountryType } from "../redux/actions/countryActions";
import {
  RegionType,
  StateType,
  CityType,
} from "../redux/actions/commonActions";
import { AppDispatch, StoreState } from "../redux/store";
import { TradeCategoryType } from "../redux/actions/tradeCategoryActions";
import { TradeSubCategoryType } from "../redux/actions/tradeSubCategoryActions";
import { OptionsTypes } from "../redux/actions/selectedOptionsActions";
import Dashloader from "../components/custom/components/DashLoader";
import { UserLog } from "../config/common";
import { DropDownOption1, defaultTradeCategory, defaultTradeSubCategory } from "../config";
import SingleSelect from "../components/custom/components/SingleSelect";
import { DropDownOption } from "./TransactionBillWiseReport";
import SingleSelect1 from "../components/custom/components/SingleSelect1";

interface Props {}
interface RecordsForMonth {
  sales: string;
  transyear: number;
  transmonth: number;
  momgrowth: number | null;
  mtd: string;
  mtdpercentchange: number | null;
  averagesale: string;
  averagerate: number;
  averageratepercent: string;
  pfcurrcode: string | "";
}
interface RecordsForYear {
  sales: string;
  transyear: number;
  yoygrowth: number | null;
  ytd: number;
  ytdpercentchange: number | null;
  averagesale: number;
  averagerate: number;
  averageratepercent: number;
  pfcurrcode: string | "";
}

interface MonthlyRecord {
  portfolio: number;
  record: RecordsForMonth[];
}
interface YearlyRecord {
  portfolio: number;
  record: RecordsForYear[];
}

interface retailCardResponse {
  monthlySales:{
    sales: string;
    transyear: number;
    transmonth: number;
    momgrowth: number | null;
    mtd: string;
    mtdpercentchange: number | null;
    averagesale: string;
    averagerate: number;
    averageratepercent: string;
    pfcurrcode: string | "";
  }[];
  yearlySales:{
    sales: string;
    transyear: number;
    yoygrowth: number | null;
    ytd: number;
    ytdpercentchange: number | null;
    averagesale: number;
    averagerate: number;
    averageratepercent: number;
    pfcurrcode: string | "";
}[]
}

interface retailRecords {
  data: {
    portfolio: number;
    records: retailCardResponse;
  }
}

const RetailBrandDashboard: React.FC<Props> = (props: Props) => {
  const dispatch = useDispatch<AppDispatch>();
  const authUser = useSelector<StoreState, AuthUserType | null>(
    (state) => state?.authUser
  );
  const authToken = authUser?.["auth-token"]!;
  const config = getAxiosRequestConfig(authToken);

  const authUserPfId = authUser?.portfolioId;
  const authUserSiteId = authUser?.siteId;

  const selectedOption = useSelector<StoreState, OptionsTypes>(
    (state) => state?.selectedOption
  );
  const countryList = useSelector<StoreState, Array<CountryType>>(
    (state) => state?.country
  );
  const regionList = useSelector<StoreState, Array<RegionType>>(
    (state) => state?.region
  );
  const stateList = useSelector<StoreState, Array<StateType>>(
    (state) => state?.state
  );
  const cityList = useSelector<StoreState, Array<CityType>>(
    (state) => state?.city
  );
  const portfolioOption = useSelector<StoreState, PortfolioType[]>(
    (state) => state?.portfolio
  );
  // if single user has access of multiple portfolio
  /*const port = authUser?.portfolioId !== undefined && authUser?.portfolioId !== null
        ? authUser?.portfolioId?.split(',')
        : []

    let portfolioData = port?.map((pe) => {
        return portfolioOption?.filter(
            (f) => f?.id?.toString() === pe?.toString()
        )
    })?.flat(1)*/

  let portfolioData = portfolioOption?.filter(
    (pf) => pf?.id?.toString() === authUserPfId?.toString()
  );
  const categoryOption = useSelector<StoreState, TradeCategoryType[]>(
    (state) => state?.tradeCategory
  );
  const subCategoryOption = useSelector<StoreState, TradeSubCategoryType[]>(
    (state) => state?.tradeSubCategory
  );
  const [portfolio, setPortfolio] = useState<string>("");
  const [pfName, setPfName] = useState<string>("");
  const [pfcurrcode, setPfcurrCode] = useState<string>("");
  const [valueCaption, setValueCaption] = useState<string>("");

  const [country, setCountry] = useState<string>("");
  const [region, setRegion] = useState<string>("");
  const [state, setState] = useState<string>("");
  const [city, setCity] = useState<string>("");
  const [category, setCategory] = useState<string>("");
  const [subcategory, setSubcategory] = useState<string>("");

  const [loader1, setLoader1] = useState<boolean>(true);

  const [categoriesOption, setCategoriesOption] = useState<DropDownOption1>(defaultTradeCategory);
  const [subCategoriesOption, setSubcategoriesOption] = useState<DropDownOption1>(defaultTradeSubCategory);

  const [catDrop,setCatDrop] = useState<DropDownOption[]>([])
  const [subcatDrop,setSubcatDrop] = useState<DropDownOption[]>([])

  const [cardDataRes, setCardDataRes] = useState<retailRecords[]>([]);


  
  const handleCategoryChange = (e:DropDownOption1) => {
      setCategory(e?.value?.toString());
      setCategoriesOption(e)
      setSubcategory("");
      setSubcategoriesOption(defaultTradeSubCategory)
      setLoader1(true)
      setWidgetLoader1(true)
      setWidgetLoader2(true)
  };

  const handleSubCategoryChange = (e:DropDownOption1) => {
      setSubcategory(e?.value?.toString());
      setSubcategoriesOption(e)
      setLoader1(true)
      setWidgetLoader1(true)
      setWidgetLoader2(true)
  };


  const handleCatDrop = (selected: any) => {
    if(selected.length>0){
      const selectedCat = selected as DropDownOption[];
      if(selectedCat[0].value!==null&&selectedCat[0].value!==0){
      setCategory(selectedCat[0]?.value?.toString());
      setCatDrop(selectedCat)
      setSubcategory("");
      setSubcatDrop([])
      setLoader1(true)
      setWidgetLoader1(true)
      setWidgetLoader2(true)
      }
    }
  };
  
  const handleClearCat = () =>{
    setCatDrop([])
    setCategory('')
    setSubcatDrop([])
    setSubcategory("");
    setLoader1(true)
    setWidgetLoader1(true)
    setWidgetLoader2(true)
  }

  const handleSubcatDrop = (selected: any) => {
    if(selected.length>0){
      const selectedSubcat = selected as DropDownOption[];
      if(selectedSubcat[0].value!==null&&selectedSubcat[0].value!==0){
        setSubcatDrop(selectedSubcat)
        setSubcategory(selectedSubcat[0]?.value?.toString())
        setLoader1(true)
        setWidgetLoader1(true)
        setWidgetLoader2(true)
      }
    }
  };
  
  const handleClearSubcat = () =>{
      setSubcatDrop([])
      setSubcategory('')
      setLoader1(true)
      setWidgetLoader1(true)
      setWidgetLoader2(true)
  }


  const [yearlyData, setYearlyData] = useState<YearlyRecord[]>([]);
  const [monthlyData, setMonthlyData] = useState<MonthlyRecord[]>([]);
  const [salesYTD, setSalesYTD] = useState<string>("");
  const [salesYTDPercent, setSalesYTDPercent] = useState<string>("");
  const [prevYearSalesYTD, setPrevYearSalesYTD] = useState<string>("");

  const [salesMTD, setSalesMTD] = useState<string>("");
  const [salesMTDPercent, setSalesMTDPercent] = useState<string>("");
  const [prevMonthSalesMTD, setPrevMonthSalesMTD] = useState<string>("");

  const [widgetLoader1,setWidgetLoader1] = useState<boolean>(true)
  const [widgetLoader2,setWidgetLoader2] = useState<boolean>(true)

  const currentYear = new Date()?.getFullYear();
  const currentMonth = new Date()?.getMonth(); //0-11

  const site = useMemo<string[]>(() => {
    if (authUserSiteId) {
      return authUserSiteId?.split(",")?.map((s) => {
        return s?.toString();
      });
    } else {
      return [];
    }
  }, [authUserSiteId]);

  const monthShort = (monthnum: number, type: number): string => {
    var CurrntMonth: string[] = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ];
    var CurrntMon: string[] = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];
    let mon_name =
      type === 1 ? CurrntMon[monthnum] : type === 2 ? CurrntMonth[monthnum] : "";
    return mon_name;
  };

  const subCategoryFilteredByCategory = useMemo<TradeSubCategoryType[]>(() => {
    if (category !== "") {
      return subCategoryOption?.filter((sc) => {
        return sc?.catid?.toString() === category?.toString();
      });
    } else return subCategoryOption;
  }, [category, subCategoryOption]);

  useEffect(() => {
    let pid =
      portfolioData[0] && portfolioData[0]?.id
        ? portfolioData[0]?.id?.toString()
        : "";
    let pname =
      portfolioData[0] && portfolioData[0]?.name
        ? portfolioData[0]?.name?.toString()
        : "";
    setPortfolio(pid);
    setPfName(pname);
  }, [portfolioData]);

  // useEffect(() => {
  //   var getData = {
  //     portfolio: [parseInt(portfolio)],
  //     site: site ? site : [],
  //     tradeCategory: category ? [parseInt(category)] : [],
  //     tradeSubCategory: subcategory ? [parseInt(subcategory)] : [],
  //     country: selectedOption?.country ? [parseInt(selectedOption?.country)] : [],
  //     region: selectedOption?.region ? [parseInt(selectedOption?.region)] : [],
  //     state: selectedOption?.state ? [parseInt(selectedOption?.state)] : [],
  //     city: selectedOption?.city ? [parseInt(selectedOption?.city)] : [],
  //   };
  //   if (getData?.portfolio?.length > 0 && portfolio && getData !== undefined) {
  //     api
  //       .post<APIResponse<{ records: MonthlyRecord[] }>>(
  //         `kpi/retail/sales/monthly`,
  //         getData,
  //         config
  //       )
  //       .then((response) => {
  //         if (response?.status === 200 && response?.data?.data) {
  //           const result = response?.data?.data?.records;
  //           setMonthlyData(result);
  //           setLoader2(false)
  //         }
  //       })
  //       .catch((error) => {});
  //   } else {
  //     setMonthlyData([]);
  //     if (getData?.portfolio?.length > 0 && portfolio && getData !== undefined) {
  //       setLoader2(false)
  //     }
  //   }
  // }, [
  //   portfolio,
  //   category,
  //   subcategory,
  //   selectedOption?.country,
  //   selectedOption?.region,
  //   selectedOption?.city,
  //   selectedOption?.state,
  // ]);

  // useEffect(() => {
  //   var getData = {
  //     portfolio: [parseInt(portfolio)],
  //     site: site ? site : [],
  //     tradeCategory: category ? [parseInt(category)] : [],
  //     tradeSubCategory: subcategory ? [parseInt(subcategory)] : [],
  //     country: selectedOption?.country ? [parseInt(selectedOption?.country)] : [],
  //     region: selectedOption?.region ? [parseInt(selectedOption?.region)] : [],
  //     state: selectedOption?.state ? [parseInt(selectedOption?.state)] : [],
  //     city: selectedOption?.city ? [parseInt(selectedOption?.city)] : [],
  //   };
  //   if (getData?.portfolio?.length > 0 && portfolio && getData !== undefined) {
  //     api
  //       .post<APIResponse<{ records: YearlyRecord[] }>>(
  //         `kpi/retail/sales/yearly`,
  //         getData,
  //         config
  //       )
  //       .then((response) => {
  //         if (response?.status === 200 && response?.data?.data) {
  //           const result = response?.data?.data?.records;
  //           setYearlyData(result);
  //           setLoader1(false)
  //         }
  //       })
  //       .catch((error) => {});
  //   } else {
  //     setYearlyData([]);
  //     if (getData?.portfolio?.length > 0 && portfolio && getData !== undefined) {
  //       setLoader1(false)
  //     }
  //   }
  // }, [
  //   portfolio,
  //   category,
  //   subcategory,
  //   selectedOption?.country,
  //   selectedOption?.region,
  //   selectedOption?.city,
  //   selectedOption?.state,
  // ]);

  // useEffect(() => {
  //   if (yearlyData?.length > 0) {
  //     let obj = yearlyData[0]?.record?.find((y) => y?.transyear === currentYear);
  //     setSalesYTD(obj?.ytd ? obj?.ytd?.toString() : "0");
  //     setSalesYTDPercent(
  //       obj?.ytdpercentchange !== null && obj?.ytdpercentchange
  //         ? obj?.ytdpercentchange?.toString()
  //         : ""
  //     );

  //     let lastYearObj = yearlyData[0]?.record?.find(
  //       (y) => y?.transyear === currentYear - 1
  //     );
  //     setPrevYearSalesYTD(lastYearObj?.ytd ? lastYearObj?.ytd?.toString() : "");
  //     setPfcurrCode(obj?.pfcurrcode ? obj?.pfcurrcode?.toString() : "USD");
  //   }
  // }, [yearlyData, currentYear]);


  // useEffect(() => {
  //   if (monthlyData?.length > 0) {
  //     let arr = monthlyData[0]?.record?.filter(
  //       (y) => y?.transyear === currentYear
  //     );
  //     let arr2 = monthlyData[0]?.record?.filter(
  //       (y) => y?.transyear === currentYear - 1
  //     );
  //     const obj = arr?.find((a) => a?.transmonth === currentMonth + 1);
  //     setSalesMTD(obj?.mtd ? obj?.mtd?.toString() : "0");
  //     setSalesMTDPercent(
  //       obj?.mtdpercentchange !== null && obj?.mtdpercentchange
  //         ? obj?.mtdpercentchange?.toString()
  //         : ""
  //     );
  //     const lastYearObj = arr2?.find((a) => a?.transmonth === currentMonth + 1);
  //     setPrevMonthSalesMTD(lastYearObj?.mtd ? lastYearObj?.mtd : "");
  //     setPfcurrCode(obj?.pfcurrcode ? obj?.pfcurrcode?.toString() : "USD");
  //   }
  // }, [monthlyData, currentMonth, currentYear]);

  useEffect(() => {
    let couid = countryList?.filter(
      (a) => a?.id?.toString() === selectedOption?.country?.toString()
    );

    if (couid?.length > 0 && couid[0]?.id !== undefined) {
      setCountry(couid[0]?.id?.toString());
    } else {
      setCountry("");
    }
  }, [country, countryList, selectedOption]);

  useEffect(() => {
    let regid = regionList?.filter(
      (a) => a?.id?.toString() === selectedOption?.region?.toString()
    );

    if (regid?.length > 0 && regid[0]?.id !== undefined) {
      setRegion(regid[0]?.id?.toString());
    } else {
      setRegion("");
    }
  }, [region, regionList, selectedOption]);

  useEffect(() => {
    let statid = stateList?.filter(
      (a) => a?.id?.toString() === selectedOption?.state?.toString()
    );

    if (statid?.length > 0 && statid[0]?.id !== undefined) {
      setState(statid[0]?.id?.toString());
    } else {
      setState("");
    }
  }, [state, stateList, selectedOption]);

  useEffect(() => {
    let citid = cityList?.filter(
      (a) => a?.id?.toString() === selectedOption?.city?.toString()
    );

    if (citid?.length > 0 && citid[0]?.id !== undefined) {
      setCity(citid[0]?.id?.toString());
    } else {
      setCity("");
    }
  }, [city, cityList, selectedOption]);


  useEffect(()=>{
     
       if(authUser){
         const logProps1 = {
           action:`Visit`,
           event: 'Retail Dashboard page | Visited '
        };
        UserLog(logProps1)
       }
     
   },[])


  useEffect(() => {
    if (cardDataRes[0]?.data?.records?.yearlySales?.length > 0) {
        let obj = cardDataRes[0]?.data?.records?.yearlySales?.find((y) => y?.transyear?.toString() === currentYear?.toString());
        setSalesYTD(obj?.ytd ? obj?.ytd?.toString() : "0");

        let lastYearObj = cardDataRes[0]?.data?.records?.yearlySales?.find(
        (y) => y?.transyear === currentYear - 1
        );
        setPrevYearSalesYTD(lastYearObj?.ytd ? lastYearObj?.ytd?.toString() : "");

        setSalesYTDPercent(
          obj?.ytdpercentchange !== null && obj?.ytdpercentchange
            ? obj?.ytdpercentchange?.toString()
            : ""
        );
        
    }else{
      setSalesYTD("0");
      setPrevYearSalesYTD("");
      setSalesYTDPercent( "");
    }

    if (cardDataRes[0]?.data?.records?.monthlySales?.length > 0) {

      let arr = cardDataRes[0]?.data?.records?.monthlySales?.filter(
        (y) => y?.transyear === currentYear
        );
        let arr2 = cardDataRes[0]?.data?.records?.monthlySales?.filter(
        (y) => y?.transyear === currentYear - 1
        );
      const obj = arr?.find((a) => a?.transmonth === currentMonth + 1);
      setSalesMTD(obj?.mtd ? obj?.mtd?.toString() : "0");
      setSalesMTDPercent(
        obj?.mtdpercentchange !== null && obj?.mtdpercentchange
          ? obj?.mtdpercentchange?.toString()
          : ""
      );
      const lastYearObj = arr2?.find((a) => a?.transmonth === currentMonth + 1);
      setPrevMonthSalesMTD(lastYearObj?.mtd ? lastYearObj?.mtd : "");

    }else{
      setSalesMTD("0");
      setSalesMTDPercent("");
      setPrevMonthSalesMTD("");

    }
}, [currentMonth, currentYear,cardDataRes]);

useEffect(()=>{
  setPfcurrCode(portfolioData[0]?.reportingcurrency?.toString()??'')
  setValueCaption(portfolioData[0]?.valuecaption?.toString()??'')
},[portfolioOption])


  useEffect(() => {
    var getData = {
      portfolio: [parseInt(portfolio)],
      site: site ? site : [],
      tradeCategory: category ? [parseInt(category)] : [],
      tradeSubCategory: subcategory ? [parseInt(subcategory)] : [],
      country: selectedOption?.country ? [parseInt(selectedOption?.country)] : [],
      region: selectedOption?.region ? [parseInt(selectedOption?.region)] : [],
      state: selectedOption?.state ? [parseInt(selectedOption?.state)] : [],
      city: selectedOption?.city ? [parseInt(selectedOption?.city)] : [],
      authUserRole: authUser?.roleId ? authUser?.roleId : "",
    };
    if (getData?.portfolio?.length > 0 && portfolio && getData !== undefined) {
    setLoader1(true)
    setWidgetLoader1(true)
    setWidgetLoader2(true)
      api
        .post<APIResponse<{ records: retailRecords[] }>>(
          `kpi/retail/dashboard/cardetails`,
          getData,
          config
        )
        .then((response) => {
          if (response?.status === 200 && response?.data?.data) {
            const result = response?.data?.data?.records;
            setCardDataRes(result);
            setLoader1(false)
          }
          else {
            setCardDataRes([]);
            setLoader1(false)
          }
        })
        .catch((error) => {});
    } 
  }, [
    portfolio,
    category,
    subcategory,
    selectedOption?.country,
    selectedOption?.region,
    selectedOption?.city,
    selectedOption?.state,
  ]);



  return (
    <>
      <div className="card background-transparent">
        <div className="row my-1">
          <div className="wow fadeInLeft ps-0 col-lg-8">
            {/* <h4 className="b-title">{pfName} Dashboard</h4> */}
            <h4 className="b-title">Portfolio Retail Performance</h4>
          </div>
          <div className="col-lg-4 wow fadeInRight">
            <div className="row mt-1">
              <div className="col-sm-6 col-md-6 col-lg-6">
                <div className="row">
                  <div className="col-lg-12">
                    {/* <FormInput2
                      name="Category"
                      label=""
                      labelClassName="required"
                      value={category}
                      onChange={handleCategoryChange}
                      placeholder="Category"
                      containerClass=""
                      type="select"
                    >
                      <option value="">Select Category</option>
                      {categoryOption?.map((p, i) => {
                        return (
                          <Option value={p?.id} key={i}>
                            {p?.name}
                          </Option>
                        );
                      })}
                    </FormInput2> */}
                    {/* <SingleSelect
                  options={[
                    defaultTradeCategory,
                    ...categoryOption?.sort(function (a, b) {
                      if (a?.name < b?.name) {
                        return -1;
                      }
                      if (a?.name > b?.name) {
                        return 1;
                      }
                      return 0;
                    })?.map((s, i) => {
                      return (
                        {
                          value: s?.id ? s?.id?.toString() : '',
                          label: s?.name,
                          imgUrl: '',
                        }
                      );
                    })
                  ]}
                  label=""
                  selectedOptions={categoriesOption}
                  setSelectedOptions={handleCategoryChange}
                  // disabled={disableSite}
                  placeHolder="Select Trade Category"
                  // filterText=""
                /> */}
                   <SingleSelect1
                      options={categoryOption.map((c, i) => {
                        return {
                          value: c?.id?c?.id:0,
                          label: c?.name?c?.name:'',
                          imgUrl: "",
                        };
                      })}
                      selectedOptions={catDrop}
                      setSelectedOptions={handleCatDrop}
                      placeHolder="Select Trade Category"
                      onClear={handleClearCat}
                      disabled = {false}
                    />
                  </div>
                </div>
              </div>
              <div className="col-sm-6 col-md-6 col-lg-6">
                <div className="row">
                  <div className="col-lg-12">
                    {/* <FormInput2
                      name="Subcategory"
                      label=""
                      labelClassName="required"
                      value={subcategory}
                      onChange={handleSubcategoryChange}
                      placeholder="Subcategory"
                      containerClass=""
                      type="select"
                    >
                      <option value="">Select Subcategory</option>
                      {subCategoryFilteredByCategory?.map((p, i) => {
                        return (
                          <Option value={p?.id} key={i}>
                            {p?.name}
                          </Option>
                        );
                      })}
                    </FormInput2> */}
                     {/* <SingleSelect
                  options={[
                    defaultTradeSubCategory,
                    ...subCategoryFilteredByCategory?.sort(function (a, b) {
                      if (a?.name < b?.name) {
                        return -1;
                      }
                      if (a?.name > b?.name) {
                        return 1;
                      }
                      return 0;
                    })?.map((s, i) => {
                      return (
                        {
                          value: s?.id ? s?.id?.toString() : '',
                          label: s?.name,
                          imgUrl: '',
                        }
                      );
                    })
                  ]}
                  label=""
                  selectedOptions={subCategoriesOption}
                  setSelectedOptions={handleSubCategoryChange}
                  // disabled={disableSite}
                  placeHolder="Select Trade Sub Category"
                  // filterText=""
                /> */}
                  <SingleSelect1
                      options={subCategoryFilteredByCategory.map((c, i) => {
                        return {
                          value: c?.id?c?.id:0,
                          label: c?.name?c?.name:'',
                          imgUrl: "",
                        };
                      })}
                      selectedOptions={subcatDrop}
                      setSelectedOptions={handleSubcatDrop}
                      placeHolder="Select Trade Sub Category"
                      onClear={handleClearSubcat}
                      disabled = {false}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="row rb-dash">
        <div className="col-sm-6  col-lg-3">
          <div
            className="sales-box sales-ytd wow fadeInRight"
            data-wow-duration="2s"
          >
            <div className="sales-head">
              <div>
              <h3>Sales {currentYear} YTD</h3>
              <p>
                {valueCaption ? "(" + valueCaption + ")" : ""}
              </p>
              </div>
              

              {Number(salesYTDPercent) !== 0 ? (
                <button
                  className={
                    parseFloat(salesYTDPercent) > 0
                      ? "btn btn-positive"
                      : "btn btn-negative"
                  }
                >
                  <i
                    className={
                      parseFloat(salesYTDPercent) > 0
                        ? "fe-arrow-up-right"
                        : "fe-arrow-down-right"
                    }
                  >
                    {salesYTDPercent}%{" "}
                  </i>
                </button>
              ) : (
                <button className={"btn btn-card-empty"}>
                  <i> </i>
                </button>
              )}
            </div>
            <h2>
              {salesYTD}
              <span className="currCodeSpan">
                {pfcurrcode ? " (" + pfcurrcode + ")" : " "}
              </span>
            </h2>
            {Number(prevYearSalesYTD) !== 0 ? (
              <p>
                Last Year Sales:{prevYearSalesYTD}
                <span className="currCodeSpan">
                  {pfcurrcode ? " (" + pfcurrcode + ")" : " "}
                </span>
              </p>
            ) : (
              <p>Last Year Sales:</p>
            )}
          </div>
        </div>
        <div className="col-sm-6 col-lg-3">
          <div
            className="sales-box sales-mtd wow fadeInRight"
            data-wow-duration="2s"
          >
            <div className="sales-head">
              <div>
              <h3>
                Sales {currentYear} {monthShort(currentMonth, 1)} MTD
              </h3>
              <p>
                {valueCaption ? "(" + valueCaption + ")" : ""}
              </p>
              </div>
              
              {Number(salesMTDPercent) !== 0 ? (
                <button
                  className={
                    parseFloat(salesMTDPercent) > 0
                      ? "btn btn-positive"
                      : "btn btn-negative"
                  }
                >
                  <i
                    className={
                      parseFloat(salesMTDPercent) > 0
                        ? "fe-arrow-up-right"
                        : "fe-arrow-down-right"
                    }
                  >
                    {salesMTDPercent}%{" "}
                  </i>
                </button>
              ) : (
                <button className={"btn btn-card-empty"}>
                  <i> </i>
                </button>
              )}
            </div>
            <h2>
              {Number(salesMTD) !== 0 ? salesMTD : 0}
              <span className="currCodeSpan">
                {pfcurrcode ? " (" + pfcurrcode + ")" : " "}
              </span>
            </h2>
            {Number(prevMonthSalesMTD) !== 0 ? (
              <p>
                Last Year {monthShort(currentMonth, 1)} Sales:
                {prevMonthSalesMTD}
                <span className="currCodeSpan">
                  {pfcurrcode ? " (" + pfcurrcode + ")" : " "}
                </span>
              </p>
            ) : (
              <p>Last Year {monthShort(currentMonth, 1)} Sales:</p>
            )}
          </div>
        </div>
      </div>

      
     { (widgetLoader1!==false||widgetLoader2!==false||loader1!==false)&&<Dashloader/>}
     
     {loader1===false && <BrandDashboardIndex
        portfolioId={parseInt(portfolio)}
        siteId={site ? site : []}
        tradeCategory={category ? [parseInt(category)] : []}
        tradeSubCategory={subcategory ? [parseInt(subcategory)] : []}
        country={
          selectedOption?.country ? [parseInt(selectedOption?.country)] : []
        }
        region={selectedOption?.region ? [parseInt(selectedOption?.region)] : []}
        state={selectedOption?.state ? [parseInt(selectedOption?.state)] : []}
        city={selectedOption?.city ? [parseInt(selectedOption?.city)] : []}
        widgetLoader1={setWidgetLoader1}
        widgetLoader2={setWidgetLoader2}
        apiEnabler1 = {loader1}
        apiEnabler2 = {loader1}
      />
      }
    </>
  );
};
export default RetailBrandDashboard;
