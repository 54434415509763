import { areaType, AreaActionsList } from "../actions/areaActions";
import { Action } from "../actions/types";

const initialValue: Array<areaType> = [];

export const AreaReducer = (
  state: areaType[] = initialValue,
  action: Action
) => {
  switch (action.type) {

    case AreaActionsList.FETCH_ALL_OUTLET_AREAS: {
      return action.data;
    }

    case AreaActionsList.CLEAR_OUTLET_AREAS: {
      return initialValue;
    }

    default:
      return state;
  }
};
