import { useEffect, useRef, useState } from "react";
import { Form } from "react-bootstrap";
import { Typeahead } from "react-bootstrap-typeahead";
import DropDnSVG from "./DropDownSvg";

interface OptProps {
    value: number|string
    label: string
    imgUrl?: string
}

interface SingleSelectProps {
    options: OptProps[]
    label?: string
    selectedOptions: any
    setSelectedOptions: React.Dispatch<any>
    disabled?: boolean
    onChange?: () => void
    placeHolder?: string
    labelKey?:string
    onClear?: () => void
}

const SingleSelect1: React.FC<SingleSelectProps>= (props: SingleSelectProps) =>{

    const { options, selectedOptions, setSelectedOptions,onClear,
         disabled, onChange, placeHolder} = props;

    const typeaheadRef = useRef<any>(null);
    const [isDropdownOpen, setIsDropdownOpen] = useState(false);
    const handleChange = (selected: any) => {
        // setIsDropdownOpen(true); 
        if(selected.length>0){
            setSelectedOptions(selected)
        }else{
            setSelectedOptions([])
        }
    }

    const handleInputChange = (input: string) => {
        if (input === ''&&onClear&&selectedOptions.length!==0) {
            onClear()
        }
    };
    const handleClick = () => {
        setIsDropdownOpen(!isDropdownOpen); // Toggle dropdown on click
    };
    

    return(
        <>
           <Form.Group className='mb-1 sngl-select-new' >
           <Typeahead
                id="basic-typeahead-single"
                labelKey='label'
                options={options}
                placeholder={placeHolder}
                selected={selectedOptions}
                align="left"
                onChange={handleChange} 
                maxResults={500}
                multiple={false}                 
                inputProps={{
                    className: 'form-select3-new',
                    readOnly:selectedOptions.length>0,
                    style: {
                        'padding': '15px !important',
                        'fontFamily': `'Roboto', sans-serif !important`
                    }
                }}
                onBlur={() => {
                    if (!selectedOptions.length) {
                    typeaheadRef.current.clear();
                    }
                    // setIsDropdownOpen(false); 
                }}
                // minLength={0}
                // filterBy={() => true}
                {...(selectedOptions.length > 0 && { filterBy: () => true })}
                onFocus={handleClick}
                onInputChange={handleInputChange}
                ref={typeaheadRef}
                clearButton
                // open={isDropdownOpen} 


                renderInput={({ inputRef, ...inputProps }) => (
                    <input
                    {...inputProps}
                    ref={inputRef}
                    autoComplete="off" 
                    />
                )}                
                emptyLabel = 'No Options'
            />
            
            <div className=" dropdown-indicatorContainer" aria-hidden="true" >
                <DropDnSVG />
            </div>
           </Form.Group>           
        </>
    )
}


export default SingleSelect1