import { nanoid } from "@reduxjs/toolkit";
import React, { ChangeEventHandler, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Option from "../components/Option";
import chartIcon from "../components/custom/assets/images/dashboard/chart_icon.svg";
import tableIcon from "../components/custom/assets/images/dashboard/table_icon.svg";
import { FormInput } from "../components/custom/components";
import FormInput2 from "../components/custom/components/FormInput2";
import {
  Tbody,
  Thead,
} from "../components/custom/components/TradeCategoryPerformanceTable";
import TransactionTable from "../components/custom/components/TransactionTable";
import LineChart from "../components/custom/components/charts/apex/LineChart";
import { COLORS } from "../components/custom/constants";
import UserLayout from "../components/custom/layouts/UserLayout";
import { ToastTypes, useToast } from "../components/toast/ToastProvider";
import { defaultLocation, defaultPortfolio, DropDownOption1, PAGE_SIZE } from "../config";
import api, { APIResponse, getAxiosRequestConfig } from "../config/api";
import {
  CSVdownload,
  getYesterday,
  handleDownloadPDF,
} from "../config/functions";
import { AuthUserType } from "../redux/actions/authUserActions";
import { BrandType } from "../redux/actions/brandActions";
import { PortfolioType } from "../redux/actions/portfolioActions";
import {
  OptionsTypes,
  addToSelectedOption,
} from "../redux/actions/selectedOptionsActions";
import { SiteType } from "../redux/actions/siteActions";
import { TradeSubCategoryType } from "../redux/actions/tradeSubCategoryActions";
import { StoreState } from "../redux/store";

import Loader from "../components/custom/components/Loader";
import Modal from "../components/Modal";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faWindowMaximize } from "@fortawesome/free-solid-svg-icons";
import { areaType } from "../redux/actions/areaActions";
import { outletType } from "../redux/actions/outletActions";
import { useLocation } from "react-router-dom";
import { UserLog } from "../config/common";
import SingleSelect from "../components/custom/components/SingleSelect";
import SingleSelect1 from "../components/custom/components/SingleSelect1";

export interface DropDownOption {
  value: number;
  label: string;
  imgUrl: string;
}

export interface FootfallHourlyFlowRecord {
  centerid: string;
  buildingid: string;
  floorid?: string;
  date: string;
  h1: number;
  h2: number;
  h3: number;
  h4: number;
  h5: number;
  h6: number;
  h7: number;
  h8: number;
  h9: number;
  h10: number;
  h11: number;
  h12: number;
  h13: number;
  h14: number;
  h15: number;
  h16: number;
  h17: number;
  h18: number;
  h19: number;
  h20: number;
  h21: number;
  h22: number;
  h23: number;
  h24: number;
}

export interface FootfallHourlyFlowReportType {
  portfolio: number;
  record: FootfallHourlyFlowRecord[];
  count?: number;
}

const FootfallHourlyFlowReport: React.FC = () => {
  const toast = useToast();
  const dispatch = useDispatch();
  const pageName = useLocation()
  const authUser = useSelector<StoreState, AuthUserType | null>(
    (state) => state?.authUser
  );
  const authUserToken = useSelector<StoreState, AuthUserType | null>(
    (state) => state?.authUser
  )?.["auth-token"]!;
  const config = getAxiosRequestConfig(authUserToken);

  const authUserSiteId = authUser?.siteId;

  const portfolioOption = useSelector<StoreState, PortfolioType[]>(
    (state) => state?.portfolio
  );
  const siteOption = useSelector<StoreState, SiteType[]>((state) => state?.site);
  const brandOption = useSelector<StoreState, BrandType[]>(
    (state) => state?.brand
  );

  const selectedOption = useSelector<StoreState, OptionsTypes>(
    (state) => state?.selectedOption
  );
  const outletList = useSelector<StoreState, outletType[]>(
    (state) => state?.outlet
  );
  
  const localityOption = useSelector<StoreState, areaType[]>(
    (state) => state?.locality
  );
  const noArray = [
    "1",
    "2",
    "3",
    "4",
    "5",
    "6",
    "7",
    "8",
    "9",
    "10",
    "11",
    "12",
    "13",
    "14",
    "15",
    "16",
    "17",
    "18",
    "19",
    "20",
    "21",
    "22",
    "23",
    "24",
  ];

  const port =
    authUser?.portfolioId !== undefined && authUser?.portfolioId !== null
      ? authUser?.portfolioId?.split(",")
      : [];

  let portfolioData = port
    ?.map((pe) => {
      return portfolioOption?.filter(
        (f) => f?.id?.toString() === pe?.toString()
      );
    })
    ?.flat(1);

  const pname = portfolioData[0]?.name;

  const st =
    authUser?.siteId !== undefined && authUser?.siteId !== null
      ? authUser?.siteId?.split(",")
      : [];

  let siteData = st
    ?.map((pe) => {
      return siteOption?.filter((f) => f?.id?.toString() === pe?.toString());
    })
    ?.flat(1);



  const [filterDate, setFilterDate] = useState<string>(getYesterday());

  const [valueIn, setValueIn] = useState<string>("");

  const [portfolios, setPortfolios] = useState<string>("");
  const [sites, setSites] = useState<string>("");
  const [brands, setBrands] = useState<string>("");

  const [location, setLocation] = useState<string>("");
  const [categories, setCategories] = useState<string>("");
  const [subCategories, setSubCategories] = useState<string>("");

  const [fileLoader, setFileLoader] = useState<boolean>(false);
  const [loader, setLoader] = useState<boolean>(false);
  const [pag, setPag] = useState<boolean>(false);


  const [footfallHourlyFlowData, setFootfallHourlyFlowData] = useState<
    FootfallHourlyFlowReportType[]
  >([]);

  const limit = PAGE_SIZE;
  const [offset, setOffset] = useState<number>(0);
  const [range, setRange] = useState<number[]>([]);
  const [length, setLength] = useState<number>(50);

  const [selectedRows, setSelectedRows] = useState<string[]>([]);

  const [showModal, setShowModal] = useState<boolean>(false);
  const [isButtonVisible, setButtonVisible] = useState(false);
  const [disableLocality, setDisableLocality] = useState<boolean>(false);
  const [condition,setCondition] = useState<boolean>(false)

  const [portfolioDrop, setPortfolioDrop] = useState<DropDownOption[]>([]);
  const [locationDrop, setLocationDrop] = useState<DropDownOption[]>([]);

  const showExpandModalHandler = () => {
    setShowModal(true)
  };

  const handleSelectedRowsChange = (selectedRows: string[]) => {
    setSelectedRows(selectedRows);
  };

  const handleOff = (conditionVal: boolean) => {
    setCondition(conditionVal);
  };


  const SiteFilteredByPortfolio = useMemo<SiteType[]>(() => {
    if (portfolios !== "") {
      return siteData?.filter((s) => {
        return s?.pfid?.toString() === portfolios?.toString();
      });
    } else return [];
  }, [portfolios, siteData]);

  const handleFilterDate: ChangeEventHandler<HTMLInputElement> = (e) => {
    setFilterDate(e?.target?.value);
    setPag(false);
  };

  const handlePortfolioDrop = (selected: any) => {
    if(selected.length>0){
      const selectedPortfolio = selected as DropDownOption[];
      if(selectedPortfolio[0].value!==null&&selectedPortfolio[0].value!==0){
        setPortfolioDrop(selectedPortfolio)
        setPortfolios(selectedPortfolio[0]?.value?.toString())
        const data = { ...selectedOption, portfolio: selectedPortfolio[0]?.value?.toString() };
        dispatch(addToSelectedOption(data));
        setOffset(0);
        setPag(false)
      }
  }
  };
  
  const handleClearPortfolio = () =>{
    let pid =portfolioData[0] && portfolioData[0]?.id? portfolioData[0].id?.toString(): "";
    setPortfolios(selectedOption.portfolio ? selectedOption.portfolio : pid);
    const b = portfolioOption.filter((a)=>a?.id?.toString()===pid?.toString())
    if (b?.length > 0 && b[0]?.id !== undefined) {
      const val: DropDownOption = {
        value: b[0]?.id, 
        label: b[0]?.name,
        imgUrl: b[0]?.logourl, 
      };
      setPortfolioDrop([val]);
      setLocation("");
      setLocationDrop([])
      const data = { ...selectedOption, portfolio: b[0]?.id?.toString(),location:''};
      dispatch(addToSelectedOption(data));
      setOffset(0);
      setPag(false);
  }
  }
  
  
  const handleLocationDrop = (selected: any) => {
    if(selected.length>0){
      const selectedLocation = selected as DropDownOption[];
      if(selectedLocation[0].value!==null&&selectedLocation[0].value!==0){
        setLocationDrop(selectedLocation)
        setLocation(selectedLocation[0]?.label?.toString())
       const data = { ...selectedOption,location: selectedLocation[0]?.label?.toString()};
       dispatch(addToSelectedOption(data));
       setOffset(0);
       setPag(false)
      }
    }
  };
  
  const handleClearLocation = () =>{
       setLocationDrop([])
       setLocation('')
       const data = { ...selectedOption,location: ""};
       dispatch(addToSelectedOption(data));
       setOffset(0);
       setPag(false)
  }
  

  const portFilter = portfolioOption?.filter((a)=>a?.id?.toString()===portfolios?.toString())
  const localityFilter = localityOption?.filter((a)=>a?.area?.toString()===location?.toString())


  const combinedValues = [
    ...portFilter?.map(a => `Portfolio: ${a?.name}`),            
    ...localityFilter?.map(f=>`Locality: ${f?.area}`),     
    `Date: ${filterDate?.toString()}`,
  ]
  ?.filter(name => name && name?.trim() !== '') 
  ?.join(', ');  
  


  const tHead = useMemo<Thead[]>(() => {
    var tableHead: Thead[] = [
      { id: "Center", children: "Center", text: "Center" },
      // { id: "Building", children: "Building", text: "Building" },
      { id: "0-1 AM", children: "0-1 AM", text: "0-1 AM" },
      { id: "1-2 AM", children: "1-2 AM", text: "1-2 AM" },
      { id: "2-3 AM", children: "2-3 AM", text: "2-3 AM" },
      { id: "3-4 AM", children: "3-4 AM", text: "3-4 AM" },
      { id: "4-5 AM", children: "4-5 AM", text: "4-5 AM" },
      { id: "5-6 AM", children: "5-6 AM", text: "5-6 AM" },
      { id: "6-7 AM", children: "6-7 AM", text: "6-7 AM" },
      { id: "7-8 AM", children: "7-8 AM", text: "7-8 AM" },
      { id: "8-9 AM", children: "8-9 AM", text: "8-9 AM" },
      { id: "9-10 AM", children: "9-10 AM", text: "9-10 AM" },
      { id: "10-11 AM", children: "10-11 AM", text: "10-11 AM" },
      { id: "11-12 PM", children: "11-12 PM", text: "11-12 PM" },
      { id: "12-1 PM", children: "12-1 PM", text: "12-1 PM" },
      { id: "1-2 PM", children: "1-2 PM", text: "1-2 PM" },
      { id: "2-3 PM", children: "2-3 PM", text: "2-3 PM" },
      { id: "3-4 PM", children: "3-4 PM", text: "3-4 PM" },
      { id: "4-5 PM", children: "4-5 PM", text: "4-5 PM" },
      { id: "5-6 PM", children: "5-6 PM", text: "5-6 PM" },
      { id: "6-7 PM", children: "6-7 PM", text: "6-7 PM" },
      { id: "7-8 PM", children: "7-8 PM", text: "7-8 PM" },
      { id: "8-9 PM", children: "8-9 PM", text: "8-9 PM" },
      { id: "9-10 PM", children: "9-10 PM", text: "9-10 PM" },
      { id: "10-11 PM", children: "10-11 PM", text: "10-11 PM" },
      { id: "11-12 AM", children: "11-12 AM", text: "11-12 AM" },
    ];


    return tableHead;
  }, []);

  const fetchDataForDownload = async () => {
    let error = false;
    if (portfolios === "") {
      toast("Select Portfolio", ToastTypes?.ERROR);
      error = true;
    } else if (filterDate === "") {
      toast("Select filterDate", ToastTypes?.ERROR);
      error = true;
    }


    var getData = {
      portfolio: portfolio,
      site: site,
      locality: location===defaultLocation?.label?'':location,
      tradeCategory: category,
      tradeSubCategory: subCategory,
      brand: brand,
      date: filterDate,
    };

    if (error === false && getData !== undefined) {
      const response = await api.post<
        APIResponse<{ records: FootfallHourlyFlowReportType[] }>
      >(`kpi/footfall/hourlyFlow`, getData, config);

      if (response?.status === 200 && response?.data?.data) {
        return response?.data?.data?.records;
      }
    }

    return [];
  };

  const getDownloadData = (Data: FootfallHourlyFlowReportType[]) => {
    var tableHead: Thead[] = tHead;
    var tableBody: Tbody[][] = [];

    Data?.forEach((data, i) => {
      data?.record?.forEach((item) => {
        const centerObj = siteOption?.find(
          (s) => s?.id?.toString() === item?.centerid?.toString()
        );
        // const buildingObj = buildingOption?.find(
        //   (b) => b?.buildingid?.toString() === item?.buildingid?.toString()
        // );
        var tBody: Tbody[] = [];
        tBody?.push(
          {
            id: nanoid(),
            theadId: "Center",
            children: centerObj?.name,
            text: centerObj?.name,
          },
          // {
          //   id: nanoid(),
          //   theadId: "Building",
          //   children: buildingObj?.buildingname,
          //   text: buildingObj?.buildingname,
          // },
          {
            id: nanoid(),
            theadId: "0-1 AM",
            children: item?.h1,
            text: item?.h1?.toString(),
          },
          {
            id: nanoid(),
            theadId: "1-2 AM",
            children: item?.h2,
            text: item?.h2?.toString(),
          },
          {
            id: nanoid(),
            theadId: "2-3 AM",
            children: item?.h3,
            text: item?.h3?.toString(),
          },
          {
            id: nanoid(),
            theadId: "3-4 AM",
            children: item?.h4,
            text: item?.h4?.toString(),
          },
          {
            id: nanoid(),
            theadId: "4-5 AM",
            children: item?.h5,
            text: item?.h5?.toString(),
          },
          {
            id: nanoid(),
            theadId: "5-6 AM",
            children: item?.h6,
            text: item?.h6?.toString(),
          },
          {
            id: nanoid(),
            theadId: "6-7 AM",
            children: item?.h7,
            text: item?.h7?.toString(),
          },
          {
            id: nanoid(),
            theadId: "7-8 AM",
            children: item?.h8,
            text: item?.h8?.toString(),
          },
          {
            id: nanoid(),
            theadId: "8-9 AM",
            children: item?.h9,
            text: item?.h9?.toString(),
          },
          {
            id: nanoid(),
            theadId: "9-10 AM",
            children: item?.h10,
            text: item?.h10?.toString(),
          },
          {
            id: nanoid(),
            theadId: "10-11 AM",
            children: item?.h11,
            text: item?.h11?.toString(),
          },
          {
            id: nanoid(),
            theadId: "11-12 PM",
            children: item?.h12,
            text: item?.h12?.toString(),
          },
          {
            id: nanoid(),
            theadId: "12-1 PM",
            children: item?.h13,
            text: item?.h13?.toString(),
          },
          {
            id: nanoid(),
            theadId: "1-2 PM",
            children: item?.h14,
            text: item?.h14?.toString(),
          },
          {
            id: nanoid(),
            theadId: "2-3 PM",
            children: item?.h15,
            text: item?.h15?.toString(),
          },
          {
            id: nanoid(),
            theadId: "3-4 PM",
            children: item?.h16,
            text: item?.h16?.toString(),
          },
          {
            id: nanoid(),
            theadId: "4-5 PM",
            children: item?.h17,
            text: item?.h17?.toString(),
          },
          {
            id: nanoid(),
            theadId: "5-6 PM",
            children: item?.h18,
            text: item?.h18?.toString(),
          },
          {
            id: nanoid(),
            theadId: "6-7 PM",
            children: item?.h19,
            text: item?.h19?.toString(),
          },
          {
            id: nanoid(),
            theadId: "7-8 PM",
            children: item?.h20,
            text: item?.h20?.toString(),
          },
          {
            id: nanoid(),
            theadId: "8-9 PM",
            children: item?.h21,
            text: item?.h21?.toString(),
          },
          {
            id: nanoid(),
            theadId: "9-10 PM",
            children: item?.h22,
            text: item?.h22?.toString(),
          },
          {
            id: nanoid(),
            theadId: "10-11 PM",
            children: item?.h23,
            text: item?.h23?.toString(),
          },
          {
            id: nanoid(),
            theadId: "11-12 AM",
            children: item?.h24,
            text: item?.h24?.toString(),
          }
        );
        tableBody?.push(tBody);
      });
    });
    return { tbody: tableBody, thead: tableHead };
  };

  const handleApply = () => {
    let error = false;
    if (portfolios === "") {
      toast("Select Portfolio", ToastTypes?.ERROR);
      error = true;
    }
    else if (filterDate === "") {
      toast("Select filterDate", ToastTypes?.ERROR);
      error = true;
    }
    var getData = {
      portfolio: portfolio,
      site: site,
      locality: location===defaultLocation?.label?'':location,
      tradeCategory: category,
      tradeSubCategory: subCategory,
      brand: brand,
      date: filterDate,
      limit: limit?.toString(),
      offset: offset?.toString(),
      authUserRole: authUser?.roleId ? authUser?.roleId : "",
    };

    if (
      error === false &&
      getData !== undefined &&
      getData?.limit !== "" &&
      getData?.offset !== ""
    ) {
      if (pag === false) {
        setLoader(true);
      }

      api
        .post<APIResponse<{ records: FootfallHourlyFlowReportType[] }>>(
          `kpi/footfall/hourlyFlow`,
          getData,
          config
        )
        .then((response) => {
          if (response?.status === 200 && response?.data?.data) {
            const result = response?.data?.data?.records;
            setLoader(false);
            setPag(true);
            setFootfallHourlyFlowData(result);
            setCondition(false)
              if(authUser){
                const logProps1 = {
                  action: 'Apply',
                  event: `${pageName?.pathname?.replace("\\", " ")} page | Filters - ${combinedValues} | Apply Clicked | Data Status: ${result?.[0]?.count && result[0]?.count > 0 ? 'Success' : 'Empty'}`
                };
                UserLog(logProps1)
              }
          }
        })
        .catch((error) => {});
    } else {
      setFootfallHourlyFlowData([]);
      setLoader(false);
      setPag(true);
      setCondition(false)
    }
  };

  const handlePag = () => {
    setPag(true);
  };

  useEffect(() => {
    if (portfolios !== "" && fileLoader === false) handleApply();
  }, [portfolios]);

  const handleCsvDownload = async () => {
    setFileLoader(true);
    const csvData = await fetchDataForDownload();
    if (
      csvData[0]?.record?.length !== 0 &&
      csvData[0]?.record?.length !== undefined
    ) {
      let Data = csvData;
      const d = { ...getDownloadData(Data) };

      const filteredHead = d?.thead
        ?.map((a) => {
          const foundObject = selectedRows
            ?.map((b) => {
              if (a?.text?.toString() === b?.toString()) {
                return a;
              }
            })
            ?.find((obj) => obj !== undefined);

          if (foundObject !== undefined) {
            return foundObject;
          }
        })
        ?.filter((obj) => obj !== undefined);
      const csvHead = filteredHead;
      const csvBody = d?.tbody;

      const head = [csvHead?.map((h) => h?.text || "") || []];
      const body =
        csvBody?.map((tr, i) => {
          return (
            csvHead?.map((th, j) => {
              const col = tr?.find((r) => r?.theadId === th?.id);
              return col?.text || "";
            }) || []
          );
        }) || [];

      const rows = [...head, ...body];
      const props = {
        rows: rows,
        fileName: "Footfall Hourly Flow Report",
      };
      CSVdownload(props);
      setFileLoader(false);
      if(authUser){
        const logProps1 = {
          action: 'Csv',
          event: `${pageName?.pathname?.replace("\\", " ")} page | Filters - ${combinedValues} | Csv Clicked | Export Status: Success | Data Status: Success`
        };
        UserLog(logProps1)
      }
    } else {
      setFileLoader(false);
      if(authUser){
        const logProps1 = {
          action: 'Csv',
          event: `${pageName?.pathname?.replace("\\", " ")} page | Filters - ${combinedValues} | Csv Clicked | Export Status: Success | Data Status: Empty`
        };
        UserLog(logProps1)
      }
    }
    setFileLoader(false);
  };

  const handlePdfDownload = async () => {
    setFileLoader(true);
    const pdfData = await fetchDataForDownload();
    if (
      pdfData[0]?.record?.length !== undefined &&
      pdfData[0]?.record?.length !== 0
    ) {
      const d = { ...getDownloadData(pdfData) };

      const filteredHead = d?.thead
        ?.map((a) => {
          const foundObject = selectedRows
            ?.map((b) => {
              if (a?.text?.toString() === b?.toString()) {
                return a;
              }
            })
            ?.find((obj) => obj !== undefined);

          if (foundObject !== undefined) {
            return foundObject;
          }
        })
        ?.filter((obj) => obj !== undefined);
      const thead = filteredHead;
      const tbody = d?.tbody;

      const head = [thead?.map((h) => h?.text || "") || []];
      const body =
        tbody?.map((tr, i) => {
          return (
            thead?.map((th, j) => {
              const col = tr?.find((r) => r?.theadId === th?.id);
              return col?.text || "";
            }) || []
          );
        }) || [];

      const props = {
        head: head,
        body: body,
        fileName: "Footfall Hourly Flow Report",
        title: "Footfall Hourly Flow Report",
      };
      handleDownloadPDF(props);
      setFileLoader(false);
      if(authUser){
        const logProps1 = {
          action: 'Pdf',
          event: `${pageName?.pathname?.replace("\\", " ")} page | Filters - ${combinedValues} | Pdf Clicked | Export Status: Success | Data Status: Success`
        };
        UserLog(logProps1)
      }
    } else {
      setFileLoader(false);
      if(authUser){
        const logProps1 = {
          action: 'Pdf',
          event: `${pageName?.pathname?.replace("\\", " ")} page | Filters - ${combinedValues} | Pdf Clicked  | Export Status: Success | Data Status: Empty`
        };
        UserLog(logProps1)
      }
    }
    setFileLoader(false);
  };

  const getTableData = () => {
    var tableHead: Thead[] = tHead;
    var tableBody: Tbody[][] = [];
    let Data = footfallHourlyFlowData;

    Data?.forEach((data, i) => {
      data?.record?.forEach((item) => {
        const centerObj = siteOption?.find(
          (s) => s?.id?.toString() === item?.centerid?.toString()
        );
        // const buildingObj = buildingOption?.find(
        //   (b) => b?.buildingid?.toString() === item?.buildingid?.toString()
        // );
        var tBody: Tbody[] = [];
        tBody?.push(
          {
            id: nanoid(),
            theadId: "Center",
            children: centerObj?.name,
            text: centerObj?.name,
          },
          // {
          //   id: nanoid(),
          //   theadId: "Building",
          //   children: buildingObj?.buildingname,
          //   text: buildingObj?.buildingname,
          // },
          {
            id: nanoid(),
            theadId: "0-1 AM",
            children: item?.h1,
            text: item?.h1?.toString(),
          },
          {
            id: nanoid(),
            theadId: "1-2 AM",
            children: item?.h2,
            text: item?.h2?.toString(),
          },
          {
            id: nanoid(),
            theadId: "2-3 AM",
            children: item?.h3,
            text: item?.h3?.toString(),
          },
          {
            id: nanoid(),
            theadId: "3-4 AM",
            children: item?.h4,
            text: item?.h4?.toString(),
          },
          {
            id: nanoid(),
            theadId: "4-5 AM",
            children: item?.h5,
            text: item?.h5?.toString(),
          },
          {
            id: nanoid(),
            theadId: "5-6 AM",
            children: item?.h6,
            text: item?.h6?.toString(),
          },
          {
            id: nanoid(),
            theadId: "6-7 AM",
            children: item?.h7,
            text: item?.h7?.toString(),
          },
          {
            id: nanoid(),
            theadId: "7-8 AM",
            children: item?.h8,
            text: item?.h8?.toString(),
          },
          {
            id: nanoid(),
            theadId: "8-9 AM",
            children: item?.h9,
            text: item?.h9?.toString(),
          },
          {
            id: nanoid(),
            theadId: "9-10 AM",
            children: item?.h10,
            text: item?.h10?.toString(),
          },
          {
            id: nanoid(),
            theadId: "10-11 AM",
            children: item?.h11,
            text: item?.h11?.toString(),
          },
          {
            id: nanoid(),
            theadId: "11-12 PM",
            children: item?.h12,
            text: item?.h12?.toString(),
          },
          {
            id: nanoid(),
            theadId: "12-1 PM",
            children: item?.h13,
            text: item?.h13?.toString(),
          },
          {
            id: nanoid(),
            theadId: "1-2 PM",
            children: item?.h14,
            text: item?.h14?.toString(),
          },
          {
            id: nanoid(),
            theadId: "2-3 PM",
            children: item?.h15,
            text: item?.h15?.toString(),
          },
          {
            id: nanoid(),
            theadId: "3-4 PM",
            children: item?.h16,
            text: item?.h16?.toString(),
          },
          {
            id: nanoid(),
            theadId: "4-5 PM",
            children: item?.h17,
            text: item?.h17?.toString(),
          },
          {
            id: nanoid(),
            theadId: "5-6 PM",
            children: item?.h18,
            text: item?.h18?.toString(),
          },
          {
            id: nanoid(),
            theadId: "6-7 PM",
            children: item?.h19,
            text: item?.h19?.toString(),
          },
          {
            id: nanoid(),
            theadId: "7-8 PM",
            children: item?.h20,
            text: item?.h20?.toString(),
          },
          {
            id: nanoid(),
            theadId: "8-9 PM",
            children: item?.h21,
            text: item?.h21?.toString(),
          },
          {
            id: nanoid(),
            theadId: "9-10 PM",
            children: item?.h22,
            text: item?.h22?.toString(),
          },
          {
            id: nanoid(),
            theadId: "10-11 PM",
            children: item?.h23,
            text: item?.h23?.toString(),
          },
          {
            id: nanoid(),
            theadId: "11-12 AM",
            children: item?.h24,
            text: item?.h24?.toString(),
          }
        );
        tableBody?.push(tBody);
      });
    });
    return { tbody: tableBody, thead: tableHead };
  };

  useEffect(() => {
    const header = tHead
      ?.map((a) => a?.text)
      ?.filter((id): id is string => typeof id === "string" && id !== undefined);
    setSelectedRows(header);
  }, []);

  const portfolio = useMemo<number[]>(() => {
    return portfolios !== "" ? [parseInt(portfolios)] : [];
  }, [portfolios]);

  const site = useMemo<string[]>(() => {
    if (authUserSiteId) {
      return authUserSiteId?.split(",")?.map((s) => {
        return s?.toString();
      });
    } else {
      return [];
    }
  }, [authUserSiteId]);
  const brand = useMemo<number[]>(() => {
    if (brands !== "") {
      return brands !== "" ? [parseInt(brands)] : [];
    } else return []; //bcz no brand filter
  }, [brands]);
  const category = useMemo<number[]>(() => {
    return categories !== "" ? [parseInt(categories)] : [];
  }, [categories]);

  const subCategory = useMemo<number[]>(() => {
    return subCategories !== "" ? [parseInt(subCategories)] : [];
  }, [subCategories]);

  useEffect(()=>{    
    if(selectedOption?.isOutletUser){
      setDisableLocality(true);
    }
    else{
      setDisableLocality(false);
    }
  },[authUser,selectedOption]);

  useEffect(() => {
    setOffset(0);
  }, [filterDate]);

  useEffect(()=>{
    let pid =portfolioData[0] && portfolioData[0]?.id? portfolioData[0].id?.toString(): "";
    setPortfolios(selectedOption.portfolio ? selectedOption.portfolio : pid);
    const b = portfolioOption.filter((a)=>a?.id?.toString()===pid?.toString())
    if (b?.length > 0 && b[0]?.id !== undefined) {
      const val: DropDownOption = {
        value: b[0]?.id, 
        label: b[0]?.name,
        imgUrl: b[0]?.logourl, 
      };
      setPortfolioDrop([val]);
    }
},[portfolioOption,selectedOption])

useEffect(()=>{
  setLocation(selectedOption.location? selectedOption.location:'')
  const b = localityOption.filter((a)=>a?.area?.toString()===selectedOption.location?.toString())
  if (b?.length > 0 && b[0]?.id !== undefined) {
    const val: DropDownOption = {
      value: b[0]?.id, 
      label: b[0]?.area,
      imgUrl:'', 
    };
    setLocationDrop([val]);
  }
  },[localityOption,selectedOption])

  useEffect(() => {
    let sid = siteData[0] && siteData[0]?.id ? siteData[0]?.id?.toString() : "";
    setSites(selectedOption?.site ? selectedOption?.site : sid);
  }, [siteData, selectedOption]);



  useEffect(() => {
    var range = [];
    var num = Math?.ceil(length / limit);
    // let i = 1;

    for (let i = 1; i <= num; i++) {
      range?.push(i);
    }
    setRange(range);
  }, [length, limit]);

  useEffect(() => {
    if (sites !== ""&&selectedOption.isOutletUser===true) {
      let siteCurrencyObject = siteOption?.find(
        (s) => s?.id?.toString() === sites?.toString()
      );
      setValueIn(
        siteCurrencyObject?.currencycode +
          " | " +
          siteCurrencyObject?.valuecaption
      );
    } 
    else if(portfolios !== ""&&selectedOption.isOutletUser===false){
      let portfolioCurrencyObject = portfolioOption?.find(
        (s) => s?.id?.toString() === portfolios?.toString()
      );
      setValueIn(
        portfolioCurrencyObject?.reportingcurrency +
          " | " +
          portfolioCurrencyObject?.valuecaption
      );
    }
    else setValueIn("");
  }, [sites, siteOption,selectedOption]);

  useEffect(() => {
    if (footfallHourlyFlowData?.length > 0)
      setLength(
        footfallHourlyFlowData[0]?.count ? footfallHourlyFlowData[0]?.count : 0
      );
  }, [footfallHourlyFlowData]);

  const colors = [
    COLORS?.COLOR1,
    COLORS?.COLOR2,
    COLORS?.COLOR3,
    COLORS?.COLOR4,
    COLORS?.COLOR5,
    COLORS?.COLOR6,
    COLORS?.COLOR7,
    COLORS?.COLOR8,
  ];

  return (
    <UserLayout>
      <div className="card background-transparent p-1">
        <div className="row">
          <div
            className="col-lg-4 wow fadeInLeft ps-0"
          >
            <h4 className="b-title">Hourly Flow</h4>
          </div>

          <div className="col-lg-8 wow fadeInRight">
            <div className="row">
              <div className="col-sm-6 col-md-4 col-lg-3">
                {/* <FormInput2
                  name="Portfolio"
                  label=""
                  labelClassName="required"
                  value={portfolios}
                  onChange={handlePortfolioChange}
                  placeholder="Select"
                  containerClass="mb-1"
                  type="select"
                >
                  <option value="">Select</option>
                  {portfolioData?.map((p, i) => {
                    return (
                      <Option value={p?.id} key={i}>
                        {p?.name}
                      </Option>
                    );
                  })}
                </FormInput2> */}
                {/* <SingleSelect
                  options={[
                    defaultPortfolio,
                    ...portfolioData?.sort(function (a, b) {
                      if (a?.name < b?.name) {
                        return -1;
                      }
                      if (a?.name > b?.name) {
                        return 1;
                      }
                      return 0;
                    })?.map((s, i) => {
                      return (
                        {
                          value: s?.id ? s?.id?.toString() : '',
                          label: s?.name,
                          imgUrl: s?.logourl,
                        }
                      );
                    })
                  ]}
                  label=""
                  selectedOptions={portfoliosOption}
                  setSelectedOptions={handlePortfolioChange}
                  // disabled={disableSite}
                  disabled={disableLocality}
                  placeHolder="Select Portfolio"
                  // filterText=""
                /> */}
                <SingleSelect1
                      options={portfolioData.map((c, i) => {
                        return {
                          value: c?.id?c?.id:0,
                          label: c?.name?c?.name:'',
                          imgUrl: "",
                        };
                      })}
                      selectedOptions={portfolioDrop}
                      setSelectedOptions={handlePortfolioDrop}
                      placeHolder="Select Portfolio"
                      onClear={handleClearPortfolio}
                      disabled = {disableLocality}
                    />
              </div>
              
              <div className="col-sm-6 col-md-4 col-lg-3">
              {/* <FormInput2
                  name="Location"
                  label=""
                  labelClassName="required"
                  value={location}
                  onChange={handleLocationChange}
                  placeholder="Select Locality"
                  containerClass="mb-1"
                  disabled={disableLocality}
                  type="select"
                >
                  <option value="">Select Locality</option>
                  {localityOption?.map((s, i) => {
                    return (
                      <Option value={s?.area} key={i}>
                        {s?.area}
                      </Option>
                    );
                  })}
                </FormInput2> */}
                {/* <SingleSelect
                  options={[
                    defaultLocation,
                    ...localityOption?.sort(function (a, b) {
                      if (a?.area < b?.area) {
                        return -1;
                      }
                      if (a?.area > b?.area) {
                        return 1;
                      }
                      return 0;
                    })?.map((s, i) => {
                      return (
                        {
                          value: s?.id ? s?.id?.toString() : '',
                          label: s?.area,
                          imgUrl: '',
                        }
                      );
                    })
                  ]}
                  label=""
                  selectedOptions={locationOption}
                  setSelectedOptions={handleLocationChange}
                  disabled={disableLocality}
                  placeHolder="Select Locality"
                  // filterText=""
                /> */}
                 <SingleSelect1
                      options={localityOption.map((c, i) => {
                        return {
                          value: c?.id?c?.id:0,
                          label: c?.area?c?.area:'',
                          imgUrl: "",
                        };
                      })}
                      selectedOptions={locationDrop}
                      setSelectedOptions={handleLocationDrop}
                      placeHolder="Select Locality"
                      onClear={handleClearLocation}
                      disabled = {disableLocality}
                    />
              </div>
            </div>

           
          </div>
        </div>
      </div>

      {loader ? (
        <Loader />
      ) : (
        <>
          <div className="row">
            <div className="col-12">
              <div className="card mt-3 widget_div" onMouseEnter={() => setButtonVisible(true)} onMouseLeave={() => setButtonVisible(false)}>
                {(<button type="button" className="expandIconBtn" onClick={showExpandModalHandler} ><i><FontAwesomeIcon icon={faWindowMaximize} size="lg" title="Open in Popup"/></i></button>)}
                <div className="card-body">
                  <div className="row">
                    <div className="col-lg-12 col-sm-12">
                      <div className="float-start">
                        <h5 className="header-title">
                          <img src={chartIcon} alt="icon"></img>
                          Footfall Hourly Flow
                        </h5>
                      </div>
                      <div className="col-lg-12 col-sm-12 col-md-6">
                        <div className="float-end hstack gap-2">
                          <div className="col-xs-12 col-sm-12 col-md-8 col-lg-8">
                            <FormInput
                              name="From Date"
                              label=""
                              labelClassName="required"
                              value={filterDate}
                              max={filterDate}
                              onChange={handleFilterDate}
                              type="date"
                              placeholder="From Date"
                            />
                          </div>
                          <div className="col-xs-12 col-sm-12 col-md-4 col-lg-4">
                            <button
                              type="button"
                              className="btn btn-primary"
                              onClick={handleApply}
                            >
                              Apply
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <hr className="m-0 my-2 p-0 hr" />

                  {footfallHourlyFlowData[0]?.record?.length !== undefined &&
                  footfallHourlyFlowData[0]?.record?.length > 0 ? (
                    <LineChart
                      datasets={(() => {
                        const hdata: { name: string; data: number[] }[] = [];

                        footfallHourlyFlowData?.forEach((y, i) => {

                          y?.record?.forEach((r) => {
                            const centerObj = siteOption?.find(
                              (s) =>
                                s?.id?.toString() === r?.centerid?.toString()
                            );
                            let cname = centerObj? centerObj?.name : "" ;
                            // const buildingObj = buildingOption?.find(
                            //   (b) =>
                            //     b?.buildingid?.toString() ===
                            //     r?.buildingid?.toString()
                            // );
                            // let bname = buildingObj?.buildingname;

                            let data: number[] = [];
                            // for (let i = 1; i <= 24; i++) {
                            data?.push(r?.h1 ? r?.h1 : 0);
                            data?.push(r?.h2 ? r?.h2 : 0);
                            data?.push(r?.h3 ? r?.h3 : 0);
                            data?.push(r?.h4 ? r?.h4 : 0);
                            data?.push(r?.h5 ? r?.h5 : 0);
                            data?.push(r?.h6 ? r?.h6 : 0);
                            data?.push(r?.h7 ? r?.h7 : 0);
                            data?.push(r?.h8 ? r?.h8 : 0);
                            data?.push(r?.h9 ? r?.h9 : 0);
                            data?.push(r?.h10 ? r?.h10 : 0);
                            data?.push(r?.h11 ? r?.h11 : 0);
                            data?.push(r?.h12 ? r?.h12 : 0);
                            data?.push(r?.h13 ? r?.h13 : 0);
                            data?.push(r?.h14 ? r?.h14 : 0);
                            data?.push(r?.h15 ? r?.h15 : 0);
                            data?.push(r?.h16 ? r?.h16 : 0);
                            data?.push(r?.h17 ? r?.h17 : 0);
                            data?.push(r?.h18 ? r?.h18 : 0);
                            data?.push(r?.h19 ? r?.h19 : 0);
                            data?.push(r?.h20 ? r?.h20 : 0);
                            data?.push(r?.h21 ? r?.h21 : 0);
                            data?.push(r?.h22 ? r?.h22 : 0);
                            data?.push(r?.h23 ? r?.h23 : 0);
                            data?.push(r?.h24 ? r?.h24 : 0);

                            let isEmpty: string[] = [];
                            data?.forEach((d2) => {
                              if (d2 !== 0) isEmpty?.push("true");
                              else isEmpty?.push("false");
                            });
                            // }
                            if (data?.length > 0 && isEmpty?.includes("true")) {
                              hdata?.push({
                                name: cname,
                                data: data,
                              });
                            }
                          });
                        });
                        return hdata;
                      })()}
                      labels={noArray?.map((w) => w?.toString())}
                      colors={[
                        colors[0],
                        colors[1],
                        colors[2],
                        colors[3],
                        colors[4],
                        colors[5],
                        colors[6],
                        colors[7],
                      ]}
                      title={(() => {
                        const txt = `Footfall Hourly Flow`;

                        return txt;
                      })()}
                      subtitle={(() => {
                        let subtxt = `\u3010Company\u3011: ${pname}`;
                        if (location) {
                          subtxt += `\u3010Locality\u3011: ${location}`;
                        }
                        subtxt += ` \u3010Date\u3011: ${filterDate?.toString()}`;

                        return subtxt;
                      })()}
                      exportfilename={"footfallHourlyFlow"}
                    />
                  ) : (
                    <div className="d-flex flex-column justify-content-center align-items-center col-12 emptychart-container  ">
                      <p className="empty-data">No Records Found</p>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-12">
              <div className="card mt-3">
                <div className="card-body">
                  <div className="row mb-1">
                    <div className="col-lg-6 col-sm-12">
                      <div className="float-start">
                        <h5 className="header-title mt-1">
                          <img src={tableIcon} alt="icon"></img>
                          Footfall Hourly Flow
                        </h5>
                      </div>
                    </div>
                    
                  </div>

                  <TransactionTable
                    data={[]}
                    {...getTableData()}
                    title=""
                    downloadCSV={true}
                    downloadPDF={true}
                    fileName="Footfall Hourly Flow"
                    limit={limit} //
                    setOffset={setOffset}
                    onClickCSV={handleCsvDownload}
                    onClickPDF={handlePdfDownload}
                    range={range}
                    fLoader={fileLoader}
                    onSelectedRowsChange={handleSelectedRowsChange}
                    onPageNoChange={pag && (offset > 0||condition) ? handleApply : undefined}
                    handlePag={pag ? handlePag : undefined}
                    onOffchange={handleOff}
                  />
                </div>
              </div>
            </div>
          </div>
          
          {showModal && (
        <Modal
        headerText={"Footfall Hourly Flow"}
        visible={showModal}
        onClose={() => setShowModal(false)}
        size="xl"
        centered
        classname="widget-container-single" > 
		<div className="card-body">
                  

                  {footfallHourlyFlowData[0]?.record?.length !== undefined &&
                  footfallHourlyFlowData[0]?.record?.length > 0 ? (
                    <LineChart
                      datasets={(() => {
                        const hdata: { name: string; data: number[] }[] = [];

                        footfallHourlyFlowData?.forEach((y, i) => {

                          y?.record?.forEach((r) => {
                            const centerObj = siteOption?.find(
                              (s) => s?.id?.toString() === r?.centerid?.toString()
                            );
                            let cname = centerObj? centerObj?.name : "";
                            // const buildingObj = buildingOption?.find(
                            //   (b) =>
                            //     b?.buildingid?.toString() ===
                            //     r?.buildingid?.toString()
                            // );
                            // let bname = buildingObj?.buildingname;
                            
                            let data: number[] = [];
                            data?.push(r?.h1 ? r?.h1 : 0);
                            data?.push(r?.h2 ? r?.h2 : 0);
                            data?.push(r?.h3 ? r?.h3 : 0);
                            data?.push(r?.h4 ? r?.h4 : 0);
                            data?.push(r?.h5 ? r?.h5 : 0);
                            data?.push(r?.h6 ? r?.h6 : 0);
                            data?.push(r?.h7 ? r?.h7 : 0);
                            data?.push(r?.h8 ? r?.h8 : 0);
                            data?.push(r?.h9 ? r?.h9 : 0);
                            data?.push(r?.h10 ? r?.h10 : 0);
                            data?.push(r?.h11 ? r?.h11 : 0);
                            data?.push(r?.h12 ? r?.h12 : 0);
                            data?.push(r?.h13 ? r?.h13 : 0);
                            data?.push(r?.h14 ? r?.h14 : 0);
                            data?.push(r?.h15 ? r?.h15 : 0);
                            data?.push(r?.h16 ? r?.h16 : 0);
                            data?.push(r?.h17 ? r?.h17 : 0);
                            data?.push(r?.h18 ? r?.h18 : 0);
                            data?.push(r?.h19 ? r?.h19 : 0);
                            data?.push(r?.h20 ? r?.h20 : 0);
                            data?.push(r?.h21 ? r?.h21 : 0);
                            data?.push(r?.h22 ? r?.h22 : 0);
                            data?.push(r?.h23 ? r?.h23 : 0);
                            data?.push(r?.h24 ? r?.h24 : 0);

                            let isEmpty: string[] = [];
                            data?.forEach((d2) => {
                              if (d2 !== 0) isEmpty?.push("true");
                              else isEmpty?.push("false");
                            });
                            if (data?.length > 0 && isEmpty?.includes("true")) {
                              hdata?.push({
                                name: cname,
                                data: data,
                              });
                            }
                          });
                        });
                        return hdata;
                      })()}
                      labels={noArray?.map((w) => w?.toString())}
                      colors={[
                        colors[0],
                        colors[1],
                        colors[2],
                        colors[3],
                        colors[4],
                        colors[5],
                        colors[6],
                        colors[7],
                      ]}
                      title={(() => {
                        const txt = `Footfall Hourly Flow`;

                        return txt;
                      })()}
                      subtitle={(() => {
                        let subtxt = `\u3010Company\u3011: ${pname}`;                        
                        if (location) {
                          subtxt += `\u3010Locality\u3011: ${location}`;
                        }
                        subtxt += ` \u3010Date\u3011: ${filterDate?.toString()}`;

                        return subtxt;
                      })()}
                      exportfilename={"footfallHourlyFlow"}
                    />
                  ) : (
                    <div className="d-flex flex-column justify-content-center align-items-center col-12 emptychart-container  ">
                      <p className="empty-data">No Records Found</p>
                    </div>
                  )}
                </div>
		
		</Modal>)}
        </>
      )}
    </UserLayout>
  );
};

export default FootfallHourlyFlowReport;
