export enum KpiFilterBtns {
    YEARLY = 'yearly',
    YTD = 'ytd',
    MONTHLY = 'monthly',
    MTD = 'mtd',
    WEEKLY = 'weekly'
}
export enum KpiViewList {
    TABLE = 'table',
    CHART = 'chart'
}
export type KpiFilterType = KpiFilterBtns.MONTHLY | KpiFilterBtns.MTD | KpiFilterBtns.YEARLY | KpiFilterBtns.YTD | KpiFilterBtns.WEEKLY

export const getOnlyUnique = <V, I>(value: V, index: I, self: V[]) => {
    return self.indexOf(value) === index;
}
export enum SalesPerformanceList {
    YEARLY = "yearly",
    YTD = "ytd",
    MONTHLY = "monthly",
    MTD = "mtd",
    WEEKLY = "weekly",
}
export type SalesPerformanceType =
  | SalesPerformanceList.MONTHLY
  | SalesPerformanceList.MTD
  | SalesPerformanceList.YEARLY
  | SalesPerformanceList.YTD
  | SalesPerformanceList.WEEKLY;