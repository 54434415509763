import { useMemo } from 'react'
import { useSelector } from 'react-redux'
import UserLayout from '../components/custom/layouts/UserLayout'
import { AuthUserType } from '../redux/actions/authUserActions'
import { StoreState } from '../redux/store'
import CommonDashboard from './CommonDashboard'
import BrandDashboard from './BrandDashboard'
import OutletBrandDashboard from './OutletBrandDashboard'
import RetailBrandDashboard from './RetailBrandDashboard'

const Home = () => {
    const authUser = useSelector<StoreState, AuthUserType | null>(state => state.authUser)
    const authUserRole = authUser?.role

    const BrandDashboardView = useMemo<boolean>(() => {
        let i = authUserRole?.findIndex((a) => a.pageurl === "/brand/dashboard");
        let view = authUserRole?.at(i!)?.view;
        if (view) return view;
        else return false;
      }, [authUserRole]);
    
      const OutletDashboardView = useMemo<boolean>(() => {
        let i = authUserRole?.findIndex((a) => a.pageurl === "/brand/outletdashboard");
        let view = authUserRole?.at(i!)?.view;
        if (view) return view;
        else return false;
      }, [authUserRole]);
    
      const RetailCompanyDashboardView = useMemo<boolean>(() => {
        let i = authUserRole?.findIndex((a) => a.pageurl === "/brand/retaildashboard");
        let view = authUserRole?.at(i!)?.view;
        if (view) return view;
        else return false;
      }, [authUserRole]);

    return <UserLayout>

        {/* <PageTitle
            title='Portfolio Dashboard : Pheonix '
        /> */}

        <div>
            {BrandDashboardView
                    ? <BrandDashboard />
                : RetailCompanyDashboardView
                    ? <RetailBrandDashboard />
                : OutletDashboardView
                    ? <OutletBrandDashboard />
                    : <CommonDashboard />
            }

        </div>

        {/* <div className="row"> */}
        {/* <div className='col-sm-12 col-lg-6'>
                <div className="card m-2">
                    <div className="card-body">
                        <div className="float-end d-none d-md-inline-block">
                            <div className="btn-group mb-2">
                                <button type="button" className="btn btn-xs btn-secondary">Monthly</button>&nbsp;&nbsp;
                                <button type="button" className="btn btn-xs btn-secondary">Yearly</button>&nbsp;&nbsp;
                                <button type="button" className="btn btn-xs btn-secondary">YoY</button>&nbsp;

                            </div>
                        </div>
                        <h4 className="card-title">Sales Performance</h4>
                        <hr />
                        <BarChart
                            datasets={[
                                {
                                    name: 'Pheonix',
                                    data: [9, 10, 3, 5, 2, 3, 9, 10, 3, 5, 2, 3],
                                }
                            ]}
                            colors={[COLORS.COLOR1]}
                            labels={['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec']}
                            title=''
                        />
                    </div>
                </div>
            </div> */}



        {/* <div className='col-sm-12 col-lg-6'>

                <div className="card m-2">
                    <div className="card-body">
                        <div className="float-end d-none d-md-inline-block">
                            <div className="btn-group mb-2">
                                <button type="button" className="btn btn-xs btn-secondary">Monthly</button>&nbsp;&nbsp;
                                <button type="button" className="btn btn-xs btn-secondary">Yearly</button>&nbsp;&nbsp;
                                <button type="button" className="btn btn-xs btn-secondary">YoY</button>&nbsp;
                            </div>
                        </div>
                        <h4 className="card-title">Multiple Center Sales</h4>
                        <hr />
                        <BarChart
                            datasets={[
                                {
                                    name: 'GBP',
                                    data: [9, 10, 14],
                                },
                                {
                                    name: 'GBM',
                                    data: [7, 9, 5],
                                },
                                {
                                    name: 'GBC',
                                    data: [4, 7, 9],
                                }
                            ]}
                            colors={[COLORS.COLOR1, COLORS.COLOR2, COLORS.COLOR3]}
                            labels={['2021', '2022', '2023']}
                            title=''
                        />
                    </div>
                </div>
            </div> */}

        {/* <div className='col-sm-12 col-lg-6'>
                <div className="card m-2">
                    <div className="card-body">
                        <div className="float-end d-none d-md-inline-block">
                            <div className="btn-group mb-2">
                                <button type="button" className="btn btn-xs btn-secondary">Monthly</button>&nbsp;&nbsp;
                                <button type="button" className="btn btn-xs btn-secondary">Yearly</button>&nbsp;&nbsp;
                                <button type="button" className="btn btn-xs btn-secondary">YoY</button>&nbsp;
                            </div>
                        </div>
                        <h4 className="card-title">Sales MAT</h4>
                        <hr />
                        <LineChart
                            datasets={[
                                {
                                    name: '2022 MAT',
                                    data: [5, 4, 3, 1, 0, 0, 0, 0, 0, 0, 0, 0],
                                    // backgroundColor: '#F95254',
                                    // fill: true
                                },
                                {
                                    name: '2023 MAT',
                                    data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 5, 4],
                                    // backgroundColor: '#52F98C',
                                    // fill: true
                                }
                            ]}
                            colors={[COLORS.COLOR11, COLORS.COLOR12, COLORS.COLOR13, COLORS.COLOR4, COLORS.COLOR5]}
                            labels={['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec']}
                            title=''
                        />
                    </div>
                </div>
            </div> */}

        {/* <div className='col-sm-12 col-lg-6'>
                <div className="card m-2">
                    <div className="card-body">
                        <div className="float-end d-none d-md-inline-block">
                            <div className="btn-group mb-2">
                                <button type="button" className="btn btn-xs btn-secondary">Monthly</button>&nbsp;&nbsp;
                                <button type="button" className="btn btn-xs btn-secondary">Yearly</button>&nbsp;&nbsp;
                                <button type="button" className="btn btn-xs btn-secondary">YoY</button>&nbsp;
                            </div>
                        </div>
                        <h4 className="card-title">Trade Category Yield</h4>
                        <hr />
                        <LineChart
                            datasets={[
                                {
                                    name: 'Retail',
                                    data: [9, 10, 3, 5, 2, 3, 9, 10, 3, 5, 2, 3]
                                },
                                {
                                    name: 'F&B',
                                    data: [6, 7, 3, 7, 4, 6, 9, 10, 3, 5, 2, 3]
                                },
                                {
                                    name: 'Entertainment',
                                    data: [4, 6, 7, 0, 1, 3, 9, 10, 3, 5, 2, 3]
                                }
                            ]}
                            colors={[COLORS.COLOR11, COLORS.COLOR12, COLORS.COLOR13, COLORS.COLOR4, COLORS.COLOR5]}
                            labels={['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec']}
                            title=''
                        />
                    </div>
                </div>
            </div> */}

        {/* <div className='col-sm-12 col-lg-6'>
                <div className="card m-2">
                    <div className="card-body">
                        <div className="float-end d-none d-md-inline-block">
                            <div className="btn-group mb-2">
                                <button type="button" className="btn btn-xs btn-secondary">Monthly</button>&nbsp;&nbsp;
                                <button type="button" className="btn btn-xs btn-secondary">Yearly</button>&nbsp;&nbsp;
                                <button type="button" className="btn btn-xs btn-secondary">YoY</button>&nbsp;
                            </div>
                        </div>
                        <h4 className="card-title">Rental Target Vs Projection Vs Actuals</h4>
                        <hr />
                        <BarChart
                            datasets={[
                                {
                                    name: 'Target',
                                    data: [9, 10, 3]
                                },
                                {
                                    name: 'Projection',
                                    data: [7, 9, 5]
                                },
                                {
                                    name: 'Actuals',
                                    data: [4, 7, 6]
                                }

                            ]}
                            colors={[COLORS.COLOR11, COLORS.COLOR12, COLORS.COLOR13, COLORS.COLOR4, COLORS.COLOR5]}
                            labels={['GBP', 'GBM', 'GBC']}
                            title=''
                        />
                    </div>
                </div>
            </div> */}

        {/* <div className='col-sm-12 col-lg-6'>
                <div className="card m-2">
                    <div className="card-body">
                        <div className="float-end d-none d-md-inline-block">
                            <div className="btn-group mb-2">
                                <button type="button" className="btn btn-xs btn-secondary">Monthly</button>&nbsp;&nbsp;
                                <button type="button" className="btn btn-xs btn-secondary">Yearly</button>&nbsp;&nbsp;
                                <button type="button" className="btn btn-xs btn-secondary">YoY</button>&nbsp;
                            </div>
                        </div>
                        <h4 className="card-title">Trade Category Sales</h4>
                        <hr />
                        <PieChart
                            datasets={[33, 40, 27]}
                            colors={[COLORS.COLOR11, COLORS.COLOR12, COLORS.COLOR13, COLORS.COLOR4, COLORS.COLOR5]}
                            labels={['Retail 37%', 'F&B 40%', 'Entertainment 27%']}
                            title=''
                        />
                    </div>
                </div>
            </div> */}

        {/* <div className='col-sm-12 col-lg-6'>
                <div className="card m-2">
                    <div className="card-body">
                        <div className="float-end d-none d-md-inline-block">
                            <div className="btn-group mb-2">
                                <button type="button" className="btn btn-xs btn-secondary">Monthly</button>&nbsp;&nbsp;
                                <button type="button" className="btn btn-xs btn-secondary">Yearly</button>&nbsp;&nbsp;
                                <button type="button" className="btn btn-xs btn-secondary">YoY</button>&nbsp;
                            </div>
                        </div>
                        <h4 className="card-title">Trade Category Revenue</h4>
                        <hr />
                        <PieChart
                            datasets={[5, 8, 7, 13, 17, 23, 17, 10]}
                            colors={[COLORS.COLOR1, COLORS.COLOR2, COLORS.COLOR3, COLORS.COLOR4, COLORS.COLOR5, COLORS.COLOR6, COLORS.COLOR7, COLORS.COLOR8]}
                            labels={['Retail_Apparel 5%', 'Retail_Electronics 8%', 'Retail_Footwear 7%', 'Retail_Luggage 13%', 'F&B_Fine Dining 17%', 'F&B_Food Court 23%', 'Entertainment_Cinemas 17%', 'Entertainment_Games Arena 10%']}
                            title=''
                        />
                    </div>
                </div>
            </div> */}

        {/* <div className='col-sm-12 col-lg-6'>
                <div className="card m-2">
                    <div className="card-body">
                        <div className="float-end d-none d-md-inline-block">
                            <div className="btn-group mb-2">
                                <button type="button" className="btn btn-xs btn-secondary">Monthly</button>&nbsp;&nbsp;
                                <button type="button" className="btn btn-xs btn-secondary">Yearly</button>&nbsp;&nbsp;
                                <button type="button" className="btn btn-xs btn-secondary">YoY</button>&nbsp;
                            </div>
                        </div>
                        <h4 className="card-title">Sales Vs Revenue</h4>
                        <hr />
                        <BarChart
                            datasets={[
                                {
                                    name: 'Sales',
                                    data: [9, 10, 8]

                                },
                                {
                                    name: 'Revenue',
                                    data: [7, 9, 4]
                                }

                            ]}
                            colors={[COLORS.COLOR11, COLORS.COLOR12, COLORS.COLOR13, COLORS.COLOR4, COLORS.COLOR5]}
                            labels={['GBP', 'GBM', 'GBC']}
                            title=''
                        />
                    </div>
                </div>
            </div> */}

        {/* <div className='col-sm-12 col-lg-6'>
                <div className="card m-2">
                    <div className="card-body">
                        <div className="float-end d-none d-md-inline-block">
                            <div className="btn-group mb-2">
                                <button type="button" className="btn btn-xs btn-secondary">Monthly</button>&nbsp;&nbsp;
                                <button type="button" className="btn btn-xs btn-secondary">Yearly</button>&nbsp;&nbsp;
                                <button type="button" className="btn btn-xs btn-secondary">YoY</button>&nbsp;
                            </div>
                        </div>
                        <h4 className="card-title">Multiple Center Sales</h4>
                        <hr />
                        <BarChart
                            datasets={[
                                {
                                    name: 'Footfall',
                                    data: [9, 10, 8]
                                }
                            ]}
                            colors={[COLORS.COLOR11, COLORS.COLOR12, COLORS.COLOR13, COLORS.COLOR4, COLORS.COLOR5]}
                            labels={['GBP', 'GBM', 'GBC']}
                            title=''
                        />
                    </div>
                </div>

            </div> */}
        {/* </div> */}

    </UserLayout>

}

export default Home
