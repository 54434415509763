import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { StoreState } from "../redux/store";
import { clearSession, SessionType } from '../redux/actions/commonActions'
import { logoutUser } from '../redux/actions/authUserActions';
import { inactive_time } from '../config';

interface SessionManagerProps {
  children: React.ReactNode;
}

const SessionManager: React.FC<SessionManagerProps> = ({ children }) => {
  const dispatch = useDispatch();
  // const userAuthenticated = useSelector<StoreState, SessionType | null>(
  //   (state) => state.session
  // )?.["isLoggedIn"]!;

  useEffect(() => {
    let timer: NodeJS.Timeout;
    const resetTimer = () => {
      clearTimeout(timer);
      let inactiveTime:number = inactive_time ? parseInt(inactive_time) : 600000;
        timer = setTimeout(() => {
          dispatch(clearSession());
          dispatch(logoutUser());
        }, inactiveTime);
    };

    resetTimer();

    const handleActivity = () => {
      resetTimer();
    };

    document.addEventListener('click', handleActivity);
    document.addEventListener('keypress', handleActivity);
    document.addEventListener('scroll', handleActivity);
    document.addEventListener('mousemove', handleActivity);
    document.addEventListener('keydown', handleActivity);

    return () => {
      clearTimeout(timer);
      document.removeEventListener('click', handleActivity);
      document.removeEventListener('keypress', handleActivity);
      document.removeEventListener('scroll', handleActivity);
      document.removeEventListener('mousemove', handleActivity);
      document.removeEventListener('keydown', handleActivity);
    };
  }, [inactive_time, dispatch]);

  return <>{children}</>;
};

export default SessionManager;